export default function (app) {
    app.factory('pillarDataService', PillarDataService);

    PillarDataService.$inject = ['apiBase', 'ApiPath'];
    function PillarDataService(apiBase, ApiPath) {
        var entity = "Pillar";
        var controllerUrl = ApiPath + entity + "/";

        function GetAllPillars(  ) {
            return apiBase.Get(controllerUrl + "GetAll" , null);
        }

        function GetPillarByID(pillarID) {
            return apiBase.Get(controllerUrl + "GetPillarByID", { pillarID: pillarID }, null);
        }

        function AddPillar(data) {
            return apiBase.Post(controllerUrl + "AddPillar", null, data);
        }

        function DeletePillar(pillarID) {
            return apiBase.Get(controllerUrl + "DeletePillar", { pillarID: pillarID }, null);
        }

        function UpdatePillar(data) {
            return apiBase.Post(controllerUrl + "UpdatePillar", null, data);
        }
        function ChangeActiveStatus(pillarID, activeState) {
            return apiBase.Post(controllerUrl + "ChangeActiveStatus", { pillarID: pillarID, activeState: activeState }, null);
        }
        function IsPillarExist(data,pillarID) {
            return apiBase.Post(controllerUrl + "IsPillarExist", { pillarID: pillarID}, data);
        }
        function DeletePillarByID(pillarID) {
            return apiBase.Post(controllerUrl + "DeletePillarByID", { pillarID: pillarID}, null);
        }
        function updatePillarsOrder(orderedPillarsID) {
            return apiBase.Post(controllerUrl + "UpdatePillarsOrder", null, orderedPillarsID);
        }
        return {
            GetAllPillars: GetAllPillars,
            GetPillarByID: GetPillarByID,
            AddPillar: AddPillar,
            DeletePillar: DeletePillar,
            UpdatePillar: UpdatePillar,
            ChangeActiveStatus: ChangeActiveStatus,
            IsPillarExist: IsPillarExist,
            DeletePillarByID: DeletePillarByID,
            UpdatePillarsOrder:updatePillarsOrder
        }
    }
}