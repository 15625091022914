export default {
    //repeatSection
    SAVE: 'Save',
    DELETE: 'Delete',
    ADD: 'Add',
    CLOSE: 'Close',
    EDIT_ITEM: 'Edit Item',
    ADD_ITEM: 'Add Item',

    //collapsibleRadioList
    PENALTY: 'Penalty',
	ALIKE: 'Alike',
	DELETE_REP_CONFIRM_HEADER: 'Delete Item',
	DELETE_REP_CONFIRM_MESSAGE: 'Are you sure you want to delete this item?',

	//select
	NO_CHOICE: 'There are no choices'
}