// unused
export default function (app) {
    app.controller('forgetPasswordController', ForgetPasswordController);
    ForgetPasswordController.$inject = ["$rootScope", "loginDataService", "Notification", "$uibModalInstance"];
    function ForgetPasswordController($rootScope, loginDataService, Notification, $uibModalInstance) {
        var vm = this;
        vm.labels = {
            RequiredUsernameOrPassword: $rootScope.GetResource('lbl_RequiredUsernameAndPassword'),
            WrongUsernameOrPassword: $rootScope.GetResource('lbl_WrongUsernameAndPassword'),
            ResetPasswordMailSent: $rootScope.GetResource('lbl_ResetPasswordMailSent'),
        };
        vm.Loading = $rootScope.Loading;
        vm.responseCode = $rootScope.enums.ResponseCode;
        vm.username = null;
        vm.load = load;
        vm.sendForgetPassword = sendForgetPassword;
        vm.cancel = cancel;
        vm.load();

        function sendForgetPassword() {

            vm.Loading();
            loginDataService.SendForgetPasswordEmail(vm.username).then(sendForgetPasswordSuccess, $rootScope.methodPublicError);
        }
        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false });
        }
        function load() {
            
        }
        function sendForgetPasswordSuccess(res) {
            if (res.responseCode === vm.responseCode.Success) {
                Notification.success({ message: vm.labels.ResetPasswordMailSent });
            }
            else if (res.responseCode === vm.responseCode.NotExist)
            {
                Notification.warning({ message: 'This email doesn’t exist'});
            }
            else {
                $rootScope.methodPublicError(res);
            }
            vm.Loading();
            $uibModalInstance.close({ Close: false, Ok: true });

        }
    }
}