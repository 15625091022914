export default {

    GENERATE: 'Generate',
    EXPORT: 'Export',
    TOTAL: 'Total',
    LEVEL: 'Report Level',
    TOTAL_BENEFICIARIES_PER_PILLAR: 'Total Beneficiaries per Pillar',
    TOTAL_SPENT_PER_PILLAR: 'Total Spend per Pillar',
    TOTAL_BENEFICIARIES_PER_CYCLE: 'Total Beneficiaries per Cycle',
    TOTAL_SPENT_PER_CYCLE: 'Total Spend per Cycle',
    ALL: 'All',
    EXPORT_SECTIONS: 'Export sections',
    ALL_SECTIONS: 'Sections',
    Cycles_Comparison: 'Cycles comparison',
    TOTAL_BENEFICIARIES_Unit: 'Beneficiaries (Person)',
    TOTAL_SPENT_Unit: 'Dirham (AED)',
    Report_Sections: 'Sections Report',
    Report_Comparison: 'Comparison Report',
    Report_Countries: 'Countries Report',
    Report_Scholarships: 'Scholarships Report',
    Report_Awards: 'Awards Report',
    Report_Beneficiaries: 'Beneficiaries Report',
    Report_List: 'Report List',
    Countries: 'Country',
    Countries_Total_BENEFICIARIES: 'Total Beneficiaries per Country',
    Countries_Total_Cost: 'Total Spend per Country',
    Award_Report: 'Award name',
    Scholarship_Report: 'Name',
    Entity_Name: 'Entity name',
    ScholarshipType_Total: 'Total',
    Beneficiaries_Unit: 'Beneficiary unit',
    PDF: 'PDF',
    EXCEL: 'EXCEL',
    Report_Summary_Totals: 'Report Summary and Totals',
    Financial_Report: 'Financial Report',
    Investment: 'Investment',
    Endowments_Grants: 'Endowments and Grants',
    Expenses: 'Expenses',
    Income: 'Income',
    Grand_Total: 'Grand Total',
    Actual: 'Actual',
    Rounded: 'Rounded'
}

