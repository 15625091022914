export default function (app) {

    app.factory('reportsService', ['apiBase', 'ApiPath', '$http', function (apiBase, ApiPath, $http) {
        var _reportControllerUrl = ApiPath + "Report/";
        var _annualCycleController = ApiPath + "AnnualCycle/";
        var _pillarController = ApiPath + "Pillar/";
        var _entityController = ApiPath + "Entity/";      
        var _getCyclesList = function () {
            return apiBase.Get(_annualCycleController + "GetAllCycles", null);
        }
        var _getEntitiesList = function () {
            return apiBase.Get(_entityController + "GetAllEntities", null);
        }
        var _getPillarsList = function () {
            return apiBase.Get(_pillarController + "GetAll", null);
        }
        var _getSectionReportInfo = function (cycleID, reportLevel, entityIDs, paillerIDs) {
            return apiBase.Get(_reportControllerUrl + "GetSectionReportInfo", { cycleID: cycleID, reportLevel: reportLevel, entityIDs: entityIDs, paillerIDs: paillerIDs }, null);
        }
        var _exportSectionReportInfo = function (cycleID, reportLevel, entityIDs, paillerIDs) {
            return apiBase.Get(_reportControllerUrl + "ExportSectionReportInfo", { cycleID: cycleID, reportLevel: reportLevel, entityIDs: entityIDs, paillerIDs: paillerIDs }, null, true, true);
        }
        var _getSectionEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "GetSectionEntitiesReportInfo", { cycleID: cycleID }, null);

        }
        var _exportSectionEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "ExportSectionEntitiesReportInfo", { cycleID: cycleID }, null, true, true);
        }
        var _getMBRGIStatisticsPerPillarReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "GetMBRGIStatisticsPerPillarReportInfo", { cycleID: cycleID }, null);

        }
        var _exportToexcel = function () {
            return apiBase.Get(_reportControllerUrl + "", null);
        }
        var _exportToPdf = function (htmlBody, reportLevel, reportType) {
            return apiBase.PostFile(_reportControllerUrl + "ExportPdf", { reportLevel: reportLevel, reportType: reportType}, htmlBody, null, "arraybuffer");
        }      
        var _exportToPdfComparisonReport = function (htmlBody, cycleIDs, reportLevel, reportType) {
            return apiBase.PostFile(_reportControllerUrl + "ExportPdfComparisonReport", { cycleIDs: cycleIDs, reportLevel: reportLevel, reportType: reportType }, htmlBody, null, "arraybuffer");
        }      
        var _getComparsionReportInfo = function (cycleIDs, reportLevel, entityID, pillarID) {
            return apiBase.Get(_reportControllerUrl + "GetComparsionReportInfo", { cycleIDs: cycleIDs, reportLevel: reportLevel, entityID: entityID, pillarID: pillarID }, null);
        }
        var _getComparisonEntitiesReportInfo = function (cycleIDs) {
            return apiBase.Get(_reportControllerUrl + "GetComparisonEntitiesReportInfo", { cycleIDs: cycleIDs }, null);
        }
        var _getComparsionStatisticsPerCycle = function (cycleIDs, reportLevel, entityID, pillarID) {
            return apiBase.Get(_reportControllerUrl + "GetComparsionStatisticsPerCycle", { cycleIDs: cycleIDs, reportLevel: reportLevel, entityID: entityID, pillarID: pillarID }, null);
        }

        var _getMBRGIComparsionStatisticsPerCyclePerPillar = function (cycleIDs) {
            return apiBase.Get(_reportControllerUrl + "GetMBRGIComparsionStatisticsPerCyclePerPillar", { cycleIDs: cycleIDs }, null);
        }

        var _getEntitiesPerPillars = function (pillarID) {
            return apiBase.Get(_reportControllerUrl + "GetEntitiesPerPillars", { pillarID: pillarID }, null);
        }
        var _getComparsionStatisticsPerCycleEntities = function (cycleIDs) {
            return apiBase.Get(_reportControllerUrl + "GetComparsionStatisticsPerCycleEntities", { cycleIDs: cycleIDs }, null);
        }
        var _getCountriesReportInfo = function (cycleID, reportLevel, entityIDs, paillerIDs) {
            return apiBase.Get(_reportControllerUrl + "GetCountriesReportInfo", { cycleID: cycleID, reportLevel: reportLevel, entityIDs: entityIDs, paillerIDs: paillerIDs }, null);
        }
        var _getCountriesEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "GetCountriesEntitiesReportInfo", { cycleID: cycleID }, null);
        }
        var _getMBRGICountriesStatisticsReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "GetMBRGICountriesStatisticsReportInfo", { cycleID: cycleID }, null);
        }

        var _getAwardReportInfo = function (cycleID, entityIDs) {
            return apiBase.Get(_reportControllerUrl + "GetAwardReportInfo", { cycleID: cycleID, entityIDs: entityIDs }, null);
        }
        var _getAwardEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "GetAwardEntitiesReportInfo", { cycleID: cycleID }, null);
        }
        var _getScholarshipsReportInfo = function (cycleID, entityIDs) {
            return apiBase.Get(_reportControllerUrl + "GetScholarshipsReportInfo", { cycleID: cycleID, entityIDs: entityIDs }, null);
        }
        var _getScholarshipsEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "GetScholarshipsEntitiesReportInfo", { cycleID: cycleID }, null);
        }
        var _getBeneficiariesReportInfo = function (cycleID, entityIDs) {
            return apiBase.Get(_reportControllerUrl + "GetBeneficiariesReportInfo", { cycleID: cycleID, entityIDs: entityIDs }, null);
        }
        var _getBeneficiariesEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "GetBeneficiariesEntitiesReportInfo", { cycleID: cycleID }, null);
        }
        var _exportComparsionReportInfo = function (cycleIDs, reportLevel, entityID, pillarID) {
            return apiBase.Get(_reportControllerUrl + "ExportComparsionReportInfo", { cycleIDs: cycleIDs, reportLevel: reportLevel, entityID: entityID, pillarID: pillarID }, null, true, true);
        }
        var _exportComparsionEntitiesReportInfo = function (cycleIDs) {
            return apiBase.Get(_reportControllerUrl + "ExportComparsionEntitiesReportInfo", { cycleIDs: cycleIDs }, null, true, true);
        }

        var _exportCountriesReportInfo = function (cycleID, reportLevel, entityIDs, pillarIDs) {
            return apiBase.Get(_reportControllerUrl + "ExportCountriesReportInfo", { cycleID: cycleID, reportLevel: reportLevel, entityIDs: entityIDs, pillarIDs: pillarIDs }, null, true, true);
        }
        var _exportCountriesEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "ExportCountriesEntitiesReportInfo", { cycleID: cycleID }, null, true, true);
        }
        var _exportAwardReportInfo = function (cycleID, entityIDs) {
            return apiBase.Get(_reportControllerUrl + "ExportAwardReportInfo", { cycleID: cycleID, entityIDs: entityIDs }, null, true, true);
        }
        var _exportAwardEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "ExportAwardEntitiesReportInfo", { cycleID: cycleID }, null, true, true);
        }
        var _exportScholarshipsReportInfo = function (cycleID, entityIDs) {
            return apiBase.Get(_reportControllerUrl + "ExportScholarshipsReportInfo", { cycleID: cycleID, entityIDs: entityIDs }, null, true, true);
        }
        var _exportScholarshipsEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "ExportScholarshipsEntitiesReportInfo", { cycleID: cycleID }, null, true, true);
        }
        var _exportBeneficiariesReportInfo = function (cycleID, entityIDs) {
            return apiBase.Get(_reportControllerUrl + "ExportBeneficiariesReportInfo", { cycleID: cycleID, entityIDs: entityIDs }, null, true, true);
        }
        var _exportBeneficiariesEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "ExportBeneficiariesEntitiesReportInfo", { cycleID: cycleID }, null, true, true);
        }
        var _getFinancialReportInfo = function (cycleID, entityIDs) {
            return apiBase.Get(_reportControllerUrl + "GetFinancialReportInfo", { cycleID: cycleID, entityIDs: entityIDs }, null);
        }
        var _getFinancialEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "GetFinancialEntitiesReportInfo", { cycleID: cycleID }, null);
        }
        var _exportFinancialReportInfo = function (cycleID, entityIDs) {
            return apiBase.Get(_reportControllerUrl + "ExportFinancialReportInfo", { cycleID: cycleID, entityIDs: entityIDs }, null, true, true);
        }
        var _exportFinancialEntitiesReportInfo = function (cycleID) {
            return apiBase.Get(_reportControllerUrl + "ExportFinancialEntitiesReportInfo", { cycleID: cycleID }, null, true, true);
        }
        return {
            getCyclesList: _getCyclesList,
            exportSectionReportInfo: _exportSectionReportInfo,
            exportSectionEntitiesReportInfo: _exportSectionEntitiesReportInfo,
            getEntitiesList: _getEntitiesList,
            getPillarsList: _getPillarsList,
            getSectionReportInfo: _getSectionReportInfo,
            getSectionEntitiesReportInfo: _getSectionEntitiesReportInfo,
            getMBRGIStatisticsPerPillarReportInfo: _getMBRGIStatisticsPerPillarReportInfo,
            exportToexcel: _exportToexcel,
            exportToPdf: _exportToPdf,
            getComparsionReportInfo: _getComparsionReportInfo,
            getComparisonEntitiesReportInfo: _getComparisonEntitiesReportInfo,
            getComparsionStatisticsPerCycle: _getComparsionStatisticsPerCycle,
            getMBRGIComparsionStatisticsPerCyclePerPillar: _getMBRGIComparsionStatisticsPerCyclePerPillar,
            getEntitiesPerPillars: _getEntitiesPerPillars,
            getComparsionStatisticsPerCycleEntities: _getComparsionStatisticsPerCycleEntities,
            getCountriesEntitiesReportInfo: _getCountriesEntitiesReportInfo,
            getMBRGICountriesStatisticsReportInfo: _getMBRGICountriesStatisticsReportInfo,
            getCountriesReportInfo: _getCountriesReportInfo,
            getAwardReportInfo: _getAwardReportInfo,
            getAwardEntitiesReportInfo: _getAwardEntitiesReportInfo,
            getScholarshipsReportInfo: _getScholarshipsReportInfo,
            getScholarshipsEntitiesReportInfo: _getScholarshipsEntitiesReportInfo,
            getBeneficiariesReportInfo: _getBeneficiariesReportInfo,
            getBeneficiariesEntitiesReportInfo: _getBeneficiariesEntitiesReportInfo,
            exportComparsionReportInfo: _exportComparsionReportInfo,
            exportComparsionEntitiesReportInfo: _exportComparsionEntitiesReportInfo,
            exportCountriesReportInfo: _exportCountriesReportInfo,
            exportCountriesEntitiesReportInfo: _exportCountriesEntitiesReportInfo,
            exportAwardReportInfo: _exportAwardReportInfo,
            exportAwardEntitiesReportInfo: _exportAwardEntitiesReportInfo,
            exportScholarshipsReportInfo: _exportScholarshipsReportInfo,
            exportScholarshipsEntitiesReportInfo: _exportScholarshipsEntitiesReportInfo,
            exportBeneficiariesReportInfo: _exportBeneficiariesReportInfo,
            exportBeneficiariesEntitiesReportInfo: _exportBeneficiariesEntitiesReportInfo,
            exportToPdfComparisonReport: _exportToPdfComparisonReport,
            getFinancialEntitiesReportInfo: _getFinancialEntitiesReportInfo,
            getFinancialReportInfo: _getFinancialReportInfo,
            exportFinancialReportInfo: _exportFinancialReportInfo,
            exportFinancialEntitiesReportInfo: _exportFinancialEntitiesReportInfo
        }
    }]);
}