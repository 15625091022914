export default {
    ADD_COUNTRY: 'إضافة دولة',
    COUNTRY_ENGLISH_NAME: 'اسم الدولة(الإنجليزية)',
    COUNTRY_ARABIC_NAME: 'اسم الدولة (العربية)',

    COUNTRY_TYPE: 'نوع الدولة',

    PLEASE_SELECT: 'الرجاء الاختيار',
    REQUIRED_COUNTRY_ARABIC_NAME: 'اسم الدولة بالعربية مطلوب',
    REQUIRED_COUNTRY_ENGLISH_NAME: 'اسم الدولة بالانجليزية مطلوب',
    REQUIRED_COUNTRY_TYPE: 'نوع الدولة بالانجليزية مطلوب',
    UPDATE: 'تعديل',
    UPDATE_COUNTRY: 'تعديل الدولة',

    COUNTRY_EXIST: 'لا يمكن تكرار اسم الدولة',
    COUNTRY_ADDED: 'تم إضافة الدولة بنجاح',
    COUNTRY_UPDATED: 'تم تعديل الدولة بنجاح',
    COUNTRY_DELETED: 'تم حذف الدولة بنجاح',
    COUNTRY_DELETE_QUES: 'هل تريد حذف الدولة؟',
    DELETE_HEADER: 'حذف',
    COUNTRY_RESTORED: 'تم استعادة الدولة بنجاح',


}