export default function (app) {
    app.directive('responsiveLayout', function () {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div  ng-transclude class="respLayout-container-Viewer"></div>',
            link: function (scope, element, attr, ctrls) {
                var fieldGroupNgForm = findFirstFieldGroupNgForm(element);  //get the root ng-form (fieldGroup)

                //mutation doesn't work on IE browsers < 11
                var observer = new MutationObserver(function (mutations) {
                    var fields = getFormlyFieldsIn(fieldGroupNgForm);
                    $(fieldGroupNgForm).css({ "display": "flex", "flex-wrap": "wrap", "width": "100 %" });
                    angular.forEach(fields, function (field, index) {
                        var approximateWidth = Array.isArray(scope.to.responsiveLayout) ? scope.to.responsiveLayout[index] : scope.to.responsiveLayout;
                        if (!isNaN(parseFloat(approximateWidth)) && isFinite(approximateWidth)) {
                            $(field).css('flex', approximateWidth + ' 1 0%');
                            $(field).css("flex-basis", approximateWidth + "px");
                            $(field).css("padding-right", "5px");
                            $(field).css("padding-left", "5px");
                        }
                        else {
                            throw Error("passing " + approximateWidth + " while the directive requires value of type number");
                        }
                    });
                });

                //observe when the html content of the directive changes
                if (fieldGroupNgForm instanceof Element)
                    observer.observe(fieldGroupNgForm, { childList: true });
            }
        }

        function findFirstFieldGroupNgForm(nodeOrElement) {
            return $(nodeOrElement).find('[is-field-group=""]')[0];
        }

        //formly fields could be input fields or fieldGroups
        function getFormlyFieldsIn(nodeOrElement) {
            return $(nodeOrElement).children('[formly-field=""]');
        }

    });
}
