import '../../../../Scripts/Datepicker/jquery.calendars';
import '../../../../Scripts/Datepicker/jquery.calendars.plus';
import '../../../../Scripts/Datepicker/jquery.plugin';
import '../../../../Scripts/Datepicker/jquery.calendars.picker';
import '../../../../Scripts/Datepicker/jquery.calendars.ummalqura';
import '../../../../Scripts/Datepicker/jquery.calendars.picker-ar-EG';
import '../../../../Scripts/Datepicker/jquery.calendars-ar-EG';
import '../../../../Scripts/Datepicker/jquery.calendars.ummalqura-ar';
import hijriMoment from 'moment-hijri';
//TODO check webpack config for css files
//import '../../../../../Contents/CSS/Datepicker/jquery.calendars.picker.css';


export default function (app) {
    //min and max dates must match dateFormat
    app.directive("customDatePicker", ["$timeout", function ($timeout) {
        var _isValidDate = function (calendar, dateForma, date) {
            try {
                $.calendars.instance(calendar).parseDate(dateForma, date);
            } catch (e) {
                return false;
            }
            return true;
        }
        var getLanguage = function (language, calendar) {
            if (language && language == 'ar') {
                return calendar == 'gregorian' ? 'ar-EG' : 'ar';
            }
            return '';
        }

        return {
            restrict: 'A',
            scope: {},
            link: function (scope, element, attrs, controllers) {

                /*
                link function is wrapped inside timeout to make sure that its content will be evaluated after digest cycle
                so that attrs have been interpolated
                */
                $timeout(function () {
                    //accepts ummalqura or gregorian to support other calendar you must import it first
                    var _calendar = attrs.calendar || 'gregorian';
                    var _dateFormat = attrs.dateFormat || 'yyyy-mm-dd';
                    var _minDateFormat = attrs.minDateFormat || 'yyyy-mm-dd';
                    var _maxDateFormat = attrs.maxDateFormat || 'yyyy-mm-dd';
                    var _minDate = attrs.minDate && _isValidDate(_calendar, _minDateFormat, attrs.minDate) ? attrs.minDate : null;
                    var _maxDate = attrs.maxDate && _isValidDate(_calendar, _maxDateFormat, attrs.maxDate) ? attrs.maxDate : null;
                    var _language = getLanguage(attrs.language, _calendar);



                    //int calendarsPicker instance
                    element.calendarsPicker($.extend({
                        calendar: $.calendars.instance(_calendar, _language),
                        dateFormat: _dateFormat,
                        minDate: _minDate,
                        maxDate: _maxDate
                    }, $.calendarsPicker.regionalOptions[_language]))

                    /*
                    changes in picker options are wrapped inside try and catch
                    to swallow calendar Picker exception that are caused by reinitializing
                    for the current use of the directive those exceptions do not affect its expected functionality
                    */
                    var unregisterCalendarObserve = attrs.$observe('calendar', function (calendar) {
                        if (calendar && typeof calendar == "string") {
                            _calendar = calendar;
                            try {
                                element.calendarsPicker("option", "calendar", $.calendars.instance(_calendar, _language));
                                element.calendarsPicker("option", $.calendarsPicker.regionalOptions[_language]);
                            } catch (e) { }
                        }
                        else {
                            throw "calendar must be a string"
                        }
                    })

                    var unregisterLanguageObserve = attrs.$observe('language', function (language) {
                        if (language && typeof language == "string") {
                            _language = getLanguage(language, _calendar);
                            try {
                                element.calendarsPicker("option", "calendar", $.calendars.instance(_calendar, _language));
                                element.calendarsPicker("option", $.calendarsPicker.regionalOptions[_language]);
                            } catch (e) { }
                        }
                        else {
                            throw "language must be a string"
                        }
                    })

                    var unregisterDateFormatObserve = attrs.$observe('dateFormat', function (dateFormat) {
                        if (dateFormat) {
                            _dateFormat = dateFormat;
                            try {
                                element.calendarsPicker("option", "dateFormat", _dateFormat);
                            } catch (e) { }
                        }
                    })

                    var unregisterMinDateObserve = attrs.$observe('minDate', function (minDate) {
                        if (minDate) {
                            if (_isValidDate(_calendar, _minDateFormat, minDate)) {
                                _minDate = minDate
                                try {
                                    element.calendarsPicker("option", "minDate", _minDate);
                                }
                                catch (e) { }
                            } else {
                                throw "minDate value must follow " + _minDateFormat + ' date format and calendar ' + _calendar;
                            }
                        }
                    })

                    var unregisterMaxDateObserve = attrs.$observe('maxDate', function (maxDate) {
                        if (maxDate) {
                            if (_isValidDate(_calendar, _maxDateFormat, maxDate)) {
                                _maxDate = maxDate
                                try {
                                    element.calendarsPicker("option", "maxDate", _maxDate);
                                }
                                catch (e) { }
                            } else {
                                throw "maxDate value must follow " + _maxDateFormat + ' date format and calendar ' + _calendar;
                            }
                        }
                    })

                    var unregisterIsOpenObserve = attrs.$observe('isOpen', function (isOpen) {
                        if (isOpen) {
                            element.calendarsPicker("show")
                        }
                    })


                    scope.$on("$destroy", function () {
                        if (element && element.calendarsPicker) {
                            try {
                                element.calendarsPicker("hide");
                            } catch (e) { }
                        }
                        unregisterCalendarObserve();
                        unregisterLanguageObserve();
                        unregisterDateFormatObserve();
                        unregisterMinDateObserve();
                        unregisterMaxDateObserve();
                        unregisterIsOpenObserve();
                    });
                })
            }
        }
    }])
}