export default function (app) {
    app.directive('reportPieChart', function () {
        return {
            restrict: 'E',
            template: '<div></div>',            
            scope: {
                title: '@',
                language: '@',
                data: '=',
                isOneChart: '=',
                size: '='
            },
            controller: ['$scope', function ($scope) {
            }],
            //lang: {
            //    printChart: 'Aaaa',
            //    thousandsSep: ','
            //},
            
            link: function (scope, element) {                
                //scope.$watch("isOneChart", function (newValue, oldValue) {
                //    
                //    if (scope.isOneChart) {
                //        element.removeClass('w-50');
                //        element.addClass('w-100');
                //    } else {
                //        element.removeClass('w-100');
                //        element.addClass('w-50');
                //    }
                //    //This gets called when data changes.
                //});
                var exportHight = 500;
                if (scope.data) {
                    
                    

                    if (scope.data.length > 60) {
                     
                        if (scope.language == 'ar')
                            exportHight = 14 * scope.data.length;
                        else
                            exportHight = 12.5 * scope.data.length;
                    }
                }
                if (scope.isOneChart) {
                    element.addClass('w-100');
                } else {
                    element.addClass('w-100');
                }
                var IsArabic = false;
                if (scope.language == 'ar')
                    IsArabic = true;
                Highcharts.chart(element[0], {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                    },
                    credits: {
                        enabled: false
                    },
                    
                    title: {
                        text: scope.title
                    },
                    legend: {
                        rtl: IsArabic,
                        itemWidth: 150,
                        maxHeight: 100,
                        
                    },
                    tooltip: {
                        pointFormat: '{y}'
                    },
                    plotOptions: {
                        pie: {
                            
                            allowPointSelect: true,
                            //size: scope.size + '%',
                            size: 150,
                            cursor: 'pointer',
                            showInLegend: true,
                           
                            dataLabels: {
                                enabled: true,
                                format: '{point.y} % <br><br/> {point.text}',
                                style: {
                                  
                                },
                                //color: '#FCFFC5',
                            }
                        }
                    },
                    

                    series: [{
                        name: 'Values',
                        data: scope.data
                    }],
                    exporting: {
                        chartOptions: {
                            chart: {
                                height: exportHight,
                                width:450
                                                              
                            },
                            plotOptions: {
                                pie: {
                                       size: 120
                                }
                            },
                            legend: {
                                align: 'right',
                                itemWidth: 200,
                                rtl: IsArabic,
                                navigation: {
                                    enabled: false
                                }
                            }
                        },
                        filename: scope.title
                    }
                });
            }
        };
    });
}