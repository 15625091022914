//Import controllers
import dashboardController from './Controllers/app.mbrgi-dashboard.controller';
//Import services
import dashboardDataService from './Services/mbrgi-dashboard.data.service';
//Import config
import './Localization';

export default function (app) {
    dashboardController(app);
    dashboardDataService(app);
}