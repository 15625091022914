export default function (app) {
    app.controller('lookupsController', LookupsController);

	LookupsController.$inject = ["$scope", "$rootScope", "lookupsDataService", "$uibModal", "Notification", "$translate", "$state","routeConstants"];
	function LookupsController($scope, $rootScope, lookupsDataService, $uibModal, Notification, $translate, $state, routeConstants) {
        var vm = this;
		$translate(["LOOKUPS.COUNTRY_DELETED", "LOOKUPS.COUNTRY_DELETE_QUES", "LOOKUPS.DELETE_HEADER","LOOKUPS.ACTIVE_CYCLE"]).then(function (translations) {
            vm.labels = {
				CountryDeleted: translations['LOOKUPS.COUNTRY_DELETED'],
				DeleteCountryQues: translations['LOOKUPS.COUNTRY_DELETE_QUES'],
				DeleteHeader: translations['LOOKUPS.DELETE_HEADER'],
				ACTIVE_CYCLE: translations['LOOKUPS.ACTIVE_CYCLE'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
		vm.Lookups = [];
		vm.lookupsFilter = lookupsFilter;
        vm.load = load;
		vm.getAllLookups = getAllLookups;
		vm.openUpdateModal = openUpdateModal;
		vm.openAddModal = openAddModal;
		vm.openEntriesList = openEntriesList;
        vm.Modal = null;
        vm.currentCycle = null;
        vm.deletedCount = 0;
        vm.load();

        function load() {
			vm.getAllLookups();
        }

		function getAllLookups() {
            vm.Loading();
			lookupsDataService.GetAllLookups().then(getAllLookupsSuccess, $rootScope.methodPublicError);
		}

		function getAllLookupsSuccess(res) {
			if (res.responseCode === vm.ResponseCode.Success) {
				vm.Lookups = res.responseObject;
				vm.Loading();
			}
			else {
				$rootScope.methodPublicError();
			}
		}

		function lookupsFilter(item) {
			if (vm.search) {
				return item.SystemKey.toLowerCase().includes(vm.search.toLowerCase()) || item.NameEn.toLowerCase().includes(vm.search.toLowerCase()) || item.NameAr.includes(vm.search);
			}
			else
				return true;
		}


		function openUpdateModal(lookup) {
			vm.Modal = $uibModal.open({
				//  backdrop: "static",
				keyboard: true,
				size: 'lg',
				animation: true,
				//backdropClick: true,
				templateUrl: 'ng/Modules/MBRGI/Lookups/Views/update-lookup.html',
				controller: 'updateLookupController',
				controllerAs: 'vm',
				resolve: {
					Params: function () { return lookup; }
				}
			});

			vm.Modal.result.then(function (result) {

				if (result.Ok)
					vm.load();
			}, function (error) {
				//backdrop clicked or error
			});
		}
		function openAddModal() {
			vm.Modal = $uibModal.open({
				//  backdrop: "static",
				keyboard: true,
				size: 'lg',
				animation: true,
				//backdropClick: true,
				templateUrl: 'ng/Modules/MBRGI/Lookups/Views/add-lookup.html',
				controller: 'addLookupController',
				controllerAs: 'vm',
				resolve: {
					Params: function () { }
				}
			});
			vm.Modal.result.then(function (result) {
				if (result.Ok)
					vm.load();
			}, function (error) {
				//backdrop clicked or error
			});
		}

        function openEntriesList(lookup) {
            if (lookup.IsPredefined) {
                $state.go(routeConstants.PredefinedLookupState.State, { lookupID: lookup.ID })
            }
            else {
                $state.go(routeConstants.LookupEntriesState.State, { lookupID: lookup.ID })
            }
		}

        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
        });
    }
}