
export default function (formlyBuilder) {
    // TODO check why this is running twice 
    formlyBuilder.controller('inputConfigController', ['$scope', function ($scope) {
         
        $scope.Pattern = " ";
        $scope.PatternOtherValue = "";
        var Expression = [
            new RegExp("^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"),
            new RegExp("^[a-zA-Z0-9~!@#$%^&*()\-+_'<>?؟:;./\\|{}÷×؛[\]‘,،\s]*$"),
            new RegExp("^[u0621-u064A0-9~!@#$%^&*()-+_'<>?؟:;./|{}÷×؛[]‘,،s]*$"),
            new RegExp("^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$"),
            new RegExp("^05[0-9]{8}$|^0[0-9]{8}$")
        ];

        $scope.SetPatternValue = function () {
           
            if ($scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions && $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.pattern) {
                var found = false;
                Expression.forEach((item) => {

                    if ($scope.checkIsEqual(item, new RegExp($scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.pattern.ValueEn))) {
                        found = true;
                    }
                });
               
                if (!found && ($scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.pattern && $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.pattern.ValueEn != " ") ) {
                    $scope.Pattern = "Other";
                    $scope.PatternOtherValue = $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.pattern.ValueEn;
                }
                else {
                    $scope.Pattern = $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.pattern.ValueEn;
                }
            }
            else {
                //$scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions = {
                //    pattern: {}};
            }
        }
        $scope.changetPatternValue = function () {
            var found = false;
            if ($scope.Pattern == null || $scope.Pattern == " "  ) {

                if ($scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions) delete $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.pattern;
                if ($scope.fieldConfigCtrl.formBuilderField.templateOptions.pattern) delete $scope.fieldConfigCtrl.formBuilderField.templateOptions.pattern;
                if ($scope.fieldConfigCtrl.formBuilderField.validation) delete $scope.fieldConfigCtrl.formBuilderField.validation.messages.pattern;
                if ($scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.validation) delete $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.validation.messages.pattern;
                return;
            }
            Expression.forEach((item) => {

                if ($scope.checkIsEqual(item, new RegExp($scope.Pattern))) {
                    found = true;
                }
            });
            if (found) {
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.pattern = { ValueEn: $scope.Pattern } ;

                $scope.fieldConfigCtrl.formBuilderField.templateOptions.pattern = $scope.Pattern;
            }
            else {

                $scope.Pattern = "Other";

                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.pattern = { ValueEn: $scope.PatternOtherValue } ;

                $scope.fieldConfigCtrl.formBuilderField.templateOptions.pattern = $scope.PatternOtherValue;

            }

        }

        $scope.checkIsEqual = function (s1, s2) {
            s1 = s1.toString().replace(/\\/g, "");
            s2 = s2.toString().replace(/\\/g, "");
            if (s1.length != s2.length) {
                return false;
            }
            var flg = true;
            for (var loop = 0; loop < s1.length; loop++) {
                if (s1.charAt(loop) != s2.charAt(loop)) {
                    flg = false;
                    break;
                }
            }
            return flg;

        }

        $scope.SetPatternValue();


    }
    ]);  
};
