export default function (formlyBuilder) {
    // TODO check why this is running twice 
    formlyBuilder.controller('singleImagePickerConfigController', ['$scope', function ($scope) {
        $scope.Extension = [
            "image/x-png",
            "image/jpeg",
            "image/jpg",
        ];
       
        $scope.SelectedExtension = [
           
        ];

        $scope.SetExtension = function () {
            if ($scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions && $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.accept) {
                $scope.SelectedExtension = $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.accept.ValueEn.split(",");
            }
         
        }
        $scope.changeExtensionValue = function (item) {
            
            $scope.SelectedExtension.push(item);
            if ($scope.SelectedExtension.length > 0) {
              
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.accept = { ValueEn: $scope.SelectedExtension.join(',') };
                $scope.fieldConfigCtrl.formBuilderField.templateOptions.accept = $scope.SelectedExtension.join(',');
            }
            else {
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.accept = { ValueEn: "" };
                $scope.fieldConfigCtrl.formBuilderField.templateOptions.accept = "";
            }
        }
        $scope.OnRemoveExtensionValue = function (item) {
 
            var index = $scope.SelectedExtension.indexOf(item);
            if (index > -1) {
           $scope.SelectedExtension.splice(index, 1);
            }
          
            if ($scope.SelectedExtension.length > 0) {
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.accept.ValueEn = $scope.SelectedExtension.join(',');
                $scope.fieldConfigCtrl.formBuilderField.templateOptions.accept = $scope.SelectedExtension.join(',');
            }
            else {
                  $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.accept.ValueEn = "";
                 $scope.fieldConfigCtrl.formBuilderField.templateOptions.accept = "";
            }
        }

        $scope.SetExtension();
        }]);

};
