import { filter as _filter, includes as _includes, map as _map, find as _find, remove as _remove, concat as _concat, pick as _pick, omit as _omit, cloneDeep as _cloneDeep } from 'lodash';


export default function (app) {

    app.filter("status", ["statusConstant", "MBRGIAnnualAchievementFilterConstants", function (statusConstant, MBRGIAnnualAchievementFilterConstants) {
        return function (achievements, StatusID) {
            if (!StatusID) {
                return achievements;
            }
        
            $('.empty-carousel').parents('.owl-stage').addClass('mx-auto');

            return _filter(achievements, (item) => {
                if (StatusID == MBRGIAnnualAchievementFilterConstants.NEW && item.StatusID == statusConstant.Submitted) {
                    return true;
                }
                else if (StatusID == MBRGIAnnualAchievementFilterConstants.UNDER_REVIEWING && (item.StatusID == statusConstant.Picked || item.StatusID == statusConstant.InProgress || item.StatusID == statusConstant.Reopened)) {
                    return true;
                }
                else if (StatusID == MBRGIAnnualAchievementFilterConstants.SENT_BACK && item.StatusID == statusConstant.SentBack) {
                    return true;
                }
                else if (StatusID == MBRGIAnnualAchievementFilterConstants.APPROVED && item.StatusID == statusConstant.Approved) {
                    return true;
                }
                else if (StatusID == MBRGIAnnualAchievementFilterConstants.IN_PROGRESS && (item.StatusID == statusConstant.NotSumbitted || item.StatusID == statusConstant.RequestForEditing)) {
                    return true;
                }
                else if (StatusID == MBRGIAnnualAchievementFilterConstants.EMPTY && item.StatusID == statusConstant.New) {
                    return true;
                }
                return false;
                //return _includes(status, item.StatusID)
            })
        };
    }]);

    app.controller('MBRGIAnnualAchievementsController', MBRGIAnnualAchievementsController);
    MBRGIAnnualAchievementsController.$inject = ["$scope", '$transitions', "$stateParams", "$rootScope", "annualAchievementsDataService", "$uibModal", "Notification", "$translate", "$state", "routeConstants", "statusConstant", "MBRGIAnnualAchievementFilterConstants", "actionsTypesConstant", '$filter', 'errorNotificationService'];
    function MBRGIAnnualAchievementsController($scope, $transitions, $stateParams, $rootScope, annualAchievementsDataService, $uibModal, Notification, $translate, $state, routeConstants, statusConstant, MBRGIAnnualAchievementFilterConstants, actionsTypesConstant, $filter, errorNotificationService) {
        var vm = this;
        var slider = [];
        vm.sliderProperties = [];
        vm.isSliderNextDisabled = [];
        vm.isSliderPrevDisabled = [];
        vm.owl = {};
        vm.load = load;
        vm.NotCycleID = $stateParams.cycleID;
        $translate(['ANNUALACHIEVEMENTS.NEW', 'ANNUALACHIEVEMENTS.APPROVED', 'ANNUALACHIEVEMENTS.SENT_BACK', 'ANNUALACHIEVEMENTS.UNDER_REVIEWING', 'ANNUALACHIEVEMENTS.PICK', 'ANNUALACHIEVEMENTS.ANNUAL_RELEASED', 'ANNUALACHIEVEMENTS.RELEASE_QUES',
            'ANNUALACHIEVEMENTS.UNPICK', 'ANNUALACHIEVEMENTS.VIEW', 'ANNUALACHIEVEMENTS.ANNUAL_REOPENED', 'ANNUALACHIEVEMENTS.PICKED_BY', 'ANNUALACHIEVEMENTS.EDIT', 'ANNUALACHIEVEMENTS.PICKEDBY_ANOTHER', 'ANNUALACHIEVEMENTS.ANNUAL_PICKED', 'ANNUALACHIEVEMENTS.NOT_ACTIVITIES_SUBMITED', 'ANNUALACHIEVEMENTS.Empty', 'ANNUALACHIEVEMENTS.InProgress', 'ANNUALACHIEVEMENTS.NotAllowToSubmit']).then(function (translations) {
                vm.labels = {
                    NEW: translations['ANNUALACHIEVEMENTS.NEW'],
                    APPROVED: translations['ANNUALACHIEVEMENTS.APPROVED'],
                    SENT_BACK: translations['ANNUALACHIEVEMENTS.SENT_BACK'],
                    UNDER_REVIEWING: translations['ANNUALACHIEVEMENTS.UNDER_REVIEWING'],
                    PICK: translations['ANNUALACHIEVEMENTS.PICK'],
                    UNPICK: translations['ANNUALACHIEVEMENTS.UNPICK'],
                    VIEW: translations['ANNUALACHIEVEMENTS.VIEW'],
                    PICKED_BY: translations['ANNUALACHIEVEMENTS.PICKED_BY'],
                    EDIT: translations['ANNUALACHIEVEMENTS.EDIT'],
                    PICKEDBY_ANOTHER: translations['ANNUALACHIEVEMENTS.PICKEDBY_ANOTHER'],
                    ANNUAL_PICKED: translations['ANNUALACHIEVEMENTS.ANNUAL_PICKED'],
                    ANNUAL_RELEASED: translations['ANNUALACHIEVEMENTS.ANNUAL_RELEASED'],
                    RELEASE_QUES: translations['ANNUALACHIEVEMENTS.RELEASE_QUES'],
                    NOT_ACTIVITIES_SUBMITED: translations['ANNUALACHIEVEMENTS.NOT_ACTIVITIES_SUBMITED'],
                    Empty: translations['ANNUALACHIEVEMENTS.Empty'],
                    InProgress: translations['ANNUALACHIEVEMENTS.InProgress'],
                    REOPEN: translations['ANNUALACHIEVEMENTS.REOPEN'],
                    ANNUAL_REOPENED: translations['ANNUALACHIEVEMENTS.ANNUAL_REOPENED'],
                    ANNUAL_CANNOT_REOPENED: translations['ANNUALACHIEVEMENTS.ANNUAL_CANNOT_REOPENED'],
                    NotAllowToSubmit: translations['ANNUALACHIEVEMENTS.NotAllowToSubmit'],
                }
            });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.status = statusConstant;
        vm.filterConstants = MBRGIAnnualAchievementFilterConstants;
        vm.actionsTypes = actionsTypesConstant;
        vm.annualAchievementsVM = null;
        vm.showFilter = true;
        vm.cycles = [];
        vm.cycleIndex = 0;
        vm.AddEmailTemplate = AddEmailTemplate;
        vm.load();
        vm.isArabic = $translate.use() == 'ar' ? true : false;
        $rootScope.$on('$translateChangeEnd', function (event, args) {
          
            $scope.isRtl = args.language === 'ar';
            $scope.sliderProperties.rtl = $scope.isRtl;
        });

        var _onSuccess = $transitions.onSuccess({ to: routeConstants.MBRGIAnnualAchievementsState.State }, function (transition) {
            vm.load();
        });

        $scope.isRtl = $translate.use() === 'ar';
        
        $scope.sliderReady = ($api, i) => {
            slider[i] = $api;
            $('.empty-carousel').parents('.owl-stage').addClass('mx-auto');
        };


        $scope.sliderNext = (i) => {
            slider[i].trigger('next.owl.carousel');
        }

        $scope.sliderPrev = (i) => {
            slider[i].trigger('prev.owl.carousel');
        };

        function AddEmailTemplate(id) {
            $state.go('MBRGI.AddEmailTemplate', { entityID:id});
        }
        
        function load() {
            //to make the state abstract 
            if ($state.$current.name === "MBRGI.MBRGIAnnualAchievements") {
                vm.Loading();
                annualAchievementsDataService.getCyclesList().then(getCyclesListSuccess, $rootScope.methodPublicError);

                var currentUser = $rootScope.GetCurrentUser();
                if (currentUser != null && currentUser !== undefined) {
                    if (currentUser.CurrentRoleID == 2) {
                        vm.showFilter = false;
                    }
                }
            }
        }

        function getCyclesListSuccess(response) {
            if (response && response.responseCode == 1 && response.responseObject) {
                var _data = response.responseObject;
                if (_data.length > 0) {
                    _data = $filter('orderBy')(_data, 'ID');
                    for (var i = 0; i < _data.length; i++) {
                        var cycle = _data[i];
                        if ($rootScope.currentLang == 'en') {
                            cycle.name = cycle.NameEn;
                        }
                        else {
                            cycle.name = cycle.NameAr;
                        }
                        if (cycle.ActiveCycle) {
                            vm.cycleID = cycle.ID;
                            $rootScope.cycleID = vm.cycleID;
                            vm.cycleIndex = i;
                        }
                        vm.cycles.push(cycle);
                    }
                    if (vm.cycleID == 0 || vm.cycleID == null) {
                        vm.cycleID = vm.cycles[vm.cycles.length - 1].ID;                       
                        vm.cycleIndex = vm.cycles.length - 1;
                    }
                    if (vm.NotCycleID != null) {

                        vm.cycleID = vm.NotCycleID;
                        vm.cycleIndex = vm.cycles.findIndex(x => x.ID == vm.cycleID);
                    }
                    annualAchievementsDataService.getAchievementsSummary(vm.cycleID).then(getMBRGIAnnualAchievementsSummarySuccess, $rootScope.methodPublicError);
                }
            }
        }

        vm.viewAnnualAchievement = function (entityID) {
            $state.go(routeConstants.MBRGIAnnualAchievementState.State, { cycleID: vm.cycleID, entityID: entityID, viewMode: true, isCycleEnabled: vm.isCycleEnabled });
        }

        vm.editAnnualAchievement = function (entityID) {
            $state.go(routeConstants.MBRGIAnnualAchievementState.State, { cycleID: vm.cycleID, entityID: entityID, viewMode: false, isCycleEnabled: vm.isCycleEnabled });
        }

        vm.pickAnnualAchievement = function (entityID) {
            vm.Loading();
            annualAchievementsDataService.pickAnnualAchivement(entityID).then(
                function (res) {
                    vm.Loading();
                    if (res.responseCode == vm.ResponseCode.Success) {
                        Notification.success({ message: vm.labels.ANNUAL_PICKED });
                        vm.editAnnualAchievement(entityID);
                    } else if (res.responseCode == vm.ResponseCode.IsAlreadyPicked) {
                        Notification.error({ message: vm.labels.PICKEDBY_ANOTHER });
                    }
                    else if (res.responseCode == 324) {
                        Notification.error({ message: vm.labels.NotAllowToSubmit });
                        vm.load();
                    }
                }, $rootScope.methodPublicError);
        }

        vm.unPickAnnualAchievement = function (entityID, state) {
            var _entityID = entityID;
            var labels = vm.labels;
            var _state = state;
            if (_state == statusConstant.Picked || _state == statusConstant.InProgress) {
                vm.Modal = $uibModal.open({
                    //  backdrop: "static",
                    keyboard: true,
                    size: 'md modal-dialog-centered',
                    animation: true,
                    //backdropClick: true,
                    templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
                    controller: function ($uibModalInstance) {

                        var vm = this;
                        vm.header = labels.UNPICK;
                        vm.message = labels.RELEASE_QUES;
                        vm.ok = function () {
                            $uibModalInstance.close({ Close: false, Ok: true });
                        };
                        vm.cancel = function () {
                            $uibModalInstance.close({ Close: true, Ok: false });
                        };
                    },
                    controllerAs: 'vm'
                });
                vm.Modal.result.then(function (result) {
                    if (result.Ok) {
                        vm.Loading();
                        annualAchievementsDataService.unPickAnnualAchivement(_entityID).then(unPickAnnualAchivementSuccess, $rootScope.methodPublicError);

                    }
                }, function (error) {
                    //backdrop clicked or error
                });
            }
        }

        vm.reopenAnnualAchievement = function (entityID) {
            vm.Loading();
            annualAchievementsDataService.reopenAnnualAchivement(entityID).then(
                function (res) {
                    vm.Loading();
                    if (res.responseCode == vm.ResponseCode.Success) {                      
                        Notification.success({ message: vm.labels.ANNUAL_REOPENED });
                        vm.editAnnualAchievement(entityID);
                    } else if (res.responseCode == vm.ResponseCode.NotApproved) {
                        Notification.error({ message: vm.labels.ANNUAL_CANNOT_REOPENED });
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, $rootScope.methodPublicError);
        }

        function getMBRGIAnnualAchievementsSummarySuccess(res) {
           
            vm.Loading();
            if (res.responseCode == vm.ResponseCode.Success) {
                vm.annualAchievementsVM = res.responseObject;
                vm.thumbPath = res.thumbPath
                vm.isCycleEnabled = res.isCycleEnabled

                vm.annualAchievementsVM.forEach(function (achievement) {
                    setSliderProperties(achievement.PillarID);
                });



            }
        }

        function setSliderProperties(i) {
            vm.sliderProperties[i] = {
                mouseDrag: false,
                touchDrag: false,
                autoWidth: false,
                margin: 5,
                rtl: $scope.isRtl,
                items: 4,
                nav: false,
                dots: false,
                onChanged: function onChanged(event) {
                    vm.isSliderNextDisabled[i] = (event.item.index + event.page.size) >= event.item.count;
                    vm.isSliderPrevDisabled[i] = (event.item.index === 0);
                }
            }
        }

        vm.getStatName = function (_state) {
            var labels = vm.labels;
            if (_state == statusConstant.Submitted) {
                return labels.NEW;
            } else if (_state == statusConstant.Approved) {
                return labels.APPROVED;
            } else if (_state == statusConstant.SentBack) {
                return labels.SENT_BACK;
            }
            else if (_state == statusConstant.New) {
                return labels.Empty;
            }
            else if (_state == statusConstant.NotSumbitted || _state == statusConstant.RequestForEditing) {
                return labels.InProgress;
            }
            else if (_state == statusConstant.Picked || _state == statusConstant.InProgress || _state == statusConstant.Reopened) {
                return labels.UNDER_REVIEWING;
            }
        }

        vm.getActionName = function (actionID) {
            var labels = vm.labels;
            if (actionID == vm.actionsTypes.Pick)
                return labels.PICK;
            else if (actionID == vm.actionsTypes.unPick)
                return labels.UNPICK;
            else if (actionID == vm.actionsTypes.View)
                return labels.VIEW;
            else if (actionID == vm.actionsTypes.Edit)
                return labels.EDIT;
            else if (actionID == vm.actionsTypes.Reopen)
                return vm.isArabic ? 'إعادة فتحها' :'Reopen';
        }

        vm.executeAction = function (entityID, actionID, state) {            
            if (actionID == 5)
                vm.pickAnnualAchievement(entityID, state);
            else if (actionID == 6)
                vm.unPickAnnualAchievement(entityID, state);
            else if (actionID == 7)
                vm.viewAnnualAchievement(entityID);
            else if (actionID == 8)
                vm.editAnnualAchievement(entityID);
            else if (actionID == 11)
                vm.reopenAnnualAchievement(entityID);
        }

        vm.getStateTextColor = function (_state) {

            if (_state == statusConstant.Submitted) {
                return 'bg-primary text-white';
            } else if (_state == statusConstant.Approved) {
                return 'bg-success text-white';
            } else if (_state == statusConstant.SentBack) {
                return 'bg-danger text-white';
            }
            else if (_state == statusConstant.New) {
                return 'bg-darkred text-white';
            }
            else if (_state == statusConstant.NotSumbitted || _state == statusConstant.RequestForEditing) {
                return 'bg-darkyellow text-white';
            }
            else if (_state == statusConstant.Picked || _state == statusConstant.InProgress || _state == statusConstant.Reopened) {
                return 'bg-darkorange text-white';
            }
        }

        vm.searchFilter = function (item) {
            if (vm.search) {
                return item.Title.toLowerCase().includes(vm.search.toLowerCase());
            }
            else
                return true;
        }

        function pickAnnualAchivementSuccess(res) {
            vm.Loading();
            if (res.responseCode == vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.ANNUAL_PICKED });
                vm.load();
            } else if (res.responseCode == vm.ResponseCode.IsAlreadyPicked) {
                Notification.error({ message: vm.labels.PICKEDBY_ANOTHER });
            }
        }

        function unPickAnnualAchivementSuccess(res) {
            vm.Loading();
            if (res.responseCode == vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.ANNUAL_RELEASED });
                vm.load();
            }
            else if (res.responseCode == vm.ResponseCode.notActivitiesSubmited) {
                Notification.error({ message: vm.labels.NOT_ACTIVITIES_SUBMITED });
            }

        }
        
        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
            if (_onSuccess)
            {
                _onSuccess();
            }
        });

        vm.getAnnualAchievement = function (index) {
            vm.NotCycleID = null;
            vm.Loading();
            vm.cycleID = vm.cycles[index].ID;
            vm.cycleIndex = index;
            
            annualAchievementsDataService.getAchievementsSummary(vm.cycleID).then(getMBRGIAnnualAchievementsSummarySuccess, $rootScope.methodPublicError);
        }
    }
}