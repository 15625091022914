export default function (formlyBuilder) {
    formlyBuilder.controller('fieldGroupRepeaterConfigController', ['$scope', function ($scope) {

        $scope.defaultValue;
        $scope.setDefaultValue = function () {
            if ($scope.fieldConfigCtrl.formBuilderField.defaultValue) {

                if ($scope.fieldConfigCtrl.formBuilderField.defaultValue[0] != "" && $scope.fieldConfigCtrl.formBuilderField.defaultValue[0] != undefined)
                    $scope.defaultValue = "{}";
                else
                    $scope.defaultValue = "";
            }
        }
        $scope.changeDefaultValue = function () {
            
            if ($scope.defaultValue == null || $scope.defaultValue == "") {
                $scope.fieldConfigCtrl.formBuilderField.defaultValue=[];
                delete $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions['defaultValue'];
            }
            else {
                $scope.fieldConfigCtrl.formBuilderField.defaultValue[0] = "{}";
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.defaultValue =
                    {
                        ValueEn: [  { } ],
                        ValueAr: null
                    };
            }

        }
        $scope.setDefaultValue();
    
    }]);
};
