export default {
    FORM_ELEMENTS: 'عناصر النموذج',
    MAIN_ELEMENTS: 'العناصر الأساسية',
    ELEMENTS_PROPERITIES:"خصائص العناصر",
    PREVIEW: 'مشاهدة النموذج',
    CLEAR: 'حذف الكل',
    SAVE: 'حفظ',
    PLACEHOLDER: 'قيمة ثانوية',
    REQUIRED: 'مطلوب',
    DELETE: 'حذف',
    OPTIONS: 'الخيارات',
    KEY: 'معرف الحقل',
    REQUIRED_ERROR_MESSAGE: 'هذا الحقل مطلوب',
    IS_NUMBER_ERROR_MESSAGE: 'يجب أن تكون القيمة رقمية',
    IS_UNIQUE_KEY_ERROR_MESSAGE: 'هذا المُعرف موجود مسبقاً',
    BINDING_ERROR_MESSAGE: 'هذا المعرف محذوف و مرتبط بنوع حقل مختلف',
    RESTORE_ERROR_MESSAGE:'هذا المعرف محذوف هل تريد استعادة؟',
    ADDITIONAL_ELEMENTS: 'عناصر إضافية',
    MSG_ADDED_SUCCESSFULLY: 'تم الحفط بنجاح',

    ///Common 
    FIELDNAME_En: 'عنوان الحقل (بالإنجليزية)',
    FIELDNAME_Ar: 'عنوان الحقل (بالعربية)',
    FIELD_SystemKey: 'معرف الحقل',
    FIELD_Expression: 'مصطلح الإخفاء',
    REQUIRED_systemKey: 'هذا الحقل مطلوب',
    Pattern_SystemKey: 'هذا الحقل يجب ان يبدأ  بالحرف',
    Placeholder_En: 'قيمة ثانوية (بالإنجليزية)',


    Description_En: 'معلومات عن الحقل (بالإنجليزية)',
    Description_Ar: 'معلومات عن الحقل (بالعربية)',
    Placeholder_En: 'قيمة ثانوية (بالإنجليزية)',
    Placeholder_Ar: 'قيمة ثانوية (بالعربية)',
    IsShortForm: 'حقل للنموذج الموجز',
    ShortFormDisplayOrder: 'ترتيب العرض',
    ReadOnly: 'للقراءة فقط',
    Pattern: 'النمط',

    Pattern_Ar: 'رسالة التحقق من صحة النمط (بالعربية) - مطلوب في حالة اختيار نمط',
    Pattern_En: 'رسالة التحقق من صحة النمط (بالإنجليزية) - مطلوب في حالة اختيار نمط',
    FIELD_Expression: 'مصطلح الإخفاء',
    REQUIRED_systemKey: ' هذا الحقل مطلوب',
 
    ONLY_ENGLISH_VALIDATION: 'هذا الحقل لا يقبل إلا اللغة الإنجليزية',
    ONLY_ARABIC_VALIDATION: 'هذا الحقل لا يقبل إلا اللغة العربية',
    min_VALIDATION: ' الحد الادني لهذا الحقل هو 1',
    CardKey: 'رقم البطاقة',
    Display_Mode: 'وضع العرض',
    Full_Display_Mode: 'كامل',
    Half_Display_Mode: 'نصف',
    Default_Value: 'القيمة الإفتراضية',

    MaximumMsgEN: 'رسالة التحقق من صحة امتداد الملف (بالإنجليزية)',
    MaximumMsgAr: 'رسالة التحقق من صحة الحجم الأقصى للملف (بالعربية)', 
    //Group 
    Group_Type:'نوع',
    //Text input
    TEXTBOX: 'حقل نصى',
    TEXTBOX_LABEL: 'عنوان الحقل النصي',
    //numericInput
    NUMBERFIELD: 'حقل رقمي',
    NUMBERFIELD_LABEL: 'عنوان الحقل الرقمي',

    Numberformat: 'تنسيق الرقم',
    NumberformatMsgAr: 'رسالة التحقق من صحة تنسيق الرقم (بالعربية)',
    NumberformatMsgEn: 'رسالة التحقق من صحة تنسيق الرقم (بالإنجليزية)',
    Minimumvalue: 'الحد الادنى',
    MinimuMsgEN: 'رسالة التحقق من صحة الحد الادنى للرقم (بالإنجليزية)',
    MinimuMsgAr: 'رسالة التحقق من صحة الحد الادنى للرقم (بالعربية)',
    Maximumvalue: 'الحد الأقصى',
    MaximumMsgEN: 'رسالة التحقق من صحة الحد الأقصى للرقم (بالإنجليزية)',
    MaximumMsgAr: 'رسالة التحقق من صحة الحد الأقصى للرقم (بالعربية)', 
    Integer: "عدد صحيح",
    Decimal:"عدد عشري",
    //TextArea
    TEXTAREA: 'مساحة نصية',
    TEXTAREA_FIELDNAME_En: 'عنوان الحقل (بالإنجليزية)',
    TEXTAREA_FIELDNAME_Ar: 'عنوان الحقل (بالعربية)',
    TEXTAREA_Placeholder_En: 'قيمة ثانوية (بالإنجليزية)',
    TEXTAREA_Placeholder_ar: 'قيمة ثانوية (بالعربية)',
    TEXTAREA_ROW_NUMBER: 'عدد الصفوف',
    //select

    SELECT: 'قائمة منسدلة',
    SELECT_FIELDNAME: 'عنوان قائمة منسدلة',
    SELECT_OPTIONS: 'نوع',
    SELECT_OPTIONS_searchable: 'بحث',
    Select_Lookups: 'مصدر البيانات',
    nullDisplay_En: 'العنصر الأول (بالإنجليزية)',
    nullDisplay_Ar: 'العنصر الأول (بالعربية)',
    AllowNull: 'لا يقبل قيمة خالية',
    //RadioButton
    RADIOBUTTON: 'اختيار مفرد',
    RADIOBUTTON_OPTIONS: 'الاختيار الاول\nالاختيار الثاني',
    RADIOBUTTON_FIELDNAME: 'عنوان اختيار مفرد',
    RADIOBUTTON_INLINE: 'افقي',
    //Checkbox
    CHECKBOX: 'مربع التأشير',
    CHECKBOX_FIELDNAME: 'عنوان مربع التأشير',
    //Multicheckbox
    MULTICHECKBOX: 'اختيار متعدد',
    MULTICHECKBOX_FIELDNAME: 'عنوان اختيار متعدد',
    MULTICHECKBOX_OPTIONS: 'الاختيار الاول\nالاختيار الثاني',
    //GregorianDatePicker
    GREGORIAN_DATEPICKER: 'تقويم ميلادي',
    GREGORIAN_DATEPICKER_FIELDNAME: 'عنوان تاريخ',
    GREGORIAN_DATETYPE: 'نوع التاريخ',
    GREGORIAN_DATETYPEABSOLUTE: 'مطلق',
    GREGORIAN_DATETYPERELATIVE: 'نسبي',
    GREGORIAN_DATESTART: 'البداية',
    GREGORIAN_DATEEND: 'النهاية',
    //HijriDatePicker
    HIJRI_DATEPICKER: 'تقويم هجري',
    HIJRI_DATEPICKER_FIELDNAME: 'عنوان تاريخ',
    HIJRI_DATETYPE: 'نوع التاريخ',
    HIJRI_DATETYPEABSOLUTE: 'مطلق',
    HIJRI_DATETYPERELATIVE: 'نسبي',
    HIJRI_DATESTART: 'البداية',
    HIJRI_DATEEND: 'النهاية',
    //map
    MAP: 'موقع',
    MAP_FIELDNAME: 'عنوان الخريطة',
    //ImagePicker
    IMAGE_PICKER: 'صورة',
    IMAGE_PICKER_LABEL: 'عنوان منتقي الصور',
    //ImagePicker
    IMAGE_PICKER: 'صورة',
    IMAGE_PICKER_LABEL: 'عنوان منتقي الصور',
    Files_extensions: 'امتداد الملفات',
    Files_Size: 'الحجم الأقصى لكل ملف(MB)',
    Files_Extensions_MaximumMsgEN: 'رسالة التحقق من صحة امتداد الملفات (بالإنجليزية)',
    Files_Extensions_MaximumMsgAr: 'رسالة التحقق من صحة امتداد الملفات (بالعربية)',
    Files_Size_MaximumMsgEN: 'رسالة التحقق من صحة الحجم الأقصى للملف (بالإنجليزية)',
    Files_Size_MaximumMsgAr: 'رسالة التحقق من صحة الحجم الأقصى للملف (بالعربية)',
    //Signature
    SIGNATURE: 'توقيع',
    SIGNATURE_LABEL: 'عنوان التوقيع',
    //SubForm
    SUBFORM: 'نموذج فرعي',
    //Wizard
    WIZARD: 'خطوات',
    WIZARD_STEP_LABEL: 'عنوان الخطوة',
    WIZARD_NEXT: 'التالي',
    WIZARD_PREV: 'السابق',
    WIZARD_NO_OF_STEPS: 'عدد الخطوات',
    //RepeatSection
    REPEAT_SECTION: 'عنصر متكرر',
    REPEAT_SECTION_LABEL: 'عنوان العنصر متكرر',
    REPEAT_SECTION_TYPE: 'نوع',
    REPEAT_SECTION_TYPE_TABLE: 'جدول',
    REPEAT_SECTION_TYPE_CARD: 'كارت',
    REPEAT_SECTION_TYPE_DISABLED: 'معطل',
    //ResponsiveLayout
    RESPONSIVE_LAYOUT: 'تصميم متجاوب',
    RESPONSIVE_LAYOUT_Field_Width: 'العرض التقريبي',
    //CollabsibleRadioList
    COLLABSIBLE_RADIO_LIST: 'إشتراطات',
    COLLABSIBLE_RADIO_LIST_LABEL: 'عنوان مجموعة الاشتراطات',
    COLLABSIBLE_RADIO_LIST_ITEM: 'إشتراط',
    NEW_COLLABSIBLE_RADIO_LIST_ITEM: 'إشتراط جديد',

    //filesPicker
    FilePicker: 'منتقي الملفات',



    ///fieldGroupRepeater
    cardTitle_EN: 'عنوان البند (بالإنجليزية)',
    cardTitle_AR: 'عنوان البند (بالعربية)',
    btnText_EN: 'اسم زر الإضافة (بالإنجليزية)',
    btnText_AR: 'اسم زر الإضافة (بالعربية)',



    NONE_Value: 'لا شئ',
    EmtpyRow_Value: 'صف فارغ',

    //Field group
    FieldGroup: 'مجمع حقول',
    Fields_Aggregator: 'مجمع حقول مكرر',
    Aggregation_expression: 'مصطلح التجميع ',
    repeaterFieldsAggregator: 'مجمع حقول مكرر مجمعة',
    source: 'حساب المصدر',



    //Entities
    Entities: 'المؤسسات ',
    Mandatory: 'مطلوب',
    Visible: 'مرئي',
    //layout
    Panellayout: 'تصميم عمودي',
    Horizontallayout: 'تصميم أفقي',
    DuplicateFieldSystemKey: 'لا يمكنك تكرار الاسم',

    //aside tabs
    Properties: 'الخصائص',
    Permissions: 'صلاحيات',
    DELETED: 'لا يمكنك حذف هذا العنصر ',
    CannotClearForm: 'لا يمكنك حذف كل العناصر ',

    ///
    ConfirmationHeader: 'تاكيد',
    ConfirmationMsg: 'قد يتسبب التعديل في فقد قيم مهمة لتقارير الإنجازات السنوية. هل تريد المتابعة؟',
    WarningMsg: 'من فضلك تحقق من بيانات المدخلة',
    Englishtitle: 'العنوان  بالإنجليزية',
    Arabictitle: 'العنوان  بالعربية',
    // pattern options 
    Email: 'بريد إليكتروني',
    English_Only: 'اللغة الإنجليزية فقط',
    Arabic_Only: 'اللغة العربية فقط',
    Website_Link: 'رابط موقع',
    Alphabetic: 'حروف ',
    Phone: 'هاتف',
    Others: 'أخري',
    repeaterWarning: 'لا يمكنك سحب هذا العنصر لانه قد يؤثر في التقارير'
}
