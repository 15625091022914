export default function (app) {
    app.directive('transformToNumeric', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                isNumber: '@',
                precision: '@'
            },
            link: function (scope, element, attr, ngModelCtrl) {
                function transformToNumeric(text) {
                    if (text === undefined) {
                        var text = '';
                    }
                    var replaceRegx = /[^0-9]/g; // defualt allow only ints

                    if (scope.isNumber == 'float') {
                        replaceRegx = /[^0-9.]/g; //allow float
                    }

                    var matchRegx = /[0-9]+[.]?[0-9]*/; //defualt handel float without a precision limit
                    if (scope.isNumber == 'float' && scope.precision) {
                        matchRegx = new RegExp("[0-9]+[.]?[0-9]" + "{0," + scope.precision + "}");
                    }


                    var matches = text.replace(replaceRegx, ''); //remove all keys except numbers or .

                    // max size of a number. this a bug as we remove the last digit instead we should remove the last entred digit
                    if (matches.length >= 16) {
                        matches = matches.substr(0, 16);
                    }

                    matches = matches.match(matchRegx);
                    var transformedInput = '';

                    if (matches) {
                        transformedInput = matches[0];
                    }

                    if (transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                ngModelCtrl.$parsers.push(transformToNumeric);
            }
        }
    });
}


