export default {
    PAGE_TITLE: 'طلبات التحرير',
    ENTITY_NAME: 'اسم المؤسسة',
    SECTION_NAME: 'اسم القسم',
    REQUEST_DATE: 'تاريخ الطلب',
    REJECT: 'رفض',
    APPROVE: 'موافقة',
    SHOWALL: 'الكل',
    NEW: 'جديد',
    ACTIONMODELTITLE: 'موافقة أو رفض',
    COMMENT: 'ملاحظة',
    REJECT_BTN: 'رفض',
    APPROVE_BTN: 'موافق',
    RequestHaveAction: 'تم إغلاق هذا الطلب من قبل مستخدم آخر',
    EntityDeactivated: 'غير مسموح لك باتخاذ إجراء بشأن هذا الطلب ، حيث أن هذه المؤسسة غير نشطة',
    NOTSubmitedERROR: 'لا يُسمح لك بالموافقة على طلب التعديل لهذه المؤسسة، لأنه قيد المراجعة باستخدام بيانات غير صالحة',
    APPROVECONFRMATION: 'أنت على وشك الموافقة على طلب تعديل لمؤسسة قيد المراجعة ، سيتم تحرير هذه المهمة تلقائيًا من المستخدم. هل تريد المتابعة؟',
    Request: 'طلب',
    State: 'حالة',
    Take_Action: 'إتخاذ إجراء',

}