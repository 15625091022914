export default {
    ADD_PILLAR: 'Add new Pillar',
    PILLAR_ENGLISH_NAME: 'Pillar name (English)',
    PILLAR_ARABIC_NAME: 'Pillar name (Arabic)',

    PILLAR_TYPE: 'Pillar type',
    PLEASE_SELECT: 'Please select',
    REQUIRED_PILLAR_ARABIC_NAME: 'Pillar name (Arabic) is required.',
    REQUIRED_PILLAR_ENGLISH_NAME: 'Pillar name (English) is required.',
    REQUIRED_PILLAR_TYPE: 'Pillar Type is required.',


    EMPTY_LIST: 'List is empty',
    PILLARS: 'Pillars',
    UPDATE: 'Update',
    UPDATE_PILLAR: 'Update Pillar',

    PILLAR_EXIST: 'Pillar name is previously used',
    PILLAR_ADDED: 'Pillar Added Successfully',
    PILLAR_UPDATED: 'Pillar has been Updated Successfully',
    PILLAR_DELETED: 'Pillar has been Deleted Successfully',
    PILLAR_DELETE_QUES: 'Are you sure you want to delete the Pillar?',
    DELETE_HEADER: 'Delete',
}

