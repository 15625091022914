import { filter as _filter, includes as _includes, map as _map, find as _find, remove as _remove, concat as _concat, pick as _pick, omit as _omit } from 'lodash';

export default function (app) {

    app.filter("oneOfTaskStates", ["sectionStatusConstant", "taskTypesConstant", function (sectionStatusConstant, taskTypesConstant) {
        return function (tasks, states, isToDOSelected, isDoneSelected) {
            if (!states) {
                return tasks;
            }

            return _filter(tasks, (task) => {
                //special handling for sent back states
                if (task.taskType == taskTypesConstant.REVIEW && isDoneSelected && task.workflowInfo.status == sectionStatusConstant.SENT_BACK) {
                    return true
                }

                return _includes(states, task.workflowInfo.status)
            })
        };
    }]);

    app.controller("tasksController", ['$scope', '$state', 'tasksService', 'uiLoadingIndicators', 'taskTypesConstant', 'sectionStatusConstant', 'errorNotificationService', 'mapedSectionStatus', '$translate', 'routeConstants', '$rootScope', '$uibModal',
        function ($scope, $state, tasksService, uiLoadingIndicators, taskTypesConstant, sectionStatusConstant, errorNotificationService, mapedSectionStatus, $translate, routeConstants, $rootScope, $uibModal) {
            var vm = this;
            $translate(["TASKS.NEW", 'TASKS.EDITING', 'TASKS.REVIEWING', 'TASKS.REVIEWED', 'TASKS.SENT_BACK',
                'TASKS.CREATE', 'TASKS.REASSIGN', 'TASKS.REOPEN', 'TASKS.EDITOR', 'TASKS.REVIEWER',
                'TASKS.SAVE', 'TASKS.SUBMIT', 'TASKS.APPROVE', 'TASKS.SENDBACK', 'ACHIEVEMENT.NO_ACTIVE_CYCLE', 'TASKS.EDITED']).then(function (translations) {
                    vm.labels = {
                        NEW: translations['TASKS.NEW'],
                        EDITING: translations['TASKS.EDITING'],
                        EDITED: translations['TASKS.EDITED'],
                        REVIEWING: translations['TASKS.REVIEWING'],
                        REVIEWED: translations['TASKS.REVIEWED'],
                        SENT_BACK: translations['TASKS.SENT_BACK'],
                        CREATE: translations['TASKS.CREATE'],
                        REASSIGN: translations['TASKS.REASSIGN'],
                        REOPEN: translations['TASKS.REOPEN'],
                        EDITOR: translations['TASKS.EDITOR'],
                        REVIEWER: translations['TASKS.REVIEWER'],
                        SAVE: translations['TASKS.SAVE'],
                        SUBMIT: translations['TASKS.SUBMIT'],
                        APPROVE: translations['TASKS.APPROVE'],
                        SENDBACK: translations['TASKS.SENDBACK'],
                        No_ACTIVE_CYCLE: translations['ACHIEVEMENT.NO_ACTIVE_CYCLE']
                    }
                });
            vm.tasks = [];
            vm.taskTypesConstant = taskTypesConstant;
            vm.sectionStatusConstant = sectionStatusConstant;
            vm.textSearch = "";
            vm.isAddTaskBtnLoading = false;
            vm.isCycleEnabled = false;
            var _addTaskModalInstance = null;

			vm.isArabic = function () {
				return $translate.use() == 'ar' ? true : false;
			}

            var _getUserTasks = function () {
                uiLoadingIndicators.load('tasks', true);
                tasksService.getUserTasks().then(function(response) {
                    if (response && response.responseObject && response.responseCode == 1) {
                        var _data = response.responseObject;
                        if (_data && _data.tasks && _data.tasks.length > 0) {
                            vm.tasks = _map(_data.tasks, (task) => {

								if (task.titleAr && vm.isArabic()) {
									task.title = task.titleAr;
								}
								else {
									task.title = task.titleEn || '';
								}

                                return task;
                            });
                        }

                        if (_data){
                            vm.isCycleEnabled = _data.isCycleEnabled;
                        }
                    }
                    else if (response && response.responseCode == 17) {
                        $state.go(routeConstants.EntityDashboardState.State);
                        $rootScope.ShowError(vm.labels.No_ACTIVE_CYCLE);
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('tasks', false);
                },function(error) {
                    errorNotificationService.showErrorNotification(error);
                    uiLoadingIndicators.load('tasks', false);
                })
            }

            vm.getStatName = function (statType, taskType) {
                var labels = vm.labels;
                var _state = _getMapedSectionStatus(statType, taskType);
                if (_state == mapedSectionStatus.NEW) {
                    return labels.NEW;
                } else if (_state == mapedSectionStatus.EDITING) {
                    return labels.EDITING;
                } else if (_state == mapedSectionStatus.REVIEWING) {
                    return labels.REVIEWING;
                } else if (_state == mapedSectionStatus.EDITED) {
                    return labels.EDITED;
                }
                else if (_state == mapedSectionStatus.REVIEWED) {
                    return labels.REVIEWED;
                }
                else if (_state == mapedSectionStatus.SENT_BACK) {
                    return labels.SENT_BACK;
                }
			}

            vm.getTooltipName = function (statType, taskType) {
				var _state = _getMapedSectionStatus(statType, taskType);
				if (_state == mapedSectionStatus.NEW) {
					return $translate.instant('TASKS.EDIT');
				} else if (_state == mapedSectionStatus.EDITING) {
					return $translate.instant('TASKS.EDIT');
				} else if (_state == mapedSectionStatus.REVIEWING) {
					return $translate.instant('TASKS.EDIT');
				} else if (_state == mapedSectionStatus.EDITED) {
					return $translate.instant('TASKS.VIEW');
				}
				else if (_state == mapedSectionStatus.REVIEWED) {
					return $translate.instant('TASKS.VIEW');
				}
				else if (_state == mapedSectionStatus.SENT_BACK) {
					return $translate.instant('TASKS.EDIT');
				}
            }

            vm.getClassName = function (statType, taskType) {
                var _state = _getMapedSectionStatus(statType, taskType);
                if (_state == mapedSectionStatus.NEW) {
                    return 'fa-edit';
                } else if (_state == mapedSectionStatus.EDITING) {
                    return 'fa-edit';
                } else if (_state == mapedSectionStatus.REVIEWING) {
                    return 'fa-edit';
                } else if (_state == mapedSectionStatus.EDITED) {
                    return 'fa-eye';
                }
                else if (_state == mapedSectionStatus.REVIEWED) {
                    return 'fa-eye';
                }
                else if (_state == mapedSectionStatus.SENT_BACK) {
                    return 'fa-eye';
                }
            }
            

            vm.getStateTextColor = function (statType, taskType) {
                var _state = _getMapedSectionStatus(statType, taskType);
                if (_state == mapedSectionStatus.NEW) {
                    return 'text-primary'
                } else if (_state == mapedSectionStatus.EDITING) {
                    return 'text-warning'
                } else if (_state == mapedSectionStatus.REVIEWING) {
                    return 'text-warning'
                } else if (_state == mapedSectionStatus.EDITED) {
                    return 'text-success' //TODO get icon for Edited state
                }
                else if (_state == mapedSectionStatus.REVIEWED) {
                    return 'text-success'
                }
                else if (_state == mapedSectionStatus.SENT_BACK) {
                    return 'text-danger' //TODO get icon for Sent back state
                }
            }
            vm.getStatIcon = function (statType, taskType) {
                var _state = _getMapedSectionStatus(statType, taskType);
                if (_state == mapedSectionStatus.NEW) {
                    return 'fa-inbox text-primary'
                } else if (_state == mapedSectionStatus.EDITING) {
                    return 'fa-hourglass-half text-warning'
                } else if (_state == mapedSectionStatus.REVIEWING) {
                    return 'fa-eye text-warning'
                } else if (_state == mapedSectionStatus.EDITED) {
					return 'fa-check-circle text-success' //TODO get icon for Edited state
                }
                else if (_state == mapedSectionStatus.REVIEWED) {
                    return 'fa-check-circle text-success'
                }
                else if (_state == mapedSectionStatus.SENT_BACK) {
                    return 'fa-inbox text-danger' //TODO get icon for Sent back state
                }
            }

            vm.isHighlightedText = function (statType, taskType) {
                var _edit = vm.taskTypesConstant.EDIT;
                var _review = vm.taskTypesConstant.REVIEW;
                if (taskType == _edit && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
                    return true;
                }

                if (taskType == _review && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
                    return true;
                }
            }

            vm.getAddTaskForm = function () {
                vm.isAddTaskBtnLoading = true;

                tasksService.getAddTaskSections().then(function(response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        if (document.getElementsByClassName("modal").length === 0)
                        _addTaskModalInstance = $uibModal.open({
                            backdrop: true,
                            keyboard: true,
                            backdropClick: true,
                            template: require('../Views/addTaskModal.html'),
                            size: 'lg',
                            controllerAs: 'vm',
                            controller: ['$uibModalInstance', function ($uibModalInstance) {
                                var vm = this;
                                var _selectedSectionKey = 'selectedSection';
                                vm.showAddTaskModalSpinner = false;
                                vm.isShortFormLoaded = false;
                                vm.model = {};
                                vm.addTaskFormFields = [
                                    {
                                        type: 'select',
                                        key: _selectedSectionKey,
                                        templateOptions: {
                                            label: $translate.instant('TASKS.TASK_TYPE'),
                                            options: response.responseObject,
                                            labelProp: 'title',
                                            valueProp: 'id',
                                            required: true,
                                            notNull: true
                                        },
                                        controller: function ($scope) {
                                            $scope.onSelectedSectionChange = function () {
                                                var _model = $scope.model && $scope.model[_selectedSectionKey] ? $scope.model[_selectedSectionKey] : null;
                                                vm.onSelectedSectionChange(_model)
                                            };
                                        },
                                        ngModelElAttrs: {
                                            'ng-change': "onSelectedSectionChange()"
                                        }
                                    }
                                ];

                                vm.onSelectedSectionChange = function (selectedSectionID) {
                                    //disable submission until short form is loaded
                                    vm.isShortFormLoaded = false;
                                    tasksService.getAddTaskForm(selectedSectionID).then( function (resp) {
                                        if (resp && resp.responseCode == 1 && resp.responseObject) {
                                            vm.addTaskFormFields = _concat(vm.addTaskFormFields[0], resp.responseObject.formDescription);
                                            vm.model = _pick(vm.model, _selectedSectionKey);
                                            vm.isShortFormLoaded = true;
                                        } else {
                                            errorNotificationService.showErrorNotification(resp.responseCode);
                                        }
                                    },function(err) {
                                        vm.isShortFormLoaded = false;
                                        errorNotificationService.showErrorNotification(err);
                                    })
                                }


                                vm.ok = function () {
                                    vm.showAddTaskModalSpinner = true;
                                    var _selectedSection = vm.model[_selectedSectionKey];
                                    var _model = _omit(vm.model, _selectedSectionKey);
                                    
                                    tasksService.addTask(_selectedSection, _model).then(function (resp) {
                                        if (resp && resp.responseCode == 1 && resp.responseObject) {
											$uibModalInstance.dismiss();
											$state.go(routeConstants.TaskState.State, { taskID: resp.responseObject, taskType: taskTypesConstant.EDIT }, { reload: true });
                                        } else {
                                            errorNotificationService.showErrorNotification(resp.responseCode);
                                        }
                                        vm.showAddTaskModalSpinner = false;
                                    },function(err) {
                                        vm.showAddTaskModalSpinner = false;
                                        errorNotificationService.showErrorNotification(err);
                                    })
                                }

                                vm.cancel = function () {
                                    $uibModalInstance.dismiss();
                                }

                            }]
                        })

                    } else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                    vm.isAddTaskBtnLoading = false;
                },function(error) {
                    errorNotificationService.showErrorNotification(error);
                    vm.isAddTaskBtnLoading = false;
                })

            }

            var _getMapedSectionStatus = function (statType, taskType) {
                var _edit = vm.taskTypesConstant.EDIT;
                var _review = vm.taskTypesConstant.REVIEW
                if (statType == sectionStatusConstant.NEW) {
                    return mapedSectionStatus.NEW;
                } else if (taskType == _edit && statType == sectionStatusConstant.IN_PROGRESS) {
                    return mapedSectionStatus.EDITING;
                } else if (taskType == _review && statType == sectionStatusConstant.IN_PROGRESS) {
                    return mapedSectionStatus.REVIEWING;
                } else if (taskType == _edit && statType == sectionStatusConstant.SUBMITTED) {
                    return mapedSectionStatus.EDITED;
                }
                else if (taskType == _review && statType == sectionStatusConstant.APPROVED) {
                    return mapedSectionStatus.REVIEWED;
                }
                else if (taskType == _review && statType == sectionStatusConstant.SENT_BACK) {
                    return mapedSectionStatus.SENT_BACK;
                }
            }


            $scope.$on('$destroy', function () {
                if (_addTaskModalInstance && _addTaskModalInstance.close) {
                    _addTaskModalInstance.close();
                }
            });

            var _init = function () {
                _getUserTasks();
            }()

        }])
}