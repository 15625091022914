export default function (app) {
    app.controller('addLookupController', AddLookupController);

	AddLookupController.$inject = ["$rootScope", "lookupsDataService", "$uibModalInstance", "Params", "Notification","$translate"];
	function AddLookupController($rootScope, lookupsDataService, $uibModalInstance, Params, Notification, $translate) {
        var vm = this;
		$translate(["LOOKUPS.SYSTEMKEY_EXIST", "LOOKUPS.LOOKUP_ADDED"]).then(function (translations) {
            vm.labels = {
				SystemKeyExist: translations['LOOKUPS.SYSTEMKEY_EXIST'],
				LookupAdded: translations['LOOKUPS.LOOKUP_ADDED'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.responseCode = $rootScope.enums.ResponseCode;
        vm.Loading();
        vm.Lookup = null;
        vm.load = load;
        vm.cancel = cancel;
        vm.addLookup = addLookup;

        vm.load();

        function load() {
            vm.Loading();
        }
        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false});
        }

		function addLookup(lookup) {
            vm.Loading();
			lookupsDataService.AddLookup(lookup).then(addLookupSuccess, $rootScope.methodPublicError);
        }

		function addLookupSuccess(res) {
            if (res.responseCode == vm.responseCode.Success) {
				vm.Loading();
				Notification.success({ message: vm.labels.LookupAdded });
				$uibModalInstance.close({ Close: true, Ok: true });
			} else if (res.responseCode == vm.responseCode.LookupSystemKeyExist) {
				vm.Loading();
				Notification.warning({ message: vm.labels.SystemKeyExist });
			} else {
                $rootScope.methodPublicError();
                $uibModalInstance.close({ Close: true, Ok: false });
            }
        }
    }
}