export default function (app) {
    app.controller('predefinedLookupController', PredefinedLookupController);

    PredefinedLookupController.$inject = ["$scope", "$rootScope", "lookupsDataService", "$uibModal", "Notification", "$translate", "$state", "$stateParams"];
    function PredefinedLookupController($scope, $rootScope, lookupsDataService, $uibModal, Notification, $translate, $state, $stateParams) {
        var vm = this;
		$translate(["LOOKUPS.ENTRY_DELETED", "LOOKUPS.ENTRY_DELETE_QUES", "LOOKUPS.DELETE_HEADER", "COMMON.ACTIVE_CYCLE"]).then(function (translations) {
			vm.labels = {
				EntryDeleted: translations['LOOKUPS.ENTRY_DELETED'],				
			}
        });
		vm.lookupID = $stateParams.lookupID;
        vm.Loading = $rootScope.Loading;
		vm.ResponseCode = $rootScope.enums.ResponseCode;
		vm.load = load;
		vm.getLookupEntries = getLookupEntries;

		vm.lookupName = "";
		vm.lookupEntries = [];
		vm.lookupsFilter = lookupsFilter;
		vm.back = back;					
        vm.load();

        function load() {
			vm.getLookupEntries();
        }

		function getLookupEntries() {
            vm.Loading();
            lookupsDataService.GetPredefinedLookupEntries(vm.lookupID).then(getLookupEntriesSuccess, $rootScope.methodPublicError);
		}

		function getLookupEntriesSuccess(res) {
			if (res.responseCode === vm.ResponseCode.Success) {
                vm.lookupEntries = res.responseObject;	
                vm.lookupName = res.lookupName;

			}
			else {
				$rootScope.methodPublicError();
			}

			vm.Loading();
		}

		function lookupsFilter(item) {
			if (vm.search) {
				return item.ValueEn.toLowerCase().includes(vm.search.toLowerCase()) || item.ValueAr.includes(vm.search);
			}
			else
				return true;
		}


		
		function back() {
			$state.go("MBRGI.Lookups");
		}
        $scope.sortableOptions = {
            disabled: vm.IsDisableMode,
            placeholder: vm.IsDisableMode ? "" : "ui-state-highlight",
            stop: function (e, ui) {
                
                vm.Loading();
                debugger;
                lookupsDataService.UpdatePredifinedLookUpEntriesOrder(vm.lookupID,vm.lookupEntries).then(function (response) {
                    vm.Loading();
                    if (response) {

                    } else {
                        $rootScope.methodPublicError();
                    }
                })
            }
        };
	
       
    }
}