'use strict';
export default function (app) {
    app.factory('authInterceptorService', function ($window, $q) {
        var _getToken = function () {
            //TODO: Decrypt token before return
            return $window.localStorage.getItem('token') || "";
        };

        var _setToken = function (token) {
            //TODO: Encrypt token before set
            $window.localStorage.setItem('token', token);
        };

        var _deleteToken = function () {
            $window.localStorage.removeItem('token');
        };

        var _request = function (config) {
            config.headers = config.headers || {};
            //TODO: Check the storage and choose suitable and more secure
            var _token = _getToken();
            if (_token) {
                //config.headers.Authorization = 'Bearer ' + _token;
            }
            return config || $q.when(config);
        };

        var _response = function (response) {
            return response || $q.when(response);
        };


        var _responseError = function (rejection) {
            if (rejection.status === 401) {
                //TODO save current user url 
                //TODO: check refresh Token if so use it else call logout method then redirect user to login page.
                _deleteToken();
            }
            return $q.reject(rejection);
        }

        return {
            request: _request,
            response: _response,
            responseError: _responseError
        }
    });

    // Register the previously created authInterceptorService.
    app.config(function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptorService');
    });
}
