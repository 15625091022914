export default function (app) {
    app.controller('restoreCountryController', RestoreCountryController);

    RestoreCountryController.$inject = ["$scope", "$rootScope", "countryDataService", "$uibModal", "Notification", "$translate","$state"];
    function RestoreCountryController($scope, $rootScope, countryDataService, $uibModal, Notification, $translate, $state) {
        var vm = this;
        $translate(["COUNTRIES.COUNTRY_RESTORED",]).then(function (translations) {
            vm.labels = {
                COUNTRY_RESTORED: translations['COUNTRIES.COUNTRY_RESTORED'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.country = {};
        vm.Countries = [];
        vm.load = load;
        vm.getDeletedCountries = getDeletedCountries;
        vm.Modal = null;
        vm.countriesFilter = countriesFilter;
        vm.currentCycle = null;
        vm.deletedCount = 0;
        vm.load();

        function load() {
            vm.getDeletedCountries();
        }

        function getDeletedCountries() {
            vm.Loading();
            countryDataService.getDeletedCountries().then(getDeletedCountriesSuccess, $rootScope.methodPublicError);
        }
        function countriesFilter(item) {
            if (vm.search) {
                return item.NameEn.toLowerCase().includes(vm.search.toLowerCase()) || item.NameAr.includes(vm.search);
            }
            else
                return true;
        }
        function getDeletedCountriesSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.Countries = res.responseObject;
                vm.currentCycle = res.currentCycle;
                vm.Loading();
                if (vm.Countries == null || vm.Countries == 0) {
                    vm.back();
                }
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        vm.back = function () {
            $state.transitionTo('MBRGI.Countries');
        }
        vm.restore = function (ID) {
            vm.Loading();
            countryDataService.restoreDeletedCountries(ID).then(restoreDeletedCountrySuccess, $rootScope.methodPublicError);
        }
        function restoreDeletedCountrySuccess(res) {
            vm.Loading();
            if (res.responseCode === vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.COUNTRY_RESTORED });
                $state.reload();
            }
        }
        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
        });
        ///The below method is for activation and deactivation (no need for it in this phase)
        //function ChangeActiveStatusSuccess(res) {
        //    if (res.responseCode == vm.ResponseCode.Activated) {
        //        Notification.success({ message: vm.labels.countryActivated });

        //    } else if (res.responseCode == vm.ResponseCode.DeActivated) {
        //        Notification.success({ message: vm.labels.CountryDeactivated });
        //    }
        //    vm.Loading();
        //    vm.load();
        //}
    }
}