export default function (app) {
    app.directive('min', function () {
        return {
            require: '?ngModel',
            link: function (scope, elm, attrs, ctrl) {
                if (!ctrl) return;

                ctrl.$validators.min = function (modelValue, viewValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        // consider empty models to be valid
                        return true;
                    }

                    if (modelValue >= attrs.min)
                        return true;
                    // it is invalid
                    return false;
                };
            }
        };
    });
}