import { filter as _filter,remove as _remove, map as _map } from 'lodash';
export default function (app) {

    app.filter("requestStatus", ["statusConstant", function (statusConstant) {
        return function (achievements, StatusID) {
            if (!StatusID) {
                return achievements;
            }

            return _filter(achievements, (item) => {
                if (StatusID == statusConstant.Approved && item.StatusID == statusConstant.Approved) {
                    return true;
                }
                else if (StatusID == statusConstant.SentBack && item.StatusID == statusConstant.SentBack ) {
                    return true
                }
                else if (StatusID == statusConstant.New && item.StatusID == statusConstant.New) {
                    return true
                }
              
                return false;
            })
        };
    }]);

    app.controller('requestController', RequestController);

    RequestController.$inject = ['$scope',"$rootScope", "requestDataService", "Notification", "$translate", "statusConstant", 'actionsTypesConstant', '$uibModal', 'successrNotificationService',"errorNotificationService"];
    function RequestController($scope,$rootScope, requestDataService, Notification, $translate, statusConstant, actionsTypesConstant, $uibModal, successrNotificationService, errorNotificationService) {
        var vm = this;
        var _viewRequestModal = null;
        $translate(["REQUESTS.NEW", "REQUESTS.APPROVE","REQUESTS.REJECT"]).then(function (translations) {
            vm.labels = {
                New: translations['REQUESTS.NEW'],
                Approved: translations['REQUESTS.APPROVE'],
                Reject: translations['REQUESTS.REJECT'],

            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.activeCycleId = 0;
        vm.filterConstants = statusConstant;
        vm.load = load;
        vm.Requests = [];
        vm.cycles = [];  
        vm.thumbPath = "";
        vm.Loading = $rootScope.Loading;
        vm.load();
       // vm.EnableActions=false;
        vm.search = '';
        vm.actionsConstant = actionsTypesConstant;
   
        function load() {
            vm.Loading();
            _getCyclesList();      
        }
        function GetAllRequests() {
            vm.Loading();
            requestDataService.GetAllRequests(vm.activeCycleId).then(function (response) {
                vm.Loading();
                if (response.responseCode === vm.ResponseCode.Success) {
                    vm.Requests = response.responseObject;
                    vm.thumbPath = response.thumbPath;
                   // vm.EnableActions = response.responseObject.EnableActions;
                }
                else {
                    $rootScope.methodPublicError();
                }
            });
        }

       
        function _getCyclesList() { 
            requestDataService.getCyclesList().then(function (response) {
                vm.Loading();
              
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                  
                    if (_data.length > 0) {
                        vm.cycles = _map(_data, (cycle) => {
                            if ($rootScope.currentLang == 'en') {
                                cycle.name = cycle.NameEn;
                            }
                            else {
                                cycle.name = cycle.NameAr;
                            }
                            if (cycle.ActiveCycle) {
                                vm.activeCycleId = cycle.ID;
                            }
                            return cycle;
                        });
                        if (vm.activeCycleId == 0) {
                            vm.cycles = $filter('orderBy')(vm.cycles, 'CreationDate', 'reverse');
                            vm.activeCycleId = vm.cycles[0].ID;
                        }
                        GetAllRequests();
                    }
                } else {

                    errorNotificationService.showErrorNotification(response.responseCode);
                }
             

            }, function (error) {
               

            })
        }          

        vm.CycleDropDownChange = function () 
        {
            GetAllRequests();
        }
        vm.searchFilter = function (item) {
            if (vm.search) {
                return item.EntityTitle.toLowerCase().includes(vm.search.toLowerCase());
            }
            else
                return true;
        }

       
        vm.getActionName = function (actionID) {
            if (actionID == vm.filterConstants.New) {
                return vm.labels.New;
            } else if (actionID == vm.filterConstants.Approved) {
                return vm.labels.Approved;
            } else if (actionID == vm.filterConstants.SentBack) {
                return vm.labels.Reject;
            }
            
        }


        vm.ActionBtnClick = function (Request) {
            var loading = vm.loading;
            var d = vm.thumbPath;
            _viewRequestModal = $uibModal.open({
                backdrop: true,
                keyboard: true,
                backdropClick: true,
                template: require('../Views/RequestActionModel.html'),
                size: 'lg',
                controllerAs: 'vm',
                controller: ['$uibModalInstance', function ($uibModalInstance) {
                    var vm = this;
                    vm.actionsConstant = actionsTypesConstant;
                    vm.ResponseCode = $rootScope.enums.ResponseCode;
                    vm.Comment = "";
                    vm.labels;
                    vm.thumbPath = d;
                    vm.Request = Request;
                    vm.ConfrimMSG = "";
                    vm.ModalSpinner = false;
                    vm.rejectModalSpinner = false;
                    vm.ShowConfirmMSG = false;
                    $translate(["REQUESTS.NOTSubmitedERROR", "REQUESTS.APPROVECONFRMATION","REQUESTS.Request"]).then(function (translations) {
                        vm.labels = {
                            NOTSubmitedERROR: translations['REQUESTS.NOTSubmitedERROR'],
                            ApprovedMassage: translations['REQUESTS.APPROVECONFRMATION'],
                            Request: translations['REQUESTS.Request']
                        }
                    });
                    vm.ApproveClick = function () {
                        vm.ModalSpinner = true;
                        requestDataService.SubmitSectionRequestAction(Request.RequestID, vm.Comment, vm.actionsConstant.APPROVE,true).then(function (response) {
                            vm.ModalSpinner = false;
                            if (response.responseCode === vm.ResponseCode.Success) {
                                successrNotificationService.showSuccessApprovingSectionNotfication(vm.labels.Request);
                                GetAllRequests();
                                $uibModalInstance.close();
                            }
                            else if (response.responseCode === vm.ResponseCode.RequestHaveAction) {
                                GetAllRequests();
                                errorNotificationService.showErrorNotification(response.responseCode);
                                $uibModalInstance.close();
                            }
                            else {
                                $rootScope.methodPublicError();

                            }
                        });
                    }
                   // vm.loading = loading;
                    vm.TackAction = function ( actionId)
                    {
                        if (actionId == vm.actionsConstant.APPROVE)
                            vm.ModalSpinner = true;
                        else
                            vm.rejectModalSpinner = true;
                        requestDataService.SubmitSectionRequestAction(Request.RequestID, vm.Comment, actionId).then(function (response) {
                            vm.ModalSpinner = false;
                            vm.rejectModalSpinner = false;
                            if (response.responseCode === vm.ResponseCode.Success) {
                                if (actionId == vm.actionsConstant.APPROVE) {
                                    successrNotificationService.showSuccessApprovingSectionNotfication(vm.labels.Request);
                                } else if (actionId == vm.actionsConstant.SENDBACK) {
                                    successrNotificationService.showSuccessRejectSectionNotfication(vm.labels.Request);
                                }
                                GetAllRequests();
                                $uibModalInstance.close();
                            }
                            else if (response.responseCode === vm.ResponseCode.EntityDeactivated) {
                                errorNotificationService.showErrorNotification(response.responseCode);
                                $uibModalInstance.close();
                            }
                            else if (response.responseCode === vm.ResponseCode.RequestHaveAction) {
                                GetAllRequests();
                                errorNotificationService.showErrorNotification(response.responseCode);
                                $uibModalInstance.close();
                            }
                            else if (response.responseCode === vm.ResponseCode.IsAlreadyPicked) {
                                vm.ShowConfirmMSG = true;
                                vm.ConfrimMSG = vm.labels.ApprovedMassage;
                            }
                            else if (response.responseCode === vm.ResponseCode.notActivitiesSubmitedRequest) {
                                errorNotificationService.showErrorNotification(response.responseCode);
                                $uibModalInstance.close();                              
                                
                            }
                            else {
                                $rootScope.methodPublicError();
                              
                            }
                        });
                      
                    }
                   

                    vm.cancel = function () {
                        $uibModalInstance.close();
                    }

                }]
            })
        }

        $scope.$on('$destroy', function () {
            if (_viewRequestModal && _viewRequestModal.close) {
                _viewRequestModal.close();
                _viewRequestModal = null;
            }

          

        });

    }
}