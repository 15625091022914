export default function (app) {
    app.factory('cycleDataService', CycleDataService);

    CycleDataService.$inject = ['apiBase', 'ApiPath'];
    function CycleDataService(apiBase, ApiPath) {
        var entity = "AnnualCycle";
        var controllerUrl = ApiPath + entity + "/";
        var entityController = ApiPath + "Entity" + "/"
        function GetAllCycles() {
            return apiBase.Get(controllerUrl + "GetAllCycles", {}, null);
        }
        function CreateCycle(data) {
            return apiBase.Post(controllerUrl + "CreateCycle", {}, data);
        }
        function UpdateCycle(data) {
            return apiBase.Post(controllerUrl + "UpdateCycle", {},data);
        }
        function _getAllEntitiesCardsInfo() {
            return apiBase.Get(entityController + "GetAllEntitiesCardsInfo", {}, null);
        }
    return {
            GetAllCycles: GetAllCycles,
            CreateCycle: CreateCycle,
        UpdateCycle: UpdateCycle,
        getAllEntitiesCardsInfo: _getAllEntitiesCardsInfo,
        }
    }
}