import sectionStatusConstant from './app.sectionStatus.constant';
import uiLoadingIndicators from './uiLoadingIndicators';
import usersRolesConstant from './app.usersRoles.constant';
import multiFileUploadService from './app.multiFileUpload.service';
import taskTypesConstant from './app.taskTypes.constant';
import sectionTypesConstant from './app.sectionTypes.constant';
import actionsTypesConstant from './app.actionsTypes.constant';
import errorNotificationService from './app.errorNotfication.service';
import exportFileService from './app.exportFile.service';
import mapedSectionStatus from './app.mapedSectionStatus.constant';
import successrNotificationService from './app.successNotfication.service';
import changeLanguageHook from './app.changeLanguageHook';
import statusConstant from './app.status.constant';

export default function (app) {
    sectionStatusConstant(app);
    uiLoadingIndicators(app);
    usersRolesConstant(app);
	multiFileUploadService(app);
    taskTypesConstant(app);
    sectionTypesConstant(app);
    actionsTypesConstant(app);
    errorNotificationService(app);
    exportFileService(app);
    mapedSectionStatus(app);
    successrNotificationService(app);
    changeLanguageHook(app);
    statusConstant(app);
}
