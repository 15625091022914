export default function (formlyBuilder) {

    formlyBuilder.controller('numericInputConfigController', ['$scope', function ($scope) {
        
        $scope.Step = "";
        $scope.SetformatValue = function () {
            if ($scope.fieldConfigCtrl.formBuilderField.templateOptions && $scope.fieldConfigCtrl.formBuilderField.templateOptions.step) {
              
                
                if ($scope.fieldConfigCtrl.formBuilderField.templateOptions.step == 1) {
                    $scope.Step = "1";
                }
                else {
                    $scope.Step = "any";
                }
            }
        }

        $scope.changeStepValue = function () {
          //  
           if ($scope.Step == "1") {
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.step = { ValueEn: 1 };

                $scope.fieldConfigCtrl.formBuilderField.templateOptions.step = 1;
            }
            else {
                
               $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.step = { ValueEn: $scope.Step };

               $scope.fieldConfigCtrl.formBuilderField.templateOptions.step = $scope.Step;

            }

        }
        $scope.SetformatValue();
    }]);

}





