"use strict";
import 'angular-drag-and-drop-lists';
import apiCheck from 'api-check';

var formBuilder = angular.module('formBuilder', ['dndLists']);
/**
 *  This directive is responsible for adding drag and drop functionality and sending the
 *  builderDropzone` the required object in order to identify the dropped item. 
 */
formBuilder.directive('builderToolboxFieldType', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        scope: {
            type: '@',
        },
        controller: ['$scope', function ($scope) {
            // check if it was defined.  If not - set a default
            $scope.item = $scope.item || //{ type: $scope.type };
                {
                //type: $scope.type ,
                data: { Type: $scope.type }
                };
        
        }],
        compile: function (tElement, attrs, transclude) {
            tElement.removeAttr('builder-toolbox-field-type');
            tElement.attr('dnd-draggable', 'item');
            tElement.attr('dnd-effect-allowed', 'move');
            tElement.attr('dnd-type', 'type');
            return {
                pre: function preLink(scope, iElement, iAttrs, controller) { },
                post: function postLink(scope, iElement, iAttrs, controller) {
                    $compile(iElement)(scope);
                }
            };
        },

    };
}]);

formBuilder.directive('builderDropzone', ['$compile', '$timeout', 'builderConfig', function ($compile, $timeout, builderConfig) {
    return {
        restrict: 'AE',
        scope: {
            formBuilderFields: '=',
            shouldUpdate: '&?',
            onDragOver: '&?'
        },
        template: '<builder-dropzone-field ng-repeat="formBuilderField in formBuilderFields" form-builder-field="formBuilderField" dnd-draggable="formBuilderField" dnd-type="formBuilderField.data.Type" dnd-moved="onItemRemoved($index, formBuilderField)" delete-field="onItemRemoved($index, formBuilderField)" dnd-effect-allowed="move"  />',
        controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
            // check if it was defined.  If not - set a default
            $scope.formBuilderFields = $scope.formBuilderFields || [];
            var _builderFieldDropListener = builderConfig.getBuilderFieldDropListener() || null;
            var _builderFieldRemovelistener = builderConfig.getBuilderFieldRemovelistener() || null;
            //  the added form field is intitialized by undefined 
            //  and the `builder-dropzone-field` is resposible for updating this value
            //  runs a callback to determine whether an item can be added to the drop zone. If it returns true then the item will be added
            $scope.onItemDropped = function (event, index, item, external, type) {
                var canAddItem = true;
                if ($attrs["shouldUpdate"] && typeof $scope.shouldUpdate == "function")
                    canAddItem = $scope.shouldUpdate({ children: $scope.formBuilderFields, nextComponent: item });
                if (canAddItem) {
                    /*
                    check if we have drop event callback and if so defer its execution 
                    after the item is added to the dropzoon (when onItemDropped return item)
                    */
                    if (_builderFieldDropListener) {
                        $timeout(function () {
                            _builderFieldDropListener(item);
                        }, 0);
                    }
                    return item;
                } else {
                    // stop Drop event propagation to higher drop zones
                    event.stopImmediatePropagation();
                    //stop drop event 
                    return false;
                }
            };



            /** 
             *this function is invoked when the `builder-dropzone-field` is destroyed
             *the `builder-dropzone-field` is mainly destoryed when the item (field) is removed from the `formBuilderFields`
             */
            $scope.onItemRemoved = function (index, item) {

                $scope.formBuilderFields.splice(index, 1);

                if (_builderFieldRemovelistener)
                    _builderFieldRemovelistener(item);
            }



        }],
        compile: function (tElement, attrs, transclude) {
            tElement.removeAttr('builder-dropzone');
            tElement.attr('dnd-list', 'formBuilderFields');
            tElement.attr('dnd-drop', 'onItemDropped(event, index, item, external, type)');
            tElement.attr('dnd-dragover', 'dragOver(event, index, type)')

            return {
                pre: function preLink(scope, iElement, iAttrs, controller) {
                    // a callback that will be invoked when user hover over a drop zone with dragged item
                    // it will invoke a user callback if it returns a value it will be inserted inside a placeholder li element.
                    scope.dragOver = function (event, index, type) {

                        var result = iAttrs["onDragOver"] && typeof scope.onDragOver == "function" ? scope.onDragOver({ children: scope.formBuilderFields, nextType: type }) : undefined;
                        var placeholder = iElement.find('.dndPlaceholder');

                        if (result == undefined) {
                            placeholder.empty();
                        }
                        else if (placeholder && placeholder.children().length == 0 && result) {
                            placeholder.append(result);
                        }

                        if (placeholder.children().length > 1)
                            placeholder.empty();

                        return true;
                    }

                },
                post: function postLink(scope, iElement, iAttrs, controller) {
                    $compile(iElement)(scope);
                }
            };
        },
    };
}]);

/**
 * This directive is responsible for rendering the droped field template  and
 *  add drag and drop functionality to it
 */
formBuilder.directive('builderDropzoneField', ['$compile', 'builderConfig', '$q', '$templateCache', '$http', '$controller', function ($compile, builderConfig, $q, $templateCache, $http, $controller) {
    var check = apiCheck();

    function getFieldTemplate(component) {
        return getTemplate(component.template || component.templateUrl, !component.template);
    }

    //Takes a function injecting controller scope into it then invoke it. Simulating runtime directives
    function invokeController(controller, scope) {
        check.throw([check.oneOfType([check.func, check.array]), check.object], arguments, { prefix: 'faild to invoke field controller this may happen if you are not passing a function', suffix: "check setType function" });
        $controller(controller, { $scope: scope })
    }

    //Takes a function injecting link function arguments into it then invoke it. Simulating runtime directives
    function invokeLink(fieldConfig, context, args) {
        var linkFunc = fieldConfig.link;
        return function () {
            if (!linkFunc)
                return;
            else if (typeof linkFunc === "function")
                linkFunc.apply(context, args);
            else
                throw new Error('Link property must be a function check setType');
        }
    }


    function freezObjectProperty(object, propertyName) {
        Object.defineProperty(object, propertyName, {
            value: object[propertyName],
            writable: false,
            enumerable: true,
            configurable: true
        });
    }

    //resolvers html template and return a promise
    function getTemplate(template, isUrl) {
        var templatePromise = $q.when(template);
        if (!isUrl)
            return templatePromise;
        else {
            var httpOptions = { cache: $templateCache };
            return templatePromise.then(function (url) {
                return $http.get(url, httpOptions);
            }).then(function (response) {
                return response.data;
            })
                .catch(function (error) {
                    throw new Error('can not load template url ' + template + ' ' + error);
                });
        }
    }

    //search dom tree for a element (transclude) then replaces that element with a new template then return a new html
    function doTransclusion(wrapper, template) {
        var superWrapper = angular.element('<a></a>');
        superWrapper.append(wrapper);
        var transcludeEl = superWrapper.find('transclude');
        if (!transcludeEl.length)
            console.warn("can not find transclude tag check your wrapper template " + wrapper);
        transcludeEl.replaceWith(template);
        return superWrapper.html();
    }

    //Takes a set of html templates then wrap them over the original html template then return a promise
    function transcludeInWrappers(fieldConfig) {
        var wrappers = fieldConfig.wrapper;
        return function (template) {
            if (!wrappers || wrappers.length == 0)
                return $q.when(template); // if no wrappers are provided just return the orginal template
            else if (Array.isArray(wrappers)) {
                //try to resolve html templates will return array of promises
                var templatesPromises = wrappers.map(function (wrapper) {
                    return getTemplate(wrapper, true);
                });
                return $q.all(templatesPromises).then(function (wrappersTemplates) {
                    wrappersTemplates.reverse();
                    var totalWrapper = wrappersTemplates.shift();
                    wrappersTemplates.forEach(function (wrapper) {
                        totalWrapper = doTransclusion(totalWrapper, wrapper);
                    });
                    return doTransclusion(totalWrapper, template);
                });
            }
            else {
                throw new Error("wrapper must be of type array check setType")
            }
        }
    }

    return {
        restrict: 'AE',
        scope: {
            formBuilderField: '=',
            deleteField: '&'
        },
        link: function (scope, elem, attr) {
            //The field name should be immutable to disallow extrnal code from modifying it but currently this behaviour is not implmented
            //freezObjectProperty(scope.item, "name");
           
            var fieldConfig = builderConfig.getType(scope.formBuilderField.data.Type);
            var args = arguments;
            var that = this;
            //link template with scope and invoke custome link function
            getFieldTemplate(fieldConfig)
                .then(transcludeInWrappers(fieldConfig))
                .then(function (templateString) {
                    var compieldHtml = $compile(templateString)(scope);
                    elem.append(compieldHtml);
                }).then(invokeLink(fieldConfig, that, args))
                .catch(function (error) {
                    throw new Error('Faild to set template for this field ' + error + " " + JSON.stringify(fieldConfig));
                });
        },
        controller: ["$scope", function ($scope) {
           
            var fieldConfig = builderConfig.getType($scope.formBuilderField.data.Type);

            $scope.transformFormField = function (formBuilderField) {
                var transformedComponent = fieldConfig.transformFormField(formBuilderField);

                var transformedFormBuilderField = transformedComponent && transformedComponent.formBuilderField ? transformedComponent.formBuilderField : {};
                Object.assign($scope.formBuilderField, transformedFormBuilderField);

            }
            if (fieldConfig.controller)
                invokeController(fieldConfig.controller, $scope);
            $scope.transformFormField($scope.formBuilderField);
        }],
    };

}]);

formBuilder.directive('builderFieldConfig', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        scope: {
            name: '@',
        },
        controller: ["$scope", function ($scope) {
            // check if it was defined.  If not - set a default
            $scope.item = $scope.item || {
                //type: $scope.type,
                data: { Type: $scope.type } };
        }],
        link: function (scope, elem, attr) {

        }

    };
}]);

//register and get application components.
formBuilder.factory('builderConfig', function () {
    var typeMap = {}; // hashmap of components 
    var check = apiCheck();
    var _onBuilderFieldDrop = null;
    var _onBuilderFieldRemove = null;

    function checkType(component) {
        if (angular.isUndefined(component.data.Type))
            throw new Error('field data.Type must be defined check setType of ' + JSON.stringify(component));
        else if (angular.isUndefined(component.template) && angular.isUndefined(component.templateUrl))
            throw new Error('field template or templateUrl must be defined check setType of ' + JSON.stringify(component));
        else if (typeof component.transformFormField != "function")
            throw new Error("field must have a transformFormField function check setType of " + JSON.stringify(component));
    }

    return {
        /*
        Takes directive definition object with extra properties and saves it to a hashmap. 
        The object properties will be validated then registered, 
        keys in the hashmap are lower case. If key exists its value will be overwritten.
         */
        setType: function (component) {
            check.throw([check.object], arguments, { prefix: 'faild to set new type this may happen if you are not passing an object', suffix: "check setType function" });
            checkType(component);
            if (typeMap[component.data.Type.toLowerCase()])
                console.warn(component.data.Type + " is registered before it will be overwritten");
            typeMap[component.data.Type.toLowerCase()] = component;
        },
        /*
        Takes component tyoe and gets it from hashmap. type is case insensitive .
         */
        getType: function (type) {
            check.throw([check.string], arguments, { prefix: 'faild to get type this may happen if you are not passing an string', suffix: "check getType function" });
            var lowerCaseType = type.toLowerCase();
            if (typeMap[lowerCaseType])
                return typeMap[lowerCaseType];
            else {
                throw new Error('The field ' + type + ' is not registered');
            }
        },

        /*
        register callback that will fire when form Builder Field is dropped 
        */
        setBuilderFieldDropListener: function (listener) {
            if (typeof listener == "function") {
                _onBuilderFieldDrop = listener;
            } else {
                throw Error("requires a listener function you passed " + listener);
            }
        },
        /*
        register callback that will fire when form Builder Field is removed 
        */
        setBuilderFieldRemoveListener: function (listener) {
            if (typeof listener == "function") {
                _onBuilderFieldRemove = listener;
            }
            else {
                throw Error("requires a listener function you passed " + listener);
            }
        },

        getBuilderFieldDropListener: function () {
            if (_onBuilderFieldDrop)
                return _onBuilderFieldDrop;
            else
                throw Error("Builder Field Drop Listener is not registered");
        },

        getBuilderFieldRemovelistener: function () {
            if (_onBuilderFieldDrop)
                return _onBuilderFieldRemove;
            else
                throw Error("Builder Field remove Listener is not registered");
        }
    };
});