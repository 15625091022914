export default function (app) {
    app.controller('updatePasswordController', UpdatePasswordController);

    UpdatePasswordController.$inject = ["$rootScope", "userProfileDataService", "Notification", "$state","$translate"];
    function UpdatePasswordController($rootScope, userProfileService, Notification, $state, $translate) {
        var vm = this;
        $translate(["COMMON.CHANGED_PASSWORD_NOTIFICATION", "COMMON.OLD_PASSWORD_WRONG_NOTIFICATION","COMMON.COULDNOT_UPDATE_PASSWORD_NOTIFICATION"]).then(function (translations) {
            vm.labels = {
                PasswordUpdated: translations['COMMON.CHANGED_PASSWORD_NOTIFICATION'],
                WrongOldPassword: translations['COMMON.OLD_PASSWORD_WRONG_NOTIFICATION'],
                CouldnotUpdatePassword: translations['COMMON.COULDNOT_UPDATE_PASSWORD_NOTIFICATION'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.changePasswordModal = {};
        vm.changePassword = changePassword;
        vm.load = load;

        vm.load();

        function load() {
            vm.Loading();
                vm.changePasswordModal.UserID = $rootScope.GetCurrentUser().ID;
                vm.Loading();
        }
        function changePassword(changePasswordModal) {
            vm.Loading();
            userProfileService.ChangePassword(changePasswordModal).then(changePasswordSuccess, $rootScope.methodPublicError);
        }
        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false });
        }

        function changePasswordSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success && res.responseObject === true) {
                Notification.success({ message: vm.labels.PasswordUpdated });
                if ($rootScope.GetCurrentUser().CurrentRole === "MBRGI Viewer")
                    $state.transitionTo('MBRGI.MBRGIAnnualAchievements');

             else if ($rootScope.GetCurrentUser().RoleSection == 'MBRGI') {
                 $state.transitionTo('MBRGI.Dashboard');
             }
             else {
                 $state.transitionTo('Entity.Dashboard');
             }
         }
         else if (res.responseCode === vm.ResponseCode.WrongPassword && res.responseObject === false)
            {
                Notification.error({ message: vm.labels.WrongOldPassword });
         }
         else if (res.responseCode === vm.ResponseCode.NoError && res.responseObject === false){
                Notification.error({ message: vm.labels.CouldnotUpdatePassword});
            }
            vm.Loading();
        }
        vm.Cancel = function () {
            var user = $rootScope.GetCurrentUser();
            if (user.CurrentRole === "MBRGI Viewer")
                $state.transitionTo('MBRGI.MBRGIAnnualAchievements');
            else if (user.RoleSection === "MBRGI")
                $state.transitionTo('MBRGI.Dashboard');
            else if (user.RoleSection === "Entity")
                $state.transitionTo('Entity.Dashboard');

        }

      
    }
}