export default function (app) {
	app.factory("multiFileUploadService", ["Upload", "ApiPath", "$cookies", function (Upload, ApiPath, $cookies) {
		var _url = ApiPath + "MultifileUpload/Upload";

		var _upload = function (files) {
			return Upload.upload({
				url: _url,
				method: "POST",
				data: {
					file: files
                }
    //            ,
				//headers: { Authorization: $cookies.token ? $cookies.token : $cookies.get("token") }
			}) 
		}


		return {
			upload: _upload
		}


	}])
}