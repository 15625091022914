export default function (app) {
    app.controller('updateSectionController', UpdateSectionController);

    UpdateSectionController.$inject = ['$scope', '$state', '$stateParams', "$rootScope", "manageSectionDataService", "Notification", "$translate", "statusConstant", 'actionsTypesConstant', '$uibModal', 'successrNotificationService', "errorNotificationService"];
    function UpdateSectionController($scope, $state, $stateParams, $rootScope, manageSectionDataService, Notification, $translate, statusConstant, actionsTypesConstant, $uibModal, successrNotificationService, errorNotificationService) {
       
        var vm = this;
        vm.load = load;
        vm.Loading = $rootScope.Loading;
        vm.sectionID = $stateParams.sectionID ? $stateParams.sectionID : 0;
        vm.ViewMode = false;
        vm.SectionsData;
       
        vm.isAllRSelected = false;
        vm.isAllVSelected = false;
        vm.disableSysKeyAndCheck = true;
        $translate(["MANAGE_SECTIONS.Save_MSG", 'MANAGE_SECTIONS.SectionKey_MSG', 'MANAGE_SECTIONS.updateSection', 'MANAGE_SECTIONS.DuplicateSectionEnName', 'MANAGE_SECTIONS.DuplicateSectionArName']).then(function (translations) {
       
            vm.labels = {
                Save_MSG: translations['MANAGE_SECTIONS.Save_MSG'],
                SectionKey_MSG: translations['MANAGE_SECTIONS.SectionKey_MSG'],
                Update: translations['MANAGE_SECTIONS.updateSection'],
                DuplicateSectionEnName: translations['MANAGE_SECTIONS.DuplicateSectionEnName'],
                DuplicateSectionArName: translations['MANAGE_SECTIONS.DuplicateSectionArName'],

            }
         
        });
        vm.load();
      
        
        function load() {
       
            vm.Loading();
            _getSectionByID();
            _IsSectionsEditable();
        }
        vm.toggleRAll = function () {
            vm.isAllRSelected = !vm.isAllRSelected;
            if (vm.isAllRSelected === true) {
                angular.forEach(vm.SectionsData.VisibleRequiredTo, function (item) { item.IsRequired = true; });
            }
            else {
                angular.forEach(vm.SectionsData.VisibleRequiredTo, function (item) { item.IsRequired = false; });
            }
        }
        vm.toggleVAll = function () {
            vm.isAllVSelected = !vm.isAllVSelected;
            if (vm.isAllVSelected === true) {
                angular.forEach(vm.SectionsData.VisibleRequiredTo, function (item) { item.IsVisible = true; });
            }
            else {
                angular.forEach(vm.SectionsData.VisibleRequiredTo, function (item) { item.IsVisible = false; });
            }
        }
        function _IsSectionsEditable() {
            vm.Loading()
            manageSectionDataService.IsSectionsEditable().then(function (response) {
                vm.Loading();
                if (response != null) {
                    vm.ViewMode = !response;
                } else {
                    errorNotificationService.showErrorNotification(response.responseCode);
                }
            }, function (error) {
            })
        }
        function _getSectionByID() {
            if (vm.sectionID == 0)
                vm.disableSysKeyAndCheck = false;
            manageSectionDataService.getSectionByID(vm.sectionID).then(function (response) {
                vm.Loading();
                if (response) {
                    vm.SectionsData = response;
                    vm.isAllRSelected= !vm.SectionsData.VisibleRequiredTo.some((x) =>
                    {
                        if (x.IsRequired == false)
                            return x;
                    }
                    );
                    vm.isAllVSelected= !vm.SectionsData.VisibleRequiredTo.some((x) => {
                        if (x.IsVisible == false)
                            return x;
                    }
                    );
                    //vm.SectionsData.VisibleRequiredTo.find
                } else {
                    errorNotificationService.showErrorNotification(response.responseCode);
                }
            }, function (error) {
            })
        }

        vm.SaveSection= function () {
            vm.Loading();
            manageSectionDataService.UpdateSection(vm.SectionsData).then(function (response) {
                vm.Loading();
                if (response == 1001) {
                   
                    Notification.success({ message: vm.labels.Save_MSG });
                    $state.transitionTo('MBRGI.SectionList', null);
                } else if (response == 1002)
                {
                    Notification.warning({ message: vm.labels.SectionKey_MSG });
                  
                }
                else if (response == 1005) {
                    //display Error 1005 ===>DuplicateSectionEnglishName
                    Notification.warning({ message: vm.labels.DuplicateSectionArName });
                }
                else if (response == 1006) {
                    //display Error 1006 ===>DuplicateSectionArabicName
                    Notification.warning({ message: vm.labels.DuplicateSectionArName });
                }
                else {
                    errorNotificationService.showErrorNotification(response.responseCode);
                }
            }, function (error) {
            })
        }


        vm.updateRCheckBox = function (Entity) {
            Entity.IsRequired = !Entity.IsRequired;
            vm.isAllRSelected = !vm.SectionsData.VisibleRequiredTo.some((x) => {
                if (x.IsRequired == false)
                    return x;
            }
            );
        }
        vm.updateVCheckBox = function (Entity) {
            Entity.IsVisible = !Entity.IsVisible;
            vm.isAllVSelected = !vm.SectionsData.VisibleRequiredTo.some((x) => {
                if (x.IsVisible == false)
                    return x;
            }
            );
        }
    }
}