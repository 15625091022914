export default {
    ADD_LOOKUP: 'إضافة جدول جديد',
    LOOKUP_ENGLISH_NAME: 'اسم الجدول(الإنجليزية)',
	LOOKUP_ARABIC_NAME: 'اسم الجدول (العربية)',
    SYSTEM_KEY: 'مفتاح النظام',
	UPDATE_LOOKUP: 'تعديل الجدول',
	REQUIRED_LOOKUP_ARABIC_NAME: 'اسم الجدول بالعربية مطلوب',
	REQUIRED_LOOKUP_ENGLISH_NAME: 'اسم الجدول بالانجليزية مطلوب',
	LOOKUP_UPDATED: 'تم تعديل الجدول بنجاح',
	SYSTEMKEY_EXIST: 'لا يمكن تكرار مفتاح النظام',
	LOOKUP_ADDED: 'تم إضافة الجدول بنجاح',
	REQUIRED_LOOKUP_SYSTEMKEY: 'مفتاح النظام مطلوب',
    LOOKUP_TYPE: 'نوع الجدول',
    LOOKUP_DATA: 'بيانات الجداول',
	PREDEFINED: 'معرف عن طريق النظام',
	USER_DEFINED: 'معرف عن طريق المستخدم',
	ADD_ENTRY: 'إضافة قيد جديد',
	ENTRY_VALUE_ENGLISH: 'القيمة (الإنجليزية)',
	ENTRY_VALUE_ARABIC: 'القيمة (العربية)',

    ENTRY_DELETED: 'تم حذف القيد بنجاح',
    ENTRY_DELETE_QUES: 'هل تريد حذف القيد؟',
    DELETE_HEADER: 'حذف',
    ENTRY_RESTORED: 'تم استعادة القيد بنجاح',

	ADD_LOOKUP_ENTRY: 'إضافة قيد جديد',
	LOOKUP_ENTRY_ENGLISH_VALUE: 'اسم القيد(الإنجليزية)',
	LOOKUP_ENTRY_ARABIC_VALUE: 'اسم القيد(العربية)',
	REQUIRED_LOOKUP_ENTRY_ARABIC_VALUE: 'اسم القيد بالعربية مطلوب',
	REQUIRED_LOOKUP_ENTRY_ENGLISH_VALUE: 'اسم القيد بالانجليزية مطلوب',
	LOOKUP_ENTRY_ADDED: 'تم إضافة القيد بنجاح',
	LOOKUP_ENTRY_UPDATED: 'تم تعديل القيد بنجاح',
	UPDATE_LOOKUP_ENTRY: 'تعديل القيد'

}