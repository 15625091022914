import { random as _random } from 'lodash';
export default function (app) {
    app.controller('addUserController', AddUserController);

    AddUserController.$inject = ["$rootScope", "userDataService", "roleDataService", "$uibModalInstance", "Params", "Notification", "fileUploadService", "$mdDialog","$translate","Upload"];
    function AddUserController($rootScope, userServiceApi, roleDataService, $uibModalInstance, Params, Notification, fileUploadService, $mdDialog, $translate,Upload) {
        var vm = this;
        $translate(["COMMON.USER_ADDED_NOTIFICATION", "COMMON.EMAIL_USED_NOTIFICATION"]).then(function (translations) {
            vm.labels = {
                AddedUser: translations['COMMON.USER_ADDED_NOTIFICATION'],
                UserExist: translations['COMMON.EMAIL_USED_NOTIFICATION'],
                UserExistWithSameRole: translations['COMMON.EMAIL_USED_NOTIFICATION'],
            }
        });

        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.user = {};
        vm.cancel = cancel;
        vm.Params = Params;
        vm.currentEntityID = null;
        vm.roles = null;
        vm.addUser = addUser;
        vm.uploadImage = uploadImage;
        vm.file = null;
        vm.selectImage = selectImage;
        vm.load = load;
        vm.IsProfileExist = false;
        vm.confirmAddingUser = confirmAddingUser;
        vm.load();
        vm.entityAdminRoleId = $rootScope.enums.userRole['Entity Admin'];
        function load() {
            vm.Loading();
            
            if (vm.Params.entityID === null || vm.Params.entityID === undefined)
                vm.currentEntityID = $rootScope.GetCurrentUser().CurrentEntity;
            else
                vm.currentEntityID = vm.Params.entityID
            // get the role lookup data
            var roleCategory = vm.currentEntityID ? 2 : 1;
            roleDataService.GetRolesLookupData(roleCategory).then(GetRolesLookupDataSuccess, $rootScope.methodPublicError);

        }
        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false});
        }
        function addUser(user) {
            vm.Loading();
            

            userServiceApi.IsUserExist(vm.user.Email, vm.user.RoleId, vm.currentEntityID).then(isUserExistSuccess, $rootScope.methodPublicError);
           
        }
        function addUserData() {
            vm.Loading();
            
            //var currentEntityID = $rootScope.GetCurrentUser().CurrentEntity;
            if (vm.file != null) {
                vm.uploadImage(vm.file);

            }
            else {
                userServiceApi.AddUser(vm.user, vm.currentEntityID).then(addUserSuccess, $rootScope.methodPublicError);
            }
        }
        function uploadImage(image) {
            if (image != null) {
                fileUploadService.UploadFiles(image, $rootScope.enums.UploadType.ProfilePic)
                    .then(uploadFilesSuccess, $rootScope.methodPublicError);
            }
        }

        function addUserSuccess(res) {

            if (res.responseCode === vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.AddedUser });
                $uibModalInstance.close({ Close: false, Ok: true });
            } else if (res.responseCode === vm.ResponseCode.NoError && res.responseObject == false) {
                Notification.warning({ message: vm.labels.UserExist });
                vm.Loading();
            } 
        }
        function uploadFilesSuccess(result) {
            var uploadResult = result.data;
            if (uploadResult.status == 1 && uploadResult.files) {
                vm.user.ImagePath = uploadResult.files[0];
                userServiceApi.AddUser(vm.user, vm.currentEntityID).then(addUserSuccess, $rootScope.methodPublicError);
            }
            else {
                $rootScope.methodPublicError();
            }

        }
        function isUserExistSuccess(res) {
            
            vm.Loading();
            if (res.responseCode == vm.ResponseCode.UserExistWithSameRole) {
                Notification.warning({ message: vm.labels.UserExist });
            }
            else if (res.responseCode == vm.ResponseCode.UserProfileExist) {

                vm.IsProfileExist = true; 
            } else if (res.responseCode == vm.ResponseCode.EntityUser) {
                Notification.warning({ message: vm.labels.UserExist });
            } else if (res.responseCode == vm.ResponseCode.MBRGIUser) {
                Notification.warning({ message: vm.labels.UserExist });
            }
            else if (res.responseCode == vm.ResponseCode.NotExist) {
                addUserData();
            }
          
        }
        function confirmAddingUser() {
            addUserData();
        }
        function GetRolesLookupDataSuccess(res) {
            if (res.responseCode == vm.ResponseCode.Success && res.responseObject !== null)
            {
                vm.roles = res.responseObject;
            }
            vm.Loading();
        }

        function selectImage(file, errFiles) {
            if (file || errFiles) {
                vm.errFile = errFiles && errFiles[0];
                var _fileName = file.name.split('.')[0] + "_" + _random(1, 99999, false) + '.' + file.name.split('.')[1];
                var _file = Upload.rename(file, _fileName);
                vm.file = _file;
            }
        }
    }
}