export default function (app) {
    app.factory('emailTemplatesDataService', EmailTemplatesDataService);

    EmailTemplatesDataService.$inject = ['apiBase', 'ApiPath'];
    function EmailTemplatesDataService(apiBase, ApiPath) {
        var entity = "EmailTemplate";
        var controllerUrl = ApiPath + entity + "/";
        var entityController = ApiPath + "Entity" + "/"
        function _getAllTemplates() {
            return apiBase.Get(controllerUrl + "GetAllTemplates", {}, null);
        }
        function _createEmailTemplate(data) {
            return apiBase.Post(controllerUrl + "CreateEmailTemplate", {}, data);
        }
        function _updateEmailTemplate(data) {
            return apiBase.Post(controllerUrl + "UpdateEmailTemplate", {}, data);
        }
        function _getDeletedEmailTemplates(id) {
            return apiBase.Get(controllerUrl + "GetDeletedEmailTemplates", { id: id }, null);
        }
        function _deleteEmailTemplate(id) {
            return apiBase.Post(controllerUrl + "DeleteEmailTemplate", {id:id}, null);
        }
        function _activateEmailTemplate(id) {
            return apiBase.Post(controllerUrl + "ActivateEmailTemplate", { id: id }, null);
        }
        function _deActivateEmailTemplate(id) {
            return apiBase.Post(controllerUrl + "DeActivateEmailTemplate", {id:id}, null);
        }
        function _getEmailTemplate(id) {
            return apiBase.Get(controllerUrl + "GetEmailTemplate", { id: id }, null);
        }
        function _getEmailCreationTokensList() {
            return apiBase.Get(controllerUrl + "GetEmailCreationTokensList", {}, null);
        }
        function _restoreEmailTemplate(id) {
            return apiBase.Post(controllerUrl + "RestoreEmailTemplate", { id: id}, null);
        }
        function _getAllEntitiesCardsInfo() {
            return apiBase.Get(entityController + "GetAllEntitiesCardsInfo", {}, null);
        }
    return {
        getAllTemplates: _getAllTemplates,
        createEmailTemplate: _createEmailTemplate,
        updateEmailTemplate: _updateEmailTemplate,
        getDeletedEmailTemplates: _getDeletedEmailTemplates,
        deleteEmailTemplate: _deleteEmailTemplate,
        activateEmailTemplate: _activateEmailTemplate,
        deActivateEmailTemplate: _deActivateEmailTemplate,
        getEmailTemplate: _getEmailTemplate,
        getEmailCreationTokensList: _getEmailCreationTokensList,
        restoreEmailTemplate: _restoreEmailTemplate,
        getAllEntitiesCardsInfo: _getAllEntitiesCardsInfo,
        }
    }
}