export default function (app) {
    app.directive('max', function () {
        return {
            require: '?ngModel',
            link: function (scope, elm, attrs, ctrl) {
                if (!ctrl) return;
                
                ctrl.$validators.max = function (modelValue, viewValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        // consider empty models to be valid
                        return true;
                    }

                    if (modelValue <= attrs.max)
                        return true;
                    // it is invalid
                    return false;
                };
            }
        };
    });
}