export default function (app) {
    app.controller('beneficiaryunitController', BeneficiaryunitController);

    BeneficiaryunitController.$inject = ["$scope", "$rootScope", "beneficiaryunitDataService", "$uibModal", "Notification","$translate","$state"];
    function BeneficiaryunitController($scope, $rootScope, beneficiaryunitDataService, $uibModal, Notification, $translate,$state) {
        var vm = this;

        $translate(["BENEFICIARYUNIT.BENEFICIARY_UNIT_DELETED", "BENEFICIARYUNIT.BENEFICIARY_UNIT_DELETE_QUES", "BENEFICIARYUNIT.DELETE_HEADER","COMMON.ACTIVE_CYCLE"]).then(function (translations) {
            vm.labels = {
                beneficiaryunitDeleted: translations['BENEFICIARYUNIT.BENEFICIARY_UNIT_DELETED'],
                DeleteBeneficiaryUnitQues: translations['BENEFICIARYUNIT.BENEFICIARY_UNIT_DELETE_QUES'],
                DeleteHeader: translations['BENEFICIARYUNIT.DELETE_HEADER'],
                ACTIVE_CYCLE: translations['COMMON.ACTIVE_CYCLE'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.beneficiaryunit = {};
        vm.Beneficiaryunits = [];
        vm.load = load;
        vm.getAllBeneficiaryunits = getAllBeneficiaryunits;
        vm.changeActiveStatus = changeActiveStatus;
        vm.openUpdateModal = openUpdateModal;
        vm.openAddModal = openAddModal;
        vm.deleteBeneficiaryUnit = deleteBeneficiaryUnit;
        vm.Modal = null;
        vm.beneficiaryunitFilter = beneficiaryunitFilter;
        vm.currentCycle = null;
        vm.deletedCount = 0;
        vm.load();

        function load() {
            
            vm.getAllBeneficiaryunits();
        }

        function getAllBeneficiaryunits() {
            vm.Loading();
            beneficiaryunitDataService.GetAllBeneficiaryunits().then(getAllBeneficiaryunitsSuccess, $rootScope.methodPublicError);
        }

        function beneficiaryunitFilter(item) {
            if (vm.search) {
                return item.NameEn.toLowerCase().includes(vm.search.toLowerCase()) || item.NameAr.toLowerCase().includes(vm.search.toLowerCase())|| item.TypeName.toLowerCase().includes(vm.search.toLowerCase());
            }
            else
                return true;
        }

        function changeActiveStatus(beneficiaryunit, ev) {
            vm.Loading();
            var beneficiaryunitID = beneficiaryunit.ID;
            var deleteState = beneficiaryunit.IsDeleted;
            var labels = vm.labels;
            if (deleteState === false) { //incase of deactivate show confirmation message 
                vm.Loading();
                vm.Modal = $uibModal.open({
                  //  backdrop: "static",
                    keyboard: true,
                    size: 'md modal-dialog-centered',
                    animation: true,
                    //backdropClick: true,
                    templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
                    controller: function ($uibModalInstance, $rootScope) {
                        var vm = this;
                        vm.header = labels.DeleteHeader;
                        vm.message = labels.DeleteBeneficiaryUnitQues;
                        vm.ok = function () {
                            $uibModalInstance.close({ Close: false, Ok: true });
                        };
                        vm.cancel = function () {
                            $uibModalInstance.close({ Close: true, Ok: false });
                        };
                    },
                    controllerAs: 'vm'
                });
                vm.Modal.result.then(function (result) {
                    if (result.Ok) {
                        vm.Loading();
                        beneficiaryunitDataService.ChangeActiveStatus(beneficiaryunitID, deleteState)
                            .then(ChangeActiveStatusSuccess, $rootScope.methodPublicError);
                    }
                }, function (error) {
                    //backdrop clicked or error
                });
            }
            else {
                beneficiaryunitDataService.ChangeActiveStatus(beneficiaryunitID, deleteState)
                    .then(ChangeActiveStatusSuccess, $rootScope.methodPublicError);
            }
        }

        function deleteBeneficiaryUnit(beneficiaryunitID) {
            var labels = vm.labels;
            vm.Modal = $uibModal.open({
                //backdrop: "static",
                keyboard: true,
                size: 'md modal-dialog-centered',
                animation: true,
                //backdropClick: true,
                templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
                controller: function ($uibModalInstance, $rootScope) {
                    var vm = this;
                    vm.header = labels.DeleteHeader;
                    vm.message = labels.DeleteBeneficiaryUnitQues;
                    vm.ok = function () {
                        $uibModalInstance.close({ Close: false, Ok: true });
                    };
                    vm.cancel = function () {
                        $uibModalInstance.close({ Close: true, Ok: false });
                    };
                },
                controllerAs: 'vm'
            });
            vm.Modal.result.then(function (result) {
                if (result.Ok) {
                    vm.Loading();
                    beneficiaryunitDataService.DeleteBeneficiaryUnit(beneficiaryunitID)
                        .then(ChangeActiveStatusSuccess, $rootScope.methodPublicError);
                }
            }, function (error) {
                //backdrop clicked or error
            });
        }

        function openUpdateModal(beneficiaryunit) {
            vm.Modal = $uibModal.open({
              //  backdrop: "static",
                keyboard: true,
                size: 'lg',
                animation: true,
                //backdropClick: true,
                templateUrl: 'ng/Modules/MBRGI/BeneficiaryUnit/Views/update-beneficiaryunit.html',
                controller: 'updateBeneficiaryunitController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () { return beneficiaryunit; }
                }
            });
            vm.Modal.result.then(function (result) {

                if (result.Ok)
                    vm.load();
            }, function (error) {
                //backdrop clicked or error
            });
        }
        function openAddModal() {
            
             vm.Modal = $uibModal.open({
              //  backdrop: "static",
                keyboard: true,
                size: 'lg',
                animation: true,
                //backdropClick: true,
                templateUrl: 'ng/Modules/MBRGI/BeneficiaryUnit/Views/add-beneficiaryunit.html',
                controller: 'addBeneficiaryunitController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () { }
                }
            });
            vm.Modal.result.then(function (result) {

                if (result.Ok)
                    vm.load();
            }, function (error) {
                //backdrop clicked or error
            });
        }

        function getAllBeneficiaryunitsSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.Beneficiaryunits = res.responseObject;
                vm.currentCycle = res.currentCycle;
                vm.deletedCount = res.deletedCount;
                beneficiaryunitDataService.GetAllBeneficiaryUnitsTypes().then(getAllBeneficiaryunitsTypesSuccess, $rootScope.methodPublicError);
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function getAllBeneficiaryunitsTypesSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.BeneficiaryunitTypes = res.responseObject;
                for (var i = 0; i < vm.Beneficiaryunits.length; i++) {
                    vm.Beneficiaryunits[i].TypeName = getBeneficiaryUnitTypeName(vm.Beneficiaryunits[i].Type); 
                }
                vm.Loading();
            }

            else {
                $rootScope.methodPublicError();
            }
        }
        function getBeneficiaryUnitTypeName(ID) {
            for (var i = 0; i < vm.BeneficiaryunitTypes.length; i++)
            {
                if (vm.BeneficiaryunitTypes[i].ID == ID) {
                    return vm.BeneficiaryunitTypes[i].Name;
                }
            }
        }

        function ChangeActiveStatusSuccess(res) {
            
            if (res.responseCode == vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.beneficiaryunitDeleted });
                vm.Loading();
                vm.load();
            } else if (res.responseCode == vm.ResponseCode.ActiveCycleExist) {
                Notification.error({ message: vm.labels.ACTIVE_CYCLE });
                vm.Loading();
            }

        }
        vm.goToTrash = function () {
            $state.transitionTo('MBRGI.RestoreBeneficiaryUnit');
        }
        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
        });


        $scope.sortableOptions = {
            disabled: vm.IsDisableMode,
            placeholder: vm.IsDisableMode ? "" : "ui-state-highlight",
            stop: function (e, ui) {
                var IDs = [];
                vm.Beneficiaryunits.forEach((x) => { IDs.push(x.ID); });
                vm.Loading();
                beneficiaryunitDataService.UpdateBeneficiaryUnitsOrder(IDs).then(function (response) {
                    vm.Loading();
                    if (response) {

                    } else {
                        $rootScope.methodPublicError();
                    }
                })
            }
        };
    }
}