import { remove as _remove, map as _map } from 'lodash';
export default function (app) {

    app.controller("EntitySectionReportController", ['$scope', 'reportsService', 'exportFileService', 'uiLoadingIndicators', 'taskTypesConstant', 'sectionStatusConstant', 'errorNotificationService', '$stateParams', 'routeConstants', '$state', '$rootScope', '$uibModal', '$timeout', 'actionsTypesConstant', 'successrNotificationService', 'sectionsService', '$translate', 'Excel', '$filter', '$document',
        function ($scope, reportsService,exportFileService, uiLoadingIndicators, taskTypesConstant, sectionStatusConstant, errorNotificationService, $stateParams, routeConstants, $state, $rootScope, $uibModal, $timeout, actionsTypesConstant, successrNotificationService, sectionsService, $translate, Excel, $filter,$document) {
            var vm = this;
           
            vm.cycles = [];            
            vm.activeCycleId = 0;    
            
            vm.coulmnKeys = {};                                                
            vm.displayReport = false;
            vm.displayExport = true;
            vm.isEmptyList = false;                    
            vm.reportInfo = [];
            vm.totalResuilts = [];
            $scope.$watch('vm.activeCycleId', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    vm.displayExport = false;
                }
            }, true);
            vm.Loading = $rootScope.Loading;
            vm.loadingPdfFile = false;

            vm.downloadPdf = function () {
                vm.loadingPdfFile = true;
                var repo = $document[0].getElementById("ReportContent").innerHTML;
                var Data = { value: repo };
                var entityLevelID = $rootScope.enums.ReportLevels['Entities'];
                var reportType = $rootScope.enums.ReportTypes['Sections'];
                var exportFile = exportFileService.exportPdf(Data, entityLevelID, reportType);
                Promise.all([exportFile]).then(function (res) {
                    vm.loadingPdfFile = false;
                    $scope.$apply();

                });
            };

            var _getAllFilterList = function () {
                vm.Loading();                
                _getCyclesList();                
                vm.Loading();
            }
            
            var _getCyclesList = function () {
                uiLoadingIndicators.load('report', true);                
                reportsService.getCyclesList().then(function(response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        if (_data.length > 0) {
                            vm.cycles = _map(_data, (cycle) => {
                                if ($rootScope.currentLang == 'en') {
                                    cycle.name = cycle.NameEn;
                                }
                                else {
                                    cycle.name = cycle.NameAr;
                                }
                                if (cycle.ActiveCycle) {
                                    vm.activeCycleId = cycle.ID;
                                }
                                return cycle;
                            });
                            if (vm.activeCycleId == 0) {
                                vm.cycles = $filter('orderBy')(vm.cycles, 'CreationDate', 'reverse');
                                vm.activeCycleId = vm.cycles[0].ID;
                            }
                        }
                    } else {

                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('report', false);

                },function(error) {
                    uiLoadingIndicators.load('report', false);

                })

            }          
            var _init = function () {
                _getAllFilterList();
            }()        
            vm.GenerateReport = function () {
                vm.isEmptyList = false;                    
                vm.displayExport = true;
                uiLoadingIndicators.load('report', true);
                reportsService.getSectionEntitiesReportInfo(vm.activeCycleId).then(function(response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        vm.reportInfo = _data.Result;
                        vm.totals = _data.ResultTotals;
                        vm.coulmnKeys = _data.ColumnName;
                        if (vm.reportInfo.length > 0 && vm.reportInfo) {
                            vm.displayReport = true;
                        }
                        else {
                            vm.displayReport = false;
                            vm.isEmptyList = true;
                        }                        
                    }
                    uiLoadingIndicators.load('report', false);
                },function(error) {
                    errorNotificationService.showErrorNotification(error);
                    uiLoadingIndicators.load('report', false);

                })

            }
            vm.exportToexcel = function (tableId) {
                vm.loadingExcelFile = true;
                var exportFile = exportExcel();
                Promise.all([exportFile]).then(function (res) {
                    vm.loadingExcelFile = false;
                    $scope.$apply();
                });
            };
            var exportExcel = function () {
                return reportsService.exportSectionEntitiesReportInfo(vm.activeCycleId).then(function (response) {
                    exportFileService.exportExcel(response);


                }, function (error) {

                    //normal error
                    errorNotificationService.showErrorNotification(error);
                });

            };
           
        }])
}