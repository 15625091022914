export default function (app) {
    app.controller('addCountryController', AddCountryController);

    AddCountryController.$inject = ["$rootScope", "countryDataService", "$uibModalInstance", "Params", "Notification","$translate"];
    function AddCountryController($rootScope, countryDataService, $uibModalInstance, Params, Notification, $translate) {
        var vm = this;
        $translate(["COUNTRIES.COUNTRY_EXIST", "COUNTRIES.COUNTRY_ADDED"]).then(function (translations) {
            vm.labels = {
                CountryIsAlreadyExist: translations['COUNTRIES.COUNTRY_EXIST'],
                CountryAdded: translations['COUNTRIES.COUNTRY_ADDED'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.responseCode = $rootScope.enums.ResponseCode;
        vm.Loading();
        vm.country = null;
        vm.load = load;
        vm.cancel = cancel;
        vm.addCountry = addCountry;


        vm.load();

        function load() {
            vm.Loading();
        }
        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false});
        }

        function addCountry(country) {
            vm.Loading();
            countryDataService.IsCountryExist(country).then(isCountryExistSuccess, $rootScope.methodPublicError);
        }

        function isCountryExistSuccess(res) {
            if (res.responseCode == vm.responseCode.NotExist) {
                countryDataService.AddCountry(vm.country).then(addCountrySuccess, $rootScope.methodPublicError);
            }
            else if (res.responseCode == vm.responseCode.Success) {
                Notification.warning({ message: vm.labels.CountryIsAlreadyExist });
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function addCountrySuccess(res) {
            if (res.responseCode == vm.responseCode.Success) {
                vm.Loading();
                Notification.success({ message: vm.labels.CountryAdded });
                $uibModalInstance.close({ Close: true, Ok: true });
            }
            else if (res.responseCode == vm.responseCode.Error) {
                $rootScope.methodPublicError();
                $uibModalInstance.close({ Close: true, Ok: false });
            }
        }
    }
}