export default function (app) {
    app.controller('restoreEmailTemplatesController', RestoreEmailTemplatesController);

    RestoreEmailTemplatesController.$inject = ["$scope", "$rootScope", "emailTemplatesDataService", "$uibModal", "Notification", "$translate","$state"];
    function RestoreEmailTemplatesController($scope, $rootScope, emailTemplatesDataService, $uibModal, Notification, $translate,$state) {
        var vm = this;

        $translate(["EMAIL_TEMPLATES.EMAIL_TEMPLATE_RESTORED"]).then(function (translations) {
            vm.labels = {
                EMAIL_TEMPLATE_RESTORED: translations['EMAIL_TEMPLATES.EMAIL_TEMPLATE_RESTORED'],
            }
        });
        vm.emailTemplates = null;
        vm.load = load;
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.load();

        function load() {
            vm.Loading();
            _getDeletedEmailTemplates();
        }

        function _getDeletedEmailTemplates() {
            emailTemplatesDataService.getDeletedEmailTemplates().then(_getDeletedEmailTemplatesSuccess, $rootScope.methodPublicError);
        }
        function _getDeletedEmailTemplatesSuccess(res) {
            vm.Loading(false);
            if (res && res.responseCode === vm.ResponseCode.Success && res.responseObject) {
                vm.emailTemplates = res.responseObject.emailTemplates;
                if (vm.emailTemplates.length == 0)
                    $state.go('MBRGI.EmailTemplates');
            }
            
        }
        vm.Back = function () {
            $state.transitionTo('MBRGI.EmailTemplates');
        }
        vm.restore = function (id) {
            vm.Loading();
            emailTemplatesDataService.restoreEmailTemplate(id).then(restoreEmailTemplateSuccess, $rootScope.methodPublicError);
        }
        function restoreEmailTemplateSuccess(res) {
            vm.Loading(false);
            if (res && res.responseCode === vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.EMAIL_TEMPLATE_RESTORED });
                $state.reload();
            }
        }
        vm.templatesFilter = function (item) {
            if (vm.search) {
                return item.templateName.toLowerCase().includes(vm.search.toLowerCase());
            }
            else
                return true;
        }
    }
}