export default function (app) {
    app.controller('entityController', EntityController);

    EntityController.$inject = ["$scope", "$rootScope", "entityDataService", "$uibModal", "Notification", "$state","$translate"];
    function EntityController($scope, $rootScope, entityDataService, $uibModal, Notification, $state, $translate) {
        var vm = this;
        $translate(["ENTITIES.DEACTIVATE_ENTITY", "ENTITIES.WOULD_DEACTIVATE_ENTITY", "ENTITIES.ENTITY_DEACTIVATED", "ENTITIES.ENTITY_ACTIVATED", "ENTITIES.CANT_DEACTIVATE"]).then(function (translations) {
            vm.labels = {
                DEACTIVATE_ENTITY: translations['ENTITIES.DEACTIVATE_ENTITY'],
                WOULD_DEACTIVATE_ENTITY: translations['ENTITIES.WOULD_DEACTIVATE_ENTITY'],
                ENTITY_DEACTIVATED: translations['ENTITIES.ENTITY_DEACTIVATED'],
                ENTITY_ACTIVATED: translations['ENTITIES.ENTITY_ACTIVATED'],
                CANT_DEACTIVATE: translations['ENTITIES.CANT_DEACTIVATE'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.Entities = [];
        vm.load = load;
        vm.goToEntityUsers = goToEntityUsers;
        vm.getAllEntitiesCardsInfo = getAllEntitiesCardsInfo;
        vm.changeActiveStatus = changeActiveStatus;
        vm.openUpdateModal = openUpdateModal;
        vm.openAddModal = openAddModal;
        vm.Modal = null;
        vm.entityFilter = entityFilter;
        vm.load();

        function load() {

            vm.getAllEntitiesCardsInfo();
        }

        function getAllEntitiesCardsInfo() {
            vm.Loading();
            entityDataService.GetAllEntitiesCardsInfo().then(GetAllEntitiesCardsInfoSuccess, $rootScope.methodPublicError);
        }

        function goToEntityUsers(entityID)
        {
            $state.go("MBRGI.EntityUsers", { 'entityID': entityID }); //for test entityUsers
        }

        function entityFilter(item) {
            if (vm.search) {
                return item.title.toLowerCase().includes(vm.search.toLowerCase()) ;
            }
            else
                return true;
        }

        function openUpdateModal(entityID) {
            $state.go("MBRGI.EntityProfile", { 'entityID': entityID });
        }
        function openAddModal() {
            vm.Modal = $uibModal.open({
               // backdrop: 'static',
                keyboard: true,
				backdropClick: true,
				size: 'lg',
                templateUrl: 'ng/Modules/MBRGI/Entities/Views/add-entity.html',
                controller: 'addEntityController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () {  }
                }
            });
            vm.Modal.result.then(function (result) {
                if (result.Ok)
                    vm.load();
            }, function (error) {
                //backdrop clicked or error
            });
        }
        function changeActiveStatus(entity, ev) {
            // Appending dialog to document.body to cover sidenav in docs app

            vm.Loading();
            var entityID = entity.id;
            var deleteState = entity.isDeleted;
            
            var labels = vm.labels;
            if (deleteState === false) { //incase of deactivate show confirmation message 
                vm.Loading();
                vm.Modal = $uibModal.open({
                  //  backdrop: "static",
                    keyboard: true,
                    size: 'md modal-dialog-centered',
                    animation: true,
                    //backdropClick: true,
                    templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
                    controller: function ($uibModalInstance) {
                        var vm = this;
                        vm.header = labels.DEACTIVATE_ENTITY;
                        vm.message = labels.WOULD_DEACTIVATE_ENTITY;
                        vm.ok = function () {
                            $uibModalInstance.close({ Close: false, Ok: true });
                        };
                        vm.cancel = function () {
                            $uibModalInstance.close({ Close: true, Ok: false });
                        };
                    },
                    controllerAs: 'vm'
                });
                vm.Modal.result.then(function (result) {
                    if (result.Ok) {
                        vm.Loading();
                        entityDataService.ChangeActiveStatus(entityID, deleteState)
                            .then(ChangeActiveStatusSuccess, $rootScope.methodPublicError);
                    }
                }, function (error) {
                    //backdrop clicked or error
                });
            }
            else {
                entityDataService.ChangeActiveStatus(entityID, deleteState)
                    .then(ChangeActiveStatusSuccess, $rootScope.methodPublicError);
            }
        }
        function GetAllEntitiesCardsInfoSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.Entities = res.responseObject;
                vm.thumbPath = res.thumbPath;
                for (var i = 0; i < vm.Entities.length; i++) {
                    if (vm.Entities[i].thumbnail)
                        vm.Entities[i].thumbnail = vm.thumbPath + vm.Entities[i].thumbnail;
                }
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function ChangeActiveStatusSuccess(res) {
            if (res.responseCode == vm.ResponseCode.Activated) {
                Notification.success({ message: vm.labels.ENTITY_ACTIVATED });

            } else if (res.responseCode == vm.ResponseCode.DeActivated) {
                Notification.success({ message: vm.labels.ENTITY_DEACTIVATED });
            }
            else if (res.responseCode == vm.ResponseCode.CantDeActivateExistTasks) {
                Notification.warning({ message: vm.labels.CANT_DEACTIVATE });
            }
            vm.Loading();
            vm.load();
        }
        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
        });
    }
}