//Import controllers
import annualAchievementsController from './Controllers/app.MBRGI.annualAchievements.controller';
import sectionSubmissionController from './Controllers/app.MBRGI.sectionSubmission.controller';
import sectionSubmissionsController from './Controllers/app.MBRGI.sectionSubmissions.controller';
import MBRGIannualAchievementController from './Controllers/app.MBRGI.AnnualAchievement.controller';

//Import services
import dataService from './Services/MBRGI.annualAchievements.data.service';
import annualAchievementService from './Services/MBRGI.annualAchievement.data.service';
import sections from './Services/MBRGI.sections.data.service'
//Import config
import './Localization';

export default function (app) {
    annualAchievementsController(app);
    sectionSubmissionController(app);
    sectionSubmissionsController(app);
    MBRGIannualAchievementController(app);
    dataService(app);
    annualAchievementService(app);
    sections(app);
}