export default function (app) {
    app.factory('homeDataService', HomeDataService);

    HomeDataService.$inject = ['apiBase', 'ApiPath'];
    function HomeDataService(apiBase, ApiPath) {
        var entity = "Home";
        var controllerUrl = ApiPath + entity + "/";

        return {
        }
    }
}