export default {
    SHARED_INITIATIVIES: 'Shared Initiatives',   
    NEW: 'New',
    EDITING: 'Editing',
    REVIEWING: 'Under Review',
    REVIEWED: 'Reviewed',
    SENT_BACK: 'Sent back',       
    CREATE: 'Create',
    REASSIGN: 'Reassign',
    REOPEN: 'Reopen',
    EDITOR: 'Editor',
    REVIEWER: 'Reviewer',
    SAVE: 'Save',
    SUBMIT: 'Submit',
    APPROVE: 'Approve',
    SENDBACK: 'Send back',
    SEND: 'Send',
    REQUIRED_COMMENT: 'Comment is required.',
}

