const DICTIONARY = {};

export function registerTranslation(lang, namespace, dict) {
    if (!lang || !namespace || !dict) {
        throw "registerTranslation cannot receive null param you passed " + JSON.stringify(arguments);
    }

    DICTIONARY[lang] = DICTIONARY[lang] || {};
    if (DICTIONARY[lang].hasOwnProperty(namespace)) {
        throw "translation for " + namespace + " is already registered";
    }
    DICTIONARY[lang][namespace] = dict;
}

export function getTranslationForLang(lang) {
    if (!lang) {
        throw "getTranslationForLang cannot receive null lang";
    }
    return DICTIONARY[lang];
} 