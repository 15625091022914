import ngValidateCondition from './ngValidateCondition';
import uiLoadingIndicators from './uiLoadingIndicatorsDirective';
import uiLoadingIndicatorsDisplay from './uiLoadingIndicatorsDisplayDirective';
import uiLoadingIndicatorsStyle from './uiLoadingIndicatorsStyleDirective';
import pageLoading from './pageLoading';
import partialLoading from './partialLoading';
import confirmNavigation from './confirmNavigation';
import customDatePicker from './custom-date-picker.directive';
import owlCarouselDirective from './owlCarouselDirective'

export default function (app) {
    ngValidateCondition(app);
    uiLoadingIndicators(app);
    uiLoadingIndicatorsDisplay(app);
    uiLoadingIndicatorsStyle(app);
    pageLoading(app);
    partialLoading(app);
    confirmNavigation(app);
    customDatePicker(app);
    owlCarouselDirective(app)
}