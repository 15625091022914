export default {
    NEW: 'جديدة',
    APPROVED: 'معتمدة ',
    SENT_BACK: 'تم إعادة إرسالها',
    REPLY: 'رد',
    UNDER_REVIEWING: 'تحت المراجعة',
    PICK: 'اختيار المهمة',
    UNPICK: 'ترك المهمة',
    VIEW: 'عرض',
    PICKED_BY: 'تم الاختيار من  : ',
    EDIT: 'تعديل',
    PICKEDBY_ANOTHER: 'لقد تم اختياره من قبل مستخدم آخر.',
    ANNUAL_PICKED: 'تم اختيار الإنجاز السنوي بنجاح',
    ANNUAL_RELEASED: 'تم تحرير الإنجاز السنوي بنجاح',
    ANNUAL_REOPENED: 'تم إعادة فتح الإنجاز السنوي للتعديل بنجاح',
    ANNUAL_CANNOT_REOPENED: 'لا يمكنك إرسال نتائج الإنجازات للمؤسسة ما لم يتم اعتمادها',
    RELEASE_QUES:'هذه المهمة لم تكتمل بعد. هل أنت متأكد من المتابعة؟',
    CANCEL_ACHIEVEMENTS: 'إلغاء',
    CANNOT_SUBMIT_ANNUAL: 'لا يحق لك إرسال الإنجازات السنوية للمؤسسة ، ما لم تراجع جميع الأقسام',
    NOT_ACTIVITIES_SUBMITED: 'لا يمكنك إرسال نتائج الإنجازات للمؤسسة ما لم تكمل بيانات جميع الأنشطة',
    ADD: 'أضف',
    Empty: 'لم يتم العمل عليها',
    InProgress: 'قيد التنفيذ',
    REOPEN: 'إعادة فتحها',
    FINAL_SUBMIT: 'إرسال نهائي',
    SEND_EMAIL: 'إرسال بريد ألكتروني',
    CONFIRM_SUBMIT_ACHIEVEMENTS: 'أنت ترسل نتائج المراجعة الخاصة بك. هل أنت متأكد من المتابعة؟',
    MBRGIViewerNotAllowToView: 'لقد تم فتح هذا الإنجاز السنوي',
    NotAllowToSubmit: 'أنت تعمل حاليًا على إنجاز سنوي لمؤسسة أرسلت طلب تعديل تمت الموافقة عليه. سيتم تحرير هذه المهمة تلقائيا.',
}