import { remove as _remove, map as _map, cloneDeep as _cloneDeep, find as _find } from 'lodash';
import { relativeTimeRounding } from 'moment';

export default function (app) {
    app.controller("MBRGIAnnualAchievementController", ['$scope', '$state', 'sectionStatusConstant', 'annualAchievementMBRGIService', 'uiLoadingIndicators', '$rootScope', 'usersRolesConstant', 'errorNotificationService', '$uibModal', '$timeout', 'routeConstants', "statusConstant", '$transitions', 'sectionsMBRGIService', '$translate', '$stateParams', 'successrNotificationService', '$filter','Notification',
        function ($scope, $state, sectionStatusConstant, annualAchievementMBRGIService, uiLoadingIndicators, $rootScope, usersRolesConstant, errorNotificationService, $uibModal, $timeout, routeConstants, statusConstant, $transitions, sectionsMBRGIService, $translate, $stateParams, successrNotificationService, $filter, Notification) {
            var vm = this;

            vm.labels = {
                No_ACTIVE_CYCLE: $translate.instant('ACHIEVEMENT.NO_ACTIVE_CYCLE'),
                SUBMIT_ACHIEVEMENTS: $translate.instant('ACHIEVEMENT.SUBMIT_ACHIEVEMENTS'),
                CONFIRM_FinalSUBMIT_ACHIEVEMENTS: $translate.instant('COMMON.SEND_MBRGISUBMIT'),
                CONFIRM_SUBMIT_ACHIEVEMENTS: $translate.instant('ANNUALACHIEVEMENTS.CONFIRM_SUBMIT_ACHIEVEMENTS'),
                FINAL_SUBMIT: $translate.instant('ANNUALACHIEVEMENTS.FINAL_SUBMIT'),
                NotAllowToSubmit: $translate.instant('ANNUALACHIEVEMENTS.NotAllowToSubmit'),
            }

            var _selectSectionPopup = null;
            var _confirmSubmitAchievementsModalInstance = null;
            vm.annualAchievementCycleTitle = "";
            vm.logo = "";
            vm.sections = [];
            vm.sectionStatus = sectionStatusConstant;
            vm.selectedSections = [];
            vm.ExportSelectedSection = [];
            vm.entityID = $state.params.entityID;
            vm.viewMode = JSON.parse($state.params.viewMode);
            vm.status = statusConstant;
            vm.statusID = 0;
            vm.cycles = [];
            var _unregisterOnTransitionSuccess  = $transitions.onSuccess({ to: routeConstants.MBRGIAnnualAchievementState.State }, function (transition) {
                //by default route to first section. on page refresh this callback doesn't fire so we duplicate the same logic in _getEntityAnnualAchievement
                if (vm.sections && vm.sections.length > 0) {
                    _navigateToSection(vm.sections[0], false);
                }
            });
            
            var _getEntityAnnualAchievement = function (ignoreActivity) {
                uiLoadingIndicators.load('annualAchievement', true);
                if (vm.cycles[vm.cycleIndex].ActiveCycle)
                    vm.viewMode = false;
                annualAchievementMBRGIService.getMBRGIEntityAnnualAchievement(vm.entityID, vm.viewMode, $state.params.cycleID).then(function(response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        vm.annualAchievementCycleTitle = _data.cycleTitle;
                        vm.logo = _data.logo;
                        vm.sections = _data.sections;
                        vm.viewMode = _data.ViewMode;
                        vm.isCycleEnabled = _data.isCycleEnabled;
                        vm.canSubmit = _data.CanSubmit;
                        vm.statusID = _data.statusID;
                        //by default route to first section
                        if (vm.sections && vm.sections.length > 0) {
                            var sectionName = null;
                            var section = vm.sections[0];
                            if ($state.params.sectionID) {
                                sectionName = $state.params.sectionID ? $state.params.sectionID : null;
                                section = _find(vm.sections, (section) => {
                                    return section.id == sectionName;
                                });
                                if (!section) section = vm.sections[0];
                            }
                            sectionsMBRGIService.setSections(vm.sections);
                            _navigateToSection(section, ignoreActivity);
                        }
                    }
                    else if (response && response.responseCode == 17) {
                        $state.go(routeConstants.MBRGIAnnualAchievementsState.State);
                        $rootScope.ShowError(vm.labels.No_ACTIVE_CYCLE);
                    }
                    else {
                        //handle business errors
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('annualAchievement', false);
                },function(error) {
                    uiLoadingIndicators.load('annualAchievement', false);
                    //normal error
                    errorNotificationService.showErrorNotification(error);
                })
            }

            var _navigateToSection = function (section, ignoreActivity) {
                //if current state is the root we navigate to a nested state
                //TODO refactor this state to be abstract state
                if (section && $state.$current.name.includes(routeConstants.MBRGIAnnualAchievementState.State)) {
                    if (section.isSingle || (!ignoreActivity && $state.params.activityID)) {
                        var activityID = (!ignoreActivity && $state.params.activityID) ? $state.params.activityID : null;
                        $state.go(routeConstants.MBRGISectionSubmissionState.State, { cycleID: vm.cycleID, sectionID: section.id, viewMode: vm.viewMode, activityID: activityID })
                    } else {
                        $state.go(routeConstants.MBRGISectionSubmissionsState.State, { cycleID: vm.cycleID, sectionID: section.id, viewMode: vm.viewMode})
                    }
                }
            }

            //TODO :[hossam] return labels for entity status per each section
            vm.getEntityStatusLabel = function (status) {
                if (status == vm.sectionStatus.NEW) {
                   return $translate.instant('COMMON.NEW');
                }
                else if (status == vm.sectionStatus.IN_PROGRESS) {
                   return $translate.instant('COMMON.INPROGRESS')
                }
                else if (status == vm.sectionStatus.SUBMITTED) {
                   return $translate.instant('COMMON.COMPLETED')
                }
            }

            vm.submitAnnualAchievementAction = function () {
                var _labels = vm.labels;
                var entityTobeSubmit = vm.entityID;
                if (document.getElementsByClassName("modal").length === 0)
                _confirmSubmitAchievementsModalInstance = $uibModal.open({
                    keyboard: true,
                    size: 'md modal-dialog-centered',
                    animation: true,
                    template: require('../../../Common/Views/confirmation-popup.html'),
                    controllerAs: 'vm',
                    controller: ['$uibModalInstance', function ($uibModalInstance) {
                        var vm = this;
                        vm.message = _labels.CONFIRM_SUBMIT_ACHIEVEMENTS;
                        vm.header = _labels.SUBMIT_ACHIEVEMENTS;
                        vm.NotAllowToSubmit = _labels.NotAllowToSubmit;
                        vm.isConfirmationAction = true;

                        vm.ok = function () {
                            uiLoadingIndicators.load('annualAchievement', true);
                            annualAchievementMBRGIService.submitAnnualAchievementAction(entityTobeSubmit).then(function(response) {
                                uiLoadingIndicators.load('annualAchievement', false);
                                if (response.responseCode == 1) {
                                    successrNotificationService.showSuccessMBRGISubmittingNotfication();
                                    $state.go('MBRGI.MBRGIAnnualAchievements', {}, { reload: true });
                                }
                                else if (response.responseCode == 324) {
                                    Notification.error({ message: vm.NotAllowToSubmit });
                                    $state.transitionTo('MBRGI.MBRGIAnnualAchievements');
                                }
                                else {
                                    errorNotificationService.showErrorNotification(response.responseCode);
                                }
                            },function(error) {
                                errorNotificationService.showErrorNotification(error);
                            });
                        }

                        vm.cancel = function () {
                            $uibModalInstance.close();
                        }
                    }]
                })
            }
            
            vm.finalSubmitAnnualAchievementAction = function () {
                var _labels = vm.labels;
                var entityTobeSubmit = vm.entityID;
                if (document.getElementsByClassName("modal").length === 0)
                    _confirmSubmitAchievementsModalInstance = $uibModal.open({
                        keyboard: true,
                        size: 'md modal-dialog-centered',
                        animation: true,
                        template: require('../../../Common/Views/confirmation-popup.html'),
                        controllerAs: 'vm',
                        controller: ['$uibModalInstance', function ($uibModalInstance) {
                            var vm = this;
                            vm.message = _labels.CONFIRM_FinalSUBMIT_ACHIEVEMENTS;
                            vm.header = _labels.FINAL_SUBMIT;
                            vm.NotAllowToSubmit = _labels.NotAllowToSubmit;
                            vm.isConfirmationAction = true;

                            vm.ok = function () {
                                uiLoadingIndicators.load('annualAchievement', true);
                                annualAchievementMBRGIService.finalSubmitAnnualAchievementAction(entityTobeSubmit).then(function (response) {
                                    uiLoadingIndicators.load('annualAchievement', false);
                                    if (response.responseCode == 1) {
                                        successrNotificationService.showSuccessMBRGISubmittingNotfication();
                                        $state.go('MBRGI.MBRGIAnnualAchievements', {}, { reload: true });
                                    }
                                    else if (response.responseCode == 324) {
                                        Notification.error({ message: vm.NotAllowToSubmit});
                                        $state.transitionTo('MBRGI.MBRGIAnnualAchievements');
                                    }
                                    else {
                                        errorNotificationService.showErrorNotification(response.responseCode);
                                    }
                                }, function (error) {
                                    errorNotificationService.showErrorNotification(error);
                                });
                            }

                            vm.cancel = function () {
                                $uibModalInstance.close();
                            }
                        }]
                    })
            }

            vm.ExportBtnClick = function () {
                var sections = vm.sections;
                var entityID = vm.entityID;
                _selectSectionPopup = $uibModal.open({
                    keyboard: true,
                    size: 'md modal-dialog-centered',
                    animation: true,
                    template: require('../../../Common/Views/export-sections.html'),
                    controller: function ($scope, $uibModalInstance) {
                        var vm = this;
                        vm.isAllSelected = false;
                        vm.sections = _cloneDeep(sections);
                        vm.ModalSpinner = false;
                        angular.forEach(vm.sections, function (item) { item.checked = false; });
                        vm.ExportSelectedSection = [];
                        vm.getFileName = function (s) {
                            var index = s.indexOf("=");
                            index = index + 1;
                            var filename = s.substring(index, s.length);
                            filename = filename.replace(/"/g, '');
                            return filename;
                        }
                        vm.ok = function () {
                            vm.ModalSpinner = true;
                            annualAchievementMBRGIService.exportEntityAnnualAchievement(vm.ExportSelectedSection, entityID, $state.params.cycleID).then(function(response) {

                                if (response) {
                                    var blob = new Blob([response.data]);
                                    var link = document.createElement('a');
                                    link.href = window.URL.createObjectURL(blob);
                                    var fileName = vm.getFileName(response.headers('content-disposition'));
                                    link.download = fileName;
                                    link.click();
                                }
                                else {
                                    //handle business errors
                                    errorNotificationService.showErrorNotification(response.responseCode);
                                }
                                $uibModalInstance.close();
                                vm.ModalSpinner = false;
                            },function(error) {
                                vm.ModalSpinner = false;
                                //normal error
                                errorNotificationService.showErrorNotification(error);
                            })
                        };
                        vm.cancel = function () {
                            vm.showAssignActivityModalSpinner = false;
                            $uibModalInstance.close();
                        };
                        vm.ExportCheckBtnClick = function (sectionName) {
                            var a = vm.ExportSelectedSection.indexOf(sectionName);
                            if (a == -1) {
                                vm.ExportSelectedSection.push(sectionName);
                                angular.forEach(vm.sections, function (item) { if (item.id == sectionName) item.checked = true; });
                                if (vm.ExportSelectedSection.length == vm.sections.length) {
                                    vm.isAllSelected = true;
                                }

                            }
                            else {
                                vm.ExportSelectedSection.splice(a, 1);
                                angular.forEach(vm.sections, function (item) { if (item.id == sectionName) item.checked = false; });
                                if (vm.ExportSelectedSection.length < vm.sections.length) {
                                    vm.isAllSelected = false;
                                }
                            }
                        }
                        vm.toggleAll = function () {
                            vm.isAllSelected = !vm.isAllSelected;
                            if (vm.isAllSelected === true) {
                                vm.ExportSelectedSection = [];
                                angular.forEach(vm.sections, function (item) { vm.ExportSelectedSection.push(item.id); });
                                angular.forEach(vm.sections, function (item) { item.checked = true; });
                            }
                            else {
                                vm.ExportSelectedSection = [];
                                angular.forEach(vm.sections, function (item) { item.checked = false; });
                            }
                        }
                    },
                    controllerAs: 'vm'
                });



              
            }

            vm.ExportCheckBtnClick = function (sectionName) {
                var a = vm.ExportSelectedSection.indexOf(sectionName);
                if(a == -1)
                    vm.ExportSelectedSection.push(sectionName);
                else
                    vm.ExportSelectedSection.splice(a, 1);
             
            }

            vm.getFileName = function (s) {
                var index = s.indexOf("=");
                index = index + 1;
                var filename = s.substring(index, s.length);
                filename = filename.replace(/"/g, '');
                return filename;
            }

            var _init = function () {
                annualAchievementMBRGIService.getCyclesList($state.params.entityID).then(getCyclesListSuccess, $rootScope.methodPublicError);                
            }()

            function getCyclesListSuccess(response) {
                var activeCycleId = null;
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data.length > 0) {
                        _data = $filter('orderBy')(_data, 'ID');
                        for (var i = 0; i < _data.length; i++) {
                            var cycle = _data[i];
                            if ($rootScope.currentLang == 'en') {
                                cycle.name = cycle.NameEn;
                            }
                            else {
                                cycle.name = cycle.NameAr;
                            }
                            if (cycle.ActiveCycle) {
                                activeCycleId = cycle.ID;
                                vm.cycleIndex = i;
                            }
                            vm.cycles.push(cycle);
                        }
                         if ($state.params.cycleID) {

                            vm.cycleID = $state.params.cycleID;
                            vm.cycleIndex = vm.cycles.findIndex(x => x.ID == vm.cycleID);
                         }
                         else if(activeCycleId && $state.params.cycleID == null)
                        {
                            vm.getAnnualAchievement(vm.cycleIndex);
                        }
                       
                       _getEntityAnnualAchievement(false);
                    }
                }

            }

            vm.getAnnualAchievement=function(index)
            {
                //var parameters = _cloneDeep( $state.params);
                vm.cycleID = vm.cycles[index].ID;
                vm.cycleIndex = index;
                //parameters.cycleID = cycleID;
                _getEntityAnnualAchievement(true);
                //$state.go($state.current, parameters, { reload: true });
            }

            $scope.$on('$destroy', function () {
                if (_unregisterOnTransitionSuccess) {
                    _unregisterOnTransitionSuccess();
                }

                if (_confirmSubmitAchievementsModalInstance && _confirmSubmitAchievementsModalInstance.close) {
                    _confirmSubmitAchievementsModalInstance.close();
                }
                if (_selectSectionPopup && _selectSectionPopup.close) {
                    _selectSectionPopup.close();
                }
                _selectSectionPopup
                sectionsMBRGIService.unsetSections();
            });
        }])
}