//var deepEqual = require('deep-equal');
import { isEqual as _isEqual} from 'lodash';
export default function (formlyBuilder) {
    formlyBuilder.controller('formlyBuilderController', ['$scope', '$state', 'dataItemsService', 'formBuilderApi', '$stateParams', '$translate', 'Notification', '$rootScope', '$uibModal', function ($scope, $state, dataItemsService, formBuilderApi, $stateParams, $translate, Notification, $rootScope, $uibModal) {
        $scope.formBuilderFields = [];
        $scope.formlyModel = {};
        $scope.formlyFields = [];
        $scope.formValid = true;
        $scope.currentLang = $translate.use();
        $scope.builderFieldsText = [];

        $scope.SelectedFormId = null;
        $scope.isNewForm = false;
        $scope.FormBeforeEdit = [];
        $scope.isFormChange = false;
      //  loading = $rootScope.loading;

        $translate(["COMMON.REQUIRED_USERNAME_OR_PASSWORD", "FORMLY_BUILDER.MAIN_ELEMENTS", 'FORMLY_BUILDER.ConfirmationMsg', 'FORMLY_BUILDER.ConfirmationHeader', 'FORMLY_BUILDER.WarningMsg', "FORMLY_BUILDER.MSG_ADDED_SUCCESSFULLY", "FORMLY_BUILDER.DuplicateFieldSystemKey", "COMMON.ACTIVE_CYCLE"]).then(function (translations) {
           
            $scope.labels = {
                RequiredUsernameOrPassword: translations['COMMON.REQUIRED_USERNAME_OR_PASSWORD'],
                FORMLY_BUILDER: translations['FORMLY_BUILDER.MAIN_ELEMENTS'],
                ConfirmationMsg: translations['FORMLY_BUILDER.ConfirmationMsg'],
                ConfirmationHeader: translations['FORMLY_BUILDER.ConfirmationHeader'],
                WarningMsg: translations['FORMLY_BUILDER.WarningMsg'],
                MSG_ADDED_SUCCESSFULLY: translations['FORMLY_BUILDER.MSG_ADDED_SUCCESSFULLY'],
                DuplicateFieldSystemKey: translations['FORMLY_BUILDER.DuplicateFieldSystemKey'],
                CURRENT_CYCLE_EXIST: translations["COMMON.ACTIVE_CYCLE"]

                }
        });




        $scope.isRtl = function () {
       
            if ($scope.currentLang == "en")
                return true;
        }
        var _intialize = function () {
            $scope.SelectedFormId = parseInt($stateParams.sectionID);
           
            $scope.ViewMode = true;
           
            $scope.formValid = true;
           dataItemsService.setBuilderFields($scope.formBuilderFields);
            _getById();
            _getAbstractVisibleRequiredToList();
            _getAvailableLookups();
        }
        var _getAbstractVisibleRequiredToList = function () {
            $rootScope.Loading();
            formBuilderApi.GetAbstractVisibleRequiredToList().then(function (response) {
               
                if (response) {
                    dataItemsService.setVisibleRequiredEntities(response);
                }
                $rootScope.Loading();
            }).catch(function (error) {
                $translate("COMMON.INTERNAL_SERVER_ERROR").then(function (msg) {
                    Notification.error(msg);
                });
            });
        }
        var _getAvailableLookups = function () {
            $rootScope.Loading();
            formBuilderApi.GetAvailableLookups().then(function (response) {
             
                if (response) {
                    dataItemsService.setLookups(response);
                }
                $rootScope.Loading();
            }).catch(function (error) {
                $translate("COMMON.INTERNAL_SERVER_ERROR").then(function (msg) {
                    Notification.error(msg);
                });
            });
        }
        var _getById = function () {

            $scope.formBuilderFields = [];
            $scope.isNewForm = false;
                $rootScope.Loading();
            formBuilderApi.getById($scope.SelectedFormId).then(function (response) {

             
                if (response) {
                    //const date1 = new Date();
                    convertInputToNumeric(response.FormlyFieldDefinition)
                    $scope.formBuilderFields = response.FormlyFieldDefinition ;
                    $scope.ViewMode = !response.IsSectionsEditable;
                    $scope.formName = response.SectionName;
                    dataItemsService.setBuilderFields($scope.formBuilderFields);
                    $scope.FormBeforeEdit = angular.copy(response.FormlyFieldDefinition);
                    dataItemsService.setSectionsEditable($scope.ViewMode);
                    if ($scope.formBuilderFields.length == 0)
                        $scope.isNewForm = true;
                    //const date2 = new Date();
                    //const diffTime = Math.abs(date2.getTime() - date1.getTime());
                }
                $rootScope.Loading();
            }).catch(function (error) {
                $translate("COMMON.INTERNAL_SERVER_ERROR").then(function (msg) {
                    Notification.error(msg);
                });
            });
        }

        var convertInputToNumeric= function(theObject) {
            var result = null;
            if (theObject instanceof Array) {
                for (var i = 0; i < theObject.length; i++) {
                    result = convertInputToNumeric(theObject[i]);
                    if (result) {
                        break;
                    }
                }
            }
            else {
                if (theObject.hasOwnProperty('fieldGroup')) {
                    result = convertInputToNumeric(theObject.fieldGroup);
                }

                else if (theObject.templateOptions && theObject.templateOptions.hasOwnProperty('fields')) {
                    result = convertInputToNumeric(theObject.templateOptions.fields);

                }

                else if (theObject.templateOptions &&  theObject.templateOptions.type == "number") {
                    theObject.data.Type = "numericInput";
                }
            }
            return result;
        }

        var convertNumericToInput = function (theObject) {
            var result = null;
            if (theObject instanceof Array) {
                for (var i = 0; i < theObject.length; i++) {
                    result = convertNumericToInput(theObject[i]);
                    if (result) {
                        break;
                    }
                }
            }
            else {
                if (theObject.hasOwnProperty('fieldGroup')) {
                    if (!theObject.data.VisibleRequiredTos || theObject.data.VisibleRequiredTos.length == 0)
                        $scope.formValid = false;
                    result = convertNumericToInput(theObject.fieldGroup);
                }

                else if (theObject.templateOptions && theObject.templateOptions.hasOwnProperty('fields')) {
                    if (!theObject.data.VisibleRequiredTos || theObject.data.VisibleRequiredTos.length == 0)
                        $scope.formValid = false;
                    result = convertNumericToInput(theObject.templateOptions.fields);

                }

                else if (theObject.data.Type == "numericInput") {
                    theObject.data.Type = "input";
                    if (!theObject.data.MetaOptions || !theObject.data.MetaOptions.templateOptions || !theObject.data.MetaOptions.templateOptions.label || !theObject.data.VisibleRequiredTos || theObject.data.VisibleRequiredTos.length == 0) 
                        $scope.formValid = false;
                }
                else if (!theObject.data.MetaOptions || !theObject.data.MetaOptions.templateOptions || !theObject.data.MetaOptions.templateOptions.label || !theObject.data.VisibleRequiredTos || theObject.data.VisibleRequiredTos.length == 0) {
                    $scope.formValid = false;

                }
            }
            return result;
        }
        var ValidateJson = function (theObject) {
            var result = null;
            var valid = true;
            
            if (theObject instanceof Array) {
                for (var i = 0; i < theObject.length; i++) {
                    result = ValidateJson(theObject[i]);
                    if (result) {
                        break;
                    }
                }
            }
            else {
                if (theObject.hasOwnProperty('fieldGroup')) {
                    result = ValidateJson(theObject.fieldGroup);
                }

                else if (theObject.templateOptions.hasOwnProperty('fields')) {
                    result = ValidateJson(theObject.templateOptions.fields);

                }
              
                else if (!theObject.data.MetaOptions || !theObject.data.MetaOptions.templateOptions || !theObject.data.VisibleRequiredTos || theObject.data.VisibleRequiredTos.length >0  ) {
                    $scope.formValid = false;
                    
                }
            }
            return valid;
        }
        $scope.save = function (formBuilderFields) {

            $scope.formValid = true;
            $scope.builderFieldsText = angular.copy(formBuilderFields);
            convertNumericToInput($scope.builderFieldsText);
            //validate is form Valid;
            if (!$scope.formValid) {
                Notification.warning({ message: $scope.labels.WarningMsg });
                return;
            }

            ///in case new form
            if ($scope.isNewForm) {
                $scope.submitForm(formBuilderFields);
            }
            else {  // in case update form fileds
                
                //Change if some changes happen 
                if (_isEqual($scope.FormBeforeEdit , $scope.builderFieldsText)) {
                    $scope.submitForm(formBuilderFields);
                    return;
                }

                var labels = $scope.labels;
                var submitForm = $scope.submitForm;
                $scope.Modal = $uibModal.open({
                    keyboard: true,
                    size: 'md modal-dialog-centered',
                    animation: true,
                    templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
                    controller: function ($uibModalInstance) {
                        var vm = this;
                        
                        vm.header = labels.ConfirmationHeader;
                        vm.message = labels.ConfirmationMsg;
                        vm.ok = function () {
                            $uibModalInstance.close({ Close: false, Ok: true });
                        };
                        vm.cancel = function () {
                            $uibModalInstance.close({ Close: true, Ok: false });
                        };
                    },
                    controllerAs: 'vm'
                });
                $scope.Modal.result.then(function (result) {
                    if (result.Ok) {
                        submitForm(formBuilderFields)
                    }
                }, function (error) {
                    //backdrop clicked or error
                });
            }
        }

        $scope.submitForm = function (formBuilderFields) {

            $rootScope.Loading();
            return formBuilderApi.UpdateSectionForm($scope.SelectedFormId, $scope.builderFieldsText).then(function (result) {
                $rootScope.Loading();
                if (result == 1001) {

                    $state.transitionTo('MBRGI.FormBuilder', { sectionID: $scope.SelectedFormId});
                    Notification.success({ message: $scope.labels.MSG_ADDED_SUCCESSFULLY });
                    // $state.transitionTo('MBRGI.SectionList', null);

                }
                else if (result == 19) {
                    //display Error 1003 ===>No  Active cycle
                    Notification.error({ message: $scope.labels.CURRENT_CYCLE_EXIST });
                    $state.transitionTo('MBRGI.FormBuilder', { sectionID: $scope.SelectedFormId });
                }
                else if (result == 1003) {
                    //display Error 1003 ===>CorruptedFieldInfo
                }
                else if (result == 1004 || result==1007) {
                    //display Error 1003 ==> DuplicateFieldSystemKey
                  
                        Notification.warning({ message: $scope.labels.DuplicateFieldSystemKey });
                        //_getById();
                    
                }
                else {
                    $translate("COMMON.INTERNAL_SERVER_ERROR").then(function (msg) {
                        Notification.error(msg);
                    });
                }

            }).catch(function (err) {
                $translate("COMMON.INTERNAL_SERVER_ERROR").then(function (msg) {
                    Notification.error(msg);
                });
            });
        };
        $scope.formlyModel = {};
        $scope.options = {
            formState: {
                filesPublicReadingPath: '/UploadedFiles/DynamicForms/',
                filesStorageKey: 'EntityProfileForm'
            }
        };
        $scope.formlyFields = [];
        $scope.preview = function () {
            $scope.formlyModel = {};
           
            $scope.formlyFields = [];
            var Fields = angular.copy($scope.formBuilderFields);
            $scope.formlyFields = JSON.parse(JSON.stringify(Fields));
        }
        var _initilizeDataItemsObject = function () {
            return {
                type: "object", properties: {}
            }
        }
        $scope.LoadCustom = function () {
            
        }
        $scope.Clear = function () {
            if ($stateParams.sectionID == 1) {
                $translate("FORMLY_BUILDER.CannotClearForm").then(function (msg) {
                    Notification.error(msg);
                });
                return;
            }
            $scope.formBuilderFields = [];
            $scope.formlyFields = [];
            var Fields = angular.copy($scope.formBuilderFields);
            $scope.formlyFields = JSON.parse(JSON.stringify(Fields));
            dataItemsService.setBuilderFields($scope.formBuilderFields);
        }
        _intialize();
        
    }]);
};
