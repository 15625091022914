import { remove as _remove, map as _map } from 'lodash';
export default function (app) {

    app.controller("MBRGIBeneficiariesReport", ['$scope', 'reportsService', 'exportFileService', 'uiLoadingIndicators', 'taskTypesConstant', 'sectionStatusConstant', 'errorNotificationService', '$stateParams', 'routeConstants', '$state', '$rootScope', '$uibModal', '$timeout', 'actionsTypesConstant', 'successrNotificationService', 'sectionsService', '$translate', 'Excel', '$filter', '$document',
        function ($scope, reportsService,exportFileService, uiLoadingIndicators, taskTypesConstant, sectionStatusConstant, errorNotificationService, $stateParams, routeConstants, $state, $rootScope, $uibModal, $timeout, actionsTypesConstant, successrNotificationService, sectionsService, $translate, Excel, $filter,$document) {
            var vm = this;
            vm.labels = {
                ALL: $translate.instant('REPORTS.ALL'),
                SECTIONS: $translate.instant('COMMON.SECTIONS'),
                ENTITIY: $translate.instant('COMMON.ENTITIY'),
                PILLAR: $translate.instant('COMMON.PILLAR'),
                MBRGI: $translate.instant('COMMON.MBRGI'),
                Countries: $translate.instant('REPORTS.Countries'),
                Countries_Total_Cost: $translate.instant('REPORTS.Countries_Total_Cost'),
                Countries_Total_BENEFICIARIES: $translate.instant('REPORTS.Countries_Total_BENEFICIARIES'),
            }



            vm.cycles = [];
            vm.activeCycleId = 0;
            $scope.$watch('vm.activeCycleId', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    vm.displayExport = false;
                }
            }, true);
            $scope.$watch('vm.entitiesIdList', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    vm.displayExport = false;
                }
                var oldValueIndex = oldValue.indexOf("0");
                var newValueIndex = newValue.indexOf("0");
                if ((oldValueIndex == -1) && (newValueIndex !== -1)) {
                    vm.entitiesIdList = ["0"];
                }
                else if (oldValueIndex !== -1 && newValueIndex !== -1 && newValue.length > 1) {
                    var index = vm.entitiesIdList.indexOf("0");
                    vm.entitiesIdList.splice(index, 1);
                }

            }, true);
            vm.entitiesIdList = [];
            vm.selectedEntitiesIdList = [];
            vm.coulmnKeys = {};
            vm.levels = [];
            vm.displayReport = false;
            vm.displayExport = false;
            vm.displayCharts = false;
            vm.isOneChart = false;
            vm.isEmptyList = false;
            vm.entities = new Object();
            vm.reportInfo = [];
            vm.totalResuilts = [];
            vm.sectionData = {};
            vm.Loading = $rootScope.Loading;
            vm.loadingPdfFile = false;
            vm.IsMBRGI = true;
            vm.downloadPdf = function () {
                vm.loadingPdfFile = true;

                var repo = $document[0].getElementById("ReportContent").innerHTML;
                var Data = { value: repo };
                var entityLevelID = $rootScope.enums.ReportLevels['Entities'];
                var reportType = $rootScope.enums.ReportTypes['Beneficiaries'];
                var exportFile = exportFileService.exportPdf(Data, entityLevelID, reportType);
                Promise.all([exportFile]).then(function (res) {
                    vm.loadingPdfFile = false;
                    $scope.$apply();

                });
            };
            var _getAllFilterList = function () {
                uiLoadingIndicators.load('report', true);

                var cyclesList = reportsService.getCyclesList();
                var entitiesList = reportsService.getEntitiesList();

                getFiltersRequests(cyclesList, entitiesList);

            }
            function getFiltersRequests(cyclesList, entitiesList) {
                Promise.all([cyclesList, entitiesList]).then(function (res) {
                    if (res != undefined) {
                        _getCyclesList(res[0]);
                        getEntitiesList(res[1]);
                        $scope.$apply();
                    }
                    uiLoadingIndicators.load('report', false);

                });
            }

            var _getCyclesList = function () {
                uiLoadingIndicators.load('report', true);
                reportsService.getCyclesList().then(function (response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        if (_data.length > 0) {
                            vm.cycles = _map(_data, (cycle) => {
                                if ($rootScope.currentLang == 'en') {
                                    cycle.name = cycle.NameEn;
                                }
                                else {
                                    cycle.name = cycle.NameAr;
                                }
                                if (cycle.ActiveCycle) {
                                    vm.activeCycleId = cycle.ID;
                                }
                                return cycle;
                            });
                            if (vm.activeCycleId == 0) {
                                vm.cycles = $filter('orderBy')(vm.cycles, 'CreationDate', 'reverse');
                                vm.activeCycleId = vm.cycles[0].ID;
                            }
                        }
                    } else {

                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('report', false);

                }, function (error) {
                    uiLoadingIndicators.load('report', false);

                })

            }
            function getEntitiesList(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data != null && !(angular.equals({}, _data))) {
                        vm.entities = _data;
                        var g = { Key: 0, Value: vm.labels.ALL }
                        vm.entities.splice(0, 0, g);
                    }
                } else {

                    errorNotificationService.showErrorNotification(response.responseCode);
                }


            }



            function getReports(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    
                    var _data = response.responseObject;
                    vm.reportData = _data;

                    // vm.reportInfo = _data.Result;
                    // vm.totals = _data.ResultTotals;
                    // vm.coulmnKeys = _data.ColumnName;

                    if (vm.reportData.length > 0 && vm.reportData) {

                        vm.displayReport = true;
                        vm.displayExport = true;


                    }
                    else {
                        vm.displayReport = false;
                        vm.isEmptyList = true;
                    }
                }

            }
            var _init = function () {
                vm.Loading();

                _getAllFilterList();
                vm.Loading();


            }();

            var _checkIfAllSelected = function () {
                var index = vm.entitiesIdList.indexOf("0")
                if (index !== -1) {
                    vm.selectedEntitiesIdList = vm.entities.map(x => { return x.Key });
                    var indexZero = vm.selectedEntitiesIdList.indexOf("0");
                    vm.selectedEntitiesIdList.splice(indexZero, 1);
                }
                else {
                    vm.selectedEntitiesIdList = vm.entitiesIdList;
                }

            }
            var _initReportData = function () {
                vm.displayCharts = false;
                vm.displayReport = false;
                vm.isOneChart = false;
                vm.isEmptyList = false;
                _checkIfAllSelected();
            }
            vm.GenerateReport = function () {
                uiLoadingIndicators.load('report', true);
                _initReportData();
                var report = reportsService.getBeneficiariesReportInfo(vm.activeCycleId, vm.selectedEntitiesIdList);
                Promise.all([report]).then(function (res) {
                    if (res != undefined) {

                        getReports(res[0]);
                        $scope.$apply();
                    }
                    uiLoadingIndicators.load('report', false);

                }, function (error) {
                    errorNotificationService.showErrorNotification(error);
                    uiLoadingIndicators.load('report', false);
                });



            } 
            vm.exportToexcel = function (tableId) {
                vm.loadingExcelFile = true;
                var exportFile = exportExcel();
                Promise.all([exportFile]).then(function (res) {
                    vm.loadingExcelFile = false;
                    $scope.$apply();
                });
            };
            var exportExcel = function () {
                return reportsService.exportBeneficiariesReportInfo(vm.activeCycleId, vm.selectedEntitiesIdList).then(function (response) {
                    exportFileService.exportExcel(response);

                }, function (error) {

                    //normal error
                    errorNotificationService.showErrorNotification(error);
                });
            }

            vm.GetTolalByColumnName = function (key) {
                var total = 0;
                _map(vm.reportData, (entityItem) => {
                    total += entityItem.ResultTotals[key];
                });
                return total;
            }

        }])
}