export default function (formlyBuilder) {
    formlyBuilder.controller('hijriDatepickerConfigController', ['$scope', '$translate', function ($scope, $translate) {
        $scope.integerRegex = /-?[0-9]*$/;
        $scope.isopen = false;
        $scope.currentLang = $translate.use();
        $scope.resetTemplateOptions = function () {
            $scope.fieldConfigCtrl.formBuilderField.templateOptions.minDate = null;
            $scope.fieldConfigCtrl.formBuilderField.templateOptions.maxDate = null;
        }
    }]);
};

