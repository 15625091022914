export default function (app) {
	app.controller('addEntityController', AddEntityController);

    AddEntityController.$inject = ["$rootScope", "entityDataService", "$uibModalInstance", "Params", "Notification","$translate"];
    function AddEntityController($rootScope, entityDataService, $uibModalInstance, Params, Notification, $translate) {
        var vm = this;

        $translate(["ENTITIES.ENTITY_EXIST", "ENTITIES.ENTITY_ADDED",'COMMON.EMAIL_USED_NOTIFICATION']).then(function (translations) {
            vm.labels = {
                UserExist: translations['COMMON.EMAIL_USED_NOTIFICATION'],
                EntityExist: translations['ENTITIES.ENTITY_EXIST'],
                EntityAdded: translations['ENTITIES.ENTITY_ADDED'],
            }
        });

        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.userRoleEnum = $rootScope.enums.userRole;
        vm.IsProfileExist = false;
        vm.confirmAddingEntity = confirmAddingEntity;
		vm.Loading();
		vm.entity = {};
		vm.entityShortForm = [];
		vm.entityShortFormModel = {};
		vm.load = load;
		vm.cancel = cancel;
		vm.addEntity = addEntity;
		vm.createEntity = createEntity;
		vm.getEntityShortForm = getEntityShortForm;
		vm.load();

		function load() {
			vm.getEntityShortForm();
			vm.Loading();
		}

		function getEntityShortForm() {
			vm.Loading();
			entityDataService.GetEntityShortFormDescription().then(showEntityShortForm, $rootScope.methodPublicError);
		}

		function showEntityShortForm(res) {
			if (res.responseCode === $rootScope.enums.ResponseCode.Success) {
				vm.entityShortForm = [];

				var fieldGroup = {
					wrapper: [],
					fieldGroup: []
				};

				for (var i = 0; i < res.responseObject.length; i += 2) {
                    
					var horizontalFieldGroup = {
						wrapper: ["horizontalLayout"],
						fieldGroup: []
					};
					res.responseObject[i].className = '';
					horizontalFieldGroup.fieldGroup.push(res.responseObject[i]);

					//if (res.responseObject.length % 2 == 0) {
                    if ((i + 1) < res.responseObject.length) {
						//res.responseObject[i + 1].className = '';
						horizontalFieldGroup.fieldGroup.push(res.responseObject[i + 1]);
					}

					fieldGroup.fieldGroup.push(horizontalFieldGroup);
				}


				vm.entityShortForm.push(fieldGroup);


				vm.Loading();
			}
			else {
				$rootScope.methodPublicError();
			}
		}

		function cancel() {
			$uibModalInstance.close({ Close: true, Ok: false });
		}

        function addEntity() {
            vm.Loading();
            entityDataService.CreateEntity(vm.entityShortFormModel).then(createEntitySuccess, $rootScope.methodPublicError);
		}




		function createEntity() {
			//Hosam: TODO: Create validation to check if the user already exists and show a modal for the user
			//with ok/ cancel buttons.
			//vm.entityShortFormModel.AdminEmail
			vm.Loading();
			entityDataService.CheckEntityUniqueName(vm.entityShortFormModel).then(CheckEntityUniqueNameSuccess, $rootScope.methodPublicError);
            
			
		}
		function CheckEntityUniqueNameSuccess(res)
		{
			vm.Loading();
			if (res.responseCode === vm.ResponseCode.NotExist) {
				vm.Loading();
				entityDataService.IsEntityAdminUserExist(vm.entityShortFormModel.AdminEmail, vm.userRoleEnum['Entity Admin']).then(IsEntityAdminUserExistSuccess, $rootScope.methodPublicError);
			}
			else {

                Notification.error({ message: vm.labels.EntityExist });
			}
		}
        function IsEntityAdminUserExistSuccess(res) {
            vm.Loading();
            if (res.responseCode == vm.ResponseCode.MBRGIUser) {
                Notification.warning({ message: vm.labels.UserExist });
            }
            else if (res.responseCode == vm.ResponseCode.UserProfileExist) {

                vm.IsProfileExist = true;
            }
            else if (res.responseCode == vm.ResponseCode.NotExist) {
                //create entity
                vm.addEntity();
            }
         
        }
        function confirmAddingEntity() {
            vm.addEntity();
        }

		function createEntitySuccess(res) {
			if (res.responseCode === $rootScope.enums.ResponseCode.Duplicated)
            {
                Notification.error({ message: vm.labels.EntityExist });
				vm.Loading();
			}
			else if (res.responseCode === $rootScope.enums.ResponseCode.Success) {
                Notification.success({ message: vm.labels.EntityAdded });
                $uibModalInstance.close({ Close: false, Ok: true });
                vm.Loading();
			}
			else {
				$rootScope.methodPublicError(res.responseObject);

			}
			
		}
	}
}