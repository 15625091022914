export default function (app) {

    app.factory("formBuilderApi", ["ApiPath", 'apiBase', function (ApiPath, apiBase) {
        var apiControllerUrl = "services/api/FormBuilder/";
        var name = "FormBuilder";
        var apiControllerUrl = ApiPath + name + "/";
        var _currentBuilderFields = [];
        var getById = function (Id) {
            return apiBase.Get(apiControllerUrl + "GetSectionFormForEdit", { sectionID: Id });
           
        }
        var updateFormBuilderData = function (FormBuilderJsonVM) {
            return;
            //return baseApi.Post(apiControllerUrl + "UpdateFormBuilderData", null, FormBuilderJsonVM);
        }

        var setBuilderFields = function (builderFields) {
            _currentBuilderFields = builderFields;
        }

        var getBuilderFields = function () {
            return _currentBuilderFields;
        }

        var _GetAbstractVisibleRequiredToList = function () {
            return apiBase.Get(apiControllerUrl + "GetAbstractVisibleRequiredToList",null);
        }
        var _UpdateSectionForm = function (sectionID, formDefinition) {
            return apiBase.Post(apiControllerUrl + "UpdateSectionForm", { sectionID: sectionID }, formDefinition);
        }
        var _GetAvailableLookups = function () {
            return apiBase.Get(apiControllerUrl + "GetAvailableLookups", null,null);
        }
        return {
            getById: getById,
            updateFormBuilderData: updateFormBuilderData,
            setBuilderFields: setBuilderFields,
            getBuilderFields: getBuilderFields,
            GetAbstractVisibleRequiredToList: _GetAbstractVisibleRequiredToList,
            UpdateSectionForm: _UpdateSectionForm,
            GetAvailableLookups: _GetAvailableLookups,
        };
    }]);
}