export default {
    ASSIGN_ACTIVITY: 'Assign activity',
    ADD_ACTIVITY: 'Add new activity',
    ASSIGN_SECTION_TO_USER: 'Assign section to user',
    SELECTED_SECTIONS: 'selected sections',
    ASSIGN: 'Assign',
    ASSIGN_USERS: 'Assign users',
    APPROVED: 'Approved',
    SENT_BACK: 'Sent back',
    REPLY: 'Reply',
    ASSIGN_USER: 'Assign User',
    NEW: 'New',
    EDITING: 'Editing',
    REVIEWING: 'Under Review',
    REVIEWED: 'Reviewed',
    SENT_BACK: 'Sent back',
    CREATE: 'Create',
    REASSIGN: 'Reassign',
    REOPEN: 'Reopen',
    EDITOR: 'Editor',
    REVIEWER: 'Reviewer',
    SAVE: 'Save',
    SUBMIT: 'Submit this Section',
    APPROVE: 'Approve',
    SENDBACK: 'Send back',
    NO_ACTIVE_CYCLE:'There is no active cycle in the current time',

    DELETE: 'Delete',
    DELETEQUESTION: 'Are you sure you want to delete ',
    SUBMIT_ACHIEVEMENTS: 'Final Submission to MBRGI',
    CONFIRM_SUBMIT_ACHIEVEMENTS: 'You are about to submit all your achievements. Are you sure you want to proceed?',
    ASSIGN: 'Assign',
    EDIT: 'Edit',
    VIEW: 'View',
    HISTORY: 'History',
    AnnualAhievementSumbitted: 'You are not allowed to submit your annual achievements, it’s already approved',
    ASSIGN_SECTIONS: 'Assign Sections',
    NO_LONGER_ASIGNED_SECTION:'This section is no longer assigned to you.',
}

