import trustAsHtmlFilter from './app.trustAsHtml.filter';
import cardTextFilter from './app.cardText.filter';
import ngArabicDateFilter from './app.arabicDate.filter';
import highlightMatchedFilter from './app.highlightMatched.filter';

export default function (app) {
  trustAsHtmlFilter(app);
    cardTextFilter(app);
	ngArabicDateFilter(app);
	highlightMatchedFilter(app);
}
