import { random as _random} from 'lodash';
export default function (app) {
    app.controller('updateUserController', UpdateUserController);

    UpdateUserController.$inject = ["$scope", "$rootScope", "userDataService", "roleDataService", "$uibModalInstance", "Params", "Notification", "fileUploadService", "$translate","Upload"];
    function UpdateUserController($scope, $rootScope, userServiceApi, roleDataService, $uibModalInstance, Params, Notification, fileUploadService, $translate, Upload) {
        var vm = this;
        $translate(["COMMON.USER_UPDATED_NOTIFICATION","COMMON.USER_CHANGE_ROLE_NOTIFICATION"]).then(function (translations) {
            vm.labels = {
                UserUpdated: translations['COMMON.USER_UPDATED_NOTIFICATION'],
                ChangeRole: translations['COMMON.USER_CHANGE_ROLE_NOTIFICATION'],
            }
        });
        
        vm.Loading = $rootScope.Loading;
        vm.user = {};
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.load = load;
        vm.cancel = cancel;
        vm.Params = Params;
        vm.currentEntityID = null;
        vm.roles = null;
        vm.updateUser = updateUser;
        vm.uploadImage = uploadImage;
        vm.file = null;
        vm.selectImage = selectImage;
        vm.entities = null;
        vm.UpdateRole = true;
        vm.load();
        vm.entityAdminRoleId = $rootScope.enums.userRole['Entity Admin'];
        vm.displayDownloadButton = true;
        function load() {
            vm.Loading();

            var currentUser = $rootScope.GetCurrentUser();
            if (currentUser != null && currentUser !== undefined) {
                if (currentUser.RoleSection === 'MBRGI') {
                    vm.UpdateRole = false;
                }
            }
            
            if (vm.Params.entityID === null || vm.Params.entityID === undefined)
                vm.currentEntityID = currentUser.CurrentEntity;
            else
                vm.currentEntityID = vm.Params.entityID
            //get Roles lookup data
            var roleCategory = vm.currentEntityID ? 2 : 1;
            roleDataService.GetRolesLookupData(roleCategory).then(GetRolesLookupDataSuccess, $rootScope.methodPublicError);
        }

        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false});
        }

        function updateUser(user) {
            vm.Loading();

            //var currentEntityID = $rootScope.GetCurrentUser().CurrentEntity;
            if (vm.file != null) {
                vm.uploadImage(vm.file);

            }
            else {
                userServiceApi.UpdateUser(user, vm.currentEntityID).then(updateUserSuccess, $rootScope.methodPublicError);
            }
            
        }

        function uploadImage(image) {
            if (image != null) {
                fileUploadService.UploadFiles(image, $rootScope.enums.UploadType.ProfilePic)
                    .then(uploadFilesSuccess, $rootScope.methodPublicError);
            }
        }

        function uploadFilesSuccess(result) {
            
            var uploadResult = result.data;
            if (uploadResult.status == 1 && uploadResult.files) {
                vm.user.ImagePath = uploadResult.files[0];
                userServiceApi.UpdateUser(vm.user, vm.currentEntityID).then(updateUserSuccess, $rootScope.methodPublicError);
            }
            else {
                $rootScope.methodPublicError();
                vm.Loading();
            }

        }

        function updateUserSuccess(response) {
            if (response.responseCode === vm.ResponseCode.CantChangeRoleExistTasks) {
                Notification.success({ message: vm.labels.ChangeRole });
                vm.Loading();
            }
            else if (response.responseCode === vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.UserUpdated });
                $uibModalInstance.close({ Close: false, Ok: true });
                vm.Loading();
            }
        }

        function getUserSuccess(response) {
            if (response.responseCode === vm.ResponseCode.Success) {
                vm.user = response.responseObject;
                vm.user.RoleId = vm.user.RoleId.toString();
                if (response.entities && response.entities.length > 0)
                vm.entities = response.entities;
            }
            else {
                $rootScope.methodPublicError();
            }
            vm.Loading();
        }

        function GetRolesLookupDataSuccess(res) {
            if (res.responseCode == vm.ResponseCode.Success && res.responseObject !== null) {
                vm.roles = res.responseObject;
                userServiceApi.GetUserByID(vm.Params.user.ID, vm.currentEntityID).then(getUserSuccess, $rootScope.methodPublicError);
            }
            else {
                $rootScope.methodPublicError();
                vm.Loading();
            }
        }

        function selectImage(file, errFiles) {
            vm.displayDownloadButton = false;
            if (file || errFiles) {
                vm.errFile = errFiles && errFiles[0];
                var _fileName = file.name.split('.')[0] + "_" + _random(1, 99999, false) + '.' + file.name.split('.')[1];
                var _file = Upload.rename(file, _fileName);
                vm.file = _file;
            }
        }
    }
}