//Import controllers
import countryController from './Controllers/app.country.controller';
import updateController from './Controllers/app.update-country.controller';
import addController from './Controllers/app.add-country-controller';
import restoreController from './Controllers/app.restore-country.controller';
//Import services
import dataService from './Services/country.data.service';
//Import config
import './Localization';

export default function (app) {
    countryController(app);
    updateController(app);
    addController(app)
    restoreController(app);
    dataService(app);
}