export default function (app) {
    app.controller('headerController', HeaderController);
    HeaderController.$inject = ["$scope", "$rootScope", "$state", "$interval", "homeNotificationService", "$translate", "$cookies", "changeLanguageHook", "$window", "routeConstants", "errorNotificationService", "$transitions"] ;
    function HeaderController($scope, $rootScope, $state, $interval, homeNotificationService, $translate, $cookies, changeLanguageHook, $window, routeConstants, errorNotificationService, $transitions) {
        var vm = this;
        $scope.currentLang = $translate.use();
        vm.CycleID = $rootScope.cycleID;
        vm.NOTIFICATION_TYPES = $rootScope.enums.NOTIFICATION_TYPES;
        vm.changeLanguage = function changeLanguage() {
            var lang = $scope.currentLang == "en" ? 'ar' : 'en';
           
			changeLanguageHook.canChangeLanguage().then(() => {

				$cookies.put('currentLanguage', lang);
				$window.location.reload();

				//$translate.use(lang).then(function uselangThen(l) {
				//
                // $rootScope.appDirection = l == "en" ? "ltr" : "rtl";
                //$scope.currentLang = l;
				//	$cookies.put('currentLanguage', l);
				//	$window.location.reload();
                //});
            }).catch(() => {
                
            })


        }
        
        var unseenNotificationCountInterival;
        vm.notifications = [];
        vm.notificationsPopoverLimit = 5;
        vm.unseenNotificationsCount = 0;
        vm.showNotificationsPopup = false
        vm.isNotificationClicked = false;
        vm.toggleNotificationPopup = function () {
            // get notification
            vm.isNotificationClicked = !vm.isNotificationClicked;  
                vm.load();          
        };

        vm.openNotification = function (type, params, CycleID, notification) {
            vm.CycleID = $rootScope.cycleID;
            switch (type) {
                case vm.NOTIFICATION_TYPES.OnTaskAssignment:
                case vm.NOTIFICATION_TYPES.OnTaskRejection:
                    { //TODO: Navigate to page x with notication.params
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(34);
                            break;
                        }
                        $state.transitionTo('Entity.Tasks.Task', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnSectionAssigned:
                    {
                        $state.transitionTo('Entity.SectionsAssignment', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnCycleReminders:
                    {
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        $state.transitionTo('Entity.AnnualAchievement', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityProfileUpdate:
                    {
                        $state.transitionTo('MBRGI.EntityProfile', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntitySharedInitiatives:
                    {
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        $state.transitionTo('Entity.SharedInitiatives.SharedInitiative', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityAddSharedInitiativeComment:
                    {
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        $state.transitionTo('Entity.AnnualAchievement.Submission', { sectionID: null, activityID: params.activityID, isCycleEnabled: true, cycleActive: true });
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityRemoveSharedInitiatives:
                    {
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        $state.transitionTo('Entity.SharedInitiatives.SharedInitiative', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityApprovedSharedInitiatives:
                    {
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        $state.transitionTo('Entity.SharedInitiatives.SharedInitiative', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityAchievementSubmitted:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo('MBRGI.MBRGIAnnualAchievements', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnMBRGIAchievementApproval:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("Entity.AnnualAchievement", param);
                        // $state.go('Entity.AnnualAchievement', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnMBRGIAchievementSentBack:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("Entity.AnnualAchievement", param);
                        // $state.go('Entity.AnnualAchievement', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnRequestEditingApproved:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("Entity.AnnualAchievement", param);
                        // $state.go('Entity.AnnualAchievement', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnRequestEditingRejected:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("Entity.AnnualAchievement", param);
                        // $state.go('Entity.AnnualAchievement', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnRequestEditing:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("MBRGI.Request", param);
                        // $state.go('Entity.AnnualAchievement', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityAnnualAchievementComment:
                    {
                        if (vm.CycleID == null) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        params.cycleID = vm.CycleID;
                        $state.transitionTo('MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.Submissions', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnMBRGIAnnualAchievementComment:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("Entity.AnnualAchievement", param);
                        break;
                    }
                default:
                    break;
            }
            vm.showNotificationsPopup = false;
            vm.markNotificationAsSeen(notification);
        };
        vm.markNotificationAsSeen = function (notification) {
            vm.showNotificationsPopup = false;
            if (!notification.seen) {
                vm.unseenNotificationsCount = Math.max(vm.unseenNotificationsCount - 1, 0);
                notification.seen = true;
                //TODO: send request for making notification seen
                homeNotificationService.markSeen(notification.id).then((response) => {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        vm.getUnseenNotificationCount();
                    }
                }).catch((error) => {

                });
            }
        }

        vm.load = function () {
            if ($rootScope.GetCurrentUser()&& $rootScope.GetCurrentUser().CurrentRoleID != 2 && $rootScope.GetCurrentUser().CurrentRoleID != null) {
                vm.getUnseenNotificationCount();

                if ($rootScope.cycleID == null)
                    homeNotificationService.getCyclesList().then(getCyclesListSuccess, $rootScope.methodPublicError);
                homeNotificationService.getNotification(vm.notificationsPopoverLimit).then((response) => {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        vm.notifications = response.responseObject;
                        if (vm.notifications.length != 0) {
                            if (vm.isNotificationClicked) {
                                vm.showNotificationsPopup = true;
                            } else
                                vm.showNotificationsPopup = false;
                        }
                    }
                }).catch((error) => {

                });

                if ($rootScope.cycleID == null)
                    homeNotificationService.getCyclesList().then(getCyclesListSuccess, $rootScope.methodPublicError);

            }

        };
        function getCyclesListSuccess(response) {

            if (response && response.responseCode == 1 && response.responseObject) {
                var _data = response.responseObject;
                if (_data.length > 0) {
                   
                    for (var i = 0; i < _data.length; i++) {
                        var cycle = _data[i];
                       
                        if (cycle.ActiveCycle) {
                            $rootScope.cycleID = cycle.ID;
                           
                        }
                    }
                    
                }
               
            }

        }
        vm.getUnseenNotificationCount = function () {
            homeNotificationService.getUnseenNotification(vm.notificationCallBack, () => { });
        }



        vm.notificationCallBack = function(response) {
            if (response && response.responseCode == 1 && response.responseObject) {
                vm.unseenNotificationsCount = response.responseObject;
            }
        }

        vm.closeNotfiPopover = function () {
            vm.showNotificationsPopup = false;
            vm.isNotificationClicked = false;
        }
        vm.showAllNotification = function () {
            vm.showNotificationsPopup = false;
           // return routeConstants.NotificationState.State;  
        }
        //unseenNotificationCountInterival = $interval(function () {
            //TODO: sent request to get the unseen notifications count
        homeNotificationService.startPull(vm.notificationCallBack, () => { });
            // vm.unseenNotificationsCount = response

        //}, 10000); //repeat the request every 1 min
        vm.load();

        //TODO: remove interival on scope destroy
        /*
        $scope.on('$destroy', function () {
            $interval.cancel(unseenNotificationCountInterival);
        });
        */
        var _onTransitionCallback = function (transition) {
            vm.isNotificationClicked = false;
        }
        var _NotificationTransition = $transitions.onBefore({ from: $state.current.name },
            _onTransitionCallback);
        $scope.$on('$destroy', function () {
            homeNotificationService.stopPull();
            if (_NotificationTransition) {
                _NotificationTransition();
            }
        });


    }
}