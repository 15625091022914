import { findIndex as _findIndex, isEqual as _isEqual } from "lodash";
import { diff } from 'deep-diff';

export default function (app) {
    app.controller("sectionSubmissionMBRGIController", ['$scope', 'annualAchievementMBRGIService', 'uiLoadingIndicators', '$rootScope', '$stateParams', 'sectionStatusConstant', 'taskTypesConstant', 'errorNotificationService', '$uibModal', '$state', 'actionsTypesConstant', 'sectionsMBRGIService', 'successrNotificationService', '$translate', 'routeConstants', 'formHelpersService', 'multiFileUploadService', 'Notification',
        function ($scope, annualAchievementMBRGIService, uiLoadingIndicators, $rootScope, $stateParams, sectionStatusConstant, taskTypesConstant, errorNotificationService, $uibModal, $state, actionsTypesConstant, sectionsMBRGIService, successrNotificationService, $translate, routeConstants, formHelpersService, multiFileUploadService, Notification) {
            var vm = this;
            var _viewHistoryModal = null;

            $translate(["ACHIEVEMENT.NEW", 'ACHIEVEMENT.EDITING', 'ACHIEVEMENT.REVIEWING', 'ACHIEVEMENT.REVIEWED', 'ACHIEVEMENT.SENT_BACK',
                'ACHIEVEMENT.CREATE', 'ACHIEVEMENT.REASSIGN', 'ACHIEVEMENT.REOPEN', 'ACHIEVEMENT.EDITOR', 'ACHIEVEMENT.REVIEWER',
                'ACHIEVEMENT.SAVE', 'ACHIEVEMENT.SUBMIT', 'ACHIEVEMENT.APPROVE', 'ACHIEVEMENT.SendBack', 'ANNUALACHIEVEMENTS.CANCEL_ACHIEVEMENTS', 'ANNUALACHIEVEMENTS.MBRGIViewerNotAllowToView']).then(function (translations) {
                    vm.labels = {
                        NEW: translations['ACHIEVEMENT.NEW'],
                        EDITING: translations['ACHIEVEMENT.EDITING'],
                        REVIEWING: translations['ACHIEVEMENT.REVIEWING'],
                        REVIEWED: translations['ACHIEVEMENT.REVIEWED'],
                        SENT_BACK: translations['ACHIEVEMENT.SENT_BACK'],
                        CREATE: translations['ACHIEVEMENT.CREATE'],
                        REASSIGN: translations['ACHIEVEMENT.REASSIGN'],
                        REOPEN: translations['ACHIEVEMENT.REOPEN'],
                        EDITOR: translations['ACHIEVEMENT.EDITOR'],
                        REVIEWER: translations['ACHIEVEMENT.REVIEWER'],
                        SAVE: translations['ACHIEVEMENT.SAVE'],
                        SUBMIT: translations['ACHIEVEMENT.SUBMIT'],
                        APPROVE: translations['ACHIEVEMENT.APPROVE'],
                        SendBack: translations['ACHIEVEMENT.SendBack'],
                        CANCEL_ACHIEVEMENTS: translations['ANNUALACHIEVEMENTS.CANCEL_ACHIEVEMENTS'],
                        MBRGIViewerNotAllowToView: translations['ANNUALACHIEVEMENTS.MBRGIViewerNotAllowToView'],
                    };

                });

            vm.form = {};
            vm.instructions = [];
            vm.actions = [];
            vm.model = {};
            vm.oldModel = {};
            vm.workflowInfo = {};
            vm.assignmentInfo = {};
            vm.sectionStatusConstant = sectionStatusConstant;
            vm.taskTypesConstant = taskTypesConstant;
            vm.options = {};
            vm.sectionTitle = '';
            vm.IsShared = false;
            vm.ShowCommentsAndHistory = true;
            vm.activityID = $state.params.activityID;
            vm.sectionkey = $state.params.sectionID;
            var _viewSharedCommentModal = null;
            var _viewCommentModal;
            vm.entityID = $state.params.entityID;
            vm.actionsTypesConstant = actionsTypesConstant;
            vm.comment = "";
            vm.isCycleEnabled = $state.params.isCycleEnabled ? JSON.parse($state.params.isCycleEnabled) : false;
            vm.viewMode = $state.params.viewMode ? JSON.parse($state.params.viewMode) : false;
            vm.isActionBtnLoading = {};
            vm.actionClicked = false;
            vm.cycleID = $state.params.cycleID;
            vm.IsSectionRequired = false;
            vm.isRtl = function () {
                return $translate.use() == 'ar' ? true : false;
            }
            vm.isDeleted = false;

            vm.EntityEditingFields = [];
            vm.MBRGIEditingFields = [];
            var _getSingleSectionDetails = function (sectionID, entityID, viewMode) {
                uiLoadingIndicators.load('sectionActivity', true);
                annualAchievementMBRGIService.getSingleSectionDetailsForMBRGI(sectionID, entityID, viewMode, $state.params.cycleID).then(function (response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        vm.activityInfo = _data.activityInfo;
                        vm.options.formState = _data.form.formState;
                        vm.model = _data.form.model;

                        vm.actions = _data.actions;
                        vm.workflowInfo = _data.workflowInfo;
                        vm.assignmentInfo = _data.assignmentInfo;
                        vm.hasSectionComments = _data.HasSectionComments;
                        vm.SectionCommentsCount = _data.SectionCommentsCount;
                        vm.hasHistory = _data.hasHistory;
                        vm.sectionTitle = vm.workflowInfo.type;
                        vm.isDeleted = _data.isDeleted;

                        vm.MBRGIEditingFields = _data.form.MBRGIEditingFields ? JSON.parse(_data.form.MBRGIEditingFields) : [];
                        vm.EntityEditingFields = _data.form.EntityEditingFields ? JSON.parse(_data.form.EntityEditingFields) : [];
                        vm.ShowCommentsAndHistory = _data.ShowCommentsAndHistory;
                        /*
                                                    {
                                "actionType": -1,
                                "actionName": "cancel"
                            },
                        */
                        vm.sectionActions = _data.actions;

                        //find save action then append a cancel action
                        var _saveActionIndex = _findIndex(vm.sectionActions, ['actionType', vm.actionsTypesConstant.SAVE]);
                        if (_saveActionIndex != -1) {
                            vm.sectionActions.splice(_saveActionIndex + 1, 0, { "actionType": -1, "actionName": vm.labels.CANCEL_ACHIEVEMENTS })
                        }

                        var _form = _data.form;
                        _form.fields.push({
                            type: 'input',
                            className: "d-none",
                            controller: ($timeout) => {
                                $timeout(function () {

                                    $('.form-group label span.truncate').each(
                                        function () {
                                            $(this).removeClass('truncate');
                                            var parentWidth = $(this).parent().parent().width();
                                            //var child = $(this).width();
                                            var childWidth = $(this).parent().width();

                                            if (childWidth >= parentWidth) {
                                                $(this).addClass('truncate');
                                            }
                                            else if (childWidth < 341) {
                                                $(this).addClass('truncate');
                                            }
                                        }
                                    );
                                }, 0);
                            }
                        })
                        configureForm(_form.fields);
                        _form.model.EntityEditingFields = vm.EntityEditingFields;
                        vm.form = _form;
                        vm.form.model.oldModel = angular.copy(_data.form.model)
                        vm.CheckIsSectionRequired();


                    } else {
                        if (response && response.responseCode == 323) {
                            Notification.error({ message: vm.labels.MBRGIViewerNotAllowToView });
                            $state.transitionTo('MBRGI.MBRGIAnnualAchievements');
                        }
                        else
                            //TODO show error msg
                            errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('sectionActivity', false);
                }, $rootScope.methodPublicError)
            }

            var _getSectionActivityDetails = function (activityID, entityID, viewMode) {
                uiLoadingIndicators.load('sectionActivity', true);
                annualAchievementMBRGIService.getSectionActivityDetails(activityID, entityID, viewMode, vm.cycleID).then(function (response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        vm.activityInfo = _data.activityInfo;

                        vm.MBRGIEditingFields = _data.form.MBRGIEditingFields ? JSON.parse(_data.form.MBRGIEditingFields) : [];
                        vm.EntityEditingFields = _data.form.EntityEditingFields ? JSON.parse(_data.form.EntityEditingFields) : [];
                        _data.form.model.EntityEditingFields = vm.EntityEditingFields;
                        configureForm(_data.form.fields);
                        vm.form = _data.form;
                        vm.options.formState = _data.form.formState;
                        vm.actions = _data.actions;
                        vm.workflowInfo = _data.workflowInfo;
                        vm.assignmentInfo = _data.assignmentInfo;
                        vm.form.model.oldModel = angular.copy(_data.form.model);
                        vm.isDeleted = _data.isDeleted;

                        vm.hasHistory = _data.hasHistory;
                        vm.hasComment = _data.hasComment;
                        vm.ShowCommentsAndHistory = _data.ShowCommentsAndHistory;
                        vm.hasSectionComments = _data.HasSectionComments;
                        vm.SectionCommentsCount = _data.SectionCommentsCount;
                        vm.IsShared = _data.form.model['initiative_SharedInitiative'];
                        vm.sectionActions = _data.actions;
                        vm.hideActivityComment = true;
                        vm.sectionTitle = vm.workflowInfo.type;
                        var _saveActionIndex = _findIndex(vm.sectionActions, ['actionType', vm.actionsTypesConstant.SAVE]);
                        if (_saveActionIndex != -1) {
                            vm.sectionActions.splice(_saveActionIndex + 1, 0, { "actionType": -1, "actionName": vm.labels.CANCEL_ACHIEVEMENTS })
                        }

                    } else {
                        //TODO show error msg
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('sectionActivity', false);
                }, function (error) {
                    uiLoadingIndicators.load('sectionActivity', false);
                    //TODO show error msg
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getStatName = function (statType) {
                if (statType == sectionStatusConstant.NEW) {
                    return vm.labels.NEW;
                } else if (statType == sectionStatusConstant.IN_PROGRESS) {
                    return vm.labels.EDITING;
                } else if (statType == sectionStatusConstant.SUBMITTED) {
                    return vm.labels.REVIEWING;
                }
                else if (statType == sectionStatusConstant.APPROVED) {
                    return vm.labels.REVIEWED;
                } else if (statType == sectionStatusConstant.SENT_BACK) {
                    return vm.labels.SENT_BACK;
                }
            }

            vm.isHighlightedText = function (statType, taskType) {
                var _edit = vm.taskTypesConstant.EDIT;
                var _review = vm.taskTypesConstant.REVIEW;
                if (taskType == _edit && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
                    return true;
                }

                if (taskType == _review && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
                    return true;
                }
            }

            vm.getSectionHistory = function () {
                var _sectionkey = $state.params.sectionID;
                var _activityID = $state.params.activityID;
                var _historyPromise = _activityID ? annualAchievementMBRGIService.getActivityHistory(_activityID) : annualAchievementMBRGIService.getSectionHistory(_sectionkey, vm.entityID, $state.params.cycleID);

                _historyPromise.then(function (response) {
                    var _labels = vm.labels;
                    if (response && response.responseCode == 1) {
                        if (document.getElementsByClassName("modal").length === 0)
                            _viewHistoryModal = $uibModal.open({
                                backdrop: true,
                                keyboard: true,
                                backdropClick: true,
                                template: require('../Views/historyModal.html'),
                                size: 'lg',
                                controllerAs: 'vm',
                                controller: ['$uibModalInstance', function ($uibModalInstance) {
                                    var vm = this;
                                    vm.actionsTypesConstant = actionsTypesConstant;
                                    vm.taskTypesConstant = taskTypesConstant;
                                    vm.history = response.responseObject;


                                    vm.getActionName = function (actionID) {
                                        if (actionID == actionsTypesConstant.SAVE) {
                                            return _labels.SAVE;
                                        } else if (actionID == actionsTypesConstant.SUBMIT) {
                                            return _labels.SUBMIT;
                                        } else if (actionID == actionsTypesConstant.APPROVE) {
                                            return _labels.APPROVE;
                                        }
                                        else if (actionID == actionsTypesConstant.SENDBACK) {
                                            return _labels.SENDBACK;
                                        }
                                    }

                                    vm.cancel = function () {
                                        $uibModalInstance.close();
                                    }

                                }]
                            })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                }, function (error) {
                    //TODO show error
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getSectionComments = function () {

                annualAchievementMBRGIService.getSectionComments($state.params.entityID, $state.params.sectionID, $state.params.cycleID).then(function (response) {
                    if (response && response.responseCode == 1) {
                        var _self = vm;
                        var sectionKey = $state.params.sectionID;
                        var entityId = $state.params.entityID;
                        if (document.getElementsByClassName("modal").length === 0)
                            _viewCommentModal = $uibModal.open({
                                keyboard: true,
                                animation: true,
                                template: require('../Views/commentsModal.html'),
                                size: 'lg',
                                controllerAs: 'vm',
                                controller: ['$uibModalInstance', 'annualAchievementService', function ($uibModalInstance, annualAchievementService) {
                                    var vm = this;
                                    //TODO add user role
                                    vm.sectionComments = response.responseObject.UsersComments;
                                    vm.CanAddComment = response.responseObject.CanAddComment;
                                    vm.addComment = function () {
                                        vm.ModalSpinner = true;
                                        annualAchievementService.submitEntitySectionComment(sectionKey, vm.replyComment, entityId).then((res) => {

                                            if (res) {
                                                var today = new Date().toISOString();
                                                vm.ModalSpinner = false;
                                                var newComment = { Comment: vm.replyComment, FullName: $rootScope.GetCurrentUser().FullName, ActionDataTime: today, ImagePath: $rootScope.GetCurrentUser().ImagePath };
                                                vm.sectionComments.splice(0, 0, newComment);
                                                _self.SectionCommentsCount++;
                                                vm.replyComment = '';
                                            }
                                        }, function (error) {
                                            errorNotificationService.showErrorNotification(error);
                                        })
                                    }
                                    vm.cancel = function () {
                                        $uibModalInstance.close();
                                    }
                                }]
                            })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                }, function (error) {
                    //TODO show error
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getCommentsList = function () {
                var _activityID = $state.params.activityID;

                annualAchievementMBRGIService.getCommentsList(_activityID).then(function (response) {
                    var _labels = vm.labels;
                    if (response && response.responseCode == 1) {
                        if (document.getElementsByClassName("modal").length === 0)
                            _viewSharedCommentModal = $uibModal.open({
                                backdrop: true,
                                keyboard: true,
                                backdropClick: true,
                                template: require('../Views/sharedInitiativeComments.html'),
                                size: 'lg',
                                controllerAs: 'vm',
                                controller: ['$uibModalInstance', function ($uibModalInstance) {
                                    var vm = this;
                                    vm.commentsList = response.responseObject;
                                    vm.comment = null;
                                    vm.commentMissed = false;
                                    vm.cancel = function () {
                                        $uibModalInstance.close();
                                    }

                                }]
                            })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, function (error) {
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.submitAction = function (actionId) {
                vm.actionClicked = vm.isActionBtnLoading[actionId] = true;
                if (actionId == vm.actionsTypesConstant.CANCEL) {
                    vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                    $state.go('MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement');
                }
                else {
                    vm.DefineEntityEditingFields();
                    var form = {
                        model: vm.form.model,
                        MBRGIEditingFields: vm.MBRGIEditingFields
                    };

                  //  var _files = formHelpersService.getFiles(vm.options.formState.filesStorageKey);
                    //if (_files && _files.length > 0) {

                    //    multiFileUploadService.upload(_files).then(function (resp) {
                    //        if (resp.status == 200) {
                    //            if ($state.params.activityID && actionId == vm.actionsTypesConstant.SAVE) {
                    //                annualAchievementMBRGIService.saveActivity($state.params.entityID, $state.params.activityID, form).then(function (response) {
                    //                    vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                    //                    if (response.responseCode == 1) {
                    //                        successrNotificationService.showSuccessSavingActivityNotfication(vm.sectionTitle);
                    //                    }
                    //                    $state.go('MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.Submissions', { sectionID: $state.params.sectionID });
                    //                }, function (error) {
                    //                    vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                    //                    errorNotificationService.showErrorNotification(error);
                    //                });
                    //            } else {
                    //                annualAchievementMBRGIService.submitSingleSectionAction($state.params.entityID, $state.params.sectionID, actionId, vm.comment, form).then(function (response) {
                    //                    vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                    //                    if (response.responseCode == 1) {
                    //                        if (actionId == vm.actionsTypesConstant.SAVE) {
                    //                            successrNotificationService.showSuccessSavingSectionNotfication(vm.sectionTitle);
                    //                        }
                    //                        else if (actionId == vm.actionsTypesConstant.APPROVE) {
                    //                            successrNotificationService.showSuccessApprovingSectionNotfication(vm.sectionTitle);
                    //                        } else if (actionId == vm.actionsTypesConstant.SENDBACK) {
                    //                            successrNotificationService.showSuccessSendingBackSectionNotfication(vm.sectionTitle);
                    //                        }
                    //                        $state.reload();

                    //                    }

                    //                }, function (error) {
                    //                    vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                    //                    errorNotificationService.showErrorNotification(error);
                    //                });
                    //            }
                    //        } else {

                    //            errorNotificationService.showErrorNotification(error);
                    //            vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                    //        }
                    //    }, function (error) {
                    //        errorNotificationService.showErrorNotification(error);
                    //        vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                    //    })
                    //}
                    //else {



                        if ($state.params.activityID && actionId == vm.actionsTypesConstant.SAVE) {

                            annualAchievementMBRGIService.saveActivity($state.params.entityID, $state.params.activityID, form).then(function (response) {
                                vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                                if (response.responseCode == 1) {
                                    successrNotificationService.showSuccessSavingActivityNotfication(vm.sectionTitle);
                                }
                                $state.go('MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.Submissions', { sectionID: $state.params.sectionID });
                            }, function (error) {
                                vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                                errorNotificationService.showErrorNotification(error);
                            });
                        } else {
                            annualAchievementMBRGIService.submitSingleSectionAction($state.params.entityID, $state.params.sectionID, actionId, vm.comment, form).then(function (response) {
                                vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                                if (response.responseCode == 1) {
                                    if (actionId == vm.actionsTypesConstant.SAVE) {
                                        successrNotificationService.showSuccessSavingSectionNotfication(vm.sectionTitle);
                                    }
                                    else if (actionId == vm.actionsTypesConstant.APPROVE) {
                                        successrNotificationService.showSuccessApprovingSectionNotfication(vm.sectionTitle);
                                    } else if (actionId == vm.actionsTypesConstant.SENDBACK) {
                                        successrNotificationService.showSuccessSendingBackSectionNotfication(vm.sectionTitle);
                                    }
                                    $state.reload();
                                }

                            }, function (error) {
                                vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                                errorNotificationService.showErrorNotification(error);
                            });
                        }
              //      }
                }
            }

            var _init = function () {
                if ($state.params.activityID) {
                    _getSectionActivityDetails($state.params.activityID, vm.entityID, vm.viewMode)
                } else if ($state.params.sectionID) {
                    _getSingleSectionDetails($state.params.sectionID, vm.entityID, vm.viewMode);
                }
            }()

            vm.CheckIsSectionRequired = function () {

                //disabled by default
                if ($state.params.sectionID) {
                    var _currentSection = sectionsMBRGIService.getSection($state.params.sectionID);
                    if (_currentSection)
                        vm.IsSectionRequired = _currentSection.isRequired;
                }


                //return true;
            }

            $scope.$on('$destroy', function () {
                if (_viewHistoryModal && _viewHistoryModal.close) {
                    _viewHistoryModal.close();
                }
                if (_viewSharedCommentModal && _viewSharedCommentModal.close) {
                    _viewSharedCommentModal.close();
                }
                if (_viewCommentModal && _viewCommentModal.close) {
                    _viewCommentModal.close();
                }
            });

            vm.DefineEntityEditingFields = function () {

                var oldModel = vm.form.model.oldModel;
            //    vm.EntityEditingFields = vm.form.model.EntityEditingFields;
                delete vm.form.model.oldModel;
                delete vm.form.model.EntityEditingFields;

                var d = diff(vm.form.model, oldModel);

                if (typeof d == "undefined") return;
                var r = d.filter((x) => {

                    if (x.lhs == "" && x.rhs == null)
                        return false;
                    else if (x.item)
                        return true;
                    else
                        return (typeof x.lhs != "undefined" && (typeof x.lhs == "string" ? !x.lhs.includes("object:") : true));
                });

                r.forEach((x) => {
                    var index = vm.MBRGIEditingFields.findIndex(z => _isEqual(z, x.path));
                    if (index == -1) {
                        vm.MBRGIEditingFields.push(x.path
                        );
                    }
                });
            }

            var configureForm = function (theObject) {
                var result = null;
                if (theObject instanceof Array) {
                    for (var i = 0; i < theObject.length; i++) {
                        result = configureForm(theObject[i]);
                        if (result) {
                            break;
                        }
                    }
                }
                else {
                    if (theObject.hasOwnProperty('fieldGroup')) {
                        result = configureForm(theObject.fieldGroup);
                    }

                    else if (theObject.templateOptions && theObject.templateOptions.hasOwnProperty('fields')) {
                        result = configureForm(theObject.templateOptions.fields);

                    }

                    else if (theObject.templateOptions && vm.EntityEditingFields.findIndex(x => x[0] == theObject.key) > -1) {
                        theObject.templateOptions.IsEdited = true;
                    }
                }
                return result;
            }

        }])
}