export default function (app) {
    app.directive('uiLoadingIndicatorsStyle', [function () {
        return {
            restrict: 'A',
            require: 'uiLoadingIndicators',
            link: function (scope, iElement, attrs, controller) {
                var prevStyles = iElement.attr('style');
                scope.$watch(function () { return controller.isLoading }, function (newVal, oldVal) {
                    if (newVal) {
                        if (!oldVal) prevStyles = iElement.attr('style')

                        attrs.uiLoadingIndicatorsStyle ?
                            iElement.attr('style', attrs.uiLoadingIndicatorsStyle) :
                            iElement.removeAttr("style")
                            ;
                    } else {
                        prevStyles ?
                            iElement.attr('style', prevStyles) :
                            iElement.removeAttr("style")
                    }
                });

            }
        }
    }]);
}