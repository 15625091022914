import { cloneDeep as _cloneDeep} from 'lodash';

export default function (app) {
    app.controller('mbrgiDashboardController', DashboardController);

    DashboardController.$inject = ["$scope", "$rootScope", "mbrgiDashboardDataService", "Notification", "$timeout","$translate"];
    function DashboardController($scope, $rootScope, mbrgiDashboardDataService, Notification, $timeout, $translate) {
        var vm = this;
        $translate(["COMMON.DAYS"]).then(function (translations) {
            vm.labels = {
                days: translations['COMMON.DAYS'],
            }
            vm.options.subText.text = vm.labels.days;
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.cycleSummary = null;
        vm.annualAchievementsSummary = null;
        vm.getProgressBarType = getProgressBarType;
        vm.getOptions = getOptions;
        vm.load = load;
        vm.options = {
            displayPrevious: true,
            readOnly: true,
            //skin: { type: 'simple', width: 2, color: '#2C3E50', spaceWidth: 0 },
            animate: { enabled: true, duration:2000, ease: 'bounce' },
            subText: {
                enabled: true,
                text: 'days',
                color: 'white',
                font: 'auto'
            },
            bgColor: '#2C3E50',
            size:150,
            barCap: 25,
            trackWidth: 30,
            barWidth: 10,
            trackColor: 'white',
            barColor: '#FFAE1A',
            textColor: 'white',
            fontSize: 'auto',
            max:null,
        };

        vm.load();

        function load() {
            vm.Loading();
            var currentCycleSummary = mbrgiDashboardDataService.GetCurrentCycleSummary();
            var annualAchievementsSummary = mbrgiDashboardDataService.GetAnnualAchievementsSummary();
            Promise.all([ currentCycleSummary,annualAchievementsSummary]).then(function (res) {
                if (res != undefined) {
                    getCurrentCycleSummarySuccess(res[0]);
                    getAnnualAchievementsSummarySuccess(res[1]);
                    vm.Loading();
                    $scope.$apply();
                } else {
                    vm.Loading();
                }
            });
        }

        function getCurrentCycleSummarySuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.cycleSummary = res.responseObject;
                if (vm.cycleSummary != null) {
                    vm.cycleSummary.CycleEndRemainingDaysOptions = vm.getOptions(vm.cycleSummary.CycleEndRemainingDays, vm.cycleSummary.TotalCycleDays);
                    vm.cycleSummary.DataEntryRemainingDaysOptions = vm.getOptions(vm.cycleSummary.DataEntryRemainingDays, vm.cycleSummary.TotalDataEntryDays);
                    vm.cycleSummary.CutoffRemainingDaysOptions = vm.getOptions(vm.cycleSummary.CutoffRemainingDays, vm.cycleSummary.TotalCutoffDays);
                }
            }
        }
        function getAnnualAchievementsSummarySuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.annualAchievementsSummary = res.responseObject;
                if (res.responseObject !== null) {
                    vm.annualAchievementsSummary.TotalSubmittedType = getProgressBarType(vm.annualAchievementsSummary.TotalSubmitted, vm.annualAchievementsSummary.NumberOfEntities);
                    vm.annualAchievementsSummary.TotalReviewingType = getProgressBarType(vm.annualAchievementsSummary.TotalReviewing, vm.annualAchievementsSummary.NumberOfEntities);
                    vm.annualAchievementsSummary.TotalReviewingType = getProgressBarType(vm.annualAchievementsSummary.TotalReviewing, vm.annualAchievementsSummary.NumberOfEntities);
                    vm.annualAchievementsSummary.TotalApprovedType = getProgressBarType(vm.annualAchievementsSummary.TotalApproved, vm.annualAchievementsSummary.NumberOfEntities);
                    vm.annualAchievementsSummary.TotalSentBackType = getProgressBarType(vm.annualAchievementsSummary.TotalSentBack, vm.annualAchievementsSummary.NumberOfEntities);
                }
            }
        }

        //helper methods
        function getProgressBarType(value, total) {
            return calcluateType(value, total);
        }
        function calcluateType(value, total) {
            if (total < 1)
                return "";
            var value = Math.floor((value / total) * 100);
            var type;
            if (value < 30) {
                type = 'danger';
            } else if (value < 60) {
                type = 'warning';
            } else if (value <= 100) {
                type = 'success';
            }
            return type;
        }
        function calcluateColor(value, total) {
            var value = Math.floor((value / total) * 100);
            var color;
            if (value < 30) {
                color = '#dc3545';
            } else if (value < 60) {
                color = '#ffc107';
            } else if (value <= 100) {
                color = '#53c0a7';
            }
            return color;
        }
        function getOptions(value, totatl) {
            var options = _cloneDeep(vm.options);
            var color = calcluateColor(value, totatl);
            options.barColor = color;
            options.max = totatl;
            return options;
        }

    }
}