export default function (app) {
    //TODO: Remove authorization header and use interceptor, remove using rootScope
    app.factory('apiBase', apiBase);

    apiBase.$inject = ["$http", "$rootScope", "$q", "$cookies", "routeConstants", "$state"];
    function apiBase($http, $rootScope, $q, $cookies, routeConstants, $state) {

        function send(method, url, params, data, type, async, returnResponse) {

            async = async === null || async ? true : false;
            var Content = returnResponse == true ? "Content - Type': 'application / JSON'": null
            return $http({
                method: method,
                beforeSend: function (jqXHR) {
                    jqXHR.setRequestHeader('Accept-Encoding', 'gzip,deflate');
                },
                url: url,
                params: params,
                data: data,
                async: async
                ,
                headers: {
                    //Authorization: $cookies.token ? $cookies.token : $cookies.get("token"),
                    Content
                    
                },
                type: type,
                responseType: type
            }).then(function (res) {                
                //if (res.headers("Authorization") && res.headers("Authorization") !== "") {
                //    $rootScope.SetToken(res.headers("Authorization"));
                //}
                if (returnResponse)
                    return res;
                else
                    return res ? res.data : $rootScope.methodPublicError({ status:499}); //undefined;

                }, function (err) {                    

                    switch (err.status) {
                    case 401: // "Unauthenticated". Role has changed.
                        $rootScope.DeleteAuthenticationToken();
                        break;
                    case 403: // "Forbidden,unauthorized". Role has changed.
                            $rootScope.HandleUnauthorizedActions(err);
                            break;
                    case 404://Not Found: to handle not found exceptions
                       
                            break;

                    }
                    if (url != "/api/WebNotification/GetUserUnSeenCount" && url != "/api/WebNotification/GetAllNotification")
                        $rootScope.methodPublicError(err);
            });
        }
        
    
        function Get(url, params, data, async, returnResponse = false) {
            if (returnResponse)
                return send("GET", url, params, data, "arraybuffer", async, returnResponse);
              else
            return send("GET", url, params, data, null, async, returnResponse);
        }

        function Post(url, params, data, async, returnResponse = false) {
            if (returnResponse)
                return send("POST", url, params, data, "arraybuffer", async, returnResponse);
            else
            return send("POST", url, params, data, null, async, false);
        }
        function PostFile(url, params, data, async, type) {
            return send("POST", url, params, data, type, async, true);
        }
        return {
            Get: Get,
            Post: Post,
            PostFile: PostFile
        }
    }
}