//Import controllers
import loginController from './Controllers/app.login.controller';
import forgetPasswordController from './Controllers/app.foregtPassword.controller';
import resetPasswordController from './Controllers/app.resetPassword.controller';
//Import services
import dataService from './Services/login.data.service';
//Import config

export default function (app) {
    loginController(app);
    forgetPasswordController(app);
    resetPasswordController(app);
    dataService(app);
}