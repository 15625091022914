export default function (app) {
    app.factory('entityProfileDataService', EntityProfileDataService);

	EntityProfileDataService.$inject = ['apiBase', 'ApiPath'];
	function EntityProfileDataService(apiBase, ApiPath) {
		var entityPortalController = "EntityPortal";
		var controllerUrl = ApiPath + entityPortalController + "/";

		function GetProfileForm() {
			return apiBase.Get(controllerUrl + "GetProfileForm", null, null);
        }

		function UpdateProfile(model) {
			return apiBase.Post(controllerUrl + "UpdateProfile", null, model);
		}
        
        return {
			GetProfileForm: GetProfileForm,
			UpdateProfile: UpdateProfile
        }
    }
}