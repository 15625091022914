export default function (app) {
    app.factory('sharedInitiativesService', ['apiBase', 'ApiPath', '$http', function (apiBase, ApiPath, $http) {
        var _controllerUrl = ApiPath + "Section/";                 
        var _sharedInitiativesControllerUrl = ApiPath + "SharedActivities/";                 

        var _getSectionActivities = function () {
            return apiBase.Get(_sharedInitiativesControllerUrl + "GetSharedActivities", null);
        }        
        var _getSectionActivityDetails = function (activityID) {
            return apiBase.Get(_sharedInitiativesControllerUrl + "GetSectionActivityDetails", { activityID: activityID }, null);
        }        
             
        var _getActivityHistory = function (activityID) {
            return apiBase.Get(_controllerUrl + "GetActivityHistory", { activityID: activityID }, null);
        }

        var _getCommentsList = function (activityID) {
            return apiBase.Get(_sharedInitiativesControllerUrl + "GetEntitySharedActivityComments", { activityID: activityID }, null);
        }
        var _submitSharedActivityComment = function (activityID, comments) {            
            return apiBase.Get(_sharedInitiativesControllerUrl + "AddSharedActivityComment", { activityID: activityID, comments: comments}, null);
        }
        return {
            getSectionActivities: _getSectionActivities,
            getSectionActivityDetails: _getSectionActivityDetails,
            getActivityHistory: _getActivityHistory,
            getCommentsList: _getCommentsList,
            submitSharedActivityComment: _submitSharedActivityComment
        }
    }]);
}