import { cloneDeep as _cloneDeep } from 'lodash';
export default function (app) {
    app.controller('updateBeneficiaryunitController', UpdateBeneficiaryunitController);

    UpdateBeneficiaryunitController.$inject = ["$rootScope", "beneficiaryunitDataService", "$uibModalInstance", "Params", "Notification","$translate"];
    function UpdateBeneficiaryunitController($rootScope, beneficiaryunitDataService, $uibModalInstance, Params, Notification, $translate) {
        var vm = this;
        vm.Loading = $rootScope.Loading;
        $translate(["BENEFICIARYUNIT.BENEFICIARY_UNIT_EXIST", "BENEFICIARYUNIT.BENEFICIARY_UNIT_UPDATED"]).then(function (translations) {
            vm.labels = {
                BeneficiaryUnitIsAlreadyExist: translations['BENEFICIARYUNIT.BENEFICIARY_UNIT_EXIST'],
                BeneficiaryUnitUpdated: translations['BENEFICIARYUNIT.BENEFICIARY_UNIT_UPDATED'],
            }
        });
        vm.beneficiaryunit = {};
        vm.load = load;
        vm.cancel = cancel;
        vm.Params = _cloneDeep(Params);
        vm.updateBeneficiaryunit = updateBeneficiaryunit;
        vm.responseCode = $rootScope.enums.ResponseCode;

        vm.load();

        function load() {
            vm.Loading();
            vm.beneficiaryunit = vm.Params;
            vm.beneficiaryunit.Type = vm.beneficiaryunit.Type.toString();
            
            beneficiaryunitDataService.GetAllBeneficiaryUnitsTypes().then(getAllBeneficiaryunitsTypesSuccess, $rootScope.methodPublicError);

        }

        function getAllBeneficiaryunitsTypesSuccess(response) {
            vm.Loading();
            if (response.responseCode === vm.responseCode.Success) {
                vm.BeneficiaryunitTypes = response.responseObject;
            }

            else {
                $rootScope.methodPublicError();
            }
        }
        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false });
        }

        function updateBeneficiaryunit(beneficiaryunit) {
            vm.Loading();
            beneficiaryunitDataService.IsBeneficiaryunitExist(beneficiaryunit, beneficiaryunit.ID).then(isBeneficiaryunitExistSuccess, $rootScope.methodPublicError);

        }


        function isBeneficiaryunitExistSuccess(res) {
            if (res.responseCode == vm.responseCode.NotExist) {
                
                beneficiaryunitDataService.UpdateBeneficiaryunit(vm.beneficiaryunit).then(updateBeneficiaryunitSuccess, $rootScope.methodPublicError);
            }
            else if (res.responseCode == vm.responseCode.Success) {
                Notification.warning({ message: vm.labels.BeneficiaryUnitIsAlreadyExist });
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function updateBeneficiaryunitSuccess(res) {
            vm.Loading();
            Notification.success({ message: vm.labels.BeneficiaryUnitUpdated });
            $uibModalInstance.close({ Close: true, Ok: true });
        }
        
    }
}