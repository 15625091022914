import { random as _random } from 'lodash';
export default function (app) {
    app.controller('userProfileController', UserProfileController);

    UserProfileController.$inject = ["$rootScope", "userProfileDataService", "$state", "$uibModal", "Notification", "fileUploadService", "$translate","roleDataService","Upload"];
    function UserProfileController($rootScope, userProfileService, $state, $uibModal, Notification, fileUploadService, $translate, roleDataService, Upload) {
        var vm = this;
        vm.Loading = $rootScope.Loading;
        $translate(["COMMON.PERSONNAL_INFO_UPDATED_NOTIFICATION", "COMMON.COULDNOT_UPDATE_PERSONNAL_INFO_NOTIFICATION", "COMMON.COULDNOT_UPLOAD_PIC_NOTIFICATION"]).then(function (translations) {
            vm.labels = {
                ProfileUpdated: translations['COMMON.PERSONNAL_INFO_UPDATED_NOTIFICATION'],
                ProfileNotUpdated: translations['COMMON.COULDNOT_UPDATE_PERSONNAL_INFO_NOTIFICATION'],
                PicNotUploaded: translations['COMMON.COULDNOT_UPLOAD_PIC_NOTIFICATION'],
            }
        });
        vm.user = {};
        vm.getCurrentUser = getCurrentUser;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.UpdateUserProfile = UpdateUserProfile;
        vm.uploadImage = uploadImage;
        vm.removeCurrentImage = removeCurrentImage;
        vm.load = load;
        vm.selectImage = selectImage;
        vm.file = null;
        vm.displayDownloadButton = true;

        vm.load();

        function load() {
            
            vm.getCurrentUser();
        }
        function getCurrentUser() {
            vm.Loading();
            var currentUserID = $rootScope.GetCurrentUser().ID;
            var currentEntityID = $rootScope.GetCurrentUser().CurrentEntity;
            userProfileService.GetUserByID(currentUserID).then(getCurrentUserSuccess, $rootScope.methodPublicError);
        }
        function UpdateUserProfile(user) {
            vm.Loading();
            if (vm.file != null) {
                vm.uploadImage(vm.file);
            }
            else {
                userProfileService.UpdateUserProfile(vm.user).then(updateUserSuccess, $rootScope.methodPublicError);

            }
            
        }
        function uploadImage(image) {
            if (image !== null) {
                fileUploadService.UploadFiles(image, $rootScope.enums.UploadType.ProfilePic)
                    .then(uploadImageSuccess, $rootScope.methodPublicError);
            }
        }

        function removeCurrentImage() {
            vm.file = null;
        }
        function getCurrentUserSuccess(response) {
            
            if (response.responseCode === vm.ResponseCode.Success) {
                vm.user = response.responseObject;
                //var userRoleEnum = $rootScope.enums.userRole;
                roleDataService.GetRolesLookupData().then(GetRolesLookupDataSuccess, $rootScope.methodPublicError);
                
            }
        }
        function GetRolesLookupDataSuccess(res) {
            if (res.responseCode == vm.ResponseCode.Success && res.responseObject !== null) {
                vm.roles = res.responseObject;
                var currentRole = $rootScope.GetCurrentUser().CurrentRoleID;
                if (currentRole != null)
                    vm.user.RoleId = currentRole.toString();
                updateCurrentUserScope();
                vm.Loading();
            }
        }
        function updateCurrentUserScope() {
            
            var user = $rootScope.GetCurrentUser();
            user.FullName = vm.user.FirstName + ' ' + vm.user.LastName;
            user.IsProfileCompleted = vm.user.IsProfileCompleted;
        }
        function selectImage(file, errFiles) {
            vm.displayDownloadButton = false;
            if (file || errFiles) {
                vm.errFile = errFiles && errFiles[0];
                var _fileName = file.name.split('.')[0] + "_" + _random(1, 99999, false) + '.' + file.name.split('.')[1];
                var _file = Upload.rename(file, _fileName);
                vm.file = _file;
            }
        }
        function updateUserSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.ProfileUpdated });
                var user = $rootScope.GetCurrentUser();
                user.FullName = vm.user.FirstName + ' '+vm.user.LastName;
                user.IsProfileCompleted = true;
                user.ImagePath = vm.user.ImagePath;
                $rootScope.SetCurrentUser(user);
                vm.Loading();
                if (user.CurrentRole === "MBRGI Viewer")
                    $state.transitionTo('MBRGI.MBRGIAnnualAchievements');
               else if (user.RoleSection === "MBRGI")
                    $state.transitionTo('MBRGI.Dashboard');
                else if (user.RoleSection === "Entity")
                    $state.transitionTo('Entity.Dashboard');
            }
            else {
                Notification.success({ message: vm.labels.ProfileNotUpdated });
                vm.Loading();
            }
        }

        function uploadImageSuccess(response) {
            var uploadResult = response.data;
            if (uploadResult.status == 1 && uploadResult.files) {
                vm.user.ImagePath = uploadResult.files[0];
                userProfileService.UpdateUserProfile(vm.user).then(updateUserSuccess, $rootScope.methodPublicError);
            }
            else {
                Notification.error({ message: vm.labels.PicNotUploaded });
                vm.Loading();
            }

        }
        vm.Cancel = function () {
            var user = $rootScope.GetCurrentUser();
            if (user.CurrentRole === "MBRGI Viewer")
                $state.transitionTo('MBRGI.MBRGIAnnualAchievements');
            else if (user.RoleSection === "MBRGI")
                $state.transitionTo('MBRGI.Dashboard');
            else if (user.RoleSection === "Entity")
                $state.transitionTo('Entity.Dashboard');

        }
        
    }
}