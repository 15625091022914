export default function (app) {
    app.factory('lookupsDataService', LookupsDataService);

	LookupsDataService.$inject = ['apiBase', 'ApiPath'];
	function LookupsDataService(apiBase, ApiPath) {
        var entity = "Lookups";
        var controllerUrl = ApiPath + entity + "/";

        function GetAllLookups(  ) {
            return apiBase.Get(controllerUrl + "GetAll" , null);
		}
		function AddLookup(data) {
			return apiBase.Post(controllerUrl + "AddLookup", null, data);
		}
		function UpdateLookup(data) {
			return apiBase.Post(controllerUrl + "UpdateLookup", null, data);
        }
        function GetPredefinedLookupEntries(lookupID) {
            return apiBase.Get(controllerUrl + "GetPredefinedLookupEntries", { lookupID: lookupID }, null);
        }
		function GetLookupEntries(lookupID) {
			return apiBase.Get(controllerUrl + "GetLookupEntries", { lookupID: lookupID }, null);
		}
		function GetDeletedLookupEntries(lookupID) {
			return apiBase.Get(controllerUrl + "GetDeletedLookupEntries", { lookupID: lookupID }, null);
		}
		function DeleteLookupEntry(entryID) {
			return apiBase.Get(controllerUrl + "DeleteLookupEntry", { entryID: entryID }, null);
		}
		function RestoreDeletedEntry(entryID) {
			return apiBase.Get(controllerUrl + "RestoreDeletedEntry", { entryID: entryID }, null);
		}
		function AddLookupEntry(lookupEntry) {
			return apiBase.Post(controllerUrl + "AddLookupEntry", null, lookupEntry);
		}
		function UpdateLookupEntry(lookupEntry) {
			return apiBase.Post(controllerUrl + "UpdateLookupEntry", null, lookupEntry);
		}
        function updateLookUpEntriesOrder(orderedEntries) {
            return apiBase.Post(controllerUrl + "UpdateLookUpEntriesOrder", null,  orderedEntries);
        }
        function updatePredifinedLookUpEntriesOrder(lookupID,orderedEntries) {
            return apiBase.Post(controllerUrl + "UpdatePredifinedLookUpEntriesOrder", { lookupId: lookupID}, orderedEntries);
        }
        return {
			GetAllLookups: GetAllLookups,
			UpdateLookup: UpdateLookup,
			AddLookup: AddLookup,
			GetLookupEntries: GetLookupEntries,
			DeleteLookupEntry: DeleteLookupEntry,
			GetDeletedLookupEntries: GetDeletedLookupEntries,
			RestoreDeletedEntry: RestoreDeletedEntry,
			AddLookupEntry: AddLookupEntry,
            UpdateLookupEntry: UpdateLookupEntry,
            GetPredefinedLookupEntries: GetPredefinedLookupEntries,
            UpdateLookUpEntriesOrder: updateLookUpEntriesOrder,
			UpdatePredifinedLookUpEntriesOrder: updatePredifinedLookUpEntriesOrder
        }
    }
}