export default function (app) {
	app.controller('lookupDeletedEntriesController', LookupDeletedEntriesController);

	LookupDeletedEntriesController.$inject = ["$scope", "$rootScope", "lookupsDataService", "$uibModal", "Notification", "$translate", "$state", "$stateParams"];
	function LookupDeletedEntriesController($scope, $rootScope, lookupsDataService, $uibModal, Notification, $translate, $state, $stateParams) {
        var vm = this;
		$translate(["LOOKUPS.ENTRY_DELETED", "LOOKUPS.ENTRY_DELETE_QUES", "LOOKUPS.DELETE_HEADER", "LOOKUPS.ENTRY_RESTORED", "COMMON.ACTIVE_CYCLE"]).then(function (translations) {
			vm.labels = {
				EntryDeleted: translations['LOOKUPS.ENTRY_DELETED'],
				DeleteEntryQues: translations['LOOKUPS.ENTRY_DELETE_QUES'],
				DeleteHeader: translations['LOOKUPS.DELETE_HEADER'],
				ACTIVE_CYCLE: translations['COMMON.ACTIVE_CYCLE'],
				ENTRY_RESTORED: translations['LOOKUPS.ENTRY_RESTORED']
			}
		});

		vm.lookupID = $stateParams.lookupID;
        vm.Loading = $rootScope.Loading;
		vm.ResponseCode = $rootScope.enums.ResponseCode;
		vm.load = load;
		vm.getDeletedLookupEntries = getDeletedLookupEntries;
		vm.restore = restore;

		vm.lookupName = "";
		vm.lookupEntries = [];
		vm.lookupsFilter = lookupsFilter;
		vm.back = back;
		
		vm.Modal = null;
        vm.currentCycle = null;
        vm.deletedCount = 0;
        vm.load();

        function load() {
			vm.getDeletedLookupEntries();
        }

		function getDeletedLookupEntries() {
            vm.Loading();
			lookupsDataService.GetDeletedLookupEntries(vm.lookupID).then(getDeletedLookupEntriesSuccess, $rootScope.methodPublicError);
		}

		function getDeletedLookupEntriesSuccess(res) {
			if (res.responseCode === vm.ResponseCode.Success) {
				vm.lookupEntries = res.responseObject;
				vm.lookupName = res.lookupName;
			}
			else {
				$rootScope.methodPublicError();
			}

			vm.Loading();
		}

		function lookupsFilter(item) {
			if (vm.search) {
				return item.ValueEn.toLowerCase().includes(vm.search.toLowerCase()) || item.ValueAr.includes(vm.search);
			}
			else
				return true;
		}

		function restore (ID) {
			vm.Loading();
			lookupsDataService.RestoreDeletedEntry(ID).then(restoreDeletedEntrySuccess, $rootScope.methodPublicError);
		}

		function restoreDeletedEntrySuccess(res) {
			vm.Loading();
			if (res.responseCode === vm.ResponseCode.Success) {
				Notification.success({ message: vm.labels.ENTRY_RESTORED });
				$state.reload();
			}
		}


		function back() {
			$state.go("MBRGI.LookupEntries", { lookupID: vm.lookupID });
		}


		
        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
        });
    }
}