export default function (app) {
    app.directive('elementWidth', function () {
        return {
            restrict: 'A',
            scope: {
                elementWidth: "="
            },
            link: function link(scope, element, attr, ctrls) {
                scope.elementWidth = element[0].clientWidth;
                scope.$watch(
                    function () {
                        return element[0].clientWidth;
                    },
                    function (value) {
                        scope.elementWidth = value;
                        
                    }
                );
            }
        };
    });
}




