export default function (app) {
    app.controller('navController', NavController);

    NavController.$inject = ["$rootScope","$uibModal","$state","routeConstants"];
    function NavController($rootScope,$uibModal, $state,routeConstants) {
        var vm = this;
        vm.goToUsers = goToUsers;
        vm.goToDashboard = goToDashboard;
        vm.goToTasks = goToTasks;
        vm.goToEntities = goToEntities;

        vm.goToAnnualAchievements = goToAnnualAchievements;
        vm.goToSharedInitiatives = goToSharedInitiatives;
        vm.goToReports = goToReports;
        vm.IsNavElementVisible = IsNavElementVisible;
        vm.NavElementsEnum = $rootScope.enums.NavElements;
        vm.userRoleEnum = $rootScope.enums.userRole;
        vm.goToMBRGIAnnualAchievements = goToMBRGIAnnualAchievements;
        vm.goToRequest = goToRequest;
        vm.goToFormBuilder = goToFormBuilder;
        vm.goToSectionsAssignment = goToSectionsAssignment;
        vm.goToPublicSettings = goToPublicSettings;
        vm.load = load;

        vm.load();

        function load() {
        }
        function goToUsers(roleSection) {
            if (roleSection === 'MBRGI') {
                //$state.transitionTo(routeConstants.MBRGIUserState.State);
                return routeConstants.MBRGIUserState.State;
            }
            else if (roleSection === 'Entity') {
              //  $state.transitionTo(routeConstants.EntityUserState.State);
                return routeConstants.EntityUserState.State;
            }
        }
        function goToDashboard(roleSection) {
            if (roleSection === 'MBRGI') {
               // $state.transitionTo(routeConstants.MBRGIDashboardState.State);
                return routeConstants.MBRGIDashboardState.State;
            }
            else if (roleSection === 'Entity') {
                //$state.transitionTo(routeConstants.EntityDashboardState.State);
                return routeConstants.EntityDashboardState.State;
            }
        }
        function goToTasks(roleSection) {
              if (roleSection === 'Entity') {
                  return "Entity.Tasks";
              }
        }
     
        function goToEntities(roleSection) {
            if (roleSection === 'MBRGI') {
                //$state.transitionTo(routeConstants.EntityState.State);
                return routeConstants.EntityState.State;
            }
            else if (roleSection === 'Entity') {
                //$state.transitionTo("Entity.Entities");
                return "Entity.Entities";
            }
        }
        function goToAnnualAchievements(roleSection) {
            if (roleSection === 'Entity'){
                return "Entity.AnnualAchievement";
            }
        }
        function goToMBRGIAnnualAchievements(roleSection) {
            
            if (roleSection === 'MBRGI') {
                return "MBRGI.MBRGIAnnualAchievements";
            }
        }
        
        function goToSharedInitiatives(roleSection) {
            if (roleSection === 'Entity') {
                return "Entity.SharedInitiatives";
            }
        }
        function goToReports(roleSection) {
            if (roleSection === 'MBRGI') {
                return routeConstants.MBRGIReportsState.State;
            }
            else if (roleSection === 'Entity') {

                return routeConstants.EntityReportsState.State;
            }
        }

        function goToRequest(roleSection) {
            if (roleSection === 'MBRGI') {
                return routeConstants.SectionRequestState.State;
            }
            
        }
        function goToFormBuilder(roleSection) {
            if (roleSection === 'MBRGI') {
                return routeConstants.SectionListState.State;
            }

        }
        function goToSectionsAssignment(roleSection) {
            if (roleSection === 'Entity') {
                return routeConstants.SectionsAssignmentState.State;
            }
        }
        function goToPublicSettings(roleSection) {
            if (roleSection === 'Entity') {
                return routeConstants.EntityPublicSettings.State;
            }
            else if (roleSection === 'MBRGI') {
                return routeConstants.MBRGIPublicSettings.State;
            }
        }
        function IsNavElementVisible(elementID) {
            var currentUser = $rootScope.GetCurrentUser();
            if (currentUser != null && currentUser !== undefined) {
                var roles = vm.NavElementsEnum[elementID];
                if (roles !== undefined) {
                    if (roles.indexOf(currentUser.CurrentRoleID) !== -1) {
                        return true;
                    }
                }
                return false;
            }
            else {
                return false;
            }
        }
    }
}