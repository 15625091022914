//Import controllers
import homeController from './Controllers/app.home.controller';
import updateUserController from '../Common/Users/Controllers/app.update-user.controller';
import navController from './Controllers/app.nav.controller';
import headerController from './Controllers/app.header.controller';

//Import services
import userDataService from '../Common/Users/Services/user.data.service';
import dataService from './Services/home.data.service';
import homeNotificationService from './Services/home.notification.service'
//Import config
import './Localization';

import ngValidateCondition from '../Common/Directives/ngValidateCondition';
export default function (app) {
    ngValidateCondition(app);
    homeController(app);
    updateUserController(app);
    userDataService(app);
    navController(app);
    headerController(app);
    dataService(app);
    homeNotificationService(app);
}