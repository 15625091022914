export default function (app) {
    app.factory('roleDataService', RoleDataService);

    RoleDataService.$inject = ['apiBase', 'ApiPath'];
    function RoleDataService(apiBase, ApiPath) {
        var entity = "Role";
        var controllerUrl = ApiPath + entity + "/";

        function GetRolesLookupData(roleCategory) {
            return apiBase.Get(controllerUrl + "GetRolesLookupData", { roleCategory: roleCategory} , null);
        }

        return {
            GetRolesLookupData: GetRolesLookupData
        }
    }
}