import { getTranslationForLang }  from '../Modules/Common/Services/app.localization.service'

export default function routingConfig(app) {
    
    app.config(['$translateProvider', function ($translateProvider) {

    $translateProvider
        .translations('en', getTranslationForLang('en'))
        .translations('ar', getTranslationForLang('ar'))
        .preferredLanguage('en');
        // remember language
    $translateProvider.useLocalStorage();
    $translateProvider.useSanitizeValueStrategy('escape');
  }]);
}