export default {
    EMPTY_LIST: 'لا يوجد بيانات للعرض',
    DEACTIVATE_USER: 'إلغاء تفعيل المستخدم',
    WOULD_DEACTIVATE_USER: 'هل تريد إلغاء تفعيل المستخدم؟',
    USER_DEACTIVATED: 'تم إلغاء تفعيل المستخدم بنجاح',
    USER_ACTIVATED: 'تم تفعيل المستخدم بنجاح',
    CANT_DEACTIVATE: 'لا يمكن إلغاء تفعيل',
    SHOW_ALL: 'الكل',
    ALL_SECTIONS: 'كل الأقسام',
    ACTIVE: 'مفعل',
    INACTIVE: 'غير مفعل',
    SEARCH: 'بحث',
    SHARED: 'مشتركة',
    MBRGI: 'مؤسسة المبادرات',
    CARD_TYPE_USER: 'مستخدم',
    EMAIL_LABEL: 'البريد الإلكتروني',
    USER_NAME_LABEL: 'اسم المستخدم',
    PASSWORD_LABEL:'كلمة السر',
    OLD_PASSWORD_LABEL: 'كلمة السر القديمة',
    NEW_PASSWORD_LABEL: 'كلمة السر الجديدة',
    RESET_PASSWORD_LABEL: 'تأكيد',
    CONFIRM_PASSWORD_LABEL: 'تأكيد كلمة السر الجديدة',
    CONFIRM_NEW_PASSWORD_LABEL: 'تأكيد كلمة السر الجديدة',
    ROLE_LABEL: 'الدور',
    JOB_TITLE_LABEL: 'المسمى الوظيفي',
    CONTACT_NUMBER_LABEL: 'رقم التواصل',
    MAIN_COORDINATOR_LABEL: 'المنسق الرئيسي',
    MAIN_ADMIN_LABEL: 'المشرف الرئيسي',
    CHANGE_PASSWORD_LABEL: 'تغيير كلمة السر',
    ADD_NEW_USER_LABEL: 'إضافة مستخدم جديد',
    PERSONRAL_PICTURE_LABEL: 'الصورة الشخصية',
    EDIT_USER: 'تعديل المستخدم',
    CONTACT_NUMBER_PLACEHOLDER: '05x xxx xxxx | 0x xxx xxxx',
    REQUIRED_USERNAME_OR_PASSWORD: 'اسم مستخدم او كلمة مرور مطلوبة',
    WRONG_USERNAME_OR_PASSWORD: 'اسم مستخدم او كلمة مرور خطأ',
    USER_NOT_ACTIVE: 'لقد تم الغاء تفعيل هذا الحساب',
    ENTITY_NOT_ACTIVE: 'لقد تم الغاء تفعيل هذا الحساب',
    RESET_PASSWORD_MAIL_SENT: 'تم إرسال بريد إلكتروني إلى بريدك المسجل لتعيين كلمة المرور من جديد',
    EMAIL_NOT_EXIST: 'البريد الإلكتروني غير موجود',
    REQUIRED_EMAIL: 'البريد الإلكتروني مطلوب',
    REQUIRED_USER_NAME: 'اسم المستخدم مطلوب',
    REQUIRED_Comment: 'ملاحظة مطلوبة ',
    REQUIRED_PASSWORD: 'كلمة السر مطلوبة',
    REQUIRED_OLD_PASSWORD: 'كلمة السر القديمة مطلوبة',
    REQUIRED_NEW_PASSWORD: 'كلمة السر الجديدة مطلوبة',
    REQUIRED_CONFIRM_PASSWORD: 'تأكيد كلمة السر مطلوب',
    REQUIRED_CONFIRM_NEW_PASSWORD: 'تأكيد كلمة السر الجديدة مطلوب',
    REQUIRED_FIRST_NAME: 'تأكيد الاسم مطلوب',
    REQUIRED_LAST_NAME: 'تأكيد اسم العائلة مطلوب',
    REQUIRED_ROLE: 'تأكيد الدور مطلوب',
    REQUIRED_JOB_TITLE: 'تأكيد المسمى الوظيفي مطلوب',
    REQUIRED_CONTACT_NUMBER: 'تأكيد رقم التواصل مطلوب',
    PASSWORD_CONFIRM_PASSWORD_NOT_THE_SAME: 'كلمة السر وتأكيدها يجب أن يكونا متطابقين',
    NEW_PASSWORD_CONFIRM_PASSWORD_NOT_THE_SAME: 'كلمة السر الجديدة وتأكيدها يجب أن يكونا متطابقين',
    FORGOT_PASSWORD: 'نسيت كلمة السر؟',
    LOGIN: 'تسجيل الدخول',
    PROCEED: 'تقدم',
    PASSWORD_WRONG_FORMAT: 'يجب أن يكون تنسيق كلمة السر أكثر من 8 أحرف ، بما في ذلك حرف كبير و رقم ',
    PERSONALE_INFO: 'بيانات المستخدم',
    ADD_USER_PHOTO: 'إضافة صورة شخصية',
    FIRST_NAME: 'الاسم',
    LAST_NAME: 'اسم العائلة',
    CANCEL: 'إلغاء',
    CONFIRM:'تأكيد',
    SAVE: 'حفظ',
    ACTIONS:'إجراءات', // to check
    PLEASE_SELECT: 'الرجاء الاختيار',
    PLEASE_SELECT_SECTION: 'من فضلك اختر الأقسام قبل الإصدار',
    //Users
    USER_ADDED_NOTIFICATION: 'تمت إضافة المستخدم بنجاح',
    EMAIL_USED_NOTIFICATION: 'لا يمكنك تكرار البريد الإلكتروني',
    CONFIRM_EMAIL_USE: 'تم إستخدام البريد الإلكتروني من قبل ؛ سنستخدم بيانات الملف الشخصي السابقة. هل تريد المتابعة؟',
    USER_UPDATED_NOTIFICATION: 'تم تعديل المستخدم بنجاح',
    PIC_VALID_FORMAT: 'يجب أن تكون تنسيقات الصور الصالحة هي jpeg أو png بحجم أقصى 2 ميجابايت',
    PIC_REQUIRED_VALID_FORMAT: 'يجب أن تكون التنسيقات الصحيحة للصورة والصورة المطلوبة jpeg أو png بحجم أقصى 2 ميجابايت',
    //LOGIN
    EMAIL_NOT_EXIST_NOTIFICATION: 'البريد الإلكتروني غير موجود',
    //User Profile
    CHANGED_PASSWORD_NOTIFICATION: 'تم تعديل كلمة السر بنجاح',
    OLD_PASSWORD_WRONG_NOTIFICATION: 'كلمة المرور القديمة خاطئة',
    COULDNOT_UPDATE_PASSWORD_NOTIFICATION: 'لم يتمكن النظام من إجراء التعديل على كلمة السر ',
    PERSONNAL_INFO_UPDATED_NOTIFICATION: 'تم تعديل بيانات المستخدم بنجاح',
    COULDNOT_UPDATE_PERSONNAL_INFO_NOTIFICATION: 'لم يتمكن النظام من اجراء التعديل على بيانات المستخدم',
    COULDNOT_UPLOAD_PIC_NOTIFICATION: 'لم يتمكن النظام من رفع الصورة',
    EXPIRED_LINK_NOTIFICATION: 'هذا الرابط منتهي الصلاحية',
    //Validations 
    ONLY_TEXT_VALIDATION: 'هذا الحقل لا يقبل إلا حروف',
    EMAIL_FORMAT_VALIDATION: 'البريد الإلكتروني غير صحيح',
    CONTACT_NUMBER_FORMAT_VALIDATION: 'رقم الاتصال غير صحيح',
    MAX25_CHARACTER_VALIDATION: 'الحد الأقصى المسموح به 25 حروف',
    MAX250_CHARACTER_VALIDATION: 'الحد الأقصى المسموح به 250 حروف',
    MIN3_CHARACTER_VALIDATION: 'الحد الأدنى المسموح به 3 حروف',
    ONLY_ENGLISH_VALIDATION: 'هذا الحقل لا يقبل إلا اللغة الإنجليزية',
    ONLY_ARABIC_VALIDATION: 'هذا الحقل لا يقبل إلا اللغة العربية',


    //navigation
    ENTITY_PROFILE: 'ملف تعريف المؤسسة',
    DASHBOARD: 'لوحة البيانات',
    ANNUAL_ACHIEVEMENTS: 'الإنجازات السنوية',
    SHARED_INTIATIIVES: 'المبادرات المشتركة',
    TASKS: 'المهام',
    ENTITIES: 'المؤسسات',
    ENTITIY: 'المؤسسة',

    USER_MANAGEMENT: 'إدارة المستخدمين',
    ENTITY_MANAGEMENT: 'إدارة المؤسسات',
    COUNTRIES: 'الدول',
    LOOKUPS: 'الجداول',
    REPORTS: 'التقارير',
    REQUEST: 'طلبات التحرير',
    SETTINGS: 'الإعدادات',
    GENERAL_SETTINGS: 'الإعدادات العامة',
    ASSIGN_SECTION: 'تعيين قسم',
    ASSIGNED_SECTIONS: 'الأقسام المعنية',
    SECTIONS: 'الأقسام',
    SECTION: 'القسم',
    BENEFICIARY_UNITS: 'وحدات المستفيدين',
    CYCLES: 'الدورات السنوية',
    CYCLE: 'الدورة',
    PILLARS: 'المحاور',
    PILLAR: 'المحور',


    //header
    MY_PROFILE: 'صفحتي الشخصية',
    CHANGE_PASSWORD: 'تغيير كلمة السر',
    SWITCH_ENTITES: 'تبديل المؤسسة',
    LOGOUT: 'تسجيل الخروج',
    SYSTEM_NOTIFICATIONS: 'إشعارات النظام',
    CHANGE_LANGUAGE: 'تغيير اللغة',

    //dashboard
    CYCLE_END: 'الأيام المتبقية  لنهاية الدورة السنوية',//    نهاية الدورة 
    DATA_ENTRY: 'الأيام المتبقية لنهاية فترة إدخال البيانات',//    إدخال البيانات
    CUT_OFF: 'الأيام المتبقية لتاريخ التوقف',//تاريخ التوقف    
    REMAINING_DAYS: 'الأيام المتبقية',
    ALL_TASKS: 'إجمالي المهام',
    NEW: 'جديد',
    EDITING: 'تحت التعديل',
    REVIEWING: 'تحت المراجعة',
    INPROGRESS: 'تحت الإجراء',
    COMPLETED:'تم',

    REVIEWING_TASKS:'إجمالي مهام المراجعة',
    REVIEWING_TASKS_NEW: 'جديدة',
    REVIEWING_TASKS_REVIEWING: 'قيد المراجعة',
    REVIEWING_TASKS_REVIEWED: 'تمت مراجعتها',

    EDITING_TASKS: 'جميع المهام المتعلقة بإضافة المحتوى',
    EDITING_TASKS_NEW:'جديدة',
    EDITING_TASKS_EDITING: 'مهام إضافة المحتوى قيد العمل',
    EDITING_TASKS_EDITED:'تم إضافة المحتوى',
    CLOSED: 'منجز',
    MYTASKS: 'مهامي',
    PROCCESSING: 'بدء المعالجة',
    DONE: 'تم',

    MBRGI_Annual_Achievements_Reports_Progress: 'تقرير الإنجازات السنوية',
    MBRGI_TOTAL_SUBMITTED:'إجمالي التقارير المرسلة',
    MBRGI_REVIEWING: 'التقارير تحت المراجعة',
    MBRGI_APPROVED: 'التقارير المعتمدة',
    MBRGI_SENT_BACK: 'التقارير المعدة للمراجعة',

    YEAR_IN_REVIEW: new Date().getFullYear() + ' العام في مراجعة ',
    HELLO: 'مرحبا',
    HISTORY: 'سجل',
    GENERAL_INSTRUCTIONS: 'تعليمات عامة',

    //error notification
    CURRENT_CYCLE_EXIST: 'الدورة الحالية لم تنتهي بعد',
    CYCLE_DATES_INVLAID: 'تاريخ غير صالح',
    CYCLE_DATES_OVERLAPED: 'تتقاطع هذه الفترة مع فترة الدورة السابقة. هذا غير مسموح به',
    CLOSED_CYCLE: 'لا يمكن تعديل دورة مغلقة',
    CYCLE_EXIST: 'لا يمكنك تكرار اسم الدورة',
    CANNOT_MODIFY_ANNUAL_ACHIEVEMENT: 'لا يمكنك تعديل الإنجاز السنوي بعد الإرسال',
    UNAUTHORIZED: ' غير مصرح لك بعرض هذه الصفحة',
    EXPORT_REPORT_FAILURE: 'لم يتمكن النظام من تحميل الملف, يمكنك المحاولة مرة اخرى في وقت لاحق',
    //15: 'This item already exist',
    NOT_COMPLETED_ANNUAL_ACHIEVEMENTS: 'إنجازاتك السنوية لم تكتمل بعد. يرجى إكمال جميع بياناتك وإرسالها',
    TASK_NOT_ASSIGNED_TO_YOU: '.هذه المهمة لم تعد مسندة إليك',
    FILL_ASSIGNEES: 'يرجى ملء المحال لهم قبل الإضافة',
    CYCLE_entry_DATES_OVERLAPED: 'تتقاطع هذه الفترة مع فترة إدخال البيانات للدورة السابقة. هذا غير مسموح به',
    //TODO:DINA to be verified
    CYCLE_PAST_DATES: 'غير مسموح بإدخال تاريخ سابق',
    ERROR_OCCURRED: ' حدث خطأ. يرجى الاتصال بالدعم مع رقم الخطأ ',
    ERROR_GENERAL: ' حدث خطأ. يرجى الاتصال بالدعم ',
    LOGEDOUT:'لقد تم تسجيل الخروج',
    DISCONNECTED:' حدث خطأ. يرجى التأكد من الإتصال بالإنترنت',

    //Common 
    ADDING: ' تمت إضافة {0} بنجاح',
    UPDATING: ' تم تعديل {0} بنجاح',
    DELETING: ' تمت حذف {0} بنجاح',
    DELETINGACTIVITY:'تم الحذف بنجاح',
    SUBMITTING: 'تمت تأكيد إدخال الإنجازات',
    ASSIGNING: ' تمت تعيين {0} بنجاح',
    SEND_MBRGISUBMIT:'هل أنت متأكد من إرسال نتائج الإنجازات للمؤسسة؟',
    MBRGISUBMITTING: 'تم إرسال نتائج مراجعة البيانات السنوية للمؤسسة بنجاح',
    SAVING: '  تمت حفظ تغييرات {0} بنجاح',
    SAVINGACTIVITY:'  تمت حفظ تغييرات {0} بنجاح',
    APPROVING: 'تم الاعتماد بنجاح',
    SENDINGBACK: 'تم إرسال التعليق بنجاح',
    REJECT: '{0} تم رفض الطلب بنجاح',
    RESETING: 'تمت إعادة بيانات {0} بنجاح',
    DATE_FORMAT_PLACEHOLDER: 'YYYY-MM-DD',
    DAYS: 'أيام',
    ANNUAL_ACHIVEMENTS: 'الإنجازات السنوية',
    SHOW_MORE:'أظهر المزيد',
    USER_CHANGE_ROLE_NOTIFICATION: 'لا يمكنك تغيير دور المستخدم على النظام لأن لديه مهام غير مكتملة. ',
    NAVIGATION_CONFIRMATION_MESSAGE: 'أنت على وشك مغادرة المهمة دون حفظ البيانات. هل أنت متأكد انك تريد المتابعة؟',
    NAVIGATION_CONFIRMATION_HEADER: 'هل قمت بحفظ التغييرات؟',
    ACTIVE_CYCLE: 'هناك دورة نشطة',
    COMMENT: 'تعليق',
    COMMENTS: 'تعليقات',
    NOT_SHARED_ACTIVITY: 'هذا النشاط غير مشترك معك',
    ADD_COMMENT: 'أرسال ',
    RequestEditingMsg: ' تم إرسال طلب بنجاح',
    SectionHaveRequest: 'لقد أرسلت الطلب بالفعل من قبل',
    RequestEditingComment: 'طلب تعديل',
    Request: 'طلب',
    NEXT: 'التالي',
    PREVIOUS: 'السابق',
    SELECT_USER: 'اختر مستخدم',
    NO_DATA: 'لا يوجد بيانات للعرض',

    //Notification

    Notification_Title: 'قائمة الإخطار',
    Notification_SeeAll: 'عرض الكل',
    Notification_SeeMore: 'تحميل المزيد',
    RESTORED: ' تمت استعادة {0} بنجاح',
    RESTORE:'استعادة',
    EMAIL_TEMPLATES: 'قوالب البريد الإلكتروني',
    BACK: 'رجوع',
    UPDATED_USER_SETTINGS: 'تم حفظ الإعدادات بنجاح',
    NOTIFICATIONS:'الإشعارات',
    Notification_Expire: 'هذا الإشعار لم يعد موجوداً',
    Notification_Previous_Cycle: 'إشعار دورة سابقة لم يعد موجوداً',
    ALL: 'الكل',
    NoMoreNotification: ' لا يوجد المزيد من الإخطار',
    NUM_OF_REVIEWERS: 'عدد المراجعين',

    OldCycleRestore: " لا يمكنك استرجاع من الدورة السابقة",

    USERS: 'المستخدمين',
    EDIT: 'تعديل',
    VIEW: 'عرض',
    ADD: 'إضافة',
    DOWNLOAD: 'تحميل',
    DELETE: 'حذف',
    FormBuilder: "بناء النموذج",
    Total_Benifecieries: 'إجمالي المستفيدين',
    Total_Cost: 'إجمالي التكاليف',
    Total_Benifecieries_Summary: 'إجمالي عدد مستفيدي ',
    Total_Cost_Summary: 'إجمالي تكاليف ',
    AED: 'درهم',
    Deleted_By: 'حذفت بواسطة',

    Download_Images: '‏تحميل صور‏',
}