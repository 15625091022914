export default function (app) {
	app.controller('lookupEntriesController', LookupEntriesController);

	LookupEntriesController.$inject = ["$scope", "$rootScope", "lookupsDataService", "$uibModal", "Notification", "$translate", "$state", "$stateParams"];
	function LookupEntriesController($scope, $rootScope, lookupsDataService, $uibModal, Notification, $translate, $state, $stateParams) {
        var vm = this;
		$translate(["LOOKUPS.ENTRY_DELETED", "LOOKUPS.ENTRY_DELETE_QUES", "LOOKUPS.DELETE_HEADER", "COMMON.ACTIVE_CYCLE"]).then(function (translations) {
			vm.labels = {
				EntryDeleted: translations['LOOKUPS.ENTRY_DELETED'],
				DeleteEntryQues: translations['LOOKUPS.ENTRY_DELETE_QUES'],
				DeleteHeader: translations['LOOKUPS.DELETE_HEADER'],
				ACTIVE_CYCLE: translations['COMMON.ACTIVE_CYCLE'],
			}
		});

		vm.lookupID = $stateParams.lookupID;
        vm.Loading = $rootScope.Loading;
		vm.ResponseCode = $rootScope.enums.ResponseCode;
		vm.load = load;
		vm.getLookupEntries = getLookupEntries;

		vm.lookupName = "";
		vm.lookupEntries = [];
		vm.lookupsFilter = lookupsFilter;
		vm.back = back;
		vm.isActiveCycle = false;
		vm.deleteEntry = deleteEntry;
		vm.goToTrash = goToTrash;
		
		
		vm.openUpdateModal = openUpdateModal;
        vm.openAddModal = openAddModal;
		vm.Modal = null;
        vm.currentCycle = null;
        vm.deletedCount = 0;
        vm.load();

        function load() {
			vm.getLookupEntries();
        }

		function getLookupEntries() {
            vm.Loading();
			lookupsDataService.GetLookupEntries(vm.lookupID).then(getLookupEntriesSuccess, $rootScope.methodPublicError);
		}

		function getLookupEntriesSuccess(res) {
			if (res.responseCode === vm.ResponseCode.Success) {
				vm.lookupEntries = res.responseObject;
				vm.lookupName = res.lookupName;
				vm.deletedCount = res.deletedCount;
				vm.isActiveCycle = res.isActiveCycle;
			}
			else {
				$rootScope.methodPublicError();
			}

			vm.Loading();
		}

		function lookupsFilter(item) {
			if (vm.search) {
				return item.ValueEn.toLowerCase().includes(vm.search.toLowerCase()) || item.ValueAr.includes(vm.search);
			}
			else
				return true;
		}


		function openUpdateModal(lookupEntry) {
			vm.Modal = $uibModal.open({
				//  backdrop: "static",
				keyboard: true,
				size: 'lg',
				animation: true,
				//backdropClick: true,
				templateUrl: 'ng/Modules/MBRGI/Lookups/Views/update-lookup-entry.html',
				controller: 'updateLookupEntryController',
				controllerAs: 'vm',
				resolve: {
					Params: function () { return lookupEntry }
				}
			});

			vm.Modal.result.then(function (result) {

				if (result.Ok)
					vm.load();
			}, function (error) {
				//backdrop clicked or error
			});
		}
		function openAddModal() {
			vm.Modal = $uibModal.open({
				//  backdrop: "static",
				keyboard: true,
				size: 'lg',
				animation: true,
				//backdropClick: true,
				templateUrl: 'ng/Modules/MBRGI/Lookups/Views/add-lookup-entry.html',
				controller: 'addLookupEntryController',
				controllerAs: 'vm',
				resolve: {
					Params: function() { return { lookupID: vm.lookupID } }
				}
			});
			vm.Modal.result.then(function (result) {
				if (result.Ok)
					vm.load();
			}, function (error) {
				//backdrop clicked or error
			});
		}

		function back() {
			$state.go("MBRGI.Lookups");
		}

		function deleteEntry(entryID, ev) {
			vm.Loading();
			var labels = vm.labels;
			vm.Modal = $uibModal.open({
				//  backdrop: "static",
				keyboard: true,
				size: 'md modal-dialog-centered',
				animation: true,
				//backdropClick: true,
				templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
				controller: function ($uibModalInstance, $rootScope) {
					var vm = this;
					vm.header = labels.DeleteHeader;
					vm.message = labels.DeleteEntryQues;
					vm.ok = function () {
						$uibModalInstance.close({ Close: false, Ok: true });
					};
					vm.cancel = function () {
						$uibModalInstance.close({ Close: true, Ok: false });
					};
				},
				controllerAs: 'vm'
			});
			vm.Loading();
			vm.Modal.result.then(function (result) {
				if (result.Ok) {
					vm.Loading();
					lookupsDataService.DeleteLookupEntry(entryID)
						.then(deleteLookupSuccess, $rootScope.methodPublicError);
				}
			}, function (error) {
				//backdrop clicked or error
			});
		}

		function deleteLookupSuccess(res) {
			if (res.responseCode == vm.ResponseCode.Success) {
				Notification.success({ message: vm.labels.EntryDeleted });
				vm.Loading();
				vm.load();
			} else if (res.responseCode == vm.ResponseCode.ActiveCycleExist) {
				Notification.error({ message: vm.labels.ACTIVE_CYCLE });
				vm.Loading();
			} else {
				$rootScope.methodPublicError();
			}

		}

		function goToTrash () {
			$state.transitionTo('MBRGI.LookupDeletedEntries', { lookupID: vm.lookupID });
		}
		
        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
        });


        $scope.sortableOptions = {
            disabled: vm.IsDisableMode,
            placeholder: vm.IsDisableMode ? "" : "ui-state-highlight",
            stop: function (e, ui) {
                //var IDs = [];
                //vm.lookupEntries.forEach((x) => { IDs.push(x.ID); });
                vm.Loading();
                debugger;
                lookupsDataService.UpdateLookUpEntriesOrder(vm.lookupEntries ).then(function (response) {
                    vm.Loading();
                    if (response) {

                    } else {
                        $rootScope.methodPublicError();
                    }
                })
            }
        };
    }
}