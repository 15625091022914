export default function (app) {
    app.filter('ngArabicDate', function ($filter) {
        return function (date, isArabic) {
            var englishNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
            var arabicNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];//['١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '٠'];
            var months = ["يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو",
                "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"];

            var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];
            var _currentDate = new Date(date);
            var _dayNameArabic = days[_currentDate.getDay()];
            var _monthNameArabic = months[_currentDate.getMonth()];
            var _monthNumberEnglish = ("0" + (_currentDate.getMonth() + 1)).slice(-2);

            var _dayNumberEnglish = ("0" + (_currentDate.getDate())).slice(-2);
            var _yearNumberEnglish = _currentDate.getFullYear();

            if (isArabic) {
                var _dayArabic = _dayNumberEnglish.toString();
                var _monthNumberArabic = _monthNumberEnglish.toString();
                var _yearArabic = _yearNumberEnglish.toString();

                for (var i = 0; i < englishNumbers.length; i++) {
                    _dayArabic = _dayArabic.replace(new RegExp(englishNumbers[i], 'g'), arabicNumbers[i]);
                    _monthNumberArabic = _monthNumberArabic.replace(new RegExp(englishNumbers[i], 'g'), arabicNumbers[i]);
                    _yearArabic = _yearArabic.replace(new RegExp(englishNumbers[i], 'g'), arabicNumbers[i]);
                }
                var arabicDate = _yearArabic+ '-' + _monthNumberArabic+ '-' + _dayArabic  

                return arabicDate;
            }
            else {
                return _dayNumberEnglish+ '-' + _monthNumberEnglish + '-' +   _yearNumberEnglish
                //return $filter('date')(date, 'fullDate');
            }
           
          
        };
    });
}