//Import controllers
import userProfileController from './Controllers/app.user-profile.controller';
import updatePasswordController from './Controllers/app.update-password.controller';
//Import services
import dataService from './Services/userprofile.data.service';
import roleDataService from '../Services/role.data.service';
//Import config


export default function (app) {
    userProfileController(app);
    updatePasswordController(app);
    dataService(app);
    roleDataService(app);
}