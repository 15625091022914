export default function (app) {
    app.factory('annualAchievementsDataService', annualAchievementsDataService);

    annualAchievementsDataService.$inject = ['apiBase', 'ApiPath'];
    function annualAchievementsDataService(apiBase, ApiPath) {
        var _entity = "MBRGIAchivement";
        var _annualCycleController = ApiPath + "AnnualCycle/";
        var _controllerUrl = ApiPath + _entity + "/";

        var _getAchievementsSummary = function (cycleID) {
            return apiBase.Get(_controllerUrl + "GetAchievementsSummary", { cycleID: cycleID}, null);
        }

        var _pickAnnualAchivement = function (entityId) {
            return apiBase.Post(_controllerUrl + "PickAnuualAchievement", { EntityId: entityId},null)
        }

        var _unPickAnnualAchivement = function (entityId) {
            return apiBase.Post(_controllerUrl + "UnPickAnuualAchievement", { EntityId: entityId }, null)
        }

        var _getCyclesList = function () {
            return apiBase.Get(_annualCycleController + "GetAllCycles", null);
        }  

        var _reopenAnnualAchivement = function (entityId) {
            return apiBase.Post(_controllerUrl + "ReopenAnnualAchievementAction", { EntityId: entityId }, null)
        }

        return {
            getAchievementsSummary: _getAchievementsSummary,
            pickAnnualAchivement: _pickAnnualAchivement,
            unPickAnnualAchivement: _unPickAnnualAchivement,
            getCyclesList: _getCyclesList,
            reopenAnnualAchivement: _reopenAnnualAchivement,
        }
    }
}