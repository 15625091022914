export default function (app) {
    app.factory('annualAchievementMBRGIService', ['apiBase', 'ApiPath','$http', function (apiBase, ApiPath, $http) {
        var _controllerUrl = ApiPath + "MBRGIAchivement/";
        var _sharedInitiativesControllerUrl = ApiPath + "SharedActivities/";  
        var _annualCycleController = ApiPath + "AnnualCycle/";

        var _exportEntityAnnualAchievement = function (sectionKeys,entityId,cycleID ) {
            return apiBase.Get(_controllerUrl + "DownloadSectionsAsExcel", { sectionKeys: sectionKeys, entityId: entityId, cycleID: cycleID }, null, true, true);
        }

        var _getMBRGIEntityAnnualAchievement = function (entityID, viewMode, cycleID) {
            return apiBase.Get(_controllerUrl + "GetEntityAnnualAchievement", { entityID: entityID, viewMode: viewMode, cycleID: cycleID}, null);
        }

        var _getSingleSectionDetailsForMBRGI = function (sectionkey, entityID, viewMode, cycleID) {
            return apiBase.Get(_controllerUrl + "GetSingleSectionDetails", { sectionKey: sectionkey, entityID: entityID, viewMode: viewMode, cycleID: cycleID}, null);
        }

        var _getSectionActivities = function (sectionkey, entityID, viewMode, cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionActivities", { sectionKey: sectionkey, entityID: entityID, viewMode: viewMode, cycleID: cycleID}, null);
        }

        var _getAddActivityForm = function (entityID, sectionkey) {
            return apiBase.Get(_controllerUrl + "GetAddActivityForm", { entityID: entityID, sectionKey: sectionkey}, null);
        }

        var _addActivity = function (entityID, sectionkey, formEntryModel) {
            return apiBase.Post(_controllerUrl + "AddActivity", { entityID: entityID, sectionKey: sectionkey }, formEntryModel);
        }

        var _saveActivity = function (entityID, activityID, formEntryModel) {
            return apiBase.Post(_controllerUrl + "UpdateActivity", { entityID: entityID, activityID: activityID }, formEntryModel);
        }

        var _deleteActivity = function (entityID,activityID) {
            return apiBase.Post(_controllerUrl + "DeleteActivity", { entityID:entityID,activityID: activityID}, null);
        }

        var _getActivityHistory = function (activityID) {
            return apiBase.Get(_controllerUrl + "GetActivityHistory", { activityID: activityID }, null);
        }

        var _getSectionHistory = function (sectionkey, entityID, cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionHistory", { sectionKey: sectionkey, entityID: entityID, cycleID: cycleID }, null);
        }

        var _getSectionActivityDetails = function (activityID, entityID, viewMode, cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionActivityDetails", { activityID: activityID, entityID: entityID, viewMode: viewMode, cycleID: cycleID}, null);
        }

        var _submitActivity = function (entityID, sectionKey, actionId, comment) {
            return apiBase.Post(_controllerUrl + "SubmitSectionAction", { entityID: entityID, sectionKey: sectionKey, actionId: actionId, comment: comment}, null);
        }
 
        var _submitSingleSectionAction = function (entityID, sectionKey, actionId, comment, formEntryModel) {
            return apiBase.Post(_controllerUrl + "SubmitSingleSectionAction", { entityID: entityID, sectionKey: sectionKey, actionId: actionId, comment: comment }, formEntryModel);
        }

        var _submitAnnualAchievementAction = function(entityID) {
            return apiBase.Post(_controllerUrl + "SubmitAnnualAchievementAction", { EntityId: entityID }, null);
        }

        var _finalSubmitAnnualAchievementAction = function (entityID) {
            return apiBase.Post(_controllerUrl + "FinalSubmitAnnualAchievementAction", { EntityId: entityID }, null);
        }

        //TODO call endpoint
        var _getSectionComments = function (entityId, sectionKey, cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionCommentsHistory", { entityId: entityId, sectionKey: sectionKey, cycleID: cycleID }, null);
        }

        var _getCommentsList = function (activityID) {
            return apiBase.Get(_sharedInitiativesControllerUrl + "GetSharedActivityComments", { activityID: activityID }, null);
        }

        var _getCyclesList = function (entityId) {
            return apiBase.Get(_annualCycleController + "GetEntityCycles", { entityId: entityId });
        }  

        var _getSectionDeletedActivitiesCount = function (sectionkey, entityId, cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionDeletedActivitiesCount", { sectionKey: sectionkey, entityID: entityId, cycleID: cycleID }, null);
        }
        
        var _getSectionDeletedActivities = function (sectionkey, entityId, cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionDeletedActivities", { sectionKey: sectionkey, entityID: entityId, cycleID: cycleID }, null);
        }

        var _restoreActivity = function (activityID) {
            return apiBase.Post(_controllerUrl + "RestoreActivity", { activityID: activityID }, null);
        }

        return {
            exportEntityAnnualAchievement: _exportEntityAnnualAchievement,
            getMBRGIEntityAnnualAchievement: _getMBRGIEntityAnnualAchievement,
            getSingleSectionDetailsForMBRGI: _getSingleSectionDetailsForMBRGI,
            getAddActivityForm: _getAddActivityForm,
            addActivity: _addActivity,
            deleteActivity: _deleteActivity,
            restoreActivity: _restoreActivity,
            getActivityHistory: _getActivityHistory,
            getSectionActivities: _getSectionActivities,
            getSectionActivityDetails: _getSectionActivityDetails,
            getSectionHistory: _getSectionHistory,
            getSectionComments: _getSectionComments,
            submitActivity: _submitActivity,
            saveActivity: _saveActivity,
            submitSingleSectionAction: _submitSingleSectionAction,
            submitAnnualAchievementAction: _submitAnnualAchievementAction,
            finalSubmitAnnualAchievementAction: _finalSubmitAnnualAchievementAction,
            getCommentsList: _getCommentsList,
            getCyclesList: _getCyclesList,
            getSectionDeletedActivitiesCount: _getSectionDeletedActivitiesCount,
            getSectionDeletedActivities: _getSectionDeletedActivities,
        }
    }]);
}