import { remove as _remove } from 'lodash';

export default function (app) {
	app.factory('formHelpersService', FormHelpersService);
	FormHelpersService.$inject = ['apiBase', 'ApiPath', '$http', 'multiFileUploadService'];
	function FormHelpersService(apiBase, ApiPath, $http, multiFileUploadService) {

		var _controllerUrl = ApiPath + "Downloads/";

		//each property have array of files
		var _formFilesDict = {};

		var _addFile = function (key, file) {
			var _files = _formFilesDict[key];
			if (_files && Array.isArray(_files) && file) {
				_files.push(file);
			} else if (file) {
				_formFilesDict[key] = [file];
			}
			multiFileUploadService.upload([file]);
		}


		var _getFiles = function (key) {
			if (key) {
				return _formFilesDict[key]
			}
		}


		var _removeFile = function (key, fileName) {
			var _files = _formFilesDict[key];
			_remove(_files, (file) => {
				return file.ngfName == fileName
			})
		}

		var _downloadFiles = function (files) {
			return apiBase.Post(_controllerUrl + "DownloadAll", null, { files: files }, true, true);
		}
		var getFileName = function (s) {
			if (s == null)
				return "";
			var index = s.indexOf("=");
			index = index + 1;
			var filename = s.substring(index, s.length);
			filename = filename.replace(/"/g, '');
			return filename;
		}
		return {
			addFile: _addFile,
			getFiles: _getFiles,
			removeFile: _removeFile,
			downloadFiles: _downloadFiles,
			getFileName: getFileName
		}

	}
}