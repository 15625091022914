export default function (app) {
    app.factory('homeNotificationService', HomeNotificationService);

    HomeNotificationService.$inject = ['apiBase', 'ApiPath', '$interval','$rootScope'];
    function HomeNotificationService(apiBase, ApiPath, $interval, $rootScope) {
        var entity = "WebNotification";
        var controllerUrl = ApiPath + entity + "/";
        var _annualCycleController = ApiPath + "AnnualCycle/";
        var _getAllNotification = function (pageNumber) {
            return apiBase.Get(controllerUrl + "GetAllNotification", { pageNumber: pageNumber}, null);
            }

        var _getNotification = function (count) {
            return apiBase.Get(controllerUrl + "GetAllNotification", { numberOfNotification: count}, null);
        }

        var _getUnseenNotification = function () {
            return apiBase.Get(controllerUrl + "GetUserUnSeenCount", null, null);
        }

        var _markSeen = function (id) {
            return apiBase.Post(controllerUrl + "MarkNotificationSeen", { notificationID: id}, null);
        }

        var _getCyclesList = function () {
            return apiBase.Get(_annualCycleController + "GetAllCycles", null);
        }
        var _processUnseenNotificationCount = function (successCallBack , errorcallBack) {
            _getUnseenNotification().then(function (response) {
                successCallBack (response)
            }).catch(function (error) {
               // errorcallBack(error);
            });
        }
        var intervalRef = null;
        var StartPull = function (successCallBack, errorcallBack) {
           
            intervalRef = $interval(function () {
                if ($rootScope.GetCurrentUser() && $rootScope.GetCurrentUser().CurrentRoleID != 2 && $rootScope.GetCurrentUser().CurrentRoleID != null) {

                    _processUnseenNotificationCount(successCallBack, errorcallBack);
                }
                }, 10000); //repeat the request every 1 min

            
        }

            var StopPull = function () {
                if (intervalRef != null) {
                    $interval.cancel(intervalRef);
                }
            }

        return {
            getAllNotification: _getAllNotification,
            getNotification: _getNotification,
            getUnseenNotification: _getUnseenNotification,
            markSeen: _markSeen,
            startPull: StartPull,
            stopPull: StopPull,
            getCyclesList: _getCyclesList
        }
    }
}