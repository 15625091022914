import { cloneDeep as _cloneDeep, filter as _filter, map as _map, find as _find, isNull as _isNull, reverse as _reverse, isEqual as _isEqual } from "lodash";
import { diff } from 'deep-diff';

export default function (app) {
    app.controller("sectionSubmissionController", ['$scope', 'annualAchievementService', 'uiLoadingIndicators', '$rootScope', '$stateParams', 'sectionStatusConstant', 'taskTypesConstant', 'errorNotificationService', '$uibModal', '$state', 'actionsTypesConstant', 'sectionsService', 'successrNotificationService', '$translate', 'multiFileUploadService', 'formHelpersService', 'tasksService',
        function ($scope, annualAchievementService, uiLoadingIndicators, $rootScope, $stateParams, sectionStatusConstant, taskTypesConstant, errorNotificationService, $uibModal, $state, actionsTypesConstant, sectionsService, successrNotificationService, $translate, multiFileUploadService, formHelpersService, tasksService) {
            var vm = this;
            var _singleSectionAssigementModal = null;
            var _viewHistoryModal = null;
            var _viewRequestModal = null;
            $translate(["ACHIEVEMENT.NEW", 'ACHIEVEMENT.EDITING', 'ACHIEVEMENT.REVIEWING', 'ACHIEVEMENT.REVIEWED', 'ACHIEVEMENT.SENT_BACK',
                'ACHIEVEMENT.CREATE', 'ACHIEVEMENT.REASSIGN', 'ACHIEVEMENT.REOPEN', 'ACHIEVEMENT.EDITOR', 'ACHIEVEMENT.REVIEWER',
                'ACHIEVEMENT.SAVE', 'ACHIEVEMENT.SUBMIT', 'ACHIEVEMENT.APPROVE', 'ACHIEVEMENT.SENDBACK']).then(function (translations) {
                    vm.labels = {
                        NEW: translations['ACHIEVEMENT.NEW'],
                        EDITING: translations['ACHIEVEMENT.EDITING'],
                        REVIEWING: translations['ACHIEVEMENT.REVIEWING'],
                        REVIEWED: translations['ACHIEVEMENT.REVIEWED'],
                        SENT_BACK: translations['ACHIEVEMENT.SENT_BACK'],
                        CREATE: translations['ACHIEVEMENT.CREATE'],
                        REASSIGN: translations['ACHIEVEMENT.REASSIGN'],
                        REOPEN: translations['ACHIEVEMENT.REOPEN'],
                        EDITOR: translations['ACHIEVEMENT.EDITOR'],
                        REVIEWER: translations['ACHIEVEMENT.REVIEWER'],
                        SAVE: translations['ACHIEVEMENT.SAVE'],
                        SUBMIT: translations['ACHIEVEMENT.SUBMIT'],
                        APPROVE: translations['ACHIEVEMENT.APPROVE'],
                        SENDBACK: translations['ACHIEVEMENT.SENDBACK'],
                    }
                });
            vm.form = {};
            vm.instructions = [];
            vm.actions = [];
            vm.model = {};
            vm.oldModel = {};
            vm.isCycleEnabled = $state.params.isCycleEnabled ? JSON.parse($state.params.isCycleEnabled) : false;
            vm.cycleActive = $state.params.cycleActive ? JSON.parse($state.params.cycleActive) : false;
            vm.workflowInfo = {};
            vm.assignmentInfo = {};
            vm.sectionStatusConstant = sectionStatusConstant;
            vm.taskTypesConstant = taskTypesConstant;
            vm.options = {};
            vm.sectionTitle = '';
            vm.IsShared = false;
            vm.activityID = $state.params.activityID;
            vm.sectionkey = $state.params.sectionID;
            vm.isDeleted = false;
            //vm.cycleID = $rootScope.cycleID;
            vm.isActionBtnLoading = {};
            vm.actionsTypes = actionsTypesConstant;
            // vm.cycleID = $rootScope.cycleID == null ? $stateParams.cycleID : $rootScope.cycleID ;
            var _viewSharedCommentModal = null;
            var _viewCommentModal;
            vm.EntityEditingFields = [];
            vm.isRtl = function () {
                return $translate.use() == 'ar' ? true : false;
            }
            var _getSingleSectionDetails = function (sectionID) {
                uiLoadingIndicators.load('sectionActivity', true);
                annualAchievementService.getSingleSectionDetails(sectionID, $state.params.cycleID).then(function (response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        vm.activityInfo = _data.activityInfo;
                        vm.options.formState = _data.form.formState;
                        vm.EntityEditingFields = _data.form.EntityEditingFields ? JSON.parse(_data.form.EntityEditingFields) : [];
                        vm.form.model = _data.form.model;
                        vm.oldModel = angular.copy(_data.form.model);
                        vm.actions = _data.actions;
                        vm.workflowInfo = _data.workflowInfo;
                        vm.assignmentInfo = _data.assignmentInfo;
                        vm.hasHistory = _data.hasHistory;
                        vm.canEdit = _data.CanEdit;
                        vm.isDeleted = _data.isDeleted;
                        vm.hasSectionComments = _data.HasSectionComments;
                        vm.SectionCommentsCount = _data.SectionCommentsCount;
                        vm.actions = _data.actions;
                        vm.reversedActions = _reverse(vm.actions);
                        var _form = _data.form;
                        _form.fields.push({
                            type: 'input',
                            className: "d-none",
                            controller: ($timeout) => {
                                $timeout(function () {

                                    $('.form-group label span.truncate').each(
                                        function () {
                                            $(this).removeClass('truncate');
                                            var parentWidth = $(this).parent().parent().width();
                                            //var child = $(this).width();
                                            var childWidth = $(this).parent().width();

                                            if (childWidth >= parentWidth) {
                                                $(this).addClass('truncate');
                                            }
                                            else if (childWidth < 341) {
                                                $(this).addClass('truncate');
                                            }
                                        }
                                    );
                                }, 0);
                            }
                        })
                        configureForm(_form.fields);
                        _form.model.EntityEditingFields = vm.EntityEditingFields;
                        vm.form = _form;
                        vm.form.model.oldModel = angular.copy(_data.form.model)


                    } else {
                        //TODO show error msg
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('sectionActivity', false);
                }, function (error) {
                    uiLoadingIndicators.load('sectionActivity', false);
                    //TODO show error msg
                    errorNotificationService.showErrorNotification(error);
                })
            }

            var _getSectionActivityDetails = function (activityID) {
                uiLoadingIndicators.load('sectionActivity', true);
                annualAchievementService.getSectionActivityDetails(activityID, $state.params.cycleID).then(function (response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        vm.activityInfo = _data.activityInfo;
                        vm.EntityEditingFields = _data.form.EntityEditingFields ? JSON.parse(_data.form.EntityEditingFields) : [];
                        configureForm(_data.form.fields);
                        _data.form.model.EntityEditingFields = vm.EntityEditingFields;
                        vm.form = _data.form;
                        vm.form.model.oldModel = angular.copy(_data.form.model)
                        vm.form.model = _data.form.model;
                        vm.oldModel = angular.copy(_data.form.model);
                        vm.options.formState = _data.form.formState;
                        vm.actions = _data.actions;
                        vm.workflowInfo = _data.workflowInfo;
                        vm.assignmentInfo = _data.assignmentInfo;
                        vm.hasHistory = _data.hasHistory;
                        vm.hasComment = _data.hasComment;
                        vm.canEdit = _data.CanEdit;
                        vm.hasSectionComments = _data.HasSectionComments;
                        vm.SectionCommentsCount = _data.SectionCommentsCount;
                        vm.actions = _data.actions;
                        vm.isDeleted = _data.isDeleted;
                        vm.reversedActions = _reverse(vm.actions);
                        vm.IsShared = _data.form.model['initiative_SharedInitiative'];
                    } else {
                        //TODO show error msg
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('sectionActivity', false);
                }, function (error) {
                    uiLoadingIndicators.load('sectionActivity', false);
                    //TODO show error msg
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getStatName = function (statType) {
                if (statType == sectionStatusConstant.NEW) {
                    return vm.labels.NEW;
                } else if (statType == sectionStatusConstant.IN_PROGRESS) {
                    return vm.labels.EDITING;
                } else if (statType == sectionStatusConstant.SUBMITTED) {
                    return vm.labels.REVIEWING;
                }
                else if (statType == sectionStatusConstant.APPROVED) {
                    return vm.labels.REVIEWED;
                } else if (statType == sectionStatusConstant.SENT_BACK) {
                    return vm.labels.SENT_BACK;
                }
            }

            vm.isHighlightedText = function (statType, taskType) {
                var _edit = vm.taskTypesConstant.EDIT;
                var _review = vm.taskTypesConstant.REVIEW;
                if (taskType == _edit && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
                    return true;
                }

                if (taskType == _review && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
                    return true;
                }
            }

            vm.openSectionAssigementModal = function (workflowInfo) {
                var _sectionkey = $state.params.sectionID;
                var _activityID = $state.params.activityID;
                var _SectionAssignementPromise = _activityID ? annualAchievementService.getActivityAssigementInfo(_activityID) : annualAchievementService.getSectionAssignementInfo(_sectionkey);
                var _assignmentInfo = vm.assignmentInfo;
                var _workflowInfo = vm.workflowInfo;

                _SectionAssignementPromise.then(function (response) {
                    var _labels = vm.labels;
                    if (response && response.responseCode == 1) {
                        if (document.getElementsByClassName("modal").length === 0)
                            _singleSectionAssigementModal = $uibModal.open({
                                backdrop: true,
                                keyboard: true,
                                backdropClick: true,
                                template: require('../Views/sectionAssigementModal.html'),
                                size: 'lg',
                                controllerAs: 'vm',
                                controller: ['$uibModalInstance', function ($uibModalInstance) {
                                    var vm = this;
                                    var _data = response.responseObject;
                                    var _editors = _data.editors;
                                    var _reviewers = _data.reviewers;
                                    var _formModel = _data.formModel;
                                    var _selectedReviewersAndEditorModel = _cloneDeep(_data.model);
                                    var _numberOfApprovals = _data.numberOfApprovals;

                                    vm.showSectionAssigementModalSpinner = false;

                                    vm.getActionBtnName = function () {
                                        if (!_assignmentInfo || _assignmentInfo.length < 2) {
                                            return _labels.CREATE;
                                        } else if (!_isNull(workflowInfo.status)) {
                                            var _workflowStates = workflowInfo.status;
                                            if (_workflowStates == sectionStatusConstant.NEW ||
                                                _workflowStates == sectionStatusConstant.IN_PROGRESS ||
                                                _workflowStates == sectionStatusConstant.SUBMITTED ||
                                                _workflowStates == sectionStatusConstant.SENT_BACK) {
                                                return _labels.REASSIGN;
                                            } else if (_workflowStates == sectionStatusConstant.APPROVED) {
                                                return _labels.REOPEN;
                                            }
                                        }
                                    }

                                    var _selectedReviewersModel = _filter(_selectedReviewersAndEditorModel, (item) => {
                                        return item.taskType == taskTypesConstant.REVIEW;
                                    });

                                    var _selectedReviewersIDs = _map(_selectedReviewersModel, (item) => {
                                        var _reviewer = _find(_reviewers, (reviewer) => reviewer.id == item.userID)
                                        return _reviewer ? item.userID : undefined;
                                    })

                                    var _selectedEditor = _find(_selectedReviewersAndEditorModel, (item) => {
                                        return item.taskType == taskTypesConstant.EDIT;
                                    })

                                    var _editor = _selectedEditor ? _find(_editors, (editor) => editor.id == _selectedEditor.userID) : undefined;

                                    var _selectedReviewersIDsAsObject = Object.assign({}, _selectedReviewersIDs);

                                    vm.model = Object.assign({},
                                        { reviewers: _selectedReviewersIDsAsObject },
                                        { selectedEditor: _editor ? _selectedEditor.userID : undefined },
                                        _formModel,
                                    );

                                    //a work arround to remove Entity Name AR and Pillar
                                    //if section ID is EntityProfile pick only entityProfile_entityNameEN field
                                    if (_sectionkey == "EntityProfile") {
                                        var _entityProfile_entityNameEN = _find(_data.formDescription, (field) => {
                                            return field.key == "entityProfile_entityNameEN";
                                        })
                                        if (_entityProfile_entityNameEN) {
                                            _data.formDescription = [_entityProfile_entityNameEN];
                                        }
                                    }

                                    vm.formDescription = _data.formDescription;
                                    vm.formDescription2 = [];
                                    vm.formDescription2.push(
                                        {
                                            type: 'select',
                                            key: 'selectedEditor',
                                            templateOptions: {
                                                label: _labels.EDITOR,
                                                required: true,
                                                notNull: true,
                                                options: _map(_editors, (editor) => {
                                                    return { name: editor.name, value: editor.id }
                                                })
                                            }
                                        },
                                        {
                                            key: 'reviewers',
                                            wrapper: 'horizontalLayout',
                                            fieldGroup: []
                                        }
                                    );

                                    for (var i = 0; i < _numberOfApprovals; i++) {
                                        var _lenght = vm.formDescription2.length - 1;
                                        //passed by ref
                                        var _container = vm.formDescription2[_lenght];
                                        _container.fieldGroup.push(
                                            {
                                                type: 'select',
                                                templateOptions: {
                                                    label: _labels.REVIEWER,
                                                    required: true,
                                                    notNull: true,
                                                    options: _map(_reviewers, (reviewer) => {
                                                        return { name: reviewer.name, value: reviewer.id }
                                                    })
                                                }
                                            }
                                        )
                                    }

                                    vm.ok = function () {
                                        vm.showSectionAssigementModalSpinner = true;
                                        var updatedModel = [];
                                        if (_selectedReviewersModel && _selectedEditor) {
                                            updatedModel = _map(_selectedReviewersModel, (item, index) => {
                                                var _updatedReviewerID = vm.model.reviewers[index];
                                                item.UserID = _updatedReviewerID;
                                                return item;
                                            })
                                            _selectedEditor.UserID = vm.model.selectedEditor;
                                            updatedModel.unshift(_selectedEditor);
                                        } else {

                                            updatedModel = _map(vm.model.reviewers, (item, key) => {
                                                return {
                                                    Type: taskTypesConstant.REVIEW,
                                                    Order: parseInt(key) + 2,
                                                    UserID: item,
                                                    TaskID: 0
                                                }
                                            })

                                            updatedModel.push({
                                                Type: taskTypesConstant.EDIT,
                                                Order: 1,
                                                UserID: vm.model.selectedEditor,
                                                TaskID: 0
                                            })
                                        }

                                        var _updateAssigementPromise = null;
                                        var _id = null;
                                        if ($state.params.activityID) {
                                            _id = $state.params.activityID;
                                        } else if ($state.params.sectionID) {
                                            _id = $state.params.sectionID;
                                        }

                                        if (workflowInfo.status == sectionStatusConstant.APPROVED) {
                                            if (_activityID) {
                                                _updateAssigementPromise = annualAchievementService.reopenActivity(_id, updatedModel);

                                            } else {
                                                _updateAssigementPromise = annualAchievementService.reopenSingleSection(_id, updatedModel);
                                            }
                                        } else {
                                            if (_activityID) {
                                                _updateAssigementPromise = annualAchievementService.reassignActivity(_id, updatedModel);
                                            } else {
                                                _updateAssigementPromise = annualAchievementService.reassignSingleSection(_id, updatedModel);
                                            }
                                        }

                                        _updateAssigementPromise.then((resp) => {
                                            if (resp && resp.responseCode == 1) {
                                                $state.reload();
                                                //TODO success message
                                                successrNotificationService.showSuccessAssigningNotfication(_workflowInfo.type);
                                            } else {
                                                //show error msg
                                                errorNotificationService.showErrorNotification(resp.responseCode);
                                            }
                                            vm.showSectionAssigementModalSpinner = false;
                                            $uibModalInstance.close();
                                        }, function (err) {
                                            //TODO show error
                                            errorNotificationService.showErrorNotification(err);
                                            $uibModalInstance.close();
                                        })
                                    }

                                    vm.cancel = function () {
                                        vm.showSectionAssigementModalSpinner = false;
                                        $uibModalInstance.close();
                                    }
                                }]
                            })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, function (error) {
                    //TODO show error msg
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getSectionHistory = function () {
                var _sectionkey = $state.params.sectionID;
                var _activityID = $state.params.activityID;
                var _historyPromise = _activityID ? annualAchievementService.getActivityHistory(_activityID) : annualAchievementService.getSectionHistory(_sectionkey, $state.params.cycleID);

                _historyPromise.then(function (response) {
                    var _labels = vm.labels;
                    if (response && response.responseCode == 1) {
                        if (document.getElementsByClassName("modal").length === 0)
                            _viewHistoryModal = $uibModal.open({
                                backdrop: true,
                                keyboard: true,
                                backdropClick: true,
                                template: require('../Views/historyModal.html'),
                                size: 'lg',
                                controllerAs: 'vm',
                                controller: ['$uibModalInstance', function ($uibModalInstance) {
                                    var vm = this;
                                    vm.actionsTypesConstant = actionsTypesConstant;
                                    vm.taskTypesConstant = taskTypesConstant;
                                    vm.history = response.responseObject;


                                    vm.getActionName = function (actionID) {
                                        if (actionID == actionsTypesConstant.SAVE) {
                                            return _labels.SAVE;
                                        } else if (actionID == actionsTypesConstant.SUBMIT) {
                                            return _labels.SUBMIT;
                                        } else if (actionID == actionsTypesConstant.APPROVE) {
                                            return _labels.APPROVE;
                                        }
                                        else if (actionID == actionsTypesConstant.SENDBACK) {
                                            return _labels.SENDBACK;
                                        }
                                    }

                                    vm.cancel = function () {
                                        $uibModalInstance.dismiss();
                                    }

                                }]
                            })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                }, function (error) {
                    //TODO show error
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getSectionComments = function () {

                annualAchievementService.getSectionCommentsHistory(vm.sectionkey, $state.params.cycleID).then(function (response) {
                    if (response && response.responseCode == 1) {
                        var _comments = response.responseObject;
                        var sectionKey = vm.sectionkey;
                        var _self = vm;
                        if (document.getElementsByClassName("modal").length === 0)
                            _viewCommentModal = $uibModal.open({
                                keyboard: true,
                                animation: true,
                                template: require('../Views/commentsModal.html'),
                                size: 'lg',
                                controllerAs: 'vm',
                                controller: ['$rootScope', '$uibModalInstance', 'annualAchievementService', function ($rootScope, $uibModalInstance, annualAchievementService) {
                                    var vm = this;
                                    vm.loading = $rootScope.loading;
                                    vm.RepliedComments = null;
                                    vm.ModalSpinner = false;
                                    vm.sectionComments = _comments.UsersComments;
                                    vm.CanAddComment = _comments.CanAddComment;
                                    vm.addComment = function () {
                                        vm.ModalSpinner = true;
                                        annualAchievementService.submitEntitySectionComment(sectionKey, vm.replyComment).then((res) => {

                                            if (res) {
                                                var today = new Date().toISOString();
                                                vm.ModalSpinner = false;
                                                var newComment = { Comment: vm.replyComment, FullName: $rootScope.GetCurrentUser().FullName, ActionDataTime: today, ImagePath: $rootScope.GetCurrentUser().ImagePath };
                                                vm.sectionComments.splice(0, 0, newComment);
                                                _self.SectionCommentsCount++;
                                                vm.replyComment = '';
                                            }
                                        }, function (error) {
                                            errorNotificationService.showErrorNotification(error);
                                        })
                                    }
                                    vm.cancel = function () {
                                        $uibModalInstance.dismiss();
                                    }
                                }]
                            })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                }, function (error) {
                    //TODO show error
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getCommentsList = function () {
                var _activityID = $state.params.activityID;

                annualAchievementService.getCommentsList(_activityID).then(function (response) {
                    var _labels = vm.labels;
                    if (response && response.responseCode == 1) {
                        if (document.getElementsByClassName("modal").length === 0)
                            _viewSharedCommentModal = $uibModal.open({
                                backdrop: true,
                                keyboard: true,
                                backdropClick: true,
                                template: require('../Views/sharedInitiativeComments.html'),
                                size: 'lg',
                                controllerAs: 'vm',
                                controller: ['$uibModalInstance', function ($uibModalInstance) {
                                    var vm = this;
                                    vm.commentsList = response.responseObject;
                                    vm.comment = null;
                                    vm.commentMissed = false;
                                    vm.cancel = function () {
                                        $uibModalInstance.dismiss();
                                    }

                                }]
                            })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                }, function (error) {
                    //TODO show error
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getIsSectionEnabled = function () {
                //disabled by default
                if ($state.params.sectionID) {
                    var _currentSection = sectionsService.getSection($state.params.sectionID);
                    if (_currentSection)
                        return !!_currentSection.enabled;
                }
                return false

                //return true;
            }

            vm.getSectionActionName = function (actionID) {
                if (actionID == actionsTypesConstant.SAVE) {
                    return vm.labels.SAVE;
                } else if (actionID == actionsTypesConstant.SUBMIT) {
                    return vm.labels.SUBMIT;
                } else if (actionID == actionsTypesConstant.APPROVE) {
                    return vm.labels.SUBMIT;
                }
                else if (actionID == actionsTypesConstant.SENDBACK) {
                    return vm.labels.SENDBACK;
                }
            }
            vm.Reload = function () {
                _getSingleSectionDetails($state.params.sectionID);
            }
            vm.RequestBtnClick = function () {
                var _sectionkey = $state.params.sectionID;
                var parent = this;
                _viewRequestModal = $uibModal.open({
                    backdrop: true,
                    keyboard: true,
                    backdropClick: true,
                    template: require('../Views/RequestEditingModel.html'),
                    size: 'lg',
                    controllerAs: 'vm',
                    controller: ['$uibModalInstance', function ($uibModalInstance) {
                        var vm = this;
                        vm.Comment = '';
                        vm.ModalSpinner = false;
                        vm.labels;
                        $translate(["ACHIEVEMENT.RequestEditingMsg"]).then(function (translations) {
                            vm.labels = {
                                RequestEditingMsg: translations['ACHIEVEMENT.RequestEditingMsg'],

                            }
                        });
                        vm.SendRequest = function () {
                            vm.ModalSpinner = true;
                            annualAchievementService.requestSectionEditing(vm.Comment, _sectionkey).then(function (response) {
                                vm.ModalSpinner = false;
                                if (response && response.responseCode == 1) {
                                    successrNotificationService.showSuccessSendingRequestNotfication();

                                    parent.Reload();
                                    $uibModalInstance.close();
                                }
                                else if (response && response.responseCode == 31) {
                                    errorNotificationService.showErrorNotification(response.responseCode);
                                    $uibModalInstance.close();
                                }
                                else {
                                    errorNotificationService.showErrorNotification(response.responseCode);
                                    $uibModalInstance.close();
                                }

                            }, function (error) {
                                //TODO show error
                                errorNotificationService.showErrorNotification(error);
                            })
                        }
                        vm.cancel = function () {
                            $uibModalInstance.close();
                        }

                    }]
                })
            }

            vm.submitTaskAction = function (actionId) {
                vm.DefineEntityEditingFields();
                var form = {
                    model: vm.form.model,
                    EntityEditingFields: vm.EntityEditingFields
                };
                vm.actionClicked = vm.isActionBtnLoading[actionId] = true;
                var _taskID = vm.workflowInfo.taskID;
                var _comment = vm.comment ? vm.taskType == vm.taskTypesConstant.REVIEW && vm.comment : '';
                uiLoadingIndicators.load('annualAchievement', true);

                //try upload form files first
                //var _files = formHelpersService.getFiles(vm.options.formState.filesStorageKey);
                //if (_files && _files.length > 0) {

                //    multiFileUploadService.upload(_files).then(function (resp) {
                //        if (resp.status == 200) {
                //            _submitAction(actionId, _taskID, _comment, form).then(function (response) {
                //                if (response && response.responseCode == 1) {
                //                    $scope.taskForm.$setSubmitted();
                //                    $state.reload();
                //                } else {
                //                    errorNotificationService.showErrorNotification(response.responseCode);
                //                }
                //                vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                //            }, function (error) {
                //                errorNotificationService.showErrorNotification(error);
                //                vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                //            })
                //        } else {
                //            //TODO show error msg
                //            vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                //        }
                //    }, function (error) {
                //        //TODO show error msg
                //        vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                //    })

                //} else {
                _submitAction(actionId, _taskID, _comment, form).then(function (response) {
                    uiLoadingIndicators.load('annualAchievement', false);

                        if (response && response.responseCode == 1) {
                            $scope.taskForm.$setSubmitted();
                            $state.reload();
                        } else {
                            errorNotificationService.showErrorNotification(response.responseCode);
                        }
                        vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                    }, function (error) {
                        errorNotificationService.showErrorNotification(error);
                        vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                    })
                //}
            }

            var _submitAction = function (actionId, _taskID, _comment, model) {
                return tasksService.submitTaskAction(_taskID, actionId, _comment, model).then(function (response) {
                    if (response && response.responseCode == 1) {
                        return Promise.resolve(response);
                    } else {
                        return Promise.resolve(response);
                    }
                }, function (error) {
                    return Promise.reject(error)
                })
            }

            var _init = function () {

                if ($state.params.activityID) {
                    _getSectionActivityDetails($state.params.activityID)
                } else if ($state.params.sectionID) {
                    _getSingleSectionDetails($state.params.sectionID);
                }
            }()

            $scope.$on('$destroy', function () {
                if (_singleSectionAssigementModal && _singleSectionAssigementModal.close) {
                    _singleSectionAssigementModal.close();
                    _singleSectionAssigementModal = null;
                }

                if (_viewHistoryModal && _viewHistoryModal.close) {
                    _viewHistoryModal.close();
                }
                if (_viewCommentModal && _viewCommentModal.close) {
                    _viewCommentModal.close();
                }
                if (_viewSharedCommentModal && _viewSharedCommentModal.close) {
                    _viewSharedCommentModal.close();
                }
                if (_viewRequestModal && _viewRequestModal.close) {
                    _viewRequestModal.close();
                }

            });
            vm.DefineEntityEditingFields = function () {

                var oldModel = vm.form.model.oldModel;
                vm.EntityEditingFields = vm.form.model.EntityEditingFields;
                delete vm.form.model.oldModel;
                delete vm.form.model.EntityEditingFields;

                var d = diff(vm.form.model, oldModel);

                if (typeof d == "undefined") return;
                var r = d.filter((x) => {

                    if (x.lhs == "" && x.rhs == null)
                        return false;
                    else if (x.item)
                        return true;
                    else
                        return (typeof x.lhs != "undefined" && (typeof x.lhs == "string" ? !x.lhs.includes("object:") : true));
                });

                r.forEach((x) => {
                    var index = vm.EntityEditingFields.findIndex(z => _isEqual(z, x.path));
                    if (index == -1) {
                        vm.EntityEditingFields.push(x.path
                        );
                    }
                });
            }

            var configureForm = function (theObject) {
                var result = null;
                if (theObject instanceof Array) {
                    for (var i = 0; i < theObject.length; i++) {
                        result = configureForm(theObject[i]);
                        if (result) {
                            break;
                        }
                    }
                }
                else {
                    if (theObject.hasOwnProperty('fieldGroup')) {
                        result = configureForm(theObject.fieldGroup);
                    }

                    else if (theObject.templateOptions && theObject.templateOptions.hasOwnProperty('fields')) {
                        result = configureForm(theObject.templateOptions.fields);

                    }

                    else if (theObject.templateOptions && vm.EntityEditingFields.findIndex(x => x == theObject.key) > -1) {
                        theObject.templateOptions.IsEdited = true;
                    }
                }
                return result;
            }
        }])
}
