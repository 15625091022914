export default function (formlyBuilder, _) {

    formlyBuilder.controller('datepickerConfigController', ['$scope', '$translate', function ($scope, $translate) {
        $scope.integerRegex = /-?[0-9]*$/;
        $scope.isopen = false;
        $scope.currentLang = $translate.use();
        $scope.selectedDataType = "";
        $scope.selectedDate = "";
        $scope.selectedMaxDataType = "";
        $scope.SetDateMinValue = function () {
            if ($scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions ) {

                if ($scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.min && $scope.fieldConfigCtrl.formBuilderField.templateOptions.min == "today") {
                    $scope.selectedDataType = "today";
                    $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.min = { ValueEn: "today" };
                }
                else {
                    $scope.selectedDataType = " ";
                }

                if ($scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.max && $scope.fieldConfigCtrl.formBuilderField.templateOptions.max == "today") {
                    $scope.selectedMaxDataType = "today";
                    $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.max = { ValueEn: "today" };
                }
                else {
                    $scope.selectedMaxDataType = " ";
                }
            }
            else {
                 $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions = {};
            }

        }
        $scope.ChangeDateMinValue = function () {

            if ($scope.selectedDataType == "today") {
                $scope.fieldConfigCtrl.formBuilderField.templateOptions.min = "today";
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.min = { ValueEn: "today" };
            
            }
            else {
                $scope.fieldConfigCtrl.formBuilderField.templateOptions.min = "";
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.min = { ValueEn: "" };
            }


        }
        $scope.ChangeDateMaxValue = function () {

            if ($scope.selectedMaxDataType == "today") {
                $scope.fieldConfigCtrl.formBuilderField.templateOptions.max = "today";
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.max = { ValueEn: "today" };

            }
            else {
                $scope.fieldConfigCtrl.formBuilderField.templateOptions.max = "";
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.max = { ValueEn: "" };
            }


        }
        $scope.SetDateMinValue();
    }]);
};