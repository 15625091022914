import { remove as _remove, map as _map } from 'lodash';
export default function (app) {

    app.controller("EntityCountriesReportController", ['$scope', 'reportsService', 'exportFileService', 'uiLoadingIndicators', 'taskTypesConstant', 'sectionStatusConstant', 'errorNotificationService', '$stateParams', 'routeConstants', '$state', '$rootScope', '$uibModal', '$timeout', 'actionsTypesConstant', 'successrNotificationService', 'sectionsService', '$translate', 'Excel', '$filter', '$document',
        function ($scope, reportsService,exportFileService, uiLoadingIndicators, taskTypesConstant, sectionStatusConstant, errorNotificationService, $stateParams, routeConstants, $state, $rootScope, $uibModal, $timeout, actionsTypesConstant, successrNotificationService, sectionsService, $translate, Excel, $filter,$document) {
            var vm = this;
            vm.labels = {
                ALL: $translate.instant('REPORTS.ALL'),
                SECTIONS: $translate.instant('COMMON.SECTIONS'),
                ENTITIY: $translate.instant('COMMON.ENTITIY'),
                PILLAR: $translate.instant('COMMON.PILLAR'),
                MBRGI: $translate.instant('COMMON.MBRGI'),
                Countries: $translate.instant('REPORTS.Countries'),
                Countries_Total_Cost: $translate.instant('REPORTS.Countries_Total_Cost'),
                Countries_Total_BENEFICIARIES: $translate.instant('REPORTS.Countries_Total_BENEFICIARIES'),
            }



            vm.cycles = [];
            vm.activeCycleId = 0;
            $scope.$watch('vm.activeCycleId', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    vm.displayExport = false;
                }
            }, true);
            vm.entitiesIdList = [];
            vm.coulmnKeys = {};
            vm.levels = [];
            vm.displayReport = false;
            vm.displayExport = false;
            vm.displayCharts = false;
            vm.isOneChart = false;
            vm.isEmptyList = false;
            vm.entities = new Object();
            vm.reportInfo = [];
            vm.totalResuilts = [];
            vm.sectionData = {};
            vm.Loading = $rootScope.Loading;
            vm.loadingPdfFile = false;

            vm.downloadPdf = function () {
                vm.loadingPdfFile = true;

                var repo = $document[0].getElementById("ReportContent").innerHTML;
                var Data = { value: repo };
                var entityLevelID = $rootScope.enums.ReportLevels['Entities'];
                var reportType = $rootScope.enums.ReportTypes['Countries'];
                var exportFile = exportFileService.exportPdf(Data, entityLevelID, reportType);
                Promise.all([exportFile]).then(function (res) {
                    vm.loadingPdfFile = false;
                    $scope.$apply();

                });
            };

            var _getAllFilterList = function () {
                uiLoadingIndicators.load('report', true);
                var cyclesList = reportsService.getCyclesList();

                getFiltersRequests(cyclesList);

            }
            function getFiltersRequests(cyclesList) {
                Promise.all([cyclesList]).then(function (res) {
                    if (res != undefined) {
                        getCyclesList(res[0]);
                        $scope.$apply();
                    }
                    uiLoadingIndicators.load('report', false);

                });
            }

            function getCyclesList(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data.length > 0) {
                        vm.cycles = _map(_data, (cycle) => {
                            if ($rootScope.currentLang == 'en') {
                                cycle.name = cycle.NameEn;
                            }
                            else {
                                cycle.name = cycle.NameAr;
                            }
                            if (cycle.ActiveCycle) {
                                vm.activeCycleId = cycle.ID;
                            }
                            return cycle;
                        });
                        if (vm.activeCycleId == 0) {
                            vm.cycles = $filter('orderBy')(vm.cycles, 'CreationDate', 'reverse');
                            vm.activeCycleId = vm.cycles[0].ID;
                        }


                    }
                } else {

                    errorNotificationService.showErrorNotification(response.responseCode);
                }


            }
            function getEntitiesList(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data != null && !(angular.equals({}, _data))) {
                        vm.entities = _data;
                        vm.entities[0] = vm.labels.ALL;
                    }
                } else {

                    errorNotificationService.showErrorNotification(response.responseCode);
                }


            }


            vm.GetObjectLength = function (objectData) {
                return Object.keys(objectData).length;
            }
            function getReports(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    vm.reportInfo = _data.Result;
                    vm.totals = _data.ResultTotals;
                    vm.coulmnKeys = _data.ColumnName;

                    if (vm.reportInfo.length > 0 && vm.reportInfo) {

                        vm.displayReport = true;
                        vm.displayExport = true;


                    }
                    else {
                        vm.displayReport = false;
                        vm.isEmptyList = true;
                    }
                }

            }
            var _init = function () {
                vm.Loading();

                _getAllFilterList();
                vm.Loading();


            }();

            var _initReportData = function () {
                vm.displayCharts = false;
                vm.displayReport = false;
                vm.isOneChart = false;
                vm.isEmptyList = false;
            }
            vm.GenerateReport = function () {
                uiLoadingIndicators.load('report', true);
                _initReportData();
                var report = reportsService.getCountriesEntitiesReportInfo(vm.activeCycleId);
                Promise.all([report]).then(function (res) {
                    if (res != undefined) {

                        getReports(res[0]);
                        $scope.$apply();
                    }
                    uiLoadingIndicators.load('report', false);

                }, function (error) {
                    errorNotificationService.showErrorNotification(error);
                    uiLoadingIndicators.load('report', false);
                });



            }
            vm.exportToexcel = function (tableId) {
                vm.loadingExcelFile = true;
                var exportFile = exportExcel();
                Promise.all([exportFile]).then(function (res) {
                    vm.loadingExcelFile = false;
                    $scope.$apply();
                });
            };
            var exportExcel = function () {
                return  reportsService.exportCountriesEntitiesReportInfo(vm.activeCycleId).then(function (response) {

                    exportFileService.exportExcel(response);


                }, function (error) {

                    //normal error
                    errorNotificationService.showErrorNotification(error);
                });

            }
          
        }])
}