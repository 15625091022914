export default function (app) {
    app.controller('emailTemplatesController', EmailTemplatesController);

    EmailTemplatesController.$inject = ["$scope", "$rootScope", "emailTemplatesDataService", "$uibModal", "Notification", "routeConstants", "$state","$translate"];
    function EmailTemplatesController($scope, $rootScope, emailTemplatesDataService, $uibModal, Notification, routeConstants, $state, $translate) {
        var vm = this;
        $translate(["EMAIL_TEMPLATES.EMAIL_TEMPLATE_DELETED", "EMAIL_TEMPLATES.EMAIL_TEMPLATE_ACTIVATED", "EMAIL_TEMPLATES.EMAIL_TEMPLATE_DEACTIVATED", "EMAIL_TEMPLATES.DEACTIVATE_EMAIL_TEMPLATE",
            "EMAIL_TEMPLATES.DELETE_EMAIL_TEMPLATE", "EMAIL_TEMPLATES.EMAIL_TEMPLATE_DELETE_QUES", "EMAIL_TEMPLATES.EMAIL_TEMPLATE_DEACTIVATE_QUES"]).then(function (translations) {
            vm.labels = {
                EMAIL_TEMPLATE_DELETED: translations['EMAIL_TEMPLATES.EMAIL_TEMPLATE_DELETED'],
                EMAIL_TEMPLATE_ACTIVATED: translations['EMAIL_TEMPLATES.EMAIL_TEMPLATE_ACTIVATED'],
                EMAIL_TEMPLATE_DEACTIVATED: translations['EMAIL_TEMPLATES.EMAIL_TEMPLATE_DEACTIVATED'],
                DEACTIVATE_EMAIL_TEMPLATE: translations['EMAIL_TEMPLATES.DEACTIVATE_EMAIL_TEMPLATE'],
                DELETE_EMAIL_TEMPLATE: translations['EMAIL_TEMPLATES.DELETE_EMAIL_TEMPLATE'],
                EMAIL_TEMPLATE_DELETE_QUES: translations['EMAIL_TEMPLATES.EMAIL_TEMPLATE_DELETE_QUES'],
                EMAIL_TEMPLATE_DEACTIVATE_QUES: translations['EMAIL_TEMPLATES.EMAIL_TEMPLATE_DEACTIVATE_QUES'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.load = load;
        vm.deleteEmailTemplate = deleteEmailTemplate;
        vm.changeActiveStatus = changeActiveStatus;
        vm.goToAddTemplate = goToAddTemplate;
        vm.goToUpdateTemplate = goToUpdateTemplate;
        vm.goToTrash = goToTrash;
        vm.Modal = null;
        vm.load();

        function load() {
            vm.Loading();
            _getEmailTemplates();
        }

        function _getEmailTemplates() {
            emailTemplatesDataService.getAllTemplates().then(_getAllTemplatesSuccess, $rootScope.methodPublicError);
        }
        function _getAllTemplatesSuccess(res) {
            vm.Loading(false);
            if (res.responseCode === vm.ResponseCode.Success && res.responseObject) {
                vm.emailTemplates = res.responseObject.emailTemplates;
                vm.deletedCount = res.responseObject.deletedCount;
            }
        }
        function deleteEmailTemplate(ID) {
            var labels = vm.labels;
            var templateID = ID;
            vm.Modal = $uibModal.open({
                //backdrop: "static",
                keyboard: true,
                size: 'md modal-dialog-centered',
                animation: true,
                //backdropClick: true,
                templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
                controller: function ($uibModalInstance, $rootScope) {
                    var vm = this;
                    vm.header = labels.DELETE_EMAIL_TEMPLATE;
                    vm.message = labels.EMAIL_TEMPLATE_DELETE_QUES;
                    vm.ok = function () {
                        $uibModalInstance.close({ Close: false, Ok: true });
                    };
                        vm.cancel = function () {
                        $uibModalInstance.dismiss();
                    };
                },
                controllerAs: 'vm'
            });
            vm.Modal.result.then(function (result) {
                vm.Loading();
                if (result.Ok) {
                    emailTemplatesDataService.deleteEmailTemplate(templateID).then(deleteEmailTemplateSuccess, $rootScope.methodPublicError);
                }
            }, function (error) {
                //backdrop clicked or error
            });
        }
        function changeActiveStatus(ID, isDeleted) {
            var templateID = ID;
            var deleteState = isDeleted;
            var labels = vm.labels;
            if (deleteState === false) { //incase of deactivate show confirmation message 
                if (document.getElementsByClassName("modal").length === 0)
                    vm.Modal = $uibModal.open({
                        //  backdrop: "static",
                        keyboard: true,
                        size: 'md modal-dialog-centered',
                        animation: true,
                        //backdropClick: true,
                        templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
                        controller: function ($uibModalInstance) {

                            var vm = this;
                            vm.header = labels.DEACTIVATE_EMAIL_TEMPLATE;
                            vm.message = labels.EMAIL_TEMPLATE_DEACTIVATE_QUES;
                            vm.ok = function () {
                                $uibModalInstance.close({ Close: false, Ok: true });
                            };
                            vm.cancel = function () {
                                $uibModalInstance.close({ Close: true, Ok: false });
                            };
                        },
                        controllerAs: 'vm'
                    });
                vm.Modal.result.then(function (result) {
                    if (result.Ok) {
                        vm.Loading();
                        emailTemplatesDataService.deActivateEmailTemplate(templateID).then(deActivateEmailTemplateSuccess, $rootScope.methodPublicError);
                    }
                }, function (error) {
                    //backdrop clicked or error
                });
            }
            else {
                vm.Loading();
                emailTemplatesDataService.activateEmailTemplate(templateID).then(activateEmailTemplateSuccess, $rootScope.methodPublicError);
            }
        }
        vm.templatesFilter = function (item) {
            if (vm.search) {
                return item.templateName.toLowerCase().includes(vm.search.toLowerCase());
            }
            else
                return true;
        }
        function goToAddTemplate() {
            $state.go(routeConstants.AddEmailTemplatesState.State);
        }
        function goToUpdateTemplate(id) {
            $state.go(routeConstants.EditEmailTemplatesState.State, { templateID:id});
        }
        function goToTrash() {
            $state.go(routeConstants.RestoreEmailTemplatesState.State);
        }
        function deleteEmailTemplateSuccess(res) {
            vm.Loading(false);
            if (res && res.responseCode === vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.EMAIL_TEMPLATE_DELETED });
                vm.load();
            }
        }
        function activateEmailTemplateSuccess(res) {
            vm.Loading(false);
            if (res && res.responseCode === vm.ResponseCode.Success ) {
                Notification.success({ message: vm.labels.EMAIL_TEMPLATE_ACTIVATED });
                vm.load();
            }
        }
        function deActivateEmailTemplateSuccess(res) {
            vm.Loading(false);
            if (res && res.responseCode === vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.EMAIL_TEMPLATE_DEACTIVATED });
                vm.load();
            }
        }
        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
        });
     
    }
}