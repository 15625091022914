export default function (app) {
    app.controller('userController', UserController);

    UserController.$inject = ["$scope","$rootScope", "userDataService", "$uibModal", "Notification", "$mdDialog", "$stateParams", "$translate","roleDataService"];
    function UserController($scope,$rootScope, userServiceApi, $uibModal, Notification, $mdDialog, $stateParams, $translate, roleDataService) {
        var vm = this;        
        $translate(["COMMON.DEACTIVATE_USER", "COMMON.WOULD_DEACTIVATE_USER", "COMMON.USER_DEACTIVATED", "COMMON.USER_ACTIVATED", "COMMON.CANT_DEACTIVATE"]).then(function(translations) {
            vm.labels = {
                DeactivateUser: translations['COMMON.DEACTIVATE_USER'],
                DeactivatePUserQues: translations['COMMON.WOULD_DEACTIVATE_USER'],
                UserDeactivated: translations['COMMON.USER_DEACTIVATED'],
                UserActivated: translations['COMMON.USER_ACTIVATED'],
                CantDeactivate: translations['COMMON.CANT_DEACTIVATE'],
            }
        });

        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.user = null;
        vm.Users = [];
        vm.load = load;
        vm.getAllUsers = getAllUsers;
        vm.changeActiveStatus = changeActiveStatus;
        vm.openUpdateModal = openUpdateModal;
        vm.openAddModal = openAddModal;
        vm.pageNumber = 1;
        vm.pageSize = 10;
        vm.TotalCounts = 0;
        vm.Params = $stateParams;
        vm.currentEntityID = null;
        vm.Modal = null;
        vm.userFilter = userFilter;
        vm.EntityName = null;
        vm.load();

        function load() {
            
            vm.Loading();
            
            if (vm.Params.entityID === null || vm.Params.entityID === undefined)
                vm.currentEntityID = $rootScope.GetCurrentUser().CurrentEntity;
            else
                vm.currentEntityID = vm.Params.entityID
            vm.getAllUsers(vm.currentEntityID);
        }

        function getAllUsers(currentEntityID) {
            
            userServiceApi.GetAll(currentEntityID).then(getAllUsersSuccess, $rootScope.methodPublicError );
        }
        function changeActiveStatus(user,ev) {
            // Appending dialog to document.body to cover sidenav in docs app
            
            vm.Loading();
            var userID = user.ID;
            var deleteState = user.IsDeleted;
            var currentEntityID = vm.currentEntityID;
            var labels = vm.labels;
            if (deleteState === false) { //incase of deactivate show confirmation message 
                vm.Loading();
                if (document.getElementsByClassName("modal").length === 0)
                vm.Modal  = $uibModal.open({
              //  backdrop: "static",
                keyboard: true,
                    size: 'md modal-dialog-centered',
                animation: true,
                //backdropClick: true,
                templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
                    controller: function ($uibModalInstance) {
                        
                    var vm = this;
                    vm.header = labels.DeactivateUser;
                    vm.message = labels.DeactivatePUserQues;
                    vm.ok = function () {
                        $uibModalInstance.close({ Close: false, Ok: true });
                    };
                    vm.cancel = function () {
                        $uibModalInstance.close({ Close: true, Ok: false });
                    };
                },
                controllerAs: 'vm'
            });
                vm.Modal.result.then(function (result) {
                if (result.Ok) {
                    vm.Loading();
                userServiceApi.ChangeActiveStatus(userID, deleteState, currentEntityID)
                   .then(ChangeActiveStatusSuccess, $rootScope.methodPublicError);
                }
            }, function (error) {
                //backdrop clicked or error
            });
            }
            else {
                userServiceApi.ChangeActiveStatus(userID, deleteState, currentEntityID)
                    .then(ChangeActiveStatusSuccess, $rootScope.methodPublicError);
            }
        }
        function openUpdateModal(user) {
            if (document.getElementsByClassName("modal").length === 0)
            vm.Modal = $uibModal.open({
              //  backdrop: "static",
                keyboard: true,
                size: 'lg',
                animation: true,
               //backdropClick: true,
                templateUrl: 'ng/Modules/Common/Users/Views/update-user.html',
                controller: 'updateUserController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () {
                        return { user:user, entityID: vm.currentEntityID };
                    }
                }
            });
            vm.Modal.result.then(function (result) {
                if (result.Ok)
                    vm.load();
            }, function (error) {
                //backdrop clicked or error
                });
        }
        function openAddModal() {
            if (document.getElementsByClassName("modal").length === 0)
            vm.Modal = $uibModal.open({
              //  backdrop: "static",
                keyboard: true,
                size: 'lg',
                animation: true,
               //backdropClick: true,
                templateUrl: 'ng/Modules/Common/Users/Views/add-user.html',
                controller: 'addUserController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () {
                        return { entityID: vm.currentEntityID} }
                }
            });
            vm.Modal.result.then(function (result) {
                if (result.Ok) {
                    vm.Loading();
                    vm.load();
                }
            }, function (error) {
                //backdrop clicked or error
            });
        }

        function userFilter(item) {
            if (vm.search) {
                return item.FirstName.toLowerCase().includes(vm.search.toLowerCase()) || item.LastName.toLowerCase().includes(vm.search.toLowerCase()) || item.Email.toLowerCase().includes(vm.search.toLowerCase()) || item.role.toLowerCase().includes(vm.search.toLowerCase());
            }
            else
                return true;
        }
        function getAllUsersSuccess(res) {
            
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.Users = res.responseObject;
                vm.UsersVM = vm.Users;
                if (res.entityCard)
                    vm.EntityName = res.entityCard.title;
                roleDataService.GetRolesLookupData().then(GetRolesLookupDataSuccess, $rootScope.methodPublicError);
                //vm.TotalCounts = res.responseObject.TotalCounts;
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function GetRolesLookupDataSuccess(res) {
            if (res.responseCode == vm.ResponseCode.Success && res.responseObject !== null) {
                vm.roles = res.responseObject;
                for (var i = 0; i < vm.Users.length; i++) {
                    for (var j = 0; j < vm.roles.length; j++) {
                        if (vm.Users[i].RoleId == vm.roles[j].ID)
                            vm.Users[i].role = vm.roles[j].Name;
                    } 
                }
                vm.Loading();
            }
        }
        function ChangeActiveStatusSuccess(res) {
            if (res.responseCode == vm.ResponseCode.Activated) {
                Notification.success({ message: vm.labels.UserActivated });

            } else if (res.responseCode == vm.ResponseCode.DeActivated) {
                Notification.success({ message: vm.labels.UserDeactivated });
            }
            else if (res.responseCode == vm.ResponseCode.UserEntityRoleDeactivated)
            {
                Notification.success({ message: vm.labels.UserDeactivated });
            }
            else if (res.responseCode == vm.ResponseCode.CantDeActivateExistTasks) {
                Notification.warning({ message: vm.labels.CantDeactivate });
            }
            vm.Loading();
            vm.load();
        }

        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal) {
                vm.Modal.dismiss();
            }
        });


    }
}