import { remove as _remove, map as _map, cloneDeep as _cloneDeep, find as _find } from 'lodash';

export default function (app) {
    app.controller("annualAchievementController", ['$scope', '$stateParams', '$state', 'sectionStatusConstant', 'annualAchievementService', 'uiLoadingIndicators', '$rootScope', 'usersRolesConstant', 'errorNotificationService', '$uibModal', '$timeout', 'routeConstants', '$transitions', 'sectionsService', '$translate', 'actionsTypesConstant', 'successrNotificationService', '$filter',
        function ($scope, $stateParams, $state, sectionStatusConstant, annualAchievementService, uiLoadingIndicators, $rootScope, usersRolesConstant, errorNotificationService, $uibModal, $timeout, routeConstants, $transitions, sectionsService, $translate, actionsTypesConstant,successrNotificationService,$filter) {
			var vm = this;

			vm.labels = {
				ASSIGN_USER: $translate.instant('ACHIEVEMENT.ASSIGN_USER'),
                No_ACTIVE_CYCLE: $translate.instant('ACHIEVEMENT.NO_ACTIVE_CYCLE'),
                SUBMIT_ACHIEVEMENTS: $translate.instant('ACHIEVEMENT.SUBMIT_ACHIEVEMENTS'),
                CONFIRM_SUBMIT_ACHIEVEMENTS: $translate.instant('ACHIEVEMENT.CONFIRM_SUBMIT_ACHIEVEMENTS')
			}

            var _sectionsAssignmentModalInstance = null;
            var _selectSectionPopup = null;
            var sectionDelegationPopup = null;
            var _confirmSubmitAchievementsModalInstance = null;
            vm.annualAchievementCycleTitle = "";
            vm.logo = "";
            vm.isCycleEnabled = false;
            vm.IsExistAssigneesUses = false;
            vm.sections = [];
            vm.sectionStatus = sectionStatusConstant;
            vm.selectedSections = [];
            vm.assignees = [];
            vm.showAssignActivityModalSpinner = false;
            vm.isActionBtnLoading = false;
            vm.cycles = [];
            vm.cycleIndex = 0;
            vm.notification = false;
            vm.cycleID = $state.params.cycleID;
            vm.cycleActive = false;
            //TODO unregister event 
           var _onSuccessEvent = $transitions.onSuccess({ to: routeConstants.AnnualAchievementState.State }, function (transition) {
                //by default route to first section. on page refresh this callback doesn't fire so we duplicate the same logic in _getEntityAnnualAchievement
                
                if (vm.sections && vm.sections.length > 0) {
                    var section = vm.sections[0];
                    if ($state.params.sectionID) {
                        sectionName = $state.params.sectionID ? $state.params.sectionID : null;
                        section = _find(vm.sections, (section) => {
                            return section.id == sectionName;
                        });
                        if (!section) section = vm.sections[0];
                    }
                    _navigateToSection(section, vm.isCycleEnabled, false);                
                }                
            });
            
            var _getEntityAnnualAchievement = function (ignoreActivity) {
                
                uiLoadingIndicators.load('annualAchievement', true);
                annualAchievementService.getEntityAnnualAchievement(vm.cycleID).then(function (response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        vm.annualAchievementCycleTitle = _data.cycleTitle;
                        vm.logo = _data.logo;
                        vm.isCycleEnabled = _data.isCycleEnabled;
                        vm.sections = _data.sections;
                        vm.assignees = _data.users;
                        if (vm.assignees && vm.assignees.length > 0)
                            vm.IsExistAssigneesUses = true;
                        else
                            vm.IsExistAssigneesUses = false;
                        
                        vm.ShowMBRGIStatus = _data.ShowMBRGIStatus;
                        //by default route to first section
                        if (vm.sections && vm.sections.length > 0) {
                            
                            var sectionName = null;
                            var section = vm.sections[0];
                            if ($state.params.sectionID) {
                                sectionName = $state.params.sectionID ? $state.params.sectionID : null;
                                section = _find(vm.sections, (section) => {
                                    return section.id == sectionName;
                                });
                                if (!section) section = vm.sections[0];
                            }
                            sectionsService.setSections(vm.sections);
                            _navigateToSection(section, vm.isCycleEnabled, ignoreActivity);
                        }
                    }
                    else if (response && response.responseCode == 17) {
                        
                        $state.go(routeConstants.EntityDashboardState.State);
                        $rootScope.ShowError(vm.labels.No_ACTIVE_CYCLE);
                    }
                    else {
                        //handle business errors
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('annualAchievement', false);

                }, function (error) {
                    uiLoadingIndicators.load('annualAchievement', false);
                    //            //normal error
                    errorNotificationService.showErrorNotification(error);
                });
            }
            
            $scope.openSectionsAssignmentModel = function (selectedSections, assignees) {
                if (selectedSections && selectedSections.length > 0 && assignees && assignees.length > 0) {
                    if (document.getElementsByClassName("modal").length === 0)
                     _sectionsAssignmentModalInstance = $uibModal.open({
                        backdrop: true,
                        keyboard: true,
                        backdropClick: true,
                        template: require('../Views/assignActivityModal.html'),
                        size: 'lg',
                        controller: function ($uibModalInstance) {
                            var vm = this;
                            vm.selectedSections = selectedSections;
                            vm.assignees = assignees;
                            vm.selectedAssignee = null;
                            vm.model = {};
                            vm.showAssignActivityModalSpinner = false;

                            vm.assignmentFormFields = [
                                {
                                    type: 'select',
                                    key: 'selectedAssignee',
                                    templateOptions: {
                                        label: vm.labels.ASSIGN_USER,
                                        required: true,
                                        notNull: true,
                                        options: _map(vm.assignees, (assignee) => {
                                            return { name: assignee.name, value: assignee }
                                        })
                                    }
                                }
                            ];

                            vm.ok = function (selectedSections, selectedAssignee) {
                                //TODO show loading on modal and call api and show error msg
                                if (selectedSections.length > 0 && selectedAssignee) {
                                    vm.showAssignActivityModalSpinner = true;
                                    var _selectedSectionsIDs = _map(selectedSections, (section) => {
                                        return section.id
                                    });

                                    annualAchievementService.assignSections(selectedAssignee.id, _selectedSectionsIDs).then(function(response) {
                                        if (response && response.responseCode == 1) {
                                            vm.showAssignActivityModalSpinner = false;
                                            $uibModalInstance.close();
                                        } else {
                                            //TODO show error msg
                                            vm.showAssignActivityModalSpinner = false;
                                            errorNotificationService.showErrorNotification(response.responseCode);
                                        }
                                    }, function(error) {
                                        //TODO show error msg
                                        vm.showAssignActivityModalSpinner = false;
                                        errorNotificationService.showErrorNotification(error);
                                    })
                                }
                            };
                            vm.cancel = function () {
                                vm.showAssignActivityModalSpinner = false;
                                $uibModalInstance.close();
                            };
                        },
                        controllerAs: 'vm'
                    });
                }
            }

            $scope.sectionSelectionChanged = function (state, section) {
                
                if (state) {
                    vm.selectedSections.push(section);
                } else {
                    _remove(vm.selectedSections, (_section) => {
                        return _section.id == section.id;
                    })
                }
            }

            var _navigateToSection = function (section, isCycleEnabled, ignoreActivity) {
               
                //if current state is the root we navigate to a nested state
                //TODO refactor this state to be abstract state
                if (section && $state.$current.name.includes(routeConstants.AnnualAchievementState.State)) {
                    if (section.isSingle || (!ignoreActivity && $state.params.activityID)) {
                        var activityID = (!ignoreActivity && $state.params.activityID) ? $state.params.activityID : null;
                        $state.go(routeConstants.SectionSubmissionState.State,
                        //    { sectionID: section.id, activityID: null, isCycleEnabled: isCycleEnabled, cycleID: vm.cycleID }
                            { cycleID: vm.cycleID, sectionID: section.id, activityID: activityID, isCycleEnabled: isCycleEnabled, cycleActive: vm.cycleActive }
                        )
                    } else {
                        $state.go(routeConstants.SectionSubmissionsState.State, { cycleID: vm.cycleID, sectionID: section.id, isCycleEnabled: isCycleEnabled, cycleActive: vm.cycleActive })
                    }
                }
            }

            vm.getMBRGIStatusLabel = function (status) {
                if (status == vm.sectionStatus.NEW) {
                    return $translate.instant('COMMON.NEW');
                }
                else if (status == vm.sectionStatus.IN_PROGRESS) {
                    return $translate.instant('COMMON.INPROGRESS')
                }
                else if (status == vm.sectionStatus.SUBMITTED) {
                    return $translate.instant('COMMON.COMPLETED')
                }
            }

            vm.ExportBtnClick = function () {
                var sections = vm.sections;
                _selectSectionPopup = $uibModal.open({
                    keyboard: true,
                    size: 'md modal-dialog-centered',
                    animation: true,
                    template: require('../../Common/Views/export-sections.html'),
                    controller: function ($scope,$uibModalInstance) {
                        var vm = this;
                        vm.isAllSelected = false;
                        vm.sections = _cloneDeep(sections);
                        vm.ModalSpinner = false;
                        angular.forEach(vm.sections, function (item) { item.checked = false; });
                        vm.ExportSelectedSection = [];
                        vm.getFileName = function (s) {
                            var index = s.indexOf("=");
                            index = index + 1;
                            var filename = s.substring(index, s.length);
                            filename = filename.replace(/"/g, '');
                            return filename;
                        }
                        vm.ok = function () {
                            vm.ModalSpinner = true;
                            annualAchievementService.exportEntityAnnualAchievement(vm.ExportSelectedSection, $state.params.cycleID).then(function(response) {
                                if (response) {
                                    var blob = new Blob([response.data]);
                                    var link = document.createElement('a');
                                    link.href = window.URL.createObjectURL(blob);
                                    var fileName = vm.getFileName(response.headers('content-disposition'));
                                    link.download = fileName;
                                    link.click();
                                }
                                else {
                                    errorNotificationService.showErrorNotification(response.responseCode);
                                }
                                vm.ModalSpinner = false;
                                $uibModalInstance.close();
                            },function(error)  {
                                vm.ModalSpinner = false;
                                //normal error
                                errorNotificationService.showErrorNotification(error);
                            })
                        };
                        vm.cancel = function () {
                            vm.showAssignActivityModalSpinner = false;
                            $uibModalInstance.close();
                        };
                        vm.ExportCheckBtnClick = function (sectionName) {
                            var a = vm.ExportSelectedSection.indexOf(sectionName);
                            if (a == -1) {
                                vm.ExportSelectedSection.push(sectionName);
                                angular.forEach(vm.sections, function (item) { if (item.id == sectionName) item.checked = true; });
                                if (vm.ExportSelectedSection.length == vm.sections.length) {
                                    vm.isAllSelected = true;
                                }
                      
                            }
                            else {
                                vm.ExportSelectedSection.splice(a, 1);
                                angular.forEach(vm.sections, function (item) { if (item.id == sectionName) item.checked = false; });
                                if (vm.ExportSelectedSection.length < vm.sections.length) {
                                    vm.isAllSelected = false;
                                }
                            }
                        }
                        vm.toggleAll = function () {
                            vm.isAllSelected = !vm.isAllSelected;
                            if (vm.isAllSelected === true) {
                                vm.ExportSelectedSection = [];
                                angular.forEach(vm.sections, function (item) { vm.ExportSelectedSection.push(item.id); });
                                angular.forEach(vm.sections, function (item) { item.checked = true; });
                            }
                            else {
                                vm.ExportSelectedSection = [];
                                angular.forEach(vm.sections, function (item) { item.checked = false; });
                            }
                        }
                    },
                    controllerAs: 'vm'
                });

              
            }

            vm.OpenDelegationPopUp = function () {
                var cycleId = vm.cycleID;
                sectionDelegationPopup = $uibModal.open({
                    keyboard: true,
                    size: 'lg modal-dialog-centered',
                    animation: true,
                    template: require('../../Common/Views/delegate-sections.html'),
                    controller: function ($scope, $rootScope, $uibModalInstance, annualAchievementService) {                       
                        var vm = this;
                        vm.Loading = $rootScope.Loading;
                        vm.users = [];
                        vm.sections = [];
                        vm.ModalSpinner = false;
                        var _init = function () {
                            vm.Loading();
                           
                            annualAchievementService.getDelegationForm(cycleId).then(getDelegationFormSuccess, $rootScope.methodPublicError);
                        }();

                        function getDelegationFormSuccess (response) {
                            vm.Loading();
                            if (response && response.responseCode == 1 && response.responseObject) {
                                var _data = response.responseObject;
                                vm.users = _data.Users;
                                vm.sections = _data.Sections;
                                angular.forEach(vm.sections, function (item) { item.UserID ? item.checked = true : item.checked = false; });
                            }
                        }
                        vm.checkSection = function (index) {
                            var section = vm.sections[index];
                            section.checked = !section.checked;
                            if (!section.checked) {
                                section.UserID = null;
                            }
                        }
                        vm.ok = function () {
                            vm.ModalSpinner = true;
                            var delgatedSections = [];
                            angular.forEach(vm.sections, function (item) {
                                if (item.UserID) {
                                    delgatedSections.push({
                                        key: item.SectionKey,
                                        value: item.UserID
                                    });
                                }
                            });
                            annualAchievementService.saveDelegation(delgatedSections).then(function (response) {
                                if (response && response.responseCode == 1) {
                                    $state.go("Entity.AnnualAchievement", undefined, { reload: true });

                                }
                                else {
                                    errorNotificationService.showErrorNotification(response.responseCode);
                                }
                                vm.ModalSpinner = false;
                                $uibModalInstance.close();
                            }, function (error) {
                                vm.ModalSpinner = false;
                                //normal error
                                errorNotificationService.showErrorNotification(error);
                            });
                        };
                        vm.cancel = function () {
                            vm.showAssignActivityModalSpinner = false;
                            $uibModalInstance.close();
                        };
                    },
                    controllerAs: 'vm'
                });


            }

            vm.getFileName = function (s) {
                var index = s.indexOf("=");
                index = index + 1;
                var filename = s.substring(index, s.length);
                filename = filename.replace(/"/g, '');
                return filename;
            }

            var _init = function () {
               //$rootScope.cycleID = null;
                annualAchievementService.getCyclesList($rootScope.GetCurrentEntityID()).then(getCyclesListSuccess, $rootScope.methodPublicError);
            }()

            vm.getIsCyleEnabled = function () {
                return vm.isCycleEnabled;
            }        
            
            vm.submitAnnualAchievementAction = function () {
               
                var _labels = vm.labels;     
                if (document.getElementsByClassName("modal").length === 0)
                _confirmSubmitAchievementsModalInstance = $uibModal.open({
                    backdrop: true,
                    keyboard: true,
                    backdropClick: true,
                    template: require('../../Common/Views/confirmation-popup.html'),
                    size: 'lg',
                    controllerAs: 'vm',
                    controller: ['$uibModalInstance', function ($uibModalInstance) {
                        var vm = this;
                        vm.message = _labels.CONFIRM_SUBMIT_ACHIEVEMENTS;
                        vm.header = _labels.SUBMIT_ACHIEVEMENTS;
                        vm.isConfirmationAction = true;

                        vm.ok = function () {
                            $uibModalInstance.close();
                        }

                        vm.cancel = function () {
                            $uibModalInstance.dismiss();
                        }
                    }]
                })

                _confirmSubmitAchievementsModalInstance.result.then(function() {
                    //TODO check prams
                    vm.isActionBtnLoading = true;
                    annualAchievementService.submitAnnualAchievementAction($rootScope.GetCurrentEntityID(), actionsTypesConstant.SUBMIT, '').then(function(response)  {
                        if (response && response.responseCode == 1) {
                            //TODO: Display success
                            successrNotificationService.showSuccessSubmittingNotfication();
                            $state.go(routeConstants.EntityDashboardState.State);
                        }
                        else {
                            errorNotificationService.showErrorNotification(response.responseCode);
                        }
                        vm.isActionBtnLoading = false;
                    },function(error) {
                        //TODO: handle error
                        errorNotificationService.showErrorNotification(error);
                        vm.isActionBtnLoading = false;

                    })

                },function(error) {
                    vm.isActionBtnLoading = false;
                    })

            }
            
            $scope.$on('$destroy', function () {
                
                if (_sectionsAssignmentModalInstance && _sectionsAssignmentModalInstance.close) {
                    _sectionsAssignmentModalInstance.close();
                }
                if (_selectSectionPopup && _selectSectionPopup.close) {
                    _selectSectionPopup.close();
                }
                if (sectionDelegationPopup && sectionDelegationPopup.close) {
                    sectionDelegationPopup.close();
                }
                if (_confirmSubmitAchievementsModalInstance && _confirmSubmitAchievementsModalInstance.close) {
                    _confirmSubmitAchievementsModalInstance.close();
                }
                if (_onSuccessEvent){
                    _onSuccessEvent();
                }
                sectionsService.unsetSections();
              
            });
            
            function getCyclesListSuccess(response) {
                
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data.length > 0) {
                        _data = $filter('orderBy')(_data, 'ID');
                        for (var i = 0; i < _data.length; i++) {
                            var cycle = _data[i];
                            if ($rootScope.currentLang == 'en') {
                                cycle.name = cycle.NameEn;
                            }
                            else {
                                cycle.name = cycle.NameAr;
                            }
                            if (cycle.ActiveCycle) {
                                vm.cycleID = cycle.ID;
                                //$rootScope.cycleID = vm.cycleID;
                                vm.cycleIndex = i;
                                vm.cycleActive = true;
                            }
                            vm.cycles.push(cycle);
                        }
                        if (vm.cycleID == 0 || vm.cycleID == null || $state.params.cycleID == null || vm.cycles.findIndex(x => x.ID == $state.params.cycleID) == -1) {
                            // vm.cycles = $filter('orderBy')(vm.cycles, 'CreationDate', 'reverse');
                            vm.cycleID = vm.cycles[vm.cycles.length - 1].ID;
                            //$rootScope.cycleID = vm.cycleID;
                            vm.cycleIndex = vm.cycles.length - 1;
                            vm.cycleActive = vm.cycles[vm.cycleIndex].ActiveCycle;
                        }
                        else if ($state.params.cycleID != null)
                        {
                            vm.cycleID = $state.params.cycleID;
                            vm.cycleIndex = vm.cycles.findIndex(x => x.ID == $state.params.cycleID);
                            vm.cycleActive = vm.cycles[vm.cycleIndex].ActiveCycle;
                        }
                        _getEntityAnnualAchievement(false);
                    }
                    else {
                        $state.go(routeConstants.EntityDashboardState.State);
                        $rootScope.ShowError(vm.labels.No_ACTIVE_CYCLE);
                    }
                }

            }

            vm.getAnnualAchievement = function (index) {
                uiLoadingIndicators.load('annualAchievement', true);
                vm.cycleID = vm.cycles[index].ID;
                //$rootScope.cycleID = vm.cycleID;
                vm.cycleIndex = index;
                vm.cycleActive = vm.cycles[index].ActiveCycle;
                uiLoadingIndicators.load('annualAchievement', false);
                _getEntityAnnualAchievement(true);
            }
        }])
}