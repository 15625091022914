export default {
	ADD_LOOKUP: 'Add new lookup',
	LOOKUP_ENGLISH_NAME: 'Lookup name (English)',
	LOOKUP_ARABIC_NAME: 'Lookup name (Arabic)',
	SYSTEM_KEY: 'System key',
	UPDATE_LOOKUP: 'Update lookup',
	REQUIRED_LOOKUP_ARABIC_NAME: 'Lookup name (Arabic) is required.',
	REQUIRED_LOOKUP_ENGLISH_NAME: 'Lookup name (English) is required.',
	LOOKUP_UPDATED: 'Lookup has been updated successfully',
	SYSTEMKEY_EXIST: 'System key is previously used',
	LOOKUP_ADDED: 'Lookup added successfully',
	REQUIRED_LOOKUP_SYSTEMKEY: 'Lookups system key is required.',
    LOOKUP_TYPE: 'Lookup type',
    LOOKUP_DATA:'Lookup data',
	PREDEFINED: 'System defined',
	USER_DEFINED: 'User defined',
	ADD_ENTRY: 'Add new entry',
	ENTRY_VALUE_ENGLISH: 'Value (English)',
	ENTRY_VALUE_ARABIC: 'Value (Arabic)',
   
	ENTRY_DELETED: 'Entry has been deleted successfully',
	ENTRY_DELETE_QUES: 'Are you sure you want to delete this entry?',
    DELETE_HEADER: 'Delete',
	ENTRY_RESTORED: 'Entry had been successfully restored',

	ADD_LOOKUP_ENTRY: 'Add new entry',
	LOOKUP_ENTRY_ENGLISH_VALUE: 'Entry name (English)',
	LOOKUP_ENTRY_ARABIC_VALUE: 'Entry name (Arabic)',
	REQUIRED_LOOKUP_ENTRY_ARABIC_VALUE: 'Entry name (Arabic) is required.',
	REQUIRED_LOOKUP_ENTRY_ENGLISH_VALUE: 'Entry name (English) is required.',
	LOOKUP_ENTRY_ADDED: 'Entry added successfully',
	LOOKUP_ENTRY_UPDATED: 'Entry has been updated successfully',
	UPDATE_LOOKUP_ENTRY: 'Update entry'

}

