export default function (app) {
    app.controller('restoreBeneficiaryunitController', RestoreBeneficiaryunitController);

    RestoreBeneficiaryunitController.$inject = ["$scope", "$rootScope", "beneficiaryunitDataService", "$uibModal", "Notification", "$translate","$state"];
    function RestoreBeneficiaryunitController($scope, $rootScope, beneficiaryunitDataService, $uibModal, Notification, $translate,$state) {
        var vm = this;

        $translate(["BENEFICIARYUNIT.BENEFICIARY_UNIT_RESTORED"]).then(function (translations) {
            vm.labels = {
                BENEFICIARY_UNIT_RESTORED: translations['BENEFICIARYUNIT.BENEFICIARY_UNIT_RESTORED'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.beneficiaryunit = {};
        vm.Beneficiaryunits = [];
        vm.load = load;
        vm.getDeletedBeneficiaryunits = getDeletedBeneficiaryunits;
        vm.Modal = null;
        vm.beneficiaryunitFilter = beneficiaryunitFilter;
        vm.currentCycle = null;
        vm.load();

        function load() {

            vm.getDeletedBeneficiaryunits();
        }

        function getDeletedBeneficiaryunits() {
            vm.Loading();
            beneficiaryunitDataService.getDeletedBeneficiaryunits().then(getDeletedBeneficiaryunitsSuccess, $rootScope.methodPublicError);
        }

        function beneficiaryunitFilter(item) {
            if (vm.search) {
                return item.NameEn.toLowerCase().includes(vm.search.toLowerCase()) || item.NameAr.toLowerCase().includes(vm.search.toLowerCase()) || item.TypeName.toLowerCase().includes(vm.search.toLowerCase());
            }
            else
                return true;
        }

        function getDeletedBeneficiaryunitsSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.Beneficiaryunits = res.responseObject;
                vm.currentCycle = res.currentCycle;
                if (vm.Beneficiaryunits == null || vm.Beneficiaryunits == 0) {
                    vm.back();
                }
                beneficiaryunitDataService.GetAllBeneficiaryUnitsTypes().then(getBeneficiaryunitsTypesSuccess, $rootScope.methodPublicError);
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function getBeneficiaryunitsTypesSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.BeneficiaryunitTypes = res.responseObject;
                for (var i = 0; i < vm.Beneficiaryunits.length; i++) {
                    vm.Beneficiaryunits[i].TypeName = getBeneficiaryUnitTypeName(vm.Beneficiaryunits[i].Type);
                }
                vm.Loading();
            }

            else {
                $rootScope.methodPublicError();
            }
        }
        function getBeneficiaryUnitTypeName(ID) {
            for (var i = 0; i < vm.BeneficiaryunitTypes.length; i++) {
                if (vm.BeneficiaryunitTypes[i].ID == ID) {
                    return vm.BeneficiaryunitTypes[i].Name;
                }
            }
        }
        vm.back = function () {
            $state.transitionTo('MBRGI.BeneficiaryUnit');
        }
        vm.restore = function (ID) {
            vm.Loading();
            beneficiaryunitDataService.restoreDeletedBeneficiaryunit(ID).then(restoreDeletedBeneficiaryunitSuccess, $rootScope.methodPublicError);
        }
        function restoreDeletedBeneficiaryunitSuccess(res) {
            if (res.responseCode == vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.BENEFICIARY_UNIT_RESTORED });

                vm.Loading();
                vm.load();
            }
        }
        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
        });
    }
}