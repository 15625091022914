import { remove as _remove, map as _map } from 'lodash';
export default function (app) {

    app.controller("sharedInitiativeController", ['$scope', 'sharedInitiativesService', 'uiLoadingIndicators', 'taskTypesConstant', 'sectionStatusConstant', 'errorNotificationService', '$stateParams', 'routeConstants', '$state', '$rootScope', '$uibModal', '$timeout', 'actionsTypesConstant', 'successrNotificationService', 'sectionsService', '$translate',
        function ($scope, sharedInitiativesService, uiLoadingIndicators, taskTypesConstant, sectionStatusConstant, errorNotificationService, $stateParams, routeConstants, $state, $rootScope, $uibModal, $timeout, actionsTypesConstant, successrNotificationService, sectionsService, $translate) {
            var vm = this;    
            vm.Loading = $rootScope.Loading;
            vm.form = {};
            vm.isShared = false;
            vm.notShared = false;
            var _viewHistoryModal = null;
            var _viewCommentModal = null;
            vm.loadingSaveButton = false;
            $translate(["SHAREDINITIATIVES.NEW", 'SHAREDINITIATIVES.EDITING', 'SHAREDINITIATIVES.REVIEWING', 'SHAREDINITIATIVES.REVIEWED', 'SHAREDINITIATIVES.SENT_BACK',
                'SHAREDINITIATIVES.CREATE', 'SHAREDINITIATIVES.REASSIGN', 'SHAREDINITIATIVES.REOPEN', 'SHAREDINITIATIVES.EDITOR', 'SHAREDINITIATIVES.REVIEWER',
                'SHAREDINITIATIVES.SAVE', 'SHAREDINITIATIVES.SUBMIT', 'SHAREDINITIATIVES.APPROVE', 'SHAREDINITIATIVES.SendBack']).then(function (translations) {
                    vm.labels = {
                        NEW: translations['SHAREDINITIATIVES.NEW'],
                        EDITING: translations['SHAREDINITIATIVES.EDITING'],
                        REVIEWING: translations['SHAREDINITIATIVES.REVIEWING'],
                        REVIEWED: translations['SHAREDINITIATIVES.REVIEWED'],
                        SENT_BACK: translations['SHAREDINITIATIVES.SENT_BACK'],
                        CREATE: translations['SHAREDINITIATIVES.CREATE'],
                        REASSIGN: translations['SHAREDINITIATIVES.REASSIGN'],
                        REOPEN: translations['SHAREDINITIATIVES.REOPEN'],
                        EDITOR: translations['SHAREDINITIATIVES.EDITOR'],
                        REVIEWER: translations['SHAREDINITIATIVES.REVIEWER'],
                        SAVE: translations['SHAREDINITIATIVES.SAVE'],
                        SUBMIT: translations['SHAREDINITIATIVES.SUBMIT'],
                        APPROVE: translations['SHAREDINITIATIVES.APPROVE'],
                        SendBack: translations['SHAREDINITIATIVES.SendBack'],
                    }
                });
            vm.sectionStatusConstant = sectionStatusConstant;
            vm.workflowInfo = {};
            vm.assignmentInfo = {};
            vm.options = {};
            vm.isSendButtunDisabled = true;
            vm.showSendComment = false;
            vm.isRtl = function () {
                return $translate.use() == 'ar' ? true : false;
            }

            var _getSectionActivityDetails = function (activityID) {
                uiLoadingIndicators.load('sharedIntiatives', true);
                sharedInitiativesService.getSectionActivityDetails(activityID).then(function(response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        vm.activityInfo = _data.activityInfo;
                        vm.form = _data.form;
                        vm.options.formState = _data.form.formState;
                        vm.actions = _data.actions;
                        vm.workflowInfo = _data.workflowInfo;
                        vm.assignmentInfo = _data.assignmentInfo;
                        vm.hasHistory = _data.hasHistory;
                        vm.hasComment = _data.hasComment;
                        vm.cycleEntryDataEnable = _data.cycleEntryDataEnable;
                        vm.showSendComment = true;
                        vm.isShared = true;
                        vm.notShared = false;
                    } else if (response && response.responseCode == 17) {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    else {
                        //TODO show error msg
                        vm.notShared = true;
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('sharedIntiatives', false);
                },function(error) {

                    uiLoadingIndicators.load('sharedIntiatives', false);
                    //TODO show error msg
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getStatName = function (statType) {
                if (statType == sectionStatusConstant.NEW) {
                    return vm.labels.NEW;
                } else if (statType == sectionStatusConstant.IN_PROGRESS) {
                    return vm.labels.EDITING;
                } else if (statType == sectionStatusConstant.SUBMITTED) {
                    return vm.labels.REVIEWING;
                }
                else if (statType == sectionStatusConstant.APPROVED) {
                    return vm.labels.REVIEWED;
                } else if (statType == sectionStatusConstant.SENT_BACK) {
                    return vm.labels.SENT_BACK;
                }
            }

            vm.getActivityHistory = function () {             
                var _activityID = $stateParams.activityID;
                    sharedInitiativesService.getActivityHistory(_activityID).then(function(response) {
                    var _labels = vm.labels;
                        if (response && response.responseCode == 1) {
                        if (document.getElementsByClassName("modal").length === 0)
                        _viewHistoryModal = $uibModal.open({
                            backdrop: true,
                            keyboard: true,
                            backdropClick: true,
                            template: require('../Views/historyModal.html'),
                            size: 'lg',
                            controllerAs: 'vm',
                            controller: ['$uibModalInstance', function ($uibModalInstance) {
                                var vm = this;
                                vm.actionsTypesConstant = actionsTypesConstant;
                                vm.taskTypesConstant = taskTypesConstant;
                                vm.history = response.responseObject;


                                vm.getActionName = function (actionID) {
                                    if (actionID == actionsTypesConstant.SAVE) {
                                        return _labels.SAVE;
                                    } else if (actionID == actionsTypesConstant.SUBMIT) {
                                        return _labels.SUBMIT;
                                    } else if (actionID == actionsTypesConstant.APPROVE) {
                                        return _labels.APPROVE;
                                    }
                                    else if (actionID == actionsTypesConstant.SENDBACK) {
                                        return _labels.SENDBACK;
                                    }
                                }

                                vm.cancel = function () {
                                    $uibModalInstance.dismiss();
                                }

                            }]
                        })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                },function(error) {
                    //TODO show error
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getCommentsList = function () {
                var _activityID = $stateParams.activityID;

                sharedInitiativesService.getCommentsList(_activityID).then(function(response) {
                    var _labels = vm.labels;
                    if (response && response.responseCode == 1) {    
                        if (document.getElementsByClassName("modal").length === 0)
                        _viewCommentModal = $uibModal.open({
                            backdrop: true,
                            keyboard: true,
                            backdropClick: true,
                            template: require('../Views/sharedInitiativeComments.html'),
                            size: 'lg',
                            controllerAs: 'vm',
                            controller: ['$uibModalInstance', function ($uibModalInstance) {
                                var vm = this;                                
                                vm.commentsList = response.responseObject;                              



                                vm.cancel = function () {
                                    $uibModalInstance.dismiss();
                                }

                            }]
                        })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                },function(error) {
                    //TODO show error
                    errorNotificationService.showErrorNotification(error);
                })
            }
            var _init = function () {
                vm.Loading();
                if ($stateParams.activityID) {
                    _getSectionActivityDetails($stateParams.activityID)
                }
                vm.Loading();
            }()
            vm.isSendButtunDisabled = true;   
            vm.comment = null;    
            vm.updateButtunStatus = function () {
                if (vm.comment == "" || vm.comment == null) {
                    vm.isSendButtunDisabled = true;
                }
                else {
                    vm.isSendButtunDisabled = false;
                }
            }
            vm.cancel = function () {
                $state.go(routeConstants.SharedInitiativesState.State);

            }
            vm.submitComment = function () {
                var _activityID = $stateParams.activityID;
                vm.loadingSaveButton = true;
                sharedInitiativesService.submitSharedActivityComment(_activityID, vm.comment).then(function (responseComment) {

                        if (responseComment && responseComment.responseCode == 1 && responseComment.responseObject) {
                            var _data = responseComment.responseObject;
                            if (_data != null) {                                
                                $state.go(routeConstants.SharedInitiativesState.State);

                            }
                            vm.sendingCommentLoading = false;
                            vm.sendComment = true;

                        } else {
                            //TODO show error msg
                            errorNotificationService.showErrorNotification(responseComment.responseCode);
                    }
                    vm.loadingSaveButton = false;

                    },function(error) {
                        //TODO show error
                        errorNotificationService.showErrorNotification(error);
                        vm.loadingSaveButton = false;

                    })               

            }
            $scope.$on('$destroy', function () {
                if (_viewHistoryModal && _viewHistoryModal.close) {
                    _viewHistoryModal.close();
                }
                if (_viewCommentModal && _viewCommentModal.close) {
                    _viewCommentModal.close();
                }
                
            });

        }])
}