//Import controllers
import requestController from './Controllers/app.request-controller';

//Import services
import requestDataService from './Services/request.data.service';
//Import config
import './Localization';

export default function (app) {
    requestController(app);
    requestDataService(app);

}