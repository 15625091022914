import { remove as _remove, map as _map, cloneDeep as _cloneDeep } from 'lodash';

export default function (app) {
    app.controller("sectionsAssignmentController", ['$scope', '$state', 'sectionStatusConstant', 'sectionsAssignmentService', 'uiLoadingIndicators', '$rootScope', 'usersRolesConstant', 'errorNotificationService', '$uibModal', '$timeout', 'routeConstants', '$transitions', 'sectionsService', '$translate', 'actionsTypesConstant', 'successrNotificationService','Notification',
        function ($scope, $state, sectionStatusConstant, sectionsAssignmentService, uiLoadingIndicators, $rootScope, usersRolesConstant, errorNotificationService, $uibModal, $timeout, routeConstants, $transitions, sectionsService, $translate, actionsTypesConstant, successrNotificationService, Notification) {
            var vm = this;

            vm.labels = {
                ASSIGN_USER: $translate.instant('ACHIEVEMENT.ASSIGN_USER'),
                No_ACTIVE_CYCLE: $translate.instant('ACHIEVEMENT.NO_ACTIVE_CYCLE'),
                SUBMIT_ACHIEVEMENTS: $translate.instant('ACHIEVEMENT.SUBMIT_ACHIEVEMENTS'),
                CONFIRM_SUBMIT_ACHIEVEMENTS: $translate.instant('ACHIEVEMENT.CONFIRM_SUBMIT_ACHIEVEMENTS'),
                NO_DATA: $translate.instant('COMMON.NO_DATA'),
            }

            var _sectionsAssignmentModalInstance = null;
            var _selectSectionPopup = null;
            var _confirmSubmitAchievementsModalInstance = null;
            vm.annualAchievementCycleTitle = "";
            vm.logo = "";
            vm.isCycleEnabled = false;
            vm.sections = [];
            vm.sectionStatus = sectionStatusConstant;
            vm.selectedSections = [];
            vm.assignees = [];
            vm.showAssignActivityModalSpinner = false;
            vm.isActionBtnLoading = false;

            //TODO unregister event 
            var _onSuccess = $transitions.onSuccess({ to: routeConstants.SectionsAssignmentState.State }, function (transition) {
                //by default route to first section. on page refresh this callback doesn't fire so we duplicate the same logic in _getEntityAnnualAchievement
                var enabledSection = null;
                for (var i = 0; i < vm.sections.length; i++) {
                    if (vm.sections[i].isDelegated === true) {
                        enabledSection = i;
                        break;
                    }
                }
                if ($state.params.sectionID) {
                    for (var i = 0; i < vm.sections.length; i++) {
                        if (vm.sections[i].id == $state.params.sectionID) {
                            enabledSection = i;
                            break;
                        }
                    }
                }
                if (vm.sections && vm.sections.length > 0 && enabledSection) {
                    sectionsService.setSections(vm.sections);
                    _navigateToSection(vm.sections[enabledSection], vm.isCycleEnabled);
                }
            });


            var _getEntityAnnualAchievement = function () {
                uiLoadingIndicators.load('annualAchievement', true);
                sectionsAssignmentService.getEntityAnnualAchievement().then(function (response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        vm.annualAchievementCycleTitle = _data.cycleTitle;
                        vm.logo = _data.logo;
                        vm.isCycleEnabled = _data.isCycleEnabled;
                        vm.sections = _data.sections;
                        vm.assignees = _data.users;
                        vm.ShowMBRGIStatus = _data.ShowMBRGIStatus;
                        //by default route to first section
                        var enabledSection = null;
                        for (var i = 0; i < vm.sections.length; i++) {
                            if (vm.sections[i].isDelegated === true) {
                                enabledSection = i;
                                break;
                            }
                        }
                        if ($state.params.sectionID) {
                            for (var i = 0; i < vm.sections.length; i++) {
                                if (vm.sections[i].id == $state.params.sectionID) {
                                    enabledSection = i;
                                    break;
                                }
                            }
                        }
                        if (vm.sections && vm.sections.length > 0 && enabledSection != null) {
                            sectionsService.setSections(vm.sections);
                            _navigateToSection(vm.sections[enabledSection], vm.isCycleEnabled);
                        }
                        else {
                            Notification.error({ message: vm.labels.NO_DATA });
                            $state.go(routeConstants.EntityDashboardState.State);
                        }
                    }
                    else if (response && response.responseCode == 17) {
                        $state.go(routeConstants.EntityDashboardState.State);
                        $rootScope.ShowError(vm.labels.No_ACTIVE_CYCLE);
                    }
                    else {
                        //handle business errors
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('annualAchievement', false);

                }, function (error) {
                    uiLoadingIndicators.load('annualAchievement', false);
                    //            //normal error
                    errorNotificationService.showErrorNotification(error);
                });
            }



            $scope.openSectionsAssignmentModel = function (selectedSections, assignees) {
                if (selectedSections && selectedSections.length > 0 && assignees && assignees.length > 0) {
                    if (document.getElementsByClassName("modal").length === 0)
                        _sectionsAssignmentModalInstance = $uibModal.open({
                            backdrop: true,
                            keyboard: true,
                            backdropClick: true,
                            template: require('../Views/assignActivityModal.html'),
                            size: 'lg',
                            controller: function ($uibModalInstance) {
                                var vm = this;
                                vm.selectedSections = selectedSections;
                                vm.assignees = assignees;
                                vm.selectedAssignee = null;
                                vm.model = {};
                                vm.showAssignActivityModalSpinner = false;

                                vm.assignmentFormFields = [
                                    {
                                        type: 'select',
                                        key: 'selectedAssignee',
                                        templateOptions: {
                                            label: vm.labels.ASSIGN_USER,
                                            required: true,
                                            notNull: true,
                                            options: _map(vm.assignees, (assignee) => {
                                                return { name: assignee.name, value: assignee }
                                            })
                                        }
                                    }
                                ];

                                vm.ok = function (selectedSections, selectedAssignee) {
                                    //TODO show loading on modal and call api and show error msg
                                    if (selectedSections.length > 0 && selectedAssignee) {
                                        vm.showAssignActivityModalSpinner = true;
                                        var _selectedSectionsIDs = _map(selectedSections, (section) => {
                                            return section.id
                                        });

                                        sectionsAssignmentService.assignSections(selectedAssignee.id, _selectedSectionsIDs).then(function (response) {
                                            if (response && response.responseCode == 1) {
                                                vm.showAssignActivityModalSpinner = false;
                                                $uibModalInstance.close();
                                            } else {
                                                //TODO show error msg
                                                vm.showAssignActivityModalSpinner = false;
                                                errorNotificationService.showErrorNotification(response.responseCode);
                                            }
                                        }, function (error) {
                                            //TODO show error msg
                                            vm.showAssignActivityModalSpinner = false;
                                            errorNotificationService.showErrorNotification(error);
                                        })
                                    }
                                };
                                vm.cancel = function () {
                                    vm.showAssignActivityModalSpinner = false;
                                    $uibModalInstance.close();
                                };
                            },
                            controllerAs: 'vm'
                        });
                }
            }

            $scope.sectionSelectionChanged = function (state, section) {
                if (state) {
                    vm.selectedSections.push(section);
                } else {
                    _remove(vm.selectedSections, (_section) => {
                        return _section.id == section.id;
                    })
                }
            }

            var _navigateToSection = function (section, isCycleEnabled) {
                //if current state is the root we navigate to a nested state
                //TODO refactor this state to be abstract state
                if (section &&( $state.$current.name == routeConstants.SectionsAssignmentState.State || $state.$current.name == routeConstants.DeletedSectionAssignmentSubmissionsState.State )) {
                    if (section.isSingle) {
                        $state.go(routeConstants.SectionAssignmentSubmissionState.State,
                            { sectionID: section.id, activityID: null, isCycleEnabled: isCycleEnabled }
                        )
                    } else {
                        $state.go(routeConstants.SectionAssignmentSubmissionsState.State, { sectionID: section.id, isCycleEnabled: isCycleEnabled })
                    }
                }
            }

            vm.getMBRGIStatusLabel = function (status) {
                if (status == vm.sectionStatus.NEW) {
                    return $translate.instant('COMMON.NEW');
                }
                else if (status == vm.sectionStatus.IN_PROGRESS) {
                    return $translate.instant('COMMON.INPROGRESS')
                }
                else if (status == vm.sectionStatus.SUBMITTED) {
                    return $translate.instant('COMMON.COMPLETED')
                }
            }
            vm.getFileName = function (s) {
                var index = s.indexOf("=");
                index = index + 1;
                var filename = s.substring(index, s.length);
                filename = filename.replace(/"/g, '');
                return filename;
            }
            var _init = function () {
                _getEntityAnnualAchievement();
            }()
            vm.getIsCyleEnabled = function () {
                return vm.isCycleEnabled;
            }


            vm.submitAnnualAchievementAction = function () {

                var _labels = vm.labels;
                if (document.getElementsByClassName("modal").length === 0)
                    _confirmSubmitAchievementsModalInstance = $uibModal.open({
                        backdrop: true,
                        keyboard: true,
                        backdropClick: true,
                        template: require('../../../Common/Views/confirmation-popup.html'),
                        size: 'lg',
                        controllerAs: 'vm',
                        controller: ['$uibModalInstance', function ($uibModalInstance) {
                            var vm = this;
                            vm.message = _labels.CONFIRM_SUBMIT_ACHIEVEMENTS;
                            vm.header = _labels.SUBMIT_ACHIEVEMENTS;
                            vm.isConfirmationAction = true;

                            vm.ok = function () {
                                $uibModalInstance.close();
                            }

                            vm.cancel = function () {
                                $uibModalInstance.dismiss();
                            }
                        }]
                    })

                _confirmSubmitAchievementsModalInstance.result.then(function () {
                    //TODO check prams
                    vm.isActionBtnLoading = true;
                    sectionsAssignmentService.submitAnnualAchievementAction($rootScope.GetCurrentEntityID(), actionsTypesConstant.SUBMIT, '').then(function (response) {
                        if (response && response.responseCode == 1) {
                            //TODO: Display success
                            successrNotificationService.showSuccessSubmittingNotfication();
                            $state.go(routeConstants.EntityDashboardState.State);
                        }
                        else {
                            errorNotificationService.showErrorNotification(response.responseCode);
                        }
                        vm.isActionBtnLoading = false;
                    }, function (error) {
                        //TODO: handle error
                        errorNotificationService.showErrorNotification(error);
                        vm.isActionBtnLoading = false;

                    })

                }, function (error) {
                    vm.isActionBtnLoading = false;
                })

            }


            $scope.$on('$destroy', function () {
                if (_sectionsAssignmentModalInstance && _sectionsAssignmentModalInstance.close) {
                    _sectionsAssignmentModalInstance.close();
                }
                if (_selectSectionPopup && _selectSectionPopup.close) {
                    _selectSectionPopup.close();
                }
                if (_confirmSubmitAchievementsModalInstance && _confirmSubmitAchievementsModalInstance.close) {
                    _confirmSubmitAchievementsModalInstance.close();
                }
                if (_onSuccess) {
                    _onSuccess();
                }

                sectionsService.unsetSections();
            });


        }])
}