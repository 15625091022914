

export default function (formlyBuilder) {
    formlyBuilder.factory('dataItemsService', function () {

        //represent data item for each builder field inside dropzoon
        var _currentBuilderFields = [];

        var _VisibleRequiredEntities = [];
        var _LookupsDTO = [];
        var _IsSectionsEditable = false;
        var setBuilderFields = function (builderFields) {
            _currentBuilderFields = builderFields;
        }

        var getBuilderFields = function () {
            return _currentBuilderFields;
        }
        var setVisibleRequiredEntities = function (VisibleRequiredToDTO) {
            _VisibleRequiredEntities = VisibleRequiredToDTO;
        }

        var getVisibleRequiredEntities= function () {
            return _VisibleRequiredEntities;
        }

        var setLookups = function (LookupsDTO) {
            _LookupsDTO = LookupsDTO;
        }

        var getLookups = function () {
            return _LookupsDTO;
        }


        var setSectionsEditable = function (IsSectionsEditable) {
            _IsSectionsEditable = IsSectionsEditable;
        }

        var getSectionsEditable = function () {
            return _IsSectionsEditable;
        }
        return {
            setBuilderFields: setBuilderFields,
            getBuilderFields: getBuilderFields,
            setVisibleRequiredEntities: setVisibleRequiredEntities,
            getVisibleRequiredEntities: getVisibleRequiredEntities,
            getLookups: getLookups,
            setLookups: setLookups,
            setSectionsEditable: setSectionsEditable,
            getSectionsEditable: getSectionsEditable

        };

    });
};