export default function (app) {
    app.factory('userProfileDataService', UserProfileDataService);

    UserProfileDataService.$inject = ['apiBase', 'ApiPath'];
    function UserProfileDataService(apiBase, ApiPath) {
        var entity = "UserProfile";
        var controllerUrl = ApiPath + entity + "/";

        function GetUserByID() {
            return apiBase.Get(controllerUrl + "GetUserByID", { }, null);
        }

        function UpdateUserProfile(data) {
            return apiBase.Post(controllerUrl + "UpdateUserProfile", null, data);
        }
        function ChangePassword(data) {
            return apiBase.Post(controllerUrl + "ChangePassword", null, data);
        }
        return {
            GetUserByID: GetUserByID,
            UpdateUserProfile: UpdateUserProfile,
            ChangePassword: ChangePassword
        }
    }
}