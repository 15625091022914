
//Import controllers
import notificationController from './Controllers/app.notification.controller';

//Import services
import homeNotificationService from '../../Home/Services/home.notification.service';

export default function (app) {
    notificationController(app);
    homeNotificationService(app);

}