export default function (app) {
    app.factory('loginDataService', LoginDataService);

    LoginDataService.$inject = ['apiBase', 'ApiPath'];
    function LoginDataService(apiBase, ApiPath) {
        var entity = "Authentication";
        var controllerUrl = ApiPath + entity + "/";


        function LoadCurrentUser() {
            return apiBase.Post(controllerUrl + "LoadCurrentUser", null, null);
        }

        function Logout() {
            return apiBase.Post(controllerUrl + "Logout", null, null);
        }
        function Login(data) {
            return apiBase.Post(controllerUrl + "Login", null, data);
        }
        function LoginToEntity(entityID, currentUserID) {
            return apiBase.Post(controllerUrl + "LoginToEntity", { entityID: entityID, currentUserID: currentUserID});
        }
        function SendForgetPasswordEmail(username) {
            return apiBase.Post(controllerUrl + "SendForgetPasswordEmail", { username: username });
        }
        function ValidateResetPassword(credential) {
            return apiBase.Post(controllerUrl + "ValidateResetPassword", { credential: credential });
        }
        function ResetPassword(data) {
            return apiBase.Post(controllerUrl + "ResetPassword", null, data);
        }

        return {
            Login: Login,
            LoginToEntity: LoginToEntity,
            SendForgetPasswordEmail: SendForgetPasswordEmail,
            ValidateResetPassword: ValidateResetPassword,
            ResetPassword: ResetPassword,
            LoadCurrentUser: LoadCurrentUser,
            Logout : Logout
        };
    }
}