export default function (app) {
    app.directive('uiLoadingIndicatorsDisplay', [function () {
        return {
            restrict: 'A',
            require: 'uiLoadingIndicators',
            link: function (scope, iElement, attrs, controller) {
                iElement.css('transition', 'all 0.3s ease');
                scope.$watch(function () { return controller.isLoading }, function (newVal, oldVal) {
                    if (newVal) {
                        iElement.css({
                            'opacity': 1,
                            'visibility': 'visible',
                            'height': 'auto'
                        });
                    } else {
                        iElement.css({
                            'opacity': 0,
                            'visibility': 'hidden',
                            'height': 0
                        });
                    }
                });

            }
        }
    }]);
}