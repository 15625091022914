export default function (app) {
    app.directive('partialLoading', function () {
        return {
            scope: {
                width: "@",
                height: "@",
                color: "@",
                left: "@",
                top: "@",
                pathColor: "@"
            },
            template: require('./../Views/partialLoading.html'),
        }
    })
}