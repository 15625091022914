export default function (app) {
    app.controller('addBeneficiaryunitController', AddBeneficiaryunitController);

    AddBeneficiaryunitController.$inject = ["$rootScope", "beneficiaryunitDataService", "$uibModalInstance", "Params", "Notification","$translate"];
    function AddBeneficiaryunitController($rootScope, beneficiaryunitDataService, $uibModalInstance, Params, Notification, $translate) {
        var vm = this;
        vm.Loading = $rootScope.Loading;
        $translate(["BENEFICIARYUNIT.BENEFICIARY_UNIT_EXIST","BENEFICIARYUNIT.BENEFICIARY_UNIT_ADDED"]).then(function (translations) {
            vm.labels = {
                BeneficiaryUnitIsAlreadyExist: translations['BENEFICIARYUNIT.BENEFICIARY_UNIT_EXIST'],
                BeneficiaryUnitAdded: translations['BENEFICIARYUNIT.BENEFICIARY_UNIT_ADDED'],
            }
        });
        vm.Loading();
        vm.beneficiaryunit = {};
        vm.responseCode = $rootScope.enums.ResponseCode;

        vm.load = load;
        vm.cancel = cancel;
        vm.Params = Params;
        vm.addBeneficiaryunit = addBeneficiaryunit;
        vm.BeneficiaryunitTypes = []

        loadStatuses();
        vm.load();

        function load() {
            vm.Loading();
        }

        function loadStatuses() {
            
            beneficiaryunitDataService.GetAllBeneficiaryUnitsTypes().then(getAllBeneficiaryunitsTypesSuccess, $rootScope.methodPublicError);
        }

        function getAllBeneficiaryunitsTypesSuccess(response) {
            if (response.responseCode === vm.responseCode.Success) {
                
                vm.BeneficiaryunitTypes = response.responseObject;  
            }

            else {
                $rootScope.methodPublicError();
            }
        }

        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false });
        }

        function addBeneficiaryunit(beneficiaryunit) {
            
            vm.Loading();
            //beneficiaryunitDataService.isBeneficiaryunitExistSuccess(beneficiaryunit).then(addBeneficiaryunitSuccess, addBeneficiaryunitError);
            beneficiaryunitDataService.IsBeneficiaryunitExist(beneficiaryunit).then(isBeneficiaryunitExistSuccess, $rootScope.methodPublicError);

        }

        function addBeneficiaryunitSuccess(response) {
            
            Notification.success({ message: vm.labels.BeneficiaryUnitAdded });
            $uibModalInstance.close({ Close: false, Ok: true });
            vm.Loading();
        }

        function isBeneficiaryunitExistSuccess(res) {
            
            if (res.responseCode == vm.responseCode.NotExist) {
                beneficiaryunitDataService.AddBeneficiaryunit(vm.beneficiaryunit).then(addBeneficiaryunitSuccess, $rootScope.methodPublicError);
            }
            else if (res.responseCode == vm.responseCode.Success) {
                Notification.warning({ message: vm.labels.BeneficiaryUnitIsAlreadyExist });
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }

        //function addBeneficiaryunitError(response) {
        //    Notification.error({ message: 'error occurred' });
        //    $uibModalInstance.close({ Close: true, Ok: false });
        //    vm.Loading();
        //}
    }
}