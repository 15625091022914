export default function (app) {
    app.directive('domWatcher', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attr, ctrls) {
                angular.getTestability(element).whenStable(function () {
                    $(window).trigger('rendered');
                });
            }
        }
    });
}
