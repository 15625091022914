export default {
    ADD_CYCLE: 'Add new cycle',
    UPDATE_CYCLE: 'Update cycle',
    CYCLE_NAME_EN: 'Cycle name (English)',
    CYCLE_NAME_AR: 'Cycle name (Arabic)',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    DATA_ENTRY_START_DATE: 'Data entry start date',
    DATA_ENTRY_END_DATE: 'Data entry end date',
    CUT_OFF_DATE: 'Cut- off date',
    CYCLES: 'Cycles',
    REQUIRED_NAME_EN: 'Cycle name (English) is required.',
    REQUIRED_NAME_AR: 'Cycle name (Arabic) is required.',

    REQUIRED_START_DATE: 'Start date is required.',
    REQUIRED_END_DATE: 'End date is required.',
    REQUIRED_DATA_ENTRY_START_DATE: 'Data entry start date is required.',
    REQUIRED_DATA_ENTRY_END_DATE: 'Data entry end date is required.',
    REQUIRED_CUT_OFF_DATE: 'Cut-off date is required.',
    CYCLE_START_DATE_VALIDATION: 'Start date must be before end date',
    CYCLE_END_DATE_VALIDATION: 'End date must be after start date.',
    CYCLE_DATA_ENTRY_START_DATE_VALIDATION: 'Data entry start date must be before Data entry end date.',
    CYCLE_DATA_ENTRY_START_END_VALIDATION: 'Data entry end date must be after Data entry start date.',
    CYCLE_CUT_OFF_DATE_VALIDATION: 'Cut-off date must be after cycle end date and data entry end date.',

    UPDATE: 'Update',

    CYCLE_EXIST: 'Cycle title is previously used',
    CYCLE_ADDED: 'Cycle Added Successfully',
    CYCLE_UPDATED: 'Cycle has been Updated Successfully',
    CYCLE_DELETED: 'Cycle has been Deleted Successfully',
    CYCLE_DELETE_QUES: 'Are you sure you want to delete the Cycle?',
    DELETE_HEADER: 'Delete',
    ENTITY_NAME: 'Entity',
}


