export default function (app) {
    app.controller('pillarController', PillarController);

    PillarController.$inject = ["$scope", "$rootScope", "pillarDataService", "$uibModal", "Notification","$translate"];
    function PillarController($scope, $rootScope, pillarDataService, $uibModal, Notification, $translate) {
        var vm = this;
        $translate(["PILLARS.PILLAR_DELETED", "PILLARS.PILLAR_DELETE_QUES", "PILLARS.DELETE_HEADER","COMMON.ACTIVE_CYCLE"]).then(function (translations) {
            vm.labels = {
                PillarDeleted: translations['PILLARS.PILLAR_DELETED'],
                DeletePillarQues: translations['PILLARS.PILLAR_DELETE_QUES'],
                DeleteHeader: translations['PILLARS.DELETE_HEADER'],
                ACTIVE_CYCLE: translations['COMMON.ACTIVE_CYCLE'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.pillar = {};
        vm.Pillars = [];
        vm.load = load;
        vm.getAllPillars = getAllPillars;
        vm.openUpdateModal = openUpdateModal;
        vm.openAddModal = openAddModal;
        vm.Modal = null;
        vm.pillarsFilter = pillarsFilter;
        vm.currentCycle = null;
        vm.load();

        function load() {
            vm.getAllPillars();
        }

        function getAllPillars() {
            vm.Loading();
            pillarDataService.GetAllPillars().then(getAllPillarsSuccess, $rootScope.methodPublicError);
        }
        function pillarsFilter(item) {
            if (vm.search) {
                return item.NameEn.toLowerCase().includes(vm.search.toLowerCase()) || item.NameAr.toLowerCase().includes(vm.search.toLowerCase());
            }
            else
                return true;
        }

        function openUpdateModal(pillar) {
            vm.Modal = $uibModal.open({
              //  backdrop: "static",
                keyboard: true,
                size: 'lg',
                animation: true,
               //backdropClick: true,
                templateUrl: 'ng/Modules/MBRGI/Pillars/Views/update-pillar.html',
                controller: 'updatePillarController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () { return pillar; }
                }
            });
            vm.Modal.result.then(function (result) {
                
                if (result.Ok)
                     vm.load();
            }, function (error) {
                //backdrop clicked or error
            });
        }
        function openAddModal() {
            vm.Modal = $uibModal.open({
              //  backdrop: "static",
                keyboard: true,
                size: 'lg',
                animation: true,
               //backdropClick: true,
                templateUrl: 'ng/Modules/MBRGI/Pillars/Views/add-pillar.html',
                controller: 'addPillarController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () { }
                }
            });
            vm.Modal.result.then(function (result) {
                if (result.Ok)
                    vm.load();
            }, function (error) {
                //backdrop clicked or error
            });
        }
        function getAllPillarsSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.Pillars = res.responseObject;
                vm.currentCycle = res.currentCycle;
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
        });
        ///The below method is for activation and deactivation (no need for it in this phase)
        //function ChangeActiveStatusSuccess(res) {
        //    if (res.responseCode == vm.ResponseCode.Activated) {
        //        Notification.success({ message: vm.labels.pillarActivated });

        //    } else if (res.responseCode == vm.ResponseCode.DeActivated) {
        //        Notification.success({ message: vm.labels.PillarDeactivated });
        //    }
        //    vm.Loading();
        //    vm.load();
        //}

        $scope.sortableOptions = {
            disabled: vm.IsDisableMode,
            placeholder: vm.IsDisableMode ? "" : "ui-state-highlight",
            stop: function (e, ui) {
                var IDs = [];
                vm.Pillars.forEach((x) => { IDs.push(x.ID); });
                vm.Loading();
                pillarDataService.UpdatePillarsOrder(IDs).then(function (response) {
                    vm.Loading();
                    if (response) {

                    } else {
                        $rootScope.methodPublicError();
                    }
                })
            }
        };

       

    }
}