export default function (app) {
    app.directive('formlyWizard', ['$compile', function ($compile) {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div class="wizard-container">' +
            '<div class="circles-container">' +
            '<div class="progress prgress-wizard"></div>' +
            '<div ng-repeat="step in fieldsScope track by $index" class="circle"  ng-class="{\'circle-active\':$index===currentStepIndex}" ng-click="moveToStep($index);">' +
            '</div>' +
            '</div>' +
            '<div style="clear: both;">{{fieldsScope[currentStepIndex].to.label}}</div>' +
            '<div ng-transclude></div>' + '<div class="buttons">' +
            '<button class="btn btn-custom-blue btn-wizard pull-right" ng-disabled="currentStepIndex<=0" ng-click="moveToStep(currentStepIndex-1)">prev</button>' +
            '<div class="steps-div">' +
            '<div class="step">No. of Steps:<span class="num-steps">{{stepsCount}}</span></div>' +
            '</div>' +
            '<button class="btn btn-custom-blue btn-wizard pull-left" ng-disabled="currentStepIndex>=stepsCount-1" ng-click="moveToStep(currentStepIndex+1)" >next</button>' +
            '</div>' + '</div>',
            link: function link(scope, element, attr, ctrls) {
                var fieldGroupNgForm = findFirstFieldGroupNgForm(element);  //get the root ng-form (fieldGroup)
                var fields = getFormlyFieldsIn(fieldGroupNgForm); // then getting the first level formly fields inside the field group, this could be fields or fieldGroups
                scope.stepsCount = fields.length;
                scope.currentStepIndex = 0;
                scope.fieldsScope = getFieldsScope(fields) || [];
                scope.currentStepIsValid = false;
                var unwatchPrevStepValidation = function () { };

                updateFieldsVisibility(fields, scope.currentStepIndex);

                //mutation doesn't work on IE browsers < 11
                var observer = new MutationObserver(function (mutations) {
                    fields = getFormlyFieldsIn(fieldGroupNgForm);
                    scope.stepsCount = fields.length;
                    scope.fieldsScope = getFieldsScope(fields);
                    scope.currentStepIsValid = false;
                    updateFieldsVisibility(fields, scope.currentStepIndex);
                    watchCurrentStepValidation();
                });

                //observe when the html content of the directive changes
                if (fieldGroupNgForm instanceof Element)
                    observer.observe(fieldGroupNgForm, {
                        childList: true
                    });

                scope.moveToStep = function (stepIndex) {
                    scope.currentStepIndex = stepIndex;
                    updateFieldsVisibility(fields, scope.currentStepIndex);
                    watchCurrentStepValidation();
                };
                // field scope gives us access to thing as templateOptions etc.
                function getFieldsScope(fields) {
                    var scopes = [];
                    angular.forEach(fields, function (field, index) {
                        scopes.push(angular.element(field).isolateScope()); //isolateScope is available in Angular starting from 1.2.x
                    });
                    return scopes;
                }

                function watchCurrentStepValidation() {
                    unwatchPrevStepValidation();
                    var currentStepNode = fields[scope.currentStepIndex];
                    var el = angular.element(currentStepNode);
                    if (nodeIsFieldGroup(currentStepNode)) // if currentStepNode is fieldGroup get watch all nested fields Validation
                    {
                        var fieldGroupScope = el.isolateScope(); // get fieldGroup scope that contains form validation
                        var ngModelNodes = findAllNgModelNodes(currentStepNode) || [];
                        var stepFieldsNames = [];
                        angular.forEach(ngModelNodes, function (ngModelNode) {
                            stepFieldsNames.push(angular.element(ngModelNode).attr('name'));
                        });
                        var stepFieldsPaths = stepFieldsNames.map(function (stepFieldName) {
                            return !!stepFieldName ? "form." + stepFieldName + ".$valid" : undefined;
                        })
                        unwatchPrevStepValidation = fieldGroupScope.$watchGroup(stepFieldsPaths,
                            function (newValue, oldValue) {
                                scope.currentStepIsValid = newValue.indexOf(false) === -1;
                            }
                        );
                    }
                    else {  //else get validation by name
                        var fieldScope = el.isolateScope();
                        scope.currentStepIsValid = fieldScope.form[fieldScope.name].$valid;
                    }
                }

                function updateFieldsVisibility(fields, currentStep) {
                    angular.forEach(fields, function (field, index) {
                        //hide all steps except current step
                        if (index === currentStep)
                            $(field).css({ 'display': "block" });
                        else
                            $(field).css({ 'display': "none" }); //hide all steps except current step
                    });
                }

                //////// helpers ////////////
                function nodeIsFieldGroup(node) {
                    var el = angular.element(node);
                    var scope = el.isolateScope();
                    if (scope && scope.options)
                        return !!scope.options.fieldGroup;
                    else
                        return false;
                }
                function findFirstFieldGroupNgForm(nodeOrElement) {
                    return $(nodeOrElement).find('[is-field-group=""]')[0];
                }

                function findAllNgModelNodes(nodeOrElement) {
                    return $(nodeOrElement).find('[name][ng-model]');
                }
                //formly fields could be input fields or fieldGroups
                function getFormlyFieldsIn(nodeOrElement) {
                    return $(nodeOrElement).children('[formly-field=""]');
                }

            }
        };
    }]);
}




