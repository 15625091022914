"use strict";
import uuidv4 from 'uuidv4';
import { map, find, omit, cloneDeep, findIndex, filter, assign } from 'lodash';
import 'angularjs-form-builder';
import 'angular-pubsub';
import hijriMoment from 'moment-hijri';
import 'angular-strap/dist/angular-strap.compat';
import 'angular-strap/dist/angular-strap.tpl';


import '../Common'
import '../FormlyViewer';
import './Localization';
import services from './services';
import controllers from './controllers';
import directives from './directives';
import { debug } from 'util';

const formlyBuilder = angular.module('formlyBuilder', ['formBuilder', 'formlyViewer', 'PubSub', 'common', 'mgcrea.ngStrap.aside']);
services(formlyBuilder);
controllers(formlyBuilder);
directives(formlyBuilder);


formlyBuilder.run(['builderConfig', '$cookies', "$translate",'Notification','dataItemsService', "$bsAside",
    function (builderConfig, $cookies, $translate, Notification, dataItemsService) {
     

        function getcurrentLanguage() {
            if ($cookies.get('currentLanguage')) {
               return $cookies.get('currentLanguage');
            }
        }

        function keyFactory(formBuilderFieldType) {
            return formBuilderFieldType + Math.floor(Math.random() * 100000000000);
        }

        function uuidKeyFactory() {
            return uuidv4();
        }
        var ControlsNotIncludeRemoveOnHidden = ['fieldGroup', 'formFieldsAggregator', 'repeaterFieldsAggregator', 'fieldGroupRepeater'];
        function ReconfigureField(formBuilderField) {
          
            if (formBuilderField.data.MetaOptions)
                for (var prop in formBuilderField.data.MetaOptions) {
                    if (prop == "DynamicLookupSystemKey") {
                        continue;
                    }
                    else if (prop == "hideExpression" || prop == "wrapper" || prop == "defaultValue" || prop =="className" ) {
                        if (!formBuilderField.data.MetaOptions[prop].hasOwnProperty('ID')) {
                            formBuilderField.data.MetaOptions[prop].ID = 0;
                        }
                    }
                    else if (prop == "validation")
                    {
                        for (var prop in formBuilderField.data.MetaOptions.validation.messages) {
                            if (!formBuilderField.data.MetaOptions.validation.messages[prop].hasOwnProperty('ID')) {
                                formBuilderField.data.MetaOptions.validation.messages[prop].ID = 0;
                            }
                        }
                    }
                    else
                        for (var prop2 in formBuilderField.data.MetaOptions[prop]) {
                            if (!formBuilderField.data.MetaOptions[prop][prop2].hasOwnProperty('ID')) {
                                formBuilderField.data.MetaOptions[prop][prop2].ID = 0;
                            }
                        }
                }

            //Add remove on hidden part 
            
            if (ControlsNotIncludeRemoveOnHidden.indexOf(formBuilderField.data.Type) == -1 && formBuilderField.data.FieldID ==0) {
                if (!formBuilderField.templateOptions.hasOwnProperty('removeOnHidden')) {
                    formBuilderField.templateOptions.removeOnHidden = true;
                }
                if (formBuilderField.data.MetaOptions.templateOptions && !formBuilderField.data.MetaOptions.templateOptions.hasOwnProperty('removeOnHidden')) {
                    formBuilderField.data.MetaOptions.templateOptions.removeOnHidden = {
                        ID: 0,
                        ValueEn: true,
                    };
                }
            }
                return formBuilderField;
        }
        function assignLocalizedValue(localizationKey, obj, property) {
            if (!localizationKey)
                throw 'can not get localized value without localization key';
            $translate(localizationKey).then((value) => {
                obj[property] = value;
            }).catch((error) => {
                obj[property] = error;
            })
        }
        
        //input
        builderConfig.setType({
            type: "input",
            data: { Type : "input"},
            template: require('./Views/ControlsTemplates/Input/inputPresentation.html'),
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            link: function (scope, elem, attr) {

            },
            controller: ["$scope", function ($scope) {
                // initialize values if they are not defined
                $scope.formBuilderField.key = $scope.formBuilderField.key || keyFactory("input");
                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data : {
                    FieldID: 0,
                    Type: "input",
                    SystemKey: $scope.formBuilderField.key,
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 1,
                    FormFieldValueType: 1,
                    HasKey: true,
                    HasType: true,
                    MetaOptions: {
                       
                    }
                };
                $scope.formBuilderField.type = 'input';
                $scope.formBuilderField.templateOptions = $scope.formBuilderField.templateOptions || { };

                if (!$scope.formBuilderField.templateOptions.label) {
                    assignLocalizedValue('FORMLY_BUILDER.TEXTBOX', $scope.formBuilderField.templateOptions, 'label')
                }
               
            }],

            transformFormField: function (formBuilderField, formViewerField) {
              
                return { formBuilderField: ReconfigureField(formBuilderField) };
            }
        });

        //numericInput
        builderConfig.setType({
            type : "numericInput",
            data: { Type: "numericInput" },
            template: require('./Views/ControlsTemplates/NumericInput/numericInputPresentation.html'),
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            link: function (scope, elem, attr) {

            },
            controller: ["$scope", function ($scope) {
                // initialize values if they are not defined
                $scope.formBuilderField.key = $scope.formBuilderField.key || keyFactory("input");
                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data : {
                    FieldID: 0,
                    Type: "numericInput",
                    SystemKey: $scope.formBuilderField.key,
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 1,
                    FormFieldValueType: 2,
                    HasKey: true,
                    HasType: true,
                    MetaOptions: {
                        templateOptions:
                        {
                            type: {
                                ID : 0,
                                ValueEn: "number",
                            },
                            step: {
                                ID: 0,
                                ValueEn: "1",
                            }
                        }
                    }
                };
                $scope.formBuilderField.type = 'input';
                $scope.formBuilderField.templateOptions = $scope.formBuilderField.templateOptions || { type: "number", step : "1"};
                if (!$scope.formBuilderField.templateOptions.label) {
                    assignLocalizedValue('FORMLY_BUILDER.NUMBERFIELD', $scope.formBuilderField.templateOptions, 'label')
                }
            }],
            transformFormField: function (formBuilderField, formViewerField) {
                return { formBuilderField: ReconfigureField(formBuilderField) };
            }
        });

        ////textarea
        builderConfig.setType({
            type: "textarea",
            data: { Type: "textarea" },
            template: require('./Views/ControlsTemplates/Textarea/textareaPresentation.html'),
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            controller: ["$scope", function ($scope) {
                // initialize values if they are not defined
                $scope.formBuilderField.key = $scope.formBuilderField.key || keyFactory("textarea");
                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data:{
                    FieldID: 0,
                    Type: "textarea",
                    SystemKey: $scope.formBuilderField.key,
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 1,
                    FormFieldValueType: 1,
                    HasKey: true,
                    HasType: true,
                    MetaOptions: {
                    
                    }
                };
                $scope.formBuilderField.templateOptions = $scope.formBuilderField.templateOptions || {};

                if (!$scope.formBuilderField.templateOptions.label) {
                    assignLocalizedValue('FORMLY_BUILDER.TEXTAREA', $scope.formBuilderField.templateOptions, 'label')
                }
                $scope.formBuilderField.type = 'textarea';
            }],
            transformFormField: function (formBuilderField) {
            
                return { formBuilderField: ReconfigureField(formBuilderField )};
            }
        });

        //fieldGroup
        builderConfig.setType({
            template: require('./Views/ControlsTemplates/FieldGroup/fieldGroupPresentation.html'),
            data: { Type: "fieldGroup" },
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            controller: ["$scope", function ($scope) {

                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data : {
                    FieldID: 0,
                    Type: "fieldGroup",
                    SystemKey: keyFactory("fieldGroup"),
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 6,
                    FormFieldValueType: 1,
                    HasKey: false,
                    HasType: false,
                    MetaOptions: {
                        templateOptions: {},
                        wrapper:
                        {
                            ID: 0,
                            ValueEn: ['panel'],
                            ValueAr: '',

                        }
                    }
                };
                $scope.formBuilderField.wrapper = $scope.formBuilderField.wrapper || ['panel'];
             //   $scope.formBuilderField.fieldGroup = $scope.formBuilderField.fieldGroup || [];
                $scope.formBuilderField.data.Type = 'fieldGroup';
                $scope.shouldUpdate = function (children, nextComponent) {
                    return true;
                }

            }],
            link: function (scope, element, attrs, controller) {

            },
            transformFormField: function (formBuilderField) {

                //formBuilderField.fieldGroup = [

                //]
               
                return { formBuilderField: ReconfigureField(formBuilderField) };
            }
        });

        ////select
        builderConfig.setType({
            type: "select",
            data: { Type: "select" },
            template: require('./Views/ControlsTemplates/Select/selectPresentation.html'),
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            controller: ["$scope", function ($scope) {
                $scope.formBuilderField.key = $scope.formBuilderField.key || keyFactory("select");
                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data : {
                    FieldID: 0,
                    Type: "select",
                    SystemKey: $scope.formBuilderField.key,
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 4,
                    FormFieldValueType: 1,
                    HasKey: true,
                    HasType: true,
                    MetaOptions: {

                    }
                };
                $scope.formBuilderField.templateOptions = $scope.formBuilderField.templateOptions || {
                    valueProp: "ID",
                    labelProp: "Label",
                    options: []
                };

                if (!$scope.formBuilderField.templateOptions.label) {
                    assignLocalizedValue('FORMLY_BUILDER.SELECT', $scope.formBuilderField.templateOptions, 'label')
                }
                $scope.formBuilderField.type = 'select';
            }],
            transformFormField: function (formBuilderField, formViewerField) {

               

                    return { formBuilderField: ReconfigureField(formBuilderField) };
                
            }
        });
                
        //checkbox
        builderConfig.setType({          
            type: "checkbox",
            data: { Type: "checkbox" },
            template: require('./Views/ControlsTemplates/Checkbox/checkboxPresentation.html'),
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            controller: ["$scope", function ($scope) {
                // initialize values if they are not defined
                $scope.formBuilderField.key = $scope.formBuilderField.key || keyFactory("checkbox");
                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data : {
                    FieldID: 0,
                    Type: "checkbox",
                    SystemKey: $scope.formBuilderField.key,
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 1,
                    FormFieldValueType: 3,
                    HasKey: true,
                    HasType: true,
                    MetaOptions: {
                    }
                };
                $scope.formBuilderField.templateOptions = $scope.formBuilderField.templateOptions || {};
                if (!$scope.formBuilderField.templateOptions.label) {
                    assignLocalizedValue('FORMLY_BUILDER.CHECKBOX', $scope.formBuilderField.templateOptions, 'label')
                }
                $scope.formBuilderField.type = 'checkbox';
            }],
            transformFormField: function (formBuilderField, formViewerField) {


                return { formBuilderField: ReconfigureField( formBuilderField )};
            }
        });
        
        ////datepicker
        builderConfig.setType({
            type: "datepicker",
            data: { Type: "datepicker" },
           
            template: require('./Views/ControlsTemplates/Datepicker/datepickerPresentation.html'),
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            link: function (scope, elem, attr) {
            },
            controller: ["$scope", "$translate", function ($scope, $translate) {
                // initialize values if they are not defined
                $scope.formBuilderField.key = $scope.formBuilderField.key || keyFactory("datepicker");
                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data : {
                    FieldID: 0,
                    Type: "datepicker",
                    SystemKey: $scope.formBuilderField.key,
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 1,
                    FormFieldValueType: 1,
                    HasKey: true,
                    HasType: true,
                    MetaOptions: {
                    }
                };
                $scope.formBuilderField.templateOptions = $scope.formBuilderField.templateOptions || {};
                if (!$scope.formBuilderField.templateOptions.label) {
                    assignLocalizedValue('FORMLY_BUILDER.GREGORIAN_DATEPICKER', $scope.formBuilderField.templateOptions, 'label')
                }
                $scope.formBuilderField.type = 'datepicker';
            }],
            transformFormField: function (formBuilderField, formViewerField) {
                return { formBuilderField: ReconfigureField(formBuilderField) };
            }
        });
        
        //imagePicker
        builderConfig.setType({
            type: "singleImagePicker",
            data: { Type: "singleImagePicker" },
           
            template: require('./Views/ControlsTemplates/SingleImagePicker/singleImagePickerPresentation.html'),
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            controller: ["$scope", function ($scope) {

                $scope.formBuilderField.key = $scope.formBuilderField.key || keyFactory("singleImagePicker");
                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data : {
                    FieldID: 0,
                    Type: "singleImagePicker",
                    SystemKey: $scope.formBuilderField.key,
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 5,
                    FormFieldValueType: 1,
                    HasKey: true,
                    HasType: true,
                    MetaOptions: {
                        templateOptions: {}
                    }
                };
                $scope.formBuilderField.templateOptions = $scope.formBuilderField.templateOptions || {};
                $scope.formBuilderField.type = 'singleImagePicker';
                if (!$scope.formBuilderField.templateOptions.label) {
                    assignLocalizedValue('FORMLY_BUILDER.IMAGE_PICKER', $scope.formBuilderField.templateOptions, 'label')
                }
                //TOODO check if this is a valid code after integrating viewer
                //$scope.formBuilderField.templateOptions.isBase64 = true;
            }],
            transformFormField: function (formBuilderField, formViewerField) {

                return { formBuilderField: ReconfigureField(formBuilderField) };
            }
        });
        
        //FilePicker
        builderConfig.setType({
            type: "filePicker",
            data: { Type: "filePicker" },

            template: require('./Views/ControlsTemplates/FilePicker/filePickerPresentation.html'),
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            controller: ["$scope", function ($scope) {

                $scope.formBuilderField.key = $scope.formBuilderField.key || keyFactory("filePicker");
                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data : {
                    FieldID: 0,
                    Type: "filePicker",
                    SystemKey: $scope.formBuilderField.key,
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 3,
                    FormFieldValueType: 1,
                    HasKey: true,
                    HasType: true,
                    MetaOptions: {
                        templateOptions: {}
                    }
                };
                $scope.formBuilderField.templateOptions = $scope.formBuilderField.templateOptions || {};
                $scope.formBuilderField.type = 'filePicker';
                if (!$scope.formBuilderField.templateOptions.label) {
                    assignLocalizedValue('FORMLY_BUILDER.FilePicker', $scope.formBuilderField.templateOptions, 'label')
                }
                //TOODO check if this is a valid code after integrating viewer
                //$scope.formBuilderField.templateOptions.isBase64 = true;
            }],
            transformFormField: function (formBuilderField, formViewerField) {

                return { formBuilderField: ReconfigureField(formBuilderField) };
            }
        });
        
        ////formFieldsAggregator
        builderConfig.setType({
            type: "formFieldsAggregator",
            data: { Type: "formFieldsAggregator" },
            template: require('./Views/ControlsTemplates/FormFieldsAggregator/formFieldsAggregatorPresentation.html'),
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            controller: ["$scope", function ($scope) {
                // initialize values if they are not defined
                $scope.formBuilderField.key = $scope.formBuilderField.key || keyFactory("formFieldsAggregator");
                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data : {
                    FieldID: 0,
                    Type: "formFieldsAggregator",
                    SystemKey: $scope.formBuilderField.key,
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 1,
                    FormFieldValueType: 2,
                    HasKey: true,
                    HasType: true,
                    MetaOptions: {

                    }
                };
                $scope.formBuilderField.templateOptions = $scope.formBuilderField.templateOptions || {};

                if (!$scope.formBuilderField.templateOptions.label) {
                    assignLocalizedValue('FORMLY_BUILDER.Fields_Aggregator', $scope.formBuilderField.templateOptions, 'label')
                }
                $scope.formBuilderField.type = 'formFieldsAggregator';
            }],
            transformFormField: function (formBuilderField) {
               
                return { formBuilderField: ReconfigureField(formBuilderField) };
            }
        });

        ////repeaterFieldsAggregator
        builderConfig.setType({
            type: "repeaterFieldsAggregator",
            data: { Type: "repeaterFieldsAggregator" },
            template: require('./Views/ControlsTemplates/RepeaterFieldsAggregator/repeaterFieldsAggregatorPresentation.html'),
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            controller: ["$scope", function ($scope) {
                // initialize values if they are not defined
                $scope.formBuilderField.key = $scope.formBuilderField.key || keyFactory("repeaterFieldsAggregator");
                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data : {
                    FieldID: 0,
                    Type: "repeaterFieldsAggregator",
                    SystemKey: $scope.formBuilderField.key,
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 1,
                    FormFieldValueType: 2,
                    HasKey: true,
                    HasType: true,
                    MetaOptions: {

                    }
                };
                $scope.formBuilderField.templateOptions = $scope.formBuilderField.templateOptions || {};

                if (!$scope.formBuilderField.templateOptions.label) {
                    assignLocalizedValue('FORMLY_BUILDER.repeaterFieldsAggregator', $scope.formBuilderField.templateOptions, 'label')
                }
                $scope.formBuilderField.type = 'repeaterFieldsAggregator';
            }],
            transformFormField: function (formBuilderField) {

                return { formBuilderField: ReconfigureField(formBuilderField) };
            }
        });

        ////fieldGroupRepeater
        builderConfig.setType({
           
            type: "fieldGroupRepeater",
            data: { Type: "fieldGroupRepeater" },
            template: require('./Views/ControlsTemplates/FieldGroupRepeater/fieldGroupRepeaterPresentation.html'),
            wrapper: ['/ng/Modules/Form/FormlyBuilder/Views/Wrappers/dragPanel.html'],
            controller: ["$scope", "Notification", function ($scope, Notification) {
                $scope.allowedTypes = ['input', 'numericInput', 'textarea', 'fieldGroup', 'checkbox', 'select', 'datepicker', 'singleImagePicker', 'filePicker', 'fieldGroupRepeater','repeaterFieldsAggregator'];

                $scope.shouldUpdate = function (children, nextComponent) {
                    if ($scope.allowedTypes.indexOf(nextComponent.data.Type) != -1 && (!nextComponent.data.FieldID || nextComponent.data.FieldID == 0)) {

                        return true;
                    }
                    else {
                        $translate("FORMLY_BUILDER.repeaterWarning").then(function (msg) {
                            Notification.warning({ message: msg });

                        });
                        return false;
                    }
                }

                var _allowedTypes = map($scope.allowedTypes, function (type) {
                    return type.toLowerCase();
                });

                $scope.onDragOver = function (children, nextType) {
                    if (_allowedTypes.indexOf(nextType) == -1 ) {
                        return '<div class="fa fa-ban customPlaceholder"  aria-hidden="true"></div>'
                    }
                }
                $scope.formBuilderField.key = $scope.formBuilderField.key || keyFactory("fieldGroupRepeater");
                $scope.formBuilderField.data = $scope.formBuilderField.data.SystemKey ? $scope.formBuilderField.data : {
                    FieldID: 0,
                    Type: "fieldGroupRepeater",
                    SystemKey: $scope.formBuilderField.key,
                    CardKey: null,
                    IsShortFormField: false,
                    ShortFormDisplayOrder: 0,
                    FormFieldStructure: 2,
                    FormFieldValueType: 1,
                    HasKey: true,
                    HasType: true,
                    MetaOptions: {
                        defaultValue: { ValueEn: [ ] } 
                    }
                };
                $scope.formBuilderField.templateOptions = $scope.formBuilderField.templateOptions || {};

                if (!$scope.formBuilderField.templateOptions.cardTitle) {
                    assignLocalizedValue('FORMLY_BUILDER.REPEAT_SECTION', $scope.formBuilderField.templateOptions, 'cardTitle')
                }
                $scope.formBuilderField.type = 'fieldGroupRepeater';
                $scope.formBuilderField.defaultValue = $scope.formBuilderField.defaultValue || [];

            }],
            transformFormField: function (formBuilderField, formViewerField) {
             
                return { formBuilderField: ReconfigureField(formBuilderField) };
            }
        });
        
        builderConfig.setBuilderFieldDropListener(function (builderField) {

        });

        builderConfig.setBuilderFieldRemoveListener(function (builderField) {

        });
         
    }]);

formlyBuilder.directive('handleSelection', ['PubSub', '$bsAside', function (PubSub, $bsAside) {
    return {
        priority: 1, //to capture onclick before other directives 
        link: function (scope, element, attrs, controller) {
            var asideTemplate = require('./Views/Partials/asideMainTemplate.html');
            var aside = $bsAside({ template: asideTemplate, show: false, placement: 'left', backdrop : 'static'});
            element.bind('click', function (e) {
                aside.show();
                PubSub.publish('componentSelected', {
                    formBuilderField: scope.formBuilderField,
                    transformFormField: scope.transformFormField,
                    deleteField: scope.deleteField
                });
                e.stopPropagation();
            });

            scope.$on("$destroy", function () {
                element.off('click');
                aside.hide();
                aside.destroy();
            });
        },
        controller: ['$scope', function ($scope) {
        }]

    }
}]);

formlyBuilder.directive('fieldConfig', ['PubSub', '$templateRequest', '$compile',  'dataItemsService',
    function (PubSub, $templateRequest, $compile, dataItemsService) {
        var selectedComponent = null;
        var builderFields = null;
        var _findformBuilderField = function (builderFields, builderField) {
            for (var i = 0; i < builderFields.length; i++) {
                if (builderFields[i].data.SystemKey == builderField.data.SystemKey)
                    return builderFields[i];
                else if (builderFields[i].fieldGroup) {
                    var found = _findformBuilderField(builderFields[i].fieldGroup, builderField);
                    if (found) return found;
                }
                else if (builderFields[i].templateOptions.fields) {
                    var found = _findformBuilderField(builderFields[i].templateOptions.fields, builderField);
                    if (found) return found;
                }
            }
        };

        return {
            restrict: 'E',
            scope: {
                hideAside: '&'
            },
           
            controllerAs: 'fieldConfigCtrl',
            controller: ["$scope", "$element", '$cookies', 'dataItemsService', 'Notification', '$translate', function ($scope, $element, $cookies, dataItemsService, Notification, $translate) {

                var fieldConfigCtrl = this;
                fieldConfigCtrl.Lang = $cookies.get('currentLanguage');
                fieldConfigCtrl.ViewMode = dataItemsService.getSectionsEditable();
                fieldConfigCtrl.disableDelete= false;
                fieldConfigCtrl.Entities = angular.copy(dataItemsService.getVisibleRequiredEntities());
                fieldConfigCtrl.Lookups = dataItemsService.getLookups();
                var configFormTemplateScope = $scope.$new(false, $scope);
                //subscribe to compnent selection event. callback gets selected component.
                PubSub.subscribe('componentSelected', function (component) {

                    //save current selected formBuilderField
                    selectedComponent = component;

                    // clone builder list then find selected field inside it and pass it by refrance to be modifayed when user clicks save pass the builder list to genrate a new data items list
                    builderFields = angular.copy(dataItemsService.getBuilderFields());

                    var formBuilderField = _findformBuilderField(builderFields, component.formBuilderField)

                    if (!formBuilderField) {
                        throw Error("can not find selected builder Field check fieldConfig ")
                    }

                    // init scope for field config form
                    fieldConfigCtrl.formBuilderField = formBuilderField;
                    fieldConfigCtrl.isAllRSelected = false;
                    fieldConfigCtrl.isAllVSelected = false;
                    fieldConfigCtrl.showRequired = (fieldConfigCtrl.formBuilderField.data.Type != 'fieldGroupRepeater' && fieldConfigCtrl.formBuilderField.data.Type != 'fieldGroup');

                    
                    var visibleRequired = [];
                    if (fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos && fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos.length > 0) {
                        fieldConfigCtrl.Entities.forEach((itm, i) => {
                            visibleRequired.push(Object.assign({}, itm, fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos.find((itmInner) => itmInner.EntityID === itm.EntityID)));
                        });
                    }
                    else {
                        visibleRequired = fieldConfigCtrl.Entities;
                    }
                    fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos = visibleRequired;
                    fieldConfigCtrl.formBuilderField.templateOptions = formBuilderField.templateOptions ? formBuilderField.templateOptions : {};

                    ///set visible and require
                    fieldConfigCtrl.isAllRSelected = !fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos.some((x) => {
                        if (x.IsRequired == false)
                            return x;
                    }
                    );
                    fieldConfigCtrl.isAllVSelected = !fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos.some((x) => {
                        if (x.IsVisible == false)
                            return x;
                    });
                    //load dynamic template
                    var _formBuilderFieldName = component.formBuilderField.data.Type;
                    var _templatePath = _formBuilderFieldName.charAt(0).toUpperCase() + _formBuilderFieldName.slice(1) + '/' + _formBuilderFieldName + "Config"

                    if (fieldConfigCtrl.formBuilderField.data.FieldID != 0 && !fieldConfigCtrl.ViewMode && !fieldConfigCtrl.formBuilderField.data.IsEditable)
                        fieldConfigCtrl.disableDelete = true;
                    else
                        fieldConfigCtrl.disableDelete = fieldConfigCtrl.ViewMode;
               
                    var fieldConfigFormHtml = require(`./Views/ControlsTemplates/${_templatePath}.html`)
               //    var template = angular.element(fieldConfigFormHtml);
                   var fieldConfigFormHtml2 = require(`./Views/ControlsTemplates/EntitiesPermission/EntitiesPermission.html`);
                 
                    var template2 = angular.element(fieldConfigFormHtml2);
                   template2[4].children[0].innerHTML = fieldConfigFormHtml;
                    $element.empty();
                    if (configFormTemplateScope) {
                        configFormTemplateScope.$destroy();
                        configFormTemplateScope = $scope.$new(false, $scope);
                    }
                    $element.append(template2);
                    $compile(template2)(configFormTemplateScope);
                });

                $scope.$on("$destroy", function () {
                    configFormTemplateScope.$destroy();
                    $element.remove()
                    PubSub.unsubscribe('componentSelected');
                });

                fieldConfigCtrl.getEntityName = function (entityID) {
                    var entity = fieldConfigCtrl.Entities.find(s => s.EntityID == entityID);
                    return entity.EntityName;
                }
                fieldConfigCtrl.save = function () {
                    if (typeof selectedComponent.transformFormField === "function") {
                        var formBuilderFieldCopy = angular.copy(fieldConfigCtrl.formBuilderField);
                        selectedComponent.transformFormField(formBuilderFieldCopy);
                        fieldConfigCtrl.hideAside();
                    } else {
                        throw new Error("component must have transformFormField function");
                    }
                }

                fieldConfigCtrl.deleteField = function () {
                    if (fieldConfigCtrl.formBuilderField.data.FieldID !=0 && !fieldConfigCtrl.formBuilderField.data.IsEditable) {
                        $translate("FORMLY_BUILDER.DELETED").then(function (msg) {
                            Notification.error({ message: msg });
                           
                        });
                        return;
                    }
                    selectedComponent.deleteField();
                    fieldConfigCtrl.hideAside();
                }
                fieldConfigCtrl.toggleRAll = function () {
                    fieldConfigCtrl.isAllRSelected = !fieldConfigCtrl.isAllRSelected;
                    if (fieldConfigCtrl.isAllRSelected === true) {
                        angular.forEach(fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos, function (item) { item.IsRequired = true; });
                    }
                    else {
                        angular.forEach(fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos, function (item) { item.IsRequired = false; });
                    }
                }
                fieldConfigCtrl.toggleVAll = function () {
                    fieldConfigCtrl.isAllVSelected = !fieldConfigCtrl.isAllVSelected;
                    if (fieldConfigCtrl.isAllVSelected === true) {
                        angular.forEach(fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos, function (item) { item.IsVisible = true; });
                    }
                    else {
                        angular.forEach(fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos, function (item) { item.IsVisible = false; });
                    }
                }
                fieldConfigCtrl.updateRCheckBox = function (Entity) {
                    Entity.IsRequired = !Entity.IsRequired;
                    fieldConfigCtrl.isAllRSelected = !fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos.some((x) => {
                        if (x.IsRequired == false)
                            return x;
                    }
                    );
                }
                fieldConfigCtrl.updateVCheckBox = function (Entity) {
                    Entity.IsVisible = !Entity.IsVisible;
                    fieldConfigCtrl.isAllVSelected = !fieldConfigCtrl.formBuilderField.data.VisibleRequiredTos.some((x) => {
                        if (x.IsVisible == false)
                            return x;
                    }
                    );
                }
            }],
            bindToController: true,
        }
    }])





formlyBuilder.directive('messageFormatter', ['$filter', function ($filter) {
        return {
            require: '?ngModel',
            link: function (scope, elem, attrs, ctrl) {
                if (!ctrl) return;


                ctrl.$formatters.unshift(function (a) {
                   
                    if (ctrl.$modelValue) {
                        var n = ctrl.$modelValue.startsWith("'");
                        if (n) {
                            return ctrl.$modelValue.substring(1, ctrl.$modelValue.length - 1);
                        }
                        return ctrl.$modelValue.toLocaleString('en-US');
                    }
                    else
                        return ctrl.$modelValue;

                });


                ctrl.$parsers.unshift(function (viewValue) {
                   
                    var n = viewValue.startsWith("'");
                    if (n)
                        return viewValue
                    else
                        return "'" + viewValue+"'";
                });
            }
        };
    }]);






