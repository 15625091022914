export default {
    NEW: 'New',
    APPROVED: 'Approved',
    SENT_BACK: 'Sent Back',
    REPLY: 'Reply',
    UNDER_REVIEWING: 'Under Review',
    PICK: 'Pick',
    UNPICK: 'Release',
    VIEW: 'View',
    PICKED_BY: 'Picked by : ',
    EDIT: 'Edit',
    PICKEDBY_ANOTHER: 'This is already picked by another user.',
    ANNUAL_PICKED: 'Annual Achievements has been successfully Picked',
    ANNUAL_RELEASED: 'Annual Achievements has been successfully Released',
    ANNUAL_REOPENED: 'Annual Achievements has been successfully Reopened',
    ANNUAL_CANNOT_REOPENED: 'You are not allowed to reopen the entity annual achievements unless it is approved',
    RELEASE_QUES: 'This task is not submitted yet. Are you sure you want to proceed?',
    CANCEL_ACHIEVEMENTS: 'Reset',
    CANNOT_SUBMIT_ANNUAL: 'You are not allowed to submit the entity annual achievements unless reviewing all sections',
    NOT_ACTIVITIES_SUBMITED: 'You are not allowed to submit the entity annual achievements, unless completing all the required data for all activities',
    ADD: 'Add',
    Empty: 'Empty',
    InProgress: 'In Progress',
    REOPEN: 'Reopen',
    FINAL_SUBMIT: 'Final Submit',
    SEND_EMAIL: 'Send Email',
    CONFIRM_SUBMIT_ACHIEVEMENTS: 'You are submitting your reviewing results. Are you sure you want to proceed?',
    MBRGIViewerNotAllowToView: '  this entity annual achievements is Reopened ',
    NotAllowToSubmit: 'You are currently working on an entity annual achievement that sent an approved editing request. This will be automatically released.',
}