export default {
    ADD_BENEFICIARY_UNIT: 'إضافة وحدة مستفيدين',
    BENEFICIARY_UNIT_ENGLISH_NAME: 'اسم الوحدة (الإنجليزية)',
    BENEFICIARY_UNIT_ARABIC_NAME: 'اسم الوحدة  (العربية)',

    BENEFICIARY_UNIT_TYPE: 'نوع الوحدة ',

    PLEASE_SELECT: 'الرجاء الاختيار',
    REQUIRED_BENEFICIARY_UNIT_ARABIC_NAME: 'اسم الوحدة  بالعربية مطلوب',
    REQUIRED_BENEFICIARY_UNIT_ENGLISH_NAME: 'اسم الوحدة  بالانجليزية مطلوب',
    REQUIRED_BENEFICIARY_UNIT_TYPE: 'نوع الوحدة  بالانجليزية مطلوب',
    BENEFICIARY_UNITS: 'وحدات المستفيدين',
    UPDATE: 'تعديل',
    UPDATE_BENEFICIARY_UNIT: 'تعديل وحدة المستفيدين',

    BENEFICIARY_UNIT_EXIST: 'لا يمكن تكرار اسم وحدة المستفيدين',
    BENEFICIARY_UNIT_ADDED: 'تم إضافة وحدة مستفيدين بنجاح',
    BENEFICIARY_UNIT_UPDATED: 'تم تعديل وحدة المستفيدين بنجاح',
    BENEFICIARY_UNIT_DELETED: 'تم حذف وحدة المستفيدين بنجاح',
    BENEFICIARY_UNIT_DELETE_QUES: 'هل تريد حذف وحدة المستفيدين؟',
    DELETE_HEADER: 'حذف',
    BENEFICIARY_UNIT_RESTORED: 'وحدة المستفيدين تم استعادة بنجاح',
    

}