export default function (app) {
    app.controller('previewEmailTemplateController', PreviewEmailTemplatesController);

    PreviewEmailTemplatesController.$inject = ["$scope", "$rootScope", "emailTemplatesDataService","$uibModalInstance", "Notification", "Params", "$translate",];
    function PreviewEmailTemplatesController($scope, $rootScope, emailTemplatesDataService, $uibModalInstance, Notification, Params, $translate) {
        var vm = this;
        vm.labels = {
        };
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.SubjectEn = Params.SubjectEn;
        vm.SubjectAr = Params.SubjectAr;
        vm.bodyEN = Params.bodyEn;
        vm.bodyAR = Params.bodyAr;
        vm.tokensList = Params.tokensList;
        vm.cancel = cancel;
        vm.load = load;
        vm.load();

        function load() {
            _processPreviewing();
            vm.Subject = $rootScope.currentLang == "ar" ? vm.SubjectAr : vm.SubjectEn;

        }
        function _processPreviewing() {

            vm.tokensList.forEach(function (token) {
                vm.SubjectEn = vm.SubjectEn ? replaceAll(vm.SubjectEn, token.Name, token.SampleEn) : vm.SubjectEn;
                vm.SubjectAr = vm.SubjectAr ? replaceAll(vm.SubjectAr, token.Name, token.SampleAr) : vm.SubjectAr;
                vm.bodyEN = vm.bodyEN ? replaceAll(vm.bodyEN, token.Name, token.SampleEn) : vm.bodyEN;
                vm.bodyAR = vm.bodyAR ? replaceAll(vm.bodyAR, token.Name, token.SampleAr) : vm.bodyAR;
            });
        }
        function replaceAll(str, find, replace) {
            return str.replace(new RegExp(find, 'g'), replace);
        }
        function cancel() {
            $uibModalInstance.close();
        }

    }
}