export default {
    //repeatSection
    DELETE: 'حذف',
    SAVE: 'حفظ',
    ADD: 'إضافة',
    CLOSE: 'إغلاق',
    EDIT_ITEM: 'تعديل عنصر',
    ADD_ITEM: 'إضافة عنصر',

    //collapsibleRadioList
    PENALTY: 'مخالف',
	ALIKE: 'مطابق',
	DELETE_REP_CONFIRM_HEADER: 'حذف قيد',
	DELETE_REP_CONFIRM_MESSAGE: 'هل تريد حذف هذا القيد؟',

	//select
	NO_CHOICE : 'لا يوجد اختيارات'
}