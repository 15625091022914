//Import controllers
import userController from './Controllers/app.user.controller';
import updateController from './Controllers/app.update-user.controller';
import addController from './Controllers/app.add-user.controller';
//Import services
import dataService from './Services/user.data.service';
//Import config
import roleDataService from '../Services/role.data.service';
import ngValidateCondition from '../Directives/ngValidateCondition';

export default function (app) {
    userController(app);
    ngValidateCondition(app);
    roleDataService(app);
    updateController(app);
    addController(app);
    dataService(app);
}