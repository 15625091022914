import { includes as _includes, toLower as _toLower, filter as _filter, find as _find} from "lodash";

export default function (app) {
    //filter card by assignees names, state, workflow type or title
    app.filter('cardText', function () {
        return function (cards, text, getStatName) {
            if (!text) {
                return cards
            } else {
                return _filter(cards, (card) => {
                    var _stateName = getStatName ? getStatName(card.workflowInfo.status, card.taskType) : null;
                    var _stateName = _toLower(_stateName);
                    var _text = _toLower(text)
                    var matchAssigneByName = _find(card.assignmentInfo, (assigne) => {
                        return _includes(_toLower(assigne.value), _text)
                    })


                    return (
                         matchAssigneByName ||
                        _includes(_stateName, _text) ||
                        _includes(_toLower(card.workflowInfo.type), _text) ||
                        _includes(_toLower(card.title), _text)
                    );
                })
            }
        }
    });
}

