export default {
    COMMENTS: 'تعليقات',
    COMMENT: 'تعليق',
    ALL: 'الكل',
    TO_DO: 'تحت الإجراء',
    COMPLETED: 'تم',
    REVIEW: 'مراجعة',
    EDITING: 'تحت التعديل',

    NEW: 'جديد',
    EDITED: 'تم التعديل',
    REVIEWING: 'تحت المراجعة',
    REVIEWED: 'تمت مراجعتها',
    SENT_BACK: 'معاد إرساله للتعديل',
    CREATE: 'إنشاء',
    REASSIGN: 'إعادة توجيه',
    REOPEN: 'إعادة فتح',
    EDITOR: 'المحرر',
    REVIEWER: 'المراجع',
    SAVE: 'حفظ',
    SUBMIT: 'تأكيد',
    APPROVE: 'تأكيد',
    SENDBACK: 'إعادة',

    DELETE: 'حذف',
    DELETEQUESTION: ' هل تريد حذف',
    ADD_Task: 'إضافة مهمة جديدة',
    VIEW: 'عرض',
    TASK_TYPE: 'نوع المهمة',
    EDIT: 'تعديل',
}