export default function (app) {

    app.factory("UserMessage", ['$rootScope', '$timeout', '$q', '$uibModal', function ($rootScope, $timeout, $q, $uibModal) {

        return {
            close: function () {
               
            },
            confirmation: function (msg, confirmFunction, CancelFunction, event, title) {
               
            },
            error: function (msg) {
                
            },
            warning: function (msg) {
                
            },
            success: function (msg) {
                
            },
            info: function (msg) {
               

            }
        };
    }]);
}
