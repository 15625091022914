import { remove as _remove, map as _map, toString as _toString } from 'lodash';
export default function (app) {

    app.controller("MBRGICountriesReportController", ['$scope', 'reportsService', 'exportFileService', 'uiLoadingIndicators', 'taskTypesConstant', 'sectionStatusConstant', 'errorNotificationService', '$stateParams', 'routeConstants', '$state', '$rootScope', '$uibModal', '$timeout', 'actionsTypesConstant', 'successrNotificationService', 'sectionsService', '$translate', 'Excel', '$filter', '$document',
        function ($scope, reportsService,exportFileService, uiLoadingIndicators, taskTypesConstant, sectionStatusConstant, errorNotificationService, $stateParams, routeConstants, $state, $rootScope, $uibModal, $timeout, actionsTypesConstant, successrNotificationService, sectionsService, $translate, Excel, $filter,$document) {
            var vm = this;
            vm.labels = {
                ALL: $translate.instant('REPORTS.ALL'),
                SECTIONS: $translate.instant('COMMON.SECTIONS'),
                ENTITIY: $translate.instant('COMMON.ENTITIY'),
                PILLAR: $translate.instant('COMMON.PILLAR'),
                MBRGI: $translate.instant('COMMON.MBRGI'),
                Countries: $translate.instant('REPORTS.Countries'),
                Countries_Total_Cost: $translate.instant('REPORTS.Countries_Total_Cost'),
                Countries_Total_BENEFICIARIES: $translate.instant('REPORTS.Countries_Total_BENEFICIARIES'),
            }
            $scope.currentLang = $translate.use();
            vm.totalBeneficiariesPieData = [];
            vm.totalSpentPieData = [];

            vm.cycles = [];
            vm.pillars = [];
            vm.activeCycleId = 0;
            vm.selectedLevelId = 1;
            vm.EntityLevelId = 0;
            vm.PillarLevelId = 0;
            vm.pillarsIdList = []; 
            vm.selectedPillarsIdList = [];
            vm.selectedEntitiesIdList = [];

            $scope.$watch('vm.activeCycleId', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    vm.displayExport = false;
                }
            }, true);
            $scope.$watch('vm.selectedLevelId', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    vm.displayExport = false;
                }
            }, true);
            $scope.$watch('vm.pillarsIdList', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    vm.displayExport = false;
                }
                var oldValueIndex = oldValue.indexOf("0");
                var newValueIndex = newValue.indexOf("0");
                var all = ["0"];
                if ((oldValueIndex == -1) && (newValueIndex !== -1)) {
                    vm.pillarsIdList = all;
                }
                else if (oldValueIndex !== -1 && newValueIndex !== -1 && newValue.length > 1) {
                    var index = vm.pillarsIdList.indexOf("0");
                    vm.pillarsIdList.splice(index, 1);
                }

            }, true);
            $scope.$watch('vm.entitiesIdList', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    vm.displayExport = false;
                }
                var oldValueIndex = oldValue.indexOf("0");
                var newValueIndex = newValue.indexOf("0");
                if ((oldValueIndex == -1) && (newValueIndex !== -1)) {
                    vm.entitiesIdList = ["0"];
                }
                else if (oldValueIndex !== -1 && newValueIndex !== -1 && newValue.length > 1) {
                    var index = vm.entitiesIdList.indexOf("0");
                    vm.entitiesIdList.splice(index, 1);
                }

            }, true);
            vm.entitiesIdList = [];
            vm.coulmnKeys = {};
            vm.isMBRGILevel = false;
            vm.isPillarLevel = false;
            vm.isEntityLevel = false;

            vm.levels = [];
            vm.displayReport = false;
            vm.displayExport = false;
            vm.displayCharts = false;
            vm.isOneChart = false;
            vm.isEmptyList = false;
            vm.pillarVisableClass = "hidden";
            vm.entityVisableClass = "hidden";
            vm.entities = new Object();
            vm.reportInfo = [];
            vm.totalResuilts = [];
            vm.sectionData = {};
            vm.Loading = $rootScope.Loading;
            vm.loadingPdfFile = false;

            vm.downloadPdf = function () {
                vm.loadingPdfFile = true;

                var repo = $document[0].getElementById("ReportContent").innerHTML;
                var Data = { value: repo };
                var reportType = $rootScope.enums.ReportTypes['Countries'];
                var exportFile = exportFileService.exportPdf(Data, vm.selectedLevelId, reportType);
                Promise.all([exportFile]).then(function (res) {
                    vm.loadingPdfFile = false;
                    $scope.$apply();

                });

            };
            var _getAllFilterList = function () {
                uiLoadingIndicators.load('report', true);
                _fillLevels();
                var cyclesList = reportsService.getCyclesList();
                var entitiesList = reportsService.getEntitiesList();
                var pillarsList = reportsService.getPillarsList();
                getFiltersRequests(cyclesList, entitiesList, pillarsList);

            }
            function getFiltersRequests(cyclesList, entitiesList, pillarsList) {
                Promise.all([cyclesList, entitiesList, pillarsList]).then(function (res) {
                    if (res != undefined) {
                        getCyclesList(res[0]);
                        getEntitiesList(res[1]);
                        getPillarsList(res[2]);
                        $scope.$apply();
                    }
                    uiLoadingIndicators.load('report', false);

                });
            }
            var _fillLevels = function () {
                var dict = [];
                dict.push({
                    key: vm.labels.MBRGI,
                    value: $rootScope.enums.ReportLevels['MBRGI']
                });
                dict.push({
                    key: vm.labels.PILLAR,
                    value: $rootScope.enums.ReportLevels['Pillars']
                });
                dict.push({
                    key: vm.labels.ENTITIY,
                    value: $rootScope.enums.ReportLevels['Entities']
                });
                vm.levels = dict;
                vm.EntityLevelId = $rootScope.enums.ReportLevels['Entities'];
                vm.PillarLevelId = $rootScope.enums.ReportLevels['Pillars'];
            }

            function getCyclesList(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data.length > 0) {
                        vm.cycles = _map(_data, (cycle) => {
                            if ($rootScope.currentLang == 'en') {
                                cycle.name = cycle.NameEn;
                            }
                            else {
                                cycle.name = cycle.NameAr;
                            }
                            if (cycle.ActiveCycle) {
                                vm.activeCycleId = cycle.ID;
                            }
                            return cycle;
                        });
                        if (vm.activeCycleId == 0) {
                            vm.cycles = $filter('orderBy')(vm.cycles, 'CreationDate', 'reverse');
                            vm.activeCycleId = vm.cycles[0].ID;
                        }


                    }
                } else {

                    errorNotificationService.showErrorNotification(response.responseCode);
                }


            }
            function getEntitiesList(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data != null && !(angular.equals({}, _data))) {
                        vm.entities = _data;
                        var g = { Key: 0, Value: vm.labels.ALL }
                        vm.entities.splice(0, 0, g);
                    }
                } else {

                    errorNotificationService.showErrorNotification(response.responseCode);
                }


            }
            function getPillarsList(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data.length > 0) {
                        vm.pillars.push({ name: vm.labels.ALL, ID: 0 });
                        for (var i = 0; i < _data.length; i++) {
                            var name = "";
                            if ($rootScope.currentLang == 'en') {
                                name = _data[i].NameEn;
                            }
                            else {
                                name = _data[i].NameAr;
                            }
                            vm.pillars.push({ name: name, ID: _data[i].ID });
                        }


                    }
                } else {

                    errorNotificationService.showErrorNotification(response.responseCode);
                }


            }
            var _fillChartsData = function (_data) {
                
                vm.totalBeneficiariesPieData = [];
                vm.totalSpentPieData = [];
                for (var i = 0; i < _data.length; i++) {
                    var name = "";
                    if ($rootScope.currentLang == 'en') {
                        name = _data[i].CountryNameEn;
                    }
                    else {
                        name = _data[i].CountryNameAr;
                    }
                    vm.totalBeneficiariesPieData.push({ name: name, y: _data[i].TotalBeneficiariesPercentage, text: _data[i].TotalBeneficiaries })
                    vm.totalSpentPieData.push({ name: name, y: _data[i].TotalSpentPercentage, text: _data[i].TotalSpent })
                }
                if (vm.totalBeneficiariesPieData.length > 0 && vm.totalSpentPieData.length > 0) {
                    vm.isOneChart = false;
                }
                else {
                    vm.isOneChart = true;

                }


            }

            function getMBRGIStatisticsReportInfo(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data.length > 0) {
                        _fillChartsData(_data);
                        vm.isMBRGILevel = true;
                        vm.displayCharts = true;
                    }
                }

            }
            vm.GetObjectLength = function (objectData) {
                return Object.keys(objectData).length;
            }

            function getReports(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    vm.reportInfo = _data.Result;
                    vm.totals = _data.ResultTotals;
                    vm.coulmnKeys = _data.ColumnName;

                    if (vm.reportInfo.length > 0 && vm.reportInfo) {

                        vm.displayReport = true;
                        vm.displayExport = true;
                        if (vm.selectedLevelId == $rootScope.enums.ReportLevels['MBRGI']) {
                            vm.isMBRGILevel = true;
                            vm.isPillarLevel = false;
                            vm.isEntityLevel = false;

                            vm.reportInfo[0].Level = vm.labels.SECTIONS;
                        }
                        else if (vm.selectedLevelId == $rootScope.enums.ReportLevels['Pillars']) {
                            vm.isPillarLevel = true;
                            vm.isMBRGILevel = false;
                            vm.isEntityLevel = false;

                        } else if (vm.selectedLevelId == $rootScope.enums.ReportLevels['Entities']) {
                            vm.isMBRGILevel = false;
                            vm.isPillarLevel = false;
                            vm.isEntityLevel = true;
                        }

                    }
                    else {
                        vm.displayReport = false;
                        vm.isEmptyList = true;
                    }
                }

            }
            var _init = function () {
                vm.Loading();

                _getAllFilterList();
                vm.Loading();


            }();

            var _checkIfAllSelected = function () {
                if (vm.selectedLevelId == $rootScope.enums.ReportLevels['Pillars']) {
                    var index = vm.pillarsIdList.indexOf("0")
                    if (index !== -1) {
                        vm.selectedPillarsIdList = _map(vm.pillars, (item) => {
                            return item.ID;
                        });

                        var indexZero = vm.selectedPillarsIdList.indexOf(0);
                        vm.selectedPillarsIdList.splice(indexZero, 1);
                    } else {
                        vm.selectedPillarsIdList = vm.pillarsIdList;
                    }
                }
                else if (vm.selectedLevelId == $rootScope.enums.ReportLevels['Entities']) {

                    var index = vm.entitiesIdList.indexOf("0")
                    if (index !== -1) {
                        vm.selectedEntitiesIdList = vm.entities.map(x => { return x.Key });
                        var indexZero = vm.selectedEntitiesIdList.indexOf("0");
                        vm.selectedEntitiesIdList.splice(indexZero, 1);
                    }
                    else {
                        vm.selectedEntitiesIdList = vm.entitiesIdList;
                    }
                }
            }
            var _initReportData = function () {
                vm.displayCharts = false;
                vm.displayReport = false;
                vm.isOneChart = false;
                vm.isEmptyList = false;
                _checkIfAllSelected();
            }
            vm.ChangeLevel = function () {
                if (vm.selectedLevelId == $rootScope.enums.ReportLevels['MBRGI']) {
                    vm.pillarVisableClass = "hidden";
                    vm.entityVisableClass = "hidden";
                    var pillar = angular.element(document.querySelector('#pillarDiv'));
                    var entity = angular.element(document.querySelector('#enitityDiv'));
                    pillar.removeClass('visible');
                    entity.removeClass('visible');
                    pillar.addClass('hidden d-none');
                    entity.addClass('hidden d-none');


                }
                else if (vm.selectedLevelId == $rootScope.enums.ReportLevels['Pillars']) {
                    vm.pillarVisableClass = "visible";
                    vm.entityVisableClass = "hidden d-none";
                    var pillar = angular.element(document.querySelector('#pillarDiv'));
                    var entity = angular.element(document.querySelector('#enitityDiv'));
                    pillar.removeClass('hidden d-none');
                    entity.removeClass('visible');
                    pillar.addClass('visible');
                    entity.addClass('hidden d-none');
                }
                else if (vm.selectedLevelId == $rootScope.enums.ReportLevels['Entities']) {
                    vm.pillarVisableClass = "hidden";
                    vm.entityVisableClass = "visible";
                    var pillar = angular.element(document.querySelector('#pillarDiv'));
                    var entity = angular.element(document.querySelector('#enitityDiv'));
                    pillar.removeClass('visible');
                    entity.removeClass('hidden d-none');
                    pillar.addClass('hidden d-none');
                    entity.addClass('visible');
                }
            }
            vm.GenerateReport = function () {
                uiLoadingIndicators.load('report', true);
                _initReportData();

                if (vm.selectedLevelId == $rootScope.enums.ReportLevels['MBRGI']) {

                    var mbrgiCharts = reportsService.getMBRGICountriesStatisticsReportInfo(vm.activeCycleId);
                    var mbrgiReport = reportsService.getCountriesReportInfo(vm.activeCycleId, vm.selectedLevelId, vm.selectedEntitiesIdList, vm.selectedPillarsIdList);
                    Promise.all([mbrgiCharts, mbrgiReport]).then(function (res) {
                        if (res != undefined) {

                            getMBRGIStatisticsReportInfo(res[0]);
                            getReports(res[1]);
                            $scope.$apply();
                        }
                        uiLoadingIndicators.load('report', false);

                    }, function (error) {
                        errorNotificationService.showErrorNotification(error);
                        uiLoadingIndicators.load('report', false);
                    });
                }
                else {
                    var mbrgiReport = reportsService.getCountriesReportInfo(vm.activeCycleId, vm.selectedLevelId, vm.selectedEntitiesIdList, vm.selectedPillarsIdList);
                    Promise.all([mbrgiReport]).then(function (res) {
                        if (res != undefined) {
                            getReports(res[0]);
                            $scope.$apply();
                        }
                        uiLoadingIndicators.load('report', false);

                    }, function (error) {
                        errorNotificationService.showErrorNotification(error);
                        uiLoadingIndicators.load('report', false);
                    });

                }

            }
            vm.exportToexcel = function (tableId) {
                vm.loadingExcelFile = true;
                var exportFile = exportExcel();
                Promise.all([exportFile]).then(function (res) {
                    vm.loadingExcelFile = false;
                    $scope.$apply();
                });
            };
            var exportExcel = function () {
                return reportsService.exportCountriesReportInfo(vm.activeCycleId, vm.selectedLevelId, vm.selectedEntitiesIdList, vm.selectedPillarsIdList).then(function (response) {
                    exportFileService.exportExcel(response);

                }, function (error) {

                    //normal error
                    errorNotificationService.showErrorNotification(error);
                });
            }
           
        }])
}