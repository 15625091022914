export default function (app) {
    app.controller('loginController', LoginController);
    LoginController.$inject = ["$rootScope", "loginDataService", "$cookies", "CryptoService", "SplittingConstant", "$state", "Notification", "$uibModal", "$window", "$translate"];
    function LoginController($rootScope, loginDataService, $cookies, CryptoService, SplittingConstant, $state, Notification, $uibModal, $window, $translate) {
        var vm = this;
        $translate(["COMMON.REQUIRED_USERNAME_OR_PASSWORD", "COMMON.WRONG_USERNAME_OR_PASSWORD", "COMMON.USER_NOT_ACTIVE",
            "COMMON.ENTITY_NOT_ACTIVE", "COMMON.RESET_PASSWORD_MAIL_SENT","COMMON.EMAIL_NOT_EXIST_NOTIFICATION"]).then(function (translations) {
            vm.labels = {
                RequiredUsernameOrPassword: translations['COMMON.REQUIRED_USERNAME_OR_PASSWORD'],
                WrongUsernameOrPassword: translations['COMMON.WRONG_USERNAME_OR_PASSWORD'],
                UserNotActive: translations['COMMON.USER_NOT_ACTIVE'],
                EntityNotActive: translations['COMMON.ENTITY_NOT_ACTIVE'],
                ResetPasswordMailSent: translations['COMMON.RESET_PASSWORD_MAIL_SENT'],
                NotExistEmail: translations['COMMON.EMAIL_NOT_EXIST_NOTIFICATION'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.username = null;
        vm.password = null;
        vm.load = load;
        vm.login = login;
        vm.currentUser = $rootScope.GetCurrentUser();
        vm.openForgetPasswordModal = openForgetPasswordModal;
        vm.sendForgetPassword = sendForgetPassword;
        vm.loginForm = true;
        vm.load();
        function login() {

            vm.Loading();
            var credentails = vm.username + SplittingConstant + vm.password;
            var encryptedCredentials = CryptoService.EncryptAES(credentails).toString();
            loginDataService.Login({ credential: encryptedCredentials }).then(function (res) {
                if (res.responseCode === vm.ResponseCode.Success) {
                    $rootScope.setAlreadyLoggedFlag(true);
                    var LoginResultVM = res.responseObject;
                    $rootScope.SetCurrentUser(LoginResultVM);
					
                    if (LoginResultVM.CurrentRole === "MBRGI Viewer") {
                        vm.Loading();
                        $state.transitionTo('MBRGI.MBRGIAnnualAchievements');
                    }
                    else if (LoginResultVM.RoleSection === "MBRGI") {
                        vm.Loading();
                        $state.transitionTo('MBRGI.Dashboard');
                    }
                    else if (LoginResultVM.RoleSection === "Entity" && LoginResultVM.CurrentEntity !== null) {
                        vm.Loading();
                        $state.transitionTo('Entity.Dashboard');
                    }
                    else {
                        vm.Loading();
                        $state.transitionTo('Entity.SwitchEntities');
                    }
                    
                }
                else {
                    if (res.responseCode === vm.ResponseCode.WrongPassword || res.responseCode === vm.ResponseCode.WrongUserName) {
                        Notification.error({ message: vm.labels.WrongUsernameOrPassword });
                    }
                    else if (res.responseCode === vm.ResponseCode.DeActivated)
                    {
                        Notification.error({ message: vm.labels.UserNotActive });
                    }
                    else if (res.responseCode === vm.ResponseCode.InActiveEntity) {
                        Notification.error({ message: vm.labels.EntityNotActive });
                    }
                    else if (res.responseCode === vm.ResponseCode.Error){
                        $rootScope.methodPublicError();
                    }
                    vm.Loading();
                }
            }, $rootScope.methodPublicError);
        }
        function load() {
            if ($rootScope.GetCurrentUser()) {
                if (vm.currentUser.CurrentRole === "MBRGI Viewer") {
                  
                    $state.transitionTo('MBRGI.MBRGIAnnualAchievements');
                }
                else if (vm.currentUser.RoleSection === "MBRGI") {
                    $state.transitionTo('MBRGI.Dashboard');
                }
                else if (vm.currentUser.RoleSection === "Entity") {
                    if (vm.currentUser.CurrentRole !== null && vm.currentUser.CurrentRole !== "null")
                        $state.transitionTo('Entity.Dashboard');
                    else {
                        $state.transitionTo('Entity.SwitchEntities');
                    }
                }
                
            }
        }

        function openForgetPasswordModal() {
            var modalInstance = $uibModal.open({
              //  backdrop: "static",
                keyboard: true,
                animation: true,
               //backdropClick: true,
                templateUrl: 'ng/Modules/Common/Login/Views/forgetPassword.html',
                controller: 'forgetPasswordController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () {
                        return null;
                    }
                }
            });
            modalInstance.result.then(function (result) {
                if (result.Ok)
                    vm.load();
            });
        }
        function sendForgetPassword() {

            vm.Loading();
            loginDataService.SendForgetPasswordEmail(vm.username).then(sendForgetPasswordSuccess, $rootScope.methodPublicError);
        }
       
        function sendForgetPasswordSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.ResetPasswordMailSent });
            }
            else if (res.responseCode === vm.ResponseCode.NotExist) {
                Notification.error({ message: vm.labels.NotExistEmail });
            }
            else {
                $rootScope.methodPublicError(res);
            }
            vm.Loading();
          //  $uibModalInstance.close({ Close: false, Ok: true });

        }
    }
}