export default {
    EMPTY_LIST: 'List is empty',
    DEACTIVATE_USER: 'Deactivate user',
    WOULD_DEACTIVATE_USER: 'Are you sure you want to deactivate the user?',
    USER_DEACTIVATED: 'The user has been deactivated successfully',
    USER_ACTIVATED: 'The user has been activated successfully',
    CANT_DEACTIVATE: 'You cannot deactivate this user at this moment',
    SHOW_ALL: 'Show all',
    ALL_SECTIONS: 'All sections',
    ACTIVE: 'Active',
    INACTIVE: 'InActive',
    SEARCH: 'Search',
    SHARED: 'Shared',
    MBRGI: 'MBRGI',
    CARD_TYPE_USER: 'User',
    EMAIL_LABEL: 'E-mail',
    USER_NAME_LABEL: 'Username',
    PASSWORD_LABEL: 'Password',
    OLD_PASSWORD_LABEL: 'Old password',
    NEW_PASSWORD_LABEL: 'New password',
    RESET_PASSWORD_LABEL:'Reset Password',
    CONFIRM_PASSWORD_LABEL: 'Confirm password',
    CONFIRM_NEW_PASSWORD_LABEL: 'Confirm new password',
    ROLE_LABEL: 'Role',
    JOB_TITLE_LABEL: 'Job title',
    CONTACT_NUMBER_LABEL: 'Contact number',
    MAIN_COORDINATOR_LABEL: 'Main Coordinator',
    MAIN_ADMIN_LABEL:'Main Admin',
    CHANGE_PASSWORD_LABEL: 'Change password',
    ADD_NEW_USER_LABEL: 'Add new user',
    PERSONRAL_PICTURE_LABEL: 'Personal picture',
    EDIT_USER:'Edit user',
    CONTACT_NUMBER_PLACEHOLDER: '05x xxx xxxx | 0x xxx xxxx',
    REQUIRED_USERNAME_OR_PASSWORD: 'Empty username or password',
    WRONG_USERNAME_OR_PASSWORD: 'Wrong Username or password',
    USER_NOT_ACTIVE: 'This account has been deactivated',
    ENTITY_NOT_ACTIVE: 'This account has been deactivated',
    RESET_PASSWORD_MAIL_SENT: 'An email is sent to your account in order to create a new password.',
    EMAIL_NOT_EXIST: 'This email doesn’t exist',
    REQUIRED_EMAIL: 'Email is required.',
    REQUIRED_USER_NAME: 'Username is required.',
    REQUIRED_Comment: 'Comment is required.',
    REQUIRED_PASSWORD: 'Password is required.',
    REQUIRED_OLD_PASSWORD: 'Old password is required.',
    REQUIRED_NEW_PASSWORD: 'New password is required.',
    REQUIRED_CONFIRM_PASSWORD: 'Confirm password is required.',
    REQUIRED_CONFIRM_NEW_PASSWORD: 'Confirm new password is required.',
    REQUIRED_FIRST_NAME: 'First name is required.',
    REQUIRED_LAST_NAME: 'Last name is required.',
    REQUIRED_ROLE: 'Role is required.',
    REQUIRED_JOB_TITLE: 'Job title is required.',
    REQUIRED_CONTACT_NUMBER: 'Contact Number is required.',

    PASSWORD_CONFIRM_PASSWORD_NOT_THE_SAME: 'Password and confirm password are not the same',
    NEW_PASSWORD_CONFIRM_PASSWORD_NOT_THE_SAME: 'New password and confirm password are not the same',
    FORGOT_PASSWORD: 'Forgot Password?',
    LOGIN: 'Login',
    PROCEED: 'Proceed',
    PASSWORD_WRONG_FORMAT: 'Password Format must be More than 8 characters including 1 Upper case and 1 digit.',
    PERSONALE_INFO: 'Personal info',
    ADD_USER_PHOTO: 'Add user photo',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    SAVE: 'Save',
    ACTIONS: 'Actions',
    PLEASE_SELECT: 'Please select',
    PLEASE_SELECT_SECTION: 'Please select section before export',
    //Users
    USER_ADDED_NOTIFICATION: 'User has been successfully added',
    EMAIL_USED_NOTIFICATION: 'Email is previously used',
    CONFIRM_EMAIL_USE: 'The email is used before; we will use the previous profile data. Do you want to proceed?',
    USER_UPDATED_NOTIFICATION: 'User has been successfully updated',
    PIC_VALID_FORMAT: 'Picture valid formats should be jpeg or png with maximum size 2 MB',
    PIC_REQUIRED_VALID_FORMAT: 'Required field and picture valid formats should be jpeg or png with maximum size 2 MB',
    //LOGIN
    EMAIL_NOT_EXIST_NOTIFICATION: 'This email doesn’t exist',
    //User Profile
    CHANGED_PASSWORD_NOTIFICATION: 'The password has been updated successfully',
    OLD_PASSWORD_WRONG_NOTIFICATION: 'The old password is wrong',
    COULDNOT_UPDATE_PASSWORD_NOTIFICATION: 'System could not update the password',
    PERSONNAL_INFO_UPDATED_NOTIFICATION: 'Personal info is updated successfully',
    COULDNOT_UPDATE_PERSONNAL_INFO_NOTIFICATION: 'System could not update the Personal info',
    COULDNOT_UPLOAD_PIC_NOTIFICATION: 'System could not upload the picture',
    EXPIRED_LINK_NOTIFICATION: 'Link has been Expired',
    //Validations 
    ONLY_TEXT_VALIDATION: 'The field allows text only',
    EMAIL_FORMAT_VALIDATION: 'Invalid email format',
    CONTACT_NUMBER_FORMAT_VALIDATION: 'Invalid contact number format',
    MAX25_CHARACTER_VALIDATION: 'Maximum 25 characters allowed',
    MAX250_CHARACTER_VALIDATION: 'Maximum 250 characters allowed',
    MIN3_CHARACTER_VALIDATION: 'Minimum 3 characters allowed',
    ONLY_ENGLISH_VALIDATION: 'The field allows English only',
    ONLY_ARABIC_VALIDATION: 'The field allows Arabic only',

    //navigation
    ENTITY_PROFILE: 'Entity Profile',
    DASHBOARD: 'Dashboard',
    ANNUAL_ACHIEVEMENTS: 'Annual Achievements',
    SHARED_INTIATIIVES: 'Shared Initiatives',

    TASKS: 'Tasks',
    ENTITIES: 'Entities',
    ENTITIY: 'Entity',

    USER_MANAGEMENT: 'User Management',
    ENTITY_MANAGEMENT: 'Entity Management',
	COUNTRIES: 'Countries',
	LOOKUPS: 'Lookups',
    REPORTS: 'Reports',
    REQUEST: 'Editing Requests',
    SETTINGS: 'Settings',
    GENERAL_SETTINGS: 'General settings',
    ASSIGN_SECTION: 'Assign Section',
    ASSIGNED_SECTIONS: 'Assigned Sections',
    SECTIONS: 'Sections',
    SECTION: 'Section',
    BENEFICIARY_UNITS: 'Beneficiaries Units',
    CYCLES: 'Cycles',   
    CYCLE: 'Cycle',
    PILLARS: 'Pillars',
    PILLAR: 'Pillar',
    //header
    MY_PROFILE: 'My profile',
    CHANGE_PASSWORD: 'Change password',
    SWITCH_ENTITES: 'Switch entities',
    LOGOUT: 'Logout',
    SYSTEM_NOTIFICATIONS: 'System Notifications',
    CHANGE_LANGUAGE: 'Change Language',

    //dashboard
    CYCLE_END: 'Current cycle ending in',//Cycle End
    DATA_ENTRY: 'Data entry ending in',//Data Entry
    CUT_OFF: 'Cut-off deadline in',//Cut Off
    REMAINING_DAYS: 'Remaining days',
    ALL_TASKS: 'Total of all tasks',
    NEW: 'New',
    EDITING: 'Editing',
    INPROGRESS: 'In Progress',
    COMPLETED: 'Completed',

    REVIEWING_TASKS: 'Under review tasks',
    REVIEWING_TASKS_NEW: 'New',
    REVIEWING_TASKS_REVIEWING: 'Under review',
    REVIEWING_TASKS_REVIEWED: 'Reviewed',

    EDITING_TASKS:'Editing tasks',
    EDITING_TASKS_NEW:'New',
    EDITING_TASKS_EDITING: 'Editing',
    EDITING_TASKS_EDITED:'Edited',
    REVIEWING: 'Under review',
    CLOSED: 'Completed',
    MYTASKS: 'My tasks',
    PROCCESSING: 'Proccessing',
    DONE: 'Done',

    MBRGI_Annual_Achievements_Reports_Progress:'Annual Achievements Reports Progress',
    MBRGI_TOTAL_SUBMITTED: 'Total Submitted',
    MBRGI_REVIEWING: 'Under review',
    MBRGI_APPROVED: 'Approved',
    MBRGI_SENT_BACK: 'Sent back',
    YEAR_IN_REVIEW: new Date().getFullYear() + ' Year in review',

    HELLO: 'Hello',
    HISTORY: 'History',
    GENERAL_INSTRUCTIONS: 'General Instructions',

    //error notification
    CURRENT_CYCLE_EXIST:'The current cycle not finished yet',
    CYCLE_DATES_INVLAID: 'This Cycle dates invalid',
    CYCLE_DATES_OVERLAPED: 'This period is intersected with the previous cycle period. Please amend',
    CLOSED_CYCLE: 'Can not update closed cycle',
    CYCLE_EXIST: 'Cycle name is previously used',
    CANNOT_MODIFY_ANNUAL_ACHIEVEMENT: 'You can not modify annual achievement after submission',
    UNAUTHORIZED: 'You are unauthorized to view this page',
    EXPORT_REPORT_FAILURE:'Failed to export the file, you can try again later.',
    //15: 'This item already exist',
    NOT_COMPLETED_ANNUAL_ACHIEVEMENTS: 'Your annual achievements are not completed yet. Please complete all your data and submit it.',
    TASK_NOT_ASSIGNED_TO_YOU: 'This Task is no longer assigned to you.',
    FILL_ASSIGNEES: 'Please fill the assignees before adding',
    CYCLE_entry_DATES_OVERLAPED: 'This period is intersected with the previous cycle data entry period. Please amend',
    //TODO:DINA to be verified
    CYCLE_PAST_DATES: 'Prevoius date is not allowed',
    ERROR_OCCURRED: 'An error occurred.Please contact support with error number ',
    ERROR_GENERAL: 'An error occurred.Please contact support',
    LOGEDOUT: 'You have been logged out',
    DISCONNECTED: 'An error occurred.Please check your internet connectivity',

    //Common 
    ADDING: '{0} has been successfully added',
    UPDATING: '{0} has been successfully updated',
    DELETING: '{0} has been successfully deleted',
    DELETINGACTIVITY: 'Activity has been successfully deleted',
    SUBMITTING: 'Achievements have been successfully submitted',
    ASSIGNING: '{0} has been successfully assigned',
    SEND_MBRGISUBMIT:'Do you confirm sending annual achievement results to the entity?',
    MBRGISUBMITTING: 'The Entity Annual Achievements reviewing results has been successfully Submitted ',
    SAVING: '{0} has been successfully saved',
    SAVINGACTIVITY: '{0} has been successfully saved',
    APPROVING: '{0} has been successfully approved',
    SENDINGBACK: '{0} has been successfully sent back',
    REJECT: '{0} has been successfully Rejected',
    DATE_FORMAT_PLACEHOLDER: 'DD-MM-YYYY',
    DAYS: 'Days',
    ANNUAL_ACHIVEMENTS: 'Annual Achievements',
    SHOW_MORE: 'show more',
    USER_CHANGE_ROLE_NOTIFICATION: 'You aren’t allowed to change the user role as he has uncompleted tasks.',
    NAVIGATION_CONFIRMATION_MESSAGE: 'You are about to leave the task without saving your changes. Are you sure you want to proceed?',
    NAVIGATION_CONFIRMATION_HEADER: 'Did you save your changes?',
    ACTIVE_CYCLE: 'There is an active cycle',
    COMMENT: 'Comment',
    COMMENTS: 'Comments',
    NOT_SHARED_ACTIVITY: 'This activity is not shared with you',
    ADD_COMMENT: 'Send',
    RequestEditingMsg: 'Your Request has send been successfully',
    SectionHaveRequest: 'You already sent the request before',
    RequestEditingComment: 'Request Editing',
    Request: 'Request',
    NEXT: 'Next',
    PREVIOUS: 'Previous',
    SELECT_USER: 'Select User',
    NO_DATA: 'No data to show',



    //Notification

    Notification_Title: 'Notification List',
    Notification_SeeAll: 'See All ',
    Notification_SeeMore: 'Load More',
    RESTORED: '{0} has been successfully restored',
    RESTORE:'Restore',
    EMAIL_TEMPLATES: 'Email Templates',
    BACK: 'Back',
    UPDATED_USER_SETTINGS: 'Settings has been successfully updated',
    NOTIFICATIONS: 'Notifications',
    Notification_Expire: 'This notification no longer exists',
    Notification_Previous_Cycle:'A previous cycle notification that is no longer exists',
    ALL: 'All',
    NoMoreNotification:'there is no more Notification ',
    NUM_OF_REVIEWERS: ' Number of reviewers',
    OldCycleRestore:" can't restore Activity from old cycle", 

    USERS:'Users',
    VIEW: 'View',
    EDIT: 'Edit',
    ADD: 'Add',
    DOWNLOAD:'Download',   
    DELETE: 'Delete',
    OldCycleRestore: " can't restore Activity from old cycle", 
    FormBuilder: "Form Builder",
    Total_Benifecieries: 'Total Beneficiaries',
    Total_Cost: 'Total Cost',
    Total_Benifecieries_Summary: 'Total Beneficiaries of ',
    Total_Cost_Summary: 'Total Cost of ',
    AED: 'AED',
    Deleted_By: 'Deleted By',

    Download_Images: 'Download Images',
}

