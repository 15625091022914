export default {
    PAGE_TITLE: 'Editing requests',
    ENTITY_NAME: 'Entity name',
    SECTION_NAME: 'Section name',
    REQUEST_DATE: ' Request date time',
    REJECT: 'Rejected',
    APPROVE: 'Approved',
    SHOWALL: 'Show all',
    NEW: 'New',
    ACTIONMODELTITLE: 'Approve Or Reject',
    COMMENT: 'Comment',
    REJECT_BTN: 'Reject',
    APPROVE_BTN: 'Approve',
    RequestHaveAction: 'This request is already closed by another user',
    EntityDeactivated: 'You are not allowed to take an action on this request, as this entity is deactivated',
    NOTSubmitedERROR: 'You are not allowed to approve the editing request for this entity, as it’s under reviewing with an invalid data',
    APPROVECONFRMATION: 'You are about to approve an entity editing request that is under reviewing, and it will be automatically released from the user. Are you sure you want to proceed?',
    Request: 'Request',
    State: 'State',
    Take_Action:'Take Action',
}

