export default function (app) {
    app.directive('oneColumnChart', function () {

        return {

            restrict: 'E',
            template: '<div></div>',
            scope: {
                title: '@',
                language: '@',
                data: '=',
                isOneChart: '=',
                yAxisTitle: '@',
                categories: '=',
                subtitle: '@'
            },
            controller: ['$scope', function ($scope) {
            }],


            link: function (scope, element) {

                if (scope.isOneChart) {
                    element.addClass('w-100');
                } else {
                    element.addClass('w-50');
                }
                var IsArabic = false;
                if (scope.language == 'ar')
                    IsArabic = true;
                Highcharts.setOptions({
                    lang: {
                        thousandsSep: ','
                    }
                });

                Highcharts.chart(element[0], {
                    chart: {
                        type: 'column',
                        //events: {
                        //    load() {
                        //        var chart = this;
                        //        chart.update({
                        //            xAxis: {
                        //                tickPositioner: function () {
                        //                    var chart = this.chart,
                        //                        tickPositions = [];
                        //                    chart.series[0].data.forEach((point) => {
                        //                        tickPositions.push(point.x)
                        //                    })
                        //                    this.update({
                        //                        tickPositions: tickPositions
                        //                    })
                        //                }
                        //            }
                        //        })
                        //    }
                        //}
                    },

                    title: {
                        text: scope.title
                    },
                    legend: {
                        //rtl: IsArabic
                    },
                    plotOptions: {
                        column: {
                            align: 'center',
                            pointPadding: 0.2,
                            borderWidth: 0
                        },
                        series: {
                            align: 'center',
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px;font-weight: bold;">{point.key}</span><table>',
                        pointFormat: '<tr><td style="padding:0;font-weight: bold;"></td>' +
                            '<td style="padding:0"><b>{point.y} </b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    subtitle: {
                        text: scope.subtitle
                    },
                    xAxis: {
                        labels: {
                            useHTML: true,
                        },
                        categories: scope.categories,
                        crosshair: true

                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: scope.yAxisTitle
                        }
                    },

                    series: scope.data,
                    exporting: {
                        allowHTML: true,
                        chartOptions: {
                            chart: {
                                height: 600
                                //width:450,
                            },

                            legend: {
                                useHTML: true,
                                itemWidth: 200,
                                //rtl: IsArabic,
                                navigation: {
                                    enabled: false
                                },

                            }
                        },
                        filename: scope.title
                    }
                });

            }
        };
    });
}
