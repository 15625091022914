//Import controllers
import lookupsController from './Controllers/app.lookups.controller';
import updateController from './Controllers/app.update-lookup.controller';
import addController from './Controllers/app.add-lookup.controller';
import lookupEntriesController from './Controllers/app.lookups-entries.controller';
import predefinedLookupController from './Controllers/app.predefined-lookups.controller';
import lookupDeletedEntriesController from './Controllers/app.lookups-deleted-entries.controller';
import addEntryController from './Controllers/app.add-lookup-entry.controller';
import updateEntryController from './Controllers/app.update-lookup-entry.controller';
//Import services
import dataService from './Services/lookups.data.service';
//Import config
import './Localization';

export default function (app) {
	lookupsController(app);
    updateController(app);
	addController(app);
	lookupEntriesController(app);
	lookupDeletedEntriesController(app);
	addEntryController(app);
	updateEntryController(app);
    dataService(app);
    predefinedLookupController(app);
}