import { debug } from "util";
import { cloneDeep as _cloneDeep } from 'lodash';

export default function (app) {
    app.controller('switchEntitiesController', SwitchEntitiesController);
    SwitchEntitiesController.$inject = ["$rootScope", "entityDataService", "loginDataService", "$cookies", "$state", "Notification"];
    function SwitchEntitiesController($rootScope, entityDataService, loginDataService, $cookies, $state, Notification) {
        var vm = this;
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.currentUser = $rootScope.GetCurrentUser();
        vm.entityRoles = vm.currentUser.Roles;
        vm.Entities = null;
        vm.thumbPath = '';
        vm.load = load;
        vm.LoginToEntity = LoginToEntity;
        vm.getOptions = getOptions;

        vm.options = {
            displayPrevious: true,
            readOnly: true,
            value: 20,
            //skin: { type: 'simple', width: 2, color: '#2C3E50', spaceWidth: 0 },
            animate: { enabled: true, duration: 2000, ease: 'bounce' },
            bgColor: '#2C3E50',
            size: 50,
            barCap: 8,
            trackWidth: 16,
            barWidth: 4,
            trackColor: 'white',
            barColor: '#FFAE1A',
            textColor: 'white',
            fontSize: 'auto'
        };
        vm.load();
        function LoginToEntity(entityID) {
            vm.Loading();
            loginDataService.LoginToEntity(entityID, $rootScope.GetCurrentUser().ID).then(function (res) {
                if (res.responseCode === vm.ResponseCode.Success) {
                    var LoginResultVM = res.responseObject;
                    
                    $rootScope.SetCurrentUser(LoginResultVM.userInfo);
                        vm.Loading();
                        $state.transitionTo('Entity.Dashboard');
                }
                else {
                    vm.Loading();
                }
            }, $rootScope.methodPublicError);
        }
        function load() {
            vm.Loading();
            entityDataService.GetCurrentUserEntities().then(GetCurrentUserEntitiesSuccess, $rootScope.methodPublicError);
        }

        function GetCurrentUserEntitiesSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success && res.responseObject && res.responseObject.length > 0) {
                if (res.responseObject != null && res.responseObject.length == 1) {
                    vm.Loading();
                    vm.LoginToEntity(res.responseObject[0].id);
                } else {
                    vm.Entities = res.responseObject;
                    vm.thumbPath = res.thumbPath;
                    for (var i = 0; i < vm.Entities.length; i++) {
                        if (vm.Entities[i].thumbnail)
                            vm.Entities[i].thumbnail = vm.thumbPath+vm.Entities[i].thumbnail;
                    }
                    vm.Loading();
                }
            }
            else {
                $rootScope.Logout();
            }
        }
        function calcluateColor(value, total) {
            var value = Math.floor((value / total) * 100);
            var color;
            if (value < 30) {
                color = '#dc3545';
            } else if (value < 60) {
                color = '#ffc107';
            } else if (value < 100) {
                color = '#53c0a7';
            }
            return color;
        }
        function getOptions(value, totatl) {
            var options = _cloneDeep(vm.options);
            var color = calcluateColor(value, totatl);
            options.barColor = color;
            return options;
        }
    }
}