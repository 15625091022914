export default function (app) {
    app.factory("CryptoService", cryptoService);
    cryptoService.$inject = ["AES_Constants"];
    function cryptoService(AES_Constants) {
        function EncryptAES(plaintext) {
            var key = CryptoJS.enc.Utf8.parse(AES_Constants.AES_Keybytes);
            var iv = CryptoJS.enc.Utf8.parse(AES_Constants.AES_IV);
            var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plaintext), key,
                {
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                });

            return encrypted;
        }

        function DecryptAES(ciphertext) {

            var key = CryptoJS.enc.Utf8.parse(AES_Constants.AES_Keybytes);
            var iv = CryptoJS.enc.Utf8.parse(AES_Constants.AES_IV);

            var decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

            return decrypted.toString(CryptoJS.enc.Utf8);
        }
        return {
            EncryptAES: EncryptAES,
            DecryptAES: DecryptAES
        };
    }
}