export default {
    DATA_ENTRY: 'الأيام المتبقية لنهاية فترة إدخال البيانات',
    REMAINING_DAYS: '',
    CUT_OFF: 'الأيام المتبقية لتاريخ التوقف',
    ALL_TASKS: 'إجمالي المهام',
    NEW: 'جديد',
    TASKS: 'مهام',
    EDITING: 'تحت التعديل',
    REVIEWING: 'تحت المراجعة',
    CLOSED: 'تمت مراجعتها',
    MYTASKS: 'مهامي',
    PROCCESSING: 'بدء المعالجة',
    DONE: 'تم',
    YEAR_IN_REVIEW: new Date().getFullYear() + 'العام في مراجعة ',

}
