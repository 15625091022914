export default function (app) {
    app.factory('sectionsAssignmentService', ['apiBase', 'ApiPath', '$http', function (apiBase, ApiPath, $http) {
        var _controllerUrl = ApiPath + "Section/";
        var _sharedInitiativesControllerUrl = ApiPath + "SharedActivities/";
        var _getEntityAnnualAchievement = function () {
            return apiBase.Get(_controllerUrl + "GetUserDelegatedAnnualAchievement", null, null);
        }

        var _getSingleSectionDetails = function (sectionkey) {
            return apiBase.Get(_controllerUrl + "GetSingleSectionDetails", { sectionKey: sectionkey }, null);
        }

        var _getAddActivityForm = function (sectionkey) {
            return apiBase.Get(_controllerUrl + "GetAddActivityForm", { sectionKey: sectionkey }, null);
        }

        var _addActivity = function (sectionkey, editorID, reviewersIDs, formmodel) {
            return apiBase.Post(_controllerUrl + "AddActivity", { sectionKey: sectionkey, editorID: editorID, reviewersIDs: reviewersIDs }, formmodel);
        }

        var _getSectionActivities = function (sectionkey) {
            return apiBase.Get(_controllerUrl + "GetSectionActivities", { sectionKey: sectionkey }, null);
        }

        var _deleteActivity = function (activityID) {
            return apiBase.Post(_controllerUrl + "DeleteActivity", { activityID: activityID }, null);
        }

        var _assignSections = function (userId, sections) {
            return apiBase.Post(_controllerUrl + "AssignSections", { assignedTo: userId }, { Parameters: sections });
        }

        var _getSectionActivityDetails = function (activityID) {
            return apiBase.Get(_controllerUrl + "GetSectionActivityDetails", { activityID: activityID }, null);
        }

        var _getActivityHistory = function (activityID) {
            return apiBase.Get(_controllerUrl + "GetActivityHistory", { activityID: activityID }, null);
        }
        var _getSectionHistory = function (sectionkey) {
            return apiBase.Get(_controllerUrl + "GetSectionHistory", { sectionKey: sectionkey }, null);
        }
        var _getCommentsList = function (activityID) {
            return apiBase.Get(_sharedInitiativesControllerUrl + "GetSharedActivityComments", { activityID: activityID }, null);
        }
        var _getActivityAssigementInfo = function (activityID) {
            return apiBase.Get(_controllerUrl + "GetActivityAssigementInfo", { activityID: activityID }, null);
        }

        var _getSectionAssignementInfo = function (sectionkey) {
            return apiBase.Get(_controllerUrl + "GetSectionAssignementInfo", { sectionKey: sectionkey }, null);
        }

        var _reassignActivity = function (activityid, assignees) {
            return apiBase.Post(_controllerUrl + "ReassignActivity", { activityID: activityid }, assignees);
        }
        var _reopenActivity = function (activityid, assignees) {
            return apiBase.Post(_controllerUrl + "ReopenActivity", { activityID: activityid }, assignees);
        }

        var _reassignSingleSection = function (sectionkey, assignees) {
            return apiBase.Post(_controllerUrl + "ReassignSingleSection", { sectionKey: sectionkey }, assignees);
        }

        var _reopenSingleSection = function (sectionkey, assignees) {
            return apiBase.Post(_controllerUrl + "ReopenSingleSection", { sectionKey: sectionkey }, assignees);
        }
        var _getSectionDeletedActivities = function (sectionkey) {
            return apiBase.Get(_controllerUrl + "GetSectionDeletedActivities", { sectionKey: sectionkey }, null);
        }
        var _getSectionCommentsHistory = function (sectionKey) {
            return apiBase.Get(_controllerUrl + "GetSectionCommentsHistory", { sectionKey: sectionKey }, null);
        }

        var _getSectionDeletedActivitiesCount = function (sectionkey, cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionDeletedActivitiesCount", { sectionKey: sectionkey, cycleID: cycleID }, null);
        }
        var _restoreActivity = function (activityID) {
            return apiBase.Post(_controllerUrl + "RestoreActivity", { activityID: activityID }, null);
        }
        return {
            getEntityAnnualAchievement: _getEntityAnnualAchievement,
            getSingleSectionDetails: _getSingleSectionDetails,
            getAddActivityForm: _getAddActivityForm,
            addActivity: _addActivity,
            getSectionActivities: _getSectionActivities,
            deleteActivity: _deleteActivity,
            assignSections: _assignSections,
            getSectionActivityDetails: _getSectionActivityDetails,
            getActivityHistory: _getActivityHistory,
            getSectionHistory: _getSectionHistory,
            getActivityAssigementInfo: _getActivityAssigementInfo,
            getSectionAssignementInfo: _getSectionAssignementInfo,
            reassignActivity: _reassignActivity,
            reopenActivity: _reopenActivity,
            reassignSingleSection: _reassignSingleSection,
            reopenSingleSection: _reopenSingleSection,
            getSectionDeletedActivities: _getSectionDeletedActivities,
            getCommentsList: _getCommentsList,
            getSectionCommentsHistory: _getSectionCommentsHistory,
            getSectionDeletedActivitiesCount: _getSectionDeletedActivitiesCount,
            restoreActivity: _restoreActivity,
        }

    }]);
}