export default function (app) {
    app.controller('countryController', CountryController);

    CountryController.$inject = ["$scope", "$rootScope", "countryDataService", "$uibModal", "Notification", "$translate","$state"];
    function CountryController($scope, $rootScope, countryDataService, $uibModal, Notification, $translate, $state) {
        var vm = this;
        $translate(["COUNTRIES.COUNTRY_DELETED", "COUNTRIES.COUNTRY_DELETE_QUES", "COUNTRIES.DELETE_HEADER","COMMON.ACTIVE_CYCLE"]).then(function (translations) {
            vm.labels = {
                CountryDeleted: translations['COUNTRIES.COUNTRY_DELETED'],
                DeleteCountryQues: translations['COUNTRIES.COUNTRY_DELETE_QUES'],
                DeleteHeader: translations['COUNTRIES.DELETE_HEADER'],
                ACTIVE_CYCLE: translations['COMMON.ACTIVE_CYCLE'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.country = {};
        vm.Countries = [];
        vm.load = load;
        vm.getAllCountries = getAllCountries;
        vm.deleteCountry = deleteCountry;
        vm.openUpdateModal = openUpdateModal;
        vm.openAddModal = openAddModal;
        vm.Modal = null;
        vm.countriesFilter = countriesFilter;
        vm.currentCycle = null;
        vm.deletedCount = 0;
        vm.load();

        function load() {
            vm.getAllCountries();
        }

        function getAllCountries() {
            vm.Loading();
            countryDataService.GetAllCountries().then(getAllCountriesSuccess, $rootScope.methodPublicError);
        }
        function deleteCountry(countryID, ev) {
            vm.Loading();
            var labels = vm.labels;
                vm.Modal= $uibModal.open({
                  //  backdrop: "static",
                    keyboard: true,
                    size: 'md modal-dialog-centered',
                    animation: true,
                    //backdropClick: true,
                    templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
                    controller: function ($uibModalInstance, $rootScope) {
                        var vm = this;
                        vm.header = labels.DeleteHeader;
                        vm.message = labels.DeleteCountryQues;
                        vm.ok = function () {
                            $uibModalInstance.close({ Close: false, Ok: true });
                        };
                        vm.cancel = function () {
                            $uibModalInstance.close({ Close: true, Ok: false });
                        };
                    },
                    controllerAs: 'vm'
            });
            vm.Loading();
            vm.Modal.result.then(function (result) {
                    if (result.Ok) {
                        vm.Loading();
                        countryDataService.DeleteCountryByID(countryID)
                            .then(DeleteCountryByIDSuccess, $rootScope.methodPublicError);
                    }
            }, function (error) {
                //backdrop clicked or error
            });
        }
        function countriesFilter(item) {
            if (vm.search) {
                return item.NameEn.toLowerCase().includes(vm.search.toLowerCase()) || item.NameAr.includes(vm.search);
            }
            else
                return true;
        }

        function openUpdateModal(country) {
            vm.Modal = $uibModal.open({
              //  backdrop: "static",
                keyboard: true,
                size: 'lg',
                animation: true,
               //backdropClick: true,
                templateUrl: 'ng/Modules/MBRGI/Countries/Views/update-country.html',
                controller: 'updateCountryController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () { return country; }
                }
            });
            vm.Modal.result.then(function (result) {
                
                if (result.Ok)
                     vm.load();
            }, function (error) {
                //backdrop clicked or error
            });
        }
        function openAddModal() {
            vm.Modal = $uibModal.open({
              //  backdrop: "static",
                keyboard: true,
                size: 'lg',
                animation: true,
               //backdropClick: true,
                templateUrl: 'ng/Modules/MBRGI/Countries/Views/add-country.html',
                controller: 'addCountryController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () { }
                }
            });
            vm.Modal.result.then(function (result) {
                if (result.Ok)
                    vm.load();
            }, function (error) {
                //backdrop clicked or error
            });
        }
        function getAllCountriesSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.Countries = res.responseObject;
                vm.currentCycle = res.currentCycle;
                vm.deletedCount = res.deletedCount;
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function DeleteCountryByIDSuccess(res) {
            if (res.responseCode == vm.ResponseCode.Success) {
                Notification.success({ message: vm.labels.CountryDeleted });
                vm.Loading();
                vm.load();
            } else if (res.responseCode == vm.ResponseCode.ActiveCycleExist) {
                Notification.error({ message: vm.labels.ACTIVE_CYCLE });
                vm.Loading();
            }else {
                    $rootScope.methodPublicError();
                }

        }
        vm.goToTrash = function () {
            $state.transitionTo('MBRGI.RestoreCountries');
        }
        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
        });
        ///The below method is for activation and deactivation (no need for it in this phase)
        //function ChangeActiveStatusSuccess(res) {
        //    if (res.responseCode == vm.ResponseCode.Activated) {
        //        Notification.success({ message: vm.labels.countryActivated });

        //    } else if (res.responseCode == vm.ResponseCode.DeActivated) {
        //        Notification.success({ message: vm.labels.CountryDeactivated });
        //    }
        //    vm.Loading();
        //    vm.load();
        //}


        $scope.sortableOptions = {
            disabled: vm.IsDisableMode,
            placeholder: vm.IsDisableMode ? "" : "ui-state-highlight",
            stop: function (e, ui) {
                var IDs = [];
                vm.Countries.forEach((x) => { IDs.push(x.ID); });
                vm.Loading();
                countryDataService.UpdateCountriesOrder(IDs).then(function (response) {
                    vm.Loading();
                    if (response) {

                    } else {
                        $rootScope.methodPublicError();
                    }
                })
            }
        };

    }
}