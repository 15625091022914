import { map as _map, omit as _omit, toString as _toString, values as _values, filter as _filter, find as _find, cloneDeep as _cloneDeep } from "lodash"

export default function (app) {

    app.filter("cardWorkflowState", ['sectionStatusConstant', function (sectionStatusConstant) {
        return function (cards, state) {
            if (!state) {
                return cards;
            }
            return _filter(cards, (card) => {
                //if IN_PROGRESS is selected include sent back state also
                if (state == sectionStatusConstant.IN_PROGRESS) {
                    return card.workflowInfo.status == sectionStatusConstant.IN_PROGRESS || card.workflowInfo.status == sectionStatusConstant.SENT_BACK;
                }

                return card.workflowInfo.status == state;
            })
        };
    }]);


    app.controller("sectionAssignmentSubmissionsController", ['$scope', 'sectionsAssignmentService', 'uiLoadingIndicators', 'taskTypesConstant', 'sectionStatusConstant', 'sectionTypesConstant', 'errorNotificationService', '$stateParams', 'routeConstants', '$state', '$rootScope', '$uibModal', '$timeout', 'actionsTypesConstant', 'successrNotificationService', 'sectionsService', '$translate',
        function ($scope, sectionsAssignmentService, uiLoadingIndicators, taskTypesConstant, sectionStatusConstant, sectionTypesConstant, errorNotificationService, $stateParams, routeConstants, $state, $rootScope, $uibModal, $timeout, actionsTypesConstant, successrNotificationService, sectionsService, $translate) {
            var vm = this;
            var _viewCommentModal = null;
            var _viewRequestModal = null;
            $translate(["ACHIEVEMENT.NEW", 'ACHIEVEMENT.EDITING', 'ACHIEVEMENT.REVIEWING', 'ACHIEVEMENT.REVIEWED', 'ACHIEVEMENT.SENT_BACK',
                'ACHIEVEMENT.CREATE', 'ACHIEVEMENT.REASSIGN', 'ACHIEVEMENT.REOPEN', 'ACHIEVEMENT.EDITOR', 'ACHIEVEMENT.REVIEWER',
                'ACHIEVEMENT.SAVE', 'ACHIEVEMENT.SUBMIT', 'ACHIEVEMENT.APPROVE', 'ACHIEVEMENT.SENDBACK', 'ACHIEVEMENT.SUBMIT_ACHIEVEMENTS', 'ACHIEVEMENT.CONFIRM_SUBMIT_ACHIEVEMENTS',
                'ACHIEVEMENT.DELETE', 'ACHIEVEMENT.DELETEQUESTION']).then(function (translations) {
                    vm.labels = {
                        NEW: translations['ACHIEVEMENT.NEW'],
                        EDITING: translations['ACHIEVEMENT.EDITING'],
                        REVIEWING: translations['ACHIEVEMENT.REVIEWING'],
                        REVIEWED: translations['ACHIEVEMENT.REVIEWED'],
                        SENT_BACK: translations['ACHIEVEMENT.SENT_BACK'],
                        CREATE: translations['ACHIEVEMENT.CREATE'],
                        REASSIGN: translations['ACHIEVEMENT.REASSIGN'],
                        REOPEN: translations['ACHIEVEMENT.REOPEN'],
                        EDITOR: translations['ACHIEVEMENT.EDITOR'],
                        REVIEWER: translations['ACHIEVEMENT.REVIEWER'],
                        SAVE: translations['ACHIEVEMENT.SAVE'],
                        SUBMIT: translations['ACHIEVEMENT.SUBMIT'],
                        APPROVE: translations['ACHIEVEMENT.APPROVE'],
                        SENDBACK: translations['ACHIEVEMENT.SENDBACK'],
                        DELETE: translations['ACHIEVEMENT.DELETE'],
                        DELETEQUESTION: translations['ACHIEVEMENT.DELETEQUESTION'],
                        SUBMIT_ACHIEVEMENTS: translations['ACHIEVEMENT.SUBMIT_ACHIEVEMENTS'],
                        CONFIRM_SUBMIT_ACHIEVEMENTS: translations['ACHIEVEMENT.CONFIRM_SUBMIT_ACHIEVEMENTS'],
                    }
                });
            var _addActivityModalInstance = null;
            var _deleteConfirmationModal = null;
            var _viewHistoryModal = null;
            var _activityAssigementModal = null;
            var _confirmSubmitAchievementsModalInstance = null;

            vm.cards = [];
            vm.sectionStatistics = [];
            vm.taskTypesConstant = taskTypesConstant;
            vm.sectionStatusConstant = sectionStatusConstant;
            vm.sectionTypesConstant = sectionTypesConstant;
            vm.activitiesListStateFilter = undefined;
            vm.activitiesListTitleFilter = "";
            vm.textSearch = "";
            vm.isCycleEnabled = $stateParams.isCycleEnabled ? JSON.parse($stateParams.isCycleEnabled) : false;
            vm.isAddActivityBtnLoading = false;
            vm.comment = '';
            vm.deletedCount = 0;
            vm.sectionTitle = '';
            vm.activityID = $stateParams.activityID;
            vm.sectionkey = $stateParams.sectionID;
            vm.cycleID = $rootScope.cycleID;

            vm.isArabic = $translate.use() == 'ar' ? true : false;
            

            var _getSectionActivities = function () {
                uiLoadingIndicators.load('activities', true);
                sectionsAssignmentService.getSectionActivities($stateParams.sectionID, vm.cycleID).then(function (response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        if (_data && _data.cards && _data.cards.length > 0) {
                            vm.cards = _map(_data.cards, (card) => {
                                if (card.titleAr && vm.isArabic) {
                                    card.title = card.titleAr;
                                }
                                else {
                                    card.title = card.titleEn || '';
                                }
                                card.entityName = '';
                                return card;
                            });
                        }
                        vm.sectionStatistics = _data.sectionStatistics;
                        vm.sectionTitle = _data.sectionSingleTitle;
                        vm.sectionName = _data.sectionTitle;
                        vm.canEdit = _data.CanEdit;

                        vm.hasSectionComments = _data.HasSectionComments;
                        vm.SectionCommentsCount = _data.SectionCommentsCount;
                        vm.sectionTotals = _data.sectionTotals;
                    } else {
                        //TODO show error msg
                    }
                    uiLoadingIndicators.load('activities', false);
                }, function (error) {
                    uiLoadingIndicators.load('activities', false);
                })
            }

            var _deleteActivity = function (activityID) {
                sectionsAssignmentService.deleteActivity(activityID).then(function (response) {

                    if (response && response.responseCode == 1) {
                        //reload to reflect change in section state
                        $state.reload();
                        //TODO successfull message
                        successrNotificationService.showSuccessDeletingNotfication(vm.sectionTitle);
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, function (error) {
                    //TODO show error msg
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getisCycleEnabled = function () {
                return JSON.parse($stateParams.isCycleEnabled);
            }

            vm.getIsSectionEnabled = function () {
                //disabled by default
                if ($stateParams.sectionID) {
                    var _currentSection = sectionsService.getSection($stateParams.sectionID);
                    if (_currentSection)
                        return !!_currentSection.enabled;
                }
                return false

                //return true;
            }

            vm.getAddActivityForm = function () {
                vm.isAddActivityBtnLoading = true;
                sectionsAssignmentService.getAddActivityForm($stateParams.sectionID).then(function (response) {
                    var _labels = vm.labels;
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _sectionTitle = vm.sectionTitle;
                        if (document.getElementsByClassName("modal").length === 0)
                            _addActivityModalInstance = $uibModal.open({
                                backdrop: true,
                                keyboard: true,
                                backdropClick: true,
                                template: require('../Views/addActivityModal.html'),
                                size: 'lg',
                                controller: ['$uibModalInstance', function ($uibModalInstance) {
                                    var vm = this;
                                    var _data = response.responseObject;
                                    var _numberOfApprovals = _data.numberOfApprovals;
                                    vm.model = {};
                                    vm.addActivityForm = _data;

                                    vm.addActivityForm.formDescription.push(
                                        {
                                            type: 'select',
                                            key: 'selectedEditor',
                                            templateOptions: {
                                                label: _labels.EDITOR,
                                                required: true,
                                                notNull: true,
                                                options: _map(vm.addActivityForm.editors, (editor) => {
                                                    return { name: editor.name, value: editor.id }
                                                })
                                            }
                                        },
                                        {
                                            key: 'reviewers',
                                            wrapper: 'horizontalLayout',
                                            fieldGroup: []
                                        }
                                    )

                                    for (var i = 0; i < _numberOfApprovals; i++) {
                                        var _lenght = vm.addActivityForm.formDescription.length - 1;
                                        var _container = vm.addActivityForm.formDescription[_lenght];
                                        _container.fieldGroup.push(
                                            {
                                                type: 'select',
                                                templateOptions: {
                                                    label: _labels.REVIEWER,
                                                    required: true,
                                                    notNull: true,
                                                    options: _map(vm.addActivityForm.reviewers, (reviewer) => {
                                                        return { name: reviewer.name, value: reviewer.id }
                                                    })
                                                }
                                            }
                                        )
                                    }

                                    vm.ok = function () {
                                        //TODO show error msg
                                        vm.showAddActivityModalSpinner = true;
                                        var _editorID = vm.model.selectedEditor;
                                        var _reviewersArray = _values(vm.model.reviewers);
                                        var _reviewersidsAsCommaSeparated = _toString(_reviewersArray);
                                        var _modal = _omit(vm.model, ['selectedEditor', 'reviewers']);

                                        sectionsAssignmentService.addActivity($stateParams.sectionID, _editorID, _reviewersidsAsCommaSeparated, _modal)
                                            .then(function (response) {
                                                if (response && response.responseCode == 1 && response.responseObject) {
                                                    vm.showAddActivityModalSpinner = false;
                                                    $uibModalInstance.close();
                                                    //TODO add success message
                                                    successrNotificationService.showSuccessAddingNotfication(_sectionTitle);
                                                    $state.reload();
                                                } else {
                                                    //TODO show error msg
                                                    errorNotificationService.showErrorNotification(response.responseCode);
                                                }
                                            }, function (error) {
                                                vm.showAddActivityModalSpinner = false;
                                                errorNotificationService.showErrorNotification(error);
                                            })
                                    };
                                    vm.cancel = function (error) {
                                        vm.showAddActivityModalSpinner = false;
                                        $uibModalInstance.close();
                                    };
                                }],
                                controllerAs: 'vm'
                            });

                    } else {
                        //TODO show error
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                    vm.isAddActivityBtnLoading = false;

                }, function (error) {
                    errorNotificationService.showErrorNotification(error);
                    vm.isAddActivityBtnLoading = false;
                })
            }


            vm.getStatName = function (statType) {
                var _labels = vm.labels;
                if (statType == sectionStatusConstant.NEW) {
                    return _labels.NEW;
                } else if (statType == sectionStatusConstant.IN_PROGRESS) {
                    return _labels.EDITING;
                } else if (statType == sectionStatusConstant.SUBMITTED) {
                    return _labels.REVIEWING;
                }
                else if (statType == sectionStatusConstant.APPROVED) {
                    return _labels.REVIEWED;
                } else if (statType == sectionStatusConstant.SENT_BACK) {
                    return _labels.SENT_BACK;
                }
            }

            vm.isHighlightedText = function (statType, taskType) {
                var _edit = vm.taskTypesConstant.EDIT;
                var _review = vm.taskTypesConstant.REVIEW;
                if (taskType == _edit && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
                    return true;
                }

                if (taskType == _review && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
                    return true;
                }
            }

            vm.confirmDeleteActivity = function (card) {
                var _labels = vm.labels;
                if (document.getElementsByClassName("modal").length === 0)
                    _deleteConfirmationModal = $uibModal.open({
                        backdrop: true,
                        keyboard: true,
                        backdropClick: true,
                        template: require('../../../Common/Views/confirmation-popup.html'),
                        size: 'md',
                        controllerAs: 'vm',
                        controller: ['$uibModalInstance', function ($uibModalInstance) {
                            var vm = this;
                            vm.header = _labels.DELETE;
                            var _title = card.titleEn || card.titleAr;
                            vm.message = _labels.DELETEQUESTION + _title;

                            vm.ok = function () {
                                $uibModalInstance.close(card.workflowInfo.id);
                            }

                            vm.cancel = function () {
                                $uibModalInstance.dismiss();
                            }
                        }]
                    });

                _deleteConfirmationModal.result.then((activityID) => {
                    if (activityID) {
                        _deleteActivity(activityID);
                    }
                }, function (error) { })
            }

            vm.getActivityHistory = function (activityID) {
                var _labels = vm.labels;
                sectionsAssignmentService.getActivityHistory(activityID).then(function (response) {
                    if (response && response.responseCode == 1) {
                        if (document.getElementsByClassName("modal").length === 0)
                            _viewHistoryModal = $uibModal.open({
                                backdrop: true,
                                keyboard: true,
                                backdropClick: true,
                                template: require('../Views/historyModal.html'),
                                size: 'lg',
                                controllerAs: 'vm',
                                controller: ['$uibModalInstance', function ($uibModalInstance) {
                                    var vm = this;
                                    vm.actionsTypesConstant = actionsTypesConstant;
                                    vm.taskTypesConstant = taskTypesConstant;
                                    vm.history = response.responseObject;

                                    vm.getActionName = function (actionID) {
                                        if (actionID == actionsTypesConstant.SAVE) {
                                            return _labels.SAVE;
                                        } else if (actionID == actionsTypesConstant.SUBMIT) {
                                            return _labels.SUBMIT;
                                        } else if (actionID == actionsTypesConstant.APPROVE) {
                                            return _labels.APPROVE;
                                        }
                                        else if (actionID == actionsTypesConstant.SENDBACK) {
                                            return _labels.SENDBACK;
                                        }
                                    }

                                    vm.cancel = function () {
                                        $uibModalInstance.dismiss();
                                    }

                                }]
                            })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, function (error) {
                    //TODO show error
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.openActivityAssigementModal = function (card) {
                var _labels = vm.labels;
                var _activityID = card.workflowInfo.id;
                sectionsAssignmentService.getActivityAssigementInfo(_activityID).then(function (response) {
                    if (response && response.responseCode == 1) {
                        if (document.getElementsByClassName("modal").length === 0)
                            _activityAssigementModal = $uibModal.open({
                                backdrop: true,
                                keyboard: true,
                                backdropClick: true,
                                template: require('../Views/activityAssigementModal.html'),
                                size: 'lg',
                                controllerAs: 'vm',
                                controller: ['$uibModalInstance', function ($uibModalInstance) {
                                    var vm = this;
                                    var _data = response.responseObject;
                                    var _editors = _data.editors;
                                    var _reviewers = _data.reviewers;
                                    var _formModel = _data.formModel;
                                    var _selectedReviewersAndEditorModel = _cloneDeep(_data.model);
                                    var _numberOfApprovals = _data.numberOfApprovals;

                                    vm.showActivityAssigementModalSpinner = false;

                                    vm.getActionBtnName = function () {
                                        var _workflowStatus = card.workflowInfo.status;
                                        var _assignmentInfo = card.assignmentInfo;
                                        if (_assignmentInfo && _assignmentInfo.length >= 2) {
                                            if (_workflowStatus == sectionStatusConstant.NEW ||
                                                _workflowStatus == sectionStatusConstant.IN_PROGRESS ||
                                                _workflowStatus == sectionStatusConstant.SUBMITTED ||
                                                _workflowStatus == sectionStatusConstant.SENT_BACK) {
                                                return _labels.REASSIGN;
                                            } else if (_workflowStatus == sectionStatusConstant.APPROVED) {
                                                return _labels.REOPEN;
                                            }
                                        }
                                    }


                                    var _selectedReviewersModel = _filter(_selectedReviewersAndEditorModel, (item) => {
                                        return item.taskType == taskTypesConstant.REVIEW;
                                    });

                                    var _selectedReviewersIDs = _map(_selectedReviewersModel, (item) => {
                                        var _reviewer = _find(_reviewers, (reviewer) => reviewer.id == item.userID)
                                        return _reviewer ? item.userID : undefined;
                                    })

                                    var _selectedEditor = _find(_selectedReviewersAndEditorModel, (item) => {
                                        return item.taskType == taskTypesConstant.EDIT;
                                    })

                                    var _editor = _selectedEditor ? _find(_editors, (editor) => editor.id == _selectedEditor.userID) : undefined;

                                    var _selectedReviewersIDsAsObject = Object.assign({}, _selectedReviewersIDs);

                                    vm.model = Object.assign({},
                                        { reviewers: _selectedReviewersIDsAsObject },
                                        { selectedEditor: _editor ? _selectedEditor.userID : undefined },
                                        _formModel,
                                    );

                                    vm.formDescription = _data.formDescription;
                                    vm.formDescription2 = [];
                                    vm.formDescription2.push(
                                        {
                                            type: 'select',
                                            key: 'selectedEditor',
                                            templateOptions: {
                                                label: _labels.EDITOR,
                                                required: true,
                                                notNull: true,
                                                options: _map(_editors, (editor) => {
                                                    return { name: editor.name, value: editor.id }
                                                })
                                            }
                                        },
                                        {
                                            key: 'reviewers',
                                            wrapper: 'horizontalLayout',
                                            fieldGroup: []
                                        }
                                    );

                                    for (var i = 0; i < _numberOfApprovals; i++) {
                                        var _lenght = vm.formDescription2.length - 1;
                                        //passed by ref
                                        var _container = vm.formDescription2[_lenght];
                                        _container.fieldGroup.push(
                                            {
                                                type: 'select',
                                                templateOptions: {
                                                    label: _labels.REVIEWER,
                                                    required: true,
                                                    notNull: true,
                                                    options: _map(_reviewers, (reviewer) => {
                                                        return { name: reviewer.name, value: reviewer.id }
                                                    })
                                                }
                                            }
                                        )
                                    }

                                    vm.ok = function () {
                                        vm.showActivityAssigementModalSpinner = true;
                                        var updatedModel = _map(_selectedReviewersModel, (item, index) => {
                                            var _updatedReviewerID = vm.model.reviewers[index];
                                            item.userID = _updatedReviewerID;
                                            return item;
                                        })
                                        _selectedEditor.userID = vm.model.selectedEditor;
                                        updatedModel.unshift(_selectedEditor);

                                        var _updateAssigementPromise = null;

                                        if (card.workflowInfo.status == sectionStatusConstant.APPROVED) {
                                            _updateAssigementPromise = sectionsAssignmentService.reopenActivity(_activityID, updatedModel);
                                        } else {
                                            _updateAssigementPromise = sectionsAssignmentService.reassignActivity(_activityID, updatedModel);
                                        }

                                        _updateAssigementPromise.then((resp) => {
                                            if (resp && resp.responseCode == 1) {
                                                _getSectionActivities();
                                            } else {
                                                //show error msg
                                                errorNotificationService.showErrorNotification(resp.responseCode);
                                            }
                                            vm.showActivityAssigementModalSpinner = false;
                                            $uibModalInstance.close();
                                        }).catch((err) => {
                                            //TODO show error
                                            errorNotificationService.showErrorNotification(error);
                                            $uibModalInstance.close();
                                        })
                                    }

                                    vm.cancel = function () {
                                        vm.showActivityAssigementModalSpinner = false;
                                        $uibModalInstance.close();
                                    }
                                }]
                            })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, function (error) {
                    //TODO show error msg
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.submitAnnualAchievementAction = function () {
                var _labels = vm.labels;
                if (document.getElementsByClassName("modal").length === 0)
                    _confirmSubmitAchievementsModalInstance = $uibModal.open({
                        backdrop: true,
                        keyboard: true,
                        backdropClick: true,
                        template: require('../../../Common/Views/confirmation-popup.html'),
                        size: 'lg',
                        controllerAs: 'vm',
                        controller: ['$uibModalInstance', function ($uibModalInstance) {
                            var vm = this;
                            vm.message = _labels.CONFIRM_SUBMIT_ACHIEVEMENTS;
                            vm.header = _labels.SUBMIT_ACHIEVEMENTS;
                            vm.isConfirmationAction = true;

                            vm.ok = function () {
                                $uibModalInstance.close();
                            }

                            vm.cancel = function () {
                                $uibModalInstance.dismiss();
                            }
                        }]
                    })

                _confirmSubmitAchievementsModalInstance.result.then(() => {
                    //TODO check prams
                    sectionsAssignmentService.submitAnnualAchievementAction($rootScope.GetCurrentEntityID(), actionsTypesConstant.SUBMIT, '').then(function (response) {
                        if (response && response.responseCode == 1) {
                            //TODO: Display success
                            successrNotificationService.showSuccessSubmittingNotfication();
                            $state.go(routeConstants.EntityDashboardState.State);
                        }
                        else {
                            errorNotificationService.showErrorNotification(response.responseCode);
                        }
                    }, function (error) {
                        //TODO: handle error
                        errorNotificationService.showErrorNotification(error);
                    })

                }, function (error) { })

            }


            var _getSectionDeletedActivitiesCount = function () {
                sectionsAssignmentService.getSectionDeletedActivitiesCount($stateParams.sectionID, vm.cycleID).then(function (response) {
                    if (response && response.responseCode == 1) {
                        vm.deletedCount = response.responseObject
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, function (error) {
                    //TODO show error msg
                    errorNotificationService.showErrorNotification(error);
                })
            }
            var _getSectionDeletedActivities = function () {
                uiLoadingIndicators.load('activities', true);
                sectionsAssignmentService.getSectionDeletedActivities($stateParams.sectionID, vm.cycleID).then(function (response) {
                    uiLoadingIndicators.load('activities', false);
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        if (_data && _data.cards && _data.cards.length > 0) {
                            vm.cards = _map(_data.cards, (card) => {
                                card.title = card.titleEn || card.titleAr || '';
                                card.entityName = '';
                                return card;
                            });
                            vm.sectionTitle = _data.sectionSingleTitle;
                        }
                    } else {
                        vm.Back();
                    }
                }, function (error) {
                    uiLoadingIndicators.load('activities', false);
                })
            }
            vm.restoreActivity = function (activityID) {
                uiLoadingIndicators.load('activities', true);
                sectionsAssignmentService.restoreActivity(activityID).then(function (response) {
                    uiLoadingIndicators.load('activities', false);
                    if (response && response.responseCode == 1) {
                        //reload to reflect change in section state
                        $state.reload();
                        successrNotificationService.showSuccessRestoringNotfication(vm.sectionTitle);
                    }
                    if (response && response.responseCode == 17) {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, function (error) {
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getSectionComments = function () {

                sectionsAssignmentService.getSectionCommentsHistory(vm.sectionkey, $rootScope.cycleID).then(function (response) {
                    if (response && response.responseCode == 1) {
                        var _comments = response.responseObject;
                        var sectionKey = vm.sectionkey;
                        var _self = vm;
                        if (document.getElementsByClassName("modal").length === 0)
                            _viewCommentModal = $uibModal.open({
                                keyboard: true,
                                animation: true,
                                template: require('../Views/commentsModal.html'),
                                size: 'lg',
                                controllerAs: 'vm',
                                controller: ['$rootScope', '$uibModalInstance', 'sectionsAssignmentService', function ($rootScope, $uibModalInstance, sectionsAssignmentService) {
                                    var vm = this;
                                    vm.loading = $rootScope.loading;
                                    vm.RepliedComments = null;

                                    vm.sectionComments = _comments.UsersComments;
                                    vm.CanAddComment = _comments.CanAddComment;
                                    vm.addComment = function () {
                                        // $rootScope.loading();
                                        sectionsAssignmentService.submitEntitySectionComment(sectionKey, vm.replyComment).then((res) => {
                                            if (res) {
                                                var today = new Date().toISOString();
                                                var newComment = { Comment: vm.replyComment, FullName: $rootScope.GetCurrentUser().FullName, ActionDataTime: today, ImagePath: $rootScope.GetCurrentUser().ImagePath };
                                                vm.sectionComments.splice(0, 0, newComment);
                                                _self.SectionCommentsCount++;
                                            }
                                            // $rootScope.loading();
                                        }, function (error) {
                                            errorNotificationService.showErrorNotification(error);
                                        })

                                    }
                                    vm.cancel = function () {
                                        $uibModalInstance.dismiss();
                                    }

                                }]
                            })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                }, function (error) {
                    //TODO show error
                    errorNotificationService.showErrorNotification(error);
                })
            }

            var _init = function () {
                if ($state.$current.name == 'Entity.SectionsAssignment.DeletedSubmissions') {
                    _getSectionDeletedActivities();
                }
                else {
                    _getSectionActivities();
                    _getSectionDeletedActivitiesCount();
                }
            }()
            vm.goToDeleted = function () {
                $state.go("Entity.SectionsAssignment.DeletedSubmissions", { sectionID: $state.params.sectionID, isCycleEnabled: $state.params.isCycleEnabled });
            }
            vm.Back = function () {
                $state.go("Entity.SectionsAssignment.Submissions", { sectionID: $stateParams.sectionID, isCycleEnabled: $stateParams.isCycleEnabled });
            }
            $scope.$on('$destroy', function () {
                if (_addActivityModalInstance && _addActivityModalInstance.close) {
                    _addActivityModalInstance.close();
                }

                if (_deleteConfirmationModal && _deleteConfirmationModal.close) {
                    _deleteConfirmationModal.close();
                }

                if (_viewHistoryModal && _viewHistoryModal.close) {
                    _viewHistoryModal.close();
                }

                if (_activityAssigementModal && _activityAssigementModal.close) {
                    _activityAssigementModal.close();
                }

                if (_confirmSubmitAchievementsModalInstance && _confirmSubmitAchievementsModalInstance.close) {
                    _confirmSubmitAchievementsModalInstance.close();
                }
                if (_viewCommentModal && _viewCommentModal.close) {
                    _viewCommentModal.close();
                }
                if (_viewRequestModal && _viewRequestModal.close) {
                    _viewRequestModal.close();
                }
            });

        }])
}