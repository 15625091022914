import { Transition } from "@uirouter/core";

export default function (app) {
    app.controller('resetPasswordController', ResetPasswordController);
    ResetPasswordController.$inject = ["$rootScope", "loginDataService", "Notification", "$state", "$stateParams","$translate"];
    function ResetPasswordController($rootScope, loginDataService, Notification, $state, $stateParams, $translate) {
        var vm = this;
        $translate(["COMMON.CHANGED_PASSWORD_NOTIFICATION", "COMMON.EMAIL_NOT_EXIST_NOTIFICATION","COMMON.EXPIRED_LINK_NOTIFICATION"]).then(function (translations) {
            vm.labels = {
                PasswordUpdated: translations['COMMON.CHANGED_PASSWORD_NOTIFICATION'],
                NotExistEmail: translations['COMMON.EMAIL_NOT_EXIST_NOTIFICATION'],
                LinkExpired: translations['COMMON.EXPIRED_LINK_NOTIFICATION'],
            }
        });
        vm.Credential = $stateParams.Credential;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.Loading = $rootScope.Loading;
        vm.resetPasswordDTO = {};
        vm.validResetPassword = false;
        vm.load = load;
        vm.resetPassword = resetPassword;
        vm.load();

 
        function load() {
            vm.Loading();
            loginDataService.ValidateResetPassword(vm.Credential)
                .then(validateResetPasswordSuccess, $rootScope.methodPublicError);
        }

        function resetPassword() {
            vm.Loading();
            loginDataService.ResetPassword(vm.resetPasswordDTO)
                .then(resetPasswordSuccess, $rootScope.methodPublicError);
        }
        function validateResetPasswordSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success && res.responseObject !== null) {
                vm.validResetPassword = true;
                vm.resetPasswordDTO = res.responseObject;
                vm.Loading();
            } else {
                //link expired
                if (res.responseCode === vm.ResponseCode.LinkExpired) {
                    Notification.error({ message: vm.labels.LinkExpired });
                    vm.Loading();
                    $state.transitionTo('Login');
                }                        //the user not exist 
                else if (res.responseCode === vm.ResponseCode.NotExist) {
                    Notification.error({ message: vm.labels.NotExistEmail });
                    vm.Loading();
                }
                else {
                    $rootScope.methodPublicError();
                }
            }


        }
        function resetPasswordSuccess(res) {
            vm.Loading();
            if (res) {
                Notification.success({ message: vm.labels.PasswordUpdated });
                $state.transitionTo('Login');
            } else {

                $rootScope.methodPublicError();
            }
        }

    }
}