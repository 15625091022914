//Import controllers
import entityProfileController from './Controllers/app.entity-profile.controller';
//Import services
import entityProfileDataService from './Services/entityprofile.data.service';
import entityDataService from '../../MBRGI/Entities/Services/entity.data.service';
//Import config
import './Localization';

export default function (app) {
	entityProfileController(app);
    entityProfileDataService(app);
    entityDataService(app);
}