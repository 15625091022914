
export default function (app) {
    app.factory('manageSectionDataService', ManageSectionDataService);

    ManageSectionDataService.$inject = ['apiBase', 'ApiPath'];
    function ManageSectionDataService(apiBase, ApiPath) {
    	var name = "FormBuilder";
        var controllerUrl = ApiPath + name + "/";

        function _getAllSections() {
        	return apiBase.Get(controllerUrl + "GetAllSections", null, null);
        }
        function _updateSectionsOrder(orderedSectionsID) {
            return apiBase.Post(controllerUrl + "UpdateSectionsOrder", null, orderedSectionsID);
        }
        function _getSectionByID(SectionId) {
            return apiBase.Get(controllerUrl + "GetSectionForUpdate", { sectionID: SectionId}, null);
        }
        function _setSectionStatus(SectionId, isDeleted) {
            return apiBase.Get(controllerUrl + "SetSectionStatus", { sectionID: SectionId, isDeleted: isDeleted}, null);
        }
        function _updateSection(section) {
            return apiBase.Post(controllerUrl + "UpdateSection",  null, section);
        } 
        function _IsSectionsEditable() {
            return apiBase.Get(controllerUrl + "IsSectionsEditable", null, null);
        } 
        return {
            GetAllSections: _getAllSections,
            UpdateSectionsOrder: _updateSectionsOrder,
            getSectionByID: _getSectionByID,
            SetSectionStatus: _setSectionStatus,
            UpdateSection: _updateSection,
            IsSectionsEditable: _IsSectionsEditable,
        }
    } 
}