export default function (app) {
    app.directive('uiLoadingIndicators', ["uiLoadingIndicators", function (uiLoadingIndicators) {
        return {
            restrict: 'A',
            controller: ['$scope', function ($scope) {
                this.isLoading = false;
            }],
            link: function (scope, iElement, attrs, controller) {
                function onLoadingChange() {
                    controller.isLoading = uiLoadingIndicators.isLoading(scope.$eval(attrs.uiLoadingIndicators));
                }
                //listen to loading changes
                let unsubscribe = uiLoadingIndicators.subscribeToLoadingChange(function () {
                    onLoadingChange();
                });

                //listen to indicators list
                attrs.$observe('uiLoadingIndicators', function () {
                    onLoadingChange();
                });

                scope.$on('destroy', function () {
                    unsubscribe();
                });

                //initialize loading state
                onLoadingChange();

            }
        }
    }]);
}