export default {
    FORM_ELEMENTS: 'Form elements',
    MAIN_ELEMENTS: 'Main elements',
    ELEMENTS_PROPERITIES: "Elements Properities",
    PREVIEW: 'Preview',
    CLEAR: 'Clear',
    SAVE: 'Save',
    PLACEHOLDER: 'Placeholder',
    REQUIRED: 'Required',
    DELETE: 'Delete',
    OPTIONS: 'Options',
    KEY: 'Field key',
    REQUIRED_ERROR_MESSAGE: 'This field is required',
    IS_NUMBER_ERROR_MESSAGE: 'Value should be number',
    IS_UNIQUE_KEY_ERROR_MESSAGE: 'This key already exists',
    BINDING_ERROR_MESSAGE: 'This Key is deleted and linked to a different field type',
    RESTORE_ERROR_MESSAGE: 'This Key is deleted do you want to restore it?',
    ADDITIONAL_ELEMENTS: 'Additional elements',
    MSG_ADDED_SUCCESSFULLY: 'Form saved successfully',
    ///Common 
    FIELD_SystemKey: 'System Key',
    FIELDNAME_En: 'Label (English)',
    FIELDNAME_Ar: 'Label (Arabic)',
    Description_En: 'Field Hint (English)',
    Description_Ar: 'Field Hint (Arabic)',
    Placeholder_En: 'Place holder (English)',
    Placeholder_Ar: 'Place holder (Arabic)',
    IsShortForm: 'Is Short Form Field', 
    ShortFormDisplayOrder: 'Short Form Display Order',
    ReadOnly: 'Read only',
    Pattern: 'Pattern',
    
    Pattern_Ar: 'Pattern validation message (Arabic) - Required in case of pattern',
    Pattern_En:'Pattern validation message (English) - Required in case of pattern',
    FIELD_Expression: 'Hide Expression',
    REQUIRED_systemKey: 'The field  is required.',
    Pattern_SystemKey: 'system Key must start with character.',
    ONLY_ENGLISH_VALIDATION: 'The field allows English only',
    ONLY_ARABIC_VALIDATION: 'The field allows Arabic only',
    min_VALIDATION: 'The field min is 1  ',
    CardKey: 'Card Key',
    Display_Mode: 'Display Mode',
    Full_Display_Mode: 'Full',
    Half_Display_Mode: 'Half',
    Default_Value: 'Default Value',


    //filesPicker
    FilePicker:'Multiple files picker',

    //Group 
    Group_Type: 'Type',
    //Text input
    TEXTBOX: 'Textbox',
    TEXTBOX_LABEL: 'Input field name',
    //numericInput
    NUMBERFIELD: 'Numeric Field',
    NUMBERFIELD_LABEL: 'Numeric input field name',
    Numberformat: 'Number format',
    NumberformatMsgAr: 'Number format validation message  (Arabic)',
    NumberformatMsgEn: 'Number format validation message  (English)',
    Minimumvalue: 'Minimum value',
    MinimuMsgEN: 'Minimum value validation message (English)', 
    MinimuMsgAr: 'Minimum value validation message (Arabic)', 
    Maximumvalue: 'Maximum value',
    MaximumMsgEN: 'Maximum value validation message (English)',
    MaximumMsgAr: 'Maximum value validation message (Arabic)', 
    Integer: "Integer",
    Decimal: "Decimal",
    //TextArea
    TEXTAREA: 'Textarea',
    TEXTAREA_FIELDNAME_En: 'Label (English)',
    TEXTAREA_FIELDNAME_Ar: 'Label (Arabic)',
    TEXTAREA_Desription_En: 'Field Hint (English)',
    TEXTAREA_Desription_Ar: 'Field Hint (Arabic)',
    TEXTAREA_ROW_NUMBER: 'Number of rows',
    TEXTAREA_IsShortFormField: 'IsShortFormField',
    TEXTAREA_ShortFormDisplayOrder: 'ShortFormDisplayOrder',
    TEXTAREA_Placeholder_En: 'Place holder (English)',
    TEXTAREA_Placeholder_ar: 'Place holder (Arabic)',
    TEXTAREA_Expression: 'Hide Expression',
    TEXTAREA_DefaultValue: 'Default Value',
    TEXTAREA_ReadOnly: 'Read only',
    TEXTAREA_Pattern: 'Pattern',
    TEXTAREA_Pattern_En: 'Pattern validation message (English)',
    TEXTAREA_Pattern_Ar: 'Pattern validation message (Arabic)',
    //select
    SELECT: 'Dropdown menu',
    SELECT_FIELDNAME: 'Dropdown menu field name',
    SELECT_OPTIONS: 'Type',
    SELECT_OPTIONS_searchable: 'searchable',
    Select_Lookups: 'Data Source',
    nullDisplay_En: 'Null item (English)',
    nullDisplay_Ar: 'Null item (Arabic)',
    AllowNull: 'Don\'t Allow Null',
    //RadioButton
    RADIOBUTTON: 'Radio button',
    RADIOBUTTON_OPTIONS: 'first choice\nsecond choice',
    RADIOBUTTON_FIELDNAME: 'Radio button field name',
    RADIOBUTTON_INLINE: 'In Line',
    //Checkbox
    CHECKBOX: 'Checkbox',
    CHECKBOX_FIELDNAME: 'Checkbox field name',
    //Multicheckbox
    MULTICHECKBOX: 'Multi checkbox',
    MULTICHECKBOX_FIELDNAME: 'Multicheckbox field name',
    MULTICHECKBOX_OPTIONS: 'first choice\nsecond choice',
    //GregorianDatePicker
    GREGORIAN_DATEPICKER: 'Calendar',
    GREGORIAN_DATEPICKER_FIELDNAME: 'Date picker field name',
    GREGORIAN_DATETYPE: 'Date picker type',
    GREGORIAN_DATETYPEABSOLUTE: 'Absolute',
    GREGORIAN_DATETYPERELATIVE: 'Relative',
    GREGORIAN_DATESTART: 'Start',
    GREGORIAN_DATEEND: 'End',
    //HijriDatePicker
    HIJRI_DATEPICKER: 'Hijri Calendar',
    HIJRI_DATEPICKER_FIELDNAME: 'Date picker field name',
    HIJRI_DATETYPE: 'Date picker type',
    HIJRI_DATESTART: 'Start',
    HIJRI_DATEEND: 'End',
    //map
    MAP: 'Map',
    MAP_FIELDNAME: 'Map name',
    //ImagePicker
    IMAGE_PICKER: 'ImagePicker',
    IMAGE_PICKER_LABEL: 'Image picker field name',
    Files_extensions: 'Files extensions',
    Files_Size: 'Maximum size (MB)',
    Files_Extensions_MaximumMsgEN: 'File extensions validation message (English)',
    Files_Extensions_MaximumMsgAr: 'File extensions validation message (Arabic)',
    Files_Size_MaximumMsgEN: 'File size validation message (English)',
    Files_Size_MaximumMsgAr: 'File size validation message (Arabic)',
    //Signature
    SIGNATURE: 'Signature',
    SIGNATURE_LABEL: 'Signature field name',
    //SubForm
    SUBFORM: 'SubForm',
    //Fields aggregator
    Fields_Aggregator: 'Fields aggregator',
    Aggregation_expression: 'Aggregation expression',
    WIZARD_NEXT: 'Next',
    WIZARD_PREV: 'Prev',
    WIZARD_NO_OF_STEPS: 'No. of Steps',
    //repeaterFieldsAggregator
    repeaterFieldsAggregator: 'Field group repeater aggregator',
    source:'Computation Source',
    //RepeatSection
    REPEAT_SECTION: 'Field group repeater',
    REPEAT_SECTION_LABEL: 'Repeat section field name',
    REPEAT_SECTION_TYPE: 'Repeat Section Type',
    REPEAT_SECTION_TYPE_TABLE: 'Table',
    REPEAT_SECTION_TYPE_CARD: 'Card',
    REPEAT_SECTION_TYPE_DISABLED: 'Disabled',
    //ResponsiveLayout
    RESPONSIVE_LAYOUT: 'Responsive Layout',
    RESPONSIVE_LAYOUT_Field_Width: 'Approximate Width',
    //CollabsibleRadioList
    COLLABSIBLE_RADIO_LIST: 'Requirements',
    COLLABSIBLE_RADIO_LIST_LABEL: 'Requirements title',
    COLLABSIBLE_RADIO_LIST_ITEM: 'Requirement',
    NEW_COLLABSIBLE_RADIO_LIST_ITEM: 'New requirement',
    ///fieldGroupRepeater
    cardTitle_EN: 'Item title (English)',
    cardTitle_AR:'Item title (Arabic)',
    btnText_EN: 'Add new button name (English)',
    btnText_AR: 'Add new button name (Arabic)',
    
    NONE_Value: 'None',
    EmtpyRow_Value: 'empty Row',
    //Field group
    FieldGroup: 'Field group',


    //Entities
    Entities: 'Entities',
    Mandatory: 'Mandatory',
    Visible: 'Visible',


    //layout
    Panellayout:'Vertical layout ',
    Horizontallayout: 'Horizontal layout',
    DuplicateFieldSystemKey: 'System key is previously used',



    //aside tabs
    Properties: 'Properties',
    Permissions: 'Permissions',
    DELETED: 'you cannot  Delete this Filed ',
    CannotClearForm: 'you cannot clear all the fileds ',

    ///
    ConfirmationHeader:'Confirmation',
    ConfirmationMsg: 'Editing may cause missing important values for the annual achievements reports. Are your sure you want to proceed? ',
    WarningMsg: 'Please Check your input',
    Englishtitle: 'English title',
    Arabictitle: 'Arabic title',
    // pattern options 
    Email: 'Email',
    English_Only: 'English only',
    Arabic_Only: 'Arabic only',
    Website_Link: 'Website link',
    Alphabetic: 'Alphabetic',
    Phone: 'Phone',
    Others: 'Others',
    repeaterWarning:'you cannot drag this item case it  may affect in reports'

}