import MBRGISectionReportController from './SectionsReport/app.MBRGI-Sectionreport.controller';
import EntitySectionReportController from './SectionsReport/app.Entity-SectionReport.controller';
import MBRGIComparisonReport from './ComparisonReport/app.MBRGI-ComparisonReport.controller';
import EntityComparisonReport from './ComparisonReport/app.Entity-ComparisonReport.controller';
import EntityCountriesReportController from './CountriesReport/app.Entity-CountriesReport.controller';
import MBRGICountriesReportController from './CountriesReport/app.MBRGI-CountriesReport.controller';
import MBRGIAwardsReport from './AwardsReport/app.MBRGI-AwardsReport.controller';
import EntityAwardsReport from './AwardsReport/app.Entity-AwardsReport.controller';
import EntityScholarshipsReport from './ScholarshipsReport/app.Entity-ScholarshipsReport.controller';
import MBRGIScholarshipsReport from './ScholarshipsReport/app.MBRGI-ScholarshipsReport.controller';
import EntityBeneficiariesReport from './BeneficiariesReport/app.Entity-BeneficiariesReport.controller';
import MBRGIBeneficiariesReport from './BeneficiariesReport/app.MBRGI-BeneficiariesReport.controller';
import MBRGIFinancialReport from './FinancialReport/app.MBRGI-FinancialReport.controller';
import EntityFinancialReport from './FinancialReport/app.Entity-FinancialReport.controller';
export default function (app) {
    MBRGISectionReportController(app);
    EntitySectionReportController(app);
    MBRGIComparisonReport(app);
    EntityComparisonReport(app);
    MBRGICountriesReportController(app);
    EntityCountriesReportController(app);
    MBRGIAwardsReport(app);
    EntityAwardsReport(app);
    EntityScholarshipsReport(app);
    MBRGIScholarshipsReport(app);
    EntityBeneficiariesReport(app);
    MBRGIBeneficiariesReport(app);
    MBRGIFinancialReport(app);
    EntityFinancialReport(app);

}