import { Transition } from '@uirouter/core';
import { reverse as _reverse, isEqual as _isEqual } from 'lodash';
import { diff } from 'deep-diff';
export default function (app) {

	app.controller('taskController', ['$state', 'uiLoadingIndicators', 'tasksService', '$stateParams', 'sectionStatusConstant', 'taskTypesConstant', 'actionsTypesConstant', 'errorNotificationService', '$uibModal', 'formHelpersService', 'multiFileUploadService', 'mapedSectionStatus', '$translate', '$scope',
		function ($state, uiLoadingIndicators, tasksService, $stateParams, sectionStatusConstant, taskTypesConstant, actionsTypesConstant, errorNotificationService, $uibModal, formHelpersService, multiFileUploadService, mapedSectionStatus, $translate, $scope) {
			var vm = this;
			var ACTIONS_NAMES;

			$translate(["TASKS.NEW", 'TASKS.EDITING', 'TASKS.EDITED', 'TASKS.REVIEWING', 'TASKS.REVIEWED', 'TASKS.SENT_BACK',
				'TASKS.CREATE', 'TASKS.REASSIGN', 'TASKS.REOPEN', 'TASKS.EDITOR', 'TASKS.REVIEWER',
				'TASKS.SAVE', 'TASKS.SUBMIT', 'TASKS.APPROVE', 'TASKS.SENDBACK']).then(function (translations) {
					vm.labels = {
						NEW: translations['TASKS.NEW'],
						EDITING: translations['TASKS.EDITING'],
						EDITED: translations['TASKS.EDITED'],
						REVIEWING: translations['TASKS.REVIEWING'],
						REVIEWED: translations['TASKS.REVIEWED'],
						SENT_BACK: translations['TASKS.SENT_BACK'],
						CREATE: translations['TASKS.CREATE'],
						REASSIGN: translations['TASKS.REASSIGN'],
						REOPEN: translations['TASKS.REOPEN'],
						EDITOR: translations['TASKS.EDITOR'],
						REVIEWER: translations['TASKS.REVIEWER'],
						SAVE: translations['TASKS.SAVE'],
						SUBMIT: translations['TASKS.SUBMIT'],
						APPROVE: translations['TASKS.APPROVE'],
						SENDBACK: translations['TASKS.SENDBACK']
					};
					ACTIONS_NAMES = {
						1: vm.labels.SAVE,
						2: vm.labels.SUBMIT,
						3: vm.labels.SUBMIT, // this is not a bug 
						4: vm.labels.SENDBACK,
					};
				});

			var _viewHistoryModal = null;
			vm.form = {};
			vm.instructions = [];
			vm.actions = [];
			vm.model = {};
			vm.workflowInfo = {};
			vm.assignmentInfo = {};
			vm.sectionStatusConstant = sectionStatusConstant;
			vm.actionsTypesConstant = actionsTypesConstant;
			vm.taskTypesConstant = taskTypesConstant;
			vm.options = {};
            vm.isActionBtnLoading = {};
            vm.actionClicked = false;
			vm.comment = null;
			vm.taskType = $stateParams.taskType;
            vm.EntityEditingFields = [];
			vm.isRtl = function () {
				return $translate.use() == 'ar' ? true : false;
			}

			var _getTaskDetails = function () {
				uiLoadingIndicators.load('task', true);

				tasksService.getTaskDetails($stateParams.taskID).then(function(response) {
					if (response && response.responseCode == 1 && response.responseObject) {
						var _data = response.responseObject
						vm.options.formState = _data.form.formState;
                        vm.model = _data.form.model;
                        vm.EntityEditingFields = _data.form.EntityEditingFields ? JSON.parse(_data.form.EntityEditingFields) : [];
						vm.actions = _data.actions;
						vm.workflowInfo = _data.workflowInfo;
						vm.assignmentInfo = _data.assignmentInfo;
						vm.actions = _data.actions;
						vm.reversedActions = _reverse(vm.actions);
						vm.hasHistory = _data.hasHistory;
						var _form = _data.form;
						_form.fields.push({
							type: 'input',
							className: "d-none",
							controller: ($timeout) => {
								$timeout(function () {

									$('.form-group label span.truncate').each(
										function () {
											$(this).removeClass('truncate');
											var parentWidth = $(this).parent().parent().width();
											//var child = $(this).width();
											var childWidth = $(this).parent().width();

											if (childWidth >= parentWidth) {
												$(this).addClass('truncate');
											}
											else if (childWidth < 341) {
												$(this).addClass('truncate');
											}
										}
									);
								}, 0);
							}
						})
                       
                        configureForm(_form.fields);
                        _form.model.EntityEditingFields = vm.EntityEditingFields;
                        vm.form = _form;
                        vm.form.model.oldModel = angular.copy(_data.form.model)
					}
					else {
						errorNotificationService.showErrorNotification(response.responseCode);
					}
					uiLoadingIndicators.load('task', false);
				},function(error){
					errorNotificationService.showErrorNotification(response.responseCode);
					uiLoadingIndicators.load('task', false);
				})
			}


			vm.getStatName = function (statType) {
				var _labels = vm.labels;
				if (statType == sectionStatusConstant.NEW) {
					return _labels.NEW;
				} else if (statType == sectionStatusConstant.IN_PROGRESS) {
					return _labels.EDITING;
				} else if (statType == sectionStatusConstant.SUBMITTED) {
					return _labels.REVIEWING;
				}
				else if (statType == sectionStatusConstant.APPROVED) {
					return _labels.REVIEWED;
				}
			}

			vm.isHighlightedText = function (statType, taskType) {
				var _edit = vm.taskTypesConstant.EDIT;
				var _review = vm.taskTypesConstant.REVIEW;
				if (taskType == _edit && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
					return true;
				}

				if (taskType == _review && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
					return true;
				}
			}

			vm.getStatName = function (statType, taskType) {
				var _labels = vm.labels;
				var _state = _getMapedSectionStatus(statType, taskType);
				if (_state == mapedSectionStatus.NEW) {
					return _labels.NEW;
				} else if (_state == mapedSectionStatus.EDITING) {
					return _labels.EDITING;
				} else if (_state == mapedSectionStatus.REVIEWING) {
					return _labels.REVIEWING;
				} else if (_state == mapedSectionStatus.EDITED) {
					return _labels.EDITED;
				}
				else if (_state == mapedSectionStatus.REVIEWED) {
					return _labels.REVIEWED;
				}
				else if (_state == mapedSectionStatus.SENT_BACK) {
					return _labels.SENT_BACK;
				}
			}

			vm.getStatIcon = function (statType, taskType) {
				var _state = _getMapedSectionStatus(statType, taskType);
				if (_state == mapedSectionStatus.NEW) {
					return 'fa-inbox text-primary'
				} else if (_state == mapedSectionStatus.EDITING) {
					return 'fa-edit text-warning'
				} else if (_state == mapedSectionStatus.REVIEWING) {
                    return 'fa-pen text-warning'
				} else if (_state == mapedSectionStatus.EDITED) {
					return 'fa-eye text-success'
				}
				else if (_state == mapedSectionStatus.REVIEWED) {
					return 'fa-check-circle text-success'
				}
				else if (_state == mapedSectionStatus.SENT_BACK) {
					return 'fa-redo-alt text-danger'
				}
			}

			vm.getStateTextColor = function (statType, taskType) {
				var _state = _getMapedSectionStatus(statType, taskType);
				if (_state == mapedSectionStatus.NEW) {
					return 'text-primary'
				} else if (_state == mapedSectionStatus.EDITING) {
					return 'text-warning'
				} else if (_state == mapedSectionStatus.REVIEWING) {
                    return 'text-warning'
				} else if (_state == mapedSectionStatus.EDITED) {
					return 'text-success' //TODO get icon for Edited state
				}
				else if (_state == mapedSectionStatus.REVIEWED) {
					return 'text-success'
				}
				else if (_state == mapedSectionStatus.SENT_BACK) {
					return 'text-danger' //TODO get icon for Sent back state
				}
			}

			var _getMapedSectionStatus = function (statType, taskType) {
				var _edit = vm.taskTypesConstant.EDIT;
				var _review = vm.taskTypesConstant.REVIEW
				if (statType == sectionStatusConstant.NEW) {
					return mapedSectionStatus.NEW;
				} else if (taskType == _edit && statType == sectionStatusConstant.IN_PROGRESS) {
					return mapedSectionStatus.EDITING;
				} else if (taskType == _review && statType == sectionStatusConstant.IN_PROGRESS) {
					return mapedSectionStatus.REVIEWING;
				} else if (taskType == _edit && statType == sectionStatusConstant.SUBMITTED) {
					return mapedSectionStatus.EDITED;
				}
				else if (taskType == _review && statType == sectionStatusConstant.APPROVED) {
					return mapedSectionStatus.REVIEWED;
				}
				else if (taskType == _review && statType == sectionStatusConstant.SENT_BACK) {
					return mapedSectionStatus.SENT_BACK;
				}
			}

			vm.getActionName = function (actionType) {
				return ACTIONS_NAMES[actionType];
			}

			vm.submitTaskAction = function (actionId) {
                vm.actionClicked = vm.isActionBtnLoading[actionId] = true;
				var _taskID = $stateParams.taskID;
                var _comment = vm.comment ? vm.taskType == vm.taskTypesConstant.REVIEW && vm.comment : '';
                vm.DefineEntityEditingFields();
                var form = {
                    model: vm.form.model,
                    EntityEditingFields: vm.EntityEditingFields
                };
				////try upload form files first
				//var _files = formHelpersService.getFiles(vm.options.formState.filesStorageKey);
				//if (_files && _files.length > 0) {

				//	multiFileUploadService.upload(_files).then(function(resp){
				//		if (resp.status == 200) {
    //                        _submitAction(actionId, _taskID, _comment, form).then(function(response) {
				//				if (response && response.responseCode == 1) {
				//					$scope.taskForm.$setSubmitted();
				//					$state.go("Entity.Tasks", undefined, { reload: true });
				//				} else {
				//					errorNotificationService.showErrorNotification(response.responseCode);
				//				}
    //                            vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
				//			},function(error){
				//				errorNotificationService.showErrorNotification(error);
    //                            vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
				//			})
				//		} else {
				//			//TODO show error msg
    //                        vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
				//		}
				//	},function(error){
				//		//TODO show error msg
    //                    vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
				//	})


				//} else {
                    _submitAction(actionId, _taskID, _comment, form).then(function(response) {
						if (response && response.responseCode == 1) {
							$scope.taskForm.$setSubmitted();
							$state.go("Entity.Tasks", undefined, { reload: true });
						} else {
							errorNotificationService.showErrorNotification(response.responseCode);
						}
                        vm.actionClicked =  vm.isActionBtnLoading[actionId] = false;
					},function(error){
						errorNotificationService.showErrorNotification(error);
                        vm.actionClicked =  vm.isActionBtnLoading[actionId] = false;
					})
				//}
			}

			//TODO remove this useless function
			var _submitAction = function (actionId, _taskID, _comment, model) {
				return tasksService.submitTaskAction(_taskID, actionId, _comment, model).then(function(response) {
					if (response && response.responseCode == 1) {
						return Promise.resolve(response);
					} else {
						return Promise.resolve(response);
					}
				},function(error){
					return Promise.reject(error)
				})
			}

			vm.getTaskActivityHistory = function () {
				var _taskID = $stateParams.taskID;
				var _historyPromise = tasksService.getTaskActivityHistory(_taskID)
				_historyPromise.then(function(response) {
					var _labels = vm.labels;
                    if (response && response.responseCode == 1) {
                        if (document.getElementsByClassName("modal").length === 0)
						_viewHistoryModal = $uibModal.open({
							backdrop: true,
							keyboard: true,
							backdropClick: true,
							template: require('../../AnnualAchievement/Views/historyModal.html'),
							size: 'lg',
							controllerAs: 'vm',
							controller: ['$uibModalInstance', function ($uibModalInstance) {
								var vm = this;
								vm.actionsTypesConstant = actionsTypesConstant;
								vm.history = response.responseObject;
								vm.taskTypesConstant = taskTypesConstant;

								vm.getActionName = function (actionID) {
									if (actionID == actionsTypesConstant.SAVE) {
										return _labels.SAVE;
									} else if (actionID == actionsTypesConstant.SUBMIT) {
										return _labels.SUBMIT;
									} else if (actionID == actionsTypesConstant.APPROVE) {
										return _labels.APPROVE;
									}
									else if (actionID == actionsTypesConstant.SENDBACK) {
										return _labels.SENDBACK;
									}
								}

								vm.cancel = function () {
									$uibModalInstance.dismiss();
								}

							}]
						})
					} else {
						errorNotificationService.showErrorNotification(response.responseCode);
					}

				},function(error){
					errorNotificationService.showErrorNotification(error);
				})
			}

			var _init = function () {
				_getTaskDetails();
			}();

			$scope.$on('$destroy', function () {
				if (_viewHistoryModal && _viewHistoryModal.close) {
					_viewHistoryModal.close();
				}
			})
            vm.DefineEntityEditingFields = function () {

                var oldModel = vm.form.model.oldModel;
                vm.EntityEditingFields = vm.form.model.EntityEditingFields;
                delete vm.form.model.oldModel;
                delete vm.form.model.EntityEditingFields;

                var d = diff(vm.form.model, oldModel);

                if (typeof d == "undefined") return;
                var r = d.filter((x) => {

                    if (x.lhs == "" && x.rhs == null)
                        return false;
                    else if (x.item)
                        return true;
                    else
                        return (typeof x.lhs != "undefined" && (typeof x.lhs == "string" ? !x.lhs.includes("object:") : true));
                });

                r.forEach((x) => {
                    var index = vm.EntityEditingFields.findIndex(z => _isEqual(z, x.path));
                    if (index == -1) {
                        vm.EntityEditingFields.push(x.path
                        );
                    }
                });
            }

            var configureForm = function (theObject) {
                var result = null;
                if (theObject instanceof Array) {
                    for (var i = 0; i < theObject.length; i++) {
                        result = configureForm(theObject[i]);
                        if (result) {
                            break;
                        }
                    }
                }
                else {
                    if (theObject.hasOwnProperty('fieldGroup')) {
                        result = configureForm(theObject.fieldGroup);
                    }

                    else if (theObject.templateOptions && theObject.templateOptions.hasOwnProperty('fields')) {
                        result = configureForm(theObject.templateOptions.fields);

                    }

                    else if (theObject.templateOptions && vm.EntityEditingFields.findIndex(x => x == theObject.key) > -1) {
                        theObject.templateOptions.IsEdited = true;
                    }
                }
                return result;
            }
		}])
}