export default {
    DATA_ENTRY: 'Data entry ending in',
    REMAINING_DAYS: '',
    CUT_OFF: 'Cut-off deadline in',
    ALL_TASKS: 'Total of all tasks',
    NEW: 'New',
    TASKS: 'Tasks',
    EDITING: 'Editing',
    REVIEWING: 'Under review',
    CLOSED: 'Reviewed',
    MYTASKS: 'My tasks',
    PROCCESSING: 'Processing',
    DONE: 'Done',
    YEAR_IN_REVIEW: new Date().getFullYear() + ' Year in review',



}


