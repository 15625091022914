export default function (app) {
    app.controller('manageSectionsController', ManageSectionsController);

    ManageSectionsController.$inject = ['$scope', '$state', '$uibModal', "Notification", "$rootScope", "manageSectionDataService", "$translate", 'successrNotificationService', "errorNotificationService"];
    function ManageSectionsController($scope, $state, $uibModal, Notification, $rootScope, manageSectionDataService, $translate, successrNotificationService, errorNotificationService) {
        var vm = this;
        vm.load = load;
        vm.Loading = $rootScope.Loading;
        vm.load();
        vm.SectionsList = [];
        vm.SectionState;
        vm.IsDisableMode = false;

        $translate(["MANAGE_SECTIONS.DeactivateSectionHeader", "MANAGE_SECTIONS.DeactivateSectionQues", 'MANAGE_SECTIONS.Section_ACTIVATED', 'MANAGE_SECTIONS.Section_DEACTIVATED']).then(function (translations) {
            vm.labels = {
                Header: translations['MANAGE_SECTIONS.DeactivateSectionHeader'],
                Message: translations['MANAGE_SECTIONS.DeactivateSectionQues'],
                Sectoin_ACTIVATED: translations['MANAGE_SECTIONS.Section_ACTIVATED'],
                Sectoin_DEACTIVATED: translations['MANAGE_SECTIONS.Section_DEACTIVATED'],

            }
        });
        function load() {
          
            _getSectionList();
            _IsSectionsEditable();
        }
        function _IsSectionsEditable() {
            vm.Loading()
            manageSectionDataService.IsSectionsEditable().then(function (response) {
                vm.Loading();
                if (response != null) {
                    vm.IsDisableMode = !response;
                    $scope.sortableOptions.disabled = vm.IsDisableMode;
                    $scope.sortableOptions.placeholder = vm.IsDisableMode ? "" : "ui-state-highlight";
                  
                } else {
                    errorNotificationService.showErrorNotification(response.responseCode);
                }
            }, function (error) {
            })
        }
        function _getSectionList() {
            vm.Loading();
            manageSectionDataService.GetAllSections().then(function (response) {
              vm.Loading();
                if (response ) {
                    vm.SectionsList = response;   
                } else {
                    errorNotificationService.showErrorNotification(response.responseCode);
                } 
            }, function (error) {
            })
        }
        vm.EditSectionMetaData = function (sectionId)
        {
            var param = { sectionID: sectionId };
            $state.transitionTo('MBRGI.updateSection', param);
        }
        vm.EditSectionForm = function (sectionId) {
            var param = { sectionID: sectionId};
            $state.transitionTo('MBRGI.FormBuilder', param);
        }
        vm.changeActiveStatus = function (section, ev) {
            // Appending dialog to document.body to cover sidenav in docs app
            if (vm.IsDisableMode)
                return;
            vm.Loading();
            var SectionID = section.ID;
            vm.SectionState= !section.IsDeleted;
            var labels = vm.labels;
            if (vm.SectionState === true) { //incase of deactivate show confirmation message 
                vm.Loading();
                if (document.getElementsByClassName("modal").length === 0)
                    vm.Modal = $uibModal.open({
                        keyboard: true,
                        size: 'md modal-dialog-centered',
                        animation: true,
                        templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
                        controller: function ($uibModalInstance) {
                            var vm = this;
                            vm.header = labels.Header;
                            vm.message = labels.Message;
                            vm.ok = function () {
                                $uibModalInstance.close({ Close: false, Ok: true });
                            };
                            vm.cancel = function () {
                                $uibModalInstance.close({ Close: true, Ok: false });
                            };
                        },
                        controllerAs: 'vm'
                    });
                vm.Modal.result.then(function (result) {
                    if (result.Ok) {
                        vm.Loading();
                        manageSectionDataService.SetSectionStatus(SectionID, vm.SectionState)
                            .then(ChangeActiveStatusSuccess, $rootScope.methodPublicError);
                    }
                }, function (error) {
                    //backdrop clicked or error
                });
            }
            else {
                manageSectionDataService.SetSectionStatus(SectionID, vm.SectionState)
                    .then(ChangeActiveStatusSuccess, $rootScope.methodPublicError);
            }
        }
        function ChangeActiveStatusSuccess(res) {
            if (!vm.SectionState ) {
                Notification.success({ message: vm.labels.Sectoin_ACTIVATED });

            } else if (vm.SectionState) {
                Notification.success({ message: vm.labels.Sectoin_DEACTIVATED });
            }
            vm.Loading();
            vm.load();
        }

        $scope.sortableOptions = { 
            disabled: vm.IsDisableMode,
            placeholder: vm.IsDisableMode ? "" : "ui-state-highlight",
            stop: function (e, ui) {
                var IDs = [];
                vm.SectionsList.forEach((x) => { IDs.push(x.ID); }); 
                vm.Loading();
                manageSectionDataService.UpdateSectionsOrder(IDs).then(function (response) {
                    vm.Loading();
                    if (response) {

                    } else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                })
            }
        };
    }
}