export default function (app) {
    app.controller('homeController', HomeController);

    HomeController.$inject = ["homeDataService"];
    function HomeController(homeServiceApi) {
        var vm = this;
        vm.formlyViewerModel = {};
        vm.load = load;

        vm.load();

        function load() {
            
        }
    }
}