import SignaturePad from 'signature_pad';

export default function (app) {
  
  app.directive('signaturePad', ['$interval', '$timeout', '$window',
    function ($interval, $timeout, $window) {
      'use strict';

      var signaturePad, element, EMPTY_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAjgAAADcCAQAAADXNhPAAAACIklEQVR42u3UIQEAAAzDsM+/6UsYG0okFDQHMBIJAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEwHMBwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcCQADAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDkQAwHMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHMBwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMBzAcAMMBDAegeayZAN3dLgwnAAAAAElFTkSuQmCC';

      return {
        restrict: 'EA',
        replace: true,
        template: '<div class="signature" style="width: 100%; max-width:{{width}}px; height: 100%; max-height:{{height}}px;"><canvas style="display: block; margin: 0 auto;" ng-mouseup="onMouseup()" ng-mousedown="notifyDrawing({ drawing: true })"></canvas></div>',
        scope: {
          accept: '=?',
          clear: '=?',
          disabled: '=?',
          dataurl: '=?',
          height: '@',
          width: '@',
          notifyDrawing: '&onDrawing',
        },
        controller: [
          '$scope',
          function ($scope) {
            $scope.accept = function () {

              return {
                isEmpty: $scope.dataurl === EMPTY_IMAGE,
                dataUrl: $scope.dataurl
              };
            };

            $scope.onMouseup = function () {
              $scope.updateModel();

              // notify that drawing has ended
              $scope.notifyDrawing({ drawing: false });
            };

            $scope.updateModel = function () {
              /*
               defer handling mouseup event until $scope.signaturePad handles
               first the same event
               */
              $timeout(function () {
                $scope.dataurl = $scope.signaturePad.isEmpty() ? EMPTY_IMAGE : $scope.signaturePad.toDataURL();
              });
            };

            $scope.clear = function () {
              $scope.signaturePad.clear();
              $scope.dataurl = EMPTY_IMAGE;
            };

            $scope.$watch("dataurl", function (dataUrl) {
              if (!dataUrl || $scope.signaturePad.toDataURL() === dataUrl) {
                return;
              }

              $scope.setDataUrl(dataUrl);
            });
          }
        ],
        link: function (scope, element, attrs) {
          var canvas = element.find('canvas')[0];
          var parent = canvas.parentElement;
          var scale = 0;
          var ctx = canvas.getContext('2d');

          var width = parseInt(scope.width, 10);
          var height = parseInt(scope.height, 10);

          canvas.width = width;
          canvas.height = height;

          scope.$watch('width', function (val) {
            width = parseInt(val, 10);
            canvas.width = width;
          });

          scope.signaturePad = new SignaturePad(canvas);

          scope.setDataUrl = function (dataUrl) {
            var ratio = Math.max(window.devicePixelRatio || 1, 1);

            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.scale(ratio, ratio);

            scope.signaturePad.clear();
            scope.signaturePad.fromDataURL(dataUrl);

            $timeout(function () {
              ctx.setTransform(1, 0, 0, 1, 0, 0);
              ctx.scale(1 / scale, 1 / scale);
            });
          };

          scope.$watch('disabled', function (val) {
            val ? scope.signaturePad.off() : scope.signaturePad.on();
          });

          var calculateScale = function () {
            var scaleWidth = Math.min(parent.clientWidth / width, 1);
            //var scaleHeight = Math.min(parent.clientHeight / height, 1);
            var scaleHeight = 1;

            var newScale = Math.min(scaleWidth, scaleHeight);

            var newWidth = width * newScale;
            var newHeight = height * newScale;
            canvas.style.height = Math.round(newHeight) + "px";
            canvas.style.width = Math.round(newWidth) + "px";

            scale = newScale;
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.scale(1 / scale, 1 / scale);
          };



          angular.element($window).bind('resize', calculateScale);
          scope.$on('$destroy', function () {
            angular.element($window).unbind('resize', calculateScale);
          });

          calculateScale();

          element.on('touchstart', onTouchstart);
          element.on('touchend', onTouchend);

          function onTouchstart(event) {
            scope.$apply(function () {
              // notify that drawing has started
              scope.notifyDrawing({ drawing: true });
            });
            event.preventDefault();
          }

          function onTouchend(event) {
            scope.$apply(function () {
              // updateModel
              scope.updateModel();

              // notify that drawing has ended
              scope.notifyDrawing({ drawing: false });
            });
            event.preventDefault();
          }
        }
      };
    }
  ]);

}