export default {
    ADD_BENEFICIARY_UNIT: 'Add new Beneficiary Unit',
    BENEFICIARY_UNIT_ENGLISH_NAME: 'Beneficiary unit name (English)',
    BENEFICIARY_UNIT_ARABIC_NAME: 'Beneficiary unit name (Arabic)',

    BENEFICIARY_UNIT_TYPE: 'Beneficiary unit type',
    PLEASE_SELECT: 'Please select',
    REQUIRED_BENEFICIARY_UNIT_ARABIC_NAME: 'Beneficiary unit name (Arabic) is required.',
    REQUIRED_BENEFICIARY_UNIT_ENGLISH_NAME: 'Beneficiary unit name (English) is required.',
    REQUIRED_BENEFICIARY_UNIT_TYPE: 'Beneficiary unit Type is required.',   
    BENEFICIARY_UNITS: 'Beneficiaries Units',
    UPDATE: 'Update',
    UPDATE_BENEFICIARY_UNIT: 'Update Beneficiary Unit',

    BENEFICIARY_UNIT_EXIST: 'Beneficiary unit name is previously used',
    BENEFICIARY_UNIT_ADDED: 'Beneficiary Unit Added Successfully',
    BENEFICIARY_UNIT_UPDATED: 'Beneficiary Unit has been Updated Successfully',
    BENEFICIARY_UNIT_DELETED: 'Beneficiary Unit has been Deleted Successfully',
    BENEFICIARY_UNIT_DELETE_QUES: 'Are you sure you want to delete the Beneficiary Unit?',
    DELETE_HEADER: 'Delete',
    BENEFICIARY_UNIT_RESTORED: 'Beneficiary unit had been successfully restored',
}

