import { map as _map, omit as _omit, toString as _toString, values as _values, filter as _filter, find as _find, cloneDeep as _cloneDeep, findIndex as _findIndex } from "lodash"

export default function (app) {

    app.filter("cardWorkflowState", ['sectionStatusConstant', function (sectionStatusConstant) {
        return function (cards, state) {
            if (!state) {
                return cards;
            }
            return _filter(cards, (card) => {
                //if IN_PROGRESS is selected include sent back state also
                if (state == sectionStatusConstant.IN_PROGRESS) {
                    return card.workflowInfo.status == sectionStatusConstant.IN_PROGRESS || card.workflowInfo.status == sectionStatusConstant.SENT_BACK;
                }

                return card.workflowInfo.status == state;
            })
        };
    }]);


    app.controller("sectionSubmissionsMBRGIController", ['$scope', 'annualAchievementMBRGIService', 'uiLoadingIndicators', 'taskTypesConstant', 'sectionStatusConstant', 'sectionTypesConstant', 'errorNotificationService', '$stateParams', 'routeConstants', '$state', '$rootScope', '$uibModal', '$timeout', 'actionsTypesConstant', 'successrNotificationService', 'sectionsMBRGIService', '$translate', "Notification",
        function ($scope, annualAchievementMBRGIService, uiLoadingIndicators, taskTypesConstant, sectionStatusConstant, sectionTypesConstant, errorNotificationService, $stateParams, routeConstants, $state, $rootScope, $uibModal, $timeout, actionsTypesConstant, successrNotificationService, sectionsMBRGIService, $translate, Notification) {
            var vm = this;
            var _viewCommentModal = null;
            $translate(["ACHIEVEMENT.NEW", 'ACHIEVEMENT.EDITING', 'ACHIEVEMENT.REVIEWING', 'ACHIEVEMENT.REVIEWED', 'ACHIEVEMENT.SENT_BACK',
                'ACHIEVEMENT.CREATE', 'ACHIEVEMENT.REASSIGN', 'ACHIEVEMENT.REOPEN', 'ACHIEVEMENT.EDITOR', 'ACHIEVEMENT.REVIEWER',
                'ACHIEVEMENT.SAVE', 'ACHIEVEMENT.SUBMIT', 'ACHIEVEMENT.APPROVE', 'ACHIEVEMENT.SENDBACK', 'ACHIEVEMENT.SUBMIT_ACHIEVEMENTS', 'ACHIEVEMENT.CONFIRM_SUBMIT_ACHIEVEMENTS',
                'ACHIEVEMENT.DELETE', 'ACHIEVEMENT.DELETEQUESTION', 'ANNUALACHIEVEMENTS.CANCEL_ACHIEVEMENTS', 'ANNUALACHIEVEMENTS.MBRGIViewerNotAllowToView']).then(function (translations) {
                    vm.labels = {
                        NEW: translations['ACHIEVEMENT.NEW'],
                        EDITING: translations['ACHIEVEMENT.EDITING'],
                        REVIEWING: translations['ACHIEVEMENT.REVIEWING'],
                        REVIEWED: translations['ACHIEVEMENT.REVIEWED'],
                        SENT_BACK: translations['ACHIEVEMENT.SENT_BACK'],
                        CREATE: translations['ACHIEVEMENT.CREATE'],
                        REASSIGN: translations['ACHIEVEMENT.REASSIGN'],
                        REOPEN: translations['ACHIEVEMENT.REOPEN'],
                        EDITOR: translations['ACHIEVEMENT.EDITOR'],
                        REVIEWER: translations['ACHIEVEMENT.REVIEWER'],
                        SAVE: translations['ACHIEVEMENT.SAVE'],
                        SUBMIT: translations['ACHIEVEMENT.SUBMIT'],
                        APPROVE: translations['ACHIEVEMENT.APPROVE'],
                        SENDBACK: translations['ACHIEVEMENT.SENDBACK'],
                        DELETE: translations['ACHIEVEMENT.DELETE'],
                        DELETEQUESTION: translations['ACHIEVEMENT.DELETEQUESTION'],
                        SUBMIT_ACHIEVEMENTS: translations['ACHIEVEMENT.SUBMIT_ACHIEVEMENTS'],
                        CONFIRM_SUBMIT_ACHIEVEMENTS: translations['ACHIEVEMENT.CONFIRM_SUBMIT_ACHIEVEMENTS'],
                        CANCEL_ACHIEVEMENTS: translations['ANNUALACHIEVEMENTS.CANCEL_ACHIEVEMENTS'],
                        MBRGIViewerNotAllowToView: translations['ANNUALACHIEVEMENTS.MBRGIViewerNotAllowToView'],
                    }
                });
            var _addActivityModalInstance = null;
            var _deleteConfirmationModal = null;
            var _viewHistoryModal = null;
            var _confirmSubmitAchievementsModalInstance = null;

            vm.cards = [];
            vm.sectionStatistics = [];
            vm.taskTypesConstant = taskTypesConstant;
            vm.sectionStatusConstant = sectionStatusConstant;
            vm.sectionTypesConstant = sectionTypesConstant;
            vm.activitiesListStateFilter = undefined;
            vm.activitiesListTitleFilter = "";
            vm.textSearch = "";
            vm.isCycleEnabled = $state.params.isCycleEnabled ? JSON.parse($state.params.isCycleEnabled) : false;
            vm.viewMode = $state.params.viewMode ? JSON.parse($state.params.viewMode) : false;
            vm.isAddActivityBtnLoading = false;
            vm.comment = '';
            vm.deletedCount = 0;
            vm.sectionTitle = '';
            vm.actionsTypesConstant = actionsTypesConstant;
            vm.isActionBtnLoading = {};
            vm.actionClicked = false;
            vm.sectionID = $state.params.sectionID;
            vm.cycleID = $state.params.cycleID;
            vm.IsSectionRequired = false;

            vm.isArabic = $translate.use() == 'ar' ? true : false;
            

            var _getSectionActivities = function () {
                uiLoadingIndicators.load('activities', true);
                annualAchievementMBRGIService.getSectionActivities($state.params.sectionID, $state.params.entityID, vm.viewMode, vm.cycleID).then(function (response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        if (_data && _data.cards && _data.cards.length > 0) {
                            vm.cards = _map(_data.cards, (card) => {
                                if (card.titleAr && vm.isArabic) {
                                    card.title = card.titleAr;
                                }
                                else {
                                    card.title = card.titleEn || '';
                                }
                                return card;
                            });
                        }
                        vm.sectionActions = _data.actions;
                        vm.sectionStatistics = _data.sectionStatistics;
                        vm.sectionTitle = _data.sectionSingleTitle;
                        vm.sectionName = _data.sectionTitle;
                        vm.hasSectionComments = _data.HasSectionComments;
                        vm.ShowCommentsAndHistory = _data.ShowCommentsAndHistory;
                        vm.sectionTotals = _data.sectionTotals;
                        vm.SectionCommentsCount = _data.SectionCommentsCount;
                        var _saveActionIndex = _findIndex(vm.sectionActions, ['actionType', vm.actionsTypesConstant.SAVE]);
                        if (_saveActionIndex != -1) {
                            vm.sectionActions.splice(_saveActionIndex + 1, 0, { "actionType": -1, "actionName": vm.labels.CANCEL_ACHIEVEMENTS })
                        }
                        vm.CheckIsSectionRequired();
                    } else {
                        //TODO show error msg
                        if (response && response.responseCode == 323) {
                            Notification.error({ message: vm.labels.MBRGIViewerNotAllowToView });
                            $state.transitionTo('MBRGI.MBRGIAnnualAchievements');
                        }
                    }
                   
                    uiLoadingIndicators.load('activities', false);
                }, function (error) {
                    uiLoadingIndicators.load('activities', false);
                })
            }

            var _deleteActivity = function (activityID) {
                annualAchievementMBRGIService.deleteActivity($state.params.entityID, activityID).then(function (response) {

                    if (response && response.responseCode == 1) {
                        //reload to reflect change in section state
                        $state.reload();
                        //TODO successfull message
                        successrNotificationService.showSuccessDeletingActivityNotfication(vm.sectionTitle);
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, function (error) {
                    //TODO show error msg
                    errorNotificationService.showErrorNotification(error);
                })
            }

            vm.getisCycleEnabled = function () {
                return JSON.parse($state.params.isCycleEnabled);
            }

            vm.getIsSectionEnabled = function () {
                //disabled by default
                if ($state.params.sectionID) {
                    var _currentSection = sectionsMBRGIService.getSection($state.params.sectionID);
                    if (_currentSection)
                        return !!_currentSection.enabled;
                }
                return false

                //return true;
            }

            vm.getAddActivityForm = function () {
                vm.isAddActivityBtnLoading = true;
                annualAchievementMBRGIService.getAddActivityForm($state.params.entityID, $state.params.sectionID).then(function (response) {
                    var _labels = vm.labels;
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _sectionTitle = vm.sectionTitle;
                        if (document.getElementsByClassName("modal").length === 0)
                            _addActivityModalInstance = $uibModal.open({
                                backdrop: true,
                                keyboard: true,
                                backdropClick: true,
                                template: require('../Views/addActivityModal.html'),
                                size: 'lg',
                                controller: ['$uibModalInstance', function ($uibModalInstance) {
                                    var vm = this;
                                    var _data = response.responseObject;
                                    vm.model = {};
                                    vm.addActivityForm = _data;



                                    vm.ok = function () {
                                        //TODO show error msg
                                        vm.showAddActivityModalSpinner = true;

                                        var _modal = _omit(vm.model, ['selectedEditor', 'reviewers']);

                                        annualAchievementMBRGIService.addActivity($state.params.entityID, $state.params.sectionID, _modal)
                                            .then(function (response) {
                                                if (response && response.responseCode == 1 && response.responseObject) {
                                                    vm.showAddActivityModalSpinner = false;
                                                    $uibModalInstance.close();
                                                    //TODO add success message
                                                    successrNotificationService.showSuccessAddingNotfication(_sectionTitle);
                                                    $state.reload();
                                                } else {
                                                    //TODO show error msg
                                                    errorNotificationService.showErrorNotification(response.responseCode);
                                                }
                                            }, function (error) {
                                                vm.showAddActivityModalSpinner = false;
                                                errorNotificationService.showErrorNotification(error);
                                            })
                                    };
                                    vm.cancel = function (error) {
                                        vm.showAddActivityModalSpinner = false;
                                        $uibModalInstance.close();
                                    };
                                }],
                                controllerAs: 'vm'
                            });

                    } else {
                        //TODO show error
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                    vm.isAddActivityBtnLoading = false;

                }, function (error) {
                    errorNotificationService.showErrorNotification(error);
                    vm.isAddActivityBtnLoading = false;
                })
            }

            vm.getStatName = function (statType) {
                var _labels = vm.labels;
                if (statType == sectionStatusConstant.NEW) {
                    return _labels.NEW;
                } else if (statType == sectionStatusConstant.IN_PROGRESS) {
                    return _labels.EDITING;
                } else if (statType == sectionStatusConstant.SUBMITTED) {
                    return _labels.REVIEWING;
                }
                else if (statType == sectionStatusConstant.APPROVED) {
                    return _labels.REVIEWED;
                } else if (statType == sectionStatusConstant.SENT_BACK) {
                    return _labels.SENT_BACK;
                }
            }

            vm.isHighlightedText = function (statType, taskType) {
                var _edit = vm.taskTypesConstant.EDIT;
                var _review = vm.taskTypesConstant.REVIEW;
                if (taskType == _edit && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
                    return true;
                }

                if (taskType == _review && (statType == sectionStatusConstant.NEW || statType == sectionStatusConstant.IN_PROGRESS)) {
                    return true;
                }
            }

            vm.confirmDeleteActivity = function (card) {
                var _labels = vm.labels;
                if (document.getElementsByClassName("modal").length === 0)
                    _deleteConfirmationModal = $uibModal.open({
                        backdrop: true,
                        keyboard: true,
                        backdropClick: true,
                        template: require('../../../Common/Views/confirmation-popup.html'),
                        size: 'md',
                        controllerAs: 'vm',
                        controller: ['$uibModalInstance', function ($uibModalInstance) {
                            var vm = this;
                            vm.header = _labels.DELETE;
                            var _title = card.titleEn || card.titleAr;
                            vm.message = _labels.DELETEQUESTION + _title;

                            vm.ok = function () {
                                $uibModalInstance.close(card.workflowInfo.id);
                            }

                            vm.cancel = function () {
                                $uibModalInstance.close();
                            }
                        }]
                    });

                _deleteConfirmationModal.result.then(function (activityID) {
                    if (activityID) {
                        _deleteActivity(activityID);
                    }
                }, function (error) { })
            }

            vm.getActivityHistory = function (activityID) {
                var _labels = vm.labels;
                annualAchievementMBRGIService.getActivityHistory(activityID).then(function (response) {
                    if (response && response.responseCode == 1) {
                        _viewHistoryModal = $uibModal.open({
                            backdrop: true,
                            keyboard: true,
                            backdropClick: true,
                            template: require('../Views/historyModal.html'),
                            size: 'lg',
                            controllerAs: 'vm',
                            controller: ['$uibModalInstance', function ($uibModalInstance) {
                                var vm = this;
                                vm.actionsTypesConstant = actionsTypesConstant;
                                vm.taskTypesConstant = taskTypesConstant;
                                vm.history = response.responseObject;

                                vm.getActionName = function (actionID) {
                                    if (actionID == actionsTypesConstant.SAVE) {
                                        return _labels.SAVE;
                                    } else if (actionID == actionsTypesConstant.SUBMIT) {
                                        return _labels.SUBMIT;
                                    } else if (actionID == actionsTypesConstant.APPROVE) {
                                        return _labels.APPROVE;
                                    }
                                    else if (actionID == actionsTypesConstant.SENDBACK) {
                                        return _labels.SENDBACK;
                                    }
                                }

                                vm.cancel = function () {
                                    $uibModalInstance.close();
                                }

                            }]
                        })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, function (error) {
                    //TODO show error
                    errorNotificationService.showErrorNotification(error);
                })
            }
            
            vm.submitAnnualAchievementAction = function () {
                var _labels = vm.labels;
                if (document.getElementsByClassName("modal").length === 0)
                    _confirmSubmitAchievementsModalInstance = $uibModal.open({
                        backdrop: true,
                        keyboard: true,
                        backdropClick: true,
                        template: require('../../../Common/Views/confirmation-popup.html'),
                        size: 'lg',
                        controllerAs: 'vm',
                        controller: ['$uibModalInstance', function ($uibModalInstance) {
                            var vm = this;
                            vm.message = _labels.CONFIRM_SUBMIT_ACHIEVEMENTS;
                            vm.header = _labels.SUBMIT_ACHIEVEMENTS;
                            vm.isConfirmationAction = true;

                            vm.ok = function () {
                                $uibModalInstance.close();
                            }

                            vm.cancel = function () {
                                $uibModalInstance.close();
                            }
                        }]
                    })

                _confirmSubmitAchievementsModalInstance.result.then(function () {
                    //TODO check prams
                    annualAchievementMBRGIService.submitAnnualAchievementAction($rootScope.GetCurrentEntityID(), actionsTypesConstant.SUBMIT, '').then(function (response) {
                        //handle response
                        if (response.responseCode == 1) {

                        }
                    }, function (error) {
                        errorNotificationService.showErrorNotification(error);
                    })

                }, function (error) { })

            }

            vm.submitAction = function (actionId) {
                vm.actionClicked = vm.isActionBtnLoading[actionId] = true;
                if (actionId == vm.actionsTypesConstant.CANCEL) {
                    vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                    $state.go('MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement');
                }
                else {
                    //if ($state.params.activityID) {
                    annualAchievementMBRGIService.submitActivity($state.params.entityID, $state.params.sectionID, actionId, vm.comment).then(function (response) {
                        //handle response
                        vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                        if (response.responseCode == 1) {
                            if (actionId == vm.actionsTypesConstant.SAVE) {
                                successrNotificationService.showSuccessSavingSectionNotfication(vm.sectionTitle);
                            } else if (actionId == vm.actionsTypesConstant.APPROVE) {
                                successrNotificationService.showSuccessApprovingSectionNotfication(vm.sectionTitle);
                            } else if (actionId == vm.actionsTypesConstant.SENDBACK) {
                                successrNotificationService.showSuccessSendingBackSectionNotfication(vm.sectionTitle);
                            }
                            $state.reload();
                            //$state.go('MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement');
                        }
                    }, function (error) {
                        vm.actionClicked = vm.isActionBtnLoading[actionId] = false;
                        errorNotificationService.showErrorNotification(error);
                    });
                    //} 
                }
            }

            vm.getSectionComments = function () {
                annualAchievementMBRGIService.getSectionComments($state.params.entityID, $state.params.sectionID, $state.params.cycleID).then(function (response) {
                    if (response && response.responseCode == 1) {
                        var _self = vm;
                        var sectionKey = $state.params.sectionID;
                        var entityId = $state.params.entityID;
                        if (document.getElementsByClassName("modal").length === 0)
                            _viewCommentModal = $uibModal.open({
                                keyboard: true,
                                animation: true,
                                template: require('../Views/commentsModal.html'),
                                size: 'lg',
                                controllerAs: 'vm',
                                controller: ['$uibModalInstance', 'annualAchievementService', function ($uibModalInstance, annualAchievementService) {
                                    var vm = this;
                                    //TODO add user role
                                    vm.sectionComments = response.responseObject.UsersComments;
                                    vm.CanAddComment = response.responseObject.CanAddComment;
                                    vm.addComment = function () {
                                        vm.ModalSpinner = true;
                                        annualAchievementService.submitEntitySectionComment(sectionKey, vm.replyComment, entityId).then((res) => {

                                            if (res) {
                                                var today = new Date().toISOString();
                                                vm.ModalSpinner = false;
                                                var newComment = { Comment: vm.replyComment, FullName: $rootScope.GetCurrentUser().FullName, ActionDataTime: today, ImagePath: $rootScope.GetCurrentUser().ImagePath };
                                                vm.sectionComments.splice(0, 0, newComment);
                                                
                                                _self.SectionCommentsCount++;
                                                vm.replyComment = '';
                                            }
                                        }, function (error) {
                                            errorNotificationService.showErrorNotification(error);
                                        })
                                    }
                                    vm.cancel = function () {
                                        $uibModalInstance.dismiss();
                                    }
                                }]
                            })
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }

                }, function (error) {
                    //TODO show error
                    errorNotificationService.showErrorNotification(error);
                })
            }

            var _getSectionDeletedActivitiesCount = function () {
                annualAchievementMBRGIService.getSectionDeletedActivitiesCount($state.params.sectionID, $state.params.entityID, $state.params.cycleID).then(function (response) {
                    if (response && response.responseCode == 1) {
                        vm.deletedCount = response.responseObject
                    }
                    else {
                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, function (error) {
                    //TODO show error msg
                    errorNotificationService.showErrorNotification(error);
                })
            }

            var _getSectionDeletedActivities = function () {
                uiLoadingIndicators.load('activities', true);
                annualAchievementMBRGIService.getSectionDeletedActivities($state.params.sectionID, $state.params.entityID, $state.params.cycleID).then(function (response) {
                    uiLoadingIndicators.load('activities', false);
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        if (_data && _data.cards && _data.cards.length > 0) {
                            vm.cards = _map(_data.cards, (card) => {
                                card.title = card.titleEn || card.titleAr || '';
                                card.entityName = '';
                                return card;
                            });
                            vm.sectionTitle = _data.sectionSingleTitle;
                        }
                    } else {
                        vm.Back();
                    }
                }, function (error) {
                    uiLoadingIndicators.load('activities', false);
                })
            }

            vm.restoreActivity = function (activityID) {
                uiLoadingIndicators.load('activities', true);
                annualAchievementMBRGIService.restoreActivity(activityID).then(function (response) {
                    uiLoadingIndicators.load('activities', false);
                    if (response && response.responseCode == 1) {
                        //reload to reflect change in section state 
                        vm.goToDeleted();

                        $state.reload();

                        successrNotificationService.showSuccessRestoringNotfication(vm.sectionTitle);
                    }
                }, function (error) {
                    errorNotificationService.showErrorNotification(error);
                })
            }

            var _init = function () {
                if ($state.$current.name == 'MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.DeletedSubmissions') {
                    _getSectionDeletedActivities();
                }
                else {
                    _getSectionActivities();
                    var currentRole = $rootScope.GetCurrentUser().CurrentRoleID;
                    
                    if (currentRole==1)
                    _getSectionDeletedActivitiesCount();
                }
            }()
            vm.CheckIsSectionRequired = function () {
                
                //disabled by default
                if ($state.params.sectionID) {
                    var _currentSection = sectionsMBRGIService.getSection($state.params.sectionID);
                    if (_currentSection)
                        vm.IsSectionRequired = _currentSection.isRequired;
                }
            }
            vm.goToDeleted = function () {
                $state.go("MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.DeletedSubmissions", { sectionID: vm.sectionID });
            }

            vm.Back = function () {

                $state.go("MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.Submissions", { sectionID: vm.sectionID });
            }

            $scope.$on('$destroy', function () {
                if (_addActivityModalInstance && _addActivityModalInstance.close) {
                    _addActivityModalInstance.close();
                }

                if (_deleteConfirmationModal && _deleteConfirmationModal.close) {
                    _deleteConfirmationModal.close();
                }

                if (_viewHistoryModal && _viewHistoryModal.close) {
                    _viewHistoryModal.close();
                }

                if (_viewCommentModal && _viewCommentModal.close) {
                    _viewCommentModal.close();
                }
                if (_confirmSubmitAchievementsModalInstance && _confirmSubmitAchievementsModalInstance.close) {
                    _confirmSubmitAchievementsModalInstance.close();
                }

            });
        }])
}