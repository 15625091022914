export default function (app) {
    app.directive('columnChartReport', function () {
        
        return {
            
            restrict: 'E',
            template: '<div></div>',
            scope: {
                title: '@',
                language:'@',
                data: '=',
                isOneChart: '=',
                yAxisTitle: '@',
                categories: '=',
                subtitle: '@'
            },
            controller: ['$scope', function ($scope) {
            }],
         

            link: function (scope, element) {
               
                if (scope.isOneChart) {
                    element.addClass('w-100');
                } else {
                    element.addClass('w-50');
                }
                var IsArabic = false;
                if (scope.language == 'ar')
                    IsArabic = true;
                Highcharts.setOptions({
                    lang: {
                        thousandsSep: ','
                    }
                });
                Highcharts.chart(element[0], {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: scope.title
                    },
                    legend: {
                        rtl: IsArabic
                    },
                    subtitle: {
                        text: scope.subtitle
                    },
                    xAxis: {
                        labels: {
                            useHTML: true,
                        },
                        categories: scope.categories, //scope.categories ,// need to replace it by scope of categroies
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: scope.yAxisTitle
                        }
                    },
                    tooltip: {
                            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>{point.y} </b></td></tr>',
                            footerFormat: '</table>',
                            shared: true,
                            useHTML: true
                        },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0, dataLabels: {
                                //format: '{point.y:,.2f}',
                                enabled: true,
                            }
                        }
                    },
                    series: scope.data,
                    exporting: {
                        allowHTML: true,
                        chartOptions: {
                            chart: {
                                height: 600
                            },
                            legend: {
                                //useHTML:true,
                                itemWidth: 200,
                                rtl: IsArabic,
                                navigation: {
                                    enabled: false,
                                }
                            }
                        },
                        filename: scope.title
                    }
                });
              
            }
        };
    });
}


