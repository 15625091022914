export default function (app) {
    app.factory('entityDashboardDataService', DashboardDataService);

    DashboardDataService.$inject = ['apiBase', 'ApiPath'];
    function DashboardDataService(apiBase, ApiPath) {
        var entity = "EntityDashboard";
        var controllerUrl = ApiPath + entity + "/";
        function GetCurrentCycleSummary() {
            return apiBase.Get(controllerUrl + "GetCurrentCycleSummary",null, null);
        }
        function GetEntityAnnualAchievementSummary(entityID) {
            return apiBase.Get(controllerUrl + "GetEntityAnnualAchievementSummary", { entityID: entityID}, null);
        }
        function GetUserEditingTasksSummary(userID,entityID, taskType) {
            return apiBase.Get(controllerUrl + "GetUserEditingTasksSummary", { userID: userID, entityID: entityID, taskType: taskType }, null);
        }
        function GetUserReviewingTasksSummary(userID, entityID,taskType) {
            return apiBase.Get(controllerUrl + "GetUserReviewingTasksSummary", { userID: userID, entityID: entityID, taskType: taskType }, null);
        }
        function GetUserAssignedSectionsSummary(userID, entityID) {
            return apiBase.Get(controllerUrl + "GetUserAssignedSectionsSummary", { userID: userID, entityID: entityID}, null);
        }
        return {
            GetCurrentCycleSummary: GetCurrentCycleSummary,
            GetEntityAnnualAchievementSummary: GetEntityAnnualAchievementSummary,
            GetUserEditingTasksSummary: GetUserEditingTasksSummary,
            GetUserReviewingTasksSummary: GetUserReviewingTasksSummary,
            GetUserAssignedSectionsSummary: GetUserAssignedSectionsSummary
            }
    }
}