export default {
    ADD_ENTITY: 'Add new entity',
    UPDATE_ENTITY: 'Update Entity',
    ENTITY: 'Entity',
    SELECT_ENTITY: 'Please select which entity you want to manage',
    ACTIVITY: 'Activity',
    NEW: 'New',
    INPROGRESS: 'In Progress',
    APPROVED: 'Approved',
    ENTITY_ADDED: 'Entity has been successfully added',
    DEACTIVATE_ENTITY: 'Deactivate entity',
    WOULD_DEACTIVATE_ENTITY: 'Are you sure you want to deactivate the entity?',
    ENTITY_DEACTIVATED: 'The entity has been deactivated successfully',
    ENTITY_ACTIVATED: 'The entity has been activated successfully',
    CANT_DEACTIVATE: 'You cannot deactivate this entity at this moment',
    ENTITY_EXIST: 'Entity is previously used',
}

