export default function (app) {
    //TODO: Need to implement function to return enums in localization
    app.factory("fileUploadService", FileUploadService);

    FileUploadService.$inject = ["ApiPath", "Upload", "$cookies"];
    function FileUploadService(ApiPath, Upload, $cookies) {
        var entity = "Upload";
        var controllerUrl = ApiPath + entity + "/";
        function UploadFiles(files, uploadType) {
            return Upload.upload({
                url: controllerUrl + 'UploadFile' + '?uploadType=' + uploadType,
                data: { file: files }
                //,
                //headers: { Authorization: $cookies.token ? $cookies.token : $cookies.get("token") }

            });
        }
        return {
            UploadFiles: UploadFiles
            }
        };
    }