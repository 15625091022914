export default function (app) {
    app.controller('updateCountryController', UpdateCountryController);

    UpdateCountryController.$inject = ["$rootScope", "countryDataService", "$uibModalInstance", "Params", "Notification","$translate"];
    function UpdateCountryController($rootScope, countryDataService, $uibModalInstance, Params, Notification, $translate) {
        var vm = this;
        
        $translate(["COUNTRIES.COUNTRY_EXIST", "COUNTRIES.COUNTRY_UPDATED"]).then(function (translations) {
            vm.labels = {
                CountryIsAlreadyExist: translations['COUNTRIES.COUNTRY_EXIST'],
                UpdatedCountry: translations['COUNTRIES.COUNTRY_UPDATED'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.responseCode = $rootScope.enums.ResponseCode;
        vm.country = {};
        vm.load = load;
        vm.cancel = cancel;
        vm.Params = Params;
        vm.updateCountry = updateCountry;


        vm.load();

        function load() {
            vm.Loading();
            countryDataService.GetCountryByID(vm.Params.ID).then(getCountryByIDSuccess, $rootScope.methodPublicError);

        }
        function cancel() {
            $uibModalInstance.close
                ({ Close: true, Ok: false });
        }

        function updateCountry(country) {
            vm.Loading();
            countryDataService.IsCountryExist(country, country.ID).then(isCountryExistSuccess, $rootScope.methodPublicError);
            
        }
        function getCountryByIDSuccess(res) {
            if (res.responseCode == vm.responseCode.Success && res.responseObject != null) {
                vm.country = res.responseObject;
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function isCountryExistSuccess(res) {
            if (res.responseCode == vm.responseCode.NotExist) {
                countryDataService.UpdateCountry(vm.country).then(updateCountrySuccess, $rootScope.methodPublicError);
            }
            else if (res.responseCode == vm.responseCode.Success) {
                Notification.warning({ message: vm.labels.CountryIsAlreadyExist });
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function updateCountrySuccess(res) {
            if (res.responseCode == vm.responseCode.Success) {
                vm.Loading();
                Notification.success({ message: vm.labels.UpdatedCountry });
                $uibModalInstance.close({ Close: true, Ok: true });
            }
            else if (res.responseCode == vm.responseCode.Error) {
                $rootScope.methodPublicError();
            }
        }

    }
}