//Import controllers
import dashboardController from './Controllers/app.entity-dashboard.controller';

//Import services
import dashboardDataService from './Services/entity-dashboard.data.service';

//Import config
import './Localization';

export default function (app) {
    dashboardController(app);
	dashboardDataService(app);

}