export default function (app) {
    app.factory('annualAchievementService', ['apiBase', 'ApiPath','$http', function (apiBase, ApiPath, $http) {
        var _controllerUrl = ApiPath + "Section/";
        var _sharedInitiativesControllerUrl = ApiPath + "SharedActivities/";     
        var _annualCycleController = ApiPath + "AnnualCycle/";
        var _exportEntityAnnualAchievement = function (sectionKeys, cycleID) {
            return apiBase.Get(_controllerUrl + "DownloadSectionsAsExcel", { sectionKeys: sectionKeys, cycleID: cycleID }, null, true, true);
        }
        var _getEntityAnnualAchievement = function (cycleID) {
            return apiBase.Get(_controllerUrl + "GetEntityAnnualAchievement", { cycleID: cycleID }, null);
        }

        var _getSingleSectionDetails = function (sectionkey,cycleID) {
            return apiBase.Get(_controllerUrl + "GetSingleSectionDetails", { sectionKey: sectionkey,cycleID:cycleID}, null);
        }

        var _getAddActivityForm = function (sectionkey) {
            return apiBase.Get(_controllerUrl + "GetAddActivityForm", { sectionKey: sectionkey }, null);
        }

        var _addActivity = function (sectionkey, editorID, reviewersIDs, formmodel) {
            return apiBase.Post(_controllerUrl + "AddActivity", { sectionKey: sectionkey, editorID: editorID, reviewersIDs: reviewersIDs}, formmodel);
        }

        var _getSectionActivities = function (sectionkey,cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionActivities", { sectionKey: sectionkey, cycleID: cycleID}, null);
        }

        var _deleteActivity = function (activityID) {
            return apiBase.Post(_controllerUrl + "DeleteActivity", { activityID: activityID}, null);
        }

        var _restoreActivity = function (activityID) {
            return apiBase.Post(_controllerUrl + "RestoreActivity", { activityID: activityID }, null);
        }
        var _assignSections = function (userId, sections) {
            return apiBase.Post(_controllerUrl + "AssignSections", { assignedTo: userId }, { Parameters: sections});
        }

        var _getSectionActivityDetails = function (activityID, cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionActivityDetails", { activityID: activityID, cycleID: cycleID }, null);
        }

        var _getActivityHistory = function (activityID) {
            return apiBase.Get(_controllerUrl + "GetActivityHistory", { activityID: activityID }, null);
        }
        var _getSectionHistory = function (sectionkey, cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionHistory", { sectionKey: sectionkey, cycleID: cycleID }, null);
        }
        var _getCommentsList = function (activityID) {
            return apiBase.Get(_sharedInitiativesControllerUrl + "GetSharedActivityComments", { activityID: activityID }, null);
        }
        var _getActivityAssigementInfo = function (activityID) {
            return apiBase.Get(_controllerUrl + "GetActivityAssigementInfo", { activityID: activityID }, null);
        }

        var _getSectionAssignementInfo = function (sectionkey) {
            return apiBase.Get(_controllerUrl + "GetSectionAssignementInfo", { sectionKey: sectionkey }, null);
        }

        var _reassignActivity = function (activityid, assignees) {
            return apiBase.Post(_controllerUrl + "ReassignActivity", { activityID: activityid }, assignees);
        }
        var _reopenActivity = function (activityid, assignees) {
            return apiBase.Post(_controllerUrl + "ReopenActivity", { activityID: activityid }, assignees);
        }

        var _reassignSingleSection = function (sectionkey, assignees) {
            return apiBase.Post(_controllerUrl + "ReassignSingleSection", { sectionKey: sectionkey }, assignees);
        }

        var _reopenSingleSection = function (sectionkey, assignees) {
            return apiBase.Post(_controllerUrl + "ReopenSingleSection", { sectionKey: sectionkey }, assignees);
        }
 
        var _submitAnnualAchievementAction = function (entityid, actionid, comment) {
            return apiBase.Post(_controllerUrl + "SubmitAnnualAchievementAction", { entityID: entityid, actionID: actionid, comment: comment }, null); 
        }

        var _getSectionDeletedActivitiesCount = function (sectionkey,cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionDeletedActivitiesCount", { sectionKey: sectionkey, cycleID: cycleID }, null);
        }
        var _getSectionDeletedActivities = function (sectionkey, cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionDeletedActivities", { sectionKey: sectionkey, cycleID: cycleID }, null);
        }
        var _submitEntitySectionComment = function (sectionkey, comment, entityId = 0) {
            return apiBase.Post(_controllerUrl + "SubmitEntitySectionComment", { sectionKey: sectionkey, comment: comment, entityId: entityId }, null);
        }
        var _getSectionCommentsHistory = function (sectionKey,cycleID) {
            return apiBase.Get(_controllerUrl + "GetSectionCommentsHistory", { sectionKey: sectionKey, cycleID: cycleID }, null);
        }
        var _getCyclesList = function (entityId) {
            return apiBase.Get(_annualCycleController + "GetEntityCycles", { entityId: entityId });
        } 
        var _getDelegationForm = function (cycleID) {
            return apiBase.Get(_controllerUrl + "GetDelegationForm", { cycleId: cycleID}, null);
        } 
        var _saveDelegation = function (data) {
            return apiBase.Post(_controllerUrl + "AssignSections", null, data);
        } 
        var _requestSectionEditing = function (comment, sectionKey) {
            return apiBase.Post(_controllerUrl + "RequestSectionEditing", { comment: comment, sectionKey: sectionKey }, null);
        }
        return {
            exportEntityAnnualAchievement:_exportEntityAnnualAchievement,
            getEntityAnnualAchievement: _getEntityAnnualAchievement,
            getSingleSectionDetails: _getSingleSectionDetails,
            getAddActivityForm: _getAddActivityForm,
            addActivity: _addActivity,
            getSectionActivities: _getSectionActivities,
            deleteActivity: _deleteActivity,
            restoreActivity: _restoreActivity,
            assignSections: _assignSections,
            getSectionActivityDetails: _getSectionActivityDetails,
            getActivityHistory: _getActivityHistory,
            getSectionHistory: _getSectionHistory,
            getActivityAssigementInfo: _getActivityAssigementInfo,
            getSectionAssignementInfo: _getSectionAssignementInfo,
            reassignActivity: _reassignActivity,
            reopenActivity: _reopenActivity,
            reassignSingleSection: _reassignSingleSection,
            reopenSingleSection: _reopenSingleSection,
            submitAnnualAchievementAction: _submitAnnualAchievementAction,
            getSectionDeletedActivitiesCount: _getSectionDeletedActivitiesCount,
            getSectionDeletedActivities: _getSectionDeletedActivities,
            getCommentsList: _getCommentsList,
            submitEntitySectionComment: _submitEntitySectionComment,
            getSectionCommentsHistory: _getSectionCommentsHistory,
            requestSectionEditing: _requestSectionEditing,
            getCyclesList: _getCyclesList,
            getDelegationForm: _getDelegationForm,
            saveDelegation: _saveDelegation
        }

    }]);
}