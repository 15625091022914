export default function (app) {
    app.directive("ngValidateCondition", ngValidateCondition);
    ngValidateCondition.$inject =[];
    function ngValidateCondition() {
        return {
           
            require: 'ngModel',
            link: function (scope, elm, attrs, ctrl) {
                scope.$watch(attrs.ngValidateCondition, function (newval) {
                    ctrl.$setValidity('validatecondition', newval);
                    return newval;
                });

            }
        };
    }
}