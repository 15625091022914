import { remove as _remove, map as _map } from 'lodash';
export default function (app) {

    app.controller("MBRGIComparisonReport", ['$scope', 'reportsService', 'exportFileService', 'uiLoadingIndicators', 'taskTypesConstant', 'sectionStatusConstant', 'errorNotificationService', '$stateParams', 'routeConstants', '$state', '$rootScope', '$uibModal', '$timeout', 'actionsTypesConstant', 'successrNotificationService', 'sectionsService', '$translate', 'Excel', '$filter', '$document',
        function ($scope, reportsService,exportFileService, uiLoadingIndicators, taskTypesConstant, sectionStatusConstant, errorNotificationService, $stateParams, routeConstants, $state, $rootScope, $uibModal, $timeout, actionsTypesConstant, successrNotificationService, sectionsService, $translate, Excel, $filter,$document) {
            var vm = this;
            vm.labels = {
                ALL: $translate.instant('REPORTS.ALL'),
                SECTIONS: $translate.instant('COMMON.SECTIONS'),
                ENTITIY: $translate.instant('COMMON.ENTITIY'),
                PILLAR: $translate.instant('COMMON.PILLAR'),
                MBRGI: $translate.instant('COMMON.MBRGI'),
                TOTAL_BENEFICIARIES_PER_PILLAR: $translate.instant('REPORTS.TOTAL_BENEFICIARIES_PER_PILLAR'),
                TOTAL_BENEFICIARIES_PER_CYCLE: $translate.instant('REPORTS.TOTAL_BENEFICIARIES_PER_CYCLE'),
                TOTAL_SPENT_PER_PILLAR: $translate.instant('REPORTS.TOTAL_SPENT_PER_PILLAR'),
                TOTAL_SPENT_PER_CYCLE: $translate.instant('REPORTS.TOTAL_SPENT_PER_CYCLE'),
                Cycles_Comparison: $translate.instant('REPORTS.Cycles_Comparison'),
                TOTAL_BENEFICIARIES_Unit: $translate.instant('REPORTS.TOTAL_BENEFICIARIES_Unit'),
                TOTAL_SPENT_Unit: $translate.instant('REPORTS.TOTAL_SPENT_Unit'),

            }
            $scope.currentLang = $translate.use();
            var entitiesTemp = new Object();
            vm.cycles = [];
            vm.pillars = [];
            vm.entities = new Object();
            vm.selectedLevelId = 1;
            vm.entityLevelId = 3;

            vm.EntityLevelId = 0;
            vm.PillarLevelId = 0;
            vm.pillarsIdList = [];
            vm.CycleIdsList = [];
            vm.entitiesIdList = [];
            vm.selectedPillarId = 0;
            vm.selectedEntities = 0;
            vm.levels = [];
            vm.pillarVisableClass = "hidden";
            vm.entityVisableClass = "hidden";
            vm.displayColumnCharts = false; 
            vm.isOneChartColumn = false;
            vm.isOneChartPerCycle = true;
            vm.yAxisTitle = 'benificaries';
            vm.maxCyclesInOneChart = 15;
            vm.categoriesPerCycle = [];
            vm.Loading = $rootScope.Loading;


            $scope.$watch('vm.CycleIdsList', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    vm.displayExport = false;
                }
                var oldValueIndex = oldValue.indexOf("0");
                var newValueIndex = newValue.indexOf("0");
                if ((oldValueIndex == -1) && (newValueIndex !== -1)) {
                    vm.CycleIdsList = ["0"];
                }
                else if (oldValueIndex !== -1 && newValueIndex !== -1 && newValue.length > 1) {
                    var index = vm.CycleIdsList.indexOf("0");
                    vm.CycleIdsList.splice(index, 1);
                }

            }, true);
            vm.loadingPdfFile = false;
            vm.downloadPdf = function () {
                vm.loadingPdfFile = true;
                var repo = $document[0].getElementById("ReportContent").innerHTML;
                var Data = { value: repo };
                var reportType = $rootScope.enums.ReportTypes['Comparisons'];
                var exportFile = exportFileService.exportToPdfComparisonReport(Data, vm.CycleIdsList, vm.selectedLevelId, reportType);
                Promise.all([exportFile]).then(function (res) {
                    vm.loadingPdfFile = false;
                    $scope.$apply();

                });
            };            
            var _loadFiltersDropdown = function () {
                uiLoadingIndicators.load('report', true);
                _fillLevels();
                var cyclesList = reportsService.getCyclesList();
                var entitiesList = reportsService.getEntitiesList();
                var pillarsList = reportsService.getPillarsList();
                getFiltersRequests(cyclesList, entitiesList, pillarsList);

            }

            vm.PillarChange = function () {
                reportsService.getEntitiesPerPillars(vm.selectedPillarId).then(function (response) {
                    if (response) {
                        debugger;
                        var _data = response.responseObject;
                        vm.selectedEntities = 0;
                        if (isEmpty(_data)) {
                            vm.entities = new Object();
                        }
                        else {
                            vm.entities = _data;
                        }
                        if (vm.selectedPillarId == 0) {
                            vm.entities = entitiesTemp;
                        }

                    } else {

                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                }, function (error) {

                    //normal error
                    errorNotificationService.showErrorNotification(error);
                });
            }
            function getFiltersRequests(cyclesList, entitiesList, pillarsList) {
                Promise.all([cyclesList, entitiesList, pillarsList]).then(function (res) {
                    if (res != undefined) {
                        getCyclesList(res[0]);
                        getEntitiesList(res[1]);
                        getPillarsList(res[2]);
                        $scope.$apply();
                    }
                    uiLoadingIndicators.load('report', false);

                });
            }
            var _fillLevels = function () {
                var dict = [];
                dict.push({
                    key: vm.labels.MBRGI,
                    value: $rootScope.enums.ReportLevels['MBRGI']
                });
                dict.push({
                    key: vm.labels.PILLAR,
                    value: $rootScope.enums.ReportLevels['Pillars']
                });
                dict.push({
                    key: vm.labels.ENTITIY,
                    value: $rootScope.enums.ReportLevels['Entities']
                });
                vm.levels = dict;
                vm.EntityLevelId = $rootScope.enums.ReportLevels['Entities'];
                vm.PillarLevelId = $rootScope.enums.ReportLevels['Pillars'];
            }
            function getCyclesList(response) {

                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data.length > 0) {
                        vm.cycles.push({ name: vm.labels.ALL, ID: 0 });
                        for (var i = 0; i < _data.length; i++) {
                            var name = "";
                            if ($rootScope.currentLang == 'en') {
                                name = _data[i].NameEn;
                            }
                            else {
                                name = _data[i].NameAr;
                            }
                            vm.cycles.push({ name: name, ID: _data[i].ID });
                        }


                    }
                } else {

                    errorNotificationService.showErrorNotification(response.responseCode);
                }

            }
            function getEntitiesList(response) {
         
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data != null && !(angular.equals({}, _data))) {
                        vm.entities = _data;
                        entitiesTemp = vm.entities;
                    }
                } else {

                    errorNotificationService.showErrorNotification(response.responseCode);
                }
            }
            function getPillarsList(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    if (_data.length > 0) {
                        for (var i = 0; i < _data.length; i++) {
                            var name = "";
                            if ($rootScope.currentLang == 'en') {
                                name = _data[i].NameEn;
                            }
                            else {
                                name = _data[i].NameAr;
                            }
                            vm.pillars.push({ name: name, ID: _data[i].ID });
                        }
                    }
                } else {

                    errorNotificationService.showErrorNotification(response.responseCode);
                }


            }

            var _init = function () {
                vm.Loading();
                _loadFiltersDropdown();
                vm.Loading();
            }();

            var _checkIfAllSelected = function () {
                var index = vm.CycleIdsList.indexOf("0")
                if (index !== -1) {
                    vm.CycleIdsList = _map(vm.cycles, (item) => {
                        return item.ID;
                    });

                    var indexZero = vm.CycleIdsList.indexOf(0);
                    vm.CycleIdsList.splice(indexZero, 1);
                }
            }
            vm.ChangeLevel = function () {
                if (vm.selectedLevelId == $rootScope.enums.ReportLevels['MBRGI']) {
                    vm.pillarVisableClass = "hidden";
                    vm.entityVisableClass = "hidden";
                    var pillar = angular.element(document.querySelector('#pillarDiv'));
                    var entity = angular.element(document.querySelector('#enitityDiv'));
                    pillar.removeClass('visible');
                    entity.removeClass('visible');
                    pillar.addClass('hidden d-none');
                    entity.addClass('hidden d-none');


                }
                else if (vm.selectedLevelId == $rootScope.enums.ReportLevels['Pillars']) {
                    vm.pillarVisableClass = "visible";
                    vm.entityVisableClass = "hidden d-none";
                    var pillar = angular.element(document.querySelector('#pillarDiv'));
                    var entity = angular.element(document.querySelector('#enitityDiv'));
                    pillar.removeClass('hidden d-none');
                    entity.removeClass('visible');
                    pillar.addClass('visible');
                    entity.addClass('hidden d-none');

                }
                else if (vm.selectedLevelId == $rootScope.enums.ReportLevels['Entities']) {
                    vm.pillarVisableClass = "visible";
                    vm.entityVisableClass = "visible";
                    var pillar = angular.element(document.querySelector('#pillarDiv'));
                    var entity = angular.element(document.querySelector('#enitityDiv'));
                    pillar.removeClass('hidden d-none');
                    entity.removeClass('hidden d-none');
                    pillar.addClass('visible');
                    entity.addClass('visible');

                }
            }
            function isEmpty(obj) {
                for (var key in obj) {
                    if (obj.hasOwnProperty(key))
                        return false;
                }
                return true;
            }


            vm.GenerateReport = function () {
                uiLoadingIndicators.load('report', true);
                _initReportData();
                if (vm.selectedLevelId == $rootScope.enums.ReportLevels['MBRGI']) {
                    var Charts = reportsService.getComparsionStatisticsPerCycle(vm.CycleIdsList, vm.selectedLevelId, vm.selectedEntities, vm.selectedPillarId);
                    var reportdata = reportsService.getComparsionReportInfo(vm.CycleIdsList, vm.selectedLevelId, vm.selectedEntities, vm.selectedPillarId);
                    var columnCharts = reportsService.getMBRGIComparsionStatisticsPerCyclePerPillar(vm.CycleIdsList);
                    Promise.all([Charts, reportdata, columnCharts]).then(function (res) {
                        if (res != undefined) {
                            getComparsionStatisticsReportInfo(res[0]);
                            getReports(res[1]);
                            getComparsionStatisticsColumnchart(res[2]);
                            $scope.$apply();
                        }
                        uiLoadingIndicators.load('report', false);

                    }, function (error) {
                        errorNotificationService.showErrorNotification(error);
                        uiLoadingIndicators.load('report', false);
                    });
                }
                else {
                    var Charts = reportsService.getComparsionStatisticsPerCycle(vm.CycleIdsList, vm.selectedLevelId, vm.selectedEntities, vm.selectedPillarId);
                    var reportdata = reportsService.getComparsionReportInfo(vm.CycleIdsList, vm.selectedLevelId, vm.selectedEntities, vm.selectedPillarId);
                    Promise.all([Charts, reportdata]).then(function (res) {
                        if (res != undefined) {
                            getComparsionStatisticsReportInfo(res[0]);
                            getReports(res[1]);
                            $scope.$apply();
                        }
                        uiLoadingIndicators.load('report', false);

                    }, function (error) {
                        errorNotificationService.showErrorNotification(error);
                        uiLoadingIndicators.load('report', false);
                    });
                }
            }
            vm.EntitiesGenerateReport = function () {
                uiLoadingIndicators.load('report', true);
                _initReportData();
               
                    
                var Charts = reportsService.getComparsionStatisticsPerCycleEntities(vm.CycleIdsList);
                var reportdata = reportsService.getComparisonEntitiesReportInfo(vm.CycleIdsList);
                    Promise.all([Charts, reportdata]).then(function (res) {
                        if (res != undefined) {
                            getComparsionStatisticsReportInfo(res[0]);
                            getReportsEntities(res[1]);
                            $scope.$apply();
                        }
                        uiLoadingIndicators.load('report', false);

                    }, function (error) {
                        errorNotificationService.showErrorNotification(error);
                        uiLoadingIndicators.load('report', false);
                    });
                
            }


            function getReportsEntities(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    vm.reportInfo = response.responseObject.Result;
                    vm.totals = response.responseObject.ResultTotals;
                    vm.coulmnKeys = response.responseObject.ColumnName;
                    if (vm.reportInfo.length > 0 && vm.reportInfo) {

                        vm.displayReport = true;
                        vm.displayExport = true;
                            vm.isEntityPillarLevel = true;
                       
                        if (isEmpty(vm.reportInfo[0].Data)) {
                            
                            vm.displayReport = false;
                            vm.isEmptyList = true;
                        }
                    }
                    else {
                        vm.displayReport = false;
                        vm.isEmptyList = true;
                    }
                }
            }
            function getReports(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    vm.reportInfo = response.responseObject.Result;
                    vm.totals = response.responseObject.ResultTotals;
                    vm.coulmnKeys = response.responseObject.ColumnName;
                    if (vm.reportInfo.length > 0 && vm.reportInfo) {

                        vm.displayReport = true;
                        vm.displayExport = true;
                        if (vm.selectedLevelId == $rootScope.enums.ReportLevels['MBRGI']) {
                            vm.isMBRGILevel = true;
                            vm.isEntityPillarLevel = false;
                        }
                        else if (vm.selectedLevelId == $rootScope.enums.ReportLevels['Pillars']) {
                            vm.isMBRGILevel = false;
                            vm.isEntityPillarLevel = true;

                        } else if (vm.selectedLevelId == $rootScope.enums.ReportLevels['Entities']) {
                            vm.isMBRGILevel = false;
                            vm.isEntityPillarLevel = true;
                        }
                        if (isEmpty(vm.reportInfo[0].Data)) {
                            
                            vm.displayReport = false;
                            vm.isEmptyList = true;
                        }


                    }
                    else {
                        vm.displayReport = false;
                        vm.isEmptyList = true;
                    }
                }
            }
            var _initReportData = function () {
                vm.displayCharts = false;
                vm.displayReport = false;
                vm.isOneChart = false;
                vm.isEmptyList = false;
                vm.displayColumnCharts = false; 

                _checkIfAllSelected();
            }
            function getComparsionStatisticsReportInfo(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    // if object is empty hide piecharts 
                    if (_data.length > 0) {
                        
                        _fillChartsDataPerCycle(_data);
                        vm.displayCharts = true;
                    }
                }

            }
            function getComparsionStatisticsColumnchart(response) {
                if (response && response.responseCode == 1 && response.responseObject) {
                    var _data = response.responseObject;
                    // if object is empty hide piecharts 
                    if (isEmpty(_data) == false) {
                        _fillColumnChartsData(_data);
                       vm.displayColumnCharts = true;
                    }
                }

            }

            var _fillChartsDataPerCycle = function (_data) {
                vm.isOneChartPerCycle = true;
                vm.totalBeneficiariesPerCycle = [];
                vm.totalSpentPerCycle = [];    
                vm.categoriesPerCycle = [];
                var counter = 0;                
                for (var i = 0; i < _data.length; i++) {
                    var name = "";
                    if ($rootScope.currentLang == 'en') {
                        name = _data[i].CycleNameEn;
                    }
                    else {
                        name = _data[i].CycleNameAr;
                    }
                    if (_data[i].TotalSpentPercentage == 'NaN' || _data[i].TotalSpentPercentage == 0)
                    {
                        _data[i].TotalSpentPercentage = 0;
                    }
                    if (_data[i].TotalBeneficiariesPercentage == 'NaN' || _data[i].TotalBeneficiariesPercentage == 0) {
                        _data[i].TotalBeneficiariesPercentage = 0;
                    }
                    vm.categoriesPerCycle.push(name);
                    vm.totalSpentPerCycle.push({ name: name, data: [[counter, _data[i].TotalSpentPercentage] ]});                                                       
                    vm.totalBeneficiariesPerCycle.push({ name: name, data: [[counter, _data[i].TotalBeneficiariesPercentage]] })                    
                    counter++;   
                }
              
                if (vm.totalBeneficiariesPerCycle.length > 0 && vm.totalBeneficiariesPerCycle.length < vm.maxCyclesInOneChart && vm.totalSpentPerCycle.length > 0 && vm.totalSpentPerCycle.length < vm.maxCyclesInOneChart) {
                    vm.isOneChartPerCycle = false;
                }
              
            }
            var _fillColumnChartsData = function (_data) {
                vm.isOneChart = false;
                vm.totalBeneficiariescoulmnData = _data.TotalBeneficiaries;
                vm.totalSpentcolumnData = _data.TotalSpent;
                vm.categories = _data.Cycles;
                if (vm.categories.length > 15) {
                    vm.isOneChart = true;
                }
                else {
                    if (vm.totalBeneficiariescoulmnData.length > 0 && vm.totalSpentcolumnData.length > 0) {
                        vm.isOneChart = false;
                    }
                    else {
                        vm.isOneChart = true;
                    }
                }
            }
            vm.exportToexcel = function (tableId) {
                vm.loadingExcelFile = true;
                var exportFile = exportExcel();
                Promise.all([exportFile]).then(function (res) {
                    vm.loadingExcelFile = false;
                    $scope.$apply();
                });
            };
            var exportExcel = function () {
                return reportsService.exportComparsionReportInfo(vm.CycleIdsList, vm.selectedLevelId, vm.selectedEntities, vm.selectedPillarId).then(function (response) {
                    exportFileService.exportExcel(response);


                }, function (error) {
                    //normal error
                    errorNotificationService.showErrorNotification(error);
                });
            }
            
        }])
}