import elementWidth from './elementWidth';
import formlyWizard from './formlyWizard';
import hijriDatepickerParser from './hijriDatepickerParser';
import responsiveLayout from './responsiveLayout';
import transformToNumeric from './transformToNumericDirective'
import signaturePad from './signaturePadDirective';
import datePicker from './datePickerDirective';
import horizontalLayout from './horizontalLayout';
import thousandFormatter from './thousandFormatterDirective';
import minDirective from './minDirective';
import maxDirective from './maxDirective';
import domWatcher from './domWatcher';

export default function (app) {
    elementWidth(app);
    formlyWizard(app);
    hijriDatepickerParser(app);
    responsiveLayout(app);
    transformToNumeric(app);
    signaturePad(app);
    datePicker(app);
    horizontalLayout(app);
    thousandFormatter(app);
    minDirective(app);
    maxDirective(app);
    domWatcher(app);
}