export default function (app) {
  app.factory("uiLoadingIndicators", function () {
    const indicatorLoadingCount = {};

    let loadingChangeSubscribers = [];

    function notifyLoadingChangeSubscribers(name, isLoading) {
      loadingChangeSubscribers.forEach(function (subscriberHandler) {
        subscriberHandler(name, isLoading);
      });
    }
    return {
      subscribeToLoadingChange: function subscribeToLoadingChange(handler) {
        loadingChangeSubscribers.push(handler);
        return () => {
          const index = loadingChangeSubscribers.indexOf(handler);
          if (index >= 0) {
            loadingChangeSubscribers.splice(index, 1);
          }
        };
      },
      load: function load(name, isLoading) {
        const prevIsLoading = !!indicatorLoadingCount[name];
        if (isLoading) {
          // if the counter is 0 or more increment the value by one otherwise (undefined) assign it the value of one
          indicatorLoadingCount[name] = indicatorLoadingCount[name] >= 0 ? (indicatorLoadingCount[name] + 1) : 1;
        }
        else {
          // if the counter is greater than 0 decrement the value by one otherwise (undefined or zero) return assign it the value of zero
          indicatorLoadingCount[name] = indicatorLoadingCount[name] > 0 ? (indicatorLoadingCount[name] - 1) : 0;
        }
        //remove 0 counters to save memory
        if (indicatorLoadingCount[name] === 0) {
          delete indicatorLoadingCount[name];
        }
        //notify subscribers only if loading state is changed
        if (prevIsLoading !== (!!indicatorLoadingCount[name])) {
          notifyLoadingChangeSubscribers(name, !!indicatorLoadingCount[name])
        }
      },
      isLoading: function isLoading(names) {
        let oneIsLoading = false;
        if (Array.isArray(names)) {
          names.forEach(function (name) {
            // isLoading is true if only one of the indicators are loading
            oneIsLoading = oneIsLoading || !!indicatorLoadingCount[name];
          });
        }
        else {
          oneIsLoading = !!indicatorLoadingCount[names];
        }
        return oneIsLoading;
      }
    };
  });
}
