export default function (app) {
    app.controller('cycleController', PillarController);

    PillarController.$inject = ["$scope","$rootScope", "cycleDataService", "$uibModal","Notification","ngArabicDateFilter"];
    function PillarController($scope,$rootScope, cycleDataService, $uibModal,Notification, ngArabicDateFilter) {
        var vm = this;
        vm.labels = {
        };
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.cycle = {};
        vm.Cycles = [];
        vm.load = load;
        vm.getAllCycles = getAllCycles;
        vm.openUpdateModal = openUpdateModal;
        vm.openAddModal = openAddModal;
        vm.Modal = null;
        vm.cyclesFilter = cyclesFilter;
        vm.load();

        function load() {
            vm.Loading();
            vm.getAllCycles();
        }

        function getAllCycles() {
            cycleDataService.GetAllCycles().then(getAllCyclesSuccess, $rootScope.methodPublicError);
        }
        function cyclesFilter(item) {
            if (vm.search) {
                return item.NameEn.toLowerCase().includes(vm.search.toLowerCase()) || item.NameAr.toLowerCase().includes(vm.search.toLowerCase()) || item.StartDate.includes(vm.search) || item.EndDate.includes(vm.search)
                    || item.DataEntryStartDate.includes(vm.search) || item.DataEntryEndDate.includes(vm.search) || item.CuttOffDate.includes(vm.search);
            }
            else
                return true;
        }
        function openUpdateModal(cycle) {
            vm.Modal = $uibModal.open({
              // backdrop: "static",
                keyboard: true,
                size: 'lg',
                animation: true,
               //backdropClick: true,
                templateUrl: 'ng/Modules/MBRGI/Cycle/Views/update-cycle.html',
                controller: 'updateCycleController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () { return cycle; }
                }
            });
            vm.Modal.result.then(function (result) {
                
                if (result.Ok)
                     vm.load();
            }, function (error) {
                //backdrop clicked or error
            });
        }
        function openAddModal() {
            vm.Modal = $uibModal.open({
                backdrop: true,
                keyboard: true,
                size: 'lg',
                animation: true,
               //backdropClick: true,
                templateUrl: 'ng/Modules/MBRGI/Cycle/Views/add-cycle.html',
                controller: 'addCycleController',
                controllerAs: 'vm',
                resolve: {
                    Params: function () { }
                }
            });
            vm.Modal.result.then(function (result) {
                if (result.Ok)
                    vm.load();
            }, function (error) {
                //backdrop clicked or error
            });
        }
        function getAllCyclesSuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.Cycles = res.responseObject;
                for (var i = 0; i < vm.Cycles.length; i++) {
                    vm.Cycles[i].StartDate = ngArabicDateFilter(vm.Cycles[i].StartDate, $rootScope.currentLang == 'ar');
                    vm.Cycles[i].EndDate = ngArabicDateFilter(vm.Cycles[i].EndDate, $rootScope.currentLang == 'ar');
                    vm.Cycles[i].DataEntryStartDate = ngArabicDateFilter(vm.Cycles[i].DataEntryStartDate, $rootScope.currentLang == 'ar');
                    vm.Cycles[i].DataEntryEndDate = ngArabicDateFilter(vm.Cycles[i].DataEntryEndDate, $rootScope.currentLang == 'ar');
                    vm.Cycles[i].CuttOffDate = ngArabicDateFilter(vm.Cycles[i].CuttOffDate, $rootScope.currentLang == 'ar');
                }
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        $scope.$on('$destroy', function () {
            //close all opened modal in this controller on destroying htis controller
            if (vm.Modal != null) {
                vm.Modal.dismiss();
            }
        });
     
    }
}