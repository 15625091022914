export default {

    GENERATE: 'عرض',
    EXPORT: 'تحميل',
    TOTAL: 'الإجمالي',
    LEVEL: 'تصنيف التقرير',
    TOTAL_BENEFICIARIES_PER_PILLAR: 'إجمالي المستفيدين لكل محور',
    TOTAL_SPENT_PER_PILLAR: 'إجمالي الإنفاق لكل محور',
    TOTAL_BENEFICIARIES_PER_CYCLE: 'إجمالي المستفيدين لكل دورة',
    TOTAL_SPENT_PER_CYCLE: 'إجمالي المصروفات لكل دورة',
    ALL: 'الكل',
    EXPORT_SECTIONS: 'تحميل الأقسام‏',
    ALL_SECTIONS: 'الأقسام',
    Cycles_Comparison: 'مقارنة الدورات',
    TOTAL_BENEFICIARIES_Unit: 'مستفيدين ',
    TOTAL_SPENT_Unit: 'درهم',
    Report_Sections: 'تقرير ملخص للأقسام',
    Report_Comparison: 'تقرير المقارنة',
    Report_Countries: 'تقرير الدول',
    Report_Scholarships: 'تقرير المنح ',
    Report_Awards: 'تقرير الجوائز',
    Report_Beneficiaries: 'تقرير المستفيدين',
    Report_List: 'التقارير',
    Countries: 'الدولة',
    Countries_Total_BENEFICIARIES: 'إجمالي المستفيدين لكل دولة',
    Countries_Total_Cost: 'إجمالي الإنفاق لكل دولة',
    Award_Report: 'اسم الجائزة',
    Scholarship_Report: 'الاسم',
    Entity_Name: 'اسم الجهة',
    ScholarshipType_Total: 'إجمالى',
    Beneficiaries_Unit: 'الوحدة',
    PDF: 'PDF',
    EXCEL: 'EXCEL',
    Report_Summary_Totals: 'ملخص التقرير',
    Financial_Report: 'تقرير المالي',
    Investment: 'الاستثمار',
    Endowments_Grants: 'الهبات و المنح',
    Expenses: 'الإنفاق',
    Income: 'الدخل',
    Grand_Total: 'المجموع الإجمالي',
    Actual: 'الفعلي',
    Rounded: 'تقريبا'
}
