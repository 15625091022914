import { remove as _remove, map as _map } from 'lodash';
export default function (app) {

    app.controller("sharedInitiativesController", ['$scope', 'sharedInitiativesService', 'uiLoadingIndicators', 'errorNotificationService', '$stateParams', 'routeConstants', '$state', '$rootScope', '$uibModal', '$timeout', 'actionsTypesConstant', 'successrNotificationService', 'sectionsService', '$translate',
        function ($scope, sharedInitiativesService, uiLoadingIndicators, errorNotificationService, $stateParams, routeConstants, $state, $rootScope, $uibModal, $timeout, actionsTypesConstant, successrNotificationService, sectionsService, $translate) {
            var vm = this;
            vm.Loading = $rootScope.Loading;
            vm.cards = [];            
            vm.sharedInitiativesFilter = sharedInitiativesFilter;            
            var _getSectionActivities = function () {
                uiLoadingIndicators.load('sharedIntiatives', true);
                sharedInitiativesService.getSectionActivities().then(function(response) {
                    if (response && response.responseCode == 1 && response.responseObject) {
                        var _data = response.responseObject;
                        if (_data.length > 0) {
                            vm.cards = _map(_data, (card) => {
                                card.title = card.titleEn || card.titleAr || '';
                                if ('IsShared' in card) {
                                    if (card.IsShared) {
                                        if ('ActivitySharedBy' in card) {
                                            card.entityName = card.ActivitySharedBy.titleEn || card.ActivitySharedBy.titleAr || '';
                                        }
                                    }
                                }
                                
                                return card;
                            });
                        }
                    } else {

                        errorNotificationService.showErrorNotification(response.responseCode);
                    }
                    uiLoadingIndicators.load('sharedIntiatives', false);
                },function(error) {
                    errorNotificationService.showErrorNotification(response.responseCode);
                    uiLoadingIndicators.load('sharedIntiatives', false);
                })
            }
            function sharedInitiativesFilter(item) {
                if (vm.search) {
                    return (item.title.toLowerCase().includes(vm.search.toLowerCase())) || (item.ActivitySharedBy.titleAr.toLowerCase().includes(vm.search.toLowerCase())) || (item.ActivitySharedBy.titleEn.toLowerCase().includes(vm.search.toLowerCase()));
                }
                else
                    return true;
            }
            var _init = function () {
                vm.Loading();
                _getSectionActivities();                
                vm.Loading();
            }()


            

            $scope.$on('$destroy', function () {

            });

        }])
}