export default function (app) {
    app.factory('userDataService', UserDataService);

    UserDataService.$inject = ['apiBase', 'ApiPath'];
    function UserDataService(apiBase, ApiPath) {
        var entity = "User";
        var controllerUrl = ApiPath + entity + "/";

        function GetAll(entityID) {
            return apiBase.Get(controllerUrl + "GetAll", { entityID: entityID} , null);
        }

        function GetUserByID(userID, entityID) {
            return apiBase.Get(controllerUrl + "GetUserByID", { userID: userID, entityID: entityID }, null);
        }

        function AddUser(data, entityID) {
            return apiBase.Post(controllerUrl + "AddUser", { entityID: entityID}, data);
        }
        function IsUserExist(email, roleID ,entityID) {
            return apiBase.Get(controllerUrl + "IsUserExist", { email: email, roleID: roleID, entityID: entityID }, null);
        }
        function UpdateUser(data, entityID) {
            return apiBase.Post(controllerUrl + "UpdateUser", { entityID: entityID }, data);
        }
        function IsUserNameAvailable(email) {
            return apiBase.Get(controllerUrl + "IsUserNameAvailable", { email: email }, null);
        }
        function ChangeActiveStatus(userID, activeState, entityID) {
            return apiBase.Post(controllerUrl + "ChangeActiveStatus", { userID: userID, activeState: activeState, entityID: entityID }, null);
        }
        return {
            GetAll: GetAll,
            GetUserByID: GetUserByID,
            AddUser: AddUser,
            IsUserExist: IsUserExist,
            UpdateUser: UpdateUser,
            IsUserNameAvailable: IsUserNameAvailable,
            ChangeActiveStatus: ChangeActiveStatus
        }
    }
}