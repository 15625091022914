export default function (app) {
    app.factory('mbrgiDashboardDataService', DashboardDataService);

    DashboardDataService.$inject = ['apiBase', 'ApiPath'];
    function DashboardDataService(apiBase, ApiPath) {
        var entity = "MBRGIDashboard";
        var controllerUrl = ApiPath + entity + "/";
        function GetCurrentCycleSummary() {
            return apiBase.Get(controllerUrl + "GetCurrentCycleSummary", null, null);
        }
        function GetAnnualAchievementsSummary() {
            return apiBase.Get(controllerUrl + "GetAnnualAchievementsSummary", null, null);
        }
     
        return {
            GetCurrentCycleSummary: GetCurrentCycleSummary,
            GetAnnualAchievementsSummary: GetAnnualAchievementsSummary
            }
    }
}