'use strict';
/*Load global modules, libraries*/
import 'angular';
import 'angular-mocks'
import 'angular-cookies';
import 'angular-route';
import 'angular-local-storage';
import '@uirouter/angularjs';
import 'ui-bootstrap4';
import 'angular-aria';
import 'angular-messages';
import 'angular-material';
import 'angular-translate';
import 'angular-translate-storage-local';
import 'angular-translate-storage-cookie';
import 'angular-drag-and-drop-lists';
import 'angular-ui-notification';
import 'ng-file-upload';
import 'chart.js';
import 'angular-chart';
import 'ng-knob';
import 'angular-bind-html-compile/angular-bind-html-compile';
import '@iamadamjowett/angular-click-outside'
import * as d3 from "d3";
import { union } from 'lodash';
import "angular-sanitize"
import 'ui-select';
import 'ng-quill';
import 'ngclipboard';
import 'angular-ui-sortable'
/*Load config, controllers, route*/

//Config
import constants from './app.constants';
import excel from './app.Excel';
import routeConfig from './app.route';
import enums from '../Services/app.enums.service';
import localization from './app.loclization.config';
import fileUpload from '../Services/app.upload.service';
import authInterceptor from './app.authInterceptor';

//Services
import baseAPI from '../Services/API/api.service';
import cryptography from '../Services/app.crypto.service';

//Modules
import '../Modules/Form/FormlyBuilder';
import '../Modules/Form/FormlyViewer';
import common from '../Modules/Common';
import entity from '../Modules/MBRGI/Entities';
import home from '../Modules/Home';
import login from '../Modules/Common/Login';
import pillar from '../Modules/MBRGI/Pillars';
import country from '../Modules/MBRGI/Countries';
import lookups from '../Modules/MBRGI/Lookups';
import cycle from '../Modules/MBRGI/Cycle';
import beneficiaryUnit from '../Modules/MBRGI/BeneficiaryUnit';
import report from '../Modules/Reports';
import user from '../Modules/Common/Users';
import { debug } from 'util';
import UserProfile from '../Modules/Common/UserProfile';
//import Dashboard from '../Modules/Dashboard';
import MBRGIDashboard from '../Modules/MBRGI/Dashboard';
import EntityDashboard from '../Modules/Entity/Dashboard';
import EntityProfile from '../Modules/Entity/Profile';
//import workflow from '../Modules/Workflow';

import annualAchievement from '../Modules/AnnualAchievement';
import sharedInitiatives from '../Modules/SharedInitiatives';
import MBRGIannualAchievement from '../Modules/MBRGI/AnnualAchievements';
import sectionRequest from '../Modules/MBRGI/SectionRequests';
import ManageSections from '../Modules/MBRGI/ManageSections';
import notification from '../Modules/Common/Notification';
import tasks from '../Modules/Tasks';
import sectionsAssignment from '../Modules/Entity/SectionsAssignment';
import emailTemplates from '../Modules/MBRGI/EmailTemplates';
import settings from '../Modules/Common/PublicSettings';

const _MBRGIApp = angular.module('app', ['ui.router', 'ngCookies', 'ui.bootstrap', 'ngMessages', 'ngMaterial', 'pascalprecht.translate', 'ui-notification', 'ngFileUpload', 'ngMessages', 'formlyViewer', 'formlyBuilder', 'chart.js', 'ui.knob', 'angular-bind-html-compile', 'angular-click-outside', 'ngSanitize', 'ui.select', 'ngQuill', 'ngclipboard','moment-picker', 'ui.sortable']);

//pass app to all modules, services, and config
authInterceptor(_MBRGIApp);
constants(_MBRGIApp);
excel(_MBRGIApp);
routeConfig(_MBRGIApp);
enums(_MBRGIApp);
localization(_MBRGIApp);
fileUpload(_MBRGIApp);
sharedInitiatives(_MBRGIApp);
baseAPI(_MBRGIApp);
cryptography(_MBRGIApp);

common(_MBRGIApp);
entity(_MBRGIApp);
home(_MBRGIApp);
login(_MBRGIApp);
pillar(_MBRGIApp);
country(_MBRGIApp);
lookups(_MBRGIApp);
cycle(_MBRGIApp);
beneficiaryUnit(_MBRGIApp);
report(_MBRGIApp);
user(_MBRGIApp);
UserProfile(_MBRGIApp);
//Dashboard(_MBRGIApp);
MBRGIDashboard(_MBRGIApp);
EntityDashboard(_MBRGIApp);
//workflow(_MBRGIApp);
EntityProfile(_MBRGIApp);
annualAchievement(_MBRGIApp);
MBRGIannualAchievement(_MBRGIApp);
tasks(_MBRGIApp);
sectionRequest(_MBRGIApp);
ManageSections(_MBRGIApp);
sectionsAssignment(_MBRGIApp);
notification(_MBRGIApp)
emailTemplates(_MBRGIApp);
settings(_MBRGIApp);
angular.module('app').run(Run);

Run.$inject = ['$rootScope', '$state', '$stateParams', '$cookies', 'enumsService', '$window', 'Notification', '$q', 'loginDataService', 'homeNotificationService', '$transitions', '$translate', 'formlyValidationMessages','uiLoadingIndicators'];
function Run($rootScope, $state, $stateParams, $cookies, enumsService, $window, Notification, $q, $loginDataService, $homeNotificationService, $transitions, $translate, formlyValidationMessages, uiLoadingIndicators) {

    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    $rootScope.enums = enumsService;
    $rootScope.currentPage = '';
    $rootScope.IsLogged = false;
    $rootScope.cycleID = null;
    $rootScope.LoadingPanel = { Load: false };
     $rootScope.currentLang;
    if ($cookies.get('currentLanguage')) {
        $rootScope.currentLang = $cookies.get('currentLanguage');
        $translate.use($rootScope.currentLang);
    } else {
        $rootScope.currentLang= $translate.use();
        $cookies.put('currentLanguage', $rootScope.currentLang);
    }
    $translate(['COMMON.ERROR_OCCURRED', 'COMMON.LOGEDOUT', 'COMMON.UNAUTHORIZED','COMMON.DISCONNECTED','COMMON.ERROR_GENERAL']).then(function (translations) {
        $rootScope.labels = {
            ERROR_OCCURRED: translations['COMMON.ERROR_OCCURRED'],
            LOGEDOUT: translations['COMMON.LOGEDOUT'],
            UNAUTHORIZED: translations['COMMON.UNAUTHORIZED'],
            DISCONNECTED: translations['COMMON.DISCONNECTED'],
            ERROR_GENERAL: translations['COMMON.ERROR_GENERAL'],
        }
    });

    $rootScope.appDirection = $rootScope.currentLang == "ar" ? "rtl" : "ltr";
    //TODO: State change events are deprecated, DISABLED and replaced by Transition Hooks as of version 1.0
    //      use https://ui-router.github.io/guide/transitionhooks to read more
    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

        var Name = toState.name.split('.');
        var length = toState.name.split('.').length;
        if (length > 1) {
            Name = Name[length - 1];
        }
        else {
            Name = toState.name;
        }
        $rootScope.currentPage = $rootScope.GetSystemPages("Page_" + Name);
    });

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {
        //TODO: fire action when the navigation start
    });
     var previousUrl;
     $rootScope.$on(
        '$locationChangeStart',
        function (event, newUrl, oldUrl) {
            // Prevent the browser to get back if logged out
            if (previousUrl && previousUrl === newUrl && !$rootScope.GetCurrentUser()) {
                //Browser is navigating back
                event.preventDefault();            
            } else {
                previousUrl = oldUrl;
            }
        }
    );
    
    var _onSuccess = $transitions.onSuccess({}, function ($transition) {
        $(window).scrollTop(0);
     });

    $rootScope.$on('$destroy', function () {

        if (_onSuccess) {
            _onSuccess();
        }
    });

    //TODO: Move this function to authentication service
    $rootScope.GetCurrentUser = function () {
        //TODO: get userinfo from cookies
        if ($rootScope.currentLoggedInUser) {
            if ($rootScope.currentLoggedInUser.FullName === "null null")
                $rootScope.currentLoggedInUser.FullName = " ";
            return $rootScope.currentLoggedInUser;
        }
        else {
            return null;
        }
    };

    $rootScope.SetCurrentUser = function (value) {
        $rootScope.currentLoggedInUser = value;
    };
    $rootScope.SetExpireTime = function (minutes) {
        var date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        return date;
    }
    $rootScope.setAlreadyLoggedFlag = function (value) {
        $cookies.put('al', value);
    }

    $rootScope.getAlreadyLoggedFlag = function (value) {
        return $cookies.get('al');
    }
    $rootScope.IsAuthenticated = function () {
        var promise = null;
        promise = $q.defer();
        if ($rootScope.currentLoggedInUser) {
            
            promise.resolve({ authenticated: true });
        }
        else {
            if ($rootScope.getAlreadyLoggedFlag() == "true") {
                $loginDataService.LoadCurrentUser().then(function (response) {
                    if (response) {
                        $rootScope.SetCurrentUser(response.responseObject);
                        promise.resolve({ authenticated: true });
                    }
                    else {
                        promise.reject();
                    }
                }, $rootScope.methodPublicError);

            }
            else {
                
                promise.resolve({ authenticated: false});
            }
        }
        return promise.promise;
    }
    //check if the target state need the user to be authorized
    $rootScope.IsAuthorized = function (state) {
        var AurhorizationEnum = $rootScope.enums.Autorization;
        var userRoleEnum = $rootScope.enums.userRole;
        var roles = AurhorizationEnum[state];
        var currentUser = $rootScope.GetCurrentUser();
        if (currentUser !== undefined && roles !== undefined) {
            if (roles.indexOf(currentUser.CurrentRole == null || currentUser.CurrentRole == "null" ? null : userRoleEnum[currentUser.CurrentRole]) !== -1) {
                return true;
            }
        }
        return false;
    }
    $rootScope.HideEntityNav = function () {
        var currentUser = $rootScope.GetCurrentUser();
        if (currentUser != null || currentUser != undefined) {
            if ($state.current.name == 'Entity.SwitchEntities' || currentUser.IsProfileCompleted == false) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            $rootScope.DeleteAuthenticationToken();
        }
    }
    $rootScope.GetCurrentEntityID = function ()
    {
        var currentUser = $rootScope.GetCurrentUser();
        if (currentUser != null || currentUser != undefined) {
            return currentUser.CurrentEntity;
        }
    }
    $rootScope.GetEntityClass = function () {
        var currentUser = $rootScope.GetCurrentUser();
        if (currentUser != null || currentUser != undefined) {
            if ($state.current.name == 'Entity.SwitchEntities')
                return "col-lg-12 green-bg";
            else if (currentUser.IsProfileCompleted == false)
                return "col-lg-12";
            else
                return "col-lg-11";
        }
    }
    $rootScope.hideSwitchEntities = function () {
        var currentUser = $rootScope.GetCurrentUser();
        if (currentUser != null || currentUser != undefined) {
            if (currentUser.RoleSection == 'MBRGI') {
                return true;
            } if (currentUser.RoleSection == 'Entity' && currentUser.CurrentEntity !== null && !currentUser.IsMultiEntity) {
                return true;
            }
            return false;
        }
    }
    $rootScope.DeleteAuthenticationToken = function () {
        //TODO: delete token and cookies info and navigate to login state
        $rootScope.setAlreadyLoggedFlag(false);
        $rootScope.SetCurrentUser(null);
        $rootScope.LoadingPanel.Load = false;
        $state.transitionTo('Login');
    };
    $rootScope.Logout = function () {
        $homeNotificationService.stopPull();
        $loginDataService.Logout();
        $rootScope.DeleteAuthenticationToken();
    }
    //Handle unathorized requests
    $rootScope.HandleUnauthorizedActions = function (error) {
        Notification.error({ message: $rootScope.labels.UNAUTHORIZED });
        if ($rootScope.GetCurrentUser().RoleSection === "Entity" && error.data == "403")
        {
            $state.go('Entity.SwitchEntities');
        }
        else {
            $state.go('Error', {}, { location: 'replace' });
        }
    }
    //COMMENT=This should redirect to error page, or login in case of unauthorized
    $state.defaultErrorHandler(function (error) {
        var currentUser = $rootScope.GetCurrentUser();
        
            if (error.detail == "403") // incase of unauthorized
                $rootScope.HandleUnauthorizedActions(error);
            else if (error.detail == "401")//incase of unathenticated
                $state.transitionTo('Login');
            else if (error.detail == "null") {//no completed profile
                if (currentUser && currentUser.RoleSection === "MBRGI")
                    $state.transitionTo('MBRGI.UserProfile');
                else if (currentUser && currentUser.RoleSection === "Entity")
                    $state.transitionTo('Entity.UserProfile');
            }
        
    });

    $rootScope.showErrorMsg = function (error) {

        var msg = error;
        var defaultMessage = $rootScope.labels.ERROR_OCCURRED;
        var errorMsg = null;
        if (typeof error !== "string"
            &&//to handle the case of a 500 statue code. 
            //in such case the .data is containing the error reference number
            error.data !== "string") {
            if (error.status == -1) {
                errorMsg = $rootScope.labels.DISCONNECTED;
            }
            if (error.status == 401) {
                errorMsg = $rootScope.labels.LOGEDOUT;
            }
            else if (error.status == 403) {
                errorMsg = $rootScope.labels.UNAUTHORIZED;
            }//customized error for respose null or undefined 
             else if (error.status == 499 || (error.message !== undefined  && error.message.includes("undefined")))
            {
                errorMsg = defaultMessage;
            }
            else {
                msg = error.data;
            }
            
        }
        if (errorMsg != null)
        {
            Notification.error({ message: errorMsg});
        }
        else if (msg) {
            Notification.error({ message: defaultMessage + msg });
        }
        else {
            Notification.error({ message: defaultMessage });
        }   
    }

    $rootScope.methodPublicError = function (error) {
        $rootScope.showErrorMsg(error);
        $rootScope.LoadingPanel.Load = false;
        uiLoadingIndicators.load('annualAchievement', false);
        uiLoadingIndicators.load('sectionActivity', false);
	}

	$rootScope.ShowError = function(error){
		Notification.error({ message: error });
	}

    $rootScope.Loading = function (load) {
        if (load !== null && load !== undefined)
            $rootScope.LoadingPanel.Load = load;
        else if (load === null || load === undefined) {
            $rootScope.LoadingPanel.Load = $rootScope.LoadingPanel.Load ? false : true;
        }
    }
}

