import { cloneDeep as _cloneDeep, find as _find, findIndex as _findIndex } from 'lodash';
import hijriMoment from 'moment-hijri';
export default function (app) {
    app.controller('addCycleController', AddCycleController);

    AddCycleController.$inject = ["$rootScope", "cycleDataService", "$uibModalInstance", "Params", "Notification", "errorNotificationService", "$translate", "ngArabicDateFilter", "$filter"];
    function AddCycleController($rootScope, cycleDataService, $uibModalInstance, Params, Notification, errorNotificationService, $translate, ngArabicDateFilter, $filter) {
        var vm = this;
        $translate(["CYCLE.CYCLE_ADDED"]).then(function (translations) {
            vm.labels = {
                AddedCycle: translations['CYCLE.CYCLE_ADDED'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.responseCode = $rootScope.enums.ResponseCode;
        vm.Loading();
        vm.cycle = null;
        vm.load = load;
        vm.cancel = cancel;
        vm.addCycle = addCycle;
        vm.dateErrorMsg = null;
        vm.isBeforeDates = isBeforeDates;
        vm.enableAddEntityEntryEndDate = false;
        vm.load();

        function load() {
            vm.Loading();
            var getAllEntitiesCardsInfoFunc = cycleDataService.getAllEntitiesCardsInfo();
            Promise.all([getAllEntitiesCardsInfoFunc]).then(function (res) {
                if (res != undefined) {
                    getAllEntitiesCardsInfoSuccess(res[0]);
                    vm.Loading(false);
                } else {
                    vm.Loading(false);
                }
            });
        }

        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false });
        }

        function addCycle() {
            vm.Loading();
            vm.cycleVM = _cloneDeep(vm.cycle);
            if ($rootScope.currentLang == 'en') {
                vm.cycleVM.StartDate = vm.cycleVM.StartDate.split("-")[2] + '-' + vm.cycleVM.StartDate.split("-")[1] + '-' + vm.cycleVM.StartDate.split("-")[0];
                vm.cycleVM.EndDate = vm.cycleVM.EndDate.split("-")[2] + '-' + vm.cycleVM.EndDate.split("-")[1] + '-' + vm.cycleVM.EndDate.split("-")[0];
                vm.cycleVM.DataEntryStartDate = vm.cycleVM.DataEntryStartDate.split("-")[2] + '-' + vm.cycleVM.DataEntryStartDate.split("-")[1] + '-' + vm.cycleVM.DataEntryStartDate.split("-")[0];
                vm.cycleVM.DataEntryEndDate = vm.cycleVM.DataEntryEndDate.split("-")[2] + '-' + vm.cycleVM.DataEntryEndDate.split("-")[1] + '-' + vm.cycleVM.DataEntryEndDate.split("-")[0];
                vm.cycleVM.CuttOffDate = vm.cycleVM.CuttOffDate.split("-")[2] + '-' + vm.cycleVM.CuttOffDate.split("-")[1] + '-' + vm.cycleVM.CuttOffDate.split("-")[0];
                for (var key in vm.cycle.EntitiesEntryEndDates) {

                    vm.cycleVM.EntitiesEntryEndDates[key] = vm.cycleVM.EntitiesEntryEndDates[key].split("-")[2] + '-' + vm.cycleVM.EntitiesEntryEndDates[key].split("-")[1] + '-' + vm.cycleVM.EntitiesEntryEndDates[key].split("-")[0];
                }
            }
            //if( IsCycleDatesValid(vm.cycle))
            cycleDataService.CreateCycle(vm.cycleVM).then(addCycleSuccess, errorNotificationService.showErrorNotification);
            // else
            // vm.Loading();
        }
        
        function addCycleSuccess(res) {

            if (res.responseCode == vm.responseCode.Success) {
                vm.Loading();
                Notification.success({ message: vm.labels.AddedCycle });
                $uibModalInstance.close({ Close: true, Ok: true });
            } else {
                errorNotificationService.showErrorNotification(res.responseCode);
                vm.Loading();
            }

        }

        function isBeforeDates(minDate, maxDate) {
            if (minDate && maxDate) {
                var firstDate = _cloneDeep(minDate);
                var secondDate = _cloneDeep(maxDate);
                if ($rootScope.currentLang == 'en') {
                    var firstDate = minDate.split("-")[2] + '-' + minDate.split("-")[1] + '-' + minDate.split("-")[0];
                    var secondDate = maxDate.split("-")[2] + '-' + maxDate.split("-")[1] + '-' + maxDate.split("-")[0];
                }
                var valid = hijriMoment(firstDate).isBefore(secondDate);

                return valid;
            }
            return false;
        }

        function getAllEntitiesCardsInfoSuccess(res) {
            if (res && res.responseCode === 1 && res.responseObject) {
                vm.entitiesList = [];
                vm.entities = res.responseObject;
                angular.forEach(vm.entities, function (item) {
                    if (item.isDeleted == false) {
                        vm.entitiesList.push(item);
                    }
                });
            }
            else
                $rootScope.methodPublicError();
        }

        vm.validateAddEntityEntryEndDate = function () {
            if (validateEntityEntryEndDate()) {
                vm.enableAddEntityEntryEndDate = true;
            }
            else {
                vm.enableAddEntityEntryEndDate = false;
            }
        }

        vm.validateDeleteEntityEntryEndDate = function () {
            var today = $filter('date')(new Date(), 'dd-MM-yyyy');
            if (isBeforeDates(vm.cycle.DataEntryEndDate, today)) {
                return true;
            }
            else {
                return false;
            }
        }

        function validateEntityEntryEndDate() {
            if (vm.EntityDataEntryEndDate && vm.entityID) {
                if (isBeforeDates(vm.EntityDataEntryEndDate, vm.cycle.CuttOffDate) && isBeforeDates(vm.cycle.DataEntryStartDate, vm.EntityDataEntryEndDate)) {
                    return true;
                }
            }
            return false;
        }

        vm.addEntryEndDate = function () {
            vm.validateAddEntityEntryEndDate();
            if (vm.enableAddEntityEntryEndDate) {
                if (vm.cycle.EntitiesEntryEndDates === undefined || vm.cycle.EntitiesEntryEndDates === null)
                    vm.cycle.EntitiesEntryEndDates = {};
                vm.cycle.EntitiesEntryEndDates[_cloneDeep(vm.entityID)] = _cloneDeep(vm.EntityDataEntryEndDate);// .push({ key: _cloneDeep(vm.entityID), value: _cloneDeep(vm.EntityDataEntryEndDate) });
                vm.entityID = null;
                vm.EntityDataEntryEndDate = null;
                vm.enableAddEntityEntryEndDate = false;
            }
        }

        vm.deleteEntryEndDate = function (key) {
            delete vm.cycle.EntitiesEntryEndDates[key];
        }

        vm.filterEntityID = function (id) {
            var entity = _find(vm.entities, (entity) => {
                return entity.id == id;
            });
            return entity.title;
        }

        vm.filterDate = function (date) {
            return ngArabicDateFilter(date, $rootScope.currentLang == 'ar');
        }
    }
}