export default function (app) {
    app.constant('actionsTypesConstant', {
        SAVE : 1,
        SUBMIT : 2,
        APPROVE : 3,
        SENDBACK: 4,
        Pick : 5,
        unPick : 6,
        View : 7,
        Edit: 8,
        Reopen: 11,
        CANCEL : -1 // not mapped to backend
    });
}