//Import controllers
import beneficiaryunitController from './Controllers/app.beneficiaryunit.controller';
import updateController from './Controllers/app.update-beneficiaryunit.controller';
import addController from './Controllers/app.add-beneficiaryunit-controller';
import restoreController from './Controllers/app.restore-beneficiaryunit.controller'
//Import services
import dataService from './Services/beneficiaryunit.data.service';
//Import config
import './Localization';

export default function (app) {
    beneficiaryunitController(app);
    updateController(app);
    addController(app);
    restoreController(app);
    dataService(app);
}