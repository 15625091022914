export default function (app) {
    app.factory("successrNotificationService", ['$rootScope', 'Notification','$translate', function ($rootScope, Notification, $translate) {
        //TODO: move this list to translation files
        var _message;
        $translate(["COMMON.ADDING", "COMMON.UPDATING", "COMMON.DELETING","COMMON.DELETINGACTIVITY",
            "COMMON.SUBMITTING", "COMMON.ASSIGNING", "COMMON.MBRGISUBMITTING", "COMMON.SAVING", "COMMON.APPROVING",
            "COMMON.SENDINGBACK", "COMMON.SAVINGACTIVITY", "COMMON.RequestEditingMsg","COMMON.REJECT","COMMON.RESTORED"]).then(function (translations) {

                _message = {
                    'Adding': translations['COMMON.ADDING'],
                    'Updating': translations['COMMON.UPDATING'],
                    'Deleting': translations['COMMON.DELETING'],
                    'Submitting': translations['COMMON.SUBMITTING'],
                    'Assigning': translations['COMMON.ASSIGNING'],
                    'MBRGISubmitting': translations['COMMON.MBRGISUBMITTING'],
                    'Saving': translations['COMMON.SAVING'],
                    'Approving': translations['COMMON.APPROVING'],
                    'SendingBack': translations['COMMON.SENDINGBACK'],
                    'SavingActivity': translations['COMMON.SAVINGACTIVITY'],
                    'DeletingActivity': translations['COMMON.DELETINGACTIVITY'],
                    'Request': translations['COMMON.RequestEditingMsg'],
                    'REJECT': translations['COMMON.REJECT'],
                    'RESTORED': translations['COMMON.RESTORED'],
                }
            });


        var _showSuccessAddingNotfication = function (item) {        	
            Notification.success({ message: _message['Adding'].replace('{0}', item)});
        }
		
        var _showSuccessUpdatingNotfication = function (item) {
            Notification.success({ message: _message['Updating'].replace('{0}', item)});
        }
		
        var _showSuccessDeletingNotfication = function (item) {
            Notification.success({ message: _message['Deleting'].replace('{0}', item)});
        }
        var _showSuccessDeletingActivityNotfication = function () {
            Notification.success({ message: _message['DeletingActivity']});
        }
        var _showSuccessSubmittingNotfication = function () {
            Notification.success({ message: _message['Submitting'] });
        }
        
        var _showSuccessMBRGISubmittingNotfication = function () {
            Notification.success({ message: _message['MBRGISubmitting'] });
        }
        var _showSuccessAssigningNotfication = function (item) {
            Notification.success({ message: _message['Assigning'].replace('{0}', item) });
        }

        var _showSuccessSavingSectionNotfication = function (item) {
            Notification.success({ message: _message['Saving'].replace('{0}', item) });
        }

        var _showSuccessApprovingSectionNotfication = function (item) {
            Notification.success({ message: _message['Approving'].replace('{0}', item) });
        }
        var _showSuccessSendingBackSectionNotfication = function (item) {
            Notification.success({ message: _message['SendingBack'].replace('{0}', item) });
        }
        var _showSuccessSavingActivityNotfication = function (item) {
            Notification.success({ message: _message['SavingActivity'].replace('{0}', item) });
        }
        var _showSuccessSendingRequestNotfication = function () {
            Notification.success({ message: _message['Request'] });
        }
        var _showSuccessRejectSectionNotfication = function (item) {
            Notification.success({ message: _message['REJECT'].replace('{0}', item) });
        }
        var _showSuccessRestoringNotfication = function (item) {
            Notification.success({ message: _message['RESTORED'].replace('{0}', item) });
        }
        return {
        	showSuccessAddingNotfication: _showSuccessAddingNotfication,
        	showSuccessUpdatingNotfication : _showSuccessUpdatingNotfication,
            showSuccessDeletingNotfication: _showSuccessDeletingNotfication,
            showSuccessDeletingActivityNotfication: _showSuccessDeletingActivityNotfication,
            showSuccessSubmittingNotfication: _showSuccessSubmittingNotfication,
            showSuccessMBRGISubmittingNotfication: _showSuccessMBRGISubmittingNotfication,
            showSuccessAssigningNotfication: _showSuccessAssigningNotfication,
            showSuccessSavingSectionNotfication: _showSuccessSavingSectionNotfication,
            showSuccessApprovingSectionNotfication: _showSuccessApprovingSectionNotfication,
            showSuccessSendingBackSectionNotfication: _showSuccessSendingBackSectionNotfication,
            showSuccessSavingActivityNotfication: _showSuccessSavingActivityNotfication,
            showSuccessSendingRequestNotfication: _showSuccessSendingRequestNotfication,
            showSuccessRejectSectionNotfication: _showSuccessRejectSectionNotfication,
            showSuccessRestoringNotfication: _showSuccessRestoringNotfication,
        }
    }]);
}