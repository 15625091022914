import { cloneDeep as _cloneDeep } from 'lodash';

export default function (app) {
    app.controller('entityDashboardController', DashboardController);

    DashboardController.$inject = ["$scope", "$rootScope", "entityDashboardDataService", "Notification", "$timeout","$translate"];
    function DashboardController($scope, $rootScope, entityDashboardDataService, Notification, $timeout, $translate) {
        var vm = this;
        $translate(["COMMON.DAYS"]).then(function (translations) {
            vm.labels = {
                days: translations['COMMON.DAYS'],
            }
            vm.options.subText.text = vm.labels.days;
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.userRoleEnum = $rootScope.enums.userRole;
        vm.loadCounter = -1;
        vm.TaskTypes = $rootScope.enums.TaskTypes;
        vm.getProgressBarType = getProgressBarType;
        vm.cycleSummary = null;
        vm.entityAnnualAchievementSummary = null;
        vm.userEditingTasksSummary = null;
        vm.UserReviewingTasksSummary = null;
        vm.userAssignedSectionsSummary = null;
        vm.getOptions = getOptions;
        vm.load = load;
        vm.options = {
            displayPrevious: true,
            readOnly: true,
            animate: { enabled: true, duration: 2000, ease: 'bounce' },
            subText: {
                enabled: true, text: 'days' , color: 'white', font: 'auto' },
            bgColor: '#2C3E50',
            size:150,
            barCap: 25,
            trackWidth: 30,
            barWidth: 10,
            trackColor: 'white',
            barColor: '#FFAE1A',
            textColor: 'white',
            fontSize: 'auto',
            max: null,
        };

        vm.load();
        function load() {
            vm.Loading();
            var currentRole = $rootScope.GetCurrentUser().CurrentRoleID;
            var currentEntityID = $rootScope.GetCurrentUser().CurrentEntity;
            var currentUserID = $rootScope.GetCurrentUser().ID;

            var currentCycleSummary = entityDashboardDataService.GetCurrentCycleSummary();
            var entityAnnualAchievementSummary= entityDashboardDataService.GetEntityAnnualAchievementSummary(currentEntityID);
            var userEditingTasksSummary = entityDashboardDataService.GetUserEditingTasksSummary(currentUserID, currentEntityID, vm.TaskTypes.Edit);
            var userReviewingTasksSummary = entityDashboardDataService.GetUserReviewingTasksSummary(currentUserID, currentEntityID,vm.TaskTypes.Review);
            var userAssignedSections = entityDashboardDataService.GetUserAssignedSectionsSummary(currentUserID, currentEntityID);
        
            if (currentRole === 3)//entity admin
                getEnityAdminDashboard(currentCycleSummary, entityAnnualAchievementSummary, userEditingTasksSummary, userReviewingTasksSummary);
            else if (currentRole === 4)//entity reviewer
                getEnityReviewerDashboard(currentCycleSummary, userAssignedSections, userReviewingTasksSummary, userEditingTasksSummary);
            else if (currentRole === 5)//entity editor
                getEnityEditorDashboard(currentCycleSummary, userAssignedSections, userEditingTasksSummary);

        }


        function getEnityAdminDashboard(currentCycleSummary, entityAnnualAchievementSummary, userEditingTasksSummary, userReviewingTasksSummary) {
            Promise.all([currentCycleSummary, entityAnnualAchievementSummary, userEditingTasksSummary, userReviewingTasksSummary]).then(function (res) {
                if (res != undefined) {
                    getCurrentCycleSummarySuccess(res[0]);
                    getEntityAnnualAchievementSummarySuccess(res[1]);
                    getUserEditingTasksSummarySuccess(res[2]);
                    getUserReviewingTasksSummarySuccess(res[3]);
                    vm.Loading();
                    $scope.$apply();
                }
                else {
                    vm.Loading();
                }
            });
        }
        function getEnityEditorDashboard(currentCycleSummary, userAssignedSections, userEditingTasksSummary ) {
            Promise.all([currentCycleSummary, userAssignedSections, userEditingTasksSummary]).then(function (res) {
                if (res != undefined) {
                    getCurrentCycleSummarySuccess(res[0]);
                    getUserAssignedSectionsSummarySuccess(res[1]);
                    getUserEditingTasksSummarySuccess(res[2]);
                    vm.Loading();
                    $scope.$apply();
                } else {
                    vm.Loading();
                }
            });
        }
        function getEnityReviewerDashboard(currentCycleSummary, userAssignedSections, userReviewingTasksSummary, userEditingTasksSummary) {
            Promise.all([currentCycleSummary, userAssignedSections, userReviewingTasksSummary, userEditingTasksSummary]).then(function (res) {
                if (res != undefined) {
                    getCurrentCycleSummarySuccess(res[0]);
                    getUserAssignedSectionsSummarySuccess(res[1]);
                    getUserReviewingTasksSummarySuccess(res[2]);
                    getUserEditingTasksSummarySuccess(res[3]);
                    vm.Loading();
                    $scope.$apply();
                } else {
                    vm.Loading();
                }
            });
        }
        function getCurrentCycleSummarySuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.cycleSummary = res.responseObject;
                if (vm.cycleSummary != null) {
                    vm.cycleSummary.DataEntryRemainingDaysOptions = vm.getOptions(vm.cycleSummary.DataEntryRemainingDays, vm.cycleSummary.TotalDataEntryDays);
                    vm.cycleSummary.CutoffRemainingDaysOptions = vm.getOptions(vm.cycleSummary.CutoffRemainingDays, vm.cycleSummary.TotalCutoffDays);
                }
            }
        }
        function getEntityAnnualAchievementSummarySuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.entityAnnualAchievementSummary = res.responseObject;
                if (res.responseObject !== null) {
                    vm.entityAnnualAchievementSummary.NewType = getProgressBarType(vm.entityAnnualAchievementSummary.New, vm.entityAnnualAchievementSummary.All);
                    vm.entityAnnualAchievementSummary.EditingType = getProgressBarType(vm.entityAnnualAchievementSummary.Editing, vm.entityAnnualAchievementSummary.All);
                    vm.entityAnnualAchievementSummary.ReviewingType = getProgressBarType(vm.entityAnnualAchievementSummary.Reviewing, vm.entityAnnualAchievementSummary.All);
                    vm.entityAnnualAchievementSummary.ClosedType = getProgressBarType(vm.entityAnnualAchievementSummary.Closed, vm.entityAnnualAchievementSummary.All);
                }
            }
        }
        function getUserEditingTasksSummarySuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.userEditingTasksSummary = res.responseObject;
                if (vm.userEditingTasksSummary != null) {
                    vm.userEditingTasksSummary.All;
                    vm.userEditingTasksSummary.Total;
                    vm.userEditingTasksSummary.NewType = getProgressBarType(vm.userEditingTasksSummary.New, vm.userEditingTasksSummary.Total);
                    vm.userEditingTasksSummary.ProccessingType = getProgressBarType(vm.userEditingTasksSummary.Proccessing, vm.userEditingTasksSummary.Total);
                    vm.userEditingTasksSummary.DoneType = getProgressBarType(vm.userEditingTasksSummary.Done, vm.userEditingTasksSummary.Total);
                }
            }
        }
        function getUserReviewingTasksSummarySuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.UserReviewingTasksSummary = res.responseObject;
                if (vm.UserReviewingTasksSummary != null) {
                    vm.UserReviewingTasksSummary.All;
                    vm.UserReviewingTasksSummary.Total;
                    vm.UserReviewingTasksSummary.NewType = getProgressBarType(vm.UserReviewingTasksSummary.New, vm.UserReviewingTasksSummary.Total);
                    vm.UserReviewingTasksSummary.ProccessingType = getProgressBarType(vm.UserReviewingTasksSummary.Proccessing, vm.UserReviewingTasksSummary.Total);
                    vm.UserReviewingTasksSummary.DoneType = getProgressBarType(vm.UserReviewingTasksSummary.Done, vm.UserReviewingTasksSummary.Total);
                }
            }
        }
        function getUserAssignedSectionsSummarySuccess(res) {
            if (res.responseCode === vm.ResponseCode.Success) {
                vm.userAssignedSectionsSummary = res.responseObject;
                if (vm.userAssignedSectionsSummary != null) {
                    vm.userAssignedSectionsSummary.NewType = getProgressBarType(vm.userAssignedSectionsSummary.New, vm.userAssignedSectionsSummary.All);
                    vm.userAssignedSectionsSummary.EditingType = getProgressBarType(vm.userAssignedSectionsSummary.Editing, vm.userAssignedSectionsSummary.All);
                    vm.userAssignedSectionsSummary.ReviewingType = getProgressBarType(vm.userAssignedSectionsSummary.Reviewing, vm.userAssignedSectionsSummary.All);
                    vm.userAssignedSectionsSummary.ClosedType = getProgressBarType(vm.userAssignedSectionsSummary.Closed, vm.userAssignedSectionsSummary.All);
                }
            }
        }
        function getProgressBarType(value, total) {
            return calcluateType(value, total);
        }
        function calcluateType(value, total) {
            if (total < 1)
                return null;
            var value = Math.floor((value / total) * 100);
            var type;
            if (value < 30) {
                type = 'danger';
            } else if (value < 60) {
                type = 'warning';
            } else if (value <= 100) {
                type = 'success';
            }
            return type;
        }
        function calcluateColor(value, total) {
            var value = Math.floor((value / total) * 100);
            var color;
            if (value < 30) {
                color = '#dc3545';
            } else if (value < 60) {
                color = '#ffc107';
            } else if (value <= 100) {
                color = '#53c0a7';
            }
            return color;
        }
        function getOptions(value, totatl) {
            var options = _cloneDeep(vm.options);
            var color = calcluateColor(value, totatl);
            options.barColor = color;
            options.max = totatl;
            return options;
        }
    }
}