//Import controllers
import emailTemplatesController from './Controllers/app.emailTemplates.controller';
import addEmailTemplatesController from './Controllers/app.add-emailTemplates.controller';
import editEmailTemplatesController from './Controllers/app.edit-emailTemplates.controller';
import restoreEmailTemplatesController from './Controllers/app.restore-emailTemplates.controller';
import previewEmailTemplateController from './Controllers/app.preview-emailTemplate.controller';
//Import services
import dataService from './Services/emailTemplates.data.service';

//Import config
import './Localization';

export default function (app) {

    emailTemplatesController(app);
    addEmailTemplatesController(app);
    editEmailTemplatesController(app);
    restoreEmailTemplatesController(app);
    previewEmailTemplateController(app);
    dataService(app);

}