export default {
    ASSIGN_ACTIVITY: 'تعيين النشاط',
    ADD_ACTIVITY: 'إضافة نشاط جديد',
    ASSIGN_SECTION_TO_USER: 'تعيين قسم للمستخدم',
    SELECTED_SECTIONS: 'الأقسام المختارة',
    ASSIGN: 'تعيين',
    ASSIGN_USERS: 'تعيين مستخدمين',
    APPROVED: 'تمت الموافقة',
    SENT_BACK: 'معاد إرساله للتعديل',
    REPLY: 'رد',
    ASSIGN_USER: 'تعيين مستخدم',
    NEW: 'جديد',
    EDITING: 'تحت التعديل',
    REVIEWING: 'تحت المراجعة',
    REVIEWED: 'تمت مراجعتها',
    CREATE: 'إنشاء',
    REASSIGN: 'إعاده توجيه',
    REOPEN: 'إعاده فتح',
    EDITOR: 'المحرر',
    REVIEWER: 'المراجع',
    SAVE: 'حفظ',
    SUBMIT: 'تقديم بيانات القسم',
    APPROVE: 'تأكيد',
    SENDBACK: 'إعادة',
    NO_ACTIVE_CYCLE: 'لا يوجد دورة زمنية نشطة في الوقت الحالي',
    DELETE: 'حذف',
    DELETEQUESTION: ' هل تريد حذف ',
    SUBMIT_ACHIEVEMENTS: 'إرسال الإنجازات النهائية',
    CONFIRM_SUBMIT_ACHIEVEMENTS: 'أنت على وشك إرسال كل إنجازاتك. هل انت متأكد من المتابعة؟',
    EDIT: 'تعديل',
    VIEW: 'عرض',
    HISTORY: 'سجل',
    AnnualAhievementSumbitted: 'لا يحق لك إرسال الإنجازات السنوية ، لقد تمت الاعتماد عليها بالفعل',
    RequestEditing: 'طلب تعديل ',
    ASSIGN_SECTIONS:'تعيين الأقسام',
}