export default {
    PAGE_TITLE: 'Sections list',
    Section: 'Section',
    Section_NAME_LABEL: 'Section name (En)',
    Section_ARNAME_LABEL: 'Section name (Ar)',
    Section_S_NAME_LABEL: 'Section single name (En)',
    Section_S_ARNAME_LABEL: 'Section single name (Ar)',

    Section_DES_LABEL: 'General instructions (English)',
    Section_ARDES_LABEL: 'General instructions (Arabic)',
    Section_systemKey: 'System Key',
    Section_multiple: 'Section with multiple activities',

    SectionList: 'Entities List',
    Section_Order: 'Section Order',
    AddSection: 'Add new section',

    updateSection: 'Update section',
    SaveBtn: 'Save',
    CancelBtn: 'Cancel',
    DeactivateSectionHeader: 'Deactivate Section',
    DeactivateSectionQues: 'Are you sure you want to deactivate the Section?',

    Section_DEACTIVATED: 'The section has been deactivated successfully',
    Section_ACTIVATED: 'The section has been activated successfully',
    All_Entities: 'All Entities',
    MANDATORY: 'Mandatory',
    OPTIONAL: 'Optional',
    VISIBLE: 'Visible',
    INVISIBLE: 'Invisible',
    REQUIRED_Section_NAME: 'Section name is required.',
    REQUIRED_Section_S_NAME: 'Section single name is required.',
    REQUIRED_SectionKey: 'Section Key is required.',
    Pattern_SectionKey: 'Section Key must start with character.',
    Save_MSG: 'Section data save successfully',
    SectionKey_MSG: 'System key is previously used.',
    SectionData: 'Section data',
    BuildForm: 'Build Form',

    SINGLE_SECTION: 'Single Section Activity',

    DuplicateSectionEnName: 'Section English name is previously used',
    DuplicateSectionArName: 'Section Arabic name is previously used', 
}
