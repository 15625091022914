//unused
export default function (app) {
    app.controller('updateEntityController', UpdateEntityController);

    UpdateEntityController.$inject = ["$rootScope","entityDataService", "$uibModalInstance", "Params","Notification"];
    function UpdateEntityController($rootScope, entityDataService, $uibModalInstance, Params, Notification) {
        var vm = this;
        vn.labels = {
            EmailUsedConfirm: $rootScope.GetResource('lbl_EmailUsedConfirm'),
        },
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.userRoleEnum = $rootScope.enums.userRole;
        vm.IsProfileExist = false;
        vm.confirmAddingEntity = confirmAddingEntity;
        vm.Loading();
        vm.entity = {};
        vm.entityShortForm = [];
        vm.entityShortFormModel = {};
        vm.load = load;
        vm.cancel = cancel;
        vm.Params = Params;
        vm.addEntity = addEntity;
        vm.createEntity = createEntity;
        vm.getEntityShortForm = getEntityShortForm;
        vm.load();

        function load() {
            
            vm.entityID = vm.Params.entityID;
            vm.getEntityShortForm();
            vm.Loading();
        }

        function getEntityShortForm() {
            vm.Loading();
            entityDataService.GetEntityShortFormDescription().then(showEntityShortForm, $rootScope.methodPublicError);
        }

        function showEntityShortForm(res) {
            if (res.responseCode === $rootScope.enums.ResponseCode.Success) {
                vm.entityShortForm = [];

                var fieldGroup = {
                    wrapper: [],
                    fieldGroup: []
                };

                for (var i = 0; i < res.responseObject.length; i += 2) {
                    
                    var horizontalFieldGroup = {
                        wrapper: ["horizontalLayout"],
                        fieldGroup: []
                    };
                    res.responseObject[i].className = '';
                    horizontalFieldGroup.fieldGroup.push(res.responseObject[i]);

                    if (res.responseObject.length % 2 == 0) {
                        //res.responseObject[i + 1].className = '';
                        horizontalFieldGroup.fieldGroup.push(res.responseObject[i + 1]);
                    }

                    fieldGroup.fieldGroup.push(horizontalFieldGroup);
                }


                vm.entityShortForm.push(fieldGroup);


                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }

        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false });
        }

        function addEntity() {
            vm.Loading();
            entityDataService.CreateEntity(vm.entityShortFormModel).then(createEntitySuccess, $rootScope.methodPublicError);
        }




        function createEntity() {
            //Hosam: TODO: Create validation to check if the user already exists and show a modal for the user
            //with ok/ cancel buttons.
            //vm.entityShortFormModel.AdminEmail
            vm.Loading();
            
            entityDataService.IsEntityAdminUserExist(vm.entityShortFormModel.AdminEmail, vm.userRoleEnum['Entity Admin']).then(IsEntityAdminUserExistSuccess, $rootScope.methodPublicError);

        }
        function IsEntityAdminUserExistSuccess(res) {
            
            vm.Loading();
            if (res.responseCode == vm.ResponseCode.MBRGIUser) {
                Notification.warning({ message: 'user exist as MBRGI user' });
            }
            else if (res.responseCode == vm.ResponseCode.UserProfileExist) {

                vm.IsProfileExist = true;
            }
            else if (res.responseCode == vm.ResponseCode.NotExist) {
                //create entity
                vm.addEntity();
            }
            else {
                Notification.error({ message: 'error ocurred' });
            }
        }
        function confirmAddingEntity() {
            vm.addEntity();
        }

        function createEntitySuccess(res) {
            if (res.responseCode === $rootScope.enums.ResponseCode.Success) {
                Notification.success({ message: res.responseObject });
                $uibModalInstance.close({ Close: false, Ok: true });
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError(res.responseObject);

            }

        }
    }
}