//Import controllers
import entityController from './Controllers/app.entity.controller';
import updateController from './Controllers/app.update-entity.controller';
import addController from './Controllers/app.add-entity.controller';
import switchEntitiesController from './Controllers/app.switch-entities.controller'
//Import services
import entityDataService from './Services/entity.data.service';
import loginDataService from '../../Common/Login/Services/login.data.service'
//Import config
import './Localization';

export default function (app) {
    entityController(app);
    updateController(app);
    addController(app);
    switchEntitiesController(app);
    entityDataService(app);
    loginDataService(app);
}