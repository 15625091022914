export default function (app) {
	app.controller('updateLookupController', UpdateLookupController);

	UpdateLookupController.$inject = ["$rootScope", "lookupsDataService", "$uibModalInstance", "Params", "Notification","$translate"];
	function UpdateLookupController($rootScope, lookupsDataService, $uibModalInstance, Params, Notification, $translate) {
        var vm = this;
        
        $translate(["LOOKUPS.LOOKUP_UPDATED"]).then(function (translations) {
            vm.labels = {
				UpdatedLookup: translations['LOOKUPS.LOOKUP_UPDATED'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.responseCode = $rootScope.enums.ResponseCode;
		vm.Lookup = {};
        vm.load = load;
        vm.cancel = cancel;
		vm.Params = angular.copy(Params);
		vm.updateLookup = updateLookup;


        vm.load();

		function load() {
			vm.Lookup = vm.Params;
		}

        function cancel() {
            $uibModalInstance.close
                ({ Close: true, Ok: false });
        }

        function updateLookup(lookup) {
            vm.Loading();
			lookupsDataService.UpdateLookup(lookup).then(updateLookupSuccess, $rootScope.methodPublicError);
            
		}

		function updateLookupSuccess(res) {
            if (res.responseCode == vm.responseCode.Success) {
                vm.Loading();
				Notification.success({ message: vm.labels.UpdatedLookup });
                $uibModalInstance.close({ Close: true, Ok: true });
            }
            else if (res.responseCode == vm.responseCode.Error) {
                $rootScope.methodPublicError();
            }
        }

    }
}