export default function (app) {
    app.factory('tasksService', ['apiBase', 'ApiPath', '$http', function (apiBase, ApiPath, $http) {
        
        var _controllerUrl = ApiPath + "Task/";

        var _getUserTasks = function () {
            return apiBase.Get(_controllerUrl + "GetUserTasks", null, null);
        }

        var _getTaskDetails = function (taskid) {
            return apiBase.Get(_controllerUrl + "GetTaskDetails", {taskID: taskid}, null);
        }


        var _submitTaskAction = function (taskid, actionId, comment, formdata) {
            return apiBase.Post(_controllerUrl + "SubmitTaskAction", { taskid: taskid, actionId: actionId, comment: comment },  formdata);
        }



        var _getTaskActivityHistory = function (taskID) {
            return apiBase.Get(_controllerUrl + "GetTaskActivityHistory", { taskID: taskID }, null);
        }

        var _getAddTaskSections = function () {
            return apiBase.Get(_controllerUrl + "GetAddTaskSections", null, null);
        }

        var _getAddTaskForm = function (sectionKey) {
            return apiBase.Get(_controllerUrl + "GetAddTaskForm", { sectionKey: sectionKey }, null);
        }

        var _addTask = function (sectionKey, formModel) {
            return apiBase.Post(_controllerUrl + "AddTask", { sectionKey: sectionKey}, formModel);
        }

        return {
            getUserTasks: _getUserTasks,
            getTaskDetails: _getTaskDetails,
            submitTaskAction: _submitTaskAction,
            getTaskActivityHistory: _getTaskActivityHistory,
            getAddTaskSections: _getAddTaskSections,
            getAddTaskForm: _getAddTaskForm,
            addTask: _addTask
        }

    }])
}