//Import controllers
import pillarController from './Controllers/app.pillar.controller';
import updateController from './Controllers/app.update-pillar.controller';
import addController from './Controllers/app.add-pillar-controller';
//Import services
import dataService from './Services/pillar.data.service';
//Import config
import './Localization';

export default function (app) {
    pillarController(app);
    updateController(app);
    addController(app)
    dataService(app);
}