export default function (app) {
    app.factory('beneficiaryunitDataService', BeneficiaryunitDataService);

    BeneficiaryunitDataService.$inject = ['apiBase', 'ApiPath'];
    function BeneficiaryunitDataService(apiBase, ApiPath) {
        var entity = "Beneficiaryunit";
        var controllerUrl = ApiPath + entity + "/";

        function GetAllBeneficiaryunits() {
            return apiBase.Get(controllerUrl + "GetAll", null);
        }
        function getDeletedBeneficiaryunits() {
            return apiBase.Get(controllerUrl + "GetDeleted", null);
        }
        function restoreDeletedBeneficiaryunit(ID) {
            return apiBase.Post(controllerUrl + "RestoreDeleted", { ID:ID });
        }
        function GetBeneficiaryunitByID(beneficiaryunitID) {
            return apiBase.Get(controllerUrl + "GetBeneficiaryUnitByID", { beneficiaryunitID: beneficiaryunitID }, null);
        }

        function AddBeneficiaryunit(data) {
            return apiBase.Post(controllerUrl + "AddBeneficiaryUnit", null, data);
        }

        function DeleteBeneficiaryunit(beneficiaryunitID) {
            return apiBase.Get(controllerUrl + "DeleteBeneficiaryUnit", { beneficiaryunitID: beneficiaryunitID }, null);
        }

        function UpdateBeneficiaryunit(data) {
            return apiBase.Post(controllerUrl + "UpdateBeneficiaryUnit", null, data);
        }

        function IsBeneficiaryunitExist(data, benIDbeneficiaryUnitID) {
            return apiBase.Post(controllerUrl + "IsBeneficiaryUnitExist", { beneficiaryUnitID: benIDbeneficiaryUnitID}, data);
        }

        function ChangeActiveStatus(beneficiaryunitID, activeState) {
            return apiBase.Post(controllerUrl + "ChangeActiveStatus", { beneficiaryUnitID: beneficiaryunitID, activeState: activeState }, null);
        }

        function GetAllBeneficiaryUnitsTypes() {
            return apiBase.Get(controllerUrl + "GetAllBeneficiaryUnitsTypes", null);
        }
        function DeleteBeneficiaryUnit(BeneficiaryUnitID) {
            return apiBase.Get(controllerUrl + "DeleteBeneficiaryUnit", { BeneficiaryUnitID: BeneficiaryUnitID}, null);
        }
        function updateBeneficiaryUnitsOrder(orderedPillarsID) {
            return apiBase.Post(controllerUrl + "UpdateBeneficiaryUnitsOrder", null, orderedPillarsID);
        }
        return {
            GetAllBeneficiaryunits: GetAllBeneficiaryunits,
            GetBeneficiaryunitByID: GetBeneficiaryunitByID,
            AddBeneficiaryunit: AddBeneficiaryunit,
            DeleteBeneficiaryunit: DeleteBeneficiaryunit,
            UpdateBeneficiaryunit: UpdateBeneficiaryunit,
            IsBeneficiaryunitExist: IsBeneficiaryunitExist,
            ChangeActiveStatus: ChangeActiveStatus,
            GetAllBeneficiaryUnitsTypes: GetAllBeneficiaryUnitsTypes,
            DeleteBeneficiaryUnit: DeleteBeneficiaryUnit,
            getDeletedBeneficiaryunits: getDeletedBeneficiaryunits,
            restoreDeletedBeneficiaryunit: restoreDeletedBeneficiaryunit,
            UpdateBeneficiaryUnitsOrder: updateBeneficiaryUnitsOrder,
        }
    }
}