export default function (app) {
    app.directive('format', ['$filter', function ($filter) {
        return {
            require: '?ngModel',
            link: function (scope, elem, attrs, ctrl) {
                if (!ctrl) return;


                ctrl.$formatters.unshift(function (a) {
                    if (ctrl.$isEmpty(ctrl.$modelValue) || attrs.format != 'number') {
                        return ctrl.$modelValue;
                    }
                    else {
                        var firstChar = ctrl.$modelValue.toString().charAt(0);
                        var plainNumber = ctrl.$modelValue.toString().replace(/[^\d|^\.+]/g, '');
                        if (attrs.step && attrs.step == '1')
                            plainNumber = ctrl.$modelValue.toString().replace(/[^\d+]/g, '');
                        if (firstChar == '-')
                            plainNumber = firstChar + plainNumber;
                        var parts = plainNumber.toString().substr(0, 15).split(".");
                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        parts = parts.slice(0, 2);
                        return parts.join(".");
                    }
                });


                ctrl.$parsers.unshift(function (viewValue) {
                    if (attrs.format != 'number')
                        return viewValue;
                    var firstChar = viewValue.charAt(0);
                    var plainNumber = viewValue.replace(/[^\d|^\.+]/g, '');
                    if (attrs.step && attrs.step == '1')
                        plainNumber = viewValue.replace(/[^\d+]/g, '');
                    if (firstChar == '-')
                        plainNumber = firstChar + plainNumber;
                    var parts = plainNumber.toString().substr(0,15).split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    parts = parts.slice(0, 2);
                    var newValue = parts.join(".");

                    ctrl.$setViewValue(newValue);
                    ctrl.$render();
                    //ngModel.$commitViewValue();
                    //elem.val(newValue);
                    return Number(plainNumber);
                });
            }
        };
    }]);
}