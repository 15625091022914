export default function (app) {
    app.constant('routeConstants', {
        Header: {
            TemplatePath: "header.html"
        },
        LoginState: {
            State: "Login",
            URL: "/login",
            TemplatePath: "ng/Modules/Common/Login/Views/login.html"
        },
        ResetPassword: {
            State: 'ResetPassword',
            URL: "/ResetPassword/:Credential",
            TemplatePath: "ng/Modules/Common/Login/Views/resetPassword.html"
        },
        HomeState: {
            State: "Home",
            URL: "/home",
            TemplatePath: "ng/Modules/Home/Views/home.html"
        },
        MBRGIHomeState: {
            State: "MBRGI",
            URL: "/MBRGI",
            TemplatePath: "ng/Modules/Home/Views/MBRGI.html"
        },
        EntityHomeState: {
            State: "Entity",
            URL: "/Entity",
            TemplatePath: "ng/Modules/Home/Views/Entity.html"
        },
        MBRGIUserProfileState: {
            State: "MBRGI.UserProfile",
            URL: "/UserProfile",
            TemplatePath: "ng/Modules/Common/UserProfile/Views/index.html"
        },
        EntityUserProfileState: {
            State: "Entity.UserProfile",
            URL: "/UserProfile",
            TemplatePath: "ng/Modules/Common/UserProfile/Views/index.html"
        },
        EntityProfileState: {
            State: "Entity.Profile",
            URL: "/Profile",
            TemplatePath: "ng/Modules/Entity/Profile/Views/Entity-Profile.html"
        },
        EntityDashboardState: {
            State: "Entity.Dashboard",
            URL: "/Dashboard",
            TemplatePath: "ng/Modules/Entity/Dashboard/Views/Entity-dashboard.html"
        },
        MBRGIDashboardState: {
            State: "MBRGI.Dashboard",
            URL: "/Dashboard",
            TemplatePath: "ng/Modules/MBRGI/Dashboard/Views/MBRGI-dashboard.html"
        },
        MBRGIChnagePasswordState: {
            State: "MBRGI.ChangePassword",
            URL: "/ChangePassword",
            TemplatePath: "ng/Modules/Common/UserProfile/Views/update-mypassword.html"
        },
        EntityChnagePasswordState: {
            State: "Entity.ChangePassword",
            URL: "/ChangePassword",
            TemplatePath: "ng/Modules/Common/UserProfile/Views/update-mypassword.html"
        },
        MBRGIUserState: {
            State: "MBRGI.Users",
            URL: "/users",
            TemplatePath: "ng/Modules/Common/Users/Views/index.html"
        },
        MBRGIEntityUsersState: {
            State: "MBRGI.EntityUsers",
            URL: "/EntityUsers/:entityID",
            TemplatePath: "ng/Modules/Common/Users/Views/index.html"
        },
        EntityUserState: {
            State: "Entity.Users",
            URL: "/users",
            TemplatePath: "ng/Modules/Common/Users/Views/index.html"
        },
        UserState: {
            State: "Home.Users",
            URL: "/users",
            TemplatePath: "ng/Modules/Common/Users/Views/index.html"
        },
        EntityState: {
            State: "MBRGI.Entities",
            URL: "/entities",
            TemplatePath: "ng/Modules/MBRGI/Entities/Views/index.html"
		},
		CountryState: {
			State: "MBRGI.Countries",
			URL: "/countries",
			TemplatePath: "ng/Modules/MBRGI/Countries/Views/index.html"
		},
        RestoreCountryState: {
            State: "MBRGI.RestoreCountries",
            URL: "/countries/deleted",
            TemplatePath: "ng/Modules/MBRGI/Countries/Views/restore-countries.html"
		},
		LookupsState: {
			State: "MBRGI.Lookups",
			URL: "/lookups",
			TemplatePath: "ng/Modules/MBRGI/Lookups/Views/index.html"
        },
        PredefinedLookupState: {
            State: "MBRGI.PredefinedLookupState",
            URL: "/PredefinedLookups/entries/:lookupID",
            TemplatePath: "ng/Modules/MBRGI/Lookups/Views/predefined-entries.html"
        },
		LookupEntriesState: {
			State: "MBRGI.LookupEntries",
			URL: "/lookups/entries/:lookupID",
			TemplatePath: "ng/Modules/MBRGI/Lookups/Views/entries.html"
		},
		LookupDeletedEntriesState: {
			State: "MBRGI.LookupDeletedEntries",
			URL: "/lookups/deleted/:lookupID",
			TemplatePath: "ng/Modules/MBRGI/Lookups/Views/deleted-entries.html"
		},
        MBRGIEntityProfileState: {
            State: "MBRGI.EntityProfile",
            URL: "/EntityProfile/:entityID",
            TemplatePath: "ng/Modules/Entity/Profile/Views/Entity-Profile.html"
        },
        SwitchEntitiesState: {
            State: "Entity.SwitchEntities",
            URL: "/SwitchEntities",
            TemplatePath: "ng/Modules/MBRGI/Entities/Views/switch-entities.html"
        },
        AddEntityState: {
            State: "MBRGI.Entities.AddEntity",
            URL: "/add",
            TemplatePath: "ng/Modules/MBRGI/Entities/Views/add-entity.html"
        },
        PillarState: {
            State: "MBRGI.Pillars",
            URL: "/pillars",
            TemplatePath: "ng/Modules/MBRGI/Pillars/Views/index.html"
        },
        CycleState: {
            State: "MBRGI.Cycles",
            URL: "/Cycles",
            TemplatePath: "ng/Modules/MBRGI/Cycle/Views/index.html"
        },
        //  PillarState: {
        //      State: "MBRGI.Pillars",
        //      URL: "/pillars",
        //      TemplatePath: "ng/Modules/Pillars/Views/index.html"
        //  },
        //Benenficiary Unit
        BeneficiaryUnitState: {
            State: "MBRGI.BeneficiaryUnit",
            URL: "/BeneficiaryUnit",
            TemplatePath: "ng/Modules/MBRGI/BeneficiaryUnit/Views/index.html"
        },
        //AddBeneficiaryUnitState: {
        //    State: "Home.BeneficiaryUnit.AddBeneficiaryUnit",
        //    URL: "/add",
        //    TemplatePath: "ng/Modules/Pillars/Views/add-beneficiaryunit.html"
        //},
        AddBeneficiaryUnitState: {
            State: "MBRGI.BeneficiaryUnit.AddBeneficiaryUnit",
            URL: "/add",
            TemplatePath: "ng/Modules/BeneficiaryUnit/Views/add-beneficiaryunit.html"
        },
        RestoreBeneficiaryUnitState: {
            State: "MBRGI.RestoreBeneficiaryUnit",
            URL: "/BeneficiaryUnit/deleted",
            TemplatePath: "ng/Modules/MBRGI/BeneficiaryUnit/Views/restore-beneficiaryunit.html"
        },
        //EndBeneficiary
        AddPillarState: {
            State: "MBRGI.Pillars.AddPillar",
            URL: "/add",
            TemplatePath: "ng/Modules/MBRGI/Pillars/Views/add-pillar.html"
        },
        //FormBuilderState: {
        //    State: 'Home.FormBuilder',
        //    URL: "/formbuilder",
        //    TemplatePath: "ng/Modules/Form/FormlyBuilder/Views/formlyBuilder.html"
        //},
       
        AnnualAchievementState: {
            State: 'Entity.AnnualAchievement',
            URL: '/annualAchievement/{cycleID}',
           // URL: '/annualAchievement',
            Template: require('../Modules/AnnualAchievement/Views/annualAchievement.html')
        },
        SharedInitiativesState: {
            State: "Entity.SharedInitiatives",
            URL: "/sharedInitiatives",
            Template: require('../Modules/SharedInitiatives/Views/sharedInitiatives.html')
        },
        SharedInitiativeState: {
            State: 'Entity.SharedInitiatives.SharedInitiative',
            URL: '/{activityID}',
            Template: require('../Modules/SharedInitiatives/Views/sharedInitiative.html')
        },
        SectionSubmissionState: {
            State: 'Entity.AnnualAchievement.Submission',
            URL: '/form/{sectionID}/{activityID}/{isCycleEnabled}/{cycleActive}',
            Template: require('../Modules/AnnualAchievement/Views/sectionSubmission.html')
        },
        SectionSubmissionsState: {
            State: 'Entity.AnnualAchievement.Submissions',
            URL: '/section/{sectionID}/{isCycleEnabled}/{cycleActive}',
            Template: require('../Modules/AnnualAchievement/Views/sectionSubmissions.html')
        },
        DeletedSectionSubmissionsState: {
            State: 'Entity.AnnualAchievement.DeletedSubmissions',
            URL: '/section/deleted/{sectionID}/{isCycleEnabled}/{cycleActive}',
            Template: require('../Modules/AnnualAchievement/Views/deletedSectionSubmissions.html')
        },
        TasksState: {
            State: 'Entity.Tasks',
            URL: '/tasks',
            Template: require('../Modules/Tasks/Views/tasks.html')
        },
        TaskState: {
            State: 'Entity.Tasks.Task',
            URL: '/{taskID}/{taskType}',
            Template: require('../Modules/Tasks/Views/task.html')
        },
        ErrorState: {
            State: 'Error',
            URL: "/Error",
            TemplatePath: "error.html"
        },
        MBRGIAnnualAchievementsState: {
            State: 'MBRGI.MBRGIAnnualAchievements',
            //URL: '/annualAchievements',
            URL: '/annualAchievements/{cycleID}',
            Template: require('../Modules/MBRGI/AnnualAchievements/Views/index.html')
        },
        MBRGIAnnualAchievementState: {
            State: 'MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement',
            URL: '/{entityID}/{viewMode}/{isCycleEnabled}',
            Template: require('../Modules/MBRGI/AnnualAchievements/Views/MBRGIAnnualAchievement.html')
        },
        MBRGISectionSubmissionState: {
            State: 'MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.Submission',
            URL: '/form/{sectionID}/{activityID}',
            Template: require('../Modules/MBRGI/AnnualAchievements/Views/sectionSubmission.html')
        },
        MBRGISectionSubmissionsState: {
            State: 'MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.Submissions',
            URL: '/section/{sectionID}',
            Template: require('../Modules/MBRGI/AnnualAchievements/Views/sectionSubmissions.html')
        },
        MBRGIDeletedSectionSubmissionsState: {
            State: 'MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.DeletedSubmissions',
            URL: '/section/deleted/{sectionID}',
            Template: require('../Modules/MBRGI/AnnualAchievements/Views/deletedSectionSubmissions.html')
        },
        MBRGIReportsState: {
            State: "MBRGI.Reports",
            URL: "/reports",
            Template: require('../Modules/Reports/Views/LandingPages/MBRGIReports.html')
        },
        EntityReportsState: {
            State: "Entity.Reports",
            URL: "/reports",
            Template: require('../Modules/Reports/Views/LandingPages/EntityReports.html')
        },
        MBRGIComparisonReportsState: {
            State: "MBRGI.ComparsionReports",
            URL: "/Reports/ComparsionReports",
            Template: require('../Modules/Reports/Views/ComparisonReport/MBRGI-ComparisonReport.html')
        },
        EntityComparisonReportsState: {
            State: "Entity.ComparsionReports",
            URL: "/Reports/ComparsionReports",
            Template: require('../Modules/Reports/Views/ComparisonReport/Entities-ComparisonReport.html')
        },
        MBRGISectionReportsState: {
            State: "MBRGI.SectionReports",
            URL: "/Reports/SectionReports",
            Template: require('../Modules/Reports/Views/SectionsReport/MBRGI-SectionReport.html')
        },
        EntitySectionReportsState: {
            State: "Entity.SectionReports",
            URL: "/Reports/SectionReports",
            Template: require('../Modules/Reports/Views/SectionsReport/Entity-SectionReport.html')
        },
        SectionRequestState: {
            State: "MBRGI.Request",
            URL: "/requests",
            Template: require('../Modules/MBRGI/SectionRequests/Views/request.html')
        },
        SectionsAssignmentState: {
            State: 'Entity.SectionsAssignment',
            URL: '/sectionsAssignment',
            Template: require('../Modules/Entity/SectionsAssignment/Views/sectionsAssignment.html')
        },
        SectionAssignmentSubmissionState: {
            State: 'Entity.SectionsAssignment.Submission',
            URL: '/form/{sectionID}/{activityID}/{isCycleEnabled}',
            Template: require('../Modules/Entity/SectionsAssignment/Views/sectionSubmission.html')
        },
        SectionAssignmentSubmissionsState: {
            State: 'Entity.SectionsAssignment.Submissions',
            URL: '/section/{sectionID}/{isCycleEnabled}',
            Template: require('../Modules/Entity/SectionsAssignment/Views/sectionSubmissions.html')
        },
        MBRGICountiresReportsState: {
            State: "MBRGI.CountiresReports",
            URL: "/Reports/CountiresReports",
            Template: require('../Modules/Reports/Views/CountriesReport/MBRGI-CountiresReport.html')
        },
        EntityCountiresReportsState: {
            State: "Entity.CountiresReports",
            URL: "/Reports/CountiresReports",
            Template: require('../Modules/Reports/Views/CountriesReport/Entities-CountiresReport.html')
        },
        MBRGIAwardsReportsState: {
            State: "MBRGI.AwardsReports",
            URL: "/Reports/AwardsReports",
            Template: require('../Modules/Reports/Views/AwardsReport/MBRGI-AwardsReport.html')
        },
        EntitiesAwardsReportsState: {
            State: "Entity.AwardsReports",
            URL: "/Reports/AwardsReports",
            Template: require('../Modules/Reports/Views/AwardsReport/Entities-AwardsReport.html')
        },
        MBRGIBeneficiariesReportsState: {
            State: "MBRGI.BeneficiariesReports",
            URL: "/Reports/BeneficiariesReports",
            Template: require('../Modules/Reports/Views/BeneficiariesReport/MBRGI-BeneficiariesReport.html')
        },
        EntitiesBeneficiariesReportState: {
            State: "Entity.BeneficiariesReports",
            URL: "/Reports/BeneficiariesReports",
            Template: require('../Modules/Reports/Views/BeneficiariesReport/Entities-BeneficiariesReport.html')
        },
        MBRGIScholarshipsReportsState: {
            State: "MBRGI.ScholarshipsReports",
            URL: "/Reports/ScholarshipsReports",
            Template: require('../Modules/Reports/Views/ScholarshipsReport/MBRGI-ScholarshipsReport.html')
        },
        NotificationState: {
            State: "MBRGI.Notification",
            URL: "/notification",
            Template: require('../Modules/Common/Notification/Views/Notification.html')
        },
        EntityNotificationState: {
            State: "Entity.Notification",
            URL: "/notification",
            Template: require("../Modules/Common/Notification/Views/Notification.html")
        },
        EntitiesScholarshipsReportsState: {
            State: "Entity.ScholarshipsReports",
            URL: "/Reports/ScholarshipsReports",
            Template: require('../Modules/Reports/Views/ScholarshipsReport/Entities-ScholarshipsReport.html')
        },
        FormBuilderState: {
            State: "MBRGI.FormBuilder",
            URL: "/FormBuilder/{sectionID}",
            Template: require('../Modules/Form/FormlyBuilder/Views/formlyBuilder.html')
        },
        SectionListState: {
            State: 'MBRGI.SectionList',
            URL: "/SectionList",
            Template: require('../Modules/MBRGI/ManageSections/Views/ManageSections.html')
        },
        SectionUpdateState: {
            State: 'MBRGI.updateSection',
            URL: "/UpdateSection/{sectionID}",
            Template: require('../Modules/MBRGI/ManageSections/Views/UpdateSection.html')
        },
        EmailTemplatesState: {
            State: "MBRGI.EmailTemplates",
            URL: "/EmailTemplates",
            Template: require('../Modules/MBRGI/EmailTemplates/Views/index.html')
        },
        AddEmailTemplatesState: {
            State: "MBRGI.AddEmailTemplate",
            URL: "/EmailTemplates/Add/{entityID}",
            Template: require('../Modules/MBRGI/EmailTemplates/Views/add-emailTemplate.html')
        },
        EditEmailTemplatesState: {
            State: "MBRGI.EditEmailTemplate",
            URL: "/EmailTemplates/Edit/{templateID}",
            Template: require('../Modules/MBRGI/EmailTemplates/Views/edit-emailTemplate.html')
        },
        RestoreEmailTemplatesState: {
            State: "MBRGI.RestoreEmailTemplates",
            URL: "/EmailTemplates/deleted",
            Template: require('../Modules/MBRGI/EmailTemplates/Views/restore-emailTemplate.html')
        },
        DeletedSectionAssignmentSubmissionsState: {
            State: 'Entity.SectionsAssignment.DeletedSubmissions',
            URL: '/section/deleted/{sectionID}/{isCycleEnabled}',
            Template: require('../Modules/Entity/SectionsAssignment/Views/deletedSectionSubmissions.html')
        },
        MBRGIPublicSettings: {
            State: "MBRGI.PublicSettings",
            URL: "/Settings",
            Template: require('../Modules/Common/PublicSettings/Views/settings.html')
        },
        EntityPublicSettings: {
            State: "Entity.PublicSettings",
            URL: "/Settings",
            Template: require('../Modules/Common/PublicSettings/Views/settings.html')
        },
        MBRGIFinancialReportsState: {
            State: "MBRGI.FinancialReports",
            URL: "/Reports/FinancialReports",
            Template: require('../Modules/Reports/Views/FinancialReport/MBRGI-FinancialReport.html')
        },
        EntitiesFinancialReportsState: {
            State: "Entity.FinancialReports",
            URL: "/Reports/FinancialReports",
            Template: require('../Modules/Reports/Views/FinancialReport/Entities-FinancialReport.html')
        },
    });

    //MBRGIReportsState: {
    //    State: "MBRGI.Reports",
    //        URL: "/reports",
    //            Template: require('../Modules/Reports/Views/Report.html')
    //},
    //EntityReportsState: {
    //    State: "Entity.Reports",
    //        URL: "/reports",
    //            Template: require('../Modules/Reports/Views/EntityReport.html')
    //},

    app.constant('ApiPath', '/api/');

    app.constant('AES_Constants', {
        AES_Keybytes: '7061737323313233',
        AES_IV: '7061737323313233'
    });

    app.constant('SplittingConstant', String.fromCharCode(31));

    app.constant('MBRGIAnnualAchievementFilterConstants', {
        NEW: 1,
        UNDER_REVIEWING: 2,
        SENT_BACK: 3,
        APPROVED: 4,
        EMPTY: 5,
        IN_PROGRESS: 6
    });
}