
export default function (app) {
app.controller('notificationController', NotificationController);

    NotificationController.$inject = ['$state', "$rootScope", "homeNotificationService", "Notification", "$translate", "statusConstant", 'actionsTypesConstant', 'successrNotificationService', "errorNotificationService"];
    function NotificationController($state, $rootScope, homeNotificationService, Notification, $translate, statusConstant, actionsTypesConstant, successrNotificationService, errorNotificationService) {
        var vm = this;
        vm.Loading = $rootScope.Loading;
        vm.Notifications=[];
        vm.load = load;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.pageNumber = 1;
        vm.load();
        vm.DisableLoad = false;
        vm.CycleID = $rootScope.cycleID;
        vm.NOTIFICATION_TYPES = $rootScope.enums.NOTIFICATION_TYPES;
        function load() {
            GetAllNotification();
        }
        function GetAllNotification() {
             vm.Loading();
            homeNotificationService.getAllNotification(vm.pageNumber).then(function (response) {
                 vm.Loading();
                if (response.responseCode === vm.ResponseCode.Success) {
                    if (response.responseObject.length > 0)
                        vm.Notifications = vm.Notifications.concat(response.responseObject);
                    else {
                        $translate("COMMON.NoMoreNotification").then(function (msg) {
                            Notification.success({ message: msg });
                            vm.DisableLoad = true;
                        });
                    }
                       
                }
                else {
                    $rootScope.methodPublicError();
                }
            });
        }

        vm.loadMore = function () {
            vm.pageNumber++;
            GetAllNotification();
        }
        vm.markNotificationAsSeen = function (notification) {
            vm.Loading();
            homeNotificationService.markSeen(notification.id).then((response) => {
                if (response && response.responseCode == 1) {
                    notification.seen = true;
                    //notification.template = notification.template.replace("list-group-item", "list-group-item swatch-200");
                    vm.Loading();
                }
            }).catch((error) => {
               // $rootScope.methodPublicError(error);
            });

        }

     
        
        vm.openNotification = function (type, params, CycleID, notification) {
            vm.CycleID = $rootScope.cycleID;
          
            switch (type) {
                case vm.NOTIFICATION_TYPES.OnTaskAssignment:
                case vm.NOTIFICATION_TYPES.OnTaskRejection:
                    { //TODO: Navigate to page x with notication.params
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(34);
                            break;
                        }
                        $state.transitionTo('Entity.Tasks.Task', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnSectionAssigned:
                    {
                        $state.transitionTo('Entity.SectionsAssignment', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnCycleReminders:
                    {
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        $state.transitionTo('Entity.AnnualAchievement', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityProfileUpdate:
                    {
                        $state.transitionTo('MBRGI.EntityProfile', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntitySharedInitiatives:
                    {
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        $state.transitionTo('Entity.SharedInitiatives.SharedInitiative', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityAddSharedInitiativeComment:
                    {
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        $state.transitionTo('Entity.AnnualAchievement.Submission', { sectionID: null, activityID: params.activityID, isCycleEnabled: true, cycleActive: true });
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityRemoveSharedInitiatives:
                    {
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        $state.transitionTo('Entity.SharedInitiatives.SharedInitiative', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityApprovedSharedInitiatives:
                    {
                        if (vm.CycleID == null || vm.CycleID != CycleID) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        $state.transitionTo('Entity.SharedInitiatives.SharedInitiative', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityAchievementSubmitted:
                    { 
                        var param = { cycleID: CycleID };
                        $state.transitionTo('MBRGI.MBRGIAnnualAchievements', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnMBRGIAchievementApproval:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("Entity.AnnualAchievement",param);
                       // $state.go('Entity.AnnualAchievement', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnMBRGIAchievementSentBack:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("Entity.AnnualAchievement", param);
                        // $state.go('Entity.AnnualAchievement', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnRequestEditingApproved:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("Entity.AnnualAchievement", param);
                        // $state.go('Entity.AnnualAchievement', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnRequestEditingRejected:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("Entity.AnnualAchievement", param);
                        // $state.go('Entity.AnnualAchievement', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnRequestEditing:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("MBRGI.Request", param);
                        // $state.go('Entity.AnnualAchievement', param);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnEntityAnnualAchievementComment:
                    {
                        if (vm.CycleID == null) {
                            errorNotificationService.showErrorNotification(32);
                            break;
                        }
                        params.cycleID = vm.CycleID;
                        $state.transitionTo('MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.Submissions', params);
                        break;
                    }
                case vm.NOTIFICATION_TYPES.OnMBRGIAnnualAchievementComment:
                    {
                        var param = { cycleID: CycleID };
                        $state.transitionTo("Entity.AnnualAchievement", param);
                        break;
                    }
                default:
                    break;
            }
            vm.markNotificationAsSeen(notification);
        };

    }
}