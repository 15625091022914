export default function (app) {
	app.controller('entityProfileController', EntityProfileController);

    EntityProfileController.$inject = ["$rootScope", "entityProfileDataService", "$state", "$uibModal", "Notification", "fileUploadService", "formHelpersService", "multiFileUploadService", "$cookies", "$stateParams", "entityDataService","$translate"];
    function EntityProfileController($rootScope, entityProfileService, $state, $uibModal, Notification, fileUploadService, formHelpersService, multiFileUploadService, $cookies, $stateParams, entityDataService, $translate) {
        var vm = this;
        $translate(["PROFILE.ENTITY_NAME_USED","PROFILE.ENTITY_PROFILE_UPDATED"]).then(function (translations) {
                vm.labels = {
                    ENTITY_NAME_USED: translations['PROFILE.ENTITY_NAME_USED'],
                    ENTITY_PROFILE_UPDATED: translations['PROFILE.ENTITY_PROFILE_UPDATED'],

                }
            });
		vm.Loading = $rootScope.Loading;
		vm.load = load;
        vm.loadCurrentEntityForm = loadCurrentEntityForm;
        vm.loadEntityForm = loadEntityForm;
		vm.updateProfileWithUploads = updateProfileWithUploads;
        vm.updateProfile = updateProfile;
        vm.updateEntityProfileByID = updateEntityProfileByID;
		vm.ProfileFormDescription = [];
		vm.ProfileFormModel = {};
		vm.ProfileFormOptions = {};
        vm.Params = $stateParams;
		vm.load();

        function load() {
            vm.Loading();
            if (vm.Params.entityID === null || vm.Params.entityID === undefined)
                vm.loadCurrentEntityForm();
            else
                vm.loadEntityForm(vm.Params.entityID);
		}

		function loadCurrentEntityForm() {
			entityProfileService.GetProfileForm().then(function (res) {
				if (res.responseCode === $rootScope.enums.ResponseCode.Success) {
					vm.ProfileFormDescription = res.responseObject.formDescription;
					




					var _form = res.responseObject.formDescription;
					_form.push({
						type: 'input',
						className: "d-none",
						controller: ($timeout) => {
							$timeout(function () {

								$('.form-group label span.truncate').each(
									function () {
										$(this).removeClass('truncate');
										var parentWidth = $(this).parent().parent().width();
										//var child = $(this).width();
										var childWidth = $(this).parent().width();

										if (childWidth >= parentWidth) {
											$(this).addClass('truncate');
										}
										else if (childWidth < 341) {
											$(this).addClass('truncate');
										}
									}
								);
							}, 0);
						}
					})
					vm.ProfileFormDescription = _form;



					vm.ProfileFormModel = res.responseObject.entryModel;
					vm.ProfileFormOptions = res.responseObject.options;




                    vm.Loading();
				}
				else {
					$rootScope.methodPublicError(res.responseObject);
				}

			},
				$rootScope.methodPublicError);
        }

        function loadEntityForm(entityID) {
            entityDataService.GetProfileForm(entityID).then(function (res) {
                if (res.responseCode === $rootScope.enums.ResponseCode.Success) {

					var _form = res.responseObject.formDescription;
					_form.push({
						type: 'input',
						className: "d-none",
						controller: ($timeout) => {
							$timeout(function () {

								$('.form-group label span.truncate').each(
									function () {
										$(this).removeClass('truncate');
										var parentWidth = $(this).parent().parent().width();
										//var child = $(this).width();
										var childWidth = $(this).parent().width();

										if (childWidth >= parentWidth) {
											$(this).addClass('truncate');
										}
										else if (childWidth < 341) {
											$(this).addClass('truncate');
										}
									}
								);
							}, 0);
						}
					})
					vm.ProfileFormDescription = _form;






                    vm.ProfileFormModel = res.responseObject.entryModel;
                    vm.ProfileFormOptions = res.responseObject.options;
                    vm.Loading();
                }
                else {
                    $rootScope.methodPublicError(res.responseObject);
                }

            },
                $rootScope.methodPublicError);
        }

		function updateProfileWithUploads() {
			vm.Loading();
			//var _files = formHelpersService.getFiles(vm.ProfileFormOptions.formState.filesStorageKey);

			//if (_files && _files.length > 0) {
			//	multiFileUploadService.upload(_files).then((resp) => {
   //                 if (resp.status == 200) {
   //                     if (vm.Params.entityID === null || vm.Params.entityID === undefined)
   //                         vm.updateProfile();
   //                     else {
   //                         vm.updateEntityProfileByID(vm.Params.entityID);
   //                     }
			//		}
			//		else {
			//			$rootScope.methodPublicError();
			//		}
			//	}).catch((error) => {
			//		$rootScope.methodPublicError();
			//	})
			//}
			//else {
                if (vm.Params.entityID === null || vm.Params.entityID === undefined)
                    vm.updateProfile();
                else {
                    vm.updateEntityProfileByID(vm.Params.entityID);
                }
			//}
		}

		function updateProfile() {
			entityProfileService.UpdateProfile(vm.ProfileFormModel).then(function (res) {
                if (res.responseCode === $rootScope.enums.ResponseCode.Duplicated) {
                    Notification.error({ message: vm.labels.ENTITY_NAME_USED });
					vm.Loading();
				}
                else if (res.responseCode === $rootScope.enums.ResponseCode.Success) {
                    Notification.success({ message: vm.labels.ENTITY_PROFILE_UPDATED });
                    vm.Loading();
                    $state.transitionTo('Entity.Dashboard');   
				}
				else {
					$rootScope.methodPublicError(res.responseObject);
					vm.Loading();
				}
			}).catch((error) => {
				$rootScope.methodPublicError();
				vm.Loading();
			})
        }
        function updateEntityProfileByID(entityID) {
			entityDataService.UpdateProfile(entityID, vm.ProfileFormModel).then(function (res) {
				if (res.responseCode === $rootScope.enums.ResponseCode.Duplicated) {
                    Notification.error({ message: vm.labels.ENTITY_NAME_USED });
					vm.Loading();
				}
                else if (res.responseCode === $rootScope.enums.ResponseCode.Success) {
                    Notification.success({ message: vm.labels.ENTITY_PROFILE_UPDATED });
                    vm.Loading();
                    vm.RedirectBasedOnUserRole();
                    
                }
                else {
                    $rootScope.methodPublicError(res.responseObject);
                    vm.Loading();
                }
            }).catch((error) => {
                $rootScope.methodPublicError();
                vm.Loading();
            })
        }
        vm.RedirectBasedOnUserRole = function () {
            var currentUser = $rootScope.GetCurrentUser();
            if (currentUser != null && currentUser !== undefined) {
                if (currentUser.RoleSection === 'MBRGI') {
                    $state.transitionTo('MBRGI.Entities');
                }
                else {
                    $state.transitionTo('Entity.Dashboard');
                }
            }

        }
	}
}