export default function (app) {
	app.controller('updateLookupEntryController', UpdateLookupEntryController);

	UpdateLookupEntryController.$inject = ["$rootScope", "lookupsDataService", "$uibModalInstance", "Params", "Notification","$translate"];
	function UpdateLookupEntryController($rootScope, lookupsDataService, $uibModalInstance, Params, Notification, $translate) {
        var vm = this;
		$translate(["LOOKUPS.LOOKUP_ENTRY_UPDATED"]).then(function (translations) {
            vm.labels = {
				LookupEntryUpdated: translations['LOOKUPS.LOOKUP_ENTRY_UPDATED'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.responseCode = $rootScope.enums.ResponseCode;
        vm.Loading();
        vm.load = load;
		vm.cancel = cancel;
		vm.lookupEntry = angular.copy(Params);
		vm.updateLookupEntry = updateLookupEntry;

        vm.load();

        function load() {
            vm.Loading();
        }
        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false});
        }

		function updateLookupEntry() {
            vm.Loading();
			lookupsDataService.UpdateLookupEntry(vm.lookupEntry).then(updateLookupEntrySuccess, $rootScope.methodPublicError);
        }

		function updateLookupEntrySuccess(res) {
            if (res.responseCode == vm.responseCode.Success) {
				vm.Loading();
				Notification.success({ message: vm.labels.LookupEntryUpdated });
				$uibModalInstance.close({ Close: true, Ok: true });
			} else {
                $rootScope.methodPublicError();
                $uibModalInstance.close({ Close: true, Ok: false });
            }
        }
    }
}