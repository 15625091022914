export default function (app) {
    app.config(routeConfiguration);

    routeConfiguration.$inject = ["$stateProvider", "$urlRouterProvider", "$locationProvider", "$cookiesProvider", "routeConstants", "NotificationProvider", "ChartJsProvider","ngQuillConfigProvider"];
    function routeConfiguration($stateProvider, $urlRouterProvider, $locationProvider, $cookiesProvider, routeConstants, NotificationProvider, ChartJsProvider, ngQuillConfigProvider) {
        var config = {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline'//, 'strike'
                    ],        // toggled buttons
                    ['blockquote', 'code-block'],
                   // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    //[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                    [{ 'direction': 'rtl' }],                         // text direction
                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                    [{ 'color': [] },
                    //{ 'background': [] }
                    ],          // dropdown with defaults from theme
                    //[{ 'font': [] }],
                    [{ 'align': [] }],

                    ['clean'],                                         // remove formatting button

                    //['link', 'image']                         // link and image, video
                ]
            },
            theme: 'snow',
            placeholder: '',
        };
        ngQuillConfigProvider.set(config);
        //bootstrap notification settings to be appeared in the right bottom of the screen
        NotificationProvider.setOptions({
            delay: 3000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'right',
            positionY: 'bottom',
            replaceMessage: true,
            maxCount : 1,
        });
        ChartJsProvider.setOptions({ colors: ['#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'] });
        $locationProvider.hashPrefix("");
        function CanValidTransition(shouldBeAuthenticated, state) {
            return ["$q", "$rootScope", function ($q, $rootScope) {
                var deferred = $q.defer();
                var isAuthenticated = false;
                $rootScope.IsAuthenticated().then(function (response) {
                    if (response && response.authenticated) {
                        isAuthenticated = true;
                    }
                    else {
                        isAuthenticated = false;
                    }
                }, function () {
                    isAuthenticated = false;
                    }).finally(function () {
                    if (shouldBeAuthenticated && !isAuthenticated) {
                        deferred.reject('401');
                    }
                    else if (shouldBeAuthenticated && isAuthenticated && $rootScope.GetCurrentUser() != null
                        && $rootScope.GetCurrentUser() != undefined
                        && !$rootScope.GetCurrentUser().IsProfileCompleted) {
                        //custom error code for incomplete profile 
                        if (state !== 'MBRGI' && state !== 'Entity' && state != 'Entity.SwitchEntities' && state.indexOf('UserProfile') == -1)
                            deferred.reject('null');
                        else
                            deferred.resolve();
                    }
                    else if (shouldBeAuthenticated && isAuthenticated && !$rootScope.IsAuthorized(state)) {
                        deferred.reject('403');
                    }
                    else {
                        deferred.resolve();
                    }
                });
              
                return deferred.promise;
            }];
        }
        $stateProvider
            .state(routeConstants.LoginState.State, {
                url: routeConstants.LoginState.URL,
                templateUrl: routeConstants.LoginState.TemplatePath,
                controller: "loginController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(false, routeConstants.LoginState.State)
                }
            }).state(routeConstants.ResetPassword.State, {
                url: routeConstants.ResetPassword.URL,
                templateUrl: routeConstants.ResetPassword.TemplatePath,
                controller: "resetPasswordController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(false, routeConstants.ResetPassword.State)
                }
            })
            .state(routeConstants.HomeState.State, {
                url: routeConstants.HomeState.URL,
                templateUrl: routeConstants.HomeState.TemplatePath,
                controller: "homeController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.HomeState.State)
                }
            })
            .state(routeConstants.MBRGIHomeState.State, {
                url: routeConstants.MBRGIHomeState.URL,
                templateUrl: routeConstants.MBRGIHomeState.TemplatePath,
                controller: "homeController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIHomeState.State)
                }
            })
            .state(routeConstants.EntityHomeState.State, {
                url: routeConstants.EntityHomeState.URL,
                templateUrl: routeConstants.EntityHomeState.TemplatePath,
                controller: "homeController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntityHomeState.State)
                }
            })
            .state(routeConstants.MBRGIUserProfileState.State, {
                url: routeConstants.MBRGIUserProfileState.URL,
                templateUrl: routeConstants.MBRGIUserProfileState.TemplatePath,
                controller: "userProfileController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIUserProfileState.State)
                }
            }).state(routeConstants.EntityUserProfileState.State, {
                url: routeConstants.EntityUserProfileState.URL,
                templateUrl: routeConstants.EntityUserProfileState.TemplatePath,
                controller: "userProfileController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntityUserProfileState.State)
                }
            }).state(routeConstants.EntityProfileState.State, {
                url: routeConstants.EntityProfileState.URL,
                templateUrl: routeConstants.EntityProfileState.TemplatePath,
                controller: "entityProfileController",
                controllerAs: "vm",
                //resolve: {
                //	isValidTransition: CanValidTransition(true, routeConstants.EntityProfileState.State)
                //}
            })
            .state(routeConstants.MBRGIChnagePasswordState.State, {
                url: routeConstants.MBRGIChnagePasswordState.URL,
                templateUrl: routeConstants.MBRGIChnagePasswordState.TemplatePath,
                controller: "updatePasswordController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIChnagePasswordState.State)
                }
            })
            .state(routeConstants.EntityChnagePasswordState.State, {
                url: routeConstants.EntityChnagePasswordState.URL,
                templateUrl: routeConstants.EntityChnagePasswordState.TemplatePath,
                controller: "updatePasswordController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntityChnagePasswordState.State)
                }
            })
            .state(routeConstants.SwitchEntitiesState.State, {
                url: routeConstants.SwitchEntitiesState.URL,
                templateUrl: routeConstants.SwitchEntitiesState.TemplatePath,
                controller: "switchEntitiesController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.SwitchEntitiesState.State)
                }
            }).state(routeConstants.EntityDashboardState.State, {
                url: routeConstants.EntityDashboardState.URL,
                templateUrl: routeConstants.EntityDashboardState.TemplatePath,
                controller: "entityDashboardController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntityDashboardState.State)
                }
            }).state(routeConstants.MBRGIDashboardState.State, {
                url: routeConstants.MBRGIDashboardState.URL,
                templateUrl: routeConstants.MBRGIDashboardState.TemplatePath,
                controller: "mbrgiDashboardController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIDashboardState.State)
                }
            })
            .state(routeConstants.MBRGIUserState.State, {
                url: routeConstants.MBRGIUserState.URL,
                templateUrl: routeConstants.MBRGIUserState.TemplatePath,
                controller: "userController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIUserState.State)
                }
            })
            .state(routeConstants.EntityUserState.State, {
                url: routeConstants.EntityUserState.URL,
                templateUrl: routeConstants.EntityUserState.TemplatePath,
                controller: "userController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntityUserState.State)
                }
            })
            .state(routeConstants.MBRGIEntityUsersState.State, {
                url: routeConstants.MBRGIEntityUsersState.URL,
                templateUrl: routeConstants.MBRGIEntityUsersState.TemplatePath,
                controller: "userController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIEntityUsersState.State)
                }
            })
            .state(routeConstants.EntityState.State, {
                url: routeConstants.EntityState.URL,
                templateUrl: routeConstants.EntityState.TemplatePath,
                controller: "entityController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntityState.State)
                }
            })
			.state(routeConstants.CountryState.State, {
				url: routeConstants.CountryState.URL,
				templateUrl: routeConstants.CountryState.TemplatePath,
				controller: "countryController",
				controllerAs: "vm",
				resolve: {
					isValidTransition: CanValidTransition(true, routeConstants.CountryState.State)
				}
			}).state(routeConstants.LookupsState.State, {
				url: routeConstants.LookupsState.URL,
				templateUrl: routeConstants.LookupsState.TemplatePath,
				controller: "lookupsController",
				controllerAs: "vm",
				resolve: {
					isValidTransition: CanValidTransition(true, routeConstants.LookupsState.State)
				}
            }).state(routeConstants.PredefinedLookupState.State, {
                url: routeConstants.PredefinedLookupState.URL,
                templateUrl: routeConstants.PredefinedLookupState.TemplatePath,
                controller: "predefinedLookupController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.PredefinedLookupState.State)
                }
            }).state(routeConstants.LookupEntriesState.State, {
				url: routeConstants.LookupEntriesState.URL,
				templateUrl: routeConstants.LookupEntriesState.TemplatePath,
				controller: "lookupEntriesController",
				controllerAs: "vm",
				resolve: {
					isValidTransition: CanValidTransition(true, routeConstants.LookupEntriesState.State)
				}
			}).state(routeConstants.LookupDeletedEntriesState.State, {
				url: routeConstants.LookupDeletedEntriesState.URL,
				templateUrl: routeConstants.LookupDeletedEntriesState.TemplatePath,
				controller: "lookupDeletedEntriesController",
				controllerAs: "vm",
				resolve: {
					isValidTransition: CanValidTransition(true, routeConstants.LookupDeletedEntriesState.State)
				}
			}).state(routeConstants.RestoreCountryState.State, {
                url: routeConstants.RestoreCountryState.URL,
                templateUrl: routeConstants.RestoreCountryState.TemplatePath,
                controller: "restoreCountryController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.RestoreCountryState.State)
                }
            }).state(routeConstants.MBRGIEntityProfileState.State, {
                url: routeConstants.MBRGIEntityProfileState.URL,
                templateUrl: routeConstants.MBRGIEntityProfileState.TemplatePath,
                controller: "entityProfileController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIEntityProfileState.State)
                }
            })
            .state(routeConstants.AddEntityState.State, {
                url: routeConstants.AddEntityState.URL,
                templateUrl: routeConstants.AddEntityState.TemplatePath,
                controller: "entityController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.AddEntityState.State)
                }
            })
            .state(routeConstants.MBRGIReportsState.State, {
                url: routeConstants.MBRGIReportsState.URL,
                template: routeConstants.MBRGIReportsState.Template,
               // controller: "reportController",
                //controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIReportsState.State),
                }
            })
            .state(routeConstants.EntityReportsState.State, {
                url: routeConstants.EntityReportsState.URL,
                template: routeConstants.EntityReportsState.Template,
               // controller: "entityReportController",
                //controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntityReportsState.State),
                }
            })
            .state(routeConstants.PillarState.State, {
                url: routeConstants.PillarState.URL,
                templateUrl: routeConstants.PillarState.TemplatePath,
                controller: "pillarController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.PillarState.State)
                }
            }).state(routeConstants.CycleState.State, {
                url: routeConstants.CycleState.URL,
                templateUrl: routeConstants.CycleState.TemplatePath,
                controller: "cycleController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.CycleState.State)
                }
            })
            .state(routeConstants.AddPillarState.State, {
                url: routeConstants.AddPillarState.URL,
                templateUrl: routeConstants.AddPillarState.TemplatePath,
                controller: "pillarController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.AddPillarState.State)
                }
            })
            .state(routeConstants.BeneficiaryUnitState.State, {
                url: routeConstants.BeneficiaryUnitState.URL,
                templateUrl: routeConstants.BeneficiaryUnitState.TemplatePath,
                controller: "beneficiaryunitController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.BeneficiaryUnitState.State)
                }
            }).state(routeConstants.RestoreBeneficiaryUnitState.State, {
                url: routeConstants.RestoreBeneficiaryUnitState.URL,
                templateUrl: routeConstants.RestoreBeneficiaryUnitState.TemplatePath,
                controller: "restoreBeneficiaryunitController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.RestoreBeneficiaryUnitState.State)
                }
            })
            .state(routeConstants.AnnualAchievementState.State, {//refactor this state to be abstract state
                url: routeConstants.AnnualAchievementState.URL,
                template: routeConstants.AnnualAchievementState.Template,
                controller: "annualAchievementController",
                controllerAs: "vm",
                params: {
                    cycleID: { value: null, squash: true }
                },
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.AnnualAchievementState.State),
                }
            }).state(routeConstants.SharedInitiativesState.State, {//refactor this state to be abstract state
                url: routeConstants.SharedInitiativesState.URL,
                template: routeConstants.SharedInitiativesState.Template,
                controller: "sharedInitiativesController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.SharedInitiativesState.State),
                }
            }).state(routeConstants.SharedInitiativeState.State, {
                url: routeConstants.SharedInitiativeState.URL,
                template: routeConstants.SharedInitiativeState.Template,
                controller: "sharedInitiativeController",
                controllerAs: "vm",
              
            }).state(routeConstants.SectionSubmissionsState.State, {
                url: routeConstants.SectionSubmissionsState.URL,
                template: routeConstants.SectionSubmissionsState.Template,
                controller: "sectionSubmissionsController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.SectionSubmissionsState.State),
                }
            }).state(routeConstants.DeletedSectionSubmissionsState.State, {
                url: routeConstants.DeletedSectionSubmissionsState.URL,
                template: routeConstants.DeletedSectionSubmissionsState.Template,
                controller: "sectionSubmissionsController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.DeletedSectionSubmissionsState.State),
                }
            }).state(routeConstants.SectionSubmissionState.State, {
                url: routeConstants.SectionSubmissionState.URL,
                template: routeConstants.SectionSubmissionState.Template,
                controller: "sectionSubmissionController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.SectionSubmissionState.State),
                }
            }).state(routeConstants.TasksState.State, {
                url: routeConstants.TasksState.URL,
                template: routeConstants.TasksState.Template,
                controller: "tasksController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.TasksState.State),
                }
            }).state(routeConstants.TaskState.State, {
                url: routeConstants.TaskState.URL,
                template: routeConstants.TaskState.Template,
                controller: "taskController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.TaskState.State),
                }
            }).state(routeConstants.MBRGIAnnualAchievementsState.State, {
                url: routeConstants.MBRGIAnnualAchievementsState.URL,
                template: routeConstants.MBRGIAnnualAchievementsState.Template,
                controller: "MBRGIAnnualAchievementsController",
                controllerAs: "vm",
                  params: {
                    cycleID: { value: null, squash: true }
                },
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIAnnualAchievementsState.State),
                }
            }).state(routeConstants.MBRGIAnnualAchievementState.State, {//refactor this state to be abstract state
                url: routeConstants.MBRGIAnnualAchievementState.URL,
                template: routeConstants.MBRGIAnnualAchievementState.Template,
                controller: "MBRGIAnnualAchievementController",
                controllerAs: "vm",
              
            }).state(routeConstants.MBRGISectionSubmissionsState.State, {
                url: routeConstants.MBRGISectionSubmissionsState.URL,
                template: routeConstants.MBRGISectionSubmissionsState.Template,
                controller: "sectionSubmissionsMBRGIController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGISectionSubmissionsState.State),
                }
            }).state(routeConstants.MBRGIDeletedSectionSubmissionsState.State, {
                url: routeConstants.MBRGIDeletedSectionSubmissionsState.URL,
                template: routeConstants.MBRGIDeletedSectionSubmissionsState.Template,
                controller: "sectionSubmissionsMBRGIController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIDeletedSectionSubmissionsState.State),
                }
            }).state(routeConstants.MBRGISectionSubmissionState.State, {
                url: routeConstants.MBRGISectionSubmissionState.URL,
                template: routeConstants.MBRGISectionSubmissionState.Template,
                controller: "sectionSubmissionMBRGIController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGISectionSubmissionState.State),
                }
            }).state(routeConstants.MBRGIComparisonReportsState.State, {
                url: routeConstants.MBRGIComparisonReportsState.URL,
                template: routeConstants.MBRGIComparisonReportsState.Template,
                controller: "MBRGIComparisonReport",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIComparisonReportsState.State),
                }

            }).state(routeConstants.MBRGISectionReportsState.State, {
                url: routeConstants.MBRGISectionReportsState.URL,
                template: routeConstants.MBRGISectionReportsState.Template,
                controller: "MBRGISectionReportController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGISectionReportsState.State),
                }
            })
            .state(routeConstants.EntitySectionReportsState.State, {
                url: routeConstants.EntitySectionReportsState.URL,
                template: routeConstants.EntitySectionReportsState.Template,
                controller: "EntitySectionReportController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntitySectionReportsState.State),
                }
            }).state(routeConstants.EntityComparisonReportsState.State, {
                url: routeConstants.EntityComparisonReportsState.URL,
                template: routeConstants.EntityComparisonReportsState.Template,
                controller: "EntityComparisonReport",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntityComparisonReportsState.State),
                }
            })
            .state(routeConstants.SectionRequestState.State, {
                url: routeConstants.SectionRequestState.URL,
                template: routeConstants.SectionRequestState.Template,
                controller: "requestController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.SectionRequestState.State),
                }
            }).state(routeConstants.SectionsAssignmentState.State, {//refactor this state to be abstract state
                url: routeConstants.SectionsAssignmentState.URL,
                template: routeConstants.SectionsAssignmentState.Template,
                controller: "sectionsAssignmentController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.SectionsAssignmentState.State),
                }
            }).state(routeConstants.SectionAssignmentSubmissionsState.State, {
                url: routeConstants.SectionAssignmentSubmissionsState.URL,
                template: routeConstants.SectionAssignmentSubmissionsState.Template,
                controller: "sectionAssignmentSubmissionsController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.SectionAssignmentSubmissionsState.State),
                }
            }).state(routeConstants.SectionAssignmentSubmissionState.State, {
                url: routeConstants.SectionAssignmentSubmissionState.URL,
                template: routeConstants.SectionAssignmentSubmissionState.Template,
                controller: "sectionAssignmentSubmissionController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.SectionAssignmentSubmissionState.State),
                }
            }).state(routeConstants.NotificationState.State, {
                url: routeConstants.NotificationState.URL,
                template: routeConstants.NotificationState.Template,
                controller: "notificationController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.NotificationState.State),
                }
            }).state(routeConstants.EntityCountiresReportsState.State, {
                url: routeConstants.EntityCountiresReportsState.URL,
                template: routeConstants.EntityCountiresReportsState.Template,
                controller: "EntityCountriesReportController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntityCountiresReportsState.State),
                }
            }).state(routeConstants.MBRGICountiresReportsState.State, {
                url: routeConstants.MBRGICountiresReportsState.URL,
                template: routeConstants.MBRGICountiresReportsState.Template,
                controller: "MBRGICountriesReportController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGICountiresReportsState.State),
                }
            }).state(routeConstants.MBRGIScholarshipsReportsState.State, {
                url: routeConstants.MBRGIScholarshipsReportsState.URL,
                template: routeConstants.MBRGIScholarshipsReportsState.Template,
                controller: "MBRGIScholarshipsReport",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIScholarshipsReportsState.State),
                }
            }).state(routeConstants.EntitiesScholarshipsReportsState.State, {
                url: routeConstants.EntitiesScholarshipsReportsState.URL,
                template: routeConstants.EntitiesScholarshipsReportsState.Template,
                controller: "EntityScholarshipsReport",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntitiesScholarshipsReportsState.State),
                }
            }).state(routeConstants.MBRGIBeneficiariesReportsState.State, {
                url: routeConstants.MBRGIBeneficiariesReportsState.URL,
                template: routeConstants.MBRGIBeneficiariesReportsState.Template,
                controller: "MBRGIBeneficiariesReport",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIBeneficiariesReportsState.State),
                }
            }).state(routeConstants.EntitiesBeneficiariesReportState.State, {
                url: routeConstants.EntitiesBeneficiariesReportState.URL,
                template: routeConstants.EntitiesBeneficiariesReportState.Template,
                controller: "EntityBeneficiariesReport",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntitiesBeneficiariesReportState.State),
                }
            }).state(routeConstants.MBRGIAwardsReportsState.State, {
                url: routeConstants.MBRGIAwardsReportsState.URL,
                template: routeConstants.MBRGIAwardsReportsState.Template,
                controller: "MBRGIAwardsReport",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIAwardsReportsState.State),
                }
            }).state(routeConstants.EntitiesAwardsReportsState.State, {
                url: routeConstants.EntitiesAwardsReportsState.URL,
                template: routeConstants.EntitiesAwardsReportsState.Template,
                controller: "EntityAwardsReport",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntitiesAwardsReportsState.State),
                }
            }).state(routeConstants.MBRGIFinancialReportsState.State, {
                url: routeConstants.MBRGIFinancialReportsState.URL,
                template: routeConstants.MBRGIFinancialReportsState.Template,
                controller: "MBRGIFinancialReport",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIFinancialReportsState.State),
                }
            }).state(routeConstants.EntitiesFinancialReportsState.State, {
                url: routeConstants.EntitiesFinancialReportsState.URL,
                template: routeConstants.EntitiesFinancialReportsState.Template,
                controller: "EntityFinancialReport",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntitiesFinancialReportsState.State),
                }
            }).state(routeConstants.EntityNotificationState.State, {
                url: routeConstants.EntityNotificationState.URL,
                template: routeConstants.EntityNotificationState.Template,
                controller: "notificationController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntityNotificationState.State),
                }
            })
            .state(routeConstants.SectionListState.State, {
                url: routeConstants.SectionListState.URL,
                template: routeConstants.SectionListState.Template,
                controller: "manageSectionsController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.SectionListState.State),
                }
            }).state(routeConstants.SectionUpdateState.State, {
                url: routeConstants.SectionUpdateState.URL,
                template: routeConstants.SectionUpdateState.Template,
                controller: "updateSectionController",
                params: {
                    sectionID: { value: null, squash: true }
                },
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.SectionUpdateState.State),
                }
            }).state(routeConstants.DeletedSectionAssignmentSubmissionsState.State, {
                url: routeConstants.DeletedSectionAssignmentSubmissionsState.URL,
                template: routeConstants.DeletedSectionAssignmentSubmissionsState.Template,
                controller: "sectionAssignmentSubmissionsController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.DeletedSectionAssignmentSubmissionsState.State),
                }
            }).state(routeConstants.EmailTemplatesState.State, {
                url: routeConstants.EmailTemplatesState.URL,
                template: routeConstants.EmailTemplatesState.Template,
                controller: "emailTemplatesController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EmailTemplatesState.State),
                }
            })
            .state(routeConstants.AddEmailTemplatesState.State, {
                url: routeConstants.AddEmailTemplatesState.URL,
                template: routeConstants.AddEmailTemplatesState.Template,
                controller: "addEmailTemplateController",
                controllerAs: "vm",
                params: {
                    entityID: { value: null, squash: true }
                },
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.AddEmailTemplatesState.State),
                }
            })
            .state(routeConstants.EditEmailTemplatesState.State, {
                url: routeConstants.EditEmailTemplatesState.URL,
                template: routeConstants.EditEmailTemplatesState.Template,
                controller: "editEmailTemplateController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EditEmailTemplatesState.State),
                }
            })
            .state(routeConstants.RestoreEmailTemplatesState.State, {
                url: routeConstants.RestoreEmailTemplatesState.URL,
                template: routeConstants.RestoreEmailTemplatesState.Template,
                controller: "restoreEmailTemplatesController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.RestoreEmailTemplatesState.State),
                }
            }).state(routeConstants.MBRGIPublicSettings.State, {
                url: routeConstants.MBRGIPublicSettings.URL,
                template: routeConstants.MBRGIPublicSettings.Template,
                controller: "publicSettingsController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.MBRGIPublicSettings.State),
                }
            }).state(routeConstants.EntityPublicSettings.State, {
                url: routeConstants.EntityPublicSettings.URL,
                template: routeConstants.EntityPublicSettings.Template,
                controller: "publicSettingsController",
                controllerAs: "vm",
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.EntityPublicSettings.State),
                }
            })
            .state(routeConstants.FormBuilderState.State, {
                url: routeConstants.FormBuilderState.URL,
                template: routeConstants.FormBuilderState.Template,
                controller: "formlyBuilderController",
                controllerAs: "vm",
                params: {
                    sectionID: { value: null, squash: true }
                },
                resolve: {
                    isValidTransition: CanValidTransition(true, routeConstants.FormBuilderState.State),
                }
            })
            .state(routeConstants.ErrorState.State, {
                url: routeConstants.ErrorState.URL,
                templateUrl: routeConstants.ErrorState.TemplatePath
            });




        $urlRouterProvider.otherwise(routeConstants.LoginState.URL);



    }
}