export default {
    SHARED_INITIATIVIES: 'مبادرات مشتركة',
    NEW: 'جديد',
    EDITING: 'تحت التعديل',
    REVIEWING: 'تحت المراجعة',
    REVIEWED: 'تمت مراجعتها',
    SENT_BACK: 'معاد ارساله للتعديل',
    CREATE: 'إنشاء',
    REASSIGN: 'إعادة توجيه',
    REOPEN: 'إعادة فتح',
    EDITOR: 'المحرر',
    REVIEWER: 'المراجع',
    SAVE: 'حفظ',
    SUBMIT: 'تأكيد',
    APPROVE: 'تأكيد',
    SENDBACK: 'إعادة',
    SEND: 'إرسال',
    REQUIRED_COMMENT: 'التعليق مطلوب',

}