export default {
    ADD_CYCLE: 'إضافة دورة زمنية',
    UPDATE_CYCLE: 'تعديل الدورة الزمنية',
    CYCLE_NAME_EN: 'اسم الدورة (الإنجليزية)',
    CYCLE_NAME_AR: 'اسم الدورة (العربية)',
    START_DATE: 'تاريخ البدء',
    END_DATE: 'تاريخ الإنتهاء',
    DATA_ENTRY_START_DATE: 'تاريخ بداية إدخال البيانات',
    DATA_ENTRY_END_DATE: 'تاريخ نهاية إدخال البيانات',
    CUT_OFF_DATE: 'تاريخ التوقف',
    CYCLES: 'الدورات السنوية',
    REQUIRED_NAME_EN: 'اسم الدورة (الإنجليزية) مطلوب',
    REQUIRED_NAME_AR: 'اسم الدورة (العربية) مطلوب',
    REQUIRED_START_DATE: 'تاريخ البدء مطلوب',
    REQUIRED_END_DATE: 'تاريخ الإنتهاء مطلوب',
    REQUIRED_DATA_ENTRY_START_DATE: 'تاريخ بداية إدخال البيانات مطلوب',
    REQUIRED_DATA_ENTRY_END_DATE: 'تاريخ نهاية إدخال البيانات مطلوب',
    REQUIRED_CUT_OFF_DATE: 'تاريخ التوقف مطلوب',
    CYCLE_START_DATE_VALIDATION: 'تاريخ البدء يجب أن يكون قبل تاريخ الإنتهاء',
    CYCLE_END_DATE_VALIDATION: 'تاريخ الإنتهاء يجب أن يكون بعد تاريخ البدء',
    CYCLE_DATA_ENTRY_START_DATE_VALIDATION: 'تاريخ بداية ادخال البيانات يجب أن يكون قبل تاريخ تاريخ نهاية إدخال البيانات',
    CYCLE_DATA_ENTRY_START_END_VALIDATION: 'تاريخ نهاية ادخال البيانات يجب أن يكون بعد تاريخ بداية ادخال البيانات',
    CYCLE_CUT_OFF_DATE_VALIDATION: 'تاريخ التوقف يجب ان يكون بعد انتهاء الدورة وتاريخ نهاية إدخال البيانات ',

    UPDATE: 'تعديل',


    CYCLE_EXIST: 'لا يمكن تكرار اسم الدورة الزمنية',
    CYCLE_ADDED: 'تم إضافة الدورة الزمنية بنجاح',
    CYCLE_UPDATED: 'تم تعديل الدورة الزمنية بنجاح',
    CYCLE_DELETED: 'تم حذف الدورة الزمنية بنجاح',
    CYCLE_DELETE_QUES: 'هل تريد حذف الدورة الزمنية؟',
    DELETE_HEADER: 'حذف',
    ENTITY_NAME : 'المؤسسة',
}