export default {
    PAGE_TITLE: 'الأقسام',
    Section:'قسم',
    Section_NAME_LABEL: 'اسم القسم (بالإنجليزية)',
    Section_ARNAME_LABEL: 'اسم القسم (بالعربية)',

    Section_S_NAME_LABEL: 'الاسم المفرد للقسم (بالإنجليزية)',
    Section_S_ARNAME_LABEL: 'الاسم المفرد للقسم (بالعربية)',

    Section_DES_LABEL: 'تعليمات القسم (بالإنجليزية)',
    Section_ARDES_LABEL: 'تعليمات القسم(بالعربية)',

    Section_systemKey:'معرف النظام',
    Section_multiple:'قسم أنشطة متعددة',

    updateSection: 'تعديل قسم ',
    AddSection: 'إضافة قسم جديد',
    SectionList: 'المؤسسات',
    Section_Order: 'ترتيب القسم',
    SaveBtn: 'حفظ',
    CancelBtn: 'إلغاء',
    DeactivateSectionHeader: 'إلغاء تفعيل القسم',
    DeactivateSectionQues: 'هل تريد إلغاء تفعيل القسم؟',
    Section_DEACTIVATED: 'تم إلغاء تفعيل قسم بنجاح',
    Section_ACTIVATED: 'تم تفعيل قسم بنجاح',
    All_Entities: 'كل المؤسسات',

    MANDATORY: 'إلزامي',
    OPTIONAL: 'اختياري',
    VISIBLE: 'مرئى',
    INVISIBLE: 'غير مرئى',
    REQUIRED_Section_NAME: 'اسم القسم مطلوب',
    REQUIRED_Section_S_NAME: 'الاسم المفرد للقسم مطلوب',
    REQUIRED_SectionKey: 'معرف النظام مطلوب',
    Pattern_SectionKey: 'معرف النظام يجب أن يبدأ بحرف',
    Save_MSG: 'تم حفظ بيانات بنجاح',
    SectionKey_MSG: 'لا يمكنك تكرار الاسم.',
    SectionData: 'بيانات القسم',
    BuildForm: 'بناء النموذج',
    SINGLE_SECTION: 'قسم ذو نشاط واحد',

    DuplicateSectionEnName: ' لا يمكنك تكرار اسم القسم (بالإنجليزية)',
    DuplicateSectionArName: ' لا يمكنك تكرار اسم القسم (بالعربية)',
}