export default function (app) {
    app.controller('publicSettingsController', PublicSettingsController);
    PublicSettingsController.$inject = ["$rootScope", "publicSettingsDataService", "$state", "Notification","$translate"];
    function PublicSettingsController($rootScope, publicSettingsDataService, $state, Notification, $translate) {
        var vm = this;
        $translate(["COMMON.UPDATED_USER_SETTINGS"]).then(function (translations) {
            vm.labels = {
                updateUserSetting: translations['COMMON.UPDATED_USER_SETTINGS'],   
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.allowSendMail = false;
        vm.isEntityAdmin = false;
        vm.load = load;
        vm.load();
        function load() {
            vm.Loading();
            var getUserAllowMail = publicSettingsDataService.getUserAllowMail();
            var getNumberOfApprovalsForEntity = null;
            if ($rootScope.GetCurrentUser().CurrentRoleID === 3) {
                vm.isEntityAdmin = true
                getNumberOfApprovalsForEntity = publicSettingsDataService.getNumberOfApprovalsForEntity();
            }
            Promise.all([getUserAllowMail, getNumberOfApprovalsForEntity]).then(function (res) {
                if (res != undefined) {
                    getUserallowSendMailuccess(res[0]);
                    getNumberOfApprovalsForEntitySuccess(res[1]);
                    vm.Loading(false);
                } else {
                    vm.Loading(false);
                }
            });
        }
        function getUserallowSendMailuccess(res) {
            if (res && res.responseCode === vm.ResponseCode.Success) {
                vm.allowSendMail = res.responseObject;
            }
        }
        function getNumberOfApprovalsForEntitySuccess(res) {
            if (res && res.responseCode == vm.ResponseCode.Success) {
                vm.numOfApprovals = res.responseObject;
                vm.canEdit = res.canEdit;
            }
        }
        vm.save = function () {
            vm.Loading();
            var updateNumberOfApprovalsForEntity = null;
            var updateUserSetting = publicSettingsDataService.updateUserSetting(vm.allowSendMail);//.then(updateUserSettingSuccess, $rootScope.methodPublicError);
            if (vm.isEntityAdmin && vm.canEdit)
             updateNumberOfApprovalsForEntity = publicSettingsDataService.updateNumberOfApprovalsForEntity(vm.numOfApprovals);
            Promise.all([updateUserSetting, updateNumberOfApprovalsForEntity]).then(function (res) {
                if (res != undefined) {
                    Notification.success({ message: vm.labels.updateUserSetting });
                    vm.Loading(false);
                    $state.reload();
                } else {
                    vm.Loading(false);
                    $state.reload();
                }
            });
        }

    }
}