export default function (app) {
    app.controller('addPillarController', AddPillarController);

    AddPillarController.$inject = ["$rootScope", "pillarDataService", "$uibModalInstance", "Params", "Notification","$translate"];
    function AddPillarController($rootScope, pillarDataService, $uibModalInstance, Params, Notification, $translate) {
        var vm = this;
        $translate(["PILLARS.PILLAR_EXIST", "PILLARS.PILLAR_ADDED"]).then(function (translations) {
            vm.labels = {
                PillarIsAlreadyExist: translations['PILLARS.PILLAR_EXIST'],
                PillarAdded: translations['PILLARS.PILLAR_ADDED'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.responseCode = $rootScope.enums.ResponseCode;
        vm.Loading();
        vm.pillar = null;
        vm.load = load;
        vm.cancel = cancel;
        vm.addPillar = addPillar;


        vm.load();

        function load() {
            vm.Loading();
        }
        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false});
        }

        function addPillar(pillar) {
            vm.Loading();
            pillarDataService.IsPillarExist(pillar).then(isPillarExistSuccess, $rootScope.methodPublicError);
        }

        function isPillarExistSuccess(res) {
            if (res.responseCode == vm.responseCode.NotExist) {
                pillarDataService.AddPillar(vm.pillar).then(addPillarSuccess, $rootScope.methodPublicError);
            }
            else if (res.responseCode == vm.responseCode.Success) {
                Notification.warning({ message: vm.labels.PillarIsAlreadyExist });
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function addPillarSuccess(res) {
            if (res.responseCode == vm.responseCode.Success) {
                vm.Loading();
                Notification.success({ message: vm.labels.PillarAdded });
                $uibModalInstance.close({ Close: true, Ok: true });
            }
            else if (res.responseCode == vm.responseCode.Error) {
                $rootScope.methodPublicError();
                $uibModalInstance.close({ Close: true, Ok: false });
            }
        }
    }
}