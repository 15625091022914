import datepicker from './datepickerConfigController';
import fieldGroup from './fieldGroupConfigController';
import hijriDatepicker from './hijriDatepickerConfigController';
import imagePicker from './singleImagePickerConfigController';
import input from './inputConfigController';
import multiCheckbox from './multiCheckboxConfigController';
import numericInput from './numericInputConfigController';
import radio from './radioConfigController';
import fieldGroupRepeater from './fieldGroupRepeaterConfigController';
import select from './selectConfigController';
import filePicker from './filePickerConfigController';
import textarea from './textareaConfigController';
import responsiveLayout from './responsiveLayoutConfigController';
import checkbox from './checkboxConfigController';
import formlyBuilder from './formlyBuilderController';

export default function (formlyBuilderModule) {
    datepicker(formlyBuilderModule);
    fieldGroup(formlyBuilderModule);
    hijriDatepicker(formlyBuilderModule);
    imagePicker(formlyBuilderModule);
    multiCheckbox(formlyBuilderModule);
    numericInput(formlyBuilderModule);
    radio(formlyBuilderModule);
    fieldGroupRepeater(formlyBuilderModule);
    select(formlyBuilderModule);
    filePicker(formlyBuilderModule);
    textarea(formlyBuilderModule);
    input(formlyBuilderModule);
    checkbox(formlyBuilderModule);
    responsiveLayout(formlyBuilderModule);
    formlyBuilder(formlyBuilderModule);
}