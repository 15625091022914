export default {
    Email_Templates_Listing : 'Email templates',
    CREATE_EMAIL_TEMPLATE_TITLE: 'Create new email template',
    EDIT_EMAIL_TEMPLATE: 'Edit email template',
    EMAIL_TEMPLATE_TRASH: 'Email templates trash',
    EMAIL_TEMPLATE_DELETED: 'Email template had been successfully deleted',
    EMAIL_TEMPLATE_ACTIVATED: 'Email template has been activated successfully',
    EMAIL_TEMPLATE_DEACTIVATED: 'Email template has been deactivated successfully',
    DEACTIVATE_EMAIL_TEMPLATE: 'Deactivate Email-Template',
    DELETE_EMAIL_TEMPLATE:'Delete Email-Template',
    EMAIL_TEMPLATE_DELETE_QUES: 'Are you want to delete Email-template ?',
    EMAIL_TEMPLATE_DEACTIVATE_QUES: 'Are you want to deactivate Email-Template ?',
    EMAIL_TEMPLATE_RESTORED: 'Email Template had been successfully restored',
    EMAIL_TEMPLATE_ADDED:'Email Template had been successfully added',
    EMAIL_TEMPLATE_UPDATED:'Email Template had been successfully updated',
    EMAIL_TEMPLATE_NAME_EN: 'Email template name (English)',
    EMAIL_TEMPLATE_NAME_AR: 'Email template name (Arabic)',
    EMAIL_TEMPLATE_SUBJECT_ARABIC:'E-mail content (Arabic)',
    EMAIL_TEMPLATE_PAST_DATES: 'Previous date not allowed.',
    EMAIL_TEMPLATE_PAST_DATE_TIME: 'Previous date time not allowed.',
    EMAIL_EXIST: 'Email name is previously used',
    EMAIL_TEMPLATE_SUBJECT_ENGLISH: 'E-mail content (English)',
    SUBJECT_EN: 'Subject (English)',
    SUBJECT_AR: 'Subject (Arabic)',
    MAIL_CONTENT_EN: 'Mail content (English)',
    MAIL_CONTENT_AR: 'Mail content (Arabic)',
    TOKEN_LIST: 'Tokens List',
    ENTITIIES: 'Entities',
    SCHEDULED_DATE: 'Scheduled Date',
    SCHEDULED_TIME: 'Scheduled Time',
    PREVIEW: 'Preview',
}


