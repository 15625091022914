import { remove as _remove, map as _map } from 'lodash';
import hijriMoment from 'moment-hijri';
export default function (app) {
    app.controller('addEmailTemplateController', AddEmailTemplatesController);

    AddEmailTemplatesController.$inject = ["$scope", "$rootScope", "emailTemplatesDataService", "$uibModal", "Notification", "$translate", "$stateParams", "$state", "$filter", '$transitions'] ;
    function AddEmailTemplatesController($scope, $rootScope, emailTemplatesDataService, $uibModal, Notification, $translate, $stateParams, $state, $filter, $transitions) {
        var vm = this;
        $translate(["EMAIL_TEMPLATES.EMAIL_TEMPLATE_ADDED", "EMAIL_TEMPLATES.EMAIL_EXIST", "COMMON.NAVIGATION_CONFIRMATION_HEADER", "COMMON.NAVIGATION_CONFIRMATION_MESSAGE"]).then(function (translations) {
            vm.labels = {
                EMAIL_TEMPLATE_ADDED: translations['EMAIL_TEMPLATES.EMAIL_TEMPLATE_ADDED'],
                EMAIL_EXIST: translations['EMAIL_TEMPLATES.EMAIL_EXIST'],
                Header: translations['COMMON.NAVIGATION_CONFIRMATION_HEADER'],
                Message: translations['COMMON.NAVIGATION_CONFIRMATION_MESSAGE'], 
            }
        });
        vm.Loading = $rootScope.Loading;
        $scope.currentLang = $translate.use();
        vm.ResponseCode = $rootScope.enums.ResponseCode;
        vm.emailTemplate = {};
        vm.Modal = null;
        vm.isDirty = false;

        vm.changeControl = function () {
            vm.isDirty = true;
        }

        vm.load = load;
        vm.Today = new Date();
        vm.load();
        vm.isBeforeToday = isBeforeToday;
        vm.isBeforeTodayTime = isBeforeTodayTime;
        vm.SelectedEntities = [];
        function isBeforeToday() {
            var minDate = $filter('date')(new Date(), "MM-dd-yyyy");
            var maxDate = GetDateIn_MM_DD_YYYYFormate(vm.ScheduleDate);
            if (minDate == maxDate)
                return true;
            if (minDate && maxDate) {
                var valid = hijriMoment(minDate).isBefore(maxDate);
                return valid;
            }
            return false;
        }
        function isBeforeTodayTime() {
            var todayTime = $filter('date')(new Date(), "HH:mm:ss");
            var scheduleTime = $filter('date')(vm.ScheduleTime, "HH:mm:ss");
            if (todayTime && scheduleTime) {
                if (vm.ScheduleDate == $filter('date')(new Date(), $scope.currentLang == 'ar' ? 'yyyy-MM-dd' : 'dd-MM-yyyy')) {
                    var selectedTime = new Date();
                    var parts = scheduleTime.split(":");
                    selectedTime.setHours(parts[0], parts[1], parts[2], 0);
                    var valid = hijriMoment(new Date()).isBefore(selectedTime);
                    return valid;
                }
                
            }
            return true;
        }
        function GetDateIn_MM_DD_YYYYFormate(date) {
            var parts = date.split("-");
            var newDate = "";
            if ($scope.currentLang == 'ar') {
                newDate = parts[1] + "-" + parts[2] + "-" + parts[0];                
            }
            else {
                 newDate = parts[1] + "-" + parts[0] + "-" + parts[2];
            }
            return newDate;
        }
        function load() {
            vm.Loading();
            vm.SelectedEntities = $stateParams.entityID != null ? [Number($stateParams.entityID)] : [];
            vm.emailTemplate.ScheduleDateTime = new Date();
            vm.ScheduleDate = $filter('date')(new Date(), $scope.currentLang == 'ar' ? 'yyyy-MM-dd' : 'dd-MM-yyyy');
            vm.ScheduleTime = new Date($filter('date')(new Date(), 'yyyy-MM-dd HH:mm'));

            var getEmailCreationTokensListFunc = emailTemplatesDataService.getEmailCreationTokensList();
            var getAllEntitiesCardsInfoFunc = emailTemplatesDataService.getAllEntitiesCardsInfo();
            Promise.all([getEmailCreationTokensListFunc, getAllEntitiesCardsInfoFunc]).then(function (res) {
                if (res != undefined) {
                    getEmailCreationTokensListSuccess(res[0]);
                    getAllEntitiesCardsInfoSuccess(res[1]);
                    vm.Loading(false);
                    $scope.$apply();
                } else {
                    vm.Loading(false);
                }
            });
        }

        vm.createEmailTemplate = function () {            
            vm.Loading();
            resolveCheckedEntities();
            emailTemplatesDataService.createEmailTemplate(vm.emailTemplate).then(createEmailTemplateSuccess, $rootScope.methodPublicError);
        }

        vm.previewEmailTemplate = function (SubjectEn, SubjectAr, bodyEn, bodyAr) {
            if (document.getElementsByClassName("modal").length === 0)
                vm.Modal = $uibModal.open({
                    keyboard: true,
                    size: 'lg',
                    animation: true,
                    templateUrl: 'ng/Modules/MBRGI/EmailTemplates/Views/preview-emailTemplate.html',
                    controller: 'previewEmailTemplateController',
                    controllerAs: 'vm',
                    resolve: {
                        Params: function () {
                            return { SubjectEn: SubjectEn, SubjectAr: SubjectAr, bodyEn: bodyEn, bodyAr: bodyAr, tokensList: vm.tokensList };
                        }
                    }
                });
            vm.Modal.result.then(function (result) {
               
            }, function (error) {
                //backdrop clicked or error
            });
        }

        vm.cancel = function () {
            $state.go('MBRGI.EmailTemplates');
        }
      
        function resolveCheckedEntities() {
            if (vm.SelectedEntities.indexOf(0) != -1) {
                vm.emailTemplate.RecieversEntities = _map(vm.entitiesList, (item) => {
                    return item.id;
                });
            }
            else {
                vm.emailTemplate.RecieversEntities = vm.SelectedEntities;
            }
            if (vm.ScheduleDate && vm.ScheduleTime) {
                var scheduleTime = $filter('date')(vm.ScheduleTime, "HH:mm:ss");                                                
                var scheduleDate = GetDateIn_MM_DD_YYYYFormate(vm.ScheduleDate);
                vm.emailTemplate.ScheduleDateTime = scheduleDate + ' ' + scheduleTime;
            }
        }

        function getEmailCreationTokensListSuccess(res) {
            if (res && res.responseCode === vm.ResponseCode.Success && res.responseObject) {
                vm.tokensList = res.responseObject.tokensList;
            }
            else
                $rootScope.methodPublicError();
        }

        function getAllEntitiesCardsInfoSuccess(res) {
            if (res && res.responseCode === vm.ResponseCode.Success && res.responseObject) {
                vm.entitiesList = res.responseObject;
                vm.entitiesList.unshift({ id: 0, title: $translate.instant('COMMON.ALL') });
            }
            else
                $rootScope.methodPublicError();
        }

        function createEmailTemplateSuccess(res) {
            vm.Loading(false);
            if (res && res.responseCode === vm.ResponseCode.Success) {
                vm.isDirty = false;
                Notification.success({ message: vm.labels.EMAIL_TEMPLATE_ADDED });
                $state.go('MBRGI.EmailTemplates');
            }
            else if (res.responseCode == $rootScope.enums.ResponseCode.Duplicated)
            {
                Notification.error({ message: vm.labels.EMAIL_EXIST });
            }
            else {
                $rootScope.methodPublicError();
            }
        }

        $scope.$watch('vm.SelectedEntities', function (newValue, oldValue) {
            if (newValue != undefined && oldValue != undefined) {
                var oldValueIndex = oldValue.indexOf(0);
                var newValueIndex = newValue.indexOf(0);
                if ((oldValueIndex == -1) && (newValueIndex !== -1)) {
                    vm.SelectedEntities = [0];
                }
                else if (oldValueIndex !== -1 && newValueIndex !== -1 && newValue.length > 1) {
                    var index = vm.SelectedEntities.indexOf(0);
                    vm.SelectedEntities.splice(index, 1);
                }

            }
        }, true);
       
        var _onTransitionCallback = function (transition) {
            var labels = vm.labels;

            if (vm.isDirty) {
                if (document.getElementsByClassName("modal").length === 0)
                    vm.Modal = $uibModal.open({
                        keyboard: true,
                        size: 'md modal-dialog-centered',
                        animation: true,
                        templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
                        controller: function ($uibModalInstance) {
                            var vm = this;
                            vm.header = labels.Header;
                            vm.message = labels.Message;
                            vm.ok = function () {
                                $uibModalInstance.close();
                            };
                            vm.cancel = function () {
                                $uibModalInstance.dismiss();
                            };
                        },
                        controllerAs: 'vm'
                    });
                return vm.Modal.result.then(() => {
                    return Promise.resolve();
                }).catch(() => {
                    //revert url changes if user click back btn
                    var options = transition.options();
                    if (options.source === 'url') {
                        var $state = transition.router.stateService;
                        var $urlRouter = transition.router.urlRouter;
                        var urlOptions = { replace: false };
                        $urlRouter.push($state.$current.navigable.url, $state.params, urlOptions);
                        $urlRouter.update(true);
                    }
                    return Promise.reject();
                });
            }

                
            
        }
        var _unregisterOnBeforeTransition = $transitions.onBefore({ from: $state.current.name },
            _onTransitionCallback);
        $scope.$on('$destroy', function () {
            if (_unregisterOnBeforeTransition) {
                _unregisterOnBeforeTransition();
            }

            if (vm.Modal && vm.Modal.close) {
                vm.Modal.close();
            }      

        })

    }
}