export default {
    COMMENTS: 'Comments',
    COMMENT: 'Comment',
    ALL: 'All',
    TO_DO: 'To Do',
    COMPLETED: 'Completed',
    REVIEW: 'Reviewing',
    EDITING: 'Editing',

    NEW: 'New',
    EDITED: 'Edited',
    REVIEWING: 'Reviewing',
    REVIEWED: 'Reviewed',
    SENT_BACK: 'Sent back',
    CREATE: 'Create',
    REASSIGN: 'Reassign',
    REOPEN: 'Reopen',
    EDITOR: 'Editor',
    REVIEWER: 'Reviewer',
    SAVE: 'Save',
    SUBMIT: 'Submit',
    APPROVE: 'Approve',
    SENDBACK: 'Send back',

    DELETE: 'Delete',
    DELETEQUESTION: 'Are you sure you want to delete?',
    ADD_Task: 'Add new task',
    VIEW: 'View',
	TASK_TYPE: 'Task type',
	EDIT : 'Edit',
}