import 'angular-formly';
//import 'angular-formly-templates-bootstrap';
import {
	find as _find,
	filter as _filter,
	forEach as _forEach,
	isEqual as _isEqual,
	isEmpty as _isEmpty,
	has as _has,
	reduce as _reduce,
	get as _get,
	clone as _clone,
	random as _random,
	remove as _remove,
	findIndex as _findIndex,
	includes as _includes,
	split as _split,
	toLower as _toLower,
    cloneDeep as _cloneDeep,
} from 'lodash';
import hijriMoment from 'moment-hijri';
import 'ui-select';
import directives from './Directives';
import './Localization';

const formlyViewer = angular.module('formlyViewer', ['formly', 'common', 'ui.select']);

formlyViewer.directive('searchableSelect', ['$compile', function ($compile) {
	return {
		link: function (scope, element) {
			if (scope.to && scope.to.type == "searchable") {
				var el = require('./Views/searchableSelect.html');
				var template = angular.element(el)
				element.append(template);
				$compile(template)(scope);
			}
		},
	}
}]);

directives(formlyViewer);

formlyViewer.config(['formlyConfigProvider', function (formlyConfigProvider) {
	//helpers
   
	var _toNumber = function (value) {
		if (value) {
			return Number(value);
		}
	}

	var hijriDatePickerFormatter = function (value) {
		if (value) {
			let modelTimeStampDate = new Date(value).getTime();
			var hijriDateValue = hijriMoment(modelTimeStampDate).add(1, 'days');
			hijriDateValue = hijriDateValue.format('iYYYY-iMM-iDD');
			return hijriDateValue.toString();
		}
		else {
			return;
		}
	}

	var hijriDatePickerParser = function (value) {
		if (value) {
			return hijriMoment(value).add(1, 'days').format('YYYY-MM-DD');
		}
		else {
			return null;
		}
	}


	//wrappers
	formlyConfigProvider.setWrapper({
		name: 'panel',
		template: require('./Views/Wrappers/panel.html')
	});

	formlyConfigProvider.setWrapper({
		name: 'wizard',
		template: require('./Views/Wrappers/wizard.html')
	});

	formlyConfigProvider.setWrapper({
		name: 'responsiveLayout',
		template: require('./Views/Wrappers/responsiveLayout.html')
	});

	formlyConfigProvider.setWrapper({
		name: 'label',
		template: require('./Views/Wrappers/label.html')
	});

	formlyConfigProvider.setWrapper({
		name: 'checkboxContainer',
		template: require('./Views/Wrappers/checkboxContainer.html')
	});

	formlyConfigProvider.setWrapper({
		name: 'hasError',
		template: require('./Views/Wrappers/hasError.html')
	});

	formlyConfigProvider.setWrapper({
		name: 'horizontalLayout',
		template: require('./Views/Wrappers/horizontalLayout.html')
	});

	//types

	//alphanumeric
	formlyConfigProvider.setType({
		name: 'input',
		template: require('./Views/input.html'),
        wrapper: ['label', 'hasError'],
        controller: ['$scope', function ($scope) {
            $scope.inputType = $scope.to.type;
            $scope.isNumeric = $scope.to && $scope.to.type == "number";
            if ($scope.to.type == "number") {
                $scope.to.type = "input"
            }
        }]
	})

	//numericInput 
	formlyConfigProvider.setType({
		name: 'numericInput',
		template: '<input class="form-control" ng-model="model[options.key]">',
		wrapper: ['bootstrapLabel', 'bootstrapHasError'],
		defaultOptions: {
			ngModelAttrs: {
				type: { attribute: 'transform-to-numeric' },
				precision: { attribute: 'precision' },
                format: { attribute: 'format' }
			},
			parsers: [_toNumber],
		}
	});




	formlyConfigProvider.setType({
		name: 'checkbox',
		template: require('./Views/checkbox.html'),
		wrapper: ['checkboxContainer', 'hasError'],
	});





	//radio
	formlyConfigProvider.setType({
		name: 'radio',
		template: require('./Views/radio.html'),
		overwriteOk: true,
		wrapper: ['label', 'hasError'],
		defaultOptions: {
			noFormControl: false
		},
	});

	//signature
	formlyConfigProvider.setType({
		name: 'signature',
		template: require('./Views/signature.html'),
		wrapper: ['label', 'hasError'],
		controller: ['$scope', function ($scope) {
			$scope.viewMode = true;
			$scope.signatureWasModified = false;
			$scope.switchToViewMode = function () {
				$scope.viewMode = true;
				$scope.signatureWasModified = false;
			}
			$scope.switchToEditMode = function () {
				$scope.viewMode = false;
				$scope.resetSign();
			}
			$scope.saveSign = function () {
				var signature = $scope.getSignaturePadContent(); // this was passed to the scope from signature-pad directive in the view
				if ($scope.to.isBase64) {
					//the dataURL is in the following format "data:image/png;base64, {{content}}
					$scope.model[$scope.options.key] = signature.dataUrl;
				}
                /*
                  TODO:else should give the file a name to the model and the file content to form attachments
                */
				$scope.switchToViewMode();
			}
			$scope.resetSign = function () {
				$scope.signatureWasModified = false;
				$scope.clearSignaturePad(); // this was passed to the scope from signature-pad  directive in the view
			}
		}]
	});

	//hijriDatepicker
	formlyConfigProvider.setType({
		name: "hijriDatepicker",
		template: require('./Views/hijriDatepicker.html'),
		wrapper: ['bootstrapLabel', 'bootstrapHasError'],
		controller: ['$scope', '$translate', '$rootScope', function ($scope, $translate, $rootScope) {
			$scope.isOpen = false;
			$scope.minDate = null;
			$scope.maxDate = null;
			$scope.currentLang = $translate.use();

			//using YYYY-MM-DD since this is the default datepicker directive default min and max date format.
			if ($scope.to && $scope.to.minDate) {
				var minDate = $scope.to.minDate.toString();
				if (minDate.charAt(0) == "-") {
					$scope.minDate = hijriMoment().subtract(parseInt(minDate.substr(1)), 'milliseconds').format("iYYYY-iMM-iDD");
				} else if (minDate.charAt(0) == "+") {
					$scope.minDate = hijriMoment().add(parseInt(minDate.substr(1)), 'milliseconds').format("iYYYY-iMM-iDD");
				} else {
					$scope.minDate = hijriMoment(parseInt(minDate)).format("iYYYY-iMM-iDD");
				}
			}

			if ($scope.to && $scope.to.maxDate) {
				var maxDate = $scope.to.maxDate.toString();
				if (maxDate.charAt(0) == "-") {
					$scope.maxDate = hijriMoment().subtract(parseInt(maxDate.substr(1)), 'milliseconds').format("iYYYY-iMM-iDD");
				} else if (maxDate.charAt(0) == "+") {
					$scope.maxDate = hijriMoment().add(parseInt(maxDate.substr(1)), 'milliseconds').format("iYYYY-iMM-iDD");
				} else {
					$scope.maxDate = hijriMoment(parseInt(maxDate)).format("iYYYY-iMM-iDD");
				}
			}

			$rootScope.$on('$translateChangeEnd', function (event, args) {
				$scope.currentLang = args.language;
			});

			$scope.open = function () {
				$scope.isOpen = !$scope.isOpen;
			}
		}],
		defaultOptions: {
			formatters: [hijriDatePickerFormatter],
			parsers: [hijriDatePickerParser]
		}
	})


	//datepicker
	formlyConfigProvider.setType({
		name: "datepicker",
		template: require('./Views/datepicker.html'),
		wrapper: ['label', 'hasError'],
		controller: ['$scope', '$translate', '$rootScope', '$timeout', function ($scope, $translate, $rootScope, $timeout) {
			var _unregisterModelChanges = null;

			//TOODO refactor this controller to accept date as string instead of timestamp
			//using YYYY-MM-DD since this is the default datepicker directive default min and max date format.
			var _timestampToDate = function (date) {
				if (typeof date == "string" && date.charAt(0) == "-") {
					return hijriMoment().subtract(parseInt(date.substr(1)), 'milliseconds').format("YYYY-MM-DD");
				} else if (typeof date == "string" && date.charAt(0) == "+") {
					return hijriMoment().add(parseInt(date.substr(1)), 'milliseconds').format("YYYY-MM-DD");
				} else {
					return hijriMoment(parseInt(date)).format("YYYY-MM-DD");
				}
			}

			var _to = $scope.to ? $scope.to : {};
			$scope.isOpen = false;

			if (_to.min) {
				if (_to.min == "today") {
					$scope.min = _timestampToDate(new Date().getTime() + "");
				} else {
					$scope.min = _timestampToDate(new Date(_to.min).getTime() + "");
				}
			} else {
				$scope.min = null;
			}

			if (_to.max) {
				if (_to.max == "today") {
					$scope.max = _timestampToDate(new Date().getTime() + "");
				} else {
					$scope.max = _timestampToDate(new Date(_to.max).getTime() + "");
				}
			} else {
				$scope.max = null;
			}


			$scope.currentLang = $translate.use();
			$rootScope.$on('$translateChangeEnd', function (event, args) {
				$scope.currentLang = args.language;
			});


			$scope.$watch('to.max', function (newValue, oldValue) {
				if (newValue && newValue != oldValue) {
					if (typeof newValue == 'string') {
						if (newValue == "today") {
							$scope.max = _timestampToDate(new Date().getTime() + "");
						} else {
							$scope.max = _timestampToDate(new Date(newValue).getTime() + "");
						}

					} else {
						$scope.max = _timestampToDate(newValue);
					}
				}
			});


			$scope.$watch('to.min', function (newValue, oldValue) {
				if (newValue && newValue != oldValue) {
					if (typeof newValue == 'string') {
						if (newValue == "today") {
							$scope.min = _timestampToDate(new Date().getTime() + "");
						} else {
							$scope.min = _timestampToDate(new Date(newValue).getTime() + "");
						}
					} else {
						$scope.min = _timestampToDate(newValue);
					}
				}
			});



			$scope.open = function () {
				$scope.isOpen = !$scope.isOpen;
			}

			//a work round to add a formater to resolve integration bug with jquery date picker
			$timeout(() => {
				var _formateViewValue = function (ctrl, currentDateFormat, newDateFormat) {
					var _modelValue = ctrl.$modelValue;
					if (currentDateFormat != newDateFormat) {
						ctrl.$viewValue = ctrl.$$lastCommittedViewValue = hijriMoment(_modelValue, currentDateFormat).format(newDateFormat);
						ctrl.$render();
					}
				}

				//TODO check new tasks as $scope.model[$scope.options.key] will be null 
				_unregisterModelChanges = $scope.$watch(function ($scope) {
					return $scope.model[$scope.options.key];
				}, function (newValue, oldValue) {
					if (newValue) {
						var newDateFormat = $scope.currentLang == 'ar' ? 'YYYY-MM-DD' : 'DD-MM-YYYY';
						var currentDateFormat = $scope.to && $scope.to.dateFormat ? $scope.to.dateFormat : 'YYYY-MM-DD';
						_formateViewValue($scope.fc, currentDateFormat, newDateFormat);
					}
				}, true)
			}, 0)


			$scope.$on("$destroy", function () {
				if (_unregisterModelChanges) {
					_unregisterModelChanges();
				}
			});

		}],
	})

	//imagePicker
	formlyConfigProvider.setType({
		name: 'imagePicker',
		template: require('./Views/imagePicker.html'),
		controller: function ($scope) {
			$scope._inputModel = {};
			$scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];

			$scope.$watch('_inputModel', function (newInputModel, oldInputModel) {
				if (oldInputModel && newInputModel.base64) {
					$scope.model[$scope.options.key].push(newInputModel.base64);
					$scope._inputModel = {}; // reset file picker
				}
			});

			$scope.deleteImg = function (imgIndex) {
				if ($scope.model[$scope.options.key] && $scope.model[$scope.options.key][imgIndex]) {
					$scope.model[$scope.options.key].splice(imgIndex, 1);
				}
			}
		},
		wrapper: ['bootstrapLabel']
	})

	//repeatSection
	formlyConfigProvider.setType({
		name: 'repeatSection',
		template: require('./Views/repeatSection.html'),
		wrapper: ['bootstrapLabel', 'bootstrapHasError'],
		controller: ['$scope', '$uibModal', function ($scope, $uibModal) {
			$scope.animationsEnabled = true;
			$scope.OrderedKeysList = [];

			$scope.UpdatedFeilds = [];
			// $scope.repeatSectionfieldLabels = locale.GetCopmonentResource('repeatSection');
			$scope.repeatSectionfieldLabel = {}
			$scope.removeItem = function (index) {
				$scope.model[$scope.options.key].splice(index, 1);
			}

			$scope.editItem = function (index) {
				$scope.openModal($scope.to.fields, index)
			}

			$scope.openModal = function (formFields, itemToEditIndex) {
				var modalInstance = $uibModal.open({
					animation: $scope.animationsEnabled,
					ariaLabelledBy: 'modal-title',
					ariaDescribedBy: 'modal-body',
					templateUrl: 'formlyRepeatSectionModalContent.html',
					resolve: {
						items: function () {
							return $scope.items;
						}
					},
					controller: ['$scope', function (scope) {
						//scope.ModalfieldLabels = locale.GetCopmonentResource('repeatSection');
						scope.ModalfieldLabels = {}
						scope.itemToEditIndex = itemToEditIndex;
						scope.mode = itemToEditIndex !== undefined ? 'edit' : 'add';
						scope.item = (scope.mode === 'edit') ? angular.copy($scope.model[$scope.options.key][itemToEditIndex]) : {}; //holdes the item values
						scope.parentFormlyOptions = $scope.formOptions; //holdes the item values
						scope.formFields = formFields;
						scope.ok = function () {
							modalInstance.close({ item: scope.item, index: itemToEditIndex });
						};
						scope.close = function () {
							modalInstance.dismiss('cancel');
						};
					}]
				});

				modalInstance.result.then(function (result) {
					$scope.model[$scope.options.key] = Array.isArray($scope.model[$scope.options.key]) ? $scope.model[$scope.options.key] : [];
					if (result.index !== undefined)
						$scope.model[$scope.options.key][itemToEditIndex] = result.item;
					else
						$scope.model[$scope.options.key].push(result.item)

				}, function () {
				});
			};

			$scope.GetValuesOrdered = function () {
				if (!$scope.to.viewerConfig.modelValuesOrder || $scope.to.viewerConfig.modelValuesOrder.length === 0) {
					$scope.OrderedKeysList = $scope.options.templateOptions.fields.map(function (field) {
						return field.key;
					});
					$scope.UpdatedFeilds = $scope.options.templateOptions.fields;

				}
				else {
					$scope.OrderedKeysList = $scope.to.viewerConfig.modelValuesOrder;
				}

				$scope.UpdatedFeilds = $scope.options.templateOptions.fields.filter(function (f) {
					for (var key in $scope.OrderedKeysList) {
						if (f.key === $scope.OrderedKeysList[key])
							return f;
					}
				});

			}
			$scope.GetValuesOrdered();
		}]
	});

	//collapsibleRadioList
	formlyConfigProvider.setType({
		name: 'collapsibleRadioList',
		template: require('./Views/collapsibleRadioList.html'),
		controller: ['$scope', function ($scope) {
			$scope.VALUES = {
				ACCEPTED: 'ACCEPTED',
				PENALTY: 'PENALTY',
				DISABLED: 'DISABLED',
			};

			$scope.RESULT_OBJECT_PROPERTIES = {
				IDENTIFIER_KEY: 'identifierKey',
				VALUE: 'value',
				COMMENT: 'comment',
			}
			//shorthands
			var VALUES = $scope.VALUES;
			var RESULT_OBJECT_PROPERTIES = $scope.RESULT_OBJECT_PROPERTIES;


			var defaultItemValue = VALUES.ACCEPTED;
			$scope.labelProp = $scope.to.labelProp || 'name';
			$scope.keyProp = $scope.to.keyProp || 'key'; // tells the collapsible component which property represents the key of item inside the option

			$scope.changeItemProp = function (itemKey, prop, value) {
				var items = $scope.model[$scope.options.key];

				var prevItemValue = _find(items, function (item) { return item[RESULT_OBJECT_PROPERTIES.IDENTIFIER_KEY] === itemKey }) || { [RESULT_OBJECT_PROPERTIES.IDENTIFIER_KEY]: itemKey };
				var newItemValue = Object.assign({}, prevItemValue, { [prop]: value });

				//if item is disabled remove all data except key and value
				if (prop === RESULT_OBJECT_PROPERTIES.VALUE && value === VALUES.DISABLED) {
					newItemValue = { [RESULT_OBJECT_PROPERTIES.IDENTIFIER_KEY]: itemKey, [RESULT_OBJECT_PROPERTIES.VALUE]: value }
				}

				$scope.changeItemValue(itemKey, newItemValue)
			}
			$scope.getItemValueByKey = function (itemKey) {
				var items = $scope.model[$scope.options.key];
				return _find(items, function (item) { return item[RESULT_OBJECT_PROPERTIES.IDENTIFIER_KEY] === itemKey });
			}


			//if you removed this initialize remember to change the ng-model binding of comment as it depends on 
			// the fact that the model of each item is initialized by an object
			// this implementation is different than the mobile implementation as it initializes the values with default value while mobile doesn't
			//but i will leave it like that for demo purpose
			$scope.getOrInitItemValue = function (itemKey) {
				var itemValue = $scope.getItemValueByKey(itemKey);
				if (!itemValue) {
					var items = $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];
					itemValue = { [RESULT_OBJECT_PROPERTIES.IDENTIFIER_KEY]: itemKey };
					items.push(itemValue);
				}

				return itemValue;
			}

			$scope.changeItemValue = function (itemKey, value) {
				var items = $scope.model[$scope.options.key];
				var itemsWithoutChangedItem = _filter(items, function (item) { return item[RESULT_OBJECT_PROPERTIES.IDENTIFIER_KEY] !== itemKey })
				itemsWithoutChangedItem.push(Object.assign({}, value, { [RESULT_OBJECT_PROPERTIES.IDENTIFIER_KEY]: itemKey })); //object.assign is used only to make sure that the value contains the key
				$scope.model[$scope.options.key] = itemsWithoutChangedItem;
			}

			$scope.itemsContainPenalty = function () {
				var items = $scope.model[$scope.options.key];
				return !!_find(items, function (item) { return item[RESULT_OBJECT_PROPERTIES.VALUE] === VALUES.PENALTY });
			}

		}]
	});

	//fieldGroupRepeater
    var RepeaterEditing = [];
    var oldMode=[];
	formlyConfigProvider.setType({
		name: 'fieldGroupRepeater',
		template: require('./Views/fieldGroupRepeater.html'),
		controller: ['$scope', '$uibModal', function ($scope, $uibModal) {
			$scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];
            $scope.addedToAllowFormValidation = $scope.model[$scope.options.key];
            var repeaterIndex = 0;
            var parentName = "";
            RepeaterEditing = $scope.model.EntityEditingFields ? $scope.model.EntityEditingFields : RepeaterEditing;
            oldMode = $scope.model.oldModel ? $scope.model.oldModel  : oldMode;
			var _getRandomInt = function (min, max) {
				return Math.floor(Math.random() * (max - min)) + min;
			}

            var _addRandomIds = function (fields) {
                var isParent = $scope.model.EntityEditingFields ? true : false;
                var parentIndex = $scope.options.id.split('-')[1];
                var parentKey = $scope.options.id.split('.')[0];
				angular.forEach(fields, function (field, index) {
					if (field.fieldGroup) {
						_addRandomIds(field.fieldGroup);
						return; // fieldGroups don't need an ID
					}

					if (field.templateOptions && field.templateOptions.fields) {
						_addRandomIds(field.templateOptions.fields);
					}

                   
                    var indexz = -1;
                    if (isParent) {
                        field.id = field.id || (parentName + '.' + field.key + '-' + repeaterIndex + '-' + _getRandomInt(0, 9999));
                        indexz = RepeaterEditing.findIndex(x => x.length > 1 && x[0] == $scope.options.key && x[1] == repeaterIndex
                            && x[2] == field.key);
                    }
                    else {
                        field.id = field.id || (parentKey + '.' + field.key + '-' + repeaterIndex + '-' + _getRandomInt(0, 9999));
                        indexz = RepeaterEditing.findIndex(x => x.length > 1 && x[1] == parentIndex &&  x[2] == $scope.options.key && x[3] == repeaterIndex
                            && x[4] == field.key );
                    }
                        if (field.templateOptions && indexz > -1) {
                            field.templateOptions.IsEdited = true;
                        }
                    
				});
			}

            $scope.copyFields = function copyFields(fields, parentKey) {
                parentName = parentKey;
                fields = angular.copy(fields);
               
                _addRandomIds(fields);
                repeaterIndex++;
				return fields;
			}

			$scope.removeItem = function (index) {
				var modalInstance = $uibModal.open({
					//  backdrop: "static",
					keyboard: true,
					size: 'md modal-dialog-centered',
					animation: true,
					//backdropClick: true,
					templateUrl: 'ng/Modules/Common/Views/confirmation-popup.html',
					controller: ['$scope', '$translate', function (scope, $translate) {

						var vm = this;
						vm.header = $translate.instant('FORMLY_VIEWER.DELETE_REP_CONFIRM_HEADER');
						vm.message = $translate.instant('FORMLY_VIEWER.DELETE_REP_CONFIRM_MESSAGE');
						vm.ok = function () {
							$scope.fc.$setDirty();
							modalInstance.close({ Close: false, Ok: true });
						};
						vm.cancel = function () {
							modalInstance.close({ Close: true, Ok: false });
						};
					}],
					controllerAs: 'vm'
				});

				modalInstance.result.then(function (result) {
                    if (result.Ok) {
                        $scope.model[$scope.options.key].splice(index, 1);
                       
                        if ($scope.model.oldModel) {
                            $scope.model.oldModel[$scope.options.key].splice(index, 1);
                            var temp = $scope.model.EntityEditingFields.filter((x) => {

                                if (x.length < 2)
                                    return true;
                                else if (x[1] != index)
                                    return true;

                            });
                            $scope.model.EntityEditingFields = temp.map((x) => {
                                if (x.length > 1 && x[1] > index)
                                    x[1] = x[1] - 1;

                                return x;
                            });
                            $scope.model.EntityEditingFields = temp;
                        }
                        else
                        {
                            var parentKey = $scope.options.id.split('.')[0];
                            var parentIndex = $scope.options.id.split('-')[1];
                            oldMode[parentKey][parentIndex][$scope.options.key].splice(index, 1);
                            var temp = RepeaterEditing.filter((x) => {

                                if (x.length < 2)
                                    return true;
                                else if (x[1] == parentIndex && x[3] != index)
                                    return true;

                            });
                            RepeaterEditing = temp.map((x) => {
                                if (x.length > 1 && x[1] == parentIndex && x[3] > index)
                                    x[3] = x[3] - 1;

                                return x;
                            });
                            RepeaterEditing = temp;

                        }
                    }
				}, function (error) {
					//backdrop clicked or error
				});
			}


            $scope.addItem = function () {
                //
				$scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];
                $scope.model[$scope.options.key].push({});
                //oldMode;
                if ($scope.model.oldModel)
                { $scope.model.oldModel[$scope.options.key].push({}); }
                else
                {
                    var parentKey = $scope.options.id.split('.')[0];
                    var parentIndex = $scope.options.id.split('-')[1];
                
                    oldMode[parentKey][parentIndex][$scope.options.key].push({});
                }
				$scope.fc.$setDirty();
			}
		}]
	});

	//repeaterFieldsAggregator
	formlyConfigProvider.setType({
		name: 'repeaterFieldsAggregator',
		template: require('./Views/repeaterFieldsAggregator.html'),
        controller: ['$scope', '$parse', '$translate', function ($scope, $parse,$translate) {
			//TODO move expression and source outside to
			var _to = $scope.to;
            $scope.currentLang = $translate.use();
			$scope.$watch(function ($scope) {
				return _get($scope.model, _to.source);
			}, function (newValue, oldValue) {
				$scope.model[$scope.options.key] = _getResult(newValue);
			}, true)

			var _getResult = function (model) {
				if (!_isEmpty(model)) {
					return _reduce(model, function (result, value, key, collection) {
						var _canEvaluate = true;
						//check if all properties have value 
						_forEach(_to.computedProperties, (property) => {
							_canEvaluate = _has(value, property);
							return _canEvaluate;
						})
						if (_canEvaluate) {
							return result + parseFloat($parse(_to.expression)(value));
						} else {
							return result;
						}
					}, 0);
				}
				return 0;
			}


		}]
	});

	//formFieldsAggregator
	formlyConfigProvider.setType({
		name: 'formFieldsAggregator',
		template: require('./Views/formFieldsAggregator.html'),
        controller: ['$scope', '$parse', '$translate', function ($scope, $parse,$translate) {
			//TODO move expression outside to
			var _to = $scope.to;
            $scope.currentLang = $translate.use();
			$scope.$watch(function ($scope) {
				return $parse(_to.expression)($scope.model);
			}, function (newValue, oldValue) {
				if (newValue != oldValue) {
					$scope.model[$scope.options.key] = newValue;
				}
			}, true)

		}]
	})




	//select
	formlyConfigProvider.setType({
		name: 'select',
		template: require('./Views/select.html'),
		wrapper: ['label', 'hasError'],
		controller: ['$scope', function ($scope) {
			//if model value is an object and ngOptions compares objects by reference we find selected object in options list then update model to have the same reference
			if (!_isEmpty($scope.model[$scope.options.key]) && !_isEmpty($scope.to) && !_isEmpty($scope.to.options)) {
				var _valueKey = _get($scope, "to.valueProp", "value");
				var _selectedOptionIndex = _findIndex($scope.to.options, (option) => {
					return _isEqual($scope.model[$scope.options.key], option[_valueKey])
				});
				if (_selectedOptionIndex != -1) {
					$scope.model[$scope.options.key] = $scope.to.options[_selectedOptionIndex][_valueKey];
				}
			}


			$scope.isSearchable = $scope.to && $scope.to.type == "searchable";
			if ($scope.isSearchable) {
				$scope.searchableSelectModel = $scope.model[$scope.options.key];
				
				if (!$scope.to.notNull) {
					$scope.to.options.unshift({ ID: -1, Label: $scope.to.nullDisplay });
					if (!$scope.searchableSelectModel) {
						$scope.searchableSelectModel = $scope.to.options[0].ID;
					}
				}

				$scope.onSelect = function ($item, $model) {
					var _selectedValue = JSON.parse(angular.toJson($model));
					if (_selectedValue == -1) {
						$scope.model[$scope.options.key] = null;
					} else {
						$scope.model[$scope.options.key] = _selectedValue
					}
				}
            }
		}],
		apiCheck: check => ({
			templateOptions: {
				options: check.arrayOf(check.object),
				optionsAttr: check.string.optional,
				labelProp: check.string.optional,
				valueProp: check.string.optional,
				groupProp: check.string.optional,
				notNull: check.bool.optional,
				nullDisplay: check.string.optional
			}
		})
	});

	//textarea
	formlyConfigProvider.setType({
		name: 'textarea',
		template: require('./Views/textarea.html'),
		wrapper: ['label', 'hasError'],
		defaultOptions: {
			ngModelAttrs: {
				rows: { attribute: 'rows' },
				cols: { attribute: 'cols' }
			}
		},
		apiCheck: check => ({
			templateOptions: {
				rows: check.number.optional,
				cols: check.number.optional
			}
		})
	});

	//filePicker
	formlyConfigProvider.setType({
		name: 'filePicker',
		template: require('./Views/filePicker.html'),
		controller: function ($scope, Upload, formHelpersService, $rootScope) {
			
			//TODO pass file size limit 
			$scope.files = [];
            $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];
            $scope.addedToAllowFormValidation = !_isEmpty($scope.model[$scope.options.key]) ? $scope.model[$scope.options.key] : null;
			$scope.initialModel = _clone($scope.model[$scope.options.key]);
			$scope.initialFilesCount = $scope.initialModel.length;
			
            var _acceptExtensions = ($scope.options.templateOptions && $scope.options.templateOptions.accept) || ["png", "jpeg", "jpg", "pdf", "doc", "docx", "xls", "xlsx", "mp4", "avi"];

			//TODO use ng storage 
			if (!$scope.formState || !$scope.formState.filesPublicReadingPath || !$scope.formState.filesStorageKey) {
				throw "formly filePicker requires from state to be initialized with filesPublicReadingPath and filesStorageKey"
            }
            $scope.Msg = "";
            if ($scope.options.validation && $scope.options.validation.messages && $scope.options.validation.messages.accept) {
                $scope.Msg = $scope.options.validation.messages.accept + "  " + $scope.options.validation.messages.maxSize;
            }
         

			var _getFileType = function (fileName) {
				if (fileName && typeof fileName == 'string') {
					return fileName.split('.').pop();
				} else {
					return null;
				}
			}

			$scope.onChange = function ($files, $file, $newFiles, $duplicateFiles, $invalidFiles, $event) {
				_forEach($files, function (file) {
					var _fileType = file.name.split('.').pop();
					var _isValidFileExtension = _includes(_acceptExtensions, _toLower(_fileType));
					if (_isValidFileExtension) {
						var _fileName = file.name + "_" + _random(1, 99999, false) + '.' + _fileType;
						var _file = Upload.rename(file, _fileName);
                        $scope.model[$scope.options.key].push(_file.ngfName);
                        $scope.addedToAllowFormValidation = $scope.model[$scope.options.key]
						$scope.files.push(_file);
						formHelpersService.addFile($scope.formState.filesStorageKey, _file);
                        $scope.onFileSelectionDialogOpen();
					}
				});
			}

			$scope.OnClickDownload = function () {
				
				$rootScope.Loading();
			
				formHelpersService.downloadFiles($scope.initialFiles).then(function (response) {
					$rootScope.Loading();
					if (response) {
					
						var blob = new Blob([response.data]);
						var link = document.createElement('a');
						link.href = window.URL.createObjectURL(blob);
						
						var fileName = formHelpersService.getFileName(response.headers('content-disposition'));
						link.download = fileName;
						link.click();
					}
					else {
						//handle business errors
					//	errorNotificationService.showErrorNotification(response.responseCode);
					}

				}, function (error) {

					//normal error
					//errorNotificationService.showErrorNotification(error);
				});
			
			}
			$scope.deleteFile = function (fileName) {
				var _fileIndexInModel = _findIndex($scope.model[$scope.options.key], (_fileName) => {
					return _fileName == fileName;
				})

				if (_fileIndexInModel != -1) {
                    $scope.model[$scope.options.key].splice(_fileIndexInModel, 1);

                    if (_isEmpty($scope.model[$scope.options.key])) {
                        $scope.addedToAllowFormValidation = null;
                    } else {
                        $scope.addedToAllowFormValidation = $scope.model[$scope.options.key]
                    }


					_remove($scope.initialModel, (_fileName) => {
						return _fileName == fileName;
					})

					var _fileIndexInFiles = _findIndex($scope.files, (file) => {
						return file.ngfName == fileName;
					})

					if (_fileIndexInFiles != -1) {
						formHelpersService.removeFile($scope.formState.filesStorageKey, $scope.files[_fileIndexInModel].ngfName);
						$scope.files.splice(_fileIndexInModel, 1);
					}
                  

                    $scope.onFileSelectionDialogOpen();

				}
			}

			$scope.getFileIconClassName = function (fileName) {
				if (fileName && typeof fileName == 'string') {
					var _fileType = _getFileType(fileName);
					if (_fileType) {
						var _fileTypeTolower = _fileType.toLowerCase();
						if (_fileTypeTolower == "png" || _fileTypeTolower == "jpeg" || _fileTypeTolower == "jpg") {
							return "fa-image text-info"
						} else if (_fileTypeTolower == "pdf") {
							return "fa-file-pdf text-danger";
						} else if (_fileTypeTolower == "mp4" || _fileTypeTolower == "avi") {
							return "fa-file-video text-info"
						} else if (_fileTypeTolower == "doc" || _fileTypeTolower == "docx") {
							return "fa-file-word text-primary"
						} else if (_fileTypeTolower == "xls" || _fileTypeTolower == "xlsx") {
							return "fa-file-excel text-success"
						} else if (_fileTypeTolower == "zip") {
							return "fa-file-archive text-warning"
						}
						else if (_fileTypeTolower == "avi") {
							return "fa-file-video text-primary"
						}

					} else {
						return null;
					}
				} else {
					return null;
				}
			}

            $scope.onFileSelectionDialogOpen = function () {
				$scope.fc.$setTouched();
				$scope.fc.$setDirty();
				$scope.fc.$validate();
			}

			$scope.GetInitialFilesQueryString = function (filesList, publicPath) {

				var files = [];

				for (var i = 0; i < filesList.length; i++) {
					files.push(publicPath + filesList[i]);
				}

				return files.join("$$_$$");

			}

			$scope.initialFiles = $scope.GetInitialFilesQueryString($scope.initialModel, $scope.formState.filesPublicReadingPath);

		},
		wrapper: ['label', 'hasError'],
	})

	//custom controll needs refactoring as html lables are not dynamic
	//singleImagePicker
	formlyConfigProvider.setType({
		name: 'singleImagePicker',
		template: require('./Views/singleImagePicker.html'),
        controller: function ($scope, Upload, formHelpersService, $translate) {
            var _acceptExtensions = ($scope.options.templateOptions && $scope.options.templateOptions.accept ) || ["png", "jpeg", "jpg"];
         
			$scope.selectedFile = null;
			$scope.selectedFileLoaded = false;

            $scope.Msg = "";
            if ($scope.options.validation && $scope.options.validation.messages && $scope.options.validation.messages.accept) {
                $scope.Msg = $scope.options.validation.messages.accept + "  " + $scope.options.validation.messages.maxSize;
            }
            else {
                if ($scope.to.required) {  // $scope.Msg = "re";
                    $translate('COMMON.PIC_REQUIRED_VALID_FORMAT').then((value) => {
                        $scope.Msg = value;
                    }).catch((error) => {
                        $scope.Msg = error;
                    })
                }
               
            }

			if (!$scope.formState || !$scope.formState.filesPublicReadingPath || !$scope.formState.filesStorageKey) {
				throw "formly singleImagePicker requires from state to be initialized with filesPublicReadingPath and filesStorageKey"
			}

            if ($scope.model[$scope.options.key]) {
				//TODO use $scope.formState.filesPublicReadingPath to access file
                $scope.imagePath = $scope.formState.filesPublicReadingPath + $scope.model[$scope.options.key];
                $scope.displayDownloadButton = true;
                Upload.urlToBlob($scope.imagePath).then(function (blob) {
					$scope.selectedFile = blob;
					$scope.selectedFileLoaded = true;
				}).catch((error) => {
					if (error && error.status == 404) {
						$scope.selectedFile = null;
						$scope.selectedFileLoaded = true;
						$scope.model[$scope.options.key] = null
					}
				})
			} else {
				$scope.selectedFileLoaded = true;
			}



            $scope.onChange = function ($files, $file, $newFiles, $duplicateFiles, $invalidFiles, $event) {
                $scope.displayDownloadButton = false;
				if ($file && $scope.selectedFileLoaded) {
					var _fileType = $file.name.split('.').pop();
					var _isValidFileExtension = _includes(_acceptExtensions, _toLower(_fileType));
					if (_isValidFileExtension) {
						var _fileName = $file.name + "_" + _random(1, 99999, false) + "_" + _random(1, 99999, false) + '.' + _fileType;
						var _file = Upload.rename($file, _fileName);
						$scope.model[$scope.options.key] = _file.ngfName;
						//case edit
						if ($scope.selectedFile) {
							formHelpersService.removeFile($scope.formState.filesStorageKey, $scope.selectedFile.ngfName);
						}
						formHelpersService.addFile($scope.formState.filesStorageKey, _file);
						$scope.selectedFile = _file;
					}
				}
			}
            
			$scope.delete = function (file) {
				if ($scope.selectedFileLoaded) {
					$scope.model[$scope.options.key] = undefined;
					$scope.selectedFileLoaded = true;
					$scope.selectedFile = null;
					formHelpersService.removeFile($scope.formState.filesStorageKey, file.ngfName);
				}
			}

			$scope.onFileSelectionDialogOpen = function () {
				$scope.fc.$setTouched();
				$scope.fc.$setDirty();
				$scope.fc.$validate();
			}

		},
	})


	//toggleButton 
	formlyConfigProvider.setType({
		name: 'toggleButton',
		template: require('./Views/toggleButton.html'),
		link: function (scope, element, attrs) {
			element.addClass('reset-flex-fill align-self-end col-auto')
		}
	})

}])

formlyViewer.run(function (formlyConfig, $parse, formlyValidationMessages, $window, $translate, $cookies) {
	formlyConfig.extras.fieldTransform = formlyConfig.extras.fieldTransform || [];
    formlyConfig.extras.fieldTransform.push(removeOnHideTransformer);

	var currentLang = 'en';

	if ($cookies.get('currentLanguage')) {
		currentLang = $cookies.get('currentLanguage');
	} else {
		currentLang = $translate.use();
	}

	if (currentLang == 'en') {
		formlyValidationMessages.addStringMessage('required', 'This field is required');
		formlyValidationMessages.addStringMessage('url', 'Invalid URL');
		formlyValidationMessages.addStringMessage('email', 'Invalid Email Format');
		formlyValidationMessages.addStringMessage('number', 'The field allows Numbers only');
	}
	else {
		formlyValidationMessages.addStringMessage('required', 'هذا الحقل مطلوب');
		formlyValidationMessages.addStringMessage('url', 'عنوان شبكي غير صالح');
		formlyValidationMessages.addStringMessage('email', 'البريد الإلكتروني غير صحيح');
		formlyValidationMessages.addStringMessage('number', 'هذا الحقل لا يقبل إلا أرقام');
	}




    function removeOnHideTransformer(fields) {
        
		return fields.map(function (field) {
			field.data = field.data || {};
			if (field.key && !field.noFormControl && field.hideExpression && field.templateOptions && field.templateOptions.removeOnHidden) {
				addFieldRemoveOnHideWatcher(field);
			} else if (field.fieldGroup) {
				field.fieldGroup = removeOnHideTransformer(field.fieldGroup);
			}
			return field;
		});
	}

	function addFieldRemoveOnHideWatcher(field) {
		var watcher = getWatcher();
		if (field.watcher) {
			if (!angular.isArray(field.watcher)) {
				field.watcher = [field.watcher];
			}
			field.watcher.push(watcher);
		} else {
			field.watcher = watcher;
		}
	}

    function getWatcher() {

		return {
			expression: function (field) {
				return field.hide;
			},
			listener: function (field, newHide, oldHide, scope) {
				var model = field.model || scope.model; // default to the field's model
				if (field.hide) {
					var getter = $parse(field.key);
					getter.assign(model, undefined);
				}
			}
		};
	}
});