//Import controllers
import ManageSectionsController from './Controllers/app.ManageSections-controller';
//Import services
import ManageSectionsServices from './Services/ManageSections.data.service';
import UpdateSectionController from './Controllers/app.UpdateSection-Controller';

////Import config
import './Localization';

export default function (app) {
    ManageSectionsController(app);
    ManageSectionsServices(app);
    UpdateSectionController(app);

}