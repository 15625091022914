import { isEqual } from 'lodash';
import 'owl.carousel';

export default function (app) {

    var owlProperties = [
        //OPTIONS
        'checkVisible',
        'rtl',
        'items',
        'margin',
        'loop',
        'center',
        'mouseDrag',
        'touchDrag',
        'pullDrag',
        'freeDrag',
        'stagePadding',
        'merge',
        'mergeFit',
        'autoWidth',
        'autoHeight', //see https://github.com/emalikterzi/angular-owl-carousel-2/issues/4
        'startPosition',
        'URLhashListener',
        'nav',
        'rewind',
        'navText',
        'navElement',
        'slideBy',
        'dots',
        'dotsEach',
        'dotData',
        'lazyLoad',
        'lazyContent',
        'autoplay',
        'autoplayTimeout',
        'autoplayHoverPause',
        'smartSpeed',
        'fluidSpeed',
        'autoplaySpeed',
        'navSpeed',
        'dotsSpeed',
        'dragEndSpeed',
        'callbacks',
        'responsive',
        'responsiveRefreshRate',
        'responsiveBaseElement',
        'video',
        'videoHeight',
        'videoWidth',
        'animateIn',
        'animateInClass',
        'animateOutClass',
        'animateOut',

        'fallbackEasing',
        'info',
        'nestedItemSelector',
        'itemElement',
        'stageElement',
        'navContainer',
        'dotsContainer',

        //CLASSES
        'refreshClass',
        'loadingClass',
        'loadedClass',
        'rtlClass',
        'dragClass',
        'grabClass',
        'stageClass',
        'stageOuterClass',
        'navContainerClass',
        'navClass',
        'controlsClass',
        'dotClass',
        'dotsClass',
        'autoHeightClass',
        'responsiveClass',

        //CALLBACKS
        'onInitialize',
        'onInitialized',
        'onResize',
        'onResized',
        'onRefresh',
        'onRefreshed',
        'onDrag',
        'onDragged',
        'onTranslate',
        'onTranslated',
        'onChange',
        'onChanged',
        'onLoadLazy',
        'onLoadedLazy',
        'onStopVideo',
        'onPlayVideo'
    ];

    app.directive('ngOwlCarousel', ['$timeout', '$log', function ($timeout, $log) {
        return {
            restrict: 'E',
            link: link,
            scope: {
                owlProperties: '=',
                owlReady: '&',
                owlItems: '=*'
            },
            transclude: true,
            template: '<div class="owl-carousel" data-ng-transclude></div>'
        };
        function link(scope, $element, $attr, owlCtrl) {
            var owlCarouselClassName = '.owl-carousel',
                owlCarousel = null,
                currentIndex = null;

            scope.owlApi = {};

            var owlCarouselClassName = '.owl-carousel',
                owlCarousel = null;

            scope.getApi = function () {
                return {
                    trigger: function () {
                        scope.owlApi.trigger.apply(scope.owlApi, arguments);
                    }
                }
            }

            scope.init = function () {
                scope.owlApi = arguments[0];
            }


            scope.$watch('owlItems', function () {
                if (owlCarousel) {
                    var owl = $element.find(owlCarouselClassName).owlCarousel(options);
                    owl.addClass("owl-loading");
                }

                destroyOwl(); //always attempt destruction
                var prevIndex = currentIndex;
               
                var options = buildProperties(scope.owlProperties)
                initOwl(options);//if array, try to instantiate

                $timeout(function () {
                    if (prevIndex) {
                        owlCarousel.trigger('to.owl.carousel', [prevIndex, 0]) 
                    }
                })

            });

            scope.$watch('owlProperties', function (newOwlProperties, oldOwlProperties) {
                if (!isEqual(newOwlProperties, oldOwlProperties)) {
                    if (owlCarousel) {
                        var owl = $element.find(owlCarouselClassName).owlCarousel(options);
                        owl.addClass("owl-loading");
                    }

                    destroyOwl(); //always attempt destruction
                    var prevIndex = currentIndex;

                    var options = buildProperties(scope.owlProperties)
                    initOwl(options);//if array, try to instantiate

                    $timeout(function () {
                        if (prevIndex) {
                            owlCarousel.trigger('to.owl.carousel', [prevIndex, 0])
                        }
                    })
                }
            }, true);

            function buildProperties(props) {
                var build = {};
                if (props) {
                    for (var key in props) {
                        if (owlProperties.indexOf(key) >= 0) {
                            build[key] = props[key];
                        } else {
                            $log.warn('unknown property : ' + key);
                        }
                    }
                }
                return build;
            }

            function initOwl(options) {
                $timeout(function () {
                    owlCarousel = $element.find(owlCarouselClassName).owlCarousel(options);
                    scope.init(owlCarousel);

                    owlCarousel.on('changed.owl.carousel', function (e) { currentIndex = e.item.index })

                    if (angular.isDefined(scope.owlReady)) {
                        scope.owlReady({ $api: scope.getApi() });
                    }
                });
            }

            function destroyOwl() {
                $timeout(function () {
                    try {
                        owlCarousel.owlCarousel('destroy');
                        $element.find('.owl-stage').remove();//for some reason its left behind. remove it
                        $element.find('.owl-loading').remove();
                        $element.find('.owl-item').remove();
                    }
                    catch(e) {}

                })
            }
        }
    }]);

}