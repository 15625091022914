export default {
    ADD_PILLAR: 'إضافة محور',
    PILLAR_ENGLISH_NAME: 'اسم المحور (بالإنجليزية)',
    PILLAR_ARABIC_NAME: 'اسم المحور (العربية)',

    PILLAR_TYPE: 'نوع المحور',

    PLEASE_SELECT: 'الرجاء الاختيار',
    REQUIRED_PILLAR_ARABIC_NAME: 'اسم المحور بالعربية مطلوب',
    REQUIRED_PILLAR_ENGLISH_NAME: 'اسم المحور بالانجليزية مطلوب',
    REQUIRED_PILLAR_TYPE: 'نوع المحور بالانجليزية مطلوب',
    PILLARS: 'المحاور',
    UPDATE: 'تعديل',
    UPDATE_PILLAR: 'تعديل المحور',

    PILLAR_EXIST: 'لا يمكن تكرار اسم المحور',
    PILLAR_ADDED: 'تم إضافة محور بنجاح',
    PILLAR_UPDATED: 'تم تعديل المحور بنجاح',
    PILLAR_DELETED: 'تم حذف المحور بنجاح',
    PILLAR_DELETE_QUES: 'هل تريد حذف المحور؟',
    DELETE_HEADER: 'حذف',


}