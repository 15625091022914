export default {
    ADD_COUNTRY: 'Add new Country',
    COUNTRY_ENGLISH_NAME: 'Country name (English)',
    COUNTRY_ARABIC_NAME: 'Country name (Arabic)',

    COUNTRY_TYPE: 'Country type',
    PLEASE_SELECT: 'Please select',
    REQUIRED_COUNTRY_ARABIC_NAME: 'Country name (Arabic) is required.',
    REQUIRED_COUNTRY_ENGLISH_NAME: 'Country name (English) is required.',
    REQUIRED_COUNTRY_TYPE: 'Country Type is required.',





    EMPTY_LIST: 'List is empty',
    UPDATE: 'Update',
    UPDATE_COUNTRY: 'Update Country',

    COUNTRY_EXIST: 'Country name is previously used',
    COUNTRY_ADDED: 'Country Added Successfully',
    COUNTRY_UPDATED: 'Country has been Updated Successfully',
    COUNTRY_DELETED: 'Country has been Deleted Successfully',
    COUNTRY_DELETE_QUES: 'Are you sure you want to delete the Country?',
    DELETE_HEADER: 'Delete',
    COUNTRY_RESTORED: 'Country had been successfully restored',
}

