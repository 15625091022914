export default function (app) {
    app.directive('horizontalLayout', function () {
        return {
            restrict: 'E',
            transclude: true,
            template: '<div  ng-transclude></div>',
            link: function (scope, element, attr, ctrls) {
                var fieldGroupNgForm = findFirstFieldGroupNgForm(element);  //get the root ng-form (fieldGroup)

                //mutation doesn't work on IE browsers < 11
                var observer = new MutationObserver(function (mutations) {
                    var fields = getFormlyFieldsIn(fieldGroupNgForm);
                    $(fieldGroupNgForm).addClass("d-flex flex-wrap");
                    angular.forEach(fields, function (field, index) {
                        $(field).addClass('flex-fill')
                    });
                });

                //observe when the html content of the directive changes
                if (fieldGroupNgForm instanceof Element)
                    observer.observe(fieldGroupNgForm, { childList: true });
            }
        }

        function findFirstFieldGroupNgForm(nodeOrElement) {
            return $(nodeOrElement).find('[is-field-group=""]')[0];
        }

        //formly fields could be input fields or fieldGroups
        function getFormlyFieldsIn(nodeOrElement) {
            return $(nodeOrElement).children('[formly-field=""]');
        }

    });
}
