export default function (app) {
    app.constant('statusConstant', {
        New : 1,
        InProgress : 2,
        Saved : 3,
        Submitted : 4,
        Approved : 5,
        SentBack : 6,
        Queued : 7,
        Deleted : 8,
        Reassigned : 9,
        Suspended : 10,
        Picked: 11,
        NotSumbitted: 12,
        RequestForEditing: 13,
        Reopened: 14
    });
}