
export default function (app) {
    app.factory('requestDataService', RequestDataService);

    RequestDataService.$inject = ['apiBase', 'ApiPath'];
    function RequestDataService(apiBase, ApiPath) {
        var entity = "MBRGIAchivement";
        var _annualCycleController = ApiPath + "AnnualCycle/";
        var controllerUrl = ApiPath + entity + "/";

        function _getAllRequests(cycleId) {
            return apiBase.Get(controllerUrl + "GetSectionRequests", { cycleId: cycleId }, null);
        }
        function _getCyclesList () {
            return apiBase.Get(_annualCycleController + "GetAllCycles", null);
        }
      
        function _submitSectionRequestAction(requestID, comment, actionId, SkipValidation = false) {
            return apiBase.Post(controllerUrl + "SubmitSectionRequestAction", { requestID: requestID, comment: comment, actionId: actionId, SkipValidation: SkipValidation}, null);
        }
        return {
            GetAllRequests: _getAllRequests,
            getCyclesList: _getCyclesList,
            SubmitSectionRequestAction: _submitSectionRequestAction,
        }
    }
}