//Import controllers
import cycleController from './Controllers/app.cycle.controller';
import updateController from './Controllers/app.update-cycle.controller';
import addController from './Controllers/app.add-cycle-controller';
//Import services
import dataService from './Services/cycle.data.service';
import ngValidateCondition from '../../Common/Directives/ngValidateCondition';
import ngArabicDate from '../../Common/Filters/app.arabicDate.filter';
//Import config
import './Localization';

export default function (app) {
    ngValidateCondition(app);
    cycleController(app);
    updateController(app);
    addController(app)
    dataService(app);
    ngArabicDate(app);
}