/* http://keith-wood.name/calendars.html
   Arabic localisation for calendars datepicker for jQuery.
   Mahmoud Khaled -- mahmoud.khaled@badrit.com
   NOTE: monthNames are the new months names */
(function($) {
	'use strict';
	$.calendarsPicker.regionalOptions['ar-EG'] = {
		renderer: $.calendarsPicker.defaultRenderer,
		prevText: '&#x3c;السابق',
		prevStatus: 'عرض الشهر السابق',
		prevJumpText: '&#x3c;&#x3c;',
		prevJumpStatus: '',
		nextText: 'التالي&#x3e;',
		nextStatus: 'عرض الشهر القادم',
		nextJumpText: '&#x3e;&#x3e;',
		nextJumpStatus: '',
		currentText: 'اليوم',
		currentStatus: 'عرض الشهر الحالي',
		todayText: 'اليوم',
		todayStatus: 'عرض الشهر الحالي',
		clearText: 'مسح',
		clearStatus: 'امسح التاريخ الحالي',
		closeText: 'إغلاق',
		closeStatus: 'إغلاق بدون حفظ',
		yearStatus: 'عرض سنة آخرى',
		monthStatus: 'عرض شهر آخر',
		weekText: 'أسبوع',
		weekStatus: 'أسبوع السنة',
		dayStatus: 'اختر D, M d',
		defaultStatus: 'اختر يوم',
		isRTL: true
	};
	$.calendarsPicker.setDefaults($.calendarsPicker.regionalOptions['ar-EG']);
})(jQuery);
