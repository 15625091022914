import { get as _get } from 'lodash';

export default function (app) {
    app.directive("confirmNavigation", ['$timeout', function ($timeout) {
        var _formCtrl = null;
        return {
            require: 'form',
            controller: ['$scope', '$uibModal', '$state', '$transitions', '$translate', 'changeLanguageHook', 'routeConstants', function ($scope, $uibModal, $state, $transitions, $translate, changeLanguageHook, routeConstants) {
                var _navigationConfirmationModal = null;
                var _onTransitionCallback = function (transition) {
                    var _nextState = _get(transition, '_targetState._identifier', '');

                    if (_formCtrl && _formCtrl.$dirty && !_formCtrl.$submitted && _nextState != routeConstants.LoginState.State) {
                        _navigationConfirmationModal = $uibModal.open({
                            backdrop: true,
                            keyboard: true,
                            backdropClick: true,
                            template: require('../Views/confirmation-popup.html'),
                            size: 'md',
                            controllerAs: 'vm',
                            controller: ['$uibModalInstance', function ($uibModalInstance) {
                                var vm = this;
                                vm.header = $translate.instant("COMMON.NAVIGATION_CONFIRMATION_HEADER");
								vm.message = $translate.instant("COMMON.NAVIGATION_CONFIRMATION_MESSAGE");

                                vm.ok = function () {
                                    $uibModalInstance.close();
                                }

                                vm.cancel = function () {
                                    $uibModalInstance.dismiss();
                                }
                            }]
                        });
                        return _navigationConfirmationModal.result.then(() => {
                            return Promise.resolve();
                        }).catch(() => {
                            //revert url changes if user click back btn
                            var options = transition.options();
                            if (options.source === 'url') {
                                var $state = transition.router.stateService;
                                var $urlRouter = transition.router.urlRouter;
                                var urlOptions = { replace: false };
                                $urlRouter.push($state.$current.navigable.url, $state.params, urlOptions);
                                $urlRouter.update(true);
                            }
                            return Promise.reject();
                        });
                    }
                }

                //TODO fix a bug if user clicks enter without changing URL
                var _unregisterOnBeforeTransition = $transitions.onBefore({ from: $state.current.name },
                    _onTransitionCallback);

                var _unregisterOnBeforeLanguageChange = changeLanguageHook.onBeforeLanguageChange(_onTransitionCallback)

                $scope.$on('$destroy', function () {
                    if (_unregisterOnBeforeTransition) {
                        _unregisterOnBeforeTransition();
                    }

                    if (_navigationConfirmationModal && _navigationConfirmationModal.close) {
                        _navigationConfirmationModal.close();
                    }

                    if (_unregisterOnBeforeLanguageChange) {
                        _unregisterOnBeforeLanguageChange();
                    }

                    _formCtrl = null;

                })

            }],
            link: function (scope, element, attrs, ctrl) {
				_formCtrl = ctrl;

				var unRegisterListener = scope.$on("DATEPICKER_INITIALIZED", () => {
					_formCtrl.$setPristine();
					_formCtrl.$setUntouched();
				})

				scope.$on("$destroy", function () {
					if (unRegisterListener) {
						unRegisterListener();
					}
				})

            }
        }
    }]);
}