export default function (app) {
    app.factory('entityDataService', EntityDataService);

    EntityDataService.$inject = ['apiBase', 'ApiPath'];
    function EntityDataService(apiBase, ApiPath) {
        var entity = "Entity";
        var controllerUrl = ApiPath + entity + "/";

        function GetAllEntitiesCardsInfo() {
            return apiBase.Get(controllerUrl + "GetAllEntitiesCardsInfo", {}, null);
        }

        function ChangeActiveStatus(entityID, activeState) {
            return apiBase.Post(controllerUrl + "ChangeActiveStatus", { entityID: entityID, activeState: activeState }, null);
        }
        function GetCurrentUserEntities() {
            return apiBase.Get(controllerUrl + "GetCurrentUserEntities", null, null);
        }

        function UpdateEntity(data) {
            return apiBase.Post(controllerUrl + "UpdateEntity", null, data);
		}

		function GetEntityShortFormDescription() {
			return apiBase.Get(controllerUrl + "GetEntityShortFormDescription", null, null);
		}

		function CreateEntity(model) {
			return apiBase.Post(controllerUrl + "CreateEntity", null, model);
		}
        function IsEntityAdminUserExist(email, roleID, entityID) {
            return apiBase.Get(controllerUrl + "IsEntityAdminUserExist", { email: email, roleID: roleID, entityID: entityID }, null);
        }
        function GetProfileForm(entityID) {
            return apiBase.Get(controllerUrl + "GetProfileForm", { entityID: entityID }, null);
        }
        function UpdateProfile(entityID, data) {
            return apiBase.Post(controllerUrl + "UpdateProfile", { entityID: entityID }, data);
		}
		function CheckEntityUniqueName(data) {
			return apiBase.Post(controllerUrl + "CheckEntityUniqueName", {}, data);
		}
        return {
            GetAllEntitiesCardsInfo: GetAllEntitiesCardsInfo,
            ChangeActiveStatus: ChangeActiveStatus,
            GetCurrentUserEntities: GetCurrentUserEntities,
			UpdateEntity: UpdateEntity,
			GetEntityShortFormDescription: GetEntityShortFormDescription,
            CreateEntity: CreateEntity,
            IsEntityAdminUserExist: IsEntityAdminUserExist,
            GetProfileForm: GetProfileForm,
			UpdateProfile: UpdateProfile,
			CheckEntityUniqueName: CheckEntityUniqueName
        }
    }
}