export default function (app) {
	app.controller('addLookupEntryController', AddLookupEntryController);

	AddLookupEntryController.$inject = ["$rootScope", "lookupsDataService", "$uibModalInstance", "Params", "Notification","$translate"];
	function AddLookupEntryController($rootScope, lookupsDataService, $uibModalInstance, Params, Notification, $translate) {
        var vm = this;
		$translate(["LOOKUPS.LOOKUP_ENTRY_ADDED"]).then(function (translations) {
            vm.labels = {
				LookupEntryAdded: translations['LOOKUPS.LOOKUP_ENTRY_ADDED'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.responseCode = $rootScope.enums.ResponseCode;
        vm.Loading();
        vm.load = load;
		vm.cancel = cancel;
		vm.Params = angular.copy(Params);
		vm.lookupEntry = { LookupID : vm.Params.lookupID };
		vm.addLookupEntry = addLookupEntry;

        vm.load();

        function load() {
            vm.Loading();
        }
        function cancel() {
            $uibModalInstance.close({ Close: true, Ok: false});
        }

		function addLookupEntry() {
            vm.Loading();
			lookupsDataService.AddLookupEntry(vm.lookupEntry).then(addLookupEntrySuccess, $rootScope.methodPublicError);
        }

		function addLookupEntrySuccess(res) {
            if (res.responseCode == vm.responseCode.Success) {
				vm.Loading();
				Notification.success({ message: vm.labels.LookupEntryAdded });
				$uibModalInstance.close({ Close: true, Ok: true });
			} else {
                $rootScope.methodPublicError();
                $uibModalInstance.close({ Close: true, Ok: false });
            }
        }
    }
}