import { map as _map } from 'lodash';

export default function (app) {
    app.factory("changeLanguageHook", [function () {
        var _hooks = [];

        var _onBeforeLanguageChange = function (callback) {
            _hooks.push(callback);
            return () => {
                var _index = _hooks.indexOf(callback);
                if (_index >= 0) {
                    _hooks.splice(_index, 1);
                }
            }
        }

        var _canChangeLanguage = function () {
            var _promises = _map(_hooks, (hook) => {
                return hook() || true;
            })
            return Promise.all(_promises)
        }

        return {
            onBeforeLanguageChange: _onBeforeLanguageChange,
            canChangeLanguage: _canChangeLanguage
        }

    }])
}