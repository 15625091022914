export default function (app) {
    //TODO: Need to implement function to return enums in localization
    app.factory("enumsService", EnumsService);

    //EnumsService.$inject = ["localeService"];
    function EnumsService() {
        return {
            userRole: {
                'MBRGI Admin': 1,
                'MBRGI Viewer': 2,
                'Entity Admin': 3,
                'Entity Reviewer': 4,
                'Entity Editor': 5,
                'Entity Viewer': 6
            },
            Gender: {
                Male: 1,
                Female: 2
            },
            UploadType:
            {
                ProfilePic: 1
            },
            ResponseCode: {
                Error: 0,
                Success: 1,
                LinkExpired: 2,
                NotExist: 3,
                NoError: 4,
                WrongPassword: 5,
                WrongUserName: 6,
                MBRGIUser:7,
                UserExistWithSameRole : 8,
                UserProfileExist: 9,
                EntityUser : 10,
                Activated : 11,
                DeActivated : 12,
                CantDeActivateExistTasks: 13,
                UserEntityRoleDeactivated: 14,
				InActiveEntity: 15,
                Duplicated: 16,
                NoActiveCycle :17,
                CantChangeRoleExistTasks: 18,
                ActiveCycleExist: 19,
                IsAlreadyPicked: 20,
                NotShared : 24,
                isPicked :25,
                cannotSumbitAnnualAchievement : 26,
                notActivitiesSubmited: 27,
                EntityDeactivated :28,
                RequestHaveAction: 29,
                notActivitiesSubmitedRequest: 30,
                SectionHaveRequest: 31,
                NotificationExpire: 32,
                NotApproved: 33,
                LookupSystemKeyExist: 320
            },
            Autorization:
            {
                "Home": [1, 2, 3],
                "MBRGI": [1, 2],
                "Entity": [3, 4, 5, null],
                "MBRGI.UserProfile": [1, 2],
                "Entity.UserProfile": [3, 4, 5],
                "MBRGI.Dashboard": [1],
                "Entity.Dashboard": [3, 4, 5],
                "MBRGI.ChangePassword": [1, 2],
                "Entity.ChangePassword": [3, 4, 5],
                "Home.Users": [1, 3],
                "MBRGI.Entities": [1, 2, 3],
                "MBRGI.Pillars": [1],
				"MBRGI.Countries": [1],
				"MBRGI.RestoreCountries": [1],
				"MBRGI.Lookups": [1],
                "MBRGI.LookupEntries": [1],
                "MBRGI.PredefinedLookupState":[1],
				"MBRGI.LookupDeletedEntries": [1],
                "Entity.SwitchEntities": [3, 4, 5, null],
                "MBRGI.Users": [1, 2],
                "MBRGI.EntityUsers": [1, 2],
                "Entity.Users": [3,4, 5, 6],
                "MBRGI.BeneficiaryUnit": [1],
                "MBRGI.RestoreBeneficiaryUnit": [1],
                "MBRGI.EntityProfile": [1],
                "MBRGI.Cycles": [1],
                "MBRGI.Reports": [1, 2],
                "Entity.AnnualAchievement": [3],
                "Entity.SharedInitiatives": [3],
                "Entity.SharedInitiatives.SharedInitiative": [3],
                "Entity.Reports": [3],
                "Entity.AnnualAchievement.Submission": [3],
                "Entity.AnnualAchievement.Submissions": [3],
                "Entity.AnnualAchievement.DeletedSubmissions": [3],
                "Entity.SectionsAssignment.DeletedSubmissions":[4,5],
                "Entity.Tasks": [3, 4, 5],
                "Entity.Tasks.Task": [3, 4, 5],
                "MBRGI.MBRGIAnnualAchievements": [1, 2],
                "MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement": [1, 2],
                "MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.Submission": [1, 2],
                "MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.Submissions": [1, 2],
                "MBRGI.MBRGIAnnualAchievements.MBRGIAnnualAchievement.DeletedSubmissions": [1],
                "MBRGI.Request": [1],
                "Entity.SectionsAssignment": [4, 5],
                "Entity.SectionsAssignment.Submission": [4, 5],
                "Entity.SectionsAssignment.Submissions":[4, 5],               
                "MBRGI.Request": [1],
                "Entity.SectionsAssignment": [4, 5],
                "Entity.SectionsAssignment.Submission": [4, 5],
                "Entity.SectionsAssignment.Submissions":[4, 5], 
                "MBRGI.ComparsionReports": [1 ,2],
                "MBRGI.SectionReports": [1,2],
                "Entity.SectionReports": [3],
                "Entity.ComparsionReports" : [3],
                "Entity.CountiresReports": [3], 
                "MBRGI.CountiresReports": [1, 2],
                "MBRGI.AwardsReports": [1, 2],
                "MBRGI.FinancialReports": [1, 2],
                "MBRGI.BeneficiariesReports": [1, 2],
                "MBRGI.ScholarshipsReports": [1, 2],
                "Entity.AwardsReports": [3],
                "Entity.FinancialReports": [3],
                "Entity.BeneficiariesReports": [3],
                "Entity.ScholarshipsReports": [3],
                "MBRGI.Notification": [1],
                "Entity.Notification": [3, 4, 5, 6],
                "MBRGI.FormBuilder": [1],
                "MBRGI.EmailTemplates": [1],
                "MBRGI.AddEmailTemplate": [1],
                "MBRGI.EditEmailTemplate": [1],
                "MBRGI.RestoreEmailTemplates": [1],
                "MBRGI.PublicSettings": [1, 2],
                "Entity.PublicSettings": [3, 4, 5],
                "MBRGI.SectionList":[1],
                "MBRGI.updateSection":[1],
            },
            NavElements:
            {
                "EntityProfile":[3],
                "Dashboard": [1, 3,4,5,6],
                "UserManagement": [1, 3],
                "EntityManagement": [1],
                "CountryManagement": [1],
                "AnnualAchievements": [3],
                "SharedInitiatives": [3],
                "Tasks": [3,4,5],
                "Delegations": [4, 5],
                "MBRGIAnnualAchievements":[1,2],
                "Settings": [1, 2, 3, 4, 5],
                "Settings-Settings": [1, 2, 3, 4, 5],
                "Reports": [1,3,2],
                "Settings-Pillars": [1],
                "Settings-Cycles": [1],
                "Settings-Beneficiaries": [1],
				"Settings-Countries": [1],
				"Settings-Lookups": [1],
                "Settings-EmailsTemplates": [1, 3],
                "MBRGI-Request": [1],
                "SectionsAssignment":[4, 5],
                "MBRGI.Notification": [1, 3, 4, 5, 6],
                "MBRGI.FormBuilder": [1],
                "Settings-EmailTemplates": [1],
                "MBRGI.SectionList": [1],
            },
            TaskTypes:
            {
                Edit : 1,
                Review : 2
            },
            ReportLevels: {
                MBRGI: 1,
                Entities: 2,
                Pillars:3
            },
            ReportTypes: {
                Sections: 1,
                Scholarships: 2,
                Comparisons: 3,
                Countries: 4,
                Beneficiaries: 5,
                Awards: 6,
                Financial: 7
            },
            NOTIFICATION_TYPES : {
                OnCycleCreation: 1,
                OnEntityCreation: 2,
                OnEntityProfileUpdate: 3,
                OnUserCreation: 4,
                OnForgetPassword: 5,
                OnUserRoleAdded: 6,
                OnCycleStarted: 7,
                OnCycleDatesChanged: 8,
                OnSectionAssigned: 9,
                OnTaskAssignment: 10,
                OnTaskDone: 11,
                OnTaskRejection: 12,
                OnFormSubmitted: 13,
                OnFormApproved: 14,
                OnFormRejected: 15,
                OnCycleReminders: 16,
                OnCycleCutoff: 17,
                OnCycleDataEntryEnded: 18,
                OnMBRGIUserCreation: 19,
                OnTaskReopened: 20,
                OnEntityAchievementSubmitted: 21,
                OnEntitySharedInitiatives: 22,
                OnEntityRemoveSharedInitiatives: 23,
                OnEntityAddSharedInitiativeComment: 24,
                OnMBRGIAchievementApproval: 25,
                OnMBRGIAchievementSentBack: 26,
                OnEntityApprovedSharedInitiatives: 27,
                OnRequestEditing: 28,
                OnRequestEditingApproved: 29,
                OnRequestEditingRejected: 30,
                OnEntityAnnualAchievementComment: 31,
                OnMBRGIAnnualAchievementComment: 32
            }

        };
    }
}