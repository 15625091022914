import { find as _find, cloneDeep as _cloneDeep } from "lodash";

export default function (app) {
    app.factory('sectionsService', [function () {
        var _sections = [];

        var _setSections = function (sections) {
            if (sections && sections.length > 0) {
                _sections = _cloneDeep(sections)
            }
        }

        var _unsetSections = function () {
            _sections = [];
        }

        var _getSection = function (sectionID) {
            if (sectionID) {
                return _find(_sections, (section) => {
                    return section.id == sectionID;
                })
            }
        }

        return {
            setSections: _setSections,
            getSection: _getSection,
            unsetSections: _unsetSections
        }

    }])
}