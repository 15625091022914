export default function (app) {
    app.factory('countryDataService', CountryDataService);

    CountryDataService.$inject = ['apiBase', 'ApiPath'];
    function CountryDataService(apiBase, ApiPath) {
        var entity = "Country";
        var controllerUrl = ApiPath + entity + "/";

        function GetAllCountries(  ) {
            return apiBase.Get(controllerUrl + "GetAll" , null);
        }
        function getDeletedCountries() {
            return apiBase.Get(controllerUrl + "GetDeleted", null);
        }
        function restoreDeletedCountries(ID) {
            return apiBase.Post(controllerUrl + "RestoreDeleted", { ID: ID });
        }
        function GetCountryByID(countryID) {
            return apiBase.Get(controllerUrl + "GetCountryByID", { countryID: countryID }, null);
        }

        function AddCountry(data) {
            return apiBase.Post(controllerUrl + "AddCountry", null, data);
        }

        function DeleteCountry(countryID) {
            return apiBase.Get(controllerUrl + "DeleteCountry", { countryID: countryID }, null);
        }

        function UpdateCountry(data) {
            return apiBase.Post(controllerUrl + "UpdateCountry", null, data);
        }
        function ChangeActiveStatus(countryID, activeState) {
            return apiBase.Post(controllerUrl + "ChangeActiveStatus", { countryID: countryID, activeState: activeState }, null);
        }
        function IsCountryExist(data, countryID) {
            return apiBase.Post(controllerUrl + "IsCountryExist", { countryID: countryID }, data);
        }
        function DeleteCountryByID(countryID) {
            return apiBase.Post(controllerUrl + "DeleteCountryByID", { countryID: countryID }, null);
        }
        function updateCountriesOrder(orderedPillarsID) {
            return apiBase.Post(controllerUrl + "UpdateCountriesOrder", null, orderedPillarsID);
        }
        return {
            GetAllCountries: GetAllCountries,
            GetCountryByID: GetCountryByID,
            AddCountry: AddCountry,
            DeleteCountry: DeleteCountry,
            UpdateCountry: UpdateCountry,
            ChangeActiveStatus: ChangeActiveStatus,
            IsCountryExist: IsCountryExist,
            DeleteCountryByID: DeleteCountryByID,
            getDeletedCountries: getDeletedCountries,
            restoreDeletedCountries: restoreDeletedCountries,
            UpdateCountriesOrder:updateCountriesOrder
        }
    }
}