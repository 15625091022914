//Import controllers
import publicSettingsController from './Controllers/app.publicSettings.controller';

//Import services
import dataService from './Services/publicSettings.data.service';
//Import config

export default function (app) {
    publicSettingsController(app);
    dataService(app);
}