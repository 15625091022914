export default function (app) {
    app.factory("exportFileService", ['reportsService', 'errorNotificationService',
        function (reportsService, errorNotificationService) {
            var getFileName = function (s) {
                if (s) {
                    var index = s.indexOf("=");
                    index = index + 1;
                    var filename = s.substring(index, s.length);
                    filename = filename.replace(/"/g, '');
                    return filename;
                }
            };
            var downloadpdf = function (response) {
                if (response) {
                    var blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    var fileName = getFileName(response.headers('content-disposition'));
                    if (fileName) {
                        link.download = fileName;
                        link.className = 'hidden';
                        if (document.body != null) { document.body.appendChild(link); }
                        link.onclick = function () {
                            requestAnimationFrame(function () {
                                URL.revokeObjectURL(url);
                                setTimeout(() => link.remove(), 300);
                            });
                        };

                        link.click();
                    }
                    return true;

                } else {

                    errorNotificationService.showErrorNotification(33);
                }
            };
            var _exportPdf = function (htmlBody, levelId, reportType) {
                return reportsService.exportToPdf(htmlBody, levelId, reportType).then(function (response) {
                    downloadpdf(response);
                }
                    , function (error) {

                        //normal error
                        errorNotificationService.showErrorNotification(error);
                    });
            };     
            var _exportToPdfComparisonReport = function (htmlBody, cycleIDs, levelId, reportType) {
                return reportsService.exportToPdfComparisonReport(htmlBody, cycleIDs, levelId, reportType).then(function (response) {
                    downloadpdf(response);
                }
                    , function (error) {

                        //normal error
                        errorNotificationService.showErrorNotification(error);
                    });
            };         

            var _exportExcel = function (response) {
                if (response) {
                    var blob = new Blob([response.data]);
                    const url = URL.createObjectURL(blob);
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    var fileName = getFileName(response.headers('content-disposition'));
                    link.download = fileName;
                    link.className = 'hidden';
                    if (document.body != null) { document.body.appendChild(link); }
                    link.onclick = function () {
                        requestAnimationFrame(function () {
                            URL.revokeObjectURL(url);
                            setTimeout(() => link.remove(), 300);
                        });
                    };
                    link.click();
                }
                else {
                    //handle business errors
                    errorNotificationService.showErrorNotification(response.responseCode);
                }
            };
                
            return {
                exportPdf: _exportPdf,
                exportExcel: _exportExcel,
                exportToPdfComparisonReport: _exportToPdfComparisonReport
            }
        }]);
}