export default function (app) {
    app.controller('updatePillarController', UpdatePillarController);

    UpdatePillarController.$inject = ["$rootScope", "pillarDataService", "$uibModalInstance", "Params", "Notification","$translate"];
    function UpdatePillarController($rootScope, pillarDataService, $uibModalInstance, Params, Notification, $translate) {
        var vm = this;
        
        $translate(["PILLARS.PILLAR_EXIST", "PILLARS.PILLAR_UPDATED"]).then(function (translations) {
            vm.labels = {
                PILLARSIsAlreadyExist: translations['PILLARS.PILLAR_EXIST'],
                UpdatedPillar: translations['PILLARS.PILLAR_UPDATED'],
            }
        });
        vm.Loading = $rootScope.Loading;
        vm.responseCode = $rootScope.enums.ResponseCode;
        vm.pillar = {};
        vm.load = load;
        vm.cancel = cancel;
        vm.Params = Params;
        vm.updatePillar = updatePillar;


        vm.load();

        function load() {
            //vm.pillar = vm.Params;
            vm.Loading();
            pillarDataService.GetPillarByID(vm.Params.ID).then(getPillarByIDSuccess, $rootScope.methodPublicError);

        }
        function cancel() {
            $uibModalInstance.close
                ({ Close: true, Ok: false });
        }

        function updatePillar(pillar) {
            vm.Loading();
            pillarDataService.IsPillarExist(pillar, pillar.ID).then(isPillarExistSuccess, $rootScope.methodPublicError);
            
        }
        function getPillarByIDSuccess(res) {
            if (res.responseCode == vm.responseCode.Success && res.responseObject != null) {
                vm.pillar = res.responseObject;
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function isPillarExistSuccess(res) {
            if (res.responseCode == vm.responseCode.NotExist) {
                pillarDataService.UpdatePillar(vm.pillar).then(updatePillarSuccess, $rootScope.methodPublicError);
            }
            else if (res.responseCode == vm.responseCode.Success) {
                Notification.warning({ message: vm.labels.PillarIsAlreadyExist });
                vm.Loading();
            }
            else {
                $rootScope.methodPublicError();
            }
        }
        function updatePillarSuccess(res) {
            if (res.responseCode == vm.responseCode.Success) {
                vm.Loading();
                Notification.success({ message: vm.labels.UpdatedPillar });
                $uibModalInstance.close({ Close: true, Ok: true });
            }
            else if (res.responseCode == vm.responseCode.Error) {
                $rootScope.methodPublicError();
            }
        }

    }
}