export default function (formlyBuilder) {
    formlyBuilder.controller('fieldGroupConfigController', ['$scope', function ($scope) {
        $scope.changeWrapperValue = function (wrapper) {
            $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.wrapper.ValueEn[0] = $scope.fieldConfigCtrl.formBuilderField.wrapper[0];
            if ($scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions &&$scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.label && wrapper == "horizontalLayout"  ) {
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.label.ValueAr = "";
                $scope.fieldConfigCtrl.formBuilderField.data.MetaOptions.templateOptions.label.ValueEn = "";

            }}
    }]);
};