export default {
    Email_Templates_Listing:' البريد ألالكتروني',
    CREATE_EMAIL_TEMPLATE_TITLE: 'إضافة بريد إلكتروني جديد',
    EDIT_EMAIL_TEMPLATE: 'تعديل البريد الإلكتروني',
    EMAIL_TEMPLATE_TRASH: 'العناصر المحذوفة',
    EMAIL_TEMPLATE_DELETED: 'تم حذف البريد الإلكتروني بنجاح',
    EMAIL_TEMPLATE_ACTIVATED: 'تم تفعيل البريد الإلكتروني بنجاح',
    EMAIL_TEMPLATE_DEACTIVATED: 'تم إلغاء تفعيل البريد الإلكتروني بنجاح',
    DEACTIVATE_EMAIL_TEMPLATE: 'إلغاء تفعيل البريد الإلكتروني',
    DELETE_EMAIL_TEMPLATE: 'حذف البريد الإلكتروني',
    EMAIL_TEMPLATE_DELETE_QUES: 'هل تريد حذف البريد الإلكتروني؟',
    EMAIL_TEMPLATE_DEACTIVATE_QUES: 'هل تريد إلغاء تفعيل البريد؟',
    EMAIL_TEMPLATE_RESTORED: 'تم استعادة البريد الإلكتروني',
    EMAIL_TEMPLATE_ADDED:'تم إضافة البريد الإلكتروني',
    EMAIL_TEMPLATE_UPDATED: 'تم تعديل البريد الإلكتروني',
    EMAIL_TEMPLATE_NAME_EN: 'اسم البريد الإلكتروني (بالإنجليزية)',
    EMAIL_TEMPLATE_NAME_AR: 'اسم البريد الإلكتروني (بالعربية)',
    EMAIL_TEMPLATE_SUBJECT_ARABIC: 'نموذج الرسالة (بالعربية)',
    EMAIL_TEMPLATE_SUBJECT_ENGLISH: 'نموذج الرسالة (بالإنجليزية)',
    EMAIL_TEMPLATE_PAST_DATES: 'التاريخ السابق غير مسموح به',
    EMAIL_TEMPLATE_PAST_DATE_TIME: 'الوقت السابق غير مسموح به',    
    EMAIL_EXIST: 'لا يمكن تكرار البريد الإلكتروني',
    SUBJECT_EN:'الموضوع (بالإنجليزية)',
    SUBJECT_AR: 'الموضوع (بالعربية)',
    MAIL_CONTENT_EN:'محتوى البريد (بالإنجليزية)',
    MAIL_CONTENT_AR: 'محتوى البريد (بالعربية)',
    TOKEN_LIST: 'قائمة الرموز',
    ENTITIIES: 'المؤسسات',
    SCHEDULED_DATE: 'التاريخ',
    SCHEDULED_TIME: 'الزمن',
    PREVIEW:'عرض',
}