export default {
    ADD_ENTITY: 'إضافة مؤسسة',
    UPDATE_ENTITY: 'تعديل مؤسسة',
    ENTITY: 'مؤسسة',
    SELECT_ENTITY: '',
    ACTIVITY: 'نشاط',  
    NEW: 'جديد',
    INPROGRESS: 'تحت الإجراء',
    APPROVED: 'معتمد',
    ENTITY_ADDED: 'تمت إضافة المؤسسة بنجاح',
    DEACTIVATE_ENTITY: 'إلغاء تفعيل المؤسسة',
    WOULD_DEACTIVATE_ENTITY: 'هل تريد إلغاء تفعيل المؤسسة؟',
    ENTITY_DEACTIVATED: 'تم إلغاء تفعيل المؤسسة بنجاح',
    ENTITY_ACTIVATED: 'تم تفعيل المؤسسة بنجاح',
    CANT_DEACTIVATE: 'لا يمكن إلغاء تفعيل',
    ENTITY_EXIST:'لا يمكن تكرار المؤسسة',
}