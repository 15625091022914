export default function (app) {
    app.factory('publicSettingsDataService', PublicSettingsDataService);

    PublicSettingsDataService.$inject = ['apiBase', 'ApiPath'];
    function PublicSettingsDataService(apiBase, ApiPath) {
        var entity = "User";
        var controllerUrl = ApiPath + entity + "/";
        var sectionControllerUrl = ApiPath + 'Section' + "/";

        function _getUserAllowMail() {
            return apiBase.Get(controllerUrl + "GetUserAllowMail", null, null);
        }

        function _updateUserSetting(allowSendMail) {
            return apiBase.Post(controllerUrl + "UpdateUserSetting", { allowSendMail: allowSendMail}, null);
        }
        function _getNumberOfApprovalsForEntity() {
            return apiBase.Get(sectionControllerUrl + "GetNumberOfApprovalsForEntity", {}, null);
        }
        function _updateNumberOfApprovalsForEntity(numOfApprovals) {
            return apiBase.Post(sectionControllerUrl + "UpdateNumberOfApprovalsForEntity", { numOfApprovals: numOfApprovals }, null);
        }
        
        return {
            getUserAllowMail: _getUserAllowMail,
            updateUserSetting: _updateUserSetting,
            getNumberOfApprovalsForEntity: _getNumberOfApprovalsForEntity,
            updateNumberOfApprovalsForEntity: _updateNumberOfApprovalsForEntity,
        };
    }
}