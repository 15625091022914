import { Transition } from "@uirouter/core";

export default function (app) {
    app.factory("errorNotificationService", ['$rootScope', 'Notification', '$translate',
        function ($rootScope, Notification, $translate) {
        //TODO: move this list to translation files
        var _error;
        var _defaultError;
        var vm = this;
        $translate(["COMMON.CURRENT_CYCLE_EXIST", "COMMON.CYCLE_DATES_INVLAID", "COMMON.CYCLE_DATES_OVERLAPED",
            "COMMON.CLOSED_CYCLE", "COMMON.CYCLE_EXIST", "COMMON.CANNOT_MODIFY_ANNUAL_ACHIEVEMENT", "COMMON.UNAUTHORIZED",
            "COMMON.NOT_COMPLETED_ANNUAL_ACHIEVEMENTS", "COMMON.TASK_NOT_ASSIGNED_TO_YOU", "COMMON.FILL_ASSIGNEES", "COMMON.CYCLE_entry_DATES_OVERLAPED",
            "COMMON.CYCLE_PAST_DATES", "COMMON.ERROR_OCCURRED", "COMMON.NOT_SHARED_ACTIVITY","ANNUALACHIEVEMENTS.PICKEDBY_ANOTHER",
            "ANNUALACHIEVEMENTS.CANNOT_SUBMIT_ANNUAL", "ANNUALACHIEVEMENTS.NOT_ACTIVITIES_SUBMITED", "ACHIEVEMENT.NO_ACTIVE_CYCLE", "ACHIEVEMENT.AnnualAhievementSumbitted", "REQUESTS.RequestHaveAction", "REQUESTS.EntityDeactivated", "REQUESTS.NOTSubmitedERROR", "COMMON.SectionHaveRequest", "COMMON.Notification_Expire", "COMMON.EXPORT_REPORT_FAILURE", "COMMON.OldCycleRestore","COMMON.Notification_Previous_Cycle"]).then(function (translations) {
                vm.labels = {
                CURRENT_CYCLE_EXIST: translations['COMMON.CURRENT_CYCLE_EXIST'],
                CYCLE_DATES_INVLAID: translations['COMMON.CYCLE_DATES_INVLAID'],
                CYCLE_DATES_OVERLAPED: translations['COMMON.CYCLE_DATES_OVERLAPED'],
                CLOSED_CYCLE: translations['COMMON.CLOSED_CYCLE'],
                CYCLE_EXIST: translations['COMMON.CYCLE_EXIST'],
                CANNOT_MODIFY_ANNUAL_ACHIEVEMENT: translations['COMMON.CANNOT_MODIFY_ANNUAL_ACHIEVEMENT'],
                UNAUTHORIZED: translations['COMMON.UNAUTHORIZED'],
                NOT_COMPLETED_ANNUAL_ACHIEVEMENTS: translations['COMMON.NOT_COMPLETED_ANNUAL_ACHIEVEMENTS'],
                TASK_NOT_ASSIGNED_TO_YOU: translations['COMMON.TASK_NOT_ASSIGNED_TO_YOU'],
                FILL_ASSIGNEES: translations['COMMON.FILL_ASSIGNEES'],
                CYCLE_entry_DATES_OVERLAPED: translations['COMMON.CYCLE_entry_DATES_OVERLAPED'],
                CYCLE_PAST_DATES: translations['COMMON.CYCLE_PAST_DATES'],
                ERROR_OCCURRED: translations['COMMON.ERROR_OCCURRED'],
                NOT_SHARED_ACTIVITY: translations['COMMON.NOT_SHARED_ACTIVITY'],
                    ANNUAL_PICKED: translations['ANNUALACHIEVEMENTS.PICKEDBY_ANOTHER'],
                    CANNOT_SUBMIT_ANNUAL: translations['ANNUALACHIEVEMENTS.CANNOT_SUBMIT_ANNUAL'],
                    NOT_ACTIVITIES_SUBMITED: translations['ANNUALACHIEVEMENTS.NOT_ACTIVITIES_SUBMITED'],
                    NO_ACTIVE_CYCLE: translations['ACHIEVEMENT.NO_ACTIVE_CYCLE'],
                    AnnualAhievementSumbitted: translations['ACHIEVEMENT.AnnualAhievementSumbitted'],
                    RequestHaveAction: translations['REQUESTS.RequestHaveAction'],
                    EntityDeactivated: translations['REQUESTS.EntityDeactivated'],
                    NOTSubmitedERROR: translations['REQUESTS.NOTSubmitedERROR'],
                    SectionHaveRequest: translations['COMMON.SectionHaveRequest'],
                    NotificationExpire: translations['COMMON.Notification_Expire'],
                    EXPORT_REPORT_FAILURE: translations['COMMON.EXPORT_REPORT_FAILURE'],
                    OldCycleRestore: translations['COMMON.OldCycleRestore'],
                    Notification_Previous_Cycle: translations['COMMON.Notification_Previous_Cycle']
                }
                 _error = {
                    1: '',
                    //TODO:DINA to be verified
                    2: vm.labels.CURRENT_CYCLE_EXIST,
                    3: vm.labels.CYCLE_DATES_INVLAID,
                    4: vm.labels.CYCLE_DATES_OVERLAPED,
                    5: vm.labels.CLOSED_CYCLE,
                    6: vm.labels.CYCLE_EXIST,
                    9: vm.labels.CANNOT_MODIFY_ANNUAL_ACHIEVEMENT,
                    10: vm.labels.UNAUTHORIZED,
                     //15: 'This item already exist',
                     17: vm.labels.NO_ACTIVE_CYCLE,
                    18: vm.labels.NOT_COMPLETED_ANNUAL_ACHIEVEMENTS,
                    20: vm.labels.TASK_NOT_ASSIGNED_TO_YOU,
                    21: vm.labels.FILL_ASSIGNEES,
                    22: vm.labels.CYCLE_entry_DATES_OVERLAPED,
                    //TODO:DINA to be verified
                     23: vm.labels.CYCLE_PAST_DATES,
                     24: vm.labels.NOT_SHARED_ACTIVITY,
                     25: vm.labels.ANNUAL_PICKED,
                     26: vm.labels.CANNOT_SUBMIT_ANNUAL,
                     27: vm.labels.NOT_ACTIVITIES_SUBMITED,
                     50: vm.labels.AnnualAhievementSumbitted,
                     29: vm.labels.RequestHaveAction,
                     28: vm.labels.EntityDeactivated,
                     30: vm.labels.NOTSubmitedERROR,
                     31: vm.labels.SectionHaveRequest,
                     32: vm.labels.NotificationExpire,
                     33: vm.labels.EXPORT_REPORT_FAILURE,
                     321: vm.labels.OldCycleRestore,
                     34: vm.labels.Notification_Previous_Cycle,
                }
              _defaultError = vm.labels.ERROR_OCCURRED;


        });
 
        var _showErrorNotfication = function (error) {
            
            if (typeof error == 'number') {

                if (_error.hasOwnProperty(error)) {
                    Notification.error({ message: _error[error] });
                }
                else{
                    //show defualt error 
                    Notification.error({ message: _defaultError + error });
                }
            }
            else {
                if (error.data) {
                    $rootScope.showErrorMsg(error);
                }
                else if (error.message.includes("undefined")) {
                    $rootScope.methodPublicError({ status: 499 });
                }
                else {
                    Notification.error({ message: _defaultError + error });
                }
            }
        }

        return {
            showErrorNotification: _showErrorNotfication
        }
    }]);
}